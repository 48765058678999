import { TableColumnItem } from "./table";
import classNames from "classnames";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../checkbox/checkbox";
import Icon from "../icon/icon";
import FormattedMessage from "../formatted-message/formatted-message";
import { TooltipPosition } from "../tooltip/tooltip";
import { RootStore } from "services/store.service";
import ReactTooltip from "react-tooltip";
import { addToastMessage } from "services/common/commonsSlice";
import {
  EVENT_CODE,
  STATUS_ID_MAPPING,
  Indentation_Require,
} from "utils/constants";
import {
  setCellAsSelectedFromDrag,
  removeCellsFromSelection,
  emptyAllSelectedCells,
} from "services/inputForm/inputFormSlice";
import { useParams } from "react-router-dom";
import {
  handleBlurChange,
  inputValueReturn,
} from "../../shared/commons/globalFunctions";
import { showTooltip } from "services/common/commonsSlice";

const TableCell = ({
  id,
  textInput,
  error,
  errorText,
  errorToastNotRequired,
  largeError,
  inputValue,
  inputName,
  placeHolder,
  isTotal,
  inputDisable,
  onchangeHandler,
  onBlur,
  icon,
  iconSize,
  iconTooltip,
  InfoIconTooltip,
  text,
  textTooltip,
  subTitle,
  isFormattedText,
  renderContent,
  className,
  iconClassName,
  addInitials,
  onClick,
  type,
  wrapperClassName,
  hidden,
  checkbox,
  noteId,
  commentNodeID,
  filterContent,
  updateSelected,
  isSelected,
  setShowNotes,
  onChange,
  placholder,
  preparerEmailId,
  reviewerEmailId,
  memberFirmEmailId,
  highlight,
  inputIcon,
  allowNegValues,
  percentIconDisabledField,
  selection,
  keyIdentifier,
  preparerList,
  setPreparerList,
  indexKey,
  showExpandIcon = false,
  iconDirection,
  iconOnlick,
  roundNumbers,
  onHandleEnter,
  revenue_Pl_SubTitle,
}: TableColumnItem) => {
  const BLOCK = "Table";
  const params: any = useParams();
  const dispatch = useDispatch();
  const cellPadding = 35;
  const [delay, setDelay] = useState<any>(null);
  const commonsState = useSelector((state: RootStore) => state.commons);
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const [isSelectedFromDrag, setIsSelectedFromDrag] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [overflowActive, setOverflowActive] = useState<boolean>(false);

  const renderPreparerList = (
    text: any,
    preparerEmailId: any,
    addInitials: any,
    className: string
  ) => {
    return (
      <div className={className}>
        <ReactTooltip
          effect="solid"
          type="dark"
          className={`${BLOCK}__actions-tooltip-common`}
        />
        {addInitials.map((item: any, index: number) => {
          return (
            index !== 0 &&
            preparerEmailId[index] && (
              <div className={`${BLOCK}__popup-container`} key={index}>
                <span className={`${BLOCK}__column-text--initials`}>
                  {item}
                </span>
                <div data-tip={preparerEmailId[index]}>
                  <a
                    href={`mailto:${preparerEmailId[index]}`}
                    className={`${BLOCK}__popup-text ${BLOCK}__email`}
                  >
                    {text[index]}
                  </a>
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  };

  const handleLeave = () => {
    return setPreparerList?.("");
  };
  const handleHover = (
    id: string,
    text: any,
    preparerEmailId: any,
    addInitials: any
  ) => {
    let homeTableBottom = document
      ?.getElementById("homeTable")
      ?.getBoundingClientRect();
    let listBottom = document?.getElementById(id)?.getBoundingClientRect();
    if (homeTableBottom && listBottom) {
      let className;
      if (listBottom?.bottom + 170 < homeTableBottom?.bottom) {
        className = `${BLOCK}__likes-list_top`;
      } else {
        className = `${BLOCK}__likes-list_bottom`;
      }
      if (className) {
        return setPreparerList?.(
          renderPreparerList(text, preparerEmailId, addInitials, className)
        );
      }
    }
  };

  const ref = useRef<HTMLInputElement | null>(null);
  const [year, setYear] = useState<any>(null);

  useEffect(() => {
    setYear(params?.subsection);
  }, [params]);

  useEffect(() => {
    if (textInput) {
      setIsSelectedFromDrag(false);
      dispatch(removeCellsFromSelection(keyIdentifier));
      dispatch(setCellAsSelectedFromDrag([]));
      dispatch(emptyAllSelectedCells(true));
    }
  }, [year]);

  useEffect(() => {
    if (error && textInput) {
      dispatch(removeCellsFromSelection(keyIdentifier));
    }
  }, [error]);

  useEffect(() => {
    if (textInput) {
      if (isSelectedFromDrag) {
        dispatch(setCellAsSelectedFromDrag(keyIdentifier));
      } else {
        dispatch(removeCellsFromSelection(keyIdentifier));
      }
    }
  }, [isSelectedFromDrag]);

  useEffect(() => {
    if (textInput) {
      if (
        inputDisable ||
        inputFormDataState.inputFormStatusId === STATUS_ID_MAPPING.ARCHIVED.id
      ) {
        setIsSelectedFromDrag(false);
      } else if (!ref.current || !selection || selection?.height === 0) {
        setIsSelectedFromDrag(false);
      } else {
        const a = ref.current.getBoundingClientRect();
        const b = selection;
        setIsSelectedFromDrag(
          !(
            a.y + a.height < b.y ||
            a.y > b.y + b.height ||
            a.x + a.width < b.x ||
            a.x > b.x + b.width
          )
        );
      }
    }
  }, [ref, selection]);

  const handleMouseOver = (e: any) => {
    if (textTooltip) {
      setDelay(
        setTimeout(() => {
          const tdElement = e.target.closest("td");
          const valueElement = tdElement
            ? tdElement.querySelector(`.compact`)
            : null;

          if (!valueElement || !tdElement) {
            return;
          }

          if (
            tdElement.getBoundingClientRect().width - cellPadding <=
            valueElement.getBoundingClientRect().width
          ) {
            const toolTipState = {
              children: <>{text || valueElement.innerText}</>,
              position: TooltipPosition.topRight,
              className: className,
              customPosition: true,
              arrowPosition: {
                top: `${10}px`,
              },
              elementDimensions: valueElement.getBoundingClientRect(),
              executeMouseLeaveEvent: true,
            };

            dispatch(showTooltip(toolTipState));
          }
        }, 400)
      );
    }
  };

  const handleMouseLeave = (e: any) => {
    clearTimeout(delay);

    if (
      commonsState &&
      commonsState.toolTip.elementDimensions &&
      !commonsState.toolTip.isOverTooltip
    ) {
      dispatch(
        showTooltip({
          children: null,
          customPosition: null,
          position: null,
          arrowPosition: null,
          elementDimensions: null,
          executeMouseLeaveEvent: false,
          isOverTooltip: null,
        })
      );
    }
  };
  useEffect(() => {
    if (error === true && !errorToastNotRequired) {
      dispatch(
        addToastMessage({
          description: <>{errorText}</>,
        })
      );
    }
  }, [error]);

  useEffect(() => {
    let maxLength = 9;
    let updatedInputValue =
      roundNumbers && inputValue % 1 != 0
        ? parseFloat(inputValue?.toString())?.toFixed(roundNumbers)
        : inputValue?.toString();
    if (
      commonsState?.toggleNavigationPanel &&
      updatedInputValue?.includes("-") &&
      ((inputIcon && updatedInputValue?.length > maxLength) ||
        updatedInputValue?.length > maxLength + 1) &&
      !focus
    ) {
      setOverflowActive(true);
    } else if (
      commonsState?.toggleNavigationPanel &&
      updatedInputValue?.includes(".") &&
      updatedInputValue?.length >= maxLength + 2 &&
      !focus
    ) {
      setOverflowActive(true);
    } else if (
      commonsState?.toggleNavigationPanel &&
      !updatedInputValue?.includes(".") &&
      updatedInputValue?.length > maxLength &&
      !focus
    ) {
      setOverflowActive(true);
    } else {
      setOverflowActive(false);
    }
  }, [roundNumbers, inputValue, focus, commonsState?.toggleNavigationPanel]);

  const isIndentationClassApply = (text: string) =>
    revenue_Pl_SubTitle === "revenue-pl.staff.table.pl.main.title" &&
    Indentation_Require.includes(text);

  return (
    <>
      {hidden ? null : (
        <td
          className={classNames(`${BLOCK}__column`, {
            [`${BLOCK}__clickable`]: onClick,
            [`${wrapperClassName}`]: wrapperClassName,
            [`consolidation-indexKey`]: indexKey,
          })}
          onClick={onClick}
          data-test={className ? className : ""}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          data-testid="table-cell"
        >
          <div
            className={classNames(
              error
                ? `${BLOCK}__column-content_has_error`
                : `${BLOCK}__column-content`,
              {
                [`${BLOCK}__text-icon`]: text && icon,
                [`${className}`]: className,
              }
            )}
          >
            {renderContent ? (
              renderContent()
            ) : (
              <>
                {/* {checkbox &&
                  (type === "shortlist" ||
                    type === "esg" ||
                    (type === "sec" && filterContent)) && (
                    <div className={`${BLOCK}__checkbox-container`}>
                      {
                        <Checkbox
                          value={isSelected || false}
                          onChange={() => {
                            const updatedRows = updateSelected
                              ? updateSelected()
                              : [];
                            checkbox.onChange(updatedRows);
                          }}
                        />
                      }
                    </div>
                  )} */}
                {icon && (
                  <Icon
                    name={icon}
                    height={iconSize}
                    width={iconSize}
                    className={
                      iconClassName
                        ? iconClassName
                        : text
                        ? `${BLOCK}__content-icon`
                        : ""
                    }
                  />
                )}
                {textInput && (
                  <div>
                    {error && (
                      <ReactTooltip
                        effect="solid"
                        className={`${BLOCK}__tooltip`}
                      />
                    )}
                    {overflowActive && (
                      <ReactTooltip
                        effect="solid"
                        className={`${BLOCK}__actions-tooltip-text-overflow`}
                      />
                    )}
                    <div
                      data-tip={
                        error && !overflowActive
                          ? errorText
                          : overflowActive
                          ? inputValueReturn(inputValue, focus, roundNumbers, inputDisable ||
                            inputFormDataState.inputFormStatusId === STATUS_ID_MAPPING.ARCHIVED.id)
                          : null
                      }
                    >
                      <input
                        className={classNames(`${BLOCK}__input-container`, {
                          [`${BLOCK}__input-container-selected-drag`]:
                            error &&
                            isSelectedFromDrag &&
                            inputFormDataState.selectedCells.length > 1,
                          [`${BLOCK}__input-container-selected-drag-input-icon`]:
                            inputIcon &&
                            isSelectedFromDrag &&
                            inputFormDataState.selectedCells.length > 1,
                          [`${BLOCK}__input-container-selected-drag`]:
                            isSelectedFromDrag &&
                            inputFormDataState.selectedCells.length > 1,
                          [`${BLOCK}__error_input-container`]:
                            error &&
                            inputFormDataState.selectedCells.length <= 1,
                          [`${BLOCK}__grade_out_input-container`]:
                            inputDisable ||
                            inputFormDataState.inputFormStatusId ===
                              STATUS_ID_MAPPING.ARCHIVED.id,
                          [`${BLOCK}__icon-rtl`]: inputIcon,
                          [`${BLOCK}__icon-rtl--disabled`]:
                            percentIconDisabledField,
                          [`${BLOCK}__disabled_input-container`]:
                            inputDisable ||
                            inputFormDataState.inputFormStatusId ===
                              STATUS_ID_MAPPING.ARCHIVED.id ||
                            percentIconDisabledField,
                          [`${BLOCK}__input-container-highlight`]:
                            inputFormDataState.isHighlightEmpty &&
                            !inputValue &&
                            inputValue !== 0 &&
                            !isSelectedFromDrag &&
                            !focus,
                        })}
                        data-test="table-input-container"
                        id={keyIdentifier}
                        key={keyIdentifier}
                        ref={ref}
                        type="text"
                        style={{ textAlign: "right" }}
                        name={inputName}
                        value={inputValueReturn(
                          inputValue,
                          focus,
                          roundNumbers,
                          inputDisable || inputFormDataState.inputFormStatusId ===
                          STATUS_ID_MAPPING.ARCHIVED.id,
                        )}
                        disabled={
                          inputDisable ||
                          inputFormDataState.inputFormStatusId ===
                            STATUS_ID_MAPPING.ARCHIVED.id
                        }
                        onChange={onchangeHandler}
                        onKeyDown={(evt) => {
                          onHandleEnter(evt, keyIdentifier);
                          return (
                            (evt.key === "e" ||
                              evt.key === "E" ||
                              evt.key === "+" ||
                              (evt.key === "-" && !allowNegValues) ||
                              (evt.keyCode >= 65 && evt.keyCode <= 90) ||
                              evt.shiftKey === true ||
                              EVENT_CODE.includes(evt.code) ||
                              evt.keyCode === 38 ||
                              evt.keyCode === 40 ||
                              evt.key === ",") &&
                            evt.preventDefault()
                          );
                        }}
                        onWheel={(e) => e.currentTarget.blur()}
                        onBlur={(e) => {
                          handleBlurChange(e);
                          onBlur?.(e);
                          setFocus(false);
                        }}
                        placeholder={placeHolder ? placeHolder : "—"}
                        onFocus={() => {
                          setFocus(true);
                        }}
                      />
                    </div>
                  </div>
                )}

                {iconTooltip}
                <div className={`${BLOCK}__text-container`}>
                  {subTitle && (
                    <div className={`${BLOCK}__column-subtitle`}>
                      {isFormattedText ? (
                        <FormattedMessage id={subTitle} />
                      ) : (
                        subTitle
                      )}
                    </div>
                  )}
                  {/* {addInitials && (
                      <div className={`${BLOCK}__column-text`}>
                          {addInitials}
                      </div>    
                    )
                  } */}
                  {text && (
                    <div
                      className={classNames(`${BLOCK}__column-text compact`, {
                        [`${BLOCK}__column-text-indentation`]:
                          typeof text === "string" &&
                          isIndentationClassApply(text),
                      })}
                    >
                      {addInitials?.length > 0 && (
                        <>
                          <div
                            className={`${BLOCK}__column-text--initialsContainer`}
                          >
                            <span className={`${BLOCK}__column-text--initials`}>
                              {typeof addInitials !== "string"
                                ? addInitials[0]
                                : addInitials}
                            </span>
                          </div>
                          <ReactTooltip
                            effect="solid"
                            className={`${BLOCK}__actions-tooltip-common`}
                          />
                          <div
                            data-tip={
                              typeof text !== "string" && preparerEmailId
                                ? preparerEmailId[0]
                                : preparerEmailId
                                ? preparerEmailId
                                : reviewerEmailId
                                ? reviewerEmailId
                                : memberFirmEmailId
                            }
                          >
                            <a
                              className={`${BLOCK}__email`}
                              href={`mailto:${
                                typeof text !== "string" && preparerEmailId
                                  ? preparerEmailId[0]
                                  : preparerEmailId
                                  ? preparerEmailId
                                  : reviewerEmailId
                                  ? reviewerEmailId
                                  : memberFirmEmailId
                              }`}
                            >
                              {typeof text !== "string" ? text[0] : text}
                            </a>
                          </div>
                          {typeof text !== "string" && text.length > 1 && (
                            <>
                              <div className={`${BLOCK}__likes-wrapper`}>
                                <div
                                  className={`${BLOCK}__likes-relavance`}
                                  id={keyIdentifier}
                                  onMouseOver={() =>
                                    handleHover(
                                      keyIdentifier,
                                      text,
                                      preparerEmailId,
                                      addInitials
                                    )
                                  }
                                  onMouseLeave={handleLeave}
                                >
                                  +{text.length - 1}
                                  {preparerList}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {textTooltip}
                      {isFormattedText ? (
                        <div className={`${BLOCK}__column-text compact`}>
                          <FormattedMessage
                            id={typeof text !== "string" ? text[0] : text}
                          />
                        </div>
                      ) : isTotal === true ? (
                        inputIcon ? (
                          <div style={{ paddingLeft: "2rem" }}> {text}%</div>
                        ) : (
                          <div style={{ paddingLeft: "2rem" }}> {text}</div>
                        )
                      ) : showExpandIcon && !addInitials && !highlight ? (
                        <>
                          <div className={`${BLOCK}__container_width`}>
                            {text}
                          </div>
                          <div className={`${BLOCK}__content-icon`}>
                            <Icon
                              name={iconDirection}
                              height={15}
                              width={15}
                              className={`${BLOCK}__expandIcon`}
                            />
                          </div>
                        </>
                      ) : !addInitials && !highlight ? (
                        text
                      ) : !showExpandIcon && !addInitials && highlight ? (
                        <div style={{ fontWeight: "bold" }}>{text}</div>
                      ) : (
                        showExpandIcon &&
                        !addInitials &&
                        highlight && (
                          <>
                            <div style={{ fontWeight: "bold" }}>{text}</div>
                            <div onClick={() => iconOnlick(id)}>
                              <Icon
                                name={iconDirection}
                                height={15}
                                width={15}
                                className={`${BLOCK}__expandIcon`}
                              />
                            </div>
                          </>
                        )
                      )}
                      {InfoIconTooltip && (
                        <div>
                          <ReactTooltip
                            effect="solid"
                            className={`${BLOCK}__actions-tooltip-common`}
                          />
                          <div
                            data-tip={InfoIconTooltip}
                            className={`${BLOCK}__Info-icon-div`}
                          >
                            <Icon
                              name="information_blue"
                              height={16}
                              width={16}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </td>
      )}
    </>
  );
};

export default TableCell;
