import { useState, useCallback, useRef, memo } from "react";
import { useDispatch } from "react-redux";
import Table, { TableRowItem } from "../shared/table/table";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { TOTAL_TARGET_EXIT_HOURS } from "utils/constants";
import Icon from "../shared/icon/icon";
import { validateInputValueLength } from "../../services/inputForm/inputFormSlice";
import { addToastMessage } from "services/common/commonsSlice";
import {
  setDirtyTables,
  inputValueValidation,
  handleEnterTable,
} from "utils/useInputForm";

const ExitProgrammePhaceTable = ({
  currency,
  setSaved,
  tableId,
  subHeadingBlock,
  subTitle,
  showCurrencyHeader,
  baseYear,
  revenueDirtyTables,
  setRevenueDirtyTables,
  tableData,
  compareTableData,
  setTableData,
  revenueTableIdList,
  revenueData,
}: any) => {
  const BLOCK = "headcountsTable";
  const [prevValue, setPrevValue] = useState<any>("");
  const dispatch = useDispatch();
  const selectContainerRef = useRef<HTMLDivElement | null>(null);

  const changeTableValue = useCallback(
    (newTableValue: any) => {
      setTableData(newTableValue);
    },
    [tableData]
  );

  const getTableHeaders = () => {
    const tableHeaders = [
      {
        text: "revenue-pl.exit.programme.main.header",
        isFormattedText: true,
      },
      {
        text: "revenue-pl.exit.programme.main.hours",
        isFormattedText: true,
      },
    ];

    return tableHeaders;
  };

  const InputChangeHandler = (
    inputVal: any,
    columnId: number,
    roundOff: boolean = false
  ) => {
    if (parseInt(inputVal) < 0) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="neg.values.not.allowed" />,
        })
      );
      return;
    }
    setPrevValue(inputVal);
    let newVal = inputValueValidation(inputVal, prevValue, setPrevValue);
    let value: any;
    if (roundOff) {
      value = validateInputValueLength(newVal, prevValue, roundOff);
    } else {
      value = validateInputValueLength(newVal, prevValue);
    }

    let newTableValue: any;
    newTableValue =
      tableData &&
      tableData.length > 0 &&
      tableData.map((item: any) => {
        if (item.exitProgrammePhaseId === columnId) {
          return {
            ...item,
            totalHoursofWork: value,
          };
        }
        return item;
      });

    if (roundOff) {
      newTableValue =
        tableData &&
        tableData.length > 0 &&
        tableData.map((item: any) => {
          if (item.exitProgrammePhaseId === columnId) {
            if (value == 0) {
              return { ...item, totalHoursofWork: null, error: true };
            } else {
              return {
                ...item,
                totalHoursofWork: value,
                error: false,
              };
            }
          }
          return item;
        });
    }
    changeTableValue(newTableValue);
    setDirtyTables(
      compareTableData,
      newTableValue,
      revenueDirtyTables,
      setRevenueDirtyTables,
      tableId
    );
  };
  const FocusChage = (e: any, columnId: number) => {
    setSaved(false);
    InputChangeHandler(e, columnId, true);
  };

  const getRowItems = () => {
    return (
      tableData &&
      tableData.length > 0 &&
      tableData?.map((item: any, index: number) => {
        let tableRows = {
          id: index + 1,
          key: `${index}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: "Total Hours of Work to Exit",
            },
            {
              textInput: true,
              inputValue: item.totalHoursofWork,
              error: item.error,
              errorText: TOTAL_TARGET_EXIT_HOURS,
              onBlur: (e: any) => {
                FocusChage(e.target.value, item.exitProgrammePhaseId);
              },
              onchangeHandler: (e: any) => {
                InputChangeHandler(
                  e.target.value,
                  item.exitProgrammePhaseId,
                  false
                );
              },
              onHandleEnter: (evt: any, keyIdentifier: any) => {
                setPrevValue(evt?.target?.value);
                handleEnterTable(
                  evt,
                  keyIdentifier,
                  tableData,
                  tableId,
                  revenueTableIdList,
                  baseYear,
                  revenueData
                );
              },
            },
          ],
        } as TableRowItem;

        return tableRows;
      })
    );
  };

  return (
    <div
      data-test="component-ExitProgrammePhaceTable"
      style={{ marginTop: 30 }}
    >
      <div className={`${subHeadingBlock}__sub-header`}>
        <div className={`${subHeadingBlock}__sub-header--title`}>
          {tableId === 1 ? (
            subTitle
          ) : (
            <>
              <FormattedMessage id={subTitle} />{" "}
              {currency && showCurrencyHeader ? `(${currency} 'M)` : ""}
            </>
          )}
        </div>
      </div>
      <div ref={selectContainerRef} id={tableId}>
        {tableData && tableData.length === 0 ? (
          <div className={`${subHeadingBlock}__spinner-container`}>
            <Icon
              name={"loading"}
              width={50}
              height={50}
              className={`${subHeadingBlock}--loading`}
            />
          </div>
        ) : (
          <div>
            <Table
              headerItems={getTableHeaders()}
              rowItems={getRowItems()}
              innerClassName={BLOCK}
              tableId={tableId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ExitProgrammePhaceTable);
