import { useState, useRef, createContext, memo } from "react";
import { useDispatch } from "react-redux";
import Table from "../shared/table/table";
import {
  productSegmentsMapping,
  headCountsTableGradeLevels,
} from "utils/constants";
import Icon from "components/shared/icon/icon";
import { useAreaSelection } from "utils/area-selection";
import {
  updateTables,
  getHeadCountsRowItems,
  useCheckTableBottomVisible,
} from "utils/useInputForm";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

const SelectionContext = createContext<DOMRect | null>(null);

const AnnualTotalHours = ({
  subHeadingBlock,
  setSaved,
  tableId,
  SelctedTableId,
  deliveryCentreId,
  tableHeader,
  showModal,
  margin_top,
  showUpdateInputsPopup,
  headCountsDirtyTables,
  setHeadCountsDirtyTables,
  tableData,
  compareTableData,
  setTableData,
  baseYear,
  headCountTableIdList,
  headCountsData,
  tableScrollIds,
  setTableScrolledIds,
}: any) => {
  const BLOCK = "headcountsTable";
  const [prevValue, setPrevValue] = useState<any>("");
  const dispatch = useDispatch();
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });

  const getTableHeaders = () => {
    let dynamicTableHeaders: any =
      tableData?.length > 0 &&
      Object.keys(tableData[0] || {})
        .filter(Number)
        .map((item) => {
          if (item in productSegmentsMapping) {
            return {
              text: productSegmentsMapping[item],
              isFormattedText: false,
            };
          }
        });
    const tableHeaders = dynamicTableHeaders &&
      dynamicTableHeaders.length > 0 && [
        {
          text: tableHeader,
          isFormattedText: true,
        },
        ...dynamicTableHeaders,
      ];

    return tableHeaders;
  };

  const handleOnChange = (
    gradeLevelId: number,
    inputVal: any,
    columnId: any,
    roundOff: boolean = false
  ) => {
    setPrevValue(inputVal);
    updateTables(
      gradeLevelId,
      inputVal,
      columnId,
      roundOff,
      tableData,
      setTableData,
      compareTableData,
      headCountsDirtyTables,
      setHeadCountsDirtyTables,
      SelctedTableId,
      setPrevValue,
      prevValue
    );
  };

  const FocusChage = (inputVal: any, gradeLevelId: any, columnId: any) => {
    setSaved(false);
    handleOnChange(gradeLevelId, inputVal, columnId, true);
  };

  useCheckTableBottomVisible(
    selectContainerRef,
    tableScrollIds,
    setTableScrolledIds
  );

  return (
    <div
      data-test="annualTotalHours-table"
      style={margin_top && { marginTop: 30 }}
    >
      <div className={`${subHeadingBlock}__sub-header`}>
        <div className={`${subHeadingBlock}__sub-header--title`}>
          <FormattedMessage id={tableHeader} />
        </div>
      </div>
      <SelectionContext.Provider value={selection}>
        <div ref={selectContainerRef} id={SelctedTableId}>
          {tableData?.length === 0 ? (
            <div className={`${subHeadingBlock}__spinner-container`}>
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className={`${subHeadingBlock}--loading`}
              />
            </div>
          ) : (
            <Table
              className={`${BLOCK}--innerWrapper`}
              headerItems={getTableHeaders()}
              rowItems={getHeadCountsRowItems(
                tableData,
                FocusChage,
                handleOnChange,
                BLOCK,
                headCountsTableGradeLevels,
                baseYear,
                SelctedTableId,
                headCountTableIdList,
                headCountsData,
                setPrevValue
              )}
              innerClassName={BLOCK}
              selection={selection}
              tableId={SelctedTableId}
            />
          )}
        </div>
      </SelectionContext.Provider>
    </div>
  );
};

export default memo(AnnualTotalHours);
