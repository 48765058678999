import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { loadNetProfit } from "services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { ConsolidationsKeyInitial } from "utils/constants";

type NetProfit = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const NetProfitTable = ({ geographies, currencyCode, baseYear }: NetProfit) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );

  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        loadNetProfit({ countryCodes: geographies, currencyCode, baseYear })
      );
    }
  }, [geographies, currencyCode, baseYear]);
  return (
    <div data-test="netProfitTable-component">
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.netProfitData?.consolidatedProfitAndLossDetails
        }
        tableHeader={consolidationData?.netProfitData?.subTotalProfitAndLoss}
        mainTableHeader="consolidation.net.profit"
        subConsolidationTableData={
          consolidationData?.netProfitData?.subConsolidatedProfitAndLossDetails
        }
        keyInitial={ConsolidationsKeyInitial.NET_PROFIT}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return item === VISUAL_DASHBOARD_OPTIONS.NET_PROFIT_LOADING;
          })?.length > 0
        }
      />
    </div>
  );
};

export default NetProfitTable;
