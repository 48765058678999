import React, { useEffect, useState } from "react";
import { useAPI } from "../updatedHeadCounts/headCountsContextProvider";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import AvgAnnualFTESalary from "../headcounts/avgAnnualFTESalary";
import SalaryPriceIncrease from "../headcounts/salaryPriceIncrease";
import FullEimeEquivalents from "../headcounts/FullEimeEquivalents";
import RatePerGrade from "../headcounts/ratePerGrade";
import TargetManagedHoursPerRole from "../headcounts/targetManagedHours";
import RateCards from "../headcounts/rateCards";
import PriceIncrease from "../headcounts/priceIncrease";
import AnnualTotalHours from "../headcounts/annualTotalHours";
import UtilisationRate from "../headcounts/utilisationRate";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  TABLE_MAPPINGS,
  delieveryCenterID,
  TABLE_MAPPINGS_UTIL_RATE,
  TABLE_MAPPINGS_PRICE_INCREASE,
  TABLE_MAPPINGS_HOURS,
  DELIVERY_CENTRE_ID_MAPPING,
  CALCULATE_ENGINE,
  GENERAL_INPUT_ERROR,
  headCountTablesList,
  COMMONTABLE_IDS,
} from "utils/constants";
import {
  getStatusId,
  calculateOpeningAndClosingPositionAPI,
  submitTargetManagedHoursData,
  submitAvgAnnualFTESalary,
  submitSalaryPrice,
  submitYearlyUtilisationRate,
  submitFy22BasedHeadCounts,
  submitAnnualTotalHours,
  submitUtilisationRates,
  submitRateCard,
  submitPriceIncrease,
  submitRate,
  setInputFormProgressDataLocal,
  getProgressPercentage,
} from "services/inputForm/inputFormSlice";
import { addToastMessage } from "services/common/commonsSlice";
import UpdateInputsModal from "../inputform/updateMultipleInputsModal";
import { cleanTableData } from "components/shared/commons/globalFunctions";

interface HeadCountsProps {
  toggleNavigation: boolean;
  planSubmissionId: number;
  saved: boolean;
  setSaved: any;
  savingData: any;
  setSavingData: any;
  haedCountApiCall: any;
  setHeadCountApiCall: any;
  headCountsTableProgress: any;
  totalPercentage: number;
  headCountsDirtyTables: any;
  setHeadCountsDirtyTables: any;
}

const UpdatedHeadcounts = ({
  planSubmissionId,
  toggleNavigation,
  saved,
  setSaved,
  savingData,
  setSavingData,
  haedCountApiCall,
  setHeadCountApiCall,
  headCountsTableProgress,
  totalPercentage,
  headCountsDirtyTables,
  setHeadCountsDirtyTables,
}: HeadCountsProps) => {
  const BLOCK = "headcounts";
  const [headCountsData, setHeadCountsData] = useState<any>({});
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const dispatch = useDispatch();
  const [showUpdateInputsPopup, setShowUpdateInputsPopup] =
    useState<boolean>(false);

  const {
    avgAnnualFTESalary,
    salaryPriceIncrease,
    yearlyUtilisationRate,
    fullTimeEquavalents,
    attritionRatePerGrade,
    promotionRatePerGrade,
    targetManagedHours,
    usiRateCard,
    radcRateCard,
    localDCRateCard,
    localCOERateCard,
    usiPriceIncreaseRates,
    radcPriceIncreaseRates,
    localDCPriceIncreaseRates,
    localCOEPriceIncreaseRates,
    usiUtilisationRates,
    radcUtilisationRates,
    localDCUtilisationRates,
    localCOEUtilisationRates,
    usiAnnualTotalHours,
    radcAnnualTotalHours,
    localDCAnnualTotalHours,
    localCOEAnnualTotalHours,
    compareTableData,
    setCompareTableData,
    setUsiRateCard,
    setRadcRateCard,
    setLocalDCRateCard,
    setLocalCoeRateCard,
    setUsiPriceIncreaseRates,
    setRadcPriceIncreaseRates,
    setLocalDCPriceIncreaseRates,
    setLocalCoePriceIncreaseRates,
    setUsiAnnualTotalHours,
    setRadcAnnualTotalHours,
    setLocalDCAnnualTotalHours,
    setLocalCoeAnnualTotalHours,
    setAvgAnnualFTESalary,
    setYearlyUtilisationRate,
    setSalaryPriceIncrease,
    setAttritionRatePerGrade,
    setPromotionRatePerGrade,
    setFullTimeEquavalents,
    setTargetManagedHours,
    setUsiUtilisationRates,
    setRadcUtilisationRates,
    setLocalDCUtilisationRates,
    setLocalCoeUtilisationRates,
    setMultipleErrorsHC,
    multipleErrorsHC,
    headCountTableIdList,
    tableScrollIds,
    setTableScrolledIds,
    initialCompletedFields,
    setInitialCompletedFields,
  }: any = useAPI();

  useEffect(() => {
    if (multipleErrorsHC) {
      dispatch(
        addToastMessage({
          description: <>{GENERAL_INPUT_ERROR}</>,
        })
      );
      setMultipleErrorsHC(false);
    }
  }, [multipleErrorsHC]);

  useEffect(() => {
    if (savingData && haedCountApiCall) {
      let promiseArray: any = [];
      headCountsDirtyTables.map((item: any) => {
        switch (item) {
          case TABLE_MAPPINGS.annualAvgFTE:
            promiseArray.push(
              submitAvgAnnualFTESalary(avgAnnualFTESalary, planSubmissionId)
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              avgAnnualFTESalary: avgAnnualFTESalary,
            }));
            break;
          case TABLE_MAPPINGS.salaryPriceIncrease:
            promiseArray.push(
              submitSalaryPrice(salaryPriceIncrease, planSubmissionId)
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              salaryPriceIncrease: salaryPriceIncrease,
            }));
            break;
          case TABLE_MAPPINGS.fullTimeEq:
            promiseArray.push(
              submitFy22BasedHeadCounts(fullTimeEquavalents, planSubmissionId)
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              fullTimeEquavalents: fullTimeEquavalents,
            }));
            break;
          case TABLE_MAPPINGS.attrition:
            promiseArray.push(
              submitRate(
                cleanTableData(attritionRatePerGrade),
                planSubmissionId,
                "attritionrates"
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              attritionRatePerGrade: cleanTableData(attritionRatePerGrade),
            }));
            break;
          case TABLE_MAPPINGS.promotion:
            promiseArray.push(
              submitRate(
                cleanTableData(promotionRatePerGrade),
                planSubmissionId,
                "promotionrates"
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              promotionRatePerGrade: cleanTableData(promotionRatePerGrade),
            }));
            break;
          case TABLE_MAPPINGS.targetManagedHours:
            promiseArray.push(
              submitTargetManagedHoursData(targetManagedHours, planSubmissionId)
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              targetManagedHours: targetManagedHours,
            }));
            break;
          case TABLE_MAPPINGS.rateCardUSI:
            promiseArray.push(
              submitRateCard(
                usiRateCard,
                delieveryCenterID.USI,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              usiRateCard: usiRateCard,
            }));
            let usiRateCardFieldCount = updateCompletedFields(usiRateCard);
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                usiRateCardInitialCompletedFields: usiRateCardFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS.rateCardRADC:
            promiseArray.push(
              submitRateCard(
                radcRateCard,
                delieveryCenterID.RADC,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              radcRateCard: radcRateCard,
            }));
            let radcRateCardFieldCount = updateCompletedFields(radcRateCard);
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                radcRateCardInitialCompletedFields: radcRateCardFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS.rateCardLocalDC:
            promiseArray.push(
              submitRateCard(
                localDCRateCard,
                delieveryCenterID.LocalDC,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              localDCRateCard: localDCRateCard,
            }));
            let localDCRateCardFieldCount =
              updateCompletedFields(localDCRateCard);
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                localDCRateCardInitialCompletedFields:
                  localDCRateCardFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS.rateCardLocalCOE:
            promiseArray.push(
              submitRateCard(
                localCOERateCard,
                delieveryCenterID.LocalCOE,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              localCOERateCard: localCOERateCard,
            }));
            let localCOERateCardFieldCount =
              updateCompletedFields(localCOERateCard);
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                localCOERateCardInitialCompletedFields:
                  localCOERateCardFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseUSI:
            promiseArray.push(
              submitPriceIncrease(
                usiPriceIncreaseRates,
                delieveryCenterID.USI,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              usiPriceIncreaseRates: usiPriceIncreaseRates,
            }));
            let usiPriceIncreaseFieldCount = updateCompletedFields(
              usiPriceIncreaseRates
            );
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                usiPriceIncreaseInitialCompletedFields:
                  usiPriceIncreaseFieldCount,
              };
            });

            break;
          case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseRADC:
            promiseArray.push(
              submitPriceIncrease(
                radcPriceIncreaseRates,
                delieveryCenterID.RADC,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              radcPriceIncreaseRates: radcPriceIncreaseRates,
            }));
            let radcPriceIncreaseFieldCount = updateCompletedFields(
              radcPriceIncreaseRates
            );
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                radcPriceIncreaseInitialCompletedFields:
                  radcPriceIncreaseFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseLocalDC:
            promiseArray.push(
              submitPriceIncrease(
                localDCPriceIncreaseRates,
                delieveryCenterID.LocalDC,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              localDCPriceIncreaseRates: localDCPriceIncreaseRates,
            }));
            let localDcPriceIncreaseFieldCount = updateCompletedFields(
              localDCPriceIncreaseRates
            );
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                localDcPriceIncreaseInitialCompletedFields:
                  localDcPriceIncreaseFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaeLocalCOE:
            promiseArray.push(
              submitPriceIncrease(
                localCOEPriceIncreaseRates,
                delieveryCenterID.LocalCOE,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              localCOEPriceIncreaseRates: localCOEPriceIncreaseRates,
            }));
            let localCOEPriceIncreaseFieldCount = updateCompletedFields(
              localCOEPriceIncreaseRates
            );
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                localCOEPriceIncreaseInitialCompletedFields:
                  localCOEPriceIncreaseFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS_HOURS.hoursUSI:
            promiseArray.push(
              submitAnnualTotalHours(
                usiAnnualTotalHours,
                delieveryCenterID.USI,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              usiAnnualTotalHours: usiAnnualTotalHours,
            }));
            let usiHoursFieldCount = updateCompletedFields(usiAnnualTotalHours);
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                usiHoursInitialCompletedFields: usiHoursFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS_HOURS.hoursRADC:
            promiseArray.push(
              submitAnnualTotalHours(
                radcAnnualTotalHours,
                delieveryCenterID.RADC,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              radcAnnualTotalHours: radcAnnualTotalHours,
            }));
            let radcHoursFieldCount =
              updateCompletedFields(radcAnnualTotalHours);
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                radcHoursInitialCompletedFields: radcHoursFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS_HOURS.hoursLocalDC:
            promiseArray.push(
              submitAnnualTotalHours(
                localDCAnnualTotalHours,
                delieveryCenterID.LocalDC,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              localDCAnnualTotalHours: localDCAnnualTotalHours,
            }));
            let localDCHoursFieldCount = updateCompletedFields(
              localDCAnnualTotalHours
            );
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                localDCHoursInitialCompletedFields: localDCHoursFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS_HOURS.hoursLocalCOE:
            promiseArray.push(
              submitAnnualTotalHours(
                localCOEAnnualTotalHours,
                delieveryCenterID.LocalCOE,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              localCOEAnnualTotalHours: localCOEAnnualTotalHours,
            }));
            let localCOEHoursFieldCount = updateCompletedFields(
              localCOEAnnualTotalHours
            );
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                localCOEHoursInitialCompletedFields: localCOEHoursFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS_UTIL_RATE.utilRateUSI:
            promiseArray.push(
              submitUtilisationRates(
                usiUtilisationRates,
                delieveryCenterID.USI,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              usiUtilisationRates: usiUtilisationRates,
            }));
            let usiUtilisationFieldCount =
              updateCompletedFields(usiUtilisationRates);
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                usiUtilisationInitialCompletedFields: usiUtilisationFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS_UTIL_RATE.utilRateRADC:
            promiseArray.push(
              submitUtilisationRates(
                radcUtilisationRates,
                delieveryCenterID.RADC,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              radcUtilisationRates: radcUtilisationRates,
            }));
            let radcUtilisationFieldCount =
              updateCompletedFields(radcUtilisationRates);
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                radcUtilisationInitialCompletedFields:
                  radcUtilisationFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS_UTIL_RATE.utilLocalDC:
            promiseArray.push(
              submitUtilisationRates(
                localDCUtilisationRates,
                delieveryCenterID.LocalDC,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              localDCUtilisationRates: localDCUtilisationRates,
            }));
            let localDCUtilisationFieldCount = updateCompletedFields(
              localDCUtilisationRates
            );
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                localDCUtilisationInitialCompletedFields:
                  localDCUtilisationFieldCount,
              };
            });
            break;
          case TABLE_MAPPINGS_UTIL_RATE.utilLocalCOE:
            promiseArray.push(
              submitUtilisationRates(
                localCOEUtilisationRates,
                delieveryCenterID.LocalCOE,
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              localCOEUtilisationRates: localCOEUtilisationRates,
            }));
            let localCOEUtilisationFieldCount = updateCompletedFields(
              localCOEUtilisationRates
            );
            setInitialCompletedFields((prevVal: any) => {
              return {
                ...prevVal,
                localCOEUtilisationInitialCompletedFields:
                  localCOEUtilisationFieldCount,
              };
            });
            break;
        }
      });

      Promise.all([...promiseArray])
        .then(() => {
          setSavingData(false);
          setHeadCountApiCall(false);
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="form.save.toaster.message" />,
            })
          );
          if (promiseArray.length > 0) {
            totalPercentage &&
              totalPercentage === 100 &&
              dispatch(getStatusId(planSubmissionId));

            let engineObject = {
              tableProgress: headCountsTableProgress == 100,
              inputFormSection: CALCULATE_ENGINE.HEADCOUNTS,
            };

            dispatch(
              calculateOpeningAndClosingPositionAPI(
                planSubmissionId,
                false,
                engineObject
              )
            );
            dispatch(getProgressPercentage(planSubmissionId));
          }
          setHeadCountsDirtyTables([]);
        })
        .catch(() => {
          setSavingData(false);
          setHeadCountApiCall(false);
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="form.error.toaster.message" />,
            })
          );
          setHeadCountsDirtyTables([]);
        });
    }
  }, [savingData, haedCountApiCall]);

  const updateCompletedFields = (tableDat: any) => {
    let fieldCount = 0;
    tableDat?.map((item: any) => {
      Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          item[id] !== null && fieldCount++;
        });
    });
    return fieldCount;
  };

  useEffect(() => {
    let inputFormProgressData =
      inputFormDataState?.InputFormProgressData?.fieldCountDetails;

    let avgAnnualFTESalaryCompletedFields =
      updateCompletedFields(avgAnnualFTESalary);
    let salaryPriceIncreaseCompletedFields =
      updateCompletedFields(salaryPriceIncrease);
    let yearlyUtilisationRateCompletedFields = updateCompletedFields(
      yearlyUtilisationRate
    );
    let fullTimeEquavalentsCompletedFields =
      updateCompletedFields(fullTimeEquavalents);
    let targetManagedHoursCompletedFields =
      updateCompletedFields(targetManagedHours);
    let usiRateCardCompletedFields = updateCompletedFields(usiRateCard);
    let radcRateCardCompletedFields = updateCompletedFields(radcRateCard);
    let localDCRateCardCompletedFields = updateCompletedFields(localDCRateCard);
    let localCOERateCardCompletedFields =
      updateCompletedFields(localCOERateCard);
    let usiPriceIncreaseRatesCompletedFields = updateCompletedFields(
      usiPriceIncreaseRates
    );
    let radcPriceIncreaseRatesCompletedFields = updateCompletedFields(
      radcPriceIncreaseRates
    );
    let localDCPriceIncreaseRatesCompletedFields = updateCompletedFields(
      localDCPriceIncreaseRates
    );
    let localCOEPriceIncreaseRatesCompletedFields = updateCompletedFields(
      localCOEPriceIncreaseRates
    );
    let usiUtilisationRatesCompletedFields =
      updateCompletedFields(usiUtilisationRates);
    let radcUtilisationRatesCompletedFields =
      updateCompletedFields(radcUtilisationRates);
    let localDCUtilisationRatesCompletedFields = updateCompletedFields(
      localDCUtilisationRates
    );
    let localCOEUtilisationRatesCompletedFields = updateCompletedFields(
      localCOEUtilisationRates
    );
    let usiAnnualTotalHoursCompletedFields =
      updateCompletedFields(usiAnnualTotalHours);
    let radcAnnualTotalHoursCompletedFields =
      updateCompletedFields(radcAnnualTotalHours);
    let localDCAnnualTotalHoursCompletedFields = updateCompletedFields(
      localDCAnnualTotalHours
    );
    let localCOEAnnualTotalHoursCompletedFields = updateCompletedFields(
      localCOEAnnualTotalHours
    );
    let attritionRatePerGradeCompletedFields = 0;
    let promotionRatePerGradeCompletedFields = 0;

    attritionRatePerGrade?.map((item: any) => {
      Object.keys(item || {})
        ?.filter(Number)
        ?.map((row: any) => {
          if (item[row] !== null) attritionRatePerGradeCompletedFields++;
        });
    });

    promotionRatePerGrade?.map((item: any) => {
      Object.keys(item || {})
        ?.filter(Number)
        ?.map((row: any) => {
          if (item[row] !== null) promotionRatePerGradeCompletedFields++;
        });
    });

    let updatedData: any = [];

    if (inputFormProgressData?.length && !saved) {
      updatedData = inputFormProgressData?.map((item: any) => {
        switch (item.inputFormMasterTableId) {
          case TABLE_MAPPINGS.annualAvgFTE:
            return {
              ...item,
              completedFields: avgAnnualFTESalaryCompletedFields,
            };
          case TABLE_MAPPINGS.salaryPriceIncrease:
            return salaryPriceIncrease && salaryPriceIncrease?.length
              ? {
                  ...item,
                  completedFields: salaryPriceIncreaseCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.fullTimeEq:
            return fullTimeEquavalents && fullTimeEquavalents?.length
              ? {
                  ...item,
                  completedFields: fullTimeEquavalentsCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.attrition:
            return attritionRatePerGrade && attritionRatePerGrade?.length
              ? {
                  ...item,
                  completedFields: attritionRatePerGradeCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.promotion:
            return promotionRatePerGrade && promotionRatePerGrade?.length
              ? {
                  ...item,
                  completedFields: promotionRatePerGradeCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.targetManagedHours:
            return targetManagedHours && targetManagedHours?.length
              ? {
                  ...item,
                  completedFields: targetManagedHoursCompletedFields,
                }
              : item;
          case COMMONTABLE_IDS.rateCard:
            if (
              usiRateCard?.length &&
              !radcRateCard?.length &&
              !localDCRateCard?.length &&
              !localCOERateCard?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  initialCompletedFields?.usiRateCardInitialCompletedFields +
                  usiRateCardCompletedFields,
              };
            } else if (
              usiRateCard?.length &&
              radcRateCard?.length &&
              !localDCRateCard?.length &&
              !localCOERateCard?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiRateCardInitialCompletedFields +
                    initialCompletedFields?.radcRateCardInitialCompletedFields) +
                  usiRateCardCompletedFields +
                  radcRateCardCompletedFields,
              };
            } else if (
              usiRateCard?.length &&
              radcRateCard?.length &&
              localDCRateCard?.length &&
              !localCOERateCard?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiRateCardInitialCompletedFields +
                    initialCompletedFields?.radcRateCardInitialCompletedFields +
                    initialCompletedFields?.localDCRateCardInitialCompletedFields) +
                  usiRateCardCompletedFields +
                  radcRateCardCompletedFields +
                  localDCRateCardCompletedFields,
              };
            } else if (
              usiRateCard?.length &&
              radcRateCard?.length &&
              localDCRateCard?.length &&
              localCOERateCard?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiRateCardInitialCompletedFields +
                    initialCompletedFields?.radcRateCardInitialCompletedFields +
                    initialCompletedFields?.localDCRateCardInitialCompletedFields +
                    initialCompletedFields?.localCOERateCardInitialCompletedFields) +
                  usiRateCardCompletedFields +
                  radcRateCardCompletedFields +
                  localDCRateCardCompletedFields +
                  localCOERateCardCompletedFields,
              };
            } else {
              return item;
            }
          case COMMONTABLE_IDS.priceIncrease:
            if (
              usiPriceIncreaseRates?.length &&
              !radcPriceIncreaseRates?.length &&
              !localDCPriceIncreaseRates?.length &&
              !localCOEPriceIncreaseRates?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  initialCompletedFields?.usiPriceIncreaseInitialCompletedFields +
                  usiPriceIncreaseRatesCompletedFields,
              };
            } else if (
              usiPriceIncreaseRates?.length &&
              radcPriceIncreaseRates?.length &&
              !localDCPriceIncreaseRates?.length &&
              !localCOEPriceIncreaseRates?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiPriceIncreaseInitialCompletedFields +
                    initialCompletedFields?.radcPriceIncreaseInitialCompletedFields) +
                  usiPriceIncreaseRatesCompletedFields +
                  radcPriceIncreaseRatesCompletedFields,
              };
            } else if (
              usiPriceIncreaseRates?.length &&
              radcPriceIncreaseRates?.length &&
              localDCPriceIncreaseRates?.length &&
              !localCOEPriceIncreaseRates?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiPriceIncreaseInitialCompletedFields +
                    initialCompletedFields?.radcPriceIncreaseInitialCompletedFields +
                    initialCompletedFields?.localDcPriceIncreaseInitialCompletedFields) +
                  usiPriceIncreaseRatesCompletedFields +
                  radcPriceIncreaseRatesCompletedFields +
                  localDCPriceIncreaseRatesCompletedFields,
              };
            } else if (
              usiPriceIncreaseRates?.length &&
              radcPriceIncreaseRates?.length &&
              localDCPriceIncreaseRates?.length &&
              localCOEPriceIncreaseRates?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiPriceIncreaseInitialCompletedFields +
                    initialCompletedFields?.radcPriceIncreaseInitialCompletedFields +
                    initialCompletedFields?.localDcPriceIncreaseInitialCompletedFields +
                    initialCompletedFields?.localCOEPriceIncreaseInitialCompletedFields) +
                  usiPriceIncreaseRatesCompletedFields +
                  radcPriceIncreaseRatesCompletedFields +
                  localDCPriceIncreaseRatesCompletedFields +
                  localCOEPriceIncreaseRatesCompletedFields,
              };
            } else {
              return item;
            }
          case COMMONTABLE_IDS.hours:
            if (
              usiAnnualTotalHours?.length &&
              !radcAnnualTotalHours?.length &&
              !localDCAnnualTotalHours?.length &&
              !localCOEAnnualTotalHours?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  initialCompletedFields?.usiHoursInitialCompletedFields +
                  usiAnnualTotalHoursCompletedFields,
              };
            } else if (
              usiAnnualTotalHours?.length &&
              radcAnnualTotalHours?.length &&
              !localDCAnnualTotalHours?.length &&
              !localCOEAnnualTotalHours?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiHoursInitialCompletedFields +
                    initialCompletedFields?.radcHoursInitialCompletedFields) +
                  usiAnnualTotalHoursCompletedFields +
                  radcAnnualTotalHoursCompletedFields,
              };
            } else if (
              usiAnnualTotalHours?.length &&
              radcAnnualTotalHours?.length &&
              localDCAnnualTotalHours?.length &&
              !localCOEAnnualTotalHours?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiHoursInitialCompletedFields +
                    initialCompletedFields?.radcHoursInitialCompletedFields +
                    initialCompletedFields?.localDCHoursInitialCompletedFields) +
                  usiAnnualTotalHoursCompletedFields +
                  radcAnnualTotalHoursCompletedFields +
                  localDCAnnualTotalHoursCompletedFields,
              };
            } else if (
              usiAnnualTotalHours?.length &&
              radcAnnualTotalHours?.length &&
              localDCAnnualTotalHours?.length &&
              localCOEAnnualTotalHours?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiHoursInitialCompletedFields +
                    initialCompletedFields?.radcHoursInitialCompletedFields +
                    initialCompletedFields?.localDCHoursInitialCompletedFields +
                    initialCompletedFields?.localCOEHoursInitialCompletedFields) +
                  usiAnnualTotalHoursCompletedFields +
                  radcAnnualTotalHoursCompletedFields +
                  localDCAnnualTotalHoursCompletedFields +
                  localCOEAnnualTotalHoursCompletedFields,
              };
            } else {
              return item;
            }
          case COMMONTABLE_IDS.utilisation:
            if (
              usiUtilisationRates?.length &&
              !radcUtilisationRates?.length &&
              !localDCUtilisationRates?.length &&
              !localCOEUtilisationRates?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  initialCompletedFields?.usiUtilisationInitialCompletedFields +
                  usiUtilisationRatesCompletedFields,
              };
            } else if (
              usiUtilisationRates?.length &&
              radcUtilisationRates?.length &&
              !localDCUtilisationRates?.length &&
              !localCOEUtilisationRates?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiUtilisationInitialCompletedFields +
                    initialCompletedFields?.radcUtilisationInitialCompletedFields) +
                  usiUtilisationRatesCompletedFields +
                  radcUtilisationRatesCompletedFields,
              };
            } else if (
              usiUtilisationRates?.length &&
              radcUtilisationRates?.length &&
              localDCUtilisationRates?.length &&
              !localCOEUtilisationRates?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiUtilisationInitialCompletedFields +
                    initialCompletedFields?.radcUtilisationInitialCompletedFields +
                    initialCompletedFields?.localDCUtilisationInitialCompletedFields) +
                  usiUtilisationRatesCompletedFields +
                  radcUtilisationRatesCompletedFields +
                  localDCUtilisationRatesCompletedFields,
              };
            } else if (
              usiUtilisationRates?.length &&
              radcUtilisationRates?.length &&
              localDCUtilisationRates?.length &&
              localCOEUtilisationRates?.length
            ) {
              return {
                ...item,
                completedFields:
                  item.completedFields -
                  (initialCompletedFields?.usiUtilisationInitialCompletedFields +
                    initialCompletedFields?.radcUtilisationInitialCompletedFields +
                    initialCompletedFields?.localDCUtilisationInitialCompletedFields +
                    initialCompletedFields?.localCOEUtilisationInitialCompletedFields) +
                  usiUtilisationRatesCompletedFields +
                  radcUtilisationRatesCompletedFields +
                  localDCUtilisationRatesCompletedFields +
                  localCOEUtilisationRatesCompletedFields,
              };
            } else {
              return item;
            }

          default:
            return item;
        }
      });
    }
    let dataObj = {
      fieldCountDetails: updatedData,
      percentCompletion:
        inputFormDataState?.InputFormProgressData?.percentCompletion,
      planSubmissionId:
        inputFormDataState?.InputFormProgressData?.planSubmissionId,
    };
    dispatch(setInputFormProgressDataLocal(dataObj));
  }, [
    saved,
    inputFormDataState?.InputFormProgressData,
    avgAnnualFTESalary,
    salaryPriceIncrease,
    yearlyUtilisationRate,
    fullTimeEquavalents,
    attritionRatePerGrade,
    promotionRatePerGrade,
    targetManagedHours,
    usiRateCard,
    radcRateCard,
    localDCRateCard,
    localCOERateCard,
    usiPriceIncreaseRates,
    radcPriceIncreaseRates,
    localDCPriceIncreaseRates,
    localCOEPriceIncreaseRates,
    usiUtilisationRates,
    radcUtilisationRates,
    localDCUtilisationRates,
    localCOEUtilisationRates,
    usiAnnualTotalHours,
    radcAnnualTotalHours,
    localDCAnnualTotalHours,
    localCOEAnnualTotalHours,
    initialCompletedFields,
  ]);

  useEffect(() => {
    setHeadCountsData({
      avgAnnualFTESalary,
      salaryPriceIncrease,
      yearlyUtilisationRate,
      fullTimeEquavalents,
      attritionRatePerGrade,
      promotionRatePerGrade,
      targetManagedHours,
      usiRateCard,
      radcRateCard,
      localDCRateCard,
      localCOERateCard,
      usiPriceIncreaseRates,
      radcPriceIncreaseRates,
      localDCPriceIncreaseRates,
      localCOEPriceIncreaseRates,
      usiUtilisationRates,
      radcUtilisationRates,
      localDCUtilisationRates,
      localCOEUtilisationRates,
      usiAnnualTotalHours,
      radcAnnualTotalHours,
      localDCAnnualTotalHours,
      localCOEAnnualTotalHours,
    });
  }, [
    avgAnnualFTESalary,
    salaryPriceIncrease,
    yearlyUtilisationRate,
    fullTimeEquavalents,
    attritionRatePerGrade,
    promotionRatePerGrade,
    targetManagedHours,
    usiRateCard,
    radcRateCard,
    localDCRateCard,
    localCOERateCard,
    usiPriceIncreaseRates,
    radcPriceIncreaseRates,
    localDCPriceIncreaseRates,
    localCOEPriceIncreaseRates,
    usiUtilisationRates,
    radcUtilisationRates,
    localDCUtilisationRates,
    localCOEUtilisationRates,
    usiAnnualTotalHours,
    radcAnnualTotalHours,
    localDCAnnualTotalHours,
    localCOEAnnualTotalHours,
  ]);

  return (
    <>
      <div
        className={`${BLOCK}`}
        style={{ width: !toggleNavigation ? "100%" : "75%" }}
        data-test="headCount-component"
        id={BLOCK}
      >
        <div className="scroll-add-remove">
          <div>
            <div className={`${BLOCK}__divider-header`}>
              <span className={`${BLOCK}__divider-header--title`}>
                <FormattedMessage id={`headcounts.main.title`} />
              </span>
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[0].refId}
            >
              <AvgAnnualFTESalary
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.annualAvgFTE}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={avgAnnualFTESalary}
                compareTableData={compareTableData?.avgAnnualFTESalary}
                setTableData={setAvgAnnualFTESalary}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[1].refId}
            >
              <SalaryPriceIncrease
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.salaryPriceIncrease}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={salaryPriceIncrease}
                compareTableData={compareTableData?.salaryPriceIncrease}
                setTableData={setSalaryPriceIncrease}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <div className={`${BLOCK}__divider-header`}>
              <span className={`${BLOCK}__divider-header--title`}>
                <FormattedMessage
                  id={`headcounts.main.bussiness.assumptions`}
                />
              </span>
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[2].refId}
            >
              <FullEimeEquivalents
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.fullTimeEq}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={fullTimeEquavalents}
                compareTableData={compareTableData?.fullTimeEquavalents}
                setTableData={setFullTimeEquavalents}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[3].refId}
            >
              <RatePerGrade
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.attrition}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                tableHeader={`headcounts.attrition.rate.per.grade`}
                tableIdMapping="AttritionRatePerGrade"
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={attritionRatePerGrade}
                compareTableData={compareTableData?.attritionRatePerGrade}
                setTableData={setAttritionRatePerGrade}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[4].refId}
            >
              <RatePerGrade
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.promotion}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                tableHeader={`headcounts.promotion.rate.per.grade`}
                tableIdMapping="PromotionRatePerGrade"
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={promotionRatePerGrade}
                compareTableData={compareTableData?.promotionRatePerGrade}
                setTableData={setPromotionRatePerGrade}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>

            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[5].refId}
            >
              <TargetManagedHoursPerRole
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.targetManagedHours}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={targetManagedHours}
                compareTableData={compareTableData?.targetManagedHours}
                setTableData={setTargetManagedHours}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <div className={`${BLOCK}__divider-header`}>
              <span className={`${BLOCK}__divider-header--title`}>
                <FormattedMessage id={`headcounts.main.rate.assumptions`} />
              </span>
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[6].refId}
            >
              <RateCards
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.rateCard}
                SelctedTableId={TABLE_MAPPINGS.rateCardUSI}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                tableHeader={`headcounts.usi.rate.cards`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.USI}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={usiRateCard}
                compareTableData={compareTableData?.usiRateCard}
                setTableData={setUsiRateCard}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[7].refId}
            >
              <RateCards
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.rateCard}
                SelctedTableId={TABLE_MAPPINGS.rateCardRADC}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                tableHeader={`headcounts.radc.rate.card`}
                margin_top={true}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.RADC}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={radcRateCard}
                compareTableData={compareTableData?.radcRateCard}
                setTableData={setRadcRateCard}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[8].refId}
            >
              <RateCards
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.rateCard}
                SelctedTableId={TABLE_MAPPINGS.rateCardLocalDC}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                tableHeader={`headcounts.localdc.rate.card`}
                margin_top={true}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.LOCAL_DC}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={localDCRateCard}
                compareTableData={compareTableData?.localDCRateCard}
                setTableData={setLocalDCRateCard}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[9].refId}
            >
              <RateCards
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.rateCard}
                SelctedTableId={TABLE_MAPPINGS.rateCardLocalCOE}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                tableHeader={`headcounts.localcoe.rate.card`}
                margin_top={true}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.LOCAL_COE}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={localCOERateCard}
                compareTableData={compareTableData?.localCOERateCard}
                setTableData={setLocalCoeRateCard}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[10].refId}
            >
              <PriceIncrease
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.priceIncreaseUSI}
                SelctedTableId={TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseUSI}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                tableHeader={`headcounts.usi.price.increase`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.USI}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={usiPriceIncreaseRates}
                compareTableData={compareTableData?.usiPriceIncreaseRates}
                setTableData={setUsiPriceIncreaseRates}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[11].refId}
            >
              <PriceIncrease
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.priceIncreaseRADC}
                SelctedTableId={TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseRADC}
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                tableHeader={`headcounts.radc.price.increase`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.RADC}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={radcPriceIncreaseRates}
                compareTableData={compareTableData?.radcPriceIncreaseRates}
                setTableData={setRadcPriceIncreaseRates}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[12].refId}
            >
              <PriceIncrease
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.priceIncreaseLocalDC}
                SelctedTableId={
                  TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseLocalDC
                }
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                tableHeader={`headcounts.localdc.price.increase`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.LOCAL_DC}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={localDCPriceIncreaseRates}
                compareTableData={compareTableData?.localDCPriceIncreaseRates}
                setTableData={setLocalDCPriceIncreaseRates}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[13].refId}
            >
              <PriceIncrease
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.priceIncreaeLocalCOE}
                SelctedTableId={
                  TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaeLocalCOE
                }
                setSaved={setSaved}
                showModal={setShowUpdateInputsPopup}
                tableHeader={`headcounts.localcoe.price.increase`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.LOCAL_COE}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={localCOEPriceIncreaseRates}
                compareTableData={compareTableData?.localCOEPriceIncreaseRates}
                setTableData={setLocalCoePriceIncreaseRates}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <div className={`${BLOCK}__divider-header`}>
              <span className={`${BLOCK}__divider-header--title`}>
                <FormattedMessage id={`headcounts.main.shape.assumptions`} />
              </span>
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[14].refId}
            >
              <AnnualTotalHours
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.hoursUSI}
                SelctedTableId={TABLE_MAPPINGS_HOURS.hoursUSI}
                planSubmissionId={planSubmissionId}
                showModal={setShowUpdateInputsPopup}
                tableHeader={`headcounts.usi.annual.total-hours`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.USI}
                setSaved={setSaved}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={usiAnnualTotalHours}
                compareTableData={compareTableData?.usiAnnualTotalHours}
                setTableData={setUsiAnnualTotalHours}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[15].refId}
            >
              <AnnualTotalHours
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.hoursRADC}
                SelctedTableId={TABLE_MAPPINGS_HOURS.hoursRADC}
                planSubmissionId={planSubmissionId}
                showModal={setShowUpdateInputsPopup}
                margin_top={true}
                tableHeader={`headcounts.radc.annual.total-hours`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.RADC}
                setSaved={setSaved}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={radcAnnualTotalHours}
                compareTableData={compareTableData?.radcAnnualTotalHours}
                setTableData={setRadcAnnualTotalHours}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[16].refId}
            >
              <AnnualTotalHours
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.hoursLocalDC}
                SelctedTableId={TABLE_MAPPINGS_HOURS.hoursLocalDC}
                planSubmissionId={planSubmissionId}
                showModal={setShowUpdateInputsPopup}
                margin_top={true}
                tableHeader={`headcounts.localDC.annual.total-hours`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.LOCAL_DC}
                setSaved={setSaved}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={localDCAnnualTotalHours}
                compareTableData={compareTableData?.localDCAnnualTotalHours}
                setTableData={setLocalDCAnnualTotalHours}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[17].refId}
            >
              <AnnualTotalHours
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.hoursLocalCOE}
                SelctedTableId={TABLE_MAPPINGS_HOURS.hoursLocalCOE}
                planSubmissionId={planSubmissionId}
                showModal={setShowUpdateInputsPopup}
                margin_top={true}
                tableHeader={`headcounts.localcoe.annual.total-hours`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.LOCAL_COE}
                setSaved={setSaved}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={localCOEAnnualTotalHours}
                compareTableData={compareTableData?.localCOEAnnualTotalHours}
                setTableData={setLocalCoeAnnualTotalHours}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[18].refId}
            >
              <UtilisationRate
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.utilRateUSI}
                SelctedTableId={TABLE_MAPPINGS_UTIL_RATE.utilRateUSI}
                showModal={setShowUpdateInputsPopup}
                margin_top={true}
                tableHeader={`headcounts.usi.utilisation.rate`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.USI}
                setSaved={setSaved}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={usiUtilisationRates}
                compareTableData={compareTableData?.usiUtilisationRates}
                setTableData={setUsiUtilisationRates}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[19].refId}
            >
              <UtilisationRate
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.utilRateRADC}
                SelctedTableId={TABLE_MAPPINGS_UTIL_RATE.utilRateRADC}
                showModal={setShowUpdateInputsPopup}
                margin_top={true}
                tableHeader={`headcounts.radc.utilisation.rates`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.RADC}
                setSaved={setSaved}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={radcUtilisationRates}
                compareTableData={compareTableData?.radcUtilisationRates}
                setTableData={setRadcUtilisationRates}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[20].refId}
            >
              <UtilisationRate
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.utilLocalDC}
                SelctedTableId={TABLE_MAPPINGS_UTIL_RATE.utilLocalDC}
                showModal={setShowUpdateInputsPopup}
                margin_top={true}
                tableHeader={`headcounts.localdc.utilisation.rates`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.LOCAL_DC}
                setSaved={setSaved}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={localDCUtilisationRates}
                compareTableData={compareTableData?.localDCUtilisationRates}
                setTableData={setLocalDCUtilisationRates}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
            <div
              className={`${BLOCK}__scroll-margin`}
              id={headCountTablesList[21].refId}
            >
              <UtilisationRate
                subHeadingBlock={BLOCK}
                tableId={TABLE_MAPPINGS.utilLocalCOE}
                SelctedTableId={TABLE_MAPPINGS_UTIL_RATE.utilLocalCOE}
                showModal={setShowUpdateInputsPopup}
                margin_top={true}
                tableHeader={`headcounts.localcoe.utilisation.rate`}
                deliveryCentreId={DELIVERY_CENTRE_ID_MAPPING.LOCAL_COE}
                setSaved={setSaved}
                showUpdateInputsPopup={showUpdateInputsPopup}
                headCountsDirtyTables={headCountsDirtyTables}
                setHeadCountsDirtyTables={setHeadCountsDirtyTables}
                tableData={localCOEUtilisationRates}
                compareTableData={compareTableData?.localCOEUtilisationRates}
                setTableData={setLocalCoeUtilisationRates}
                baseYear={
                  inputFormDataState?.metaData?.baseYear
                    ? inputFormDataState?.metaData?.baseYear
                    : ""
                }
                headCountTableIdList={headCountTableIdList}
                headCountsData={headCountsData}
                tableScrollIds={tableScrollIds}
                setTableScrolledIds={setTableScrolledIds}
              />
            </div>
          </div>
        </div>
      </div>

      {showUpdateInputsPopup && inputFormDataState.selectedCells.length > 0 && (
        <UpdateInputsModal
          setSaved={setSaved}
          showModal={setShowUpdateInputsPopup}
          selectedCells={inputFormDataState.selectedCells}
          dirtyTables={headCountsDirtyTables}
          setDirtyTables={setHeadCountsDirtyTables}
        />
      )}
    </>
  );
};

export default UpdatedHeadcounts;
