import { useState, useEffect } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import {
  DashBoardTablesSection,
  dashboardHeadCountsTableList,
} from "../../utils/constants";

type commonTable = {
  TableComponent: any;
  subTitle: string;
  TablesSection?: string;
  showCurrencyHeader?: boolean;
  showHours?: boolean;
  RefId?: string;
};

const CommonTable = ({
  TableComponent,
  subTitle,
  TablesSection,
  showCurrencyHeader = true,
  showHours = false,
  RefId,
}: commonTable) => {
  const BLOCK = "consolidatedTableData";
  const TABLE_BLOCK = "consolidationTables";
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  const dashboardTableState = useSelector(
    (state: RootStore) => state.dashboard
  );
  const [geographies, setGeographies] = useState<string[]>([]);
  const [currencyCode, setCurrencyCode] = useState<string>("");
  const [baseYear, setBaseYear] = useState<number>(0);
  useEffect(() => {
    if (
      consolidationData?.consolidationGeographiesSelected &&
      Object.keys(consolidationData?.consolidationGeographiesSelected).length &&
      consolidationData?.currency &&
      Object.keys(consolidationData?.currency).length
    ) {
      let currencyCode = `${consolidationData?.currency[0]}`.split(" - ")[0];

      const Geographies: any =
        consolidationData?.consolidationGeographiesSelected
          ?.childCheckBoxSelected;
      const SubGeographies: any =
        consolidationData?.consolidationGeographiesSelected
          .childSubConsolidationGeographyCheckBoxSelected &&
        Object.keys(
          consolidationData?.consolidationGeographiesSelected
            .childSubConsolidationGeographyCheckBoxSelected
        ).length
          ? consolidationData?.consolidationGeographiesSelected
              .childSubConsolidationGeographyCheckBoxSelected
          : null;
      let customGeographies = SubGeographies
        ? Geographies.concat(SubGeographies)
        : Geographies;
      setCurrencyCode(currencyCode);
      setGeographies(customGeographies);
    }
    if (
      dashboardTableState?.fiscalYearList &&
      Object.keys(dashboardTableState?.fiscalYearList).length &&
      dashboardTableState?.fiscalYearList[0]?.year
    ) {
      setBaseYear(dashboardTableState?.fiscalYearList[0]?.year);
    }
  }, [
    consolidationData?.consolidationGeographiesSelected,
    consolidationData?.currency,
    dashboardTableState?.fiscalYearList,
  ]);
  return (
    <div
      className={
        TablesSection == DashBoardTablesSection.HEADCOUNT &&
        RefId != dashboardHeadCountsTableList[6].tablePath &&
        RefId != dashboardHeadCountsTableList[7].tablePath
          ? `${BLOCK}__scroll_margin_head_counts`
          : `${BLOCK}__scroll_margin`
      }
      data-testid="commonTable"
      id={RefId}
    >
      <div className={`${BLOCK}__subHeader`}>
        <span className={`${BLOCK}__subHeader--title`}>
          {!TablesSection ? (
            <FormattedMessage id={subTitle} />
          ) : TablesSection == DashBoardTablesSection.GM_ANALYSIS ? (
            showCurrencyHeader ? (
              `${subTitle}  (${currencyCode} ${
                showHours
                  ? DashBoardTablesSection.HEADCOUNT
                  : DashBoardTablesSection.GM_ANALYSIS
              })`
            ) : (
              `${subTitle} (${
                showHours
                  ? DashBoardTablesSection.HEADCOUNT
                  : DashBoardTablesSection.GM_ANALYSIS
              })`
            )
          ) : TablesSection == DashBoardTablesSection.PROFIT_AND_LOSSES ? (
            showCurrencyHeader ? (
              `${subTitle}  (${currencyCode} ${DashBoardTablesSection.PROFIT_AND_LOSSES})`
            ) : (
              `${subTitle}`
            )
          ) : TablesSection == DashBoardTablesSection.HEADCOUNT ? (
            `${subTitle}`
          ) : null}
        </span>
      </div>
      <TableComponent
        geographies={geographies}
        currencyCode={currencyCode}
        baseYear={baseYear}
        innerClassName={TABLE_BLOCK}
      />
    </div>
  );
};

export default CommonTable;
