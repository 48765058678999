import Modal from "components/shared/modal/modal";
import { RootStore } from "services/store.service";
import { useState, useEffect, useRef, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  TABLE_MAPPINGS,
  TABLE_MAPPINGS_UTIL_RATE,
  TABLE_MAPPINGS_PRICE_INCREASE,
  TABLE_MAPPINGS_HOURS,
  NEGATIVE_ALLOW_OVERHEAD_TYPE_ID,
  neg_value_columns_id,
  TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS,
  TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES,
  productSegmentsMapping,
  LIBERATED_HOURS_TABLE_IDS,
  productSegmentsMappingWithYearlyUtilizationTableId,
} from "utils/constants";
import {
  emptyAllSelectedCells,
  validateInputValueLength,
  validateInputValueLengthPercentage,
  RevenueMultipleUpdate,
  PandLcostMultipleUpdate,
  FeePerExitHoursMultipleUpdate,
  IncreaseInHoursMultipleUpdate,
  SalaryPriceIncreaseMultipleUpdate,
  HeadCountsMultipleUpdate,
  RatePerGradeMultipleUpdate,
  PriceIncreaseMultipleUpdate,
  updateConvertionRatiosTableData,
  commonRevenueTableUpdate,
  commonTransformationAssumptionsMultipleUpdate,
  setConversionRatiosGetTableData,
  costAdoptionAssumptionsMultipleUpdate,
  deployementOfFilesMultipleUpdate,
  inflationTableUpdate,
  yearlyUtilizationMultipleUpdate,
} from "../../services/inputForm/inputFormSlice";
import { useParams } from "react-router-dom";
import { addToastMessage } from "services/common/commonsSlice";
import { useAPI as revenueAPI } from "../updatedRevenuePL/revenuePnlContextProvider";
import { useAPI as headCountsAPI } from "../updatedHeadCounts/headCountsContextProvider";
import { useAPI as transformationAssumptionsAPI } from "../updatedTransformationAssumptions/transformationAssumptionContextProvider";
import { useAPI as liberatedHoursAPI } from "../liberatedHours/liberatedHoursContextProvider";
import { useAPI as yearlyUtilizationAPI } from "../yearlyUtilization/yearlyUtilizationContextProvider";
import {
  handleBlurChange,
  inputValueReturn,
} from "components/shared/commons/globalFunctions";
import { inputValueValidation } from "utils/useInputForm";

type UpdateInputsModalProps = {
  showModal: any;
  selectedCells: any;
  setSaved: any;
  selectedTab?: number;
  dirtyTables?: any;
  setDirtyTables?: any;
};

const UpdateInputsModal = ({
  showModal,
  selectedCells,
  setSaved,
  selectedTab,
  dirtyTables,
  setDirtyTables,
}: UpdateInputsModalProps) => {
  const BLOCK = "updateInputsModal";
  const dispatch = useDispatch();
  const params: any = useParams();
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const [newValue, setNewValue] = useState<any>(null);
  const [year, setYear] = useState<any>(null);
  const [allowNegValue, setAllowNegValues] = useState<boolean>(false);
  const InputRef: any = useRef(null);
  const [tableId, setTableID] = useState<Number>();
  const [focus, setFocus] = useState<boolean>(false);

  const {
    revenuePnlData,
    setRevenuePnlData,
    revenuePriceIncrease,
    setRevenuePriceIncrease,
    profitsAndLossesTableData,
    setProfitsAndLossesTableData,
    winsNetRevenueData,
    setWinsNetRevenueTableData,
    lossesNetRevenueData,
    setLossesNetRevenueTableData,
    feePerExitData,
    setFeePerExitTableData,
    increaseInHoursData,
    setIncreaseInHoursTableData,
    exitPhasingData,
    setExitPhasingTableData,
    compareTableData,
    setMultipleErrorsRev,
    annualInflationData,
    setAnnualInflationTableData,
  }: any = revenueAPI() === null ? {} : revenueAPI();

  const {
    avgAnnualFTESalary,
    setAvgAnnualFTESalary,
    salaryPriceIncrease,
    setSalaryPriceIncrease,
    yearlyUtilisationRate,
    setYearlyUtilisationRate,
    fullTimeEquavalents,
    setFullTimeEquavalents,
    attritionRatePerGrade,
    setAttritionRatePerGrade,
    promotionRatePerGrade,
    setPromotionRatePerGrade,
    targetManagedHours,
    setTargetManagedHours,
    usiRateCard,
    setUsiRateCard,
    radcRateCard,
    setRadcRateCard,
    localDCRateCard,
    setLocalDCRateCard,
    localCOERateCard,
    setLocalCoeRateCard,
    usiPriceIncreaseRates,
    setUsiPriceIncreaseRates,
    radcPriceIncreaseRates,
    setRadcPriceIncreaseRates,
    localDCPriceIncreaseRates,
    setLocalDCPriceIncreaseRates,
    localCOEPriceIncreaseRates,
    setLocalCoePriceIncreaseRates,
    usiUtilisationRates,
    setUsiUtilisationRates,
    radcUtilisationRates,
    setRadcUtilisationRates,
    localDCUtilisationRates,
    setLocalDCUtilisationRates,
    localCOEUtilisationRates,
    setLocalCoeUtilisationRates,
    usiAnnualTotalHours,
    setUsiAnnualTotalHours,
    radcAnnualTotalHours,
    setRadcAnnualTotalHours,
    localDCAnnualTotalHours,
    setLocalDCAnnualTotalHours,
    localCOEAnnualTotalHours,
    setLocalCoeAnnualTotalHours,
    compareTableData: headCountCompareTableData,
    setMultipleErrorsHC,
  }: any = headCountsAPI() === null ? {} : headCountsAPI();

  const {
    costAssumptionCenterData,
    setCostAssumptionCenterData,
    deployementAndLiberatedHoursData,
    setDeployementAndLiberatedHoursData,
    compareTableData: liberatedHoursCompareTableData,
  }: any = liberatedHoursAPI() === null ? {} : liberatedHoursAPI();

  const {
    transformationAssumptionsTableData,
    setTransformationAssumptionsTableData,
    setMultipleErrors,
  }: any =
    transformationAssumptionsAPI() === null
      ? {}
      : transformationAssumptionsAPI();

  const {
    compareTablesData,
    yearlyUtilizationTablesData,
    setYearlyUtilizationTablesData,
    configToggle,
  }: any = yearlyUtilizationAPI() === null ? {} : yearlyUtilizationAPI();

  useEffect(() => {
    setYear(params?.subsection?.match(/\d+/)?.[0]);
  }, [params]);

  useEffect(() => {
    if (InputRef.current) {
      InputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    let CheckTheNegitiveValue: any = [];
    let tableId: any;
    if (selectedCells && Object.keys(selectedCells).length) {
      selectedCells.map((item: any) => {
        const splitSelectedItem = item?.split("#");
        CheckTheNegitiveValue.push(
          determineNegValuesAllowed(splitSelectedItem[1], splitSelectedItem[0])
        );
        tableId = splitSelectedItem[0];
      });
      setTableID(Number(tableId));
      let AllowNegValues: any = [];
      let NotAllowNegValues: any = [];
      for (let item of CheckTheNegitiveValue) {
        if (item) {
          AllowNegValues.push(item);
        } else {
          NotAllowNegValues.push(item);
        }
      }
      if (NotAllowNegValues?.length > 0) {
        setAllowNegValues(false);
      } else if (NotAllowNegValues?.length === 0) {
        setAllowNegValues(true);
      }
    }
  }, [selectedCells]);
  const determineNegValuesAllowed = (currId: string, tableId: number) => {
    if (
      tableId == TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaeLocalCOE ||
      tableId == TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseLocalDC ||
      tableId == TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseRADC ||
      tableId == TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseUSI ||
      tableId == TABLE_MAPPINGS.salaryPriceIncrease ||
      tableId == TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costOmnia ||
      tableId == TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costLEVVIA ||
      tableId == TABLE_MAPPINGS.annualinflation
    ) {
      return true;
    } else if (
      tableId == TABLE_MAPPINGS.plCosts &&
      currId === NEGATIVE_ALLOW_OVERHEAD_TYPE_ID
    ) {
      return true;
    } else if (
      tableId == TABLE_MAPPINGS.omniaTransformation ||
      tableId == TABLE_MAPPINGS.omniaLevvieTransformation ||
      tableId == TABLE_MAPPINGS.omniaTier5Transformation ||
      tableId == TABLE_MAPPINGS.omniaAssuranceTransformation ||
      tableId == TABLE_MAPPINGS.omniaOtherTransformation
    ) {
      const values = Object.values(neg_value_columns_id);
      for (let i = 0; i < values.length; i++) {
        if (values[i] === currId) return true;
      }
      return false;
    } else {
      return false;
    }
  };

  const reversedObject: any = {};

  for (const key in productSegmentsMappingWithYearlyUtilizationTableId) {
    const value = productSegmentsMappingWithYearlyUtilizationTableId[key];
    reversedObject[value] = parseInt(key);
  }

  const handleClose = () => {
    showModal(false);
    dispatch(emptyAllSelectedCells(true));
  };

  const onSubmit = () => {
    setSaved(false);
    showModal(false);

    updateMultipleInputs(selectedCells[0].split("#")[0]);

    dispatch(emptyAllSelectedCells(true));
  };

  const updateMultipleInputs = (tableID: any) => {
    if (tableID) {
      switch (parseInt(tableID)) {
        case TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costOmnia:
          costAdoptionAssumptionsMultipleUpdate(
            costAssumptionCenterData[
              productSegmentsMapping[
                inputFormDataState?.selectedProductSegment?.id
              ]
            ],
            setCostAssumptionCenterData,
            newValue,
            selectedCells,
            TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costOmnia,
            inputFormDataState?.selectedProductSegment?.id,
            liberatedHoursCompareTableData?.costAssumptionCenterData[
              productSegmentsMapping[
                inputFormDataState?.selectedProductSegment?.id
              ]
            ],
            dirtyTables,
            setDirtyTables,
            LIBERATED_HOURS_TABLE_IDS.COST_ADOPTION_ASSUMPTIONS,
            setMultipleErrors
          );
          break;
        case TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costLEVVIA:
          costAdoptionAssumptionsMultipleUpdate(
            costAssumptionCenterData[
              productSegmentsMapping[
                inputFormDataState?.selectedProductSegment?.id
              ]
            ],
            setCostAssumptionCenterData,
            newValue,
            selectedCells,
            TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costLEVVIA,
            inputFormDataState?.selectedProductSegment?.id,
            liberatedHoursCompareTableData?.costAssumptionCenterData[
              productSegmentsMapping[
                inputFormDataState?.selectedProductSegment?.id
              ]
            ],
            dirtyTables,
            setDirtyTables,
            LIBERATED_HOURS_TABLE_IDS.COST_ADOPTION_ASSUMPTIONS,
            setMultipleErrors
          );
          break;
        case TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES.deployementOmnia:
          deployementOfFilesMultipleUpdate(
            deployementAndLiberatedHoursData[
              productSegmentsMapping[
                inputFormDataState?.selectedProductSegment?.id
              ]
            ],
            setDeployementAndLiberatedHoursData,
            newValue,
            selectedCells,
            TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES.deployementOmnia,
            inputFormDataState?.selectedProductSegment?.id,
            liberatedHoursCompareTableData?.deployementAndLiberatedHoursData[
              productSegmentsMapping[
                inputFormDataState?.selectedProductSegment?.id
              ]
            ],
            dirtyTables,
            setDirtyTables,
            LIBERATED_HOURS_TABLE_IDS.DEPLOYEMENT_HOURS,
            setMultipleErrors
          );
          break;
        case TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES.deployementLEVVIA:
          deployementOfFilesMultipleUpdate(
            deployementAndLiberatedHoursData[
              productSegmentsMapping[
                inputFormDataState?.selectedProductSegment?.id
              ]
            ],
            setDeployementAndLiberatedHoursData,
            newValue,
            selectedCells,
            TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES.deployementLEVVIA,
            inputFormDataState?.selectedProductSegment?.id,
            liberatedHoursCompareTableData?.deployementAndLiberatedHoursData[
              productSegmentsMapping[
                inputFormDataState?.selectedProductSegment?.id
              ]
            ],
            dirtyTables,
            setDirtyTables,
            LIBERATED_HOURS_TABLE_IDS.DEPLOYEMENT_HOURS,
            setMultipleErrors
          );
          break;

        case TABLE_MAPPINGS.fy23Rev:
          dispatch(
            RevenueMultipleUpdate(
              revenuePnlData,
              setRevenuePnlData,
              newValue,
              selectedCells,
              compareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.fy23Rev,
              setMultipleErrorsRev
            )
          );
          break;
        case TABLE_MAPPINGS.annualinflation:
          dispatch(
            inflationTableUpdate(
              annualInflationData,
              setAnnualInflationTableData,
              newValue,
              selectedCells,
              compareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.annualinflation
            )
          );
          break;
        case TABLE_MAPPINGS.plCosts:
          dispatch(
            PandLcostMultipleUpdate(
              profitsAndLossesTableData,
              setProfitsAndLossesTableData,
              newValue,
              selectedCells,
              compareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.plCosts,
              setMultipleErrorsRev
            )
          );
          break;
        case TABLE_MAPPINGS.winsNetRev:
          dispatch(
            commonRevenueTableUpdate(
              winsNetRevenueData,
              setWinsNetRevenueTableData,
              newValue,
              selectedCells,
              compareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.winsNetRev
            )
          );
          break;
        case TABLE_MAPPINGS.lossesNetRev:
          dispatch(
            commonRevenueTableUpdate(
              lossesNetRevenueData,
              setLossesNetRevenueTableData,
              newValue,
              selectedCells,
              compareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.lossesNetRev
            )
          );
          break;
        case TABLE_MAPPINGS.feePerExit:
          dispatch(
            FeePerExitHoursMultipleUpdate(
              feePerExitData,
              setFeePerExitTableData,
              newValue,
              selectedCells,
              compareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.feePerExit
            )
          );
          break;
        case TABLE_MAPPINGS.exitPhasing:
          dispatch(
            commonRevenueTableUpdate(
              exitPhasingData,
              setExitPhasingTableData,
              newValue,
              selectedCells,
              compareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.exitPhasing
            )
          );
          break;
        case TABLE_MAPPINGS.revPriceIncrease:
          dispatch(
            commonRevenueTableUpdate(
              revenuePriceIncrease,
              setRevenuePriceIncrease,
              newValue,
              selectedCells,
              compareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.revPriceIncrease
            )
          );
          break;
        case TABLE_MAPPINGS.increaseHoursDeliver:
          dispatch(
            IncreaseInHoursMultipleUpdate(
              increaseInHoursData,
              setIncreaseInHoursTableData,
              newValue,
              selectedCells,
              compareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.increaseHoursDeliver
            )
          );
          break;
        case TABLE_MAPPINGS.omniaYearlyUtilization:
          dispatch(
            yearlyUtilizationMultipleUpdate(
              yearlyUtilizationTablesData,
              setYearlyUtilizationTablesData,
              newValue,
              selectedCells,
              compareTablesData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.omniaYearlyUtilization,
              reversedObject[TABLE_MAPPINGS.omniaYearlyUtilization],
              configToggle
            )
          );
          break;
        case TABLE_MAPPINGS.LevviaYearlyUtilization:
          dispatch(
            yearlyUtilizationMultipleUpdate(
              yearlyUtilizationTablesData,
              setYearlyUtilizationTablesData,
              newValue,
              selectedCells,
              compareTablesData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.LevviaYearlyUtilization,
              reversedObject[TABLE_MAPPINGS.LevviaYearlyUtilization],
              configToggle
            )
          );
          break;
        case TABLE_MAPPINGS.Tier5YearlyUtilization:
          dispatch(
            yearlyUtilizationMultipleUpdate(
              yearlyUtilizationTablesData,
              setYearlyUtilizationTablesData,
              newValue,
              selectedCells,
              compareTablesData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.Tier5YearlyUtilization,
              reversedObject[TABLE_MAPPINGS.Tier5YearlyUtilization],
              configToggle
            )
          );
          break;
        case TABLE_MAPPINGS.AssuranceYearlyUtilization:
          dispatch(
            yearlyUtilizationMultipleUpdate(
              yearlyUtilizationTablesData,
              setYearlyUtilizationTablesData,
              newValue,
              selectedCells,
              compareTablesData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.AssuranceYearlyUtilization,
              reversedObject[TABLE_MAPPINGS.AssuranceYearlyUtilization],
              configToggle
            )
          );
          break;
        case TABLE_MAPPINGS.OtherYearlyUtilization:
          dispatch(
            yearlyUtilizationMultipleUpdate(
              yearlyUtilizationTablesData,
              setYearlyUtilizationTablesData,
              newValue,
              selectedCells,
              compareTablesData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.OtherYearlyUtilization,
              reversedObject[TABLE_MAPPINGS.OtherYearlyUtilization],
              configToggle
            )
          );
          break;
        case TABLE_MAPPINGS.annualAvgFTE:
          dispatch(
            HeadCountsMultipleUpdate(
              avgAnnualFTESalary,
              setAvgAnnualFTESalary,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.annualAvgFTE
            )
          );
          break;
        case TABLE_MAPPINGS.salaryPriceIncrease:
          dispatch(
            SalaryPriceIncreaseMultipleUpdate(
              salaryPriceIncrease,
              setSalaryPriceIncrease,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.salaryPriceIncrease
            )
          );
          break;
        case TABLE_MAPPINGS.yearlyUtilRate:
          dispatch(
            HeadCountsMultipleUpdate(
              yearlyUtilisationRate,
              setYearlyUtilisationRate,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.yearlyUtilRate
            )
          );
          break;
        case TABLE_MAPPINGS.fullTimeEq:
          dispatch(
            HeadCountsMultipleUpdate(
              fullTimeEquavalents,
              setFullTimeEquavalents,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.fullTimeEq
            )
          );
          break;
        case TABLE_MAPPINGS.attrition:
          dispatch(
            RatePerGradeMultipleUpdate(
              attritionRatePerGrade,
              setAttritionRatePerGrade,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.attrition,
              setMultipleErrorsHC
            )
          );
          break;
        case TABLE_MAPPINGS.promotion:
          dispatch(
            RatePerGradeMultipleUpdate(
              promotionRatePerGrade,
              setPromotionRatePerGrade,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.promotion,
              setMultipleErrorsHC
            )
          );
          break;
        case TABLE_MAPPINGS.targetManagedHours:
          dispatch(
            HeadCountsMultipleUpdate(
              targetManagedHours,
              setTargetManagedHours,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.targetManagedHours
            )
          );
          break;
        case TABLE_MAPPINGS.rateCardUSI:
          dispatch(
            HeadCountsMultipleUpdate(
              usiRateCard,
              setUsiRateCard,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.rateCardUSI
            )
          );
          break;
        case TABLE_MAPPINGS.rateCardRADC:
          dispatch(
            HeadCountsMultipleUpdate(
              radcRateCard,
              setRadcRateCard,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.rateCardRADC
            )
          );
          break;
        case TABLE_MAPPINGS.rateCardLocalDC:
          dispatch(
            HeadCountsMultipleUpdate(
              localDCRateCard,
              setLocalDCRateCard,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.rateCardLocalDC
            )
          );
          break;
        case TABLE_MAPPINGS.rateCardLocalCOE:
          dispatch(
            HeadCountsMultipleUpdate(
              localCOERateCard,
              setLocalCoeRateCard,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS.rateCardLocalCOE
            )
          );
          break;
        case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseUSI:
          dispatch(
            PriceIncreaseMultipleUpdate(
              usiPriceIncreaseRates,
              setUsiPriceIncreaseRates,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseUSI
            )
          );
          break;
        case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseRADC:
          dispatch(
            PriceIncreaseMultipleUpdate(
              radcPriceIncreaseRates,
              setRadcPriceIncreaseRates,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseRADC
            )
          );
          break;
        case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseLocalDC:
          dispatch(
            PriceIncreaseMultipleUpdate(
              localDCPriceIncreaseRates,
              setLocalDCPriceIncreaseRates,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseLocalDC
            )
          );
          break;
        case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaeLocalCOE:
          dispatch(
            PriceIncreaseMultipleUpdate(
              localCOEPriceIncreaseRates,
              setLocalCoePriceIncreaseRates,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaeLocalCOE
            )
          );
          break;

        case TABLE_MAPPINGS_HOURS.hoursUSI:
          dispatch(
            HeadCountsMultipleUpdate(
              usiAnnualTotalHours,
              setUsiAnnualTotalHours,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_HOURS.hoursUSI
            )
          );
          break;
        case TABLE_MAPPINGS_HOURS.hoursRADC:
          dispatch(
            HeadCountsMultipleUpdate(
              radcAnnualTotalHours,
              setRadcAnnualTotalHours,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_HOURS.hoursRADC
            )
          );
          break;
        case TABLE_MAPPINGS_HOURS.hoursLocalDC:
          dispatch(
            HeadCountsMultipleUpdate(
              localDCAnnualTotalHours,
              setLocalDCAnnualTotalHours,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_HOURS.hoursLocalDC
            )
          );
          break;
        case TABLE_MAPPINGS_HOURS.hoursLocalCOE:
          dispatch(
            HeadCountsMultipleUpdate(
              localCOEAnnualTotalHours,
              setLocalCoeAnnualTotalHours,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_HOURS.hoursLocalCOE
            )
          );
          break;

        case TABLE_MAPPINGS_UTIL_RATE.utilRateUSI:
          dispatch(
            HeadCountsMultipleUpdate(
              usiUtilisationRates,
              setUsiUtilisationRates,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_UTIL_RATE.utilRateUSI
            )
          );
          break;
        case TABLE_MAPPINGS_UTIL_RATE.utilRateRADC:
          dispatch(
            HeadCountsMultipleUpdate(
              radcUtilisationRates,
              setRadcUtilisationRates,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_UTIL_RATE.utilRateRADC
            )
          );
          break;
        case TABLE_MAPPINGS_UTIL_RATE.utilLocalDC:
          dispatch(
            HeadCountsMultipleUpdate(
              localDCUtilisationRates,
              setLocalDCUtilisationRates,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_UTIL_RATE.utilLocalDC
            )
          );
          break;
        case TABLE_MAPPINGS_UTIL_RATE.utilLocalCOE:
          dispatch(
            HeadCountsMultipleUpdate(
              localCOEUtilisationRates,
              setLocalCoeUtilisationRates,
              newValue,
              selectedCells,
              headCountCompareTableData,
              dirtyTables,
              setDirtyTables,
              TABLE_MAPPINGS_UTIL_RATE.utilLocalCOE
            )
          );
          break;
        case TABLE_MAPPINGS.conversionRatio:
          dispatch(
            updateConvertionRatiosTableData(
              inputFormDataState.conversionRatiosGetData,
              selectedCells,
              newValue,
              TABLE_MAPPINGS.conversionRatio,
              setConversionRatiosGetTableData,
              setMultipleErrors
            )
          );
          break;
        case TABLE_MAPPINGS.omniaTransformation:
          commonTransformationAssumptionsMultipleUpdate(
            year,
            selectedTab,
            newValue,
            transformationAssumptionsTableData,
            selectedCells,
            dirtyTables,
            setTransformationAssumptionsTableData,
            setDirtyTables,
            TABLE_MAPPINGS.omniaTransformation,
            setMultipleErrors
          );
          break;
        case TABLE_MAPPINGS.omniaLevvieTransformation:
          commonTransformationAssumptionsMultipleUpdate(
            year,
            selectedTab,
            newValue,
            transformationAssumptionsTableData,
            selectedCells,
            dirtyTables,
            setTransformationAssumptionsTableData,
            setDirtyTables,
            TABLE_MAPPINGS.omniaLevvieTransformation,
            setMultipleErrors
          );
          break;
        case TABLE_MAPPINGS.omniaTier5Transformation:
          commonTransformationAssumptionsMultipleUpdate(
            year,
            selectedTab,
            newValue,
            transformationAssumptionsTableData,
            selectedCells,
            dirtyTables,
            setTransformationAssumptionsTableData,
            setDirtyTables,
            TABLE_MAPPINGS.omniaTier5Transformation,
            setMultipleErrors
          );
          break;
        case TABLE_MAPPINGS.omniaAssuranceTransformation:
          commonTransformationAssumptionsMultipleUpdate(
            year,
            selectedTab,
            newValue,
            transformationAssumptionsTableData,
            selectedCells,
            dirtyTables,
            setTransformationAssumptionsTableData,
            setDirtyTables,
            TABLE_MAPPINGS.omniaAssuranceTransformation,
            setMultipleErrors
          );
          break;
        case TABLE_MAPPINGS.omniaOtherTransformation:
          commonTransformationAssumptionsMultipleUpdate(
            year,
            selectedTab,
            newValue,
            transformationAssumptionsTableData,
            selectedCells,
            dirtyTables,
            setTransformationAssumptionsTableData,
            setDirtyTables,
            TABLE_MAPPINGS.omniaOtherTransformation,
            setMultipleErrors
          );
          break;

        default:
          break;
      }
    }
  };
  const [prevValue, setPrevValue] = useState<any>("");

  const onchangeHandler = (e: any, focus: boolean = false) => {
    setPrevValue(e.target.value);
    let newVal = inputValueValidation(e.target.value, prevValue, setPrevValue);

    if (parseInt(e.target.value) < 0 && !allowNegValue) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="neg.values.not.allowed" />,
        })
      );
      return;
    } else {
      let validationFunctionSelector: boolean =
        tableId &&
        (tableId === TABLE_MAPPINGS.exitPhasing ||
          tableId === TABLE_MAPPINGS.revPriceIncrease ||
          tableId === TABLE_MAPPINGS.increaseHoursDeliver ||
          tableId === TABLE_MAPPINGS.salaryPriceIncrease ||
          tableId === TABLE_MAPPINGS.yearlyUtilRate ||
          tableId === TABLE_MAPPINGS.omniaYearlyUtilization ||
          tableId === TABLE_MAPPINGS.LevviaYearlyUtilization ||
          tableId === TABLE_MAPPINGS.Tier5YearlyUtilization ||
          tableId === TABLE_MAPPINGS.AssuranceYearlyUtilization ||
          tableId === TABLE_MAPPINGS.OtherYearlyUtilization ||
          tableId === TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseUSI ||
          tableId === TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseRADC ||
          tableId === TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseLocalDC ||
          tableId === TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaeLocalCOE ||
          tableId === TABLE_MAPPINGS_UTIL_RATE.utilRateUSI ||
          tableId === TABLE_MAPPINGS_UTIL_RATE.utilRateRADC ||
          tableId === TABLE_MAPPINGS_UTIL_RATE.utilLocalDC ||
          tableId === TABLE_MAPPINGS_UTIL_RATE.utilLocalCOE ||
          tableId === TABLE_MAPPINGS.attrition ||
          tableId === TABLE_MAPPINGS.promotion ||
          tableId ===
            TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costOmnia ||
          tableId ===
            TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costLEVVIA ||
          tableId === TABLE_MAPPINGS.conversionRatio ||
          tableId === TABLE_MAPPINGS.annualinflation)
          ? true
          : false;
      let value;
      if (focus) {
        value = validationFunctionSelector
          ? validateInputValueLengthPercentage(
              newVal,
              prevValue,
              focus,
              tableId === TABLE_MAPPINGS.annualinflation ? tableId : null
            )
          : validateInputValueLength(newVal, prevValue, focus);
      } else {
        value = validationFunctionSelector
          ? validateInputValueLengthPercentage(
              newVal,
              prevValue,
              false,
              tableId === TABLE_MAPPINGS.annualinflation ? tableId : null
            )
          : validateInputValueLength(newVal, prevValue);
      }
      setNewValue(value);
    }
  };
  return (
    <div data-test="component-updateModel">
      {selectedCells && Object.keys(selectedCells).length > 1 && (
        <Modal
          show={true}
          header={"change.inputs"}
          handleClose={handleClose}
          config={false}
          hideBackgroud={false}
        >
          <div className={BLOCK}>
            <div className={`${BLOCK}__content`}>
              <div className={`${BLOCK}__content--text`}>
                <FormattedMessage
                  id={`update.mulitple.inputs.pop.up.message`}
                />
                <div className={`${BLOCK}__content--input-container`}>
                  <input
                    className={`${BLOCK}__content--inputbox`}
                    data-test="component-inputbox"
                    type="text"
                    ref={InputRef}
                    style={{ textAlign: "right" }}
                    value={inputValueReturn(newValue, focus)}
                    onChange={onchangeHandler}
                    onKeyDown={(evt) =>
                      (evt.key === "e" ||
                        evt.key === "E" ||
                        evt.key === "+" ||
                        (evt.key === "-" && !allowNegValue) ||
                        evt.keyCode === 38 ||
                        evt.keyCode === 40 ||
                        evt.key === ",") &&
                      evt.preventDefault()
                    }
                    onBlur={(e) => {
                      handleBlurChange(e);
                      onchangeHandler(e, true);
                      setFocus(false);
                    }}
                    onWheel={(e) => e.currentTarget.blur()}
                    placeholder={"-"}
                    onFocus={() => {
                      setFocus(true);
                    }}
                  />
                </div>
              </div>
              <div className={`${BLOCK}__content--footer`}>
                <div className={`${BLOCK}__content--footer--actions--warning`}>
                  <button
                    className={`${BLOCK}__content--footer--actions--cancel`}
                    data-test="cancel-button"
                    onClick={() => handleClose()}
                  >
                    <FormattedMessage id={`input.form.submit.review.cancel`} />
                  </button>
                  <button
                    className={`${BLOCK}__content--footer--actions--confirm`}
                    data-test="save-btn"
                    onClick={() => onSubmit()}
                  >
                    <FormattedMessage id={`submit.multiple.inputs.pop.up`} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UpdateInputsModal;
