import { useState, useRef, createContext, memo } from "react";
import { useDispatch } from "react-redux";
import Table from "../shared/table/table";
import Icon from "../shared/icon/icon";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useAreaSelection } from "utils/area-selection";
import {
  revenueUpdateTables,
  getRevenueRowItems,
  inputValueValidation,
} from "utils/useInputForm";

const SelectionContext = createContext<DOMRect | null>(null);

const NetRevenueTable = ({
  currency,
  setSaved,
  tableId,
  showModal,
  subHeadingBlock,
  baseYear,
  subTitle,
  showCurrencyHeader,
  showUpdateInputsPopup,
  revenueDirtyTables,
  setRevenueDirtyTables,
  tableData,
  compareTableData,
  setTableData,
  revenueTableIdList,
  revenueData,
}: any) => {
  const BLOCK = "headcountsTable";
  const dispatch = useDispatch();
  const [prevValue, setPrevValue] = useState<any>("");
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });

  const getTableHeaders = () => {
    let dynamicTableHeaders: any =
      tableData &&
      tableData?.length > 0 &&
      Object.keys(tableData[0] || {})
        .filter(Number)
        .map((item) => {
          return {
            text: `FY${item}`,
            isFormattedText: false,
          };
        });

    const tableHeaders = dynamicTableHeaders &&
      dynamicTableHeaders.length > 0 && [
        {
          text: "revenue-pl.price.increase.main.header",
          isFormattedText: true,
        },
        ...dynamicTableHeaders,
      ];

    return tableHeaders;
  };

  const handleOnChange = (
    inputVal: any,
    segmentId: any,
    columnId: any,
    roundOff: boolean = false
  ) => {
    setPrevValue(inputVal);
    let newVal = inputValueValidation(inputVal, prevValue, setPrevValue, true);

    revenueUpdateTables(
      segmentId,
      newVal,
      columnId,
      roundOff,
      tableData,
      setTableData,
      compareTableData,
      revenueDirtyTables,
      setRevenueDirtyTables,
      tableId,
      prevValue,
      true
    );
  };
  const FocusChage = (e: any, segmentId: number, id: number) => {
    setSaved(false);
    handleOnChange(e, segmentId, id, true);
  };

  return (
    <>
      <div data-test="priceIncrease" style={{ marginTop: 30 }}>
        <div className={`${subHeadingBlock}__sub-header`}>
          <div className={`${subHeadingBlock}__sub-header--title`}>
            {tableId === 1 ? (
              subTitle
            ) : (
              <>
                <FormattedMessage id={subTitle} />{" "}
                {currency && showCurrencyHeader ? `(${currency} 'M)` : ""}
              </>
            )}
          </div>
        </div>
        <SelectionContext.Provider value={selection}>
          <div
            ref={selectContainerRef}
            id={tableId}
            data-test="component-winsNetRevenue"
          >
            {tableData && tableData.length === 0 ? (
              <div className={`${subHeadingBlock}__spinner-container`}>
                <Icon
                  name={"loading"}
                  width={50}
                  height={50}
                  className={`${subHeadingBlock}--loading`}
                />
              </div>
            ) : (
              <Table
                className={`${BLOCK}--innerWrapper`}
                headerItems={getTableHeaders()}
                rowItems={getRevenueRowItems(
                  tableData,
                  FocusChage,
                  handleOnChange,
                  baseYear,
                  tableId,
                  revenueTableIdList,
                  revenueData,
                  BLOCK,
                  setPrevValue,
                  true
                )}
                innerClassName={BLOCK}
                selection={selection}
                tableId={tableId}
              />
            )}
            <div className={`revenue__subHeaderlargeNote--largeNote`}>
              <p style={{ margin: 0 }}>
                <span>
                  <strong>
                    <FormattedMessage id="revenue-pl-note" />
                  </strong>
                  <FormattedMessage id="revenue-pl.revenue.increase.net.note" />
                </span>
              </p>
            </div>
          </div>
        </SelectionContext.Provider>
      </div>
    </>
  );
};

export default memo(NetRevenueTable);
