import { useState, useRef, createContext } from "react";
import Table from "../shared/table/table";
import { useDispatch } from "react-redux";
import { addToastMessage } from "services/common/commonsSlice";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  delieveryCenterIDKeys,
  headCountsTableGradeLevels,
  neg_value_columns_id,
  PERCENTAGE_ABOVE_100_ERROR,
  transformationsHeadersIds,
} from "utils/constants";
import Icon from "components/shared/icon/icon";
import { useAreaSelection } from "utils/area-selection";
import { useAPI } from "../updatedTransformationAssumptions/transformationAssumptionContextProvider";
import {
  useCalculateTotalValueRows,
  updateTransformationTables,
} from "utils/useInputForm";

type TransFormationTable = {
  tableData: any;
  loader: any;
  transformationsHeaders: any;
  setSaved: any;
  year: string;
  tableId: any;
  selectedTab: Number;
  transformationDirtyTables: any;
  setTransformationDirtyTables: Function;
  tableName: String;
  showModal?: any;
  setExpandedRowIds: Function;
  expandedRowIds?: any;
  showUpdateInputsPopup: boolean;
};

const SelectionContext = createContext<DOMRect | null>(null);

const TransformationTable = ({
  tableData,
  loader,
  transformationsHeaders,
  setSaved,
  year,
  tableId,
  selectedTab,
  transformationDirtyTables,
  setTransformationDirtyTables,
  tableName,
  showModal,
  setExpandedRowIds,
  expandedRowIds,
  showUpdateInputsPopup,
}: TransFormationTable) => {
  const BLOCK = "transformationTable";
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });

  const {
    setTransformationAssumptionsTableData,
    transformationAssumptionsTableDataCompare,
  }: any = useAPI();

  const dispatch = useDispatch();

  const totalValueRow = useCalculateTotalValueRows(tableData);

  const getTableHeaders = () => {
    let dynamicTableHeaders: any =
      tableData &&
      Object.keys(tableData || {}).length > 0 &&
      Object.keys(tableData[0])
        .filter(Number)
        .map((item: any) => {
          if (item in transformationsHeaders) {
            return {
              text: transformationsHeaders[item],
              isFormattedText: false,
            };
          }
        });

    const tableHeaders = dynamicTableHeaders &&
      dynamicTableHeaders.length > 0 && [
        {
          text: transformationsHeaders[0],
          isFormattedText: false,
        },
        ...dynamicTableHeaders,
      ];

    return tableHeaders;
  };

  const [prevValue, setPrevValue] = useState<any>("");

  const handleOnChange = (
    dataIndex: number,
    inputVal: any,
    columnId: any,
    allowNegValues: boolean,
    roundOff: boolean = false
  ) => {
    if (parseInt(inputVal) < 0 && !allowNegValues) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="neg.values.not.allowed" />,
        })
      );
      return;
    }

    setPrevValue(inputVal);

    updateTransformationTables(
      dataIndex,
      inputVal,
      columnId,
      allowNegValues,
      roundOff,
      tableData,
      setTransformationAssumptionsTableData,
      transformationAssumptionsTableDataCompare,
      transformationDirtyTables,
      setTransformationDirtyTables,
      selectedTab,
      year,
      setPrevValue,
      prevValue
    );
  };

  const FocusChage = (inputVal: any, index: any, columnId: any) => {
    setSaved(false);
    handleOnChange(
      index,
      inputVal,
      columnId,
      determineNegValuesAllowed(columnId),
      true
    );
  };

  const determineNegValuesAllowed = (currId: string) => {
    const values = Object.values(neg_value_columns_id);
    for (let i = 0; i < values.length; i++) {
      if (values[i] === currId) return true;
    }

    return false;
  };

  const handleOnClickIcon = (value: any, deliveryCenterRowId: any) => {
    let id = value.deliveryCenterId;
    Array(7)
      .fill("1")
      .map((item, index) => {
        if (
          document!.getElementById(`${index + 1}-${id}`)!.style.display ===
          "none"
        ) {
          document!.getElementById(`${index + 1}-${id}`)!.style.display =
            "table-row";
        } else {
          document!.getElementById(`${index + 1}-${id}`)!.style.display =
            "none";
        }
      });

    let myIds = expandedRowIds;

    const index = myIds.indexOf(deliveryCenterRowId);

    if (index > -1) {
      myIds.splice(index, 1);
      setExpandedRowIds([...myIds]);
    } else {
      setExpandedRowIds((prevVal: any) => [...prevVal, deliveryCenterRowId]);
    }
  };

  const handleEnter = (evt: any, keyIdentifier: any) => {
    if (evt.key === "Enter") {
      let localKeyIdentifier: any = [];
      let tableId = +selectedTab + 40;

      tableData?.map((item: any, index: number) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            Object.keys(item?.disabled || {}).length > 0 &&
              !item?.disabled[id] &&
              localKeyIdentifier.push(`${tableId}#${id}#${index}`);
          });
      });

      localKeyIdentifier.sort((a: any, b: any) => {
        return a.split("#")[1] - b.split("#")[1];
      });

      let index = localKeyIdentifier.findIndex(
        (item: any) => item === keyIdentifier
      );
      let element;
      if (index > -1) {
        element = document.getElementById(localKeyIdentifier[index + 1]);
      }

      if (!element) {
        let disabledList = Object.keys(tableData[0].disabled);
        let objectNumberKeys = Object.keys(tableData[0]).filter(Number);
        let i = 0;
        let j = 0;
        let arrayWithoutDisabled = [];

        while (i < disabledList.length && j < objectNumberKeys.length) {
          if (disabledList[i] === objectNumberKeys[j]) {
            i++;
            j++;
          } else {
            arrayWithoutDisabled.push(objectNumberKeys[j]);
            j++;
          }
        }
        let key = `${tableId}#${arrayWithoutDisabled[0]}#0`;
        document.getElementById(key)?.focus();
      } else {
        element?.focus();
      }
    }
  };

  const getRowItems = () => {
    let rowItems: any =
      tableData &&
      tableData.length > 0 &&
      tableData.map((item: any, index: number) => {
        let dynamicRows = Object.keys(item || {})
          .filter(Number)
          .map((id) => {
            if (id in transformationsHeaders) {
              return {
                id: `${id}`,
                key: `${id}-${item.deliveryCenterId}-${item.gradeLevelId}`,
                customIndex: `${index}`,
                textInput: true,
                error: item?.errorList?.includes(id) ? true : false,
                errorText: PERCENTAGE_ABOVE_100_ERROR,
                errorToastNotRequired: item?.errorToastNotRequired
                  ? true
                  : false,
                onBlur: (e: any) => {
                  FocusChage(e.target.value, index, id);
                },
                onchangeHandler: (e: any) => {
                  let columnId = id;
                  handleOnChange(
                    index,
                    e.target.value,
                    columnId,
                    determineNegValuesAllowed(id)
                  );
                },
                onHandleEnter: (evt: any, keyIdentifier: any) => {
                  setPrevValue(evt?.target?.value);
                  handleEnter(evt, keyIdentifier);
                },
                inputIcon:
                  Number(id) ==
                    transformationsHeadersIds["Omnia Efficiency Improvement"] ||
                  Number(id) ==
                    transformationsHeadersIds[
                      "Levvia Efficiency Improvement"
                    ] ||
                  Number(id) ==
                    transformationsHeadersIds["“Free” Efficiency Improvement"]
                    ? true
                    : false,
                inputValue: item[id],
                allowNegValues: determineNegValuesAllowed(id),
                inputDisable:
                  Object.keys(item?.disabled || {}).length > 0 &&
                  item?.disabled[id]
                    ? true
                    : false,
                roundNumbers:
                  Number(id) == transformationsHeadersIds["Opening Position"] ||
                  Number(id) ==
                    transformationsHeadersIds["Omnia Efficiency Improvement"] ||
                  Number(id) ==
                    transformationsHeadersIds[
                      "Levvia Efficiency Improvement"
                    ] ||
                  Number(id) ==
                    transformationsHeadersIds["Closing/Opening Position"]
                    ? 2
                    : 5,
              };
            }
          });
        let tableRows = {
          id: `${item.gradeLevelId}-${item.deliveryCenterId}`,
          key: `${item.gradeLevelId}-${index}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text:
                item.deliveryCenterId !== 0 && item.gradeLevelId === 0
                  ? delieveryCenterIDKeys[item.deliveryCenterId]
                  : headCountsTableGradeLevels[item.gradeLevelId],
              highlight:
                item.deliveryCenterId !== 0 && item.gradeLevelId === 0
                  ? true
                  : false,
              showExpandIcon:
                item.deliveryCenterId !== 0 && item.gradeLevelId === 0
                  ? true
                  : false,
              iconDirection: expandedRowIds.includes(
                `${item.gradeLevelId}-${item.deliveryCenterId}`
              )
                ? "down"
                : "up",
              iconOnlick: (deliveryCenterRowId: any) => {
                handleOnClickIcon(item, deliveryCenterRowId);
              },
            },
            ...dynamicRows,
          ],
        };
        return tableRows;
      });

    let updatedArray: any = rowItems?.length > 0 && [
      ...rowItems,
      {
        className: `${BLOCK}__row`,
        columnItems: [{ text: "Total", highlight: true }, ...totalValueRow],
        id: 99999,
        key: "1-9999",
      },
    ];
    return updatedArray;
  };
  return (
    <div data-test="transformation-table">
      <SelectionContext.Provider value={selection}>
        <div ref={selectContainerRef}>
          {Object.keys(tableData || {})?.length === 0 ? (
            <div className={`${BLOCK}__spinner-container`}>
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className={`${BLOCK}--loading`}
              />
            </div>
          ) : (
            <Table
              className={`${BLOCK}--innerWrapper`}
              headerItems={getTableHeaders()}
              rowItems={getRowItems()}
              innerClassName={BLOCK}
              selection={selection}
              tableId={+selectedTab + 40}
            />
          )}
        </div>
      </SelectionContext.Provider>
    </div>
  );
};

export default TransformationTable;
