import { useEffect, useState } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  VISUAL_DASHBOARD_ICONS,
  EXPORT_TYPE_MAPPING,
  EXPORT_FORMAT,
  CONSOLIDATION_EXPORT,
  dashboardRefreshIconTootlTipText,
} from "utils/constants";
import Icon from "components/shared/icon/icon";
import ConsolidatedDataTable from "./consolidated_data_table";
import ConsolidatedDataGraph from "./consolidated_data_graph";
import Modal from "../shared/modal/modal";
import MetricsModal from "./metricsModal";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import emptyimg from "images/saved.shortlist-empty.png";
import ReactTooltip from "react-tooltip";
import {
  setConsolidationGeographies,
  setVisualDashboardCurrency,
  loadConsolidationTrasnformationAssumptions,
  setRefreshVisualDashboard,
  loadAccumExits,
  loadCostPerHour,
  getOverHeadFTEDrivenData,
  getOverHeadRevenueData,
  loadGrossMargin,
  loadGrossProfit,
  loadTotalAttritionLoss,
  getHeadCountByCohortsLocalCOEData,
  getHeadCountByGradesLocalCOEData,
  getHeadCountByCohortsLocalDCData,
  getHeadCountByGradesLocalDCData,
  loadNetFeePerHour,
  loadNetMargin,
  loadNetProfit,
  loadNetRevenue,
  loadNetRevenueAfterPricing,
  getHeadCountByCohortsOnshoreData,
  getHeadCountByGradesOnshoreData,
  getOverHeadAllocationsData,
  getOverHeadsDetailsTotalData,
  getOverHeadOtherData,
  loadPricingImprovements,
  loadPromotedHeadCounts,
  getHeadCountByCohortsRadcData,
  getHeadCountByGradesRadcData,
  getTechnicalStaffCostLocalCOEData,
  getTechnicalStaffCostOnshoreData,
  getTechnicalStaffCostRADCData,
  loadTotalTechnicalStaffCost,
  getTechnicalStaffCostUSIData,
  getTechnicalStaffCostLocalDCData,
  loadTotalHeadcountCohorts,
  getTotalHeadCountByGradesData,
  loadTotalHours,
  getHeadCountByCohortsUsiData,
  getHeadCountByGradesUsiData,
} from "services/visualDashboard/visualDashBoardSlice";
import { sendExportItem } from "services/download/downloadSlice";
import classNames from "classnames";
import socket, { rooms } from "utils/socket";
import RefreshNotification from "../refreshNotificationDashboard/refreshNotification";
import DashboardNavigationPanel from "./visualDashboardNavigationPanel";

const VisualDashboard = () => {
  const dispatch = useDispatch();
  const BLOCK = "visual_dashboard";
  const EXPORT_BLOCK = "inputform";
  const BLOCK_CONSOLIDATION = "consolidatedData";
  const EMPTY_BLOCK = "dashboardTable";
  const [section, setSection] = useState(1);
  const [refreshState, setRefreshState] = useState<boolean>(false);
  const changeSection = (e: any, value: number) => {
    e.preventDefault();
    dispatch(setRefreshVisualDashboard(false));
    setSection(value);
  };
  const consolidationData: any = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  const dashboardTableState = useSelector(
    (state: RootStore) => state.dashboard
  );
  const [openMetricsModal, setOpenMetricsModal] = useState(false);

  const [geographies, setGeographies] = useState<string[]>([]);
  const [currencyCode, setCurrencyCode] = useState<string>("");
  const [metricsSelection, setMetricsSelection] = useState(false);
  const [dashboardBaseYear, setDashboardBaseYear] = useState(null);
  const [refreshModal, setRefereshModal] = useState(false);
  const [geographyName, setGepgraphyName] = useState<string>("");

  useEffect(() => {
    if (geographies && geographies.length > 0) {
      geographies?.map((item: any) => {
        socket.on("ready.to.update", openRefreshPopUp);
        socket.join(rooms.refresh(item));
      });
    }
    const leavRooms = () => {
      geographies?.map((item: any) => {
        socket.leave(rooms.refresh(item));
      });
    };
    return () => {
      leavRooms();
    };
  }, [geographies]);

  const openRefreshPopUp = (payload: any) => {
    if (
      payload.event === "ready.to.update" &&
      payload.data.calculationStatus === 1 &&
      !refreshModal
    ) {
      dispatch(setRefreshVisualDashboard(false));
      setRefreshState(true);
      setRefereshModal(true);
      setGepgraphyName(payload.data?.countryName);
    }
  };

  const loadVisualDashboard = (isAll: boolean = true) => {
    if (
      (consolidationData?.consolidationGeographiesSelected
        ?.childCheckBoxSelected?.length > 0 ||
        consolidationData?.consolidationGeographiesSelected
          ?.childSubConsolidationGeographyCheckBoxSelected?.length > 0 ||
        consolidationData?.consolidationGeographiesSelected
          ?.subParentCheckBoxSelected?.length > 0 ||
        consolidationData?.consolidationGeographiesSelected
          ?.parentCheckBoxSelected?.length > 0) &&
      consolidationData?.currency.length > 0 &&
      dashboardTableState?.fiscalYearList &&
      Object.keys(dashboardTableState?.fiscalYearList).length &&
      dashboardTableState?.fiscalYearList[0]?.year
    ) {
      let currencyCode = `${consolidationData?.currency[0]}`.split(" - ")[0];

      const Geographies: any =
        consolidationData?.consolidationGeographiesSelected
          ?.childCheckBoxSelected;
      const SubGeographies: any =
        consolidationData?.consolidationGeographiesSelected
          .childSubConsolidationGeographyCheckBoxSelected &&
        Object.keys(
          consolidationData?.consolidationGeographiesSelected
            .childSubConsolidationGeographyCheckBoxSelected
        ).length
          ? consolidationData?.consolidationGeographiesSelected
              .childSubConsolidationGeographyCheckBoxSelected
          : null;
      let customGeographies = SubGeographies
        ? Geographies.concat(SubGeographies)
        : Geographies;
      setMetricsSelection(true);
      setCurrencyCode(currencyCode);
      setGeographies(customGeographies.filter((item: any) => item));
      setDashboardBaseYear(dashboardTableState?.fiscalYearList[0]?.year);
      if (customGeographies?.length > 0 && currencyCode) {
        let data = {
          countryCodes: customGeographies,
          currencyCode,
          baseYear: dashboardTableState?.fiscalYearList[0]?.year,
        };
        dispatch(loadConsolidationTrasnformationAssumptions(data));
        if (isAll) {
          dispatch(loadAccumExits(data));
          dispatch(loadCostPerHour(data));
          dispatch(getOverHeadFTEDrivenData(data));
          dispatch(getOverHeadRevenueData(data));
          dispatch(loadGrossMargin(data));
          dispatch(loadGrossProfit(data));
          dispatch(loadTotalAttritionLoss(data));
          dispatch(getHeadCountByCohortsLocalCOEData(data));
          dispatch(getHeadCountByGradesLocalCOEData(data));
          dispatch(getHeadCountByCohortsLocalDCData(data));
          dispatch(getHeadCountByGradesLocalDCData(data));
          dispatch(loadNetFeePerHour(data));
          dispatch(loadNetMargin(data));
          dispatch(loadNetProfit(data));
          dispatch(loadNetRevenue(data));
          dispatch(loadNetRevenueAfterPricing(data));
          dispatch(getHeadCountByCohortsOnshoreData(data));
          dispatch(getHeadCountByGradesOnshoreData(data));
          dispatch(getOverHeadAllocationsData(data));
          dispatch(getOverHeadsDetailsTotalData(data));
          dispatch(getOverHeadOtherData(data));
          dispatch(loadPricingImprovements(data));
          dispatch(loadPromotedHeadCounts(data));
          dispatch(getHeadCountByCohortsRadcData(data));
          dispatch(getHeadCountByGradesRadcData(data));
          dispatch(getTechnicalStaffCostLocalCOEData(data));
          dispatch(getTechnicalStaffCostOnshoreData(data));
          dispatch(getTechnicalStaffCostRADCData(data));
          dispatch(loadTotalTechnicalStaffCost(data));
          dispatch(getTechnicalStaffCostUSIData(data));
          dispatch(getTechnicalStaffCostLocalDCData(data));
          dispatch(loadTotalHeadcountCohorts(data));
          dispatch(getTotalHeadCountByGradesData(data));
          dispatch(loadTotalHours(data));
          dispatch(getHeadCountByCohortsUsiData(data));
          dispatch(getHeadCountByGradesUsiData(data));
        }
      }
    }
  };

  const refreshDashboard = () => {
    dispatch(setRefreshVisualDashboard(true));
    setRefreshState(false);
    loadVisualDashboard(true);
    setRefereshModal(false);
  };

  useEffect(() => {
    loadVisualDashboard(false);
  }, [
    consolidationData?.consolidationGeographiesSelected,
    consolidationData?.currency,
    dashboardTableState.fiscalYearList,
  ]);

  const handleClose = async () => {
    if (consolidationData.applyMetrics) {
      setOpenMetricsModal(false);
    } else {
      dispatch(
        setConsolidationGeographies({
          checked: false,
          parentCheckBoxSelected: [],
          subParentCheckBoxSelected: [],
          childCheckBoxSelected: [],
          childSubConsolidationGeographyCheckBoxSelected: [],
        })
      );
      dispatch(setVisualDashboardCurrency([]));
      setOpenMetricsModal(false);
    }
  };

  const exportSeedModel = async () => {
    const payload = {
      exportReferenceId: CONSOLIDATION_EXPORT.EXPORT_REFERENCE_ID,
      exportFileName: CONSOLIDATION_EXPORT.EXPORT_FILE_NAME,
      currencyCode: currencyCode,
      exportType: EXPORT_TYPE_MAPPING.CONSOLIDATION_DASHBOARD,
      exportFormat: EXPORT_FORMAT.xls,
      countryCodes: geographies,
      BaseYear: dashboardBaseYear,
    };
    dispatch(sendExportItem(payload));
  };
  useEffect(() => {
    if (
      consolidationData?.loading.length == 0 &&
      consolidationData?.refreshVisualDashboard
    )
      dispatch(setRefreshVisualDashboard(false));
  }, [consolidationData?.loading, consolidationData?.refreshVisualDashboard]);
  return (
    <>
      <div className={BLOCK} data-test="component-Visual Dashboard">
        <div className={`${BLOCK}__header`}>
          <span className={`${BLOCK}__header--title`}>
            <FormattedMessage id={`dashboard.main.title`} />
          </span>
          <div className={`${BLOCK}__dashboard-buttons`}>
            {metricsSelection === true && (
              <>
                <div className={`${BLOCK}__icons-shift`}>
                  <div
                    className={
                      section === 1
                        ? `${BLOCK}__table-selected-icon`
                        : `${BLOCK}__table-un-selected-icon`
                    }
                    onClick={(e) => changeSection(e, 1)}
                  >
                    <div className={`${BLOCK}__shift-icon1`}>
                      <Icon
                        name={
                          section === 1
                            ? VISUAL_DASHBOARD_ICONS.table.selected
                            : VISUAL_DASHBOARD_ICONS.table.unSelected
                        }
                        height={24}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      section === 2
                        ? `${BLOCK}__garph-selected-icon`
                        : `${BLOCK}__garph-un-selected-icon`
                    }
                    onClick={(e) => changeSection(e, 2)}
                  >
                    <div className={`${BLOCK}__shift-icon2`}>
                      <Icon
                        name={
                          section === 2
                            ? VISUAL_DASHBOARD_ICONS.graph.selected
                            : VISUAL_DASHBOARD_ICONS.graph.unSelected
                        }
                        height={24}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`${EXPORT_BLOCK}__header--export ${BLOCK}__export-section`}
                >
                  <button
                    className={classNames(`${BLOCK}__header--refresh--btn`, {
                      [`${BLOCK}__header--refresh--btn--graphView`]:
                        section === 2,
                      [`${BLOCK}__header--refresh--btn--disabled`]:
                        !refreshState,
                      [`${BLOCK}__header--refresh--btn--enabled`]: refreshState,
                    })}
                    disabled={!refreshState}
                    onClick={refreshDashboard}
                  >
                    {refreshState && (
                      <div className={`${BLOCK}__header--redDot`}></div>
                    )}
                    <div
                      data-tip={
                        refreshState
                          ? dashboardRefreshIconTootlTipText.enabledText
                          : dashboardRefreshIconTootlTipText.disabledText
                      }
                      data-for="refreshIcon"
                    >
                      <Icon
                        name={!refreshState ? "shape" : "shape-active"}
                        height={22}
                        width={22}
                      />
                    </div>
                    <ReactTooltip id="refreshIcon" type="dark" effect="solid" />
                  </button>
                  {section === 1 && (
                    <button
                      className={classNames(
                        `${EXPORT_BLOCK}__header--export--btn`,
                        {
                          [`${EXPORT_BLOCK}__header--export--btn--disabled`]:
                            geographies.length === 0 && !currencyCode,
                          [`${EXPORT_BLOCK}__header--export--btn--enabled`]:
                            geographies.length > 0 && currencyCode,
                        }
                      )}
                      onClick={() => exportSeedModel()}
                      disabled={
                        geographies.length > 0 && currencyCode ? false : true
                      }
                    >
                      <Icon
                        name={
                          geographies.length === 0 && !currencyCode
                            ? "disabled-export"
                            : "download-blue"
                        }
                        height={25}
                        width={25}
                      />
                    </button>
                  )}
                </div>
              </>
            )}
            <div
              className={
                section === 1 ? `${BLOCK}__metrics` : `${BLOCK}__export-section`
              }
              style={{ marginTop: "-1px" }}
            >
              <div className={`${BLOCK}__button-section`}>
                <button
                  className={`${BLOCK}__metrics-button`}
                  data-test="dashboard-metrics-button"
                  onClick={() => {
                    setOpenMetricsModal(true);
                  }}
                >
                  <FormattedMessage id="visual.dashboard.metrics.button" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {refreshModal && (
        <RefreshNotification
          show={refreshModal}
          refreshDashboard={refreshDashboard}
          geographyName={geographyName}
        />
      )}
      {metricsSelection === false ? (
        <div className={EMPTY_BLOCK}>
          <div className={`${EMPTY_BLOCK}__empty-container`}>
            <div className={`${EMPTY_BLOCK}__empty-container--img`}>
              <img
                data-testid="empty-img"
                className={""}
                src={emptyimg}
                alt={emptyimg}
              />
            </div>
            <div className={`${EMPTY_BLOCK}__empty-container--text`}>
              <span className={`${EMPTY_BLOCK}__empty-container--text--main`}>
                <FormattedMessage id="dashboard.table.empty.table.main.text" />
              </span>
              <span className={`${EMPTY_BLOCK}__empty-container--text--sub`}>
                <FormattedMessage id="dashboard.table.empty.table.sub.text.please" />
                <a
                  onClick={() => setOpenMetricsModal(true)}
                  target="_blank"
                  className={`${BLOCK}__link`}
                  style={{ cursor: "pointer" }}
                >
                  <FormattedMessage id="dashboard.table.empty.table.sub.text.geographies" />
                </a>
                <FormattedMessage id="dashboard.table.empty.table.sub.text.consolidation" />
              </span>
            </div>
          </div>
        </div>
      ) : (
        <>
          {consolidationData?.refreshVisualDashboard && (
            <div className={`${BLOCK}__spinner-container`}>
              <Icon
                name={"loading"}
                width={30}
                height={30}
                className={`${BLOCK}--loading`}
              />
              <div>
                <FormattedMessage id="dashboard.refresh.notification.refresh.text" />
              </div>
            </div>
          )}
          {section === 1 ? (
            <DashboardNavigationPanel />
          ) : (
            <div className={BLOCK}>
              <ConsolidatedDataGraph />
            </div>
          )}
        </>
      )}
      {openMetricsModal && (
        <Modal
          show={true}
          header="visual.dashboard.metrics.heading"
          handleClose={handleClose}
          config={false}
        >
          <MetricsModal setOpenMetricsModal={setOpenMetricsModal} />
        </Modal>
      )}
    </>
  );
};

export default VisualDashboard;
