import { scaleLinear } from "d3-scale";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
  Text,
  ResponsiveContainer,
  ErrorBar,
  Label,
  ReferenceLine,
} from "recharts";
import { GMBridgeY } from "utils/constants";

const customLabelList = (props: any, isPercentage: boolean = false) => {
  const { x, y, width, value } = props;
  const radius = 20;
  return (
    <Text
      className="visual_graph__LabelList"
      x={x + width / 2}
      y={value < 0 ? y + radius : y - radius}
      angle={-50}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {value === 0 || isNaN(value)
        ? "__"
        : new Intl.NumberFormat("en-EN", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            style: isPercentage ? "percent" : "decimal",
          }).format(value)}
    </Text>
  );
};
const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload, isPercentage } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text className="visual_graph__Label" x={0} y={0} textAnchor="middle">
        {payload?.value?.toLocaleString("us-EN", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
          style: isPercentage ? "percent" : "decimal",
        })}
      </text>
    </g>
  );
};
const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        className="visual_graph__LabelGMBridge"
        x={0}
        y={-20}
        dy={10}
        textAnchor="middle"
      >
        {payload?.value}
      </text>
    </g>
  );
};

type GMBridgeChartProps = {
  data: any;
  percentageToggle: boolean;
};

const GMRevenueChart = ({ data, name, isPercentage }: any) => {
  return (
    <>
      {data && Object.keys(data).length && (
        <>
          <ResponsiveContainer
            width="95%"
            height={408}
            style={{ paddingBlock: 25, marginLeft: "2.5%" }}
          >
            <BarChart
              data={data}
              margin={{
                top: 35,
                left: 10,
                bottom: 35,
              }}
            >
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                tickMargin={50}
                fontSize={25}
                height={50}
                tick={CustomizedXAxisTick}
                orientation={"top"}
              />
              <YAxis
                dataKey="value"
                axisLine={false}
                tickLine={false}
                height={20}
                tick={<CustomizedYAxisTick isPercentage={isPercentage} />}
              >
                <Label
                  angle={-90}
                  value={`${name} EFFECT`}
                  offset={0}
                  dy={-50}
                  position="left"
                  style={{
                    fontWeight: 600,
                    color: "#212121",
                    fontSize: "12px",
                  }}
                  color="#212121"
                  fill="0"
                />
              </YAxis>
              <Tooltip
                cursor={false}
                separator=""
                viewBox={{ x: 0, y: 0, width: 600, height: 200 }}
                formatter={(value: any, name: any, props: any) => {
                  return [
                    props.payload.label === 0 || isNaN(props.payload.label)
                      ? "__"
                      : new Intl.NumberFormat("en-EN", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          style: isPercentage ? "percent" : "decimal",
                        }).format(props.payload.label),
                    "",
                  ];
                }}
              />
              <ReferenceLine y={0} stroke="#E0E0E0" strokeDasharray="3 3" />

              <Bar
                dataKey="value"
                fill="#8884d8"
                maxBarSize={56}
                minPointSize={4}
                isAnimationActive={false}
              >
                {data?.map((entry: any, index: number) => {
                  const color =
                    entry.label === "___"
                      ? "#ffffff"
                      : Number(entry.label) < 0
                      ? "#E35A0E"
                      : "#62B5E5";

                  return <Cell key={index} fill={color} />;
                })}
                <ErrorBar
                  dataKey="error"
                  width={30}
                  strokeWidth={1}
                  stroke="#97999B"
                  direction="y"
                />
                <LabelList
                  content={(e) => customLabelList(e, isPercentage)}
                  position="top"
                  dataKey="label"
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  );
};

const GMCostChart = ({ data, name, isPercentage }: any) => {
  return (
    <>
      {data && Object.keys(data).length && (
        <>
          <ResponsiveContainer
            width="95%"
            height={358}
            style={{ paddingBottom: 25, marginLeft: "2.5%" }}
          >
            <BarChart
              data={data}
              margin={{
                top: 35,
                left: 10,
                bottom: 35,
              }}
            >
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                tickMargin={50}
                fontSize={25}
                height={50}
                tick={CustomizedXAxisTick}
                orientation={"top"}
                hide={true}
              />
              <YAxis
                dataKey="value"
                axisLine={false}
                tickLine={false}
                height={20}
                tick={<CustomizedYAxisTick isPercentage={isPercentage} />}
              >
                <Label
                  angle={-90}
                  value={`${name} EFFECT`}
                  offset={0}
                  position="left"
                  dy={-50}
                  style={{
                    fontWeight: 600,
                    color: "#212121",
                    fontSize: "12px",
                  }}
                  color="#212121"
                  fill="0"
                />
              </YAxis>
              <Tooltip
                cursor={false}
                separator=""
                viewBox={{ x: 0, y: 0, width: 600, height: 200 }}
                formatter={(value: any, name: any, props: any) => {
                  return [
                    props.payload.label === 0 || isNaN(props.payload.label)
                      ? "__"
                      : new Intl.NumberFormat("en-EN", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          style: isPercentage ? "percent" : "decimal",
                        }).format(props.payload.label),
                    "",
                  ];
                }}
              />
              <ReferenceLine y={0} stroke="#E0E0E0" strokeDasharray="3 3" />
              <Bar
                dataKey="value"
                fill="#8884d8"
                maxBarSize={70}
                minPointSize={3}
                isAnimationActive={false}
              >
                {data?.map((entry: any, index: number) => {
                  const color =
                    entry.label === "___"
                      ? "#ffffff"
                      : Number(entry.label) > 0
                      ? "#E35A0E"
                      : "#62B5E5";

                  return <Cell key={index} fill={color} />;
                })}
                <ErrorBar
                  dataKey="error"
                  width={30}
                  strokeWidth={1}
                  stroke="#97999B"
                  direction="y"
                />
                <LabelList
                  content={(e) => customLabelList(e, isPercentage)}
                  position="top"
                  dataKey="label"
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  );
};

const GMGrossProfitChart = ({ data, name, isPercentage }: any) => {
  return (
    <>
      {data && Object.keys(data).length && (
        <>
          <ResponsiveContainer
            width="95%"
            height={358}
            style={{ marginLeft: "2.5%" }}
          >
            <BarChart
              data={data}
              margin={{
                top: 35,
                left: 10,
                bottom: 35,
              }}
            >
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                tickMargin={50}
                fontSize={25}
                height={50}
                tick={CustomizedXAxisTick}
                orientation={"top"}
                hide={true}
              />
              <YAxis
                dataKey="value"
                axisLine={false}
                tickLine={false}
                height={20}
                tick={<CustomizedYAxisTick isPercentage={isPercentage} />}
              >
                <Label
                  angle={-90}
                  value={`${name} EFFECT`}
                  offset={0}
                  position="left"
                  dy={-60}
                  style={{
                    fontWeight: 600,
                    color: "#212121",
                    fontSize: "12px",
                  }}
                  color="#212121"
                  fill="0"
                />
              </YAxis>
              <Tooltip
                cursor={false}
                separator=""
                viewBox={{ x: 0, y: 0, width: 600, height: 200 }}
                formatter={(value: any, name: any, props: any) => {
                  return [
                    props.payload.label === 0 || isNaN(props.payload.label)
                      ? "__"
                      : new Intl.NumberFormat("en-EN", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          style: isPercentage ? "percent" : "decimal",
                        }).format(props.payload.label),
                    "",
                  ];
                }}
              />
              <ReferenceLine y={0} stroke="#E0E0E0" strokeDasharray="3 3" />
              <Bar
                dataKey="value"
                fill="#8884d8"
                maxBarSize={70}
                minPointSize={3}
                isAnimationActive={false}
              >
                {data?.map((entry: any, index: number) => {
                  const color =
                    entry.label === "___"
                      ? "#ffffff"
                      : Number(entry.label) < 0
                      ? "#E35A0E"
                      : "#62B5E5";
                  return <Cell key={index} fill={color} />;
                })}
                <ErrorBar
                  dataKey="error"
                  width={30}
                  strokeWidth={1}
                  stroke="#97999B"
                  direction="y"
                />
                <LabelList
                  content={(e) => customLabelList(e, isPercentage)}
                  position="top"
                  dataKey="label"
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  );
};

const GMBridgeChart = ({ data, percentageToggle }: GMBridgeChartProps) => {
  return (
    <div data-test="GMBridgeChart">
      <GMRevenueChart
        data={
          data
            ? data.filter((e: any) => e.name === GMBridgeY.REVENUE)[0]?.data
            : []
        }
        name={GMBridgeY.REVENUE}
        isPercentage={percentageToggle}
      />
      <GMCostChart
        data={
          data
            ? data.filter((e: any) => e.name === GMBridgeY.COST)[0]?.data
            : []
        }
        name={GMBridgeY.COST}
        isPercentage={percentageToggle}
      />
      <GMGrossProfitChart
        data={
          data
            ? data.filter((e: any) => e.name === GMBridgeY.GROSS)[0]?.data
            : []
        }
        name={GMBridgeY.GROSS}
        isPercentage={percentageToggle}
      />
    </div>
  );
};
export default GMBridgeChart;
