import { RootStore } from "services/store.service";
import { useDispatch, useSelector } from "react-redux";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { LIBERATED_HOURS_DEFAULT_PRODUCTSEGMENT } from "utils/constants";
import DropDown from "components/shared/dropdown/dropdown";
import { setLiberatedHourProductSegment } from "services/inputForm/inputFormSlice";
import { useEffect } from "react";

const LiberatedHoursHeader = ({ BLOCK }: any) => {
  const inputFormDataState: any = useSelector(
    (state: RootStore) => state.inputForm
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      inputFormDataState?.selectedProductSegment &&
      Object.keys(inputFormDataState?.selectedProductSegment).length === 0
    ) {
      dispatch(
        setLiberatedHourProductSegment(LIBERATED_HOURS_DEFAULT_PRODUCTSEGMENT)
      );
    }
  }, []);

  return (
    <div
      className={`${BLOCK}__liberated_hours-header`}
      data-test="liberatedHoursHeader-table"
    >
      <div>
        <FormattedMessage id={`liberated.hours`} />
      </div>
      <div className={`${BLOCK}__liberated_hours-divider`}></div>
      <div className={`${BLOCK}__liberated_hours-icon`}>
        <DropDown
          className={`productSegmant`}
          parentBlock={""}
          data={
            inputFormDataState?.liberatedHoursDropDownData &&
            Object.keys(inputFormDataState.liberatedHoursDropDownData).length
              ? inputFormDataState?.liberatedHoursDropDownData.map(
                  (item: any) => {
                    return {
                      productSegment: item.description,
                      id: item.displayOrder,
                    };
                  }
                )
              : []
          }
          selected={
            inputFormDataState?.selectedProductSegment &&
            Object.keys(inputFormDataState?.selectedProductSegment).length
              ? inputFormDataState?.selectedProductSegment
              : LIBERATED_HOURS_DEFAULT_PRODUCTSEGMENT
          }
          keys={{
            displayName: "productSegment",
            id: "id",
          }}
          onChange={(e: any) => {
            dispatch(setLiberatedHourProductSegment(e));
          }}
        />
      </div>
    </div>
  );
};

export default LiberatedHoursHeader;
