import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useClickOutside } from "utils/functions";
import Icon from "../icon/icon";
type Props = {
  className: string;
  parentBlock: string;
  data: any[];
  selected: any;
  keys: { displayName: string; id: string };
  onChange: any;
};

const Dropdown = ({
  className,
  parentBlock,
  data,
  selected,
  keys,
  onChange,
}: Props) => {
  const BLOCK = "shared-dropdown";
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setShowOptions(false));

  return (
    <div
      ref={dropdownRef}
      className={`${parentBlock}__dropdown ${BLOCK} ${BLOCK}__filter--${className} `}
    >
      <span
        onClick={() => setShowOptions(!showOptions)}
        className={classNames(`${BLOCK}__selected`, {
          [`${BLOCK}__selected--productSegment`]:
            className === "productSegmant",
        })}
      >
        {className !== "year" && selected[keys.displayName]}
        <Icon
          name={showOptions ? "chevron-up-blue" : "chevron-down-blue"}
          width={className === "productSegmant" ? 20 : 14}
          height={className === "productSegmant" ? 20 : 14}
        />
      </span>
      {showOptions ? (
        <div
          className={`${BLOCK}__options ${BLOCK}__options__filter--${className}`}
        >
          {data.map((option, i) => (
            <div
              key={`dropdown-option-${i}`}
              onClick={() => {
                setShowOptions(false);
                onChange(option);
              }}
              className={classNames(`${BLOCK}__option`, {
                [`${BLOCK}__option--selected`]:
                  option[keys.id] === selected[keys.id],
              })}
            >
              {option[keys.displayName]}
              {option[keys.id] === selected[keys.id] ? (
                <Icon name="tick" width={20} height={20} />
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
