import { useEffect, useState, createContext, useContext } from "react";
import {
  loadYearlyUtilisationRateContext,
  loadYearlyUtilizationConfigContext,
  setYearlyUtilizationGetTableData,
} from "services/inputForm/inputFormSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { TABLE_MAPPINGS, yearlyUtilizationTableIdList } from "utils/constants";

const YearlyUtilizationContext: any = createContext(null);

type yearlyUtilizationContext = {
  children: React.ReactNode;
};

export const YearlyUtilizationContextProvider = ({
  children,
}: yearlyUtilizationContext) => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  let planSubmissionId = inputFormDataState?.metaData?.planSubmissionId
    ? inputFormDataState?.metaData?.planSubmissionId
    : Number(params?.id);

  const [yearlyUtilizationTablesData, setYearlyUtilizationTablesData] =
    useState<any>([]);
  const [configToggle, setConfigToggle] = useState<boolean>(false);
  const [initialcheck, setInitialCheck] = useState<boolean>(true);
  const [compareTablesData, setCompareTablesData] = useState<any>([]);

  useEffect(() => {
    const fetchData = () => {
      loadYearlyUtilizationConfigContext(planSubmissionId).then((data: any) => {
        setConfigToggle(data);
      });
      yearlyUtilizationTablesData.length == 0 &&
        loadYearlyUtilisationRateContext(planSubmissionId).then((data: any) => {
          setYearlyUtilizationTablesData(data);
          setCompareTablesData(data);
        });
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (yearlyUtilizationTablesData?.length > 0 && initialcheck) {
      multiyearEnableAndDisable(yearlyUtilizationTablesData, configToggle);
      setInitialCheck(false);
    }
  }, [configToggle, yearlyUtilizationTablesData]);

  useEffect(() => {
    !initialcheck &&
      multiyearEnableAndDisable(yearlyUtilizationTablesData, configToggle);
  }, [configToggle]);

  useEffect(() => {
    dispatch(setYearlyUtilizationGetTableData(yearlyUtilizationTablesData));
  }, [yearlyUtilizationTablesData]);

  const multiyearEnableAndDisable = (data: any, toggle: boolean) => {
    var updatedTableData: any;
    if (toggle) {
      updatedTableData = data?.map((item: any) => {
        return { ...item };
      });
      updatedTableData?.map((item: any) => {
        delete item.disabled;
      });
    } else if (!toggle) {
      let disableObject = {};
      data?.map((item: any) => {
        Object.keys(item || {})
          ?.filter(Number)
          ?.map((row: any, index: number) => {
            if (index !== 0) {
              disableObject = { ...disableObject, [row]: "true" };
            }
          });
      });
      updatedTableData = data?.map((item: any) => {
        return { ...item, disabled: disableObject };
      });
    }
    setYearlyUtilizationTablesData(updatedTableData);
  };

  return (
    <YearlyUtilizationContext.Provider
      value={{
        compareTablesData,
        configToggle,
        yearlyUtilizationTablesData,
        setCompareTablesData,
        setConfigToggle,
        setYearlyUtilizationTablesData,
        yearlyUtilizationTableIdList,
      }}
    >
      {children}
    </YearlyUtilizationContext.Provider>
  );
};

export function useAPI() {
  const context = useContext(YearlyUtilizationContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
