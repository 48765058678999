import { useEffect, useState, useRef } from "react";
import Table, { TableRowItem } from "../shared/table/table";
import {
  productSegmentsMapping,
  headCountsTableGradeLevels,
  ConsolidationsKeyInitial,
} from "utils/constants";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import Icon from "components/shared/icon/icon";

type ConsolidationTables = {
  tableData?: any;
  tableHeader?: any;
  mainTableHeader?: string;
  subConsolidationTableData?: any;
  keyInitial?: string;
  loading?: boolean;
  isFormattedText?: boolean;
  isGradeLevelTable?: boolean;
};

const ConsolidationTables = ({
  tableData,
  tableHeader,
  mainTableHeader,
  subConsolidationTableData,
  keyInitial,
  loading,
  isFormattedText = true,
  isGradeLevelTable = false,
}: ConsolidationTables) => {
  const BLOCK = "headcountsTable";
  const [updatedTableData, setUpdatedTableData] = useState<any>();
  const [
    updatedSubConsolidationTableData,
    setUpdatedSubConsolidationTableData,
  ] = useState<any>();
  const [selectedProductSegmentId, setSelectedProductSegmentId] = useState<any>(
    []
  );
  const [tableRows, setTableRows] = useState<any>([]);
  const table: any = useRef<any>();
  const [RevenueBaseYearHeaders, setRevenueBaseYearHeaders] =
    useState<any>(null);

  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  const dashboardTableState = useSelector(
    (state: RootStore) => state.dashboard
  );

  useEffect(() => {
    setTableRows(getRowItems());
    table.current = getRowItems();
  }, [updatedTableData]);

  const insert = (arr: any, index: any, ...newItems: any) => [
    ...arr.slice(0, index),
    ...newItems,
    ...arr.slice(index),
  ];

  const generateRows = (
    updatedSubConsolidationTableData: any,
    productSegmentId: number
  ) => {
    const selectedTableRowData: any =
      updatedSubConsolidationTableData[productSegmentId];
    const updatedTableData: any = {};
    selectedTableRowData?.length > 0 &&
      selectedTableRowData.map((item: any) => {
        if (updatedTableData[item.countryName]) {
          updatedTableData[item.countryName] = [
            ...updatedTableData[item.countryName],
            {
              value:
                keyInitial?.includes(
                  ConsolidationsKeyInitial.TECHNICAL_STAFF_COST
                ) && item?.displayValue?.length > 0
                  ? item?.displayValue
                  : item.value,
              productSegmentId: productSegmentId,
            },
          ];
        } else {
          updatedTableData[item.countryName] = [
            {
              value:
                keyInitial?.includes(
                  ConsolidationsKeyInitial.TECHNICAL_STAFF_COST
                ) && item?.displayValue?.length > 0
                  ? item?.displayValue
                  : item.value,
              productSegmentId: productSegmentId,
            },
          ];
        }
      });
    let selectedRow: any = getSubConsolidationRows(updatedTableData);
    return selectedRow;
    return [];
  };

  const handleOnClickRow = (productSegmentId: any) => {
    setSelectedProductSegmentId((prevVal: any) => {
      if (!prevVal?.includes(productSegmentId)) {
        let index = table.current.findIndex(
          (item: any) => item.id === productSegmentId
        );
        let newTableRowsArray: any = [...table.current];
        const result = insert(
          newTableRowsArray,
          index + 1,
          ...generateRows(updatedSubConsolidationTableData, productSegmentId)
        );
        result.map((item: any) => {
          if (item.id === productSegmentId) {
            item.columnItems.map((item: any) => {
              if (item?.showExpandIcon) {
                item.iconDirection = "up";
              }
            });
          }
        });
        table.current = result;
        setTableRows([...result]);
        return [...prevVal, productSegmentId];
      } else {
        let newTable: any = table.current.filter((item: any) => {
          return item.indexKey !== productSegmentId;
        });
        newTable.map((item: any) => {
          if (item.id === productSegmentId) {
            item.columnItems.map((item: any) => {
              if (item?.showExpandIcon) {
                item.iconDirection = "down";
              }
            });
          }
        });
        table.current = newTable;
        setTableRows([...newTable]);
        return prevVal?.filter((item: any) => {
          return item !== productSegmentId;
        });
      }
    });
  };

  useEffect(() => {
    const updatedTableData: any = {};
    tableData?.length > 0 &&
      tableData.map((item: any) => {
        let value =
          keyInitial?.includes(ConsolidationsKeyInitial.TECHNICAL_STAFF_COST) &&
          item?.displayValue?.length > 0
            ? new Intl.NumberFormat("en-EN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: "decimal",
              }).format(item?.displayValue)
            : item.value?.toLocaleString("us-EN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });

        if(value === '-0.00'){
          value = '0.00';
        }

        if (item.productSegmentId) {
          if (updatedTableData[item.productSegmentId]) {
            updatedTableData[item.productSegmentId] = [
              ...updatedTableData[item.productSegmentId],
              {
                value: value,
              },
            ];
          } else {
            updatedTableData[item.productSegmentId] = [
              {
                value: value,
              },
            ];
          }
        } else {
          if (updatedTableData[item.gradeLevelId]) {
            updatedTableData[item.gradeLevelId] = [
              ...updatedTableData[item.gradeLevelId],
              {
                value: value,
              },
            ];
          } else {
            updatedTableData[item.gradeLevelId] = [
              {
                value: value,
              },
            ];
          }
        }
      });

    setUpdatedTableData(updatedTableData);
  }, [tableData]);

  useEffect(() => {
    const updatedSubConsolidationTableData: any = {};
    subConsolidationTableData?.length > 0 &&
      subConsolidationTableData.map((item: any) => {
        let value =
          keyInitial?.includes(ConsolidationsKeyInitial.TECHNICAL_STAFF_COST) &&
          item?.displayValue?.length > 0
            ? new Intl.NumberFormat("en-EN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: "decimal",
              }).format(item?.displayValue)
            : item.value?.toLocaleString("us-EN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
        if(value === '-0.00'){
          value = '0.00';
        }
        if (item.productSegmentId) {
          if (updatedSubConsolidationTableData[item.productSegmentId]) {
            updatedSubConsolidationTableData[item.productSegmentId] = [
              ...updatedSubConsolidationTableData[item.productSegmentId],
              {
                value: value,
                countryName: item.countryName,
                productSegmentId: item.productSegmentId,
              },
            ];
          } else {
            updatedSubConsolidationTableData[item.productSegmentId] = [
              {
                value: value,
                countryName: item.countryName,
                productSegmentId: item.productSegmentId,
              },
            ];
          }
        } else {
          if (updatedSubConsolidationTableData[item.gradeLevelId]) {
            updatedSubConsolidationTableData[item.gradeLevelId] = [
              ...updatedSubConsolidationTableData[item.gradeLevelId],
              {
                value: value,
                countryName: item.countryName,
                gradeLevelId: item.gradeLevelId,
              },
            ];
          } else {
            updatedSubConsolidationTableData[item.gradeLevelId] = [
              {
                value: value,
                countryName: item.countryName,
                gradeLevelId: item.gradeLevelId,
              },
            ];
          }
        }
      });
    setUpdatedSubConsolidationTableData(updatedSubConsolidationTableData);
  }, [subConsolidationTableData]);

  useEffect(() => {
    let baseYear: number = 0;
    if (
      consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails &&
      Object.keys(
        consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails
      ).length &&
      consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails[0] &&
      consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails[0]
        ?.year
    ) {
      baseYear =
        consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails[0]
          ?.year;
    } else if (
      dashboardTableState?.fiscalYearList &&
      Object.keys(dashboardTableState?.fiscalYearList).length &&
      dashboardTableState?.fiscalYearList[0]?.year
    ) {
      baseYear = parseInt(
        dashboardTableState?.fiscalYearList[0]?.year?.toString().slice(-2)
      );
    }
    if (baseYear) {
      let resultArr: any = [
        {
          text: "",
          isFormattedText: false,
        },
      ];
      const yearsList = findYearsList(
        consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails
      );
      if (yearsList && yearsList.length > 0) {
        for (let i = 0; i < yearsList.length; i++) {
          let year = baseYear ? baseYear + i : 0;
          let Obj = {
            text: "FY" + year,
            isFormattedText: false,
          };
          resultArr.push(Obj);
        }
        setRevenueBaseYearHeaders(resultArr);
      } else {
        setRevenueBaseYearHeaders(null);
      }
    }
  }, [
    consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails,
    dashboardTableState?.fiscalYearList,
  ]);
  const findYearsList = (data: any) => {
    if (data && Object.keys(data).length) {
      const result: any = [];
      data.map((item: any) => {
        if (!result.includes(item.year)) {
          result.push(item.year);
        }
      });
      return result;
    }
  };

  const getTableHeaders = () => {
    let dynamicTableHeaders: any =
      tableHeader?.length > 0 &&
      tableHeader.map((item: any) => {
        let value =
          keyInitial?.includes(ConsolidationsKeyInitial.TECHNICAL_STAFF_COST) &&
          item?.displayValue?.length > 0
            ? new Intl.NumberFormat("en-EN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: "decimal",
              }).format(item?.displayValue)
            : item.value?.toLocaleString("us-EN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
        if(value === '-0.00'){
          value = '0.00';
        }
        return {
          text: value,
          isFormattedText: false,
        };
      });

    const tableHeaders = dynamicTableHeaders &&
      dynamicTableHeaders.length > 0 && [
        {
          text: mainTableHeader,
          isFormattedText: isFormattedText,
        },
        ...dynamicTableHeaders,
      ];

    return tableHeaders;
  };

  const getRowItems = () => {
    let objKeys: any = isGradeLevelTable
      ? Object.keys(updatedTableData || {}).reverse()
      : Object.keys(updatedTableData || {});

    let consolidationRowsData: any =
      objKeys.length > 0 &&
      objKeys.map((item: any, index: number) => {
        let dynamicRows = updatedTableData[item].map((item: any) => {
          return {
            text:
              keyInitial?.includes(
                ConsolidationsKeyInitial.TECHNICAL_STAFF_COST
              ) && item?.displayValue?.length > 0
                ? item?.displayValue
                : keyInitial?.includes(
                    ConsolidationsKeyInitial.TECHNICAL_STAFF_COST
                  ) && item?.displayValue?.length === 0
                ? "-"
                : item.value
                ? item.value
                : "-",
          };
        });

        let tableRows = {
          id: item,
          key: `${keyInitial}-${item}-${index}`,
          className: `${BLOCK}__row`,
          onClick: () => {
            subConsolidationTableData &&
              Object.keys(subConsolidationTableData).length &&
              handleOnClickRow(item);
          },
          columnItems: [
            {
              text: isGradeLevelTable
                ? headCountsTableGradeLevels[item]
                : productSegmentsMapping[item],

              showExpandIcon:
                subConsolidationTableData &&
                Object.keys(subConsolidationTableData).length
                  ? true
                  : false,
              iconDirection: "down",
            },
            ...dynamicRows,
          ],
        };
        return tableRows;
      });
    return consolidationRowsData;
  };

  const getSubConsolidationRows = (updatedTableData: any) => {
    let subConsolidationRowsData: any =
      Object.keys(updatedTableData || {})?.length > 0 &&
      Object.keys(updatedTableData || {}).map((item: any, index: number) => {
        let dynamicRows = updatedTableData[item].map((item: any) => {
          return {
            text:
              keyInitial?.includes(
                ConsolidationsKeyInitial.TECHNICAL_STAFF_COST
              ) && item?.displayValue?.length > 0
                ? item?.displayValue
                : keyInitial?.includes(
                    ConsolidationsKeyInitial.TECHNICAL_STAFF_COST
                  ) && item?.displayValue?.length === 0
                ? "-"
                : item.value
                ? item.value
                : "-",
          };
        });
        let tableRows = {
          id: item,
          key: `${item}-${updatedTableData[item][0].productSegmentId}`,
          indexKey: `${updatedTableData[item][0].productSegmentId}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: item,
            },
            ...dynamicRows,
          ],
        };
        return tableRows;
      });
    return subConsolidationRowsData;
  };

  return (
    <div data-test="component-Consolidations Common Table">
      {tableData?.length > 0 ? (
        <Table
          className={`${BLOCK}--innerWrapper`}
          headerItems={getTableHeaders()}
          secondHeaderItems={RevenueBaseYearHeaders}
          rowItems={tableRows}
          innerClassName={BLOCK}
        />
      ) : loading ? (
        <div className={`${BLOCK}__spinner-container`}>
          <Icon
            name={"loading"}
            width={50}
            height={50}
            className={`${BLOCK}--loading`}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ConsolidationTables;
