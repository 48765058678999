import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import {
  ConsolidationGeographies as ConsolidationModel,
  ConsolidationGeographiesData,
  SubConsolidationGeographiesData,
} from "services/visualDashboard/visual.dashboard.models";
import Icon from "../shared/icon/icon";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

const ConsolidationGeographies = forwardRef<any, any>(
  (
    {
      BLOCK,
      consolidationGeographies,
      setEnableExpandAllButton,
      setEnableCollapseAllButton,
      parentCheckBoxSelected,
      setParentCheckBoxSelected,
      subParentCheckBoxSelected,
      setSubParentCheckBoxSelected,
      childSubConsolidationGeographyCheckBoxSelected,
      setChildSubConsolidationGeographyCheckBoxSelected,
      childCheckBoxSelected,
      setChildCheckBoxSelected,
    },
    ref
  ) => {
    const [totalGeographies, setTotalGeogrpahies] = useState<any>({});
    const [totalSubGeographies, setTotalSubGeographies] = useState<any>({});
    const [checked, setChecked] = useState<boolean>(false);
    const [consolidationSelected, setConsolidationSelected] = useState<any>({
      parentGeoCons: [],
      subParentGeoCons: [],
    });

    const [totalDropDownCount, seTotalDropdownCount] = useState<any>(null);

    useImperativeHandle(
      ref,
      () => ({
        collapseAllGeographies() {
          setConsolidationSelected({
            parentGeoCons: [],
            subParentGeoCons: [],
          });
        },
        expandAllGeographies() {
          let parentCheckBoxSelectedData: any = [...parentCheckBoxSelected];
          let subParentCheckBoxSelectedData: any = [
            ...subParentCheckBoxSelected,
          ];
          let consGeoSelected = { ...consolidationSelected };
          consolidationGeographies?.map((parentConsGeo: any) => {
            parentCheckBoxSelectedData.indexOf(parentConsGeo.code) === -1 &&
              parentCheckBoxSelectedData.push(parentConsGeo.code);

            parentConsGeo.subConsolidations.map((subPrentConsGeo: any) => {
              subParentCheckBoxSelectedData.indexOf(subPrentConsGeo.code) ===
                -1 && subParentCheckBoxSelectedData.push(subPrentConsGeo.code);
            });
          });

          consGeoSelected.parentGeoCons = parentCheckBoxSelectedData;
          consGeoSelected.subParentGeoCons = subParentCheckBoxSelectedData;
          setConsolidationSelected(consGeoSelected);
        },
      }),
      [consolidationGeographies]
    );

    useEffect(() => {
      if (
        totalDropDownCount ===
        consolidationSelected.parentGeoCons.length +
          consolidationSelected.subParentGeoCons.length
      ) {
        setEnableExpandAllButton(false);
      } else {
        setEnableExpandAllButton(true);
      }

      if (
        consolidationSelected.parentGeoCons.length > 0 ||
        consolidationSelected.subParentGeoCons.length > 0
      ) {
        setEnableCollapseAllButton(true);
      } else {
        setEnableCollapseAllButton(false);
      }
    }, [totalDropDownCount, consolidationSelected]);

    useEffect(() => {
      let totalCount = 0;
      let totalConsGeoSelectedCount =
        (subParentCheckBoxSelected.length
          ? subParentCheckBoxSelected.length
          : 0) +
        (childSubConsolidationGeographyCheckBoxSelected.length
          ? childSubConsolidationGeographyCheckBoxSelected.length
          : 0) +
        (childCheckBoxSelected.length ? childCheckBoxSelected.length : 0);

      Object.keys(totalGeographies).map((item: any) => {
        totalCount += totalGeographies[item];
      });

      if (totalCount === totalConsGeoSelectedCount) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }, [
      totalGeographies,
      parentCheckBoxSelected,
      subParentCheckBoxSelected,
      childSubConsolidationGeographyCheckBoxSelected,
      childCheckBoxSelected,
    ]);

    useEffect(() => {
      let totalGeographies: any = {};
      let totalSubGeographies: any = {};
      let totalParentConsGeoCount = 0;
      let totalSubParentConsGeoCount = 0;

      // SET TOTAL COUNT FOR ALL GEOGRAPHIES UNDER PARENT GEOGRAPHY
      consolidationGeographies?.map((parentCons: any) => {
        if (parentCons.code) {
          totalParentConsGeoCount += 1;
        }
        parentCons.geographies.map((childGeoCons: any) => {
          if (childGeoCons.code) {
            if (totalGeographies[parentCons.code]) {
              totalGeographies[parentCons.code] =
                totalGeographies[parentCons.code] + 1;
            } else {
              totalGeographies[parentCons.code] = 1;
            }
          }
        });

        if (parentCons?.subConsolidations?.length > 0) {
          if (totalGeographies[parentCons.code]) {
            totalGeographies[parentCons.code] =
              totalGeographies[parentCons.code] +
              parentCons?.subConsolidations?.length;
          } else {
            totalGeographies[parentCons.code] =
              parentCons?.subConsolidations?.length;
          }

          parentCons?.subConsolidations?.map((subCons: any) => {
            if (subCons.code) {
              totalSubParentConsGeoCount += 1;
            }
            subCons.geographies.map((subChildGeoCons: any) => {
              if (subChildGeoCons.code) {
                if (totalGeographies[parentCons.code]) {
                  totalGeographies[parentCons.code] =
                    totalGeographies[parentCons.code] + 1;
                } else {
                  totalGeographies[parentCons.code] = 1;
                }
              }
            });
          });
        }
      });

      // SET TOTAL COUNT FOR ALL SUBCHILD GEOGRAPHIES UNDER SUBPARENT GEOGRAPHIES
      consolidationGeographies?.map((parentCons: any) => {
        parentCons?.subConsolidations?.map((subCons: any) => {
          subCons.geographies.map((subChildGeoCons: any) => {
            if (subChildGeoCons.code) {
              if (totalSubGeographies[subCons.code]) {
                totalSubGeographies[subCons.code] =
                  totalSubGeographies[subCons.code] + 1;
              } else {
                totalSubGeographies[subCons.code] = 1;
              }
            }
          });
        });
      });

      seTotalDropdownCount(
        totalParentConsGeoCount + totalSubParentConsGeoCount
      );
      setTotalSubGeographies(totalSubGeographies);
      setTotalGeogrpahies(totalGeographies);
    }, [consolidationGeographies]);

    const handleOnChangeAllMembers = (e: any) => {
      setChecked((preVal: any) => !preVal);

      let parentCheckBoxSelectedData: any = [...parentCheckBoxSelected];
      let subParentCheckBoxSelectedData: any = [...subParentCheckBoxSelected];
      let childSubConsolidationGeographyCheckBoxSelectedData: any = [
        ...childSubConsolidationGeographyCheckBoxSelected,
      ];
      let childCheckBoxSelectedData: any = [...childCheckBoxSelected];

      if (e.target.checked) {
        consolidationGeographies?.map((parentConsGeo: any) => {
          // PUSH PARENT GEOGRAPHIES IF NOT SELECTED
          parentCheckBoxSelectedData.indexOf(parentConsGeo.code) === -1 &&
            parentCheckBoxSelectedData.push(parentConsGeo.code);

          // PUSH CHILD GEOGRAPHIES IF NOT SELECTED
          parentConsGeo.geographies.map((childConsGeo: any) => {
            if (childConsGeo.code) {
              childCheckBoxSelectedData.indexOf(childConsGeo.code) === -1 &&
                childCheckBoxSelectedData.push(childConsGeo.code);
            }
          });

          // PUSH SUB PARENT GEOGRAPHIES IF NOT SELECTED
          parentConsGeo.subConsolidations.map((subPrentConsGeo: any) => {
            subParentCheckBoxSelectedData.indexOf(subPrentConsGeo.code) ===
              -1 && subParentCheckBoxSelectedData.push(subPrentConsGeo.code);

            // PUSH SUB CHILD GEOGRAPHIES IF NOT SELECTED
            subPrentConsGeo.geographies.map((subChildConsGeo: any) => {
              childSubConsolidationGeographyCheckBoxSelectedData.indexOf(
                subChildConsGeo.code
              ) === -1 &&
                childSubConsolidationGeographyCheckBoxSelectedData.push(
                  subChildConsGeo.code
                );
            });
          });
        });
      } else {
        parentCheckBoxSelectedData = [];
        subParentCheckBoxSelectedData = [];
        childSubConsolidationGeographyCheckBoxSelectedData = [];
        childCheckBoxSelectedData = [];
      }

      setParentCheckBoxSelected(parentCheckBoxSelectedData);
      setSubParentCheckBoxSelected(subParentCheckBoxSelectedData);
      setChildSubConsolidationGeographyCheckBoxSelected(
        childSubConsolidationGeographyCheckBoxSelectedData
      );
      setChildCheckBoxSelected(childCheckBoxSelectedData);
    };

    const handleOnParentGeographyClick = (code: any) => {
      let consGeoSelected = { ...consolidationSelected };
      if (
        consGeoSelected?.parentGeoCons?.length > 0 &&
        consGeoSelected?.parentGeoCons?.includes(code)
      ) {
        consGeoSelected.parentGeoCons = consGeoSelected.parentGeoCons.filter(
          (item: any) => {
            return item !== code;
          }
        );
      } else {
        consGeoSelected.parentGeoCons = [
          ...(consGeoSelected.parentGeoCons || []),
          code,
        ];
      }
      setConsolidationSelected(consGeoSelected);
    };

    const handleOnSubGeographyClick = (code: any) => {
      let consGeoSelected = { ...consolidationSelected };
      if (
        consGeoSelected?.subParentGeoCons?.length > 0 &&
        consGeoSelected?.subParentGeoCons?.includes(code)
      ) {
        consGeoSelected.subParentGeoCons =
          consGeoSelected.subParentGeoCons.filter((item: any) => {
            return item !== code;
          });
      } else {
        consGeoSelected.subParentGeoCons = [
          ...(consGeoSelected.subParentGeoCons || []),
          code,
        ];
      }
      setConsolidationSelected(consGeoSelected);
    };

    const handleOnChangeParent = (e: any) => {
      let parentCheckBoxSelectedData: any = [...parentCheckBoxSelected];
      let subParentCheckBoxSelectedData: any = [...subParentCheckBoxSelected];
      let childSubConsolidationGeographyCheckBoxSelectedData: any = [
        ...childSubConsolidationGeographyCheckBoxSelected,
      ];
      let childCheckBoxSelectedData: any = [...childCheckBoxSelected];

      if (parentCheckBoxSelectedData.includes(e.target.value)) {
        parentCheckBoxSelectedData = parentCheckBoxSelectedData.filter(
          (item: any) => {
            return item !== e.target.value;
          }
        );
      } else {
        parentCheckBoxSelectedData = [
          ...parentCheckBoxSelectedData,
          e.target.value,
        ];
      }

      consolidationGeographies?.map((parentConsGeo: any) => {
        parentConsGeo.geographies.map((childConsGeo: any) => {
          if (parentCheckBoxSelectedData.includes(parentConsGeo.code)) {
            if (childConsGeo.code) {
              childCheckBoxSelectedData.indexOf(childConsGeo.code) === -1 &&
                childCheckBoxSelectedData.push(childConsGeo.code);
            }
          } else {
            childCheckBoxSelectedData = childCheckBoxSelectedData.filter(
              (item: any) => {
                return item !== childConsGeo.code;
              }
            );
          }
        });

        parentConsGeo.subConsolidations.map((subConsGeo: any) => {
          if (parentCheckBoxSelectedData.includes(parentConsGeo.code)) {
            if (subConsGeo.code) {
              subParentCheckBoxSelectedData.indexOf(subConsGeo.code) === -1 &&
                subParentCheckBoxSelectedData.push(subConsGeo.code);
            }
          } else {
            subParentCheckBoxSelectedData =
              subParentCheckBoxSelectedData.filter((item: any) => {
                return item !== subConsGeo.code;
              });
          }

          subConsGeo.geographies.map((subChilConsGeo: any) => {
            if (parentCheckBoxSelectedData.includes(parentConsGeo.code)) {
              if (subChilConsGeo.code) {
                childSubConsolidationGeographyCheckBoxSelectedData.indexOf(
                  subChilConsGeo.code
                ) === -1 &&
                  childSubConsolidationGeographyCheckBoxSelectedData.push(
                    subChilConsGeo.code
                  );
              }
            } else {
              childSubConsolidationGeographyCheckBoxSelectedData =
                childSubConsolidationGeographyCheckBoxSelectedData.filter(
                  (item: any) => {
                    return item !== subChilConsGeo.code;
                  }
                );
            }
          });
        });
      });
      setParentCheckBoxSelected(parentCheckBoxSelectedData);
      setSubParentCheckBoxSelected(subParentCheckBoxSelectedData);
      setChildSubConsolidationGeographyCheckBoxSelected(
        childSubConsolidationGeographyCheckBoxSelectedData
      );
      setChildCheckBoxSelected(childCheckBoxSelectedData);
    };

    const handleOnChangeSubParent = (e: any) => {
      let parentCheckBoxSelectedData: any = [...parentCheckBoxSelected];
      let subParentCheckBoxSelectedData: any = [...subParentCheckBoxSelected];
      let childSubConsolidationGeographyCheckBoxSelectedData: any = [
        ...childSubConsolidationGeographyCheckBoxSelected,
      ];
      let childCheckBoxSelectedData: any = [...childCheckBoxSelected];
      let parentCheckbox = "";

      // TO SET PARENT CHECKBOX
      consolidationGeographies?.map((parentConsGeo: any) => {
        parentConsGeo.subConsolidations.map((subParentConsGeo: any) => {
          if (subParentConsGeo.code === e.target.value) {
            return (parentCheckbox = parentConsGeo.code);
          }
        });
      });

      // CHECK IF SUBPARENT CHECKBOX ALREADY SELECTED OR NOT
      if (subParentCheckBoxSelectedData.includes(e.target.value)) {
        subParentCheckBoxSelectedData = subParentCheckBoxSelectedData.filter(
          (item: any) => {
            return item !== e.target.value;
          }
        );
        // IF PARENT CHECKBOX ALREADY SELECTED THEN REMOVE IT
        if (parentCheckBoxSelectedData.includes(parentCheckbox)) {
          parentCheckBoxSelectedData = parentCheckBoxSelectedData.filter(
            (item: any) => {
              return item !== parentCheckbox;
            }
          );
        }

        // REMOVE SUBGEOGRAPHIES IF SUBPARENT CHECKBOX ALREADY SELECTED
        consolidationGeographies?.map((parentConsGeo: any) => {
          parentConsGeo.subConsolidations.map((subParentConsGeo: any) => {
            subParentConsGeo.geographies.map((subChilConsGeo: any) => {
              if (subParentConsGeo.code === e.target.value) {
                childSubConsolidationGeographyCheckBoxSelectedData =
                  childSubConsolidationGeographyCheckBoxSelectedData.filter(
                    (item: any) => {
                      return item !== subChilConsGeo.code;
                    }
                  );
              }
            });
          });
        });
      } else {
        subParentCheckBoxSelectedData = [
          ...subParentCheckBoxSelectedData,
          e.target.value,
        ];

        // ADD SUBGEOGRAPHIES IF SUBPARENT GEOGRAPHY SELECTED
        consolidationGeographies?.map((parentConsGeo: any) => {
          parentConsGeo.subConsolidations.map((subParentConsGeo: any) => {
            subParentConsGeo.geographies.map((subChilConsGeo: any) => {
              if (subParentConsGeo.code === e.target.value) {
                if (
                  childSubConsolidationGeographyCheckBoxSelectedData.indexOf(
                    subChilConsGeo.code
                  ) === -1
                ) {
                  childSubConsolidationGeographyCheckBoxSelectedData.push(
                    subChilConsGeo.code
                  );
                }
              }
            });
          });
        });
      }

      let totalCountObject: any = {};

      consolidationGeographies?.map((parentConsGeo: any) => {
        if (parentCheckbox === parentConsGeo.code) {
          parentConsGeo.geographies.map((childConsGeo: any) => {
            if (childCheckBoxSelectedData.includes(childConsGeo.code)) {
              totalCountObject[parentCheckbox] = {
                ...totalCountObject[parentCheckbox],
                childGeos: [
                  ...(totalCountObject[parentCheckbox]?.childGeos || []),
                  childConsGeo.code,
                ],
              };
            }
          });
          parentConsGeo.subConsolidations.map((subParentConsGeo: any) => {
            if (subParentCheckBoxSelectedData.includes(subParentConsGeo.code)) {
              totalCountObject[parentCheckbox] = {
                ...totalCountObject[parentCheckbox],
                subParentGeos: [
                  ...(totalCountObject[parentCheckbox]?.subParentGeos || []),
                  subParentConsGeo.code,
                ],
              };
            }
            subParentConsGeo.geographies.map((subChildConsGeo: any) => {
              if (
                childSubConsolidationGeographyCheckBoxSelectedData.includes(
                  subChildConsGeo.code
                )
              ) {
                totalCountObject[parentCheckbox] = {
                  ...totalCountObject[parentCheckbox],
                  subChildGeos: [
                    ...(totalCountObject[parentCheckbox]?.subChildGeos || []),
                    subChildConsGeo.code,
                  ],
                };
              }
            });
          });
        }
      });

      // IF TOTAL GEOGRAPHIES IS EQUAL TO TOTAL ADDITION OF ALL CHILDREN GEOGRAPHIES THEN ADD BACK THE PARENT CHECKBOX
      if (
        totalGeographies[parentCheckbox] ===
        (totalCountObject?.[parentCheckbox]?.childGeos?.length
          ? totalCountObject?.[parentCheckbox]?.childGeos?.length
          : 0) +
          (totalCountObject?.[parentCheckbox]?.subParentGeos?.length
            ? totalCountObject?.[parentCheckbox]?.subParentGeos?.length
            : 0) +
          (totalCountObject?.[parentCheckbox]?.subChildGeos?.length
            ? totalCountObject?.[parentCheckbox]?.subChildGeos?.length
            : 0)
      ) {
        parentCheckBoxSelectedData = [
          ...parentCheckBoxSelectedData,
          parentCheckbox,
        ];
      }
      setParentCheckBoxSelected(parentCheckBoxSelectedData);
      setSubParentCheckBoxSelected(subParentCheckBoxSelectedData);
      setChildSubConsolidationGeographyCheckBoxSelected(
        childSubConsolidationGeographyCheckBoxSelectedData
      );
    };
    const handleOnChangeSubConsolidationGeographyChild = (e: any) => {
      let parentCheckBoxSelectedData: any = [...parentCheckBoxSelected];
      let subParentCheckBoxSelectedData: any = [...subParentCheckBoxSelected];
      let childSubConsolidationGeographyCheckBoxSelectedData: any = [
        ...childSubConsolidationGeographyCheckBoxSelected,
      ];
      let childCheckBoxSelectedData: any = [...childCheckBoxSelected];
      let parentCheckbox = "";
      let subParentChecbox = "";
      let totalSubGeographiesData: any = {};

      // TO SET PARENT CHECKBOX
      consolidationGeographies?.map((parentConsGeo: any) => {
        parentConsGeo.subConsolidations.map((subParentConsGeo: any) => {
          subParentConsGeo.geographies.map((subChilConsGeo: any) => {
            if (subChilConsGeo.code === e.target.value) {
              return (parentCheckbox = parentConsGeo.code);
            }
          });
        });
      });

      // TO SET SUBPARENT CHECKBOX
      consolidationGeographies?.map((parentConsGeo: any) => {
        parentConsGeo.subConsolidations.map((subParentConsGeo: any) => {
          subParentConsGeo.geographies.map((subChilConsGeo: any) => {
            if (subChilConsGeo.code === e.target.value) {
              return (subParentChecbox = subParentConsGeo.code);
            }
          });
        });
      });

      if (
        childSubConsolidationGeographyCheckBoxSelectedData.includes(
          e.target.value
        )
      ) {
        childSubConsolidationGeographyCheckBoxSelectedData =
          childSubConsolidationGeographyCheckBoxSelectedData.filter(
            (item: any) => {
              return item !== e.target.value;
            }
          );

        // IF PARENT CHECKBOX ALREADY SELECTED THEN REMOVE IT
        if (parentCheckBoxSelectedData.includes(parentCheckbox)) {
          parentCheckBoxSelectedData = parentCheckBoxSelectedData.filter(
            (item: any) => {
              return item !== parentCheckbox;
            }
          );
        }

        // IF SUBPARENT ALREADY SELECTED THEN REMOVE IT
        if (subParentCheckBoxSelectedData.includes(subParentChecbox)) {
          subParentCheckBoxSelectedData = subParentCheckBoxSelectedData.filter(
            (item: any) => {
              return item !== subParentChecbox;
            }
          );
        }
      } else {
        childSubConsolidationGeographyCheckBoxSelectedData = [
          ...childSubConsolidationGeographyCheckBoxSelectedData,
          e.target.value,
        ];
      }

      // ADD SUBPARENT GEOGRAPHY IF ALL SUBCHILDREN GEOGRAPHIES ARE SELECTED
      consolidationGeographies?.map((parentConsGeo: any) => {
        parentConsGeo.subConsolidations.map((subParentConsGeo: any) => {
          subParentConsGeo.geographies.map((subChildConsGeo: any) => {
            if (
              childSubConsolidationGeographyCheckBoxSelectedData.includes(
                subChildConsGeo.code
              )
            ) {
              if (totalSubGeographiesData[subParentConsGeo.code]) {
                totalSubGeographiesData[subParentConsGeo.code] =
                  totalSubGeographiesData[subParentConsGeo.code] + 1;
              } else {
                totalSubGeographiesData[subParentConsGeo.code] = 1;
              }
            }
          });
        });
      });

      // IF TOTAL SUB GEOGRAPHIES IS EQUAL TO TOTAL ADDITION OF ALL SUB CHILDREN GEOGRAPHIES THEN ADD BACK THE SUB PARENT CHECKBOX
      if (
        totalSubGeographies[subParentChecbox] ===
        totalSubGeographiesData[subParentChecbox]
      ) {
        subParentCheckBoxSelectedData = [
          ...subParentCheckBoxSelectedData,
          subParentChecbox,
        ];
      }

      let totalCountObject: any = {};

      consolidationGeographies?.map((parentConsGeo: any) => {
        if (parentCheckbox === parentConsGeo.code) {
          parentConsGeo.geographies.map((childConsGeo: any) => {
            if (childCheckBoxSelectedData.includes(childConsGeo.code)) {
              totalCountObject[parentCheckbox] = {
                ...totalCountObject[parentCheckbox],
                childGeos: [
                  ...(totalCountObject[parentCheckbox]?.childGeos || []),
                  childConsGeo.code,
                ],
              };
            }
          });
          parentConsGeo.subConsolidations.map((subParentConsGeo: any) => {
            if (subParentCheckBoxSelectedData.includes(subParentConsGeo.code)) {
              totalCountObject[parentCheckbox] = {
                ...totalCountObject[parentCheckbox],
                subParentGeos: [
                  ...(totalCountObject[parentCheckbox]?.subParentGeos || []),
                  subParentConsGeo.code,
                ],
              };
            }
            subParentConsGeo.geographies.map((subChildConsGeo: any) => {
              if (
                childSubConsolidationGeographyCheckBoxSelectedData.includes(
                  subChildConsGeo.code
                )
              ) {
                totalCountObject[parentCheckbox] = {
                  ...totalCountObject[parentCheckbox],
                  subChildGeos: [
                    ...(totalCountObject[parentCheckbox]?.subChildGeos || []),
                    subChildConsGeo.code,
                  ],
                };
              }
            });
          });
        }
      });

      // IF TOTAL GEOGRAPHIES IS EQUAL TO TOTAL ADDITION OF ALL CHILDREN GEOGRAPHIES THEN ADD BACK THE PARENT CHECKBOX
      if (
        totalGeographies[parentCheckbox] ===
        (totalCountObject?.[parentCheckbox]?.childGeos?.length
          ? totalCountObject?.[parentCheckbox]?.childGeos?.length
          : 0) +
          (totalCountObject?.[parentCheckbox]?.subParentGeos?.length
            ? totalCountObject?.[parentCheckbox]?.subParentGeos?.length
            : 0) +
          (totalCountObject?.[parentCheckbox]?.subChildGeos?.length
            ? totalCountObject?.[parentCheckbox]?.subChildGeos?.length
            : 0)
      ) {
        parentCheckBoxSelectedData = [
          ...parentCheckBoxSelectedData,
          parentCheckbox,
        ];
      }

      setParentCheckBoxSelected(parentCheckBoxSelectedData);
      setSubParentCheckBoxSelected(subParentCheckBoxSelectedData);
      setChildSubConsolidationGeographyCheckBoxSelected(
        childSubConsolidationGeographyCheckBoxSelectedData
      );
    };
    const handleOnChangeChild = (e: any) => {
      let parentCheckBoxSelectedData: any = [...parentCheckBoxSelected];
      let subParentCheckBoxSelectedData: any = [...subParentCheckBoxSelected];
      let childSubConsolidationGeographyCheckBoxSelectedData: any = [
        ...childSubConsolidationGeographyCheckBoxSelected,
      ];
      let childCheckBoxSelectedData: any = [...childCheckBoxSelected];
      let parentCheckbox = "";

      // TO SET PARENT CHECKBOX
      consolidationGeographies?.map((parentConsGeo: any) => {
        parentConsGeo.geographies.map((childConsGeo: any) => {
          if (childConsGeo.code) {
            if (childConsGeo.code === e.target.value) {
              return (parentCheckbox = parentConsGeo.code);
            }
          }
        });
      });

      if (childCheckBoxSelectedData.includes(e.target.value)) {
        childCheckBoxSelectedData = childCheckBoxSelectedData.filter(
          (item: any) => {
            return item !== e.target.value;
          }
        );
        // IF PARENT CHECKBOX ALREADY SELECTED THEN REMOVE IT
        if (parentCheckBoxSelectedData.includes(parentCheckbox)) {
          parentCheckBoxSelectedData = parentCheckBoxSelectedData.filter(
            (item: any) => {
              return item !== parentCheckbox;
            }
          );
        }
      } else {
        childCheckBoxSelectedData = [
          ...childCheckBoxSelectedData,
          e.target.value,
        ];
      }

      let totalCountObject: any = {};

      consolidationGeographies?.map((parentConsGeo: any) => {
        if (parentCheckbox === parentConsGeo.code) {
          parentConsGeo.geographies.map((childConsGeo: any) => {
            if (childCheckBoxSelectedData.includes(childConsGeo.code)) {
              totalCountObject[parentCheckbox] = {
                ...totalCountObject[parentCheckbox],
                childGeos: [
                  ...(totalCountObject[parentCheckbox]?.childGeos || []),
                  childConsGeo.code,
                ],
              };
            }
          });
          parentConsGeo.subConsolidations.map((subParentConsGeo: any) => {
            if (subParentCheckBoxSelectedData.includes(subParentConsGeo.code)) {
              totalCountObject[parentCheckbox] = {
                ...totalCountObject[parentCheckbox],
                subParentGeos: [
                  ...(totalCountObject[parentCheckbox]?.subParentGeos || []),
                  subParentConsGeo.code,
                ],
              };
            }
            subParentConsGeo.geographies.map((subChildConsGeo: any) => {
              if (
                childSubConsolidationGeographyCheckBoxSelectedData.includes(
                  subChildConsGeo.code
                )
              ) {
                totalCountObject[parentCheckbox] = {
                  ...totalCountObject[parentCheckbox],
                  subChildGeos: [
                    ...(totalCountObject[parentCheckbox]?.subChildGeos || []),
                    subChildConsGeo.code,
                  ],
                };
              }
            });
          });
        }
      });

      // IF TOTAL GEOGRAPHIES IS EQUAL TO TOTAL ADDITION OF ALL CHILDREN GEOGRAPHIES THEN ADD BACK THE PARENT CHECKBOX
      if (
        totalGeographies[parentCheckbox] ===
        (totalCountObject?.[parentCheckbox]?.childGeos?.length
          ? totalCountObject?.[parentCheckbox]?.childGeos?.length
          : 0) +
          (totalCountObject?.[parentCheckbox]?.subParentGeos?.length
            ? totalCountObject?.[parentCheckbox]?.subParentGeos?.length
            : 0) +
          (totalCountObject?.[parentCheckbox]?.subChildGeos?.length
            ? totalCountObject?.[parentCheckbox]?.subChildGeos?.length
            : 0)
      ) {
        parentCheckBoxSelectedData = [
          ...parentCheckBoxSelectedData,
          parentCheckbox,
        ];
      }
      setParentCheckBoxSelected(parentCheckBoxSelectedData);
      setChildCheckBoxSelected(childCheckBoxSelectedData);
    };
    return (
      <div
        className={`${BLOCK}__geography-container`}
        data-test="Consolidation-Geographies"
      >
        <div className={`${BLOCK}__all-memberFirms`}>
          <input
            type="checkbox"
            checked={checked ? true : false}
            onChange={handleOnChangeAllMembers}
          />
          <span className={`${BLOCK}__text-styling`}>
            <FormattedMessage id="consolidation.geographies.all.member.firms" />
          </span>
        </div>
        {consolidationGeographies?.length > 0 &&
          consolidationGeographies?.map((parentConsGeo: ConsolidationModel) => {
            return (
              <div
                key={`${parentConsGeo.code}`}
                className={`${BLOCK}__geography-list `}
              >
                <div
                  data-test="parentGeographyClick"
                  onClick={() =>
                    handleOnParentGeographyClick(parentConsGeo.code)
                  }
                >
                  <Icon
                    name={
                      consolidationSelected.parentGeoCons.includes(
                        parentConsGeo.code
                      )
                        ? "caretdown"
                        : "caretright"
                    }
                    className={classNames(
                      `${BLOCK}__hover ${BLOCK}__icon-margin`,
                      {
                        [`${BLOCK}__icon`]:
                          consolidationSelected.parentGeoCons.includes(
                            parentConsGeo.code
                          ),
                      }
                    )}
                    width={
                      consolidationSelected.parentGeoCons.includes(
                        parentConsGeo.code
                      )
                        ? 10
                        : 6
                    }
                  />
                </div>
                <div>
                  <input
                    type="checkbox"
                    id={parentConsGeo.code}
                    value={parentConsGeo.code}
                    checked={
                      parentCheckBoxSelected.includes(parentConsGeo.code)
                        ? true
                        : false
                    }
                    onChange={handleOnChangeParent}
                  />
                  <span
                    className={`${BLOCK}__hover ${BLOCK}__text-styling`}
                    data-test="parentGeographyClickName"
                    onClick={() =>
                      handleOnParentGeographyClick(parentConsGeo.code)
                    }
                  >
                    {parentConsGeo.name}
                  </span>
                  <span
                    className={`${BLOCK}__text-styling ${BLOCK}__total-geos`}
                  >
                    {Object.keys(totalGeographies || {}).length > 0
                      ? `(${totalGeographies[parentConsGeo.code]})`
                      : ""}
                  </span>
                  {consolidationSelected.parentGeoCons.includes(
                    parentConsGeo.code
                  ) &&
                    parentConsGeo.subConsolidations.map((subGeoCons: any) => {
                      return (
                        <div key={subGeoCons.code}>
                          <div
                            className={`${BLOCK}__subconsolidation-container`}
                          >
                            <div
                              data-test="subGeographyClick"
                              onClick={() =>
                                handleOnSubGeographyClick(subGeoCons.code)
                              }
                            >
                              <Icon
                                name={
                                  consolidationSelected.subParentGeoCons.includes(
                                    subGeoCons.code
                                  )
                                    ? "caretdown"
                                    : "caretright"
                                }
                                className={classNames(
                                  `${BLOCK}__hover ${BLOCK}__icon-margin`,
                                  {
                                    [`${BLOCK}__icon`]:
                                      consolidationSelected.subParentGeoCons.includes(
                                        subGeoCons.code
                                      ),
                                  }
                                )}
                                width={
                                  consolidationSelected.subParentGeoCons.includes(
                                    subGeoCons.code
                                  )
                                    ? 10
                                    : 6
                                }
                              />
                            </div>
                            <input
                              type="checkbox"
                              id={subGeoCons.code}
                              value={subGeoCons.code}
                              checked={
                                subParentCheckBoxSelected.includes(
                                  subGeoCons.code
                                )
                                  ? true
                                  : false
                              }
                              onChange={handleOnChangeSubParent}
                            />
                            <span
                              data-test="subGeographyClickName"
                              className={`${BLOCK}__hover ${BLOCK}__text-styling`}
                              onClick={() =>
                                handleOnSubGeographyClick(subGeoCons.code)
                              }
                            >
                              {subGeoCons.name}
                            </span>
                          </div>
                          {consolidationSelected.subParentGeoCons.includes(
                            subGeoCons.code
                          ) &&
                            subGeoCons.geographies.map(
                              (subChildGeoCons: any) => {
                                return (
                                  <div
                                    key={subChildGeoCons.code}
                                    className={`${BLOCK}__subconsolidation-geos`}
                                  >
                                    <input
                                      type="checkbox"
                                      value={subChildGeoCons.code}
                                      checked={
                                        childSubConsolidationGeographyCheckBoxSelected.includes(
                                          subChildGeoCons.code
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        handleOnChangeSubConsolidationGeographyChild
                                      }
                                    />
                                    <span className={`${BLOCK}__text-styling`}>
                                      {subChildGeoCons.name}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      );
                    })}

                  {consolidationSelected.parentGeoCons.includes(
                    parentConsGeo.code
                  ) &&
                    parentConsGeo.geographies.map((geoCons: any) => {
                      return (
                        geoCons.code &&
                        geoCons.name && (
                          <div key={geoCons.code}>
                            <input
                              type="checkbox"
                              value={geoCons.code}
                              checked={
                                childCheckBoxSelected.includes(geoCons.code)
                                  ? true
                                  : false
                              }
                              onChange={handleOnChangeChild}
                            />
                            <span className={`${BLOCK}__text-styling`}>
                              {geoCons.name}
                            </span>
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
);

export default ConsolidationGeographies;
