import classNames from "classnames";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDownloads, showDownloadTab } from "services/download/downloadSlice";
import { setInputFormSaved } from "services/inputForm/inputFormSlice";
import NotSavedPopUp from "../shared/not-saved-warning-modal/notSavedWarningPopUp";
import { MENU_ITEMS } from "utils/constants";
import MenuItem from "./menu-item";
import Icon from "../shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import history from "utils/history";
import { configurationsdPath, planSubmission } from "utils/constants";
import { useParams } from "react-router-dom";
import doubleArrowRight from "images/double-arrow-right.svg";
import doubleArrowLeft from "images/double-arrow-left.svg";

const SideMenu = () => {
  const BLOCK = "SideMenu";
  const dispatch = useDispatch();
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const [showWarningPopUp, setShowWarningPopUp] = useState<boolean>(false);
  const [destination, setDestination] = useState<string>();
  const downloadState = useSelector((state: RootStore) => state.download);
  const [isOpen, toggleOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pathname, setPathname] = useState("");
  const params: any = useParams();

  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );

  const downloadCount = downloadState.downloads?.filter(
    (download: any) => download.exportState === 3
  ).length;

  const confirmChangeTabs = (destination: string) => {
    dispatch(setInputFormSaved(true));
    history.push(destination);
  };

  let planSubmissionId = inputFormDataState?.metaData?.planSubmissionId
    ? inputFormDataState?.metaData?.planSubmissionId
    : params?.id;
  useEffect(() => {
    dispatch(getDownloads());
  }, []);

  return (
    <>
      <div className={classNames(BLOCK, { [`${BLOCK}--open`]: isOpen })}>
        <div>
          <MenuItem
            BLOCK={BLOCK}
            icon={MENU_ITEMS.dashboard.icon}
            path={MENU_ITEMS.dashboard.path}
            isMenuOpen={isOpen}
            setPathname={setPathname}
            setShowModal={setShowModal}
            label="home"
            tootlTipLabel="home"
            setShowWarningPopUp={setShowWarningPopUp}
            setDestination={setDestination}
          />
          <MenuItem
            BLOCK={BLOCK}
            icon={MENU_ITEMS.configuration.icon}
            path={
              planSubmissionId &&
              `${planSubmission}/${planSubmissionId}${configurationsdPath}`
            }
            isMenuOpen={isOpen}
            setPathname={setPathname}
            setShowModal={setShowModal}
            label="geography-inputs"
            tootlTipLabel={
              planSubmissionId && isOpen
                ? ""
                : planSubmissionId && !isOpen
                ? "geography-inputs"
                : !planSubmissionId && "ConfigurationWithoutPlanSubmisssionId"
            }
            configuration={inputFormDataState}
            setShowWarningPopUp={setShowWarningPopUp}
            setDestination={setDestination}
          />
          {/* New Dashboard */}
          <MenuItem
            BLOCK={BLOCK}
            icon={MENU_ITEMS.Visual_dashboard.icon}
            path={MENU_ITEMS.Visual_dashboard.path}
            isMenuOpen={isOpen}
            setPathname={setPathname}
            setShowModal={setShowModal}
            label="dashboard"
            tootlTipLabel="dashboard"
            setShowWarningPopUp={setShowWarningPopUp}
            setDestination={setDestination}
          />
          <div
            className={classNames(`${BLOCK}__menu-item`)}
            onClick={() => {
              if (!downloadState.showDownloads) {
                dispatch(getDownloads());
                dispatch(showDownloadTab(!downloadState.showDownloads));
              } else dispatch(showDownloadTab(!downloadState.showDownloads));
            }}
            data-test="side-menu-downloads"
            data-testid="side-menu-downloads"
          >
            {downloadCount !== 0 && (
              <span className={`${BLOCK}__menu-item__icon`}>
                <span className={`${BLOCK}__menu-item__icon-content`}>
                  {isNaN(downloadCount) ? 0 : downloadCount.toString()}
                </span>
              </span>
            )}
            <Icon name={MENU_ITEMS.downloads.icon} height={24} />

            <div
              className={classNames(`${BLOCK}__menu-label`, {
                [`${BLOCK}__menu-label--show`]: isOpen,
              })}
            >
              <FormattedMessage id={`side-menu.menu-item.downloads`} />
            </div>
            {!isOpen && (
              <Tooltip position={TooltipPosition.right}>
                <FormattedMessage id={`side-menu.menu-item.downloads`} />
              </Tooltip>
            )}
          </div>
        </div>
        <div>
          <img
            className={`${BLOCK}--double-arrow`}
            src={!isOpen ? doubleArrowRight : doubleArrowLeft}
            onClick={() => toggleOpen(!isOpen)}
          />
        </div>
      </div>

      {showWarningPopUp && (
        <NotSavedPopUp
          showModal={setShowWarningPopUp}
          confirmChangeTabs={confirmChangeTabs}
          destination={destination}
        />
      )}
    </>
  );
};

export default SideMenu;
