import { useEffect, useState } from "react";
import { logoutCurrentUser } from "../../services/common/commons.api";
import { userLogOut } from "../../services/api.service";
import Icon from "../shared/icon/icon";

const Logout = () => {
  const BLOCK = "unauthorized";
  const [userLoggedOut, setUserLoggedOut] = useState(false);
  useEffect(() => {
    const logout = async () => {
      await logoutCurrentUser();
      userLogOut();
      // Expiring the cookies
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      setUserLoggedOut(true);
    };

    if (!userLoggedOut) {
      logout();
    }
  }, [userLoggedOut]);

  return (
    <div className={`${BLOCK}`} data-test="logout-component">
      <div className={`${BLOCK}__container`}>
        <Icon
          name={"loading"}
          width={50}
          height={50}
          className="loading-icon"
        />
      </div>
    </div>
  );
};

export default Logout;
