import { useDispatch, useSelector } from "react-redux";
import { Position } from "services/common/common.model";
import { RootStore } from "services/store.service";
import { showTooltip } from "services/common/commonsSlice";

export enum TooltipPosition {
  right = "right",
  left = "left",
  top = "top",
  bottom = "bottom",
  periodRight = "periodRight",
  topRight = "topRight",
  topLeft = "topLeft",
  frameworkleft = "frameworkleft",
  bottomLeft = "bottomLeft",
  largeTextRight = "largeTextRight",
}

type Props = {
  id?: string;
  children: JSX.Element;
  position: TooltipPosition;
  customPosition?: boolean;
  arrowPosition?: Position;
  elementDimensions?: any;
  className?: string;
  width?: number;
  executeMouseLeaveEvent?: boolean;
};

const Tooltip = ({
  id,
  children,
  position,
  customPosition,
  arrowPosition,
  elementDimensions,
  className,
  width,
  executeMouseLeaveEvent,
}: Props) => {
  const BLOCK = "tooltip";
  const tooltipMaxWidth = 240;
  //const offset = 8;
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const dispatch = useDispatch();
  const getPositionStyle = (position: any) => {
    let positionStyle = {};

    positionStyle = Object.fromEntries(
      Object.entries(position).filter(([_, value]) => value !== null)
    );

    return positionStyle;
  };

  const getCustomPosition = () => {
    switch (position) {
      case TooltipPosition.topRight:
        return {
          top: `unset`,
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${elementDimensions.x + elementDimensions.width}px`,
        };
      case TooltipPosition.topLeft:
        return {
          top: `unset`,
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${
            elementDimensions.x + elementDimensions.width - tooltipMaxWidth
          }px`,
        };
      case TooltipPosition.top:
        return {
          top: "unset",
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${
            elementDimensions.x +
            elementDimensions.width / 2 -
            tooltipMaxWidth / 2
          }px`,
        };
      case TooltipPosition.left: {
        return {
          top: "unset",
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${
            elementDimensions.x - (width ? width * 0.7 : tooltipMaxWidth)
          }px`,
        };
      }
      case TooltipPosition.bottom: {
        return {
          top: `${elementDimensions.bottom}px`,
          bottom: "unset",
          left: `${
            elementDimensions.x - (width ? width / 2 : tooltipMaxWidth)
          }px`,
        };
      }
      case TooltipPosition.bottomLeft: {
        return {
          top: `${elementDimensions.bottom}px`,
          bottom: "unset",
          left: `${elementDimensions.x - (width ? width : tooltipMaxWidth)}px`,
        };
      }
      default:
        return {
          top: null,
          bottom: null,
          right: null,
          left: null,
        };
    }
  };
  const handleMouseLeave = (e: any) => {
    if (executeMouseLeaveEvent)
      dispatch(
        showTooltip({
          children: null,
          position: null,
          customPosition: null,
          elementDimensions: null,
          className: null,
          width: null,
          executeMouseLeaveEvent: null,
          isOverTooltip: null,
        })
      );
  };
  return (
    <>
      {!customPosition && (
        <div id={id} className={`${BLOCK} ${BLOCK}--${position} ${className}`}>
          <div className={`${BLOCK}__arrow ${BLOCK}--${position}__arrow`}></div>
          <div className={`${BLOCK}__label`}>{children}</div>
        </div>
      )}
      {customPosition && (
        <div
          id={id}
          className={`${BLOCK} ${className}`}
          style={{
            ...getPositionStyle(getCustomPosition()),
          }}
          onMouseEnter={(e) => {
            if (executeMouseLeaveEvent)
              dispatch(showTooltip({ ...tooltip, isOverTooltip: true }));
          }}
          onMouseLeave={handleMouseLeave}
        >
          <div className={`${BLOCK}__arrow ${BLOCK}--${position}__arrow`}></div>
          <div className={`${BLOCK}__label`}>{children}</div>
        </div>
      )}
    </>
  );
};

export default Tooltip;
