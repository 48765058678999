import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { getHeadCountByCohortsLocalCOEData } from "services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { ConsolidationsKeyInitial } from "utils/constants";

type HeadCountCohort = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const LoacalCOEHeadCountCohortTable = ({
  geographies,
  currencyCode,
  baseYear,
}: HeadCountCohort) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        getHeadCountByCohortsLocalCOEData({
          countryCodes: geographies,
          currencyCode,
          baseYear,
        })
      );
    }
  }, [geographies, currencyCode, baseYear]);

  return (
    <div data-test="loacalCOEHeadCountCohortTable-component">
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.localCOECohort?.consolidatedHeadCountDetails
        }
        tableHeader={consolidationData?.localCOECohort?.subTotalHeadCount}
        mainTableHeader="consolidation.headcount"
        subConsolidationTableData={
          consolidationData?.localCOECohort?.subConsolidatedHeadCountDetails
        }
        keyInitial={ConsolidationsKeyInitial.LOCAL_COE_HEADCOUNT_COHORT}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return item === VISUAL_DASHBOARD_OPTIONS.LOCAL_COE_COHORT_LOADING;
          })?.length > 0
        }
      />
    </div>
  );
};

export default LoacalCOEHeadCountCohortTable;
