import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { loadTotalHeadcountCohorts } from "services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { ConsolidationsKeyInitial } from "utils/constants";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

type HeadCountCohort = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const TotalHeadCountCohortTable = ({
  geographies,
  currencyCode,
  baseYear,
}: HeadCountCohort) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  const BLOCK = "consolidatedTableData";

  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        loadTotalHeadcountCohorts({
          countryCodes: geographies,
          currencyCode,
          baseYear,
        })
      );
    }
  }, [geographies, currencyCode, baseYear]);

  return (
    <>
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.totalHeadcountCohortData
            ?.consolidatedHeadCountDetails
        }
        tableHeader={
          consolidationData?.totalHeadcountCohortData?.subTotalHeadCount
        }
        mainTableHeader="consolidation.headcount"
        subConsolidationTableData={
          consolidationData?.totalHeadcountCohortData
            ?.subConsolidatedHeadCountDetails
        }
        keyInitial={ConsolidationsKeyInitial.TOTAL_HEADCOUNT_COHORT}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return (
              item === VISUAL_DASHBOARD_OPTIONS.TOTAL_HEADCOUNTS_COHORTS_LOADING
            );
          })?.length > 0
        }
      />
    </>
  );
};

export default TotalHeadCountCohortTable;
