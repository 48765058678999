import { useEffect, useState, createContext, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import {
  loadAvgAnnualFTESalaryContext,
  loadSalaryPriceIncreaseContext,
  loadFullTimeEquavalentsContext,
  loadAttritionRatePerGradeContext,
  loadPromotionRatePerGradeContext,
  loadTargetManagedHoursContext,
  loadRateCardsContext,
  loadPriceIncreaseRatesContext,
  loadAnnualTotalHoursContext,
  loadUtilisationRatesContext,
} from "services/inputForm/inputFormSlice";
import {
  delieveryCenterID,
  TABLE_MAPPINGS,
  TABLE_MAPPINGS_PRICE_INCREASE,
  TABLE_MAPPINGS_HOURS,
  TABLE_MAPPINGS_UTIL_RATE,
  headCountTablesList,
} from "utils/constants";

const HeadCountContext: any = createContext(null);

type headCountsContext = {
  children: React.ReactNode;
};

export const HeadCountsContextProvider = ({ children }: headCountsContext) => {
  const params: any = useParams();
  const locationName = useLocation().pathname;

  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);

  let planSubmissionId = inputFormDataState?.metaData?.planSubmissionId
    ? inputFormDataState?.metaData?.planSubmissionId
    : Number(params?.id);

  const [avgAnnualFTESalary, setAvgAnnualFTESalary] = useState<any>([]);
  const [salaryPriceIncrease, setSalaryPriceIncrease] = useState<any>([]);
  const [yearlyUtilisationRate, setYearlyUtilisationRate] = useState<any>([]);
  const [fullTimeEquavalents, setFullTimeEquavalents] = useState<any>([]);
  const [attritionRatePerGrade, setAttritionRatePerGrade] = useState<any>([]);
  const [promotionRatePerGrade, setPromotionRatePerGrade] = useState<any>([]);
  const [targetManagedHours, setTargetManagedHours] = useState<any>([]);
  const [usiRateCard, setUsiRateCard] = useState<any>([]);
  const [radcRateCard, setRadcRateCard] = useState<any>([]);
  const [localDCRateCard, setLocalDCRateCard] = useState<any>([]);
  const [localCOERateCard, setLocalCoeRateCard] = useState<any>([]);
  const [usiPriceIncreaseRates, setUsiPriceIncreaseRates] = useState<any>([]);
  const [radcPriceIncreaseRates, setRadcPriceIncreaseRates] = useState<any>([]);
  const [localDCPriceIncreaseRates, setLocalDCPriceIncreaseRates] =
    useState<any>([]);
  const [localCOEPriceIncreaseRates, setLocalCoePriceIncreaseRates] =
    useState<any>([]);
  const [usiAnnualTotalHours, setUsiAnnualTotalHours] = useState<any>([]);
  const [radcAnnualTotalHours, setRadcAnnualTotalHours] = useState<any>([]);
  const [localDCAnnualTotalHours, setLocalDCAnnualTotalHours] = useState<any>(
    []
  );
  const [localCOEAnnualTotalHours, setLocalCoeAnnualTotalHours] = useState<any>(
    []
  );
  const [usiUtilisationRates, setUsiUtilisationRates] = useState<any>([]);
  const [radcUtilisationRates, setRadcUtilisationRates] = useState<any>([]);
  const [localDCUtilisationRates, setLocalDCUtilisationRates] = useState<any>(
    []
  );
  const [localCOEUtilisationRates, setLocalCoeUtilisationRates] = useState<any>(
    []
  );
  const [multipleErrorsHC, setMultipleErrorsHC] = useState<boolean>(false);

  const [compareTableData, setCompareTableData] = useState<any>({
    avgAnnualFTESalary: [],
    salaryPriceIncrease: [],
    yearlyUtilisationRate: [],
    fullTimeEquavalents: [],
    attritionRatePerGrade: [],
    promotionRatePerGrade: [],
    targetManagedHours: [],
    usiRateCard: [],
    radcRateCard: [],
    localDCRateCard: [],
    localCOERateCard: [],
    usiPriceIncreaseRates: [],
    radcPriceIncreaseRates: [],
    localDCPriceIncreaseRates: [],
    localCOEPriceIncreaseRates: [],
    usiAnnualTotalHours: [],
    radcAnnualTotalHours: [],
    localDCAnnualTotalHours: [],
    localCOEAnnualTotalHours: [],
    usiUtilisationRates: [],
    radcUtilisationRates: [],
    localDCUtilisationRates: [],
    localCOEUtilisationRates: [],
  });

  const headCountTableIdList = [
    TABLE_MAPPINGS.annualAvgFTE,
    TABLE_MAPPINGS.salaryPriceIncrease,
    TABLE_MAPPINGS.fullTimeEq,
    TABLE_MAPPINGS.attrition,
    TABLE_MAPPINGS.promotion,
    TABLE_MAPPINGS.targetManagedHours,
    TABLE_MAPPINGS.rateCardUSI,
    TABLE_MAPPINGS.rateCardRADC,
    TABLE_MAPPINGS.rateCardLocalDC,
    TABLE_MAPPINGS.rateCardLocalCOE,
    TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseUSI,
    TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseRADC,
    TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseLocalDC,
    TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaeLocalCOE,
    TABLE_MAPPINGS_HOURS.hoursUSI,
    TABLE_MAPPINGS_HOURS.hoursRADC,
    TABLE_MAPPINGS_HOURS.hoursLocalDC,
    TABLE_MAPPINGS_HOURS.hoursLocalCOE,
    TABLE_MAPPINGS_UTIL_RATE.utilRateUSI,
    TABLE_MAPPINGS_UTIL_RATE.utilRateRADC,
    TABLE_MAPPINGS_UTIL_RATE.utilLocalDC,
    TABLE_MAPPINGS_UTIL_RATE.utilLocalCOE,
  ];

  const updateCompletedFields = (tableDat: any) => {
    let fieldCount = 0;
    tableDat?.map((item: any) => {
      Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          item[id] !== null && fieldCount++;
        });
    });
    return fieldCount;
  };

  const [tableScrollIds, setTableScrolledIds] = useState<Number | null>(null);
  const [initialCompletedFields, setInitialCompletedFields] = useState<any>({});

  useEffect(() => {
    switch (inputFormDataState?.currentHeadCountTab) {
      case headCountTablesList[0].refId:
        avgAnnualFTESalary.length == 0 &&
          loadAvgAnnualFTESalaryContext(planSubmissionId).then((data) => {
            setAvgAnnualFTESalary(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              avgAnnualFTESalary: data,
            }));
          });
        break;
      case headCountTablesList[1].refId:
        salaryPriceIncrease.length == 0 &&
          loadSalaryPriceIncreaseContext(planSubmissionId).then((data) => {
            setSalaryPriceIncrease(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              salaryPriceIncrease: data,
            }));
          });
        break;
      case headCountTablesList[2].refId:
        fullTimeEquavalents.length == 0 &&
          loadFullTimeEquavalentsContext(planSubmissionId).then((data) => {
            setFullTimeEquavalents(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              fullTimeEquavalents: data,
            }));
          });
        break;
      case headCountTablesList[3].refId:
        attritionRatePerGrade.length == 0 &&
          loadAttritionRatePerGradeContext(
            planSubmissionId,
            "attritionrates"
          ).then((data) => {
            setAttritionRatePerGrade(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              attritionRatePerGrade: data,
            }));
          });
        break;
      case headCountTablesList[4].refId:
        promotionRatePerGrade.length == 0 &&
          loadPromotionRatePerGradeContext(
            planSubmissionId,
            "promotionrates"
          ).then((data) => {
            setPromotionRatePerGrade(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              promotionRatePerGrade: data,
            }));
          });
        break;
      case headCountTablesList[5].refId:
        targetManagedHours.length == 0 &&
          loadTargetManagedHoursContext(planSubmissionId).then((data) => {
            setTargetManagedHours(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              targetManagedHours: data,
            }));
          });
        break;
      case headCountTablesList[6].refId:
        rateCardGetApiCalls();
        break;
      case headCountTablesList[7].refId:
        rateCardGetApiCalls();
        break;
      case headCountTablesList[8].refId:
        rateCardGetApiCalls();
        break;
      case headCountTablesList[9].refId:
        rateCardGetApiCalls();
        break;
      case headCountTablesList[10].refId:
        priceIncreaseApiCalls();
        break;
      case headCountTablesList[11].refId:
        priceIncreaseApiCalls();
        break;
      case headCountTablesList[12].refId:
        priceIncreaseApiCalls();
        break;
      case headCountTablesList[13].refId:
        priceIncreaseApiCalls();
        break;
      case headCountTablesList[14].refId:
        annualaTotalHoursApiCall();
        break;
      case headCountTablesList[15].refId:
        annualaTotalHoursApiCall();
        break;
      case headCountTablesList[16].refId:
        annualaTotalHoursApiCall();
        break;
      case headCountTablesList[17].refId:
        annualaTotalHoursApiCall();
        break;
      case headCountTablesList[18].refId:
        UtilisationRateApiCall();
        break;
      case headCountTablesList[19].refId:
        UtilisationRateApiCall();
        break;
      case headCountTablesList[20].refId:
        UtilisationRateApiCall();
        break;
      case headCountTablesList[21].refId:
        UtilisationRateApiCall();
        break;

      default:
        break;
    }
  }, [inputFormDataState?.currentHeadCountTab]);

  const rateCardGetApiCalls = () => {
    usiRateCard.length == 0 &&
      loadRateCardsContext(planSubmissionId, delieveryCenterID.USI).then(
        (data: any) => {
          let fieldCount = updateCompletedFields(data);
          setInitialCompletedFields((prevVal: any) => {
            return {
              ...prevVal,
              usiRateCardInitialCompletedFields: fieldCount,
            };
          });
          setUsiRateCard(data);
          setCompareTableData((prevVal: any) => ({
            ...prevVal,
            usiRateCard: data,
          }));
        }
      );

    radcRateCard.length == 0 &&
      loadRateCardsContext(planSubmissionId, delieveryCenterID.RADC).then(
        (data) => {
          let fieldCount = updateCompletedFields(data);
          setInitialCompletedFields((prevVal: any) => {
            return {
              ...prevVal,
              radcRateCardInitialCompletedFields: fieldCount,
            };
          });
          setRadcRateCard(data);
          setCompareTableData((prevVal: any) => ({
            ...prevVal,
            radcRateCard: data,
          }));
        }
      );

    localDCRateCard.length == 0 &&
      loadRateCardsContext(planSubmissionId, delieveryCenterID.LocalDC).then(
        (data) => {
          let fieldCount = updateCompletedFields(data);
          setInitialCompletedFields((prevVal: any) => {
            return {
              ...prevVal,
              localDCRateCardInitialCompletedFields: fieldCount,
            };
          });
          setLocalDCRateCard(data);
          setCompareTableData((prevVal: any) => ({
            ...prevVal,
            localDCRateCard: data,
          }));
        }
      );
    localCOERateCard.length == 0 &&
      loadRateCardsContext(planSubmissionId, delieveryCenterID.LocalCOE).then(
        (data) => {
          let fieldCount = updateCompletedFields(data);
          setInitialCompletedFields((prevVal: any) => {
            return {
              ...prevVal,
              localCOERateCardInitialCompletedFields: fieldCount,
            };
          });
          setLocalCoeRateCard(data);
          setCompareTableData((prevVal: any) => ({
            ...prevVal,
            localCOERateCard: data,
          }));
        }
      );
  };
  const priceIncreaseApiCalls = () => {
    usiPriceIncreaseRates.length == 0 &&
      loadPriceIncreaseRatesContext(
        planSubmissionId,
        delieveryCenterID.USI
      ).then((data) => {
        let fieldCount = updateCompletedFields(data);
        setInitialCompletedFields((prevVal: any) => {
          return {
            ...prevVal,
            usiPriceIncreaseInitialCompletedFields: fieldCount,
          };
        });
        setUsiPriceIncreaseRates(data);
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          usiPriceIncreaseRates: data,
        }));
      });
    radcPriceIncreaseRates.length == 0 &&
      loadPriceIncreaseRatesContext(
        planSubmissionId,
        delieveryCenterID.RADC
      ).then((data) => {
        let fieldCount = updateCompletedFields(data);
        setInitialCompletedFields((prevVal: any) => {
          return {
            ...prevVal,
            radcPriceIncreaseInitialCompletedFields: fieldCount,
          };
        });
        setRadcPriceIncreaseRates(data);
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          radcPriceIncreaseRates: data,
        }));
      });
    localDCPriceIncreaseRates.length == 0 &&
      loadPriceIncreaseRatesContext(
        planSubmissionId,
        delieveryCenterID.LocalDC
      ).then((data) => {
        let fieldCount = updateCompletedFields(data);
        setInitialCompletedFields((prevVal: any) => {
          return {
            ...prevVal,
            localDcPriceIncreaseInitialCompletedFields: fieldCount,
          };
        });
        setLocalDCPriceIncreaseRates(data);
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          localDCPriceIncreaseRates: data,
        }));
      });
    localCOEPriceIncreaseRates.length == 0 &&
      loadPriceIncreaseRatesContext(
        planSubmissionId,
        delieveryCenterID.LocalCOE
      ).then((data) => {
        let fieldCount = updateCompletedFields(data);
        setInitialCompletedFields((prevVal: any) => {
          return {
            ...prevVal,
            localCOEPriceIncreaseInitialCompletedFields: fieldCount,
          };
        });
        setLocalCoePriceIncreaseRates(data);
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          localCOEPriceIncreaseRates: data,
        }));
      });
  };

  const annualaTotalHoursApiCall = () => {
    usiAnnualTotalHours.length == 0 &&
      loadAnnualTotalHoursContext(planSubmissionId, delieveryCenterID.USI).then(
        (data) => {
          let fieldCount = updateCompletedFields(data);
          setInitialCompletedFields((prevVal: any) => {
            return {
              ...prevVal,
              usiHoursInitialCompletedFields: fieldCount,
            };
          });
          setUsiAnnualTotalHours(data);
          setCompareTableData((prevVal: any) => ({
            ...prevVal,
            usiAnnualTotalHours: data,
          }));
        }
      );
    radcAnnualTotalHours.length == 0 &&
      loadAnnualTotalHoursContext(
        planSubmissionId,
        delieveryCenterID.RADC
      ).then((data) => {
        let fieldCount = updateCompletedFields(data);
        setInitialCompletedFields((prevVal: any) => {
          return {
            ...prevVal,
            radcHoursInitialCompletedFields: fieldCount,
          };
        });
        setRadcAnnualTotalHours(data);
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          radcAnnualTotalHours: data,
        }));
      });
    localDCAnnualTotalHours.length == 0 &&
      loadAnnualTotalHoursContext(
        planSubmissionId,
        delieveryCenterID.LocalDC
      ).then((data) => {
        let fieldCount = updateCompletedFields(data);
        setInitialCompletedFields((prevVal: any) => {
          return {
            ...prevVal,
            localDCHoursInitialCompletedFields: fieldCount,
          };
        });
        setLocalDCAnnualTotalHours(data);
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          localDCAnnualTotalHours: data,
        }));
      });
    localCOEAnnualTotalHours.length == 0 &&
      loadAnnualTotalHoursContext(
        planSubmissionId,
        delieveryCenterID.LocalCOE
      ).then((data) => {
        let fieldCount = updateCompletedFields(data);
        setInitialCompletedFields((prevVal: any) => {
          return {
            ...prevVal,
            localCOEHoursInitialCompletedFields: fieldCount,
          };
        });
        setLocalCoeAnnualTotalHours(data);
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          localCOEAnnualTotalHours: data,
        }));
      });
  };

  const UtilisationRateApiCall = () => {
    usiUtilisationRates.length == 0 &&
      loadUtilisationRatesContext(planSubmissionId, delieveryCenterID.USI).then(
        (data) => {
          let fieldCount = updateCompletedFields(data);
          setInitialCompletedFields((prevVal: any) => {
            return {
              ...prevVal,
              usiUtilisationInitialCompletedFields: fieldCount,
            };
          });
          setUsiUtilisationRates(data);
          setCompareTableData((prevVal: any) => ({
            ...prevVal,
            usiUtilisationRates: data,
          }));
        }
      );
    radcUtilisationRates.length == 0 &&
      loadUtilisationRatesContext(
        planSubmissionId,
        delieveryCenterID.RADC
      ).then((data) => {
        let fieldCount = updateCompletedFields(data);
        setInitialCompletedFields((prevVal: any) => {
          return {
            ...prevVal,
            radcUtilisationInitialCompletedFields: fieldCount,
          };
        });
        setRadcUtilisationRates(data);
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          radcUtilisationRates: data,
        }));
      });
    localDCUtilisationRates.length == 0 &&
      loadUtilisationRatesContext(
        planSubmissionId,
        delieveryCenterID.LocalDC
      ).then((data) => {
        let fieldCount = updateCompletedFields(data);
        setInitialCompletedFields((prevVal: any) => {
          return {
            ...prevVal,
            localDCUtilisationInitialCompletedFields: fieldCount,
          };
        });
        setLocalDCUtilisationRates(data);
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          localDCUtilisationRates: data,
        }));
      });
    localCOEUtilisationRates.length == 0 &&
      loadUtilisationRatesContext(
        planSubmissionId,
        delieveryCenterID.LocalCOE
      ).then((data) => {
        let fieldCount = updateCompletedFields(data);
        setInitialCompletedFields((prevVal: any) => {
          return {
            ...prevVal,
            localCOEUtilisationInitialCompletedFields: fieldCount,
          };
        });
        setLocalCoeUtilisationRates(data);
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          localCOEUtilisationRates: data,
        }));
      });
  };
  return (
    <HeadCountContext.Provider
      value={{
        avgAnnualFTESalary,
        setAvgAnnualFTESalary,
        salaryPriceIncrease,
        setSalaryPriceIncrease,
        yearlyUtilisationRate,
        setYearlyUtilisationRate,
        fullTimeEquavalents,
        setFullTimeEquavalents,
        attritionRatePerGrade,
        setAttritionRatePerGrade,
        promotionRatePerGrade,
        setPromotionRatePerGrade,
        targetManagedHours,
        setTargetManagedHours,
        usiRateCard,
        setUsiRateCard,
        radcRateCard,
        setRadcRateCard,
        localDCRateCard,
        setLocalDCRateCard,
        localCOERateCard,
        setLocalCoeRateCard,
        usiPriceIncreaseRates,
        setUsiPriceIncreaseRates,
        radcPriceIncreaseRates,
        setRadcPriceIncreaseRates,
        localDCPriceIncreaseRates,
        setLocalDCPriceIncreaseRates,
        localCOEPriceIncreaseRates,
        setLocalCoePriceIncreaseRates,
        usiUtilisationRates,
        setUsiUtilisationRates,
        radcUtilisationRates,
        setRadcUtilisationRates,
        localDCUtilisationRates,
        setLocalDCUtilisationRates,
        localCOEUtilisationRates,
        setLocalCoeUtilisationRates,
        usiAnnualTotalHours,
        setUsiAnnualTotalHours,
        radcAnnualTotalHours,
        setRadcAnnualTotalHours,
        localDCAnnualTotalHours,
        setLocalDCAnnualTotalHours,
        localCOEAnnualTotalHours,
        setLocalCoeAnnualTotalHours,
        compareTableData,
        setCompareTableData,
        multipleErrorsHC,
        setMultipleErrorsHC,
        headCountTableIdList,
        tableScrollIds,
        setTableScrolledIds,
        initialCompletedFields,
        setInitialCompletedFields,
      }}
    >
      {children}
    </HeadCountContext.Provider>
  );
};

export function useAPI() {
  const context = useContext(HeadCountContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
