import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { getHeadCountByCohortsRadcData } from "services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { ConsolidationsKeyInitial } from "utils/constants";

type HeadCountCohort = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const RadcHeadCountCohortTable = ({
  geographies,
  currencyCode,
  baseYear,
}: HeadCountCohort) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        getHeadCountByCohortsRadcData({
          countryCodes: geographies,
          currencyCode,
          baseYear,
        })
      );
    }
  }, [geographies, currencyCode, baseYear]);

  return (
    <div data-test="radcHeadCountCohortTable-component">
      <ConsolidationsCommonTable
        tableData={consolidationData?.radcCohort?.consolidatedHeadCountDetails}
        tableHeader={consolidationData?.radcCohort?.subTotalHeadCount}
        mainTableHeader="consolidation.headcount"
        subConsolidationTableData={
          consolidationData?.radcCohort?.subConsolidatedHeadCountDetails
        }
        keyInitial={ConsolidationsKeyInitial.RADC_HEADCOUNT_COHORT}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return item === VISUAL_DASHBOARD_OPTIONS.RADC_COHORT_LOADING;
          })?.length > 0
        }
      />
    </div>
  );
};

export default RadcHeadCountCohortTable;
