import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { loadNetFeePerHour } from "services/visualDashboard/visualDashBoardSlice";
import {
  ConsolidationsKeyInitial,
  DashBoardCurrencyHeader,
} from "utils/constants";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";

type NetFeePerhour = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const NetFeePerhourTable = ({
  geographies,
  currencyCode,
  baseYear,
}: NetFeePerhour) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );

  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        loadNetFeePerHour({ countryCodes: geographies, currencyCode, baseYear })
      );
    }
  }, [geographies, currencyCode, baseYear]);
  return (
    <div>
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.netFeePerHourData?.consolidatedGrossMarginDetails
        }
        tableHeader={consolidationData?.netFeePerHourData?.subTotalGrossMargin}
        mainTableHeader={`${DashBoardCurrencyHeader.NET_FEE_PER_HOUR}`}
        subConsolidationTableData={
          consolidationData?.netFeePerHourData
            ?.subConsolidatedGrossMarginDetails
        }
        keyInitial={ConsolidationsKeyInitial.NET_FEE_PER_HOUR}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return item === VISUAL_DASHBOARD_OPTIONS.NET_FEE_PER_HOUR_LOADING;
          })?.length > 0
        }
        isFormattedText={false}
      />
    </div>
  );
};

export default NetFeePerhourTable;
