import React, { useState, useRef, useEffect } from "react";
import Modal from "../shared/modal/modal";
import AddNewGeoFilters from "./addNewGeoFilters";
import {
  setGeoFilter,
  setPreparerFilter,
  setMemberFirmAdmin,
  setReviewerFilter,
  loadPreparerFilter,
  loadMemberFirmAdmin,
  loadReviewerFilter,
} from "services/geoFilters/geoSlice";
import { useDispatch } from "react-redux";

type props = {
  setShowGeographyModal: (value: boolean) => void;
};

const AddNewGeo = ({ setShowGeographyModal }: props) => {
  const dispatch = useDispatch();
  const BLOCK = "AddNewGeo";

  const handleClose = () => {
    dispatch(setGeoFilter([]));
    dispatch(setPreparerFilter([]));
    dispatch(setReviewerFilter([]));
    dispatch(setMemberFirmAdmin([]));
    setShowGeographyModal(false);
    const payload = { ContentText: "" };
    dispatch(loadPreparerFilter({ countryCode: "", payload }));
    dispatch(loadMemberFirmAdmin({ countryCode: "", payload }));
    dispatch(loadReviewerFilter({ countryCode: "", payload }));
  };

  return (
    <Modal
      show={true}
      header="addnew.geo.title"
      handleClose={handleClose}
      config={false}
    >
      <div className={BLOCK} data-test="component-feedback">
        <AddNewGeoFilters
          BLOCK={BLOCK}
          setShowGeographyModal={setShowGeographyModal}
        />
      </div>
    </Modal>
  );
};

export default AddNewGeo;
