import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { ConsolidationsKeyInitial } from "utils/constants";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { getTechnicalStaffCostLocalCOEData } from "services/visualDashboard/visualDashBoardSlice";

type TechnicalStaffCostLocalCOE = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const TechnicalStaffCostLocalCOETable = ({
  geographies,
  currencyCode,
  baseYear,
}: TechnicalStaffCostLocalCOE) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        getTechnicalStaffCostLocalCOEData({
          countryCodes: geographies,
          currencyCode,
          baseYear,
        })
      );
    }
  }, [geographies, currencyCode, baseYear]);

  return (
    <div data-test="technicalStaffCostLocalCOETable-component">
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.technicalStaffCostLocalCOEData
            ?.consolidatedProfitAndLossDetails
        }
        tableHeader={
          consolidationData?.technicalStaffCostLocalCOEData
            ?.subTotalProfitAndLoss
        }
        mainTableHeader="consolidation.technical.staaff.cost.local.coe"
        subConsolidationTableData={
          consolidationData?.technicalStaffCostLocalCOEData
            ?.subConsolidatedProfitAndLossDetails
        }
        keyInitial={ConsolidationsKeyInitial.TECHNICAL_STAFF_COST_LOCAL_COE}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return (
              item ===
              VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_LOCAL_COE_LOADING
            );
          })?.length > 0
        }
      />
    </div>
  );
};

export default TechnicalStaffCostLocalCOETable;
