import React, { useState, useRef, useEffect } from "react";
import Modal from "../shared/modal/modal";
import { useDispatch } from "react-redux";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import { Line } from "rc-progress";
import Icon from "../shared/icon/icon";
import {
  setRollForwardProcess,
  getRollForwardData,
  getDashboardTableList,
  getFilterYears,
  setSelectedYear,
} from "../../services/dashboard/dashBoardSlice";

type props = {
  setRollForwardModal: (value: boolean) => void;
  setTripleConfirmationCount: Function;
  tripleConfirmationCount: number;
  BLOCK: string;
  header: string;
  buttonNamme: string;
  content?: string;
  checkBox?: string;
  current_Year?: number;
};

const RollForwardModel = ({
  setRollForwardModal,
  setTripleConfirmationCount,
  tripleConfirmationCount,
  BLOCK,
  header,
  buttonNamme,
  content,
  checkBox,
  current_Year,
}: props) => {
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const [percentage, setPercentage] = useState<number>(0);
  const [isInProcess, setIsInProcess] = useState<boolean>(false);
  const [isProcessDone, setIsProcessDone] = useState<boolean>(false);
  const [isProcessSuccess, setisProcessSuccess] = useState<boolean>(false);

  const config = {
    onUploadProgress: (progressEvent: any) => {
      setPercentage(
        Math.round((100 * progressEvent.loaded) / progressEvent.total)
      );
    },
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (tripleConfirmationCount === 4 && current_Year) {
      setRollForwardProcess(current_Year, config)
        .then((response: any) => {
          setIsInProcess(false);
          setIsProcessDone(true);
          if (response?.data === true) {
            setisProcessSuccess(true);
            dispatch(getRollForwardData());
            dispatch(getFilterYears());
            dispatch(getDashboardTableList(null));
            dispatch(
              setSelectedYear({
                year: current_Year + 1,
                id: 1,
              })
            );
          } else {
            setisProcessSuccess(false);
          }
        })
        .catch(() => {
          setIsInProcess(false);
          setIsProcessDone(true);
          setisProcessSuccess(false);
        });
    }
  }, [tripleConfirmationCount]);

  const handleClose = () => {
    setRollForwardModal(false);
  };
  return (
    <Modal
      show={true}
      header={header}
      handleClose={handleClose}
      config={false}
      data-test="component-RollForwardModel"
    >
      <div className={BLOCK}>
        <div className={`${BLOCK}__content`}>
          {content ? (
            <>
              <div className={`${BLOCK}__contentText`}>{content}</div>
              {checkBox && (
                <div className={`${BLOCK}__checkBoxContainer`}>
                  <input
                    type="checkbox"
                    data-test="checkbox"
                    defaultChecked={checkBoxValue}
                    onClick={(e) => setCheckBoxValue(!checkBoxValue)}
                  ></input>
                  {checkBox}
                </div>
              )}
            </>
          ) : (
            <>
              <div className={`${BLOCK}__progressTextContainer`}>
                <div className={`${BLOCK}__progressText`}>
                  {isInProcess && !isProcessDone ? (
                    <FormattedMessage id="roll.forward.progress.process"></FormattedMessage>
                  ) : !isProcessSuccess ? (
                    <FormattedMessage id="roll.forward.progress.error"></FormattedMessage>
                  ) : isProcessSuccess ? (
                    <FormattedMessage id="roll.forward.progress.success"></FormattedMessage>
                  ) : null}
                </div>
                {isProcessSuccess && isProcessDone && (
                  <div className={`${BLOCK}__sucessIcon`}>
                    <Icon name="progresSuccess" height={20} />
                  </div>
                )}
              </div>
              <div className={`${BLOCK}_progressContainer`}>
                <Line
                  percent={percentage}
                  trailWidth={1}
                  strokeWidth={1}
                  strokeColor={
                    percentage === 100 && isProcessSuccess && isProcessDone
                      ? "#43B02A"
                      : percentage === 100 && !isProcessSuccess && isProcessDone
                      ? "#DA291C"
                      : "#00A3E0"
                  }
                />
              </div>
            </>
          )}
        </div>

        <div className={`${BLOCK}__buttonsLayout`}>
          <div className={`${BLOCK}__buttonsContainer`}>
            {!isProcessDone && (
              <button
                className={
                  !isInProcess ? `primary-outline` : `${BLOCK}__cancelDisabled`
                }
                onClick={handleClose}
                disabled={isInProcess}
              >
                <FormattedMessage id="roll.forward.button.cancel" />
              </button>
            )}

            <button
              data-test="confirmButton"
              onClick={(e) => {
                isProcessDone && handleClose();
                tripleConfirmationCount === 3 && setIsInProcess(true);
                setTripleConfirmationCount(tripleConfirmationCount + 1);
              }}
              className={
                isInProcess || (checkBox && !checkBoxValue)
                  ? `${BLOCK}__rollForwardButtonDisable`
                  : `${BLOCK}__rollForwardButton`
              }
              disabled={
                isInProcess || (checkBox && !checkBoxValue) ? true : false
              }
            >
              {isProcessDone ? (
                <FormattedMessage id="roll.forward.button.step4" />
              ) : (
                <FormattedMessage id={buttonNamme} />
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RollForwardModel;
