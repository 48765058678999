import { configureStore } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import history from "../utils/history";
import commonReducer from "./common/commonsSlice";
import dashboardReducer from "./dashboard/dashBoardSlice";
import inputFormReducer from "./inputForm/inputFormSlice";
import guidanceNotes from "./guidanceNotes/guidanceNotesSlice";
import geographyReducer from "./geoFilters/geoSlice";
import visualDashboardReducer from "./visualDashboard/visualDashBoardSlice";
import downloadSlice from "./download/downloadSlice";
import { AnalyticsMiddleware } from "../adobe-analytics/middleware";

const rootReducer = (history: any) => ({
  commons: commonReducer,
  dashboard: dashboardReducer,
  inputForm: inputFormReducer,
  guidanceNotes: guidanceNotes,
  filters: geographyReducer,
  visualDashboard: visualDashboardReducer,
  download: downloadSlice,
  router: connectRouter(history),
});

export default configureStore({
  reducer: rootReducer(history),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false }).concat(
      AnalyticsMiddleware,
      routerMiddleware(history)
    );
  },
});

export type RootStore = ReturnType<any>;
