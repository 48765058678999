import React, { useState, useEffect } from "react";
import RollForwardModel from "./roll-forwardModel";
import { ROLL_FORWARD_PROCESS_CONTENT } from "../../utils/constants";
import { RootStore } from "services/store.service";
import { useSelector } from "react-redux";

type props = {
  setRollForwardModal: (value: boolean) => void;
};

const RollForward = ({ setRollForwardModal }: props) => {
  const [tripleConfirmationCount, setTripleConfirmationCount] =
    useState<number>(1);

  const dashboardTableState = useSelector(
    (state: RootStore) => state.dashboard
  );
  const [firstConfirmationContent, setFirstConfirmationContent] =
    useState<string>("");
  const [secondConfirmationContent, setSecondConfirmationContent] =
    useState<string>("");
  const [current_Year, setcurrent_Year] = useState<number>(0);

  useEffect(() => {
    if (
      dashboardTableState.fiscalYearList &&
      Object.keys(dashboardTableState.fiscalYearList).length &&
      dashboardTableState.fiscalYearList[0]?.year
    ) {
      const RollForward_Year: number =
        dashboardTableState.fiscalYearList[0]?.year + 1;
      const temp_current_Year = dashboardTableState.fiscalYearList[0]?.year;
      setcurrent_Year(temp_current_Year);

      setFirstConfirmationContent(
        ROLL_FORWARD_PROCESS_CONTENT.fiscalYear +
          temp_current_Year +
          ROLL_FORWARD_PROCESS_CONTENT.firstConfirmation +
          ROLL_FORWARD_PROCESS_CONTENT.fiscalYear +
          RollForward_Year
      );

      setSecondConfirmationContent(
        ROLL_FORWARD_PROCESS_CONTENT.secondConfirmationSplit1 +
          ROLL_FORWARD_PROCESS_CONTENT.fiscalYear +
          temp_current_Year +
          ROLL_FORWARD_PROCESS_CONTENT.secondConfirmationSplit2
      );
    }
  }, [dashboardTableState.fiscalYearList]);

  const BLOCK = "RollForward";

  return (
    <div data-test="component-RollForwardMain">
      {(() => {
        switch (tripleConfirmationCount) {
          case 1:
            return (
              <RollForwardModel
                setRollForwardModal={setRollForwardModal}
                setTripleConfirmationCount={setTripleConfirmationCount}
                tripleConfirmationCount={tripleConfirmationCount}
                BLOCK={BLOCK}
                header="roll.forward.step1"
                buttonNamme="roll.forward.button.step1"
                content={firstConfirmationContent}
                checkBox={ROLL_FORWARD_PROCESS_CONTENT.checkBoxText}
              />
            );
          case 2:
            return (
              <RollForwardModel
                setRollForwardModal={setRollForwardModal}
                setTripleConfirmationCount={setTripleConfirmationCount}
                tripleConfirmationCount={tripleConfirmationCount}
                BLOCK={BLOCK}
                header="roll.forward.step2"
                buttonNamme="roll.forward.button.step2"
                content={secondConfirmationContent}
              />
            );
          case 3:
            return (
              <RollForwardModel
                setRollForwardModal={setRollForwardModal}
                setTripleConfirmationCount={setTripleConfirmationCount}
                tripleConfirmationCount={tripleConfirmationCount}
                BLOCK={BLOCK}
                header="roll.forward.step3"
                buttonNamme="roll.forward.button.step3"
                content={ROLL_FORWARD_PROCESS_CONTENT.thirdConfirmation}
              />
            );
          case 4:
            return (
              <RollForwardModel
                setRollForwardModal={setRollForwardModal}
                setTripleConfirmationCount={setTripleConfirmationCount}
                tripleConfirmationCount={tripleConfirmationCount}
                BLOCK={BLOCK}
                header="roll.forward.step4"
                buttonNamme="roll.forward.button.step3"
                current_Year={current_Year}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default RollForward;
