import { useEffect, useState, createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";

const TransformationAssumptionContext: any = createContext(null);

type transformationAssumptionContext = {
  children: React.ReactNode;
};

export const TransformationAssumptionContextProvider = ({
  children,
}: transformationAssumptionContext) => {
  const params: any = useParams();

  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);

  const [
    transformationAssumptionsTableData,
    setTransformationAssumptionsTableData,
  ] = useState<any>({});
  const [
    transformationAssumptionsTableDataCompare,
    setTransformationAssumptionsTableDataCompare,
  ] = useState<any>({});

  const [multipleErrors, setMultipleErrors] = useState<boolean>(false);
  const [initialCompletedFields, setInitialCompletedFields] = useState<any>(0);

  return (
    <TransformationAssumptionContext.Provider
      value={{
        transformationAssumptionsTableData,
        setTransformationAssumptionsTableData,
        transformationAssumptionsTableDataCompare,
        setTransformationAssumptionsTableDataCompare,
        multipleErrors,
        setMultipleErrors,
        initialCompletedFields,
        setInitialCompletedFields,
      }}
    >
      {children}
    </TransformationAssumptionContext.Provider>
  );
};

export function useAPI() {
  const context = useContext(TransformationAssumptionContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
