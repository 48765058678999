import track from "./track";
import updateDataLayer from "./data-layer";
import TRACKING_ACTIONS from "./actions";
/** A redux middleware component to see if we're tracking that action
 * and send to Adobe for tracking
 */

let DEFER: { type: any }[] = [];

export const AnalyticsMiddleware =
  (store: { getState: () => any }) =>
  (next: (arg0: any) => any) =>
  (action: { type: string; payload: any }) => {
    const returnValue = next(action);
    const state = store.getState();

    //Don't track un authenticated routes, to be set properly when authentication is set up completely with specific content of path string
    if (state?.router?.location?.pathname?.includes("authsuccess")) {
      return returnValue;
    }

    // Always wait until user data is loaded, before tracking, since we need to send user info for every track
    if (!state.commons.currentUser) {
      if (TRACKING_ACTIONS[action.type]) {
        updateDataLayer(state);
        DEFER.push({ type: action.type });
      }
    }
    if (DEFER.length > 0) {
      DEFER.forEach((event) => {
        updateDataLayer(state);
        track(event.type);
      });
      DEFER = [];
    }

    if (TRACKING_ACTIONS[action.type]) {
      updateDataLayer(state);
      track(action.type);
    }

    return returnValue;
  };
