import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { loadAccumExits } from "../../services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { ConsolidationsKeyInitial } from "utils/constants";

type AccumExitsTable = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const AccumExits = ({
  geographies,
  currencyCode,
  baseYear,
}: AccumExitsTable) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );

  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        loadAccumExits({ countryCodes: geographies, currencyCode, baseYear })
      );
    }
  }, [geographies, currencyCode, baseYear]);
  return (
    <div>
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.accumExitsData?.consolidatedProfitAndLossDetails
        }
        tableHeader={consolidationData?.accumExitsData?.subTotalProfitAndLoss}
        mainTableHeader="consolidation.accum.exits"
        subConsolidationTableData={
          consolidationData?.accumExitsData?.subConsolidatedProfitAndLossDetails
        }
        keyInitial={ConsolidationsKeyInitial.ACCUM_EXITS}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return item === VISUAL_DASHBOARD_OPTIONS.ACCUM_EXITS_LOADING;
          })?.length > 0
        }
      />
    </div>
  );
};

export default AccumExits;
