import Icon from "../shared/icon/icon";
import { useDispatch, useSelector } from "react-redux";
import { showDownloadTab } from "services/download/downloadSlice";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useState, useEffect } from "react";
import { RootStore } from "services/store.service";

type Props = {
  BLOCK: string;
};

const DownloadsHeader = ({ BLOCK }: Props) => {
  const dispatch = useDispatch();
  const downloadState = useSelector((state: RootStore) => state.download);
  const [downloadCount, setDownloadCount] = useState<number>(0);

  useEffect(() => {
    if (downloadState.downloads !== null) {
      let count = downloadState.downloads.filter(
        (download: any) => download.exportState === 3
      );
      setDownloadCount(count.length);
    }
  }, [downloadState.downloads]);

  return (
    <>
      <div className={`${BLOCK}__content`} data-test={`${BLOCK}__content`}>
        <FormattedMessage id="downloads.header.text" />
        {downloadCount !== 0 && (
          <span className={`${BLOCK}__content__icon`}>
            <span className={`${BLOCK}__content__icon-content`}>
              {
                /*istanbul ignore next*/ isNaN(downloadCount)
                  ? 0
                  : downloadCount.toString()
              }
            </span>
          </span>
        )}
      </div>

      <div
        className={`${BLOCK}__cross`}
        data-test={`${BLOCK}__cross`}
        onClick={() => dispatch(showDownloadTab(!downloadState.showDownloads))}
      >
        <Icon name="cross" height={24} width={24} />
      </div>
    </>
  );
};

export default DownloadsHeader;
