import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useEffect, useState } from "react";
import classNames from "classnames";
import CommonTable from "./commonTable";
import Omnia from "./omnia";
import Tier5 from "./tier5";
import Assurance from "./assurance";
import Levvia from "./levvia";
import Others from "./others";
import NetRevenueTable from "./netRevenue";
import PricingImprovementTable from "./pricingImprovements";
import TechnicalStaffCostTotal from "./technicalStaffCostTotal";
import TechnicalStaffCostOnshore from "./technicalStaffCostOnshore";
import TechnicalStaffCostUSI from "./technicalStaffCostUSI";
import TechnicalStaffCostRADC from "./technicalStaffCostRADC";
import TechnicalStaffCostLocalDC from "./technicalStffCostLocalDC";
import TechnicalStaffCostLocalCOE from "./technicalStaffCostLocalCOE";
import GrossProfitTable from "./grossProfit";
import GrossMarginTable from "./grossMargin";
import OverHeadsTotal from "./overHeadDetail";
import DirectOverHeadFTETable from "./directOverHeadsFTE";
import DirectOverHeadRevenue from "./directOverHeadsRevenue";
import OverHeadAllocationExp from "./overHeadAllocationExp";
import OverHeadOtherTable from "./overHeadOther";
import NetProfit from "./netProfit";
import NetMargin from "./netMargin";
import TotalHours from "./totalHours";
import NetFeePerHour from "./netFeePerHour";
import CostPerHour from "./costPerHour";
import TotalHeadCountcohort from "./totalHeadCountCohort";
import PromotedHeadCount from "./promotedHeadCount";
import AccumExits from "./accumExits";
import NetRevenueAfterPricingExits from "./netRevenueAfterPricingExits";
import HeadCountAttritionLoss from "./headCountAttritionLoss";
import OnShoreHeadCountCohortTable from "./onshoreHeadcountCohort";
import UsiHeadCountCohortTable from "./usiHeadCountCohort";
import RadcHeadCountCohortTable from "./radcHeadCountCohort";
import LoacalDCHeadCountCohortTable from "./localDCHeadCountCohort";
import LoacalCOEHeadCountCohortTable from "./localCOEHeadCountCohort";
import TotalHeadCountGrade from "./totalHeadCountGrade";
import OnshoreHeadCountGrade from "./onshoreHeadcountGrade";
import UsiHeadCountGrade from "./usiHeadCountGrade";
import RadcHeadCountGrade from "./radcHeadCountGrades";
import LocalDCHeadCountGrade from "./localDCHeadCountGrade";
import LocalCOEHeadCountGrade from "./localCOEHeadCountGrade";
import {
  DashBoardCurrencyHeader,
  DashBoardTablesSection,
  dashboardGrossMarginTableList,
  dashboardHeadCountsTableList,
  dashboardProfitsAndLossTableList,
  dashboardSummaryOfTATableList,
  VISUAL_DASHBOARD_MENU,
} from "utils/constants";

const ConsolidatedDataTable = ({
  toggleNavigation,
  stepSelectedNavigation,
}: any) => {
  const BLOCK = "consolidatedTableData";
  const CONTAINER_BLOCK = "headcounts";

  return (
    <div
      id="consolidatedTableData"
      data-test="consolidatedTableData"
      className={classNames(`${CONTAINER_BLOCK}`, {
        [`${BLOCK}--container-with-panel`]: toggleNavigation,
        [`${BLOCK}--container-without-panel`]: !toggleNavigation,
      })}
    >
      {stepSelectedNavigation === VISUAL_DASHBOARD_MENU.PROFIT_AND_LOSSES && (
        <div className="scroll-add-remove">
          <div className={`${CONTAINER_BLOCK}__divider-header`}>
            <span className={`${CONTAINER_BLOCK}__divider-header--title`}>
              <FormattedMessage
                id={"visual.dashboard.table.subheading.profit.loss"}
              />
            </span>
          </div>
          <CommonTable
            TableComponent={NetRevenueTable}
            subTitle={DashBoardCurrencyHeader.NET_REVENUE}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[0].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={PricingImprovementTable}
            subTitle={DashBoardCurrencyHeader.PRICING_IMPROVEMENT}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[1].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}> </div>
          <CommonTable
            TableComponent={AccumExits}
            subTitle={DashBoardCurrencyHeader.ACCUM_EXITS}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[2].tablePath}
          />
          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={NetRevenueAfterPricingExits}
            subTitle={DashBoardCurrencyHeader.NET_REVENUE_AFTER_PRICING_EXITS}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[3].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}> </div>
          <CommonTable
            TableComponent={TechnicalStaffCostTotal}
            subTitle={DashBoardCurrencyHeader.TECHNICAL_STAFF_COST}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[4].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={TechnicalStaffCostOnshore}
            subTitle={DashBoardCurrencyHeader.TECHNICAL_STAFF_COST_ONSHORE}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[5].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={TechnicalStaffCostUSI}
            subTitle={DashBoardCurrencyHeader.TECHNICAL_STAFF_COST_USI}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[6].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={TechnicalStaffCostRADC}
            subTitle={DashBoardCurrencyHeader.TECHNICAL_STAFF_COST_RADC}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[7].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={TechnicalStaffCostLocalDC}
            subTitle={DashBoardCurrencyHeader.TECHNICAL_STAFF_COST_LOCAL_DC}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[8].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={TechnicalStaffCostLocalCOE}
            subTitle={DashBoardCurrencyHeader.TECHNICAL_STAFF_COST_LOCAL_COE}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[9].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={GrossProfitTable}
            subTitle={DashBoardCurrencyHeader.GROSS_PROFIT}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[10].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}> </div>
          <CommonTable
            TableComponent={GrossMarginTable}
            subTitle={DashBoardCurrencyHeader.GROSS_MARGIN}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            showCurrencyHeader={false}
            RefId={dashboardProfitsAndLossTableList[11].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={OverHeadsTotal}
            subTitle={DashBoardCurrencyHeader.OVER_HEAD_TOTAL}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[12].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}> </div>
          <CommonTable
            TableComponent={DirectOverHeadFTETable}
            subTitle={DashBoardCurrencyHeader.DIRECT_OVER_HEAD_FTE}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[13].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}> </div>
          <CommonTable
            TableComponent={DirectOverHeadRevenue}
            subTitle={DashBoardCurrencyHeader.DIRECT_OVER_HEAD_REVENUE}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[14].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={OverHeadOtherTable}
            subTitle={DashBoardCurrencyHeader.OVER_HEADS_OTHER}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[15].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={OverHeadAllocationExp}
            subTitle={DashBoardCurrencyHeader.OVER_HEADS_ALLOCATION_EXPENSES}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[16].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={NetProfit}
            subTitle={DashBoardCurrencyHeader.NET_PROFIT}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            RefId={dashboardProfitsAndLossTableList[17].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}> </div>
          <CommonTable
            TableComponent={NetMargin}
            subTitle={DashBoardCurrencyHeader.NET_MARGIN}
            TablesSection={DashBoardTablesSection.PROFIT_AND_LOSSES}
            showCurrencyHeader={false}
            RefId={dashboardProfitsAndLossTableList[18].tablePath}
          />
        </div>
      )}

      {/* Gross Margin Analysis */}
      {stepSelectedNavigation === VISUAL_DASHBOARD_MENU.GROSS_MARGIN && (
        <div className="scroll-add-remove">
          <div className={`${CONTAINER_BLOCK}__divider-header`}>
            <span className={`${CONTAINER_BLOCK}__divider-header--title`}>
              <FormattedMessage
                id={"visual.dashboard.table.subheading.gross.margin"}
              />
            </span>
          </div>
          <CommonTable
            TableComponent={TotalHours}
            subTitle={`${DashBoardCurrencyHeader.TOTAL_HOURS}`}
            TablesSection={DashBoardTablesSection.GM_ANALYSIS}
            showCurrencyHeader={false}
            showHours={true}
            RefId={dashboardGrossMarginTableList[0].tablePath}
          />
          <div className={`${BLOCK}__common-dashboard-margin`}> </div>
          <CommonTable
            TableComponent={NetFeePerHour}
            subTitle={`${DashBoardCurrencyHeader.NET_FEE_PER_HOUR}`}
            TablesSection={DashBoardTablesSection.GM_ANALYSIS}
            RefId={dashboardGrossMarginTableList[1].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}> </div>
          <CommonTable
            TableComponent={CostPerHour}
            subTitle={`${DashBoardCurrencyHeader.COST_PER_HOUR}`}
            TablesSection={DashBoardTablesSection.GM_ANALYSIS}
            RefId={dashboardGrossMarginTableList[2].tablePath}
          />
        </div>
      )}

      {stepSelectedNavigation === VISUAL_DASHBOARD_MENU.HEADCOUNT && (
        <div className="scroll-add-remove">
          {/* Head Count */}
          <div className={`${CONTAINER_BLOCK}__divider-header`}>
            <span className={`${CONTAINER_BLOCK}__divider-header--title`}>
              <FormattedMessage
                id={"visual.dashboard.table.subheading.head.count"}
              />
            </span>
          </div>
          <div>
            <div className={`${BLOCK}__divider-header`}>
              <span className={`${BLOCK}__divider-header--title`}>
                <FormattedMessage id={`headcounts.cohorts.total`} />
              </span>
            </div>
            <CommonTable
              TableComponent={TotalHeadCountcohort}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[0].tablePath}
            />
            <div className={`${BLOCK}__common-dashboard-margin`}></div>
            <CommonTable
              TableComponent={TotalHeadCountGrade}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT_GRADE}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[0].tablePath}
            />

            <div className={`${BLOCK}__common-dashboard-margin`}> </div>

            <div className={`${BLOCK}__divider-header`}>
              <span className={`${BLOCK}__divider-header--title`}>
                <FormattedMessage id={`headcounts.cohorts.onshore`} />
              </span>
            </div>

            <CommonTable
              TableComponent={OnShoreHeadCountCohortTable}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[1].tablePath}
            />
            <div className={`${BLOCK}__common-dashboard-margin`}></div>
            <CommonTable
              TableComponent={OnshoreHeadCountGrade}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT_GRADE}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[1].tablePath}
            />

            <div className={`${BLOCK}__common-dashboard-margin`}></div>

            <div className={`${BLOCK}__divider-header`}>
              <span className={`${BLOCK}__divider-header--title`}>
                <FormattedMessage id={`headcounts.cohorts.usi`} />
              </span>
            </div>

            <CommonTable
              TableComponent={UsiHeadCountCohortTable}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[2].tablePath}
            />
            <div className={`${BLOCK}__common-dashboard-margin`}></div>
            <CommonTable
              TableComponent={UsiHeadCountGrade}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT_GRADE}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[2].tablePath}
            />

            <div className={`${BLOCK}__common-dashboard-margin`}></div>

            <div className={`${BLOCK}__divider-header`}>
              <span className={`${BLOCK}__divider-header--title`}>
                <FormattedMessage id={`headcounts.cohorts.radc`} />
              </span>
            </div>

            <CommonTable
              TableComponent={RadcHeadCountCohortTable}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[3].tablePath}
            />
            <div className={`${BLOCK}__common-dashboard-margin`}></div>
            <CommonTable
              TableComponent={RadcHeadCountGrade}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT_GRADE}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[3].tablePath}
            />

            <div className={`${BLOCK}__common-dashboard-margin`}></div>

            <div className={`${BLOCK}__divider-header`}>
              <span className={`${BLOCK}__divider-header--title`}>
                <FormattedMessage id={`headcounts.cohorts.localdc`} />
              </span>
            </div>

            <CommonTable
              TableComponent={LoacalDCHeadCountCohortTable}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[4].tablePath}
            />
            <div className={`${BLOCK}__common-dashboard-margin`}></div>
            <CommonTable
              TableComponent={LocalDCHeadCountGrade}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT_GRADE}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[4].tablePath}
            />

            <div className={`${BLOCK}__common-dashboard-margin`}></div>

            <div className={`${BLOCK}__divider-header`}>
              <span className={`${BLOCK}__divider-header--title`}>
                <FormattedMessage id={`headcounts.cohorts.localcoe`} />
              </span>
            </div>

            <CommonTable
              TableComponent={LoacalCOEHeadCountCohortTable}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[5].tablePath}
            />
            <div className={`${BLOCK}__common-dashboard-margin`}></div>
            <CommonTable
              TableComponent={LocalCOEHeadCountGrade}
              subTitle={DashBoardCurrencyHeader.HEADCOUNT_GRADE}
              TablesSection={DashBoardTablesSection.HEADCOUNT}
              RefId={dashboardHeadCountsTableList[5].tablePath}
            />
          </div>
          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={PromotedHeadCount}
            subTitle={DashBoardCurrencyHeader.PROMOTED_HEADCOUNT}
            TablesSection={DashBoardTablesSection.HEADCOUNT}
            RefId={dashboardHeadCountsTableList[6].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}> </div>
          <CommonTable
            TableComponent={HeadCountAttritionLoss}
            subTitle={DashBoardCurrencyHeader.HEADCOUNT_ATTRITION}
            TablesSection={DashBoardTablesSection.HEADCOUNT}
            RefId={dashboardHeadCountsTableList[7].tablePath}
          />
        </div>
      )}

      {stepSelectedNavigation ===
        VISUAL_DASHBOARD_MENU.TRANSFORMATION_ASSUMPTIONS && (
        <div className="scroll-add-remove">
          {/*TA Consolidation Tables*/}
          <div className={`${CONTAINER_BLOCK}__divider-header`}>
            <span className={`${CONTAINER_BLOCK}__divider-header--title`}>
              <FormattedMessage
                id={"visual.dashboard.table.subheading.Trasformation"}
              />
            </span>
          </div>
          <CommonTable
            TableComponent={Omnia}
            subTitle={"visual.dashboard.table.consolidation.omnia"}
            RefId={dashboardSummaryOfTATableList[0].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={Levvia}
            subTitle={"visual.dashboard.table.consolidation.omnia-levvia"}
            RefId={dashboardSummaryOfTATableList[1].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={Tier5}
            subTitle={"visual.dashboard.table.consolidation.omnia-tier5"}
            RefId={dashboardSummaryOfTATableList[2].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={Assurance}
            subTitle={"visual.dashboard.table.consolidation.omnia-assurance"}
            RefId={dashboardSummaryOfTATableList[3].tablePath}
          />

          <div className={`${BLOCK}__common-dashboard-margin`}></div>
          <CommonTable
            TableComponent={Others}
            subTitle={"visual.dashboard.table.consolidation.omnia-other"}
            RefId={dashboardSummaryOfTATableList[4].tablePath}
          />
        </div>
      )}
    </div>
  );
};

export default ConsolidatedDataTable;
