import { useState } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import CommonSubItems from "./commonSubItems";
import ConsolidatedDataTable from "./consolidated_data_table";
import {
  dashboardProfitsAndLossTableList,
  dashboardGrossMarginTableList,
  dashboardHeadCountsTableList,
  dashboardSummaryOfTATableList,
  VISUAL_DASHBOARD_MENU,
} from "utils/constants";
import doubleArrowRight from "images/double-arrow-right.svg";
import doubleArrowLeft from "images/double-arrow-left.svg";

const DashboardNavigationPanel = () => {
  const [toggleNavigation, setToggleNavigation] = useState(true);
  const [stepSelectedNavigation, setStepSelectedNavigation] = useState<
    number | null
  >(VISUAL_DASHBOARD_MENU.PROFIT_AND_LOSSES);
  const [pAndLToggle, setPandLToggle] = useState(false);
  const [gmAnalysisToggle, setGmAnalysisToggle] = useState(false);
  const [headCountsToggle, setHeadCountsToggle] = useState(false);
  const [taToggle, setTAToggle] = useState(false);
  const BLOCK = "inputform";
  return (
    <div className={`inputform__dashboard__sidebar`}>
      <div
        className={`${BLOCK}__navigation-panel`}
        style={
          !toggleNavigation
            ? { cursor: "pointer", backgroundColor: "#e9e9e9", width: "48px" }
            : { width: "25%" }
        }
        onClick={() =>
          !toggleNavigation && setToggleNavigation(!toggleNavigation)
        }
      >
        {!toggleNavigation ? (
          <div className={`${BLOCK}__side-bar-container`}>
            <div style={{ marginTop: 25, marginRight: 12 }}>
              <img
                className={`${BLOCK}--double-arrow`}
                src={!toggleNavigation ? doubleArrowRight : doubleArrowLeft}
                onClick={() => setToggleNavigation(!toggleNavigation)}
              />
            </div>
            <div className={`${BLOCK}__side-bar-onclose`}>
              <FormattedMessage id="tool.yp.name" />
            </div>
          </div>
        ) : (
          <>
            <div className={`${BLOCK}__navigation-panel--item`}>
              <FormattedMessage id="tool.yp.name" />
              <div style={{ float: "right" }}>
                <img
                  className={`${BLOCK}--double-arrow`}
                  src={!toggleNavigation ? doubleArrowRight : doubleArrowLeft}
                  onClick={() => setToggleNavigation(!toggleNavigation)}
                />
              </div>
            </div>

            <div className={`${BLOCK}__navigation-panel-scroll`}>
              <div
                className={`${BLOCK}__navigation-panel--item ${BLOCK}__tooltip`}
              >
                <div className={`${BLOCK}__icon-text`}>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setPandLToggle(!pAndLToggle)}
                  >
                    <Icon
                      name={pAndLToggle ? "caretup" : "caretright"}
                      width={16}
                      height={16}
                    />
                  </span>

                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setPandLToggle(true);
                      setStepSelectedNavigation(
                        VISUAL_DASHBOARD_MENU.PROFIT_AND_LOSSES
                      );
                    }}
                  >
                    <div
                      style={{
                        color:
                          stepSelectedNavigation ===
                          VISUAL_DASHBOARD_MENU.PROFIT_AND_LOSSES
                            ? "#00A3E0"
                            : "black",
                        marginLeft: 20,
                      }}
                    >
                      <FormattedMessage
                        id={`visual.dashboard.table.subheading.profit.loss`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {pAndLToggle && (
                <CommonSubItems
                  BLOCK={BLOCK}
                  selection={stepSelectedNavigation}
                  changeSelection={setStepSelectedNavigation}
                  subItemsList={dashboardProfitsAndLossTableList}
                  orderId={VISUAL_DASHBOARD_MENU.PROFIT_AND_LOSSES}
                />
              )}

              <div
                className={`${BLOCK}__navigation-panel--item ${BLOCK}__tooltip`}
              >
                <div className={`${BLOCK}__icon-text`}>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setGmAnalysisToggle(!gmAnalysisToggle)}
                  >
                    <Icon
                      name={gmAnalysisToggle ? "caretup" : "caretright"}
                      width={16}
                      height={16}
                    />
                  </span>

                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setGmAnalysisToggle(true);
                      setStepSelectedNavigation(
                        VISUAL_DASHBOARD_MENU.GROSS_MARGIN
                      );
                    }}
                  >
                    <div
                      style={{
                        color:
                          stepSelectedNavigation ===
                          VISUAL_DASHBOARD_MENU.GROSS_MARGIN
                            ? "#00A3E0"
                            : "black",
                        marginLeft: 20,
                      }}
                    >
                      <FormattedMessage
                        id={`visual.dashboard.table.subheading.gross.margin`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {gmAnalysisToggle && (
                <CommonSubItems
                  BLOCK={BLOCK}
                  selection={stepSelectedNavigation}
                  changeSelection={setStepSelectedNavigation}
                  subItemsList={dashboardGrossMarginTableList}
                  orderId={VISUAL_DASHBOARD_MENU.GROSS_MARGIN}
                />
              )}

              <div
                className={`${BLOCK}__navigation-panel--item ${BLOCK}__tooltip`}
              >
                <div className={`${BLOCK}__icon-text`}>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setHeadCountsToggle(!headCountsToggle)}
                  >
                    <Icon
                      name={headCountsToggle ? "caretup" : "caretright"}
                      width={16}
                      height={16}
                    />
                  </span>

                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setHeadCountsToggle(true);
                      setStepSelectedNavigation(
                        VISUAL_DASHBOARD_MENU.HEADCOUNT
                      );
                    }}
                  >
                    <div
                      style={{
                        color:
                          stepSelectedNavigation ===
                          VISUAL_DASHBOARD_MENU.HEADCOUNT
                            ? "#00A3E0"
                            : "black",
                        marginLeft: 20,
                      }}
                    >
                      <FormattedMessage
                        id={`visual.dashboard.table.subheading.head.count`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {headCountsToggle && (
                <CommonSubItems
                  BLOCK={BLOCK}
                  selection={stepSelectedNavigation}
                  changeSelection={setStepSelectedNavigation}
                  subItemsList={dashboardHeadCountsTableList}
                  orderId={VISUAL_DASHBOARD_MENU.HEADCOUNT}
                />
              )}

              <div
                className={`${BLOCK}__navigation-panel--item ${BLOCK}__tooltip`}
              >
                <div className={`${BLOCK}__icon-text`}>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setTAToggle(!taToggle)}
                  >
                    <Icon
                      name={taToggle ? "caretup" : "caretright"}
                      width={16}
                      height={16}
                    />
                  </span>

                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setTAToggle(true);
                      setStepSelectedNavigation(
                        VISUAL_DASHBOARD_MENU.TRANSFORMATION_ASSUMPTIONS
                      );
                    }}
                  >
                    <div
                      style={{
                        color:
                          stepSelectedNavigation ===
                          VISUAL_DASHBOARD_MENU.TRANSFORMATION_ASSUMPTIONS
                            ? "#00A3E0"
                            : "black",
                        marginLeft: 20,
                      }}
                    >
                      <FormattedMessage
                        id={`visual.dashboard.table.subheading.Trasformation`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {taToggle && (
                <CommonSubItems
                  BLOCK={BLOCK}
                  selection={stepSelectedNavigation}
                  changeSelection={setStepSelectedNavigation}
                  subItemsList={dashboardSummaryOfTATableList}
                  orderId={VISUAL_DASHBOARD_MENU.TRANSFORMATION_ASSUMPTIONS}
                />
              )}
            </div>
          </>
        )}
      </div>
      <ConsolidatedDataTable
        toggleNavigation={toggleNavigation}
        stepSelectedNavigation={stepSelectedNavigation}
      />
    </div>
  );
};
export default DashboardNavigationPanel;
