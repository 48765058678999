import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "./commons.api";

const initialState = {
  toastMessages: [],
  toolTip: {
    position: null,
    children: null,
    arrowPosition: null,
    customPosition: null,
    element: null,
    className: null,
    width: null,
    executeMouseLeaveEvent: null,
    isOverTooltip: null,
  },
  currentUser: {},
  toggleNavigationPanel: true,
};

const commonsSlice = createSlice({
  name: "commons",
  initialState,
  reducers: {
    addToastMessage(state: any, action) {
      state.toastMessages.push(action.payload);
    },
    removeToastMessage(state: any, action) {
      state.toastMessages = state.toastMessages.filter(
        (item: any, i: number) => i !== action.payload
      );
    },
    showTooltip(state: any, action) {
      state.toolTip = action.payload;
    },
    setToggleNavigationPanel(state: any, action) {
      state.toggleNavigationPanel = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(loadCurrentUser.fulfilled, (state: any, action) => {
      state.currentUser = action.payload;
    });
  },
});

export const loadCurrentUser = createAsyncThunk(
  "commons/fetchUser",
  async () => {
    try {
      const currentUser = await api.getCurrentUser();
      window.localStorage.setItem("userID", currentUser.email);
      return currentUser;
    } catch (e) {
      console.log("Error in loading User data");
      console.log(e);
    }
  }
);

export const userLogs = createAsyncThunk(
  "commons/userLogs",
  async () => {
    try {
      await api.saveUserLogs();
    } catch (e) {
      console.log("Error in loading User data");
      console.log(e);
    }
  }
);

export const {
  addToastMessage,
  removeToastMessage,
  showTooltip,
  setToggleNavigationPanel,
} = commonsSlice.actions;

export default commonsSlice.reducer;
