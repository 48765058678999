import FormattedMessage from "../shared/formatted-message/formatted-message";
import DropDown from "../search/filters/drop-down";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { addToastMessage } from "services/common/commonsSlice";
import { GMBridgeToggle, cohorts } from "utils/constants";
import {
  setGMBridgeCohort,
  setGMBridgeStartYear,
  setGMBridgeEndYear,
  setGMBridgeToggle,
} from "services/visualDashboard/visualDashBoardSlice";
import Icon from "components/shared/icon/icon";
import ReactTooltip from "react-tooltip";
import { useEffect, useState } from "react";

const GMBridgeNav = ({ BLOCK, yearList }: any) => {
  const dispatch = useDispatch();
  const [endYearsList, setEndYearsList] = useState<any[]>([]);

  useEffect(() => {
    const [, ...endYears] = Object.keys(yearList || {});
    setEndYearsList(endYears);
  }, [yearList]);

  const visualDashboard = useSelector(
    (state: RootStore) => state.visualDashboard
  );

  const selectCohort = (value: any) => {
    dispatch(setGMBridgeCohort([value]));
  };
  const selectStartYear = (value: any) => {
    if (yearList[value] > yearList[visualDashboard?.gmBridgeEndYear]) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="gm.bridge.greater.start.year" />,
        })
      );
    } else if (yearList[value] === yearList[visualDashboard?.gmBridgeEndYear]) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="gm.bridge.greater.start.end.same.year" />
          ),
        })
      );
    } else {
      dispatch(setGMBridgeStartYear([value]));
    }
  };
  const selectEndYear = (value: any) => {
    if (yearList[visualDashboard?.gmBridgeStartYear] > yearList[value]) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="gm.bridge.greater.start.year" />,
        })
      );
    } else if (
      yearList[visualDashboard?.gmBridgeStartYear] === yearList[value]
    ) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="gm.bridge.greater.start.end.same.year" />
          ),
        })
      );
    } else {
      dispatch(setGMBridgeEndYear([value]));
    }
  };
  return (
    <div className={`${BLOCK}_container`}>
      <ReactTooltip effect="solid" />
      <div>
        <div className={`${BLOCK}_section-header`}>
          <FormattedMessage id="gm.bridge.section.header" />
        </div>
      </div>
      <div className={`${BLOCK}_nav`}>
        <div className={`${BLOCK}_nav--item`}>
          <div className={`${BLOCK}__icons-shift`}>
            <div
              className={
                visualDashboard?.gmBridgeToggle
                  ? `${BLOCK}__table-un-selected-icon`
                  : `${BLOCK}__table-selected-icon`
              }
              onClick={(e) => {
                dispatch(setGMBridgeToggle(false));
              }}
            >
              <div className={`${BLOCK}__shift-icon1`}>
                <span>{GMBridgeToggle.CURRENCY}</span>
              </div>
            </div>
            <div
              className={
                visualDashboard?.gmBridgeToggle
                  ? `${BLOCK}__garph-selected-icon`
                  : `${BLOCK}__garph-un-selected-icon`
              }
              onClick={(e) => {
                dispatch(setGMBridgeToggle(true));
              }}
            >
              <div className={`${BLOCK}__shift-icon2`}>
                <span>{GMBridgeToggle.PERCENTAGE}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${BLOCK}_nav--item`}>
          <DropDown
            className={`metricsModel__drop-down`}
            label={
              <div className={`${BLOCK}_nav--drop-down-label`}>
                <FormattedMessage id="gm.bridge.section.cohort" />
              </div>
            }
            placeholder={<FormattedMessage id="gm.bridge.section.cohort" />}
            loading={false}
            objectKeys={{
              name: "",
              id: "",
              externalId: "",
            }}
            values={visualDashboard.gmBridgeCohort}
            options={Object.keys(cohorts)}
            handleSelect={selectCohort}
            displayFunction={(option: any) => {
              return option;
            }}
          />
        </div>
        <div className={`${BLOCK}_nav--item`}>
          <DropDown
            className={`metricsModel__drop-down`}
            label={
              <div className={`${BLOCK}_nav--drop-down-label`}>
                <FormattedMessage id="gm.bridge.section.start.year" />
              </div>
            }
            placeholder={<FormattedMessage id="gm.bridge.section.start.year" />}
            loading={false}
            objectKeys={{
              name: "",
              id: "",
              externalId: "",
            }}
            values={visualDashboard.gmBridgeStartYear}
            options={Object.keys(yearList || {})}
            handleSelect={selectStartYear}
            displayFunction={(option: any) => {
              return option;
            }}
          />
        </div>
        <div className={`${BLOCK}_nav--item`}>
          <DropDown
            className={`metricsModel__drop-down`}
            label={
              <div className={`${BLOCK}_nav--drop-down-label`}>
                <FormattedMessage id="gm.bridge.section.end.year" />
              </div>
            }
            placeholder={<FormattedMessage id="gm.bridge.section.end.year" />}
            loading={false}
            objectKeys={{
              name: "",
              id: "",
              externalId: "",
            }}
            values={visualDashboard.gmBridgeEndYear}
            options={endYearsList}
            handleSelect={selectEndYear}
            displayFunction={(option: any) => {
              return option;
            }}
          />
        </div>
        <div
          className={`${BLOCK}_nav--item`}
          data-tip={
            "Please note: Movements are more clear when viewed year-over-year (YoY)."
          }
        >
          <Icon name="info" width={15} height={15} />
        </div>
      </div>
    </div>
  );
};

export default GMBridgeNav;
