import moment from "moment";

export const dashboardPath = "/dashboard";
export const homePath = "/home";
export const configurationsdPath = "/configuration";
export const revenuePLdPath = "/revenuepl";
export const headCountInputsPath = "/headcounts";
export const yearlyUtilizationPath = "/yearlyutilization";
export const transformationsPath = "/transformations";
export const coversionRatiosPath = "/conversionratios";
export const loginPath = "/login";
export const planSubmission = "/plansubmissions";
export const insightsPath = "/insights";
export const Fee = "fee";
export const hours = "hours";
export const conversion = "conversion";
export const liberatedHours = "liberatedhours";
export const userGuidePath="/userguide"
export const MENU_ITEMS = {
  dashboard: {
    icon: "home",
    path: "/home",
  },
  configuration: {
    icon: "workspace",
    path: "/configuration",
  },
  Visual_dashboard: {
    icon: "dashboard",
    path: "/dashboard",
  },
  downloads: {
    icon: "downloads",
    path: "/downloads",
  },
};
export const VISUAL_DASHBOARD_ICONS = {
  table: {
    selected: "workspace",
    unSelected: "visualDashboardIcon1",
  },
  graph: {
    selected: "graphTab",
    unSelected: "visualDashboardIcon2",
  },
  exportIcon: "export",
  exportLabel: "EXPORT",
};
export const ConfigurationAnuualUtilizationToolTip =
  "Default value has been set to 2008 hours for 100% utilization. However, this utilization may vary by geography...";

export const configurationRequirements =
  "Configuration requirements must be saved to proceed";
export const ROLL_FORWARD_PROCESS_CONTENT = {
  fiscalYear: "Fiscal Year ",
  checkBoxText:
    "Confirm all input forms are 100% complete and in reviewed status.",
  firstConfirmation:
    " is now closed. You may now begin rolling forward input forms for all geographies to ",
  secondConfirmationSplit1: "Once you begin the roll forward process, ",
  secondConfirmationSplit2:
    " input forms will be archived and no more changes can be made.",
  thirdConfirmation:
    "Click 'CONFIRM' to begin roll forward process or 'CANCEL' to abort. You will not be able to undo this action once it begins.",
};

export const REV_PNL_TOTAL_FIELD_SEGMENT_ID = 8;

export const MAX_PERCENTAGE_LENGTH = 3;
export const MAX_NON_PERCENTAGE_LENGTH = 10;
export const REFRESH_NOTIFICATION_CONTENT =
  "{Geography} input data has been updated. Click 'REFRESH' button to update your dashboard. Otherwise, click 'CLOSE' to refresh it later by clicking 'Refresh' icon in the dashboard header.";

export enum CALCULATE_ENGINE {
  REVENUE_PNL = 1,
  HEADCOUNTS = 2,
  TRANSFORMATION_ASSUMPTIONS = 3,
  CONFIGURATION = 4,
}

export enum HOME_TABLES_ICONS {
  EDIT = "Edit",
  GO_TO_INPUT_FORM = "Go To Input Form",
  DOWLOAD = "Download",
  UPLOAD = "Upload",
  UPLOAD_NOT_COMPLETED = "Input form must be 100% completed to upload a model",
}

export enum UPLOAD_CONFIRMATION {
  LESS_THEN_HUNDRED_MODEL = "UPLOADING MODEL WHEN THE PERCENTAGE IS LESS THEN 100%",
  COMPLETED_HUNDRED_MODEL = "UPLOADING MODEL WHEN THE PERCENTAGE COMPLETED TO 100%",
  INPUT_FORM = "UPLOADING INPUTFORM WHEN THE PERCENTAGE COMPLETED OR LESS THEN 100%",
  EXTENSION_XLSM = "xlsm",
  EXTENSION_XLSX = "xlsx",
}

export const uploadFileError =
  "There was a problem uploading this file. Please go back and refer to the guidance before uploading.";

export enum TRANSFORMATION_MODEL {
  OMNIA_USI = "omniaUsiTransformation",
  OMNIA_RADC = "omniaRadcTransformation",
  LEVVIA = "levviaTransformations",
  TIER_5 = "tier5Transformations",
  ASSURANCE = "assuranceTransformations",
  OTHERS = "othersTransformations",
}
export enum INTERM_RESULT_MAPPING {
  INTERM_RESULT_1 = 0,
  INTERM_RESULT_2 = 1,
  INTERM_RESULT_3 = 2,
  INTERM_RESULT_4 = 3,
  INTERM_RESULT_5 = 4,
  INTERM_RESULT_6 = 5,
}

export enum VISUAL_DASHBOARD_MENU {
  PROFIT_AND_LOSSES = 1,
  GROSS_MARGIN = 2,
  HEADCOUNT = 3,
  TRANSFORMATION_ASSUMPTIONS = 4,
}

export const CONSOLIDATED_NET_REVENUE_MOCK = [
  { name: "FY22", value: 605, label: 605 },
  { name: "Omnia", value: [605, 803], label: 198 },
  { name: "Levvia", value: [803, 820], label: 17 },
  { name: "Assurance", value: [820.9, 846], label: 26 },
  { name: "Tier 5", value: [840.1, 840.1], label: "___", error: "0" }, //Error will work with float value
  { name: "Other", value: [840.1, 840.1], label: "___", error: "0" },
  { name: "Pricing", value: [846, 932], label: 86 },
  { name: "FY27", value: [0, 931], label: 931 },
];

export const CONSOLIDATED_REVENUE_BRIDGE_OMNIA_MOCK = [
  { name: "FY22", value: 378.2, label: 378.2 },
  { name: "Wins", value: [378.2, 669.3], label: 291.1 },
  { name: "Losses", value: [669.3, 634.9], label: -34.4 },
  { name: "Exits", value: [634.9, 576.4], label: -58.5 },
  { name: "Pricing", value: [576.4, 642.2], label: 65.8 },
  { name: "FY27", value: [0, 642.2], label: 642.2 },
];
export const CONSOLIDATED_REVENUE_BRIDGE_LEVVIA_MOCK = [
  { name: "FY22", value: 99.8, label: 99.8 },
  { name: "Wins", value: [99.8, 126.9], label: 27.1 },
  { name: "Losses", value: [126.9, 124.9], label: -2.0 },
  { name: "Exits", value: [124.9, 117.1], label: -7.8 },
  { name: "Pricing", value: [117.1, 126.1], label: 9.0 },
  { name: "FY27", value: [0, 126.1], label: 126.1 },
];
export const CONSOLIDATED_REVENUE_BRIDGE_ASSURANCE_MOCK = [
  { name: "FY22", value: 126.7, label: 126.7 },
  { name: "Wins", value: [126.7, 200.8], label: 74.1 },
  { name: "Losses", value: [200.8, 154.8], label: -46 },
  { name: "Exits", value: [154.8, 154.8], label: "___", error: "0" },
  { name: "Pricing", value: [154.8, 165.7], label: 10.9 },
  { name: "FY27", value: [0, 163.2], label: 163.2 },
];
export const GraphLabels: any = ["Wins", "Losses", "Exits", "Pricing"];
export const ConsolidatedGraphLabels: any = [
  "Omnia",
  "Levvia",
  "Assurance",
  "Tier 5",
  "Other",
  "Pricing",
];
export const ConsolidatedNetRvenueByYearLabels: any = [
  "Omnia",
  "Levvia",
  "Tier 5",
  "Assurance",
  "Other",
];
export const EVENT_CODE = [
  "Semicolon",
  "Quote",
  "BracketLeft",
  "BracketRight",
  "Backslash",
  "Slash",
];
export const NET_REVENUE_BRIDGES_RESPONSE_MOCK = [
  {
    productSegmentId: 1,
    productSegment: "Omnia - USI",
    planSubmissionId: 57,
    fyYear: 22,
    baseYear: null,
    wins: 24.0,
    losses: 24.0,
    exits: -0.0562,
    pricing: 28665.0,
    lastForecastedYear: 28719.9438,
  },
  {
    productSegmentId: 2,
    productSegment: "Omnia - RADC",
    planSubmissionId: 57,
    fyYear: 22,
    baseYear: null,
    wins: 24.0,
    losses: 24.0,
    exits: -0.0562,
    pricing: 28665.0,
    lastForecastedYear: 28719.9438,
  },
  {
    productSegmentId: 3,
    productSegment: "Levvia",
    planSubmissionId: 57,
    fyYear: 22,
    baseYear: null,
    wins: 24.0,
    losses: 24.0,
    exits: -0.0562,
    pricing: 28665.0,
    lastForecastedYear: 28719.9438,
  },
  {
    productSegmentId: 4,
    productSegment: "Tier 5",
    planSubmissionId: 57,
    fyYear: 22,
    baseYear: null,
    wins: 24.0,
    losses: 3497.0,
    exits: -0.0562,
    pricing: 28665.0,
    lastForecastedYear: 32192.9438,
  },
  {
    productSegmentId: 5,
    productSegment: "Assurance",
    planSubmissionId: 57,
    fyYear: 22,
    baseYear: null,
    wins: 24.0,
    losses: 24.0,
    exits: -0.0562,
    pricing: 28665.0,
    lastForecastedYear: 28719.9438,
  },
  {
    productSegmentId: 6,
    productSegment: "Other",
    planSubmissionId: 57,
    fyYear: 22,
    baseYear: null,
    wins: 33.0,
    losses: 964643.0,
    exits: -0.0562,
    pricing: 28665.0,
    lastForecastedYear: 993347.9438,
  },
];
export const CONSOLIDATED_REVENUE_MOCK = [
  {
    name: "FY23",
    omniaUSI: 90.0,
    omniaRADC: 32.4,
    levvia: 90,
    tier5: 90.0,
    assurance: 32.4,
    other: 90,
  },
  {
    name: "FY24",
    omniaUSI: 90.0,
    omniaRADC: 32.4,
    levvia: 90,
    tier5: 90.0,
    assurance: 32.4,
    other: 90,
  },
  {
    name: "FY25",
    omniaUSI: 90.0,
    omniaRADC: 32.4,
    levvia: 90,
    tier5: 90.0,
    assurance: 32.4,
    other: 90,
  },
  {
    name: "FY26",
    omniaUSI: 90.0,
    omniaRADC: 32.4,
    levvia: 90,
    tier5: 90.0,
    assurance: 32.4,
    other: 90,
  },
  {
    name: "FY27",
    omniaUSI: 90.0,
    omniaRADC: 32.4,
    levvia: 90,
    tier5: 90.0,
    assurance: 32.4,
    other: 90,
  },
];
export const CONSOLIDATED_REVENUE_BRIDGE_FEE_COST_MOCK = [
  {
    name: "FY23",
    fee: 90.0,
    cost: 32.4,
    amt: 90,
  },
  {
    name: "FY24",
    fee: 97.7,
    cost: 32.4,
    amt: 100,
  },
  {
    name: "FY25",
    fee: 122.9,
    cost: 32.8,
    amt: 200,
  },
  {
    name: "FY26",
    fee: 147.2,
    cost: 33.1,
    amt: 250,
  },
  {
    name: "FY27",
    fee: 178.1,
    cost: 33.4,
    amt: 250,
  },
];
export enum REVENUE_PNL {
  WINSET_REVENUE_ID = "winsNetRevenueId",
  LOSSES_NET_REVENUE_ID = "lossesNetRevenueId",
  EXIT_PHASING_ID = "exitPhasingId",
  REVENUE_PRICE_INCREASE_ID = "revenuePriceIncreaseId",
  ANNUAL_INFLATION_ID = "annualInflationId",
}
export const PERCENTAGE_ABOVE_100_ERROR =
  "The value cannot be greater than 100%.";
export const GENERAL_INPUT_ERROR =
  "Invalid inputs. Hover over the individual red cells for details.";
export const REVENUE_AND_PL_CANNOT_BE_ZERO =
  "Invalid input. Amount cannot be '0.'";
export const REVENUE_AND_PL_MUST_BE_ZERO = "Invalid input. Amount must be '0.'";
export const REVENUE_TABLE_ERR0R_MSG =
  "The values for Fee and Hours must either both be '0' or both be non-zero. Please re-enter appropriate values to continue.";
export const CONVERSION_RATIOS_ERROR =
  "Invalid input. This value cannot be '0.'";
export const TOTAL_TARGET_EXIT_HOURS = "Invalid Input. Value cannot be '0'.";

export const conversionRatios: any = {
  "1": "USI",
  "2": "RADC",
  "3": "Local DC",
  "4": "Local COE",
};

export const productSegmentOtherId = "6";
export const productSegmentTier5Id = "4";

export const productSegments: any = {
  "1": "Omnia - USI",
  "2": "Omnia - RADC",
  "3": "Levvia",
  "4": "Tier 5",
  "5": "Assurance",
  "6": "Other",
};
export const productSegmentsMapping: any = {
  "2": "Omnia",
  "3": "Levvia",
  "4": "Tier 5",
  "5": "Assurance",
  "6": "Other",
}; //use this for Omnia productsegment

export const productSegmentsToIdsMapping: any = {
  Omnia: "2",
  Levvia: "3",
  "Tier 5": "4",
  Assurance: "5",
  Other: "6",
};

export const ALL_TABLES_TRANSFORMATIONS = [
  "assuranceTransformations",
  "levviaTransformations",
  "omniaRadcTransformation",
  "omniaUsiTransformation",
  "othersTransformations",
  "tier5Transformations",
];

export const HEAD_COUNT_MOCK_DATA = [
  {
    "1": 1000,
    "2": 1000,
    "3": 1000,
    "5": 1000,
    "6": null,
    planSubmissionId: 3188,
    gradeLevelId: 7,
    deliveryCenterId: 1,
  },
  {
    "1": 1000,
    "2": 1000,
    "3": 1000,
    "5": 1000,
    "6": null,
    planSubmissionId: 3188,
    gradeLevelId: 6,
    deliveryCenterId: 1,
  },
  {
    "1": 1000,
    "2": 1000,
    "3": 1000,
    "5": 1000,
    "6": null,
    planSubmissionId: 3188,
    gradeLevelId: 5,
    deliveryCenterId: 1,
  },
  {
    "1": 1000,
    "2": 1000,
    "3": 1000,
    "5": 1000,
    "6": null,
    planSubmissionId: 3188,
    gradeLevelId: 4,
    deliveryCenterId: 1,
  },
  {
    "1": 1000,
    "2": 1000,
    "3": 1000,
    "5": 1000,
    "6": null,
    planSubmissionId: 3188,
    gradeLevelId: 3,
    deliveryCenterId: 1,
  },
  {
    "1": 1000,
    "2": 1000,
    "3": 1000,
    "5": 1000,
    "6": null,
    planSubmissionId: 3188,
    gradeLevelId: 2,
    deliveryCenterId: 1,
  },
  {
    "1": 1000,
    "2": 1000,
    "3": 1000,
    "5": 1000,
    "6": null,
    planSubmissionId: 3188,
    gradeLevelId: 1,
    deliveryCenterId: 1,
  },
];

export const revenueAndPlTablesList: any = [
  { tableName: "FY{year} Revenue", tablePath: "fyrevenue", refId: 101 },
  { tableName: "Annual Inflation", tablePath: "annualinflation", refId: 33 },
  { tableName: "P&L Costs", tablePath: "plcosts", refId: 103 },
  { tableName: "Wins Net Revenue", tablePath: "winsnetrevenue", refId: 104 },
  {
    tableName: "Losses Net Revenue",
    tablePath: "lossesnetrevenue",
    refId: 105,
  },
  {
    tableName: "Total Target Exit Hours",
    tablePath: "totaltargetexithours",
    refId: 106,
  },
  { tableName: "Fee per Exit Hours", tablePath: "feeperexithours", refId: 25 },
  { tableName: "Exit Phasing", tablePath: "exitphasing", refId: 13 },
  {
    tableName: "Revenue Price Increase",
    tablePath: "revenuepriceincrease",
    refId: 107,
  },
  {
    tableName: "Increase in Hours to Deliver New Jobs",
    tablePath: "increaseinhours",
    refId: 21,
  },
];

export const headCountTablesList: any = [
  {
    tableName: "Avg Annual FTE Salary",
    tablePath: "avgannualftesalary",
    refId: 16,
  },
  {
    tableName: "Salary Price Increase",
    tablePath: "salarypriceincrease",
    refId: 108,
  },
  {
    tableName: "Full Time Equivalents",
    tablePath: "fulltimeequivalents",
    refId: 110,
  },
  {
    tableName: "Attrition Rate Per Grade",
    tablePath: "attritionratepergrade",
    refId: 12,
  },
  {
    tableName: "Promotion Rate Per Grade",
    tablePath: "promotionratepergrade",
    refId: 26,
  },
  {
    tableName: "PPMD & Director Grade Mix",
    tablePath: "ppmddirectgrademix",
    refId: 20,
  },
  {
    tableName: "USI - Rate Card",
    tablePath: "usiratecard",
    refId: 181,
  },
  { tableName: "RADC - Rate Card", tablePath: "radcratecard", refId: 182 },
  {
    tableName: "Local DC - Rate Card",
    tablePath: "localdcratecard",
    refId: 183,
  },
  {
    tableName: "Local COE - Rate Card",
    tablePath: "localcoeratecard",
    refId: 184,
  },
  {
    tableName: "USI Price Increase",
    tablePath: "usipriceincrease",
    refId: 241,
  },
  {
    tableName: "RADC Price Increase",
    tablePath: "radcpriceincrease",
    refId: 242,
  },
  {
    tableName: "Local DC Price Increase",
    tablePath: "localdcpriceincrease",
    refId: 243,
  },
  {
    tableName: "Local COE Price Increase",
    tablePath: "localcoepriceincrease",
    refId: 244,
  },
  { tableName: "USI Hours", tablePath: "usihours", refId: 281 },
  { tableName: "RADC Hours", tablePath: "radchours", refId: 282 },
  { tableName: "Local DC Hours", tablePath: "localdchours", refId: 283 },
  { tableName: "Local COE Hours", tablePath: "localcoehours", refId: 284 },
  { tableName: "USI Utilization", tablePath: "usiutilization", refId: 191 },
  { tableName: "RADC Utilization", tablePath: "radcutilization", refId: 192 },
  {
    tableName: "Local DC Utilization",
    tablePath: "localdcutilization",
    refId: 193,
  },
  {
    tableName: "Local COE Utilization",
    tablePath: "localcoeutilization",
    refId: 194,
  },
];

export const dashboardProfitsAndLossTableList: any = [
  { tableName: "Net Revenue", tablePath: "netrevenue", refId: 0 },
  {
    tableName: "Pricing Improvement",
    tablePath: "pricingimprovement",
    refId: 1,
  },
  {
    tableName: "Accumulated Exits Including Pricing Forgone",
    tablePath: "accumexits",
    refId: 2,
  },
  {
    tableName: "Net Revenue After Pricing and Exits",
    tablePath: "afterpricing",
    refId: 3,
  },
  {
    tableName: "Technical Staff Cost - Total",
    tablePath: "tsctotal",
    refId: 4,
  },
  {
    tableName: "Technical Staff Cost - Onshore",
    tablePath: "tsconshore",
    refId: 5,
  },
  {
    tableName: "Technical Staff Cost - USI",
    tablePath: "tscusi",
    refId: 6,
  },
  { tableName: "Technical Staff Cost - RADC", tablePath: "tscradc", refId: 7 },
  {
    tableName: "Technical Staff Cost - Local DC",
    tablePath: "tsclocaldc",
    refId: 8,
  },
  {
    tableName: "Technical Staff Cost - Local COE",
    tablePath: "tsclocalcoe",
    refId: 9,
  },
  {
    tableName: "Gross Profit",
    tablePath: "grossprofit",
    refId: 10,
  },

  { tableName: "Gross Margin", tablePath: "grossmargin", refId: 11 },
  { tableName: "Overheads - Total", tablePath: "overheadstotal", refId: 12 },
  {
    tableName: "Overheads - Headcount and Inflation Driven",
    tablePath: "headcountsinflation",
    refId: 13,
  },
  {
    tableName: "Overheads - Inflation Driven",
    tablePath: "inflationdriven",
    refId: 14,
  },
  { tableName: "Overheads - Other", tablePath: "overheadsother", refId: 15 },
  {
    tableName: "Overheads - Allocations",
    tablePath: "overheadsallocations",
    refId: 16,
  },
  { tableName: "Net Profit", tablePath: "netprofit", refId: 17 },
  { tableName: "Net Margin", tablePath: "netmargin", refId: 18 },
];

export const dashboardGrossMarginTableList: any = [
  { tableName: "Total Hours", tablePath: "totalhours", refId: 19 },
  {
    tableName: "Net Fee Per Hour",
    tablePath: "netfeeperhour",
    refId: 20,
  },
  {
    tableName: "Cost Per Hour",
    tablePath: "costperhour",
    refId: 21,
  },
];

export const dashboardHeadCountsTableList: any = [
  { tableName: "Headcount - Total", tablePath: "headcounttotal", refId: 22 },
  {
    tableName: "Headcount - Onshore",
    tablePath: "headcountonshore",
    refId: 23,
  },
  {
    tableName: "Headcount - USI",
    tablePath: "headcountusi",
    refId: 24,
  },
  {
    tableName: "Headcount - RADC",
    tablePath: "headcountradc",
    refId: 25,
  },
  {
    tableName: "Headcount - Local DC",
    tablePath: "headcountlocaldc",
    refId: 26,
  },
  {
    tableName: "Headcount - Local COE",
    tablePath: "headcountlocalcoe",
    refId: 27,
  },
  {
    tableName: "Promoted Headcount",
    tablePath: "promotedheadcount",
    refId: 28,
  },
  {
    tableName: "Attrition Loss",
    tablePath: "attritionloss",
    refId: 29,
  },
];

export const dashboardSummaryOfTATableList: any = [
  {
    tableName: "Omnia Summary",
    tablePath: "omniasummary",
    refId: 31,
  },
  {
    tableName: "Levvia Summary",
    tablePath: "levviasummary",
    refId: 32,
  },
  {
    tableName: "Tier 5 Summary",
    tablePath: "tier5summary",
    refId: 33,
  },
  {
    tableName: "Assurance Summary",
    tablePath: "assurancesummary",
    refId: 34,
  },
  {
    tableName: "Other Summary",
    tablePath: "othersummary",
    refId: 35,
  },
];

export const HEADCOUNTS_TABLE_NAMES = {
  promotionTable: {
    name: "promotionRatePerGrade",
    filter: "promotionPercent",
  },
  attritionTable: {
    name: "attritionRatePerGrade",
    filter: "attritionPercent",
  },
};

export const transformationsHeaders: any = {
  0: "DC Expanded View",
  1: "Opening Position",
  2: "Omnia Efficiency Improvement",
  3: "Levvia Efficiency Improvement",
  4: "“Free” Efficiency Improvement",
  5: "Strategic Resourcing Core Audit Team Shift",
  6: "USI SHIFT",
  7: "RADC SHIFT",
  8: "Local DC SHIFT",
  9: "Local COE SHIFT",
  10: "Closing/Opening Position",
};

export enum transformationsHeadersIds {
  "DC Expanded View" = 0,
  "Opening Position" = 1,
  "Omnia Efficiency Improvement" = 2,
  "Levvia Efficiency Improvement" = 3,
  "“Free” Efficiency Improvement" = 4,
  "Strategic Resourcing Core Audit Team Shift" = 5,
  "USI SHIFT" = 6,
  "RADC SHIFT" = 7,
  "Local DC SHIFT" = 8,
  "Local COE SHIFT" = 9,
  "Closing/Opening Position" = 10,
}

export const EXPORT_TYPE_MAPPING = {
  ENGINE_EXPORT: 1,
  INPUT_FORM_EXPORT: 2,
  CONSOLIDATION_DASHBOARD: 3,
  USER_REPORT: 4,
};

export const CONSOLIDATION_EXPORT = {
  EXPORT_REFERENCE_ID: 0,
  EXPORT_FILE_NAME: "Consolidation",
};

export const headCountsTableGradeLevels: any = {
  1: "1 years’ experience",
  2: "2 years’ experience",
  3: "3 years’ experience",
  4: "4 years’ experience",
  5: "5+ years’ experience",
  6: "Manager",
  7: "Senior Manager",
  8: "Director",
  9: "Signing Director",
  10: "Salary Partner",
  11: "Equity Partner",
  12: "Other",
};

export const headCountsTableGradeLevelsMini: any = {
  1: "1 years’ experience",
  2: "2 years’ experience",
  3: "3 years’ experience",
  4: "4 years’ experience",
  5: "5+ years’ experience",
  6: "Manager",
  7: "Senior Manager",
};

export const yearDateHeaders: any = {
  1: "FY" + moment().add("y").format("YY"),
  2: "FY" + moment().add(1, "y").format("YY"),
  3: "FY" + moment().add(2, "y").format("YY"),
  4: "FY" + moment().add(3, "y").format("YY"),
  5: "FY" + moment().add(4, "y").format("YY"),
};
export const Losses_Net_revenue_Note =
  "The Wins and Losses are based on " +
  "FY" +
  moment().add("y").format("YY") +
  " Prices NOT adjusted by inflation ";

export const Preparer_Required = "Preparer must be added";
export const Reviewer_Required = "Reviewer must be added";
export const delieveryCenterID = {
  USI: 1,
  RADC: 2,
  LocalDC: 3,
  LocalCOE: 4,
};

export const delieveryCenterIDKeys: any = {
  1: "USI",
  2: "RADC",
  3: "Local DC",
  4: "Local COE",
};

export enum FILTER_OPTIONS {
  COMPANY = "company",
  CONTENT = "content",
  DOCUMENT_TYPE = "document_type",
  PERIOD = "period",
  INDUSTRY = "industry",
  AUDITOR = "auditor",
  GOVERNING_BOARD = "goberning_board",
  ESG_RATING = "esg_rating",
  GEOLIST = "geo_list",
  MEMBERFIRMADMIN = "memberfirmadmin_list",
  PREPARER = "preparer_list",
  REVIEWER = "Reviewer_list",
}

export type EngineObject = {
  tableProgress?: boolean;
  inputFormSection?: number;
};

export enum TRANSFORMATION_OPTIONS {
  OMNIA_USI = "OmniaUsiTransformations",
  OMNIA_RADC = "OmniaRadcTransformations",
  LEVVIA = "LevviaTransformations",
  TIER_5 = "Tier5Transformations",
  ASSURANCE = "AssuranceTransformations",
  OTHERS = "OthersTransformations",
}

export const FORM_TYPES = [
  "N/A",
  "ESG",
  "N/AF",
  "10-Q",
  "10-K",
  "20-F",
  "DEF 14A",
  "DEFA14A",
];

export const HighlightOptions = {
  padding: 5,
  color: "rgba(255, 230, 127,0.5)",
  baseColor: "rgba(160, 220, 255, 0.6)",
  bookends: "#00A3E0",
};

export const SessionTimeout = {
  CHECK_INTERVAL_MS: 1000,
  IDLE_WAIT_TIME_MS: 1500000,
  WARNING_TIME_MS: 300000,
};

export const companyPagination = {
  pageNumber: 1,
  totalRecordCount: 30,
};

export const NOTE_TYPES = {
  SHORTLIST: 0,
  SHORTLIST_ITEM: 1,
  COMPARISON: 2,
  COMPARISON_ITEM: 3,
};

export const esgFrameworksShortNames = [
  { governingBoardId: 2, shortname: "SDGs" },
  { governingBoardId: 3, shortname: "GRI" },
  { governingBoardId: 5, shortname: "SASB" },
  { governingBoardId: 6, shortname: "CDP" },
  { governingBoardId: 7, shortname: "TCFD" },
];

export const REPORT_STATUS_ERRORS = {
  FILE_NOT_FOUND: 5,
  INVALID_CIK_NUMBER: 6,
  METADATA_MISSING: 7,
  DUPLICATE_FILE: 8,
  VIRUS_INFECTED: 9,
  UNSUPPORTED_FORMAT: 10,
};

export const homepage = "/";
export const unauthorizedPath = "/unauthorized";
export const logout = `${window.ESG.ENV.LOGOUT_URL}`;
export const dashboard = "/dashboard";
export const searchPath = "/search";
export const reportPath = "/report/";
export const comparisonPath = "/comparison";
export const savedSearchesPath = "/mysearches";
export const publishedSearchesPath = "/mysearches/published";
export const dropdownHeight = 340;
export const peerBenchmarkPath = "/peerbenchmark";
export const ESGsortResults = {
  sortBy: "ReportYear",
  sortOrder: "desc",
};

export const SECsortResults = {
  sortBy: "PeriodName",
  sortOrder: "desc",
};

export const SORT_ORDERS = {
  ASC: "asc",
  DESC: "desc",
  NONE: null,
};

export const NOTIFICATION_TYPES = {
  SHARE_SEARCH: 1,
  SHARE_SHORTLIST: 2,
  TO_BE_PURGED_SHARE: 3,
  SHARE_COMPARE: 4,
};

export const NOTIFICATION_STATUS = {
  SHOW: 1,
  CLOSED: 2,
};

export const TOAST_OPTIONS = {
  dismissTime: 5000,
  position: "bottom-center",
};

export const TABLE_ACTIONS = {
  SORT: "sort",
  NEXT_PAGE: "next_page",
  INIT: "init",
};

export const DEFAULT_SHORTLISTS_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "lastUpdatedDate",
  sortOrder: "desc",
  contentText: "",
};

export const DEFAULT_SAVED_COMPARISONS_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "lastUpdatedDate",
  sortOrder: "desc",
};

export const DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "lastUpdatedDate",
  sortOrder: "desc",
  contentText: "",
};

export const DEFAULT_SAVED_SEARCHES_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "null",
  sortOrder: "null",
};

export const DEFAULT_ESG_EXPORT_PAYLOAD = {
  exportReferenceId: 0, // Respective Id
  exportFileName: "", // Respective Name
  exportType: 1, // 1 - ESG, 2 - SEC, 3 - SHORTLIST
  exportFormat: 1, // 1 - pdf, 2 - xls, 3 - word
};

export const DEFAULT_SEC_EXPORT_PAYLOAD = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 2,
  exportFormat: 1,
};
export const EXPORT_FORMAT = {
  xls: 0,
  pdf: 1,
};

export const DEFAULT_ESG_RAW_EXPORT_PAYLOAD = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 6,
  exportFormat: 1,
};

export const DEFAULT_SHORTLIST_EXPORT_PAYLOAD = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 3,
  exportFormat: 1,
  searchCriteria: {
    contentText: "",
  },
};

export const DEFAULT_COMPARISON_EXPORT_PAYLOAD: {
  exportReferenceId: number;
  exportFileName: string;
  exportType: number;
  exportFormat: number;
  withoutKeywordHighlightItems: number[];
} = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 5,
  exportFormat: 1,
  withoutKeywordHighlightItems: [],
};

export const DEFAULT_DASHBOARD_EXPORT_PAYLOAD = {
  exportFileName: "Dashboard",
  exportType: 9,
  exportFormat: 0,
};

export const DEFAULT_SAVED_INSIGHTS_LIST_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "lastUpdatedDate",
  sortOrder: "desc",
  filterBy: null,
  filterText: null,
};

export const DOWNLOAD_EXPORT_TYPES = {
  NEW: 1,
  IN_PROGRESS: 2,
  READY_TO_DOWNLOAD: 3,
  DONE: 4,
  ERROR: 5,
};

export const FILEUPLOAD_STATUS_TYPES = {
  PRE_UPLOAD: 0,
  UPLOADED: 1,
  PROCESSING: 2,
  PROCESSED: 3,
  PROCESSING_ERROR: 4,
  FILE_NOT_FOUND: 5,
  INVALID_CIK_NUMBER: 6,
  METADATA_MISSING: 7,
  DUPLICATE_FILE: 8,
  VIRUS_INFECTED: 9,
  UNSUPPORTED_FORMAT: 10,
};

export const BenchmarkActionTypes = {
  Create_Benchmark_Custom: 1,
  Edit_Benchmark_Custom: 2,
  Create_Benchmark_Peer: 3,
  Edit_Benchmark_Peer: 4,
  Share_Benchmark_Peer: 5,
  Edit_Benchmark_Transpose: 6,
  Edit_Benchmark_RemoveMetric: 7,
  Edit_Benchmark_RemoveCompany: 8,
};

export const DEFAULT_ESG_REMOVE_PAYLOAD = {
  shortlistId: 0,
  esgReport: {
    reportId: null,
  },
  sectionItem: {
    filingId: null,
    sectionId: null,
  },
  shortlistItemType: 0,
  contentKeywords: "",
  displayOrder: 0,
};

export const INPUT_NUMBER_TOTAL = {
  REVENUE_PNL: 98,
  HEADCOUNTS_INPUTS: 185,
  TRANSFORMATION_ASSUMPTIONS: 0,
};

export const REV_PNL_TABLE_INPUT_COUNT = 10;

export const MOCK_DATA_HEAD_COUNTS_TABLE = {
  auditAnnualAVGTableData: [
    {
      planSubmissionId: 1,
      gradeLevelId: 1,
      year: 2022,
      value: "100",
    },
    {
      planSubmissionId: 1,
      gradeLevelId: 2,
      year: 2022,
      value: "100",
    },
  ],
  assuranceAnnualAVGTableData: [
    {
      planSubmissionId: 1,
      gradeLevelId: 1,
      year: 2022,
      value: "100",
    },
    {
      planSubmissionId: 1,
      gradeLevelId: 2,
      year: 2022,
      value: "100",
    },
  ],
  salaryPriceTableData: [
    {
      planSubmissionId: 1,
      gradeLevelId: 1,
      year: 2023,
      value: "100",
    },
    {
      planSubmissionId: 1,
      gradeLevelId: 2,
      year: 2023,
      value: "100",
    },
    {
      planSubmissionId: 1,
      gradeLevelId: 1,
      year: 2024,
      value: "100",
    },
    {
      planSubmissionId: 1,
      gradeLevelId: 2,
      year: 2024,
      value: "100",
    },
  ],
  yearlyUtilRateTableData: [
    {
      planSubmissionId: 1,
      gradeLevelId: 1,
      productSegmentId: 1,
      value: "100",
    },
    {
      planSubmissionId: 1,
      gradeLevelId: 2,
      productSegmentId: 1,
      value: "100",
    },
    {
      planSubmissionId: 1,
      gradeLevelId: 2,
      productSegmentId: 2,
      value: "100",
    },
  ],
  baseHeadCountsTableData: [
    {
      planSubmissionId: 1,
      gradeLevelId: 1,
      productSegmentId: 1,
      headcount: "100",
    },
    {
      planSubmissionId: 1,
      gradeLevelId: 2,
      productSegmentId: 1,
      headcount: "100",
    },
  ],
  utilisedHoursDeliverTableData: [
    {
      planSubmissionId: 1,
      productSegmentId: 1,
      deliveryCentreId: 1,
      hours: "100",
    },
    {
      planSubmissionId: 1,
      productSegmentId: 2,
      deliveryCentreId: 1,
      hours: "100",
    },
  ],
  attritionRate: [
    {
      planSubmissionId: 1,
      gradeLevelId: 1,
      attrition: "100",
    },
    {
      planSubmissionId: 1,
      gradeLevelId: 2,
      attrition: "100",
    },
  ],
};

export const MOCK_DATA_REV_PNL_TABLE = {
  revenueAndPnLInputsTableData: [
    {
      planSubmissionId: 1,
      productSegementId: 1,
      fee: "100",
      hours: "10",
    },
    //test where fee is filled in but not the hour for that row
    {
      planSubmissionId: 2,
      productSegementId: 2,
      fee: "100",
      hours: "",
    },
  ],
  totalTechincalStaffCostsTableData: [
    {
      planSubmissionId: 1,
      managementAccountId: 1,
      year: 2022,
      value: "100",
    },
  ],
  profitAndLossesCostTableData: [
    {
      planSubmissionId: 1,
      overheadTypeId: 2,
      year: 2022,
      value: "10",
    },
    {
      planSubmissionId: 4,
      overheadTypeId: 2,
      year: 2022,
      value: "10",
    },
  ],
  winsNetRevenueTableData: [
    {
      planSubmissionId: 1,
      productSegmentId: 1,
      year: 2022,
      value: "10",
    },
    {
      planSubmissionId: 1,
      productSegmentId: 1,
      year: 2023,
      value: "10",
    },
    {
      planSubmissionId: 1,
      productSegmentId: 1,
      year: 2022,
      value: "10",
    },
  ],
  lossesNetRevenueTableData: [
    {
      planSubmissionId: 1,
      productSegmentId: 1,
      year: 2022,
      value: "10",
    },
    {
      planSubmissionId: 1,
      productSegmentId: 1,
      year: 2023,
      value: "10",
    },
  ],
  exitProgramPhasesTableData: [
    {
      planSubmissionId: 1,
      totalHoursOfWork: "10",
      feePerExitHours: "100",
      priceIncrease: "",
    },
  ],
  exitPhasingTableData: [
    {
      planSubmissionId: 1,
      productSegmentId: 1,
      year: 2023,
      value: "10",
    },
    {
      planSubmissionId: 1,
      productSegmentId: 1,
      year: 2022,
      value: "10",
    },
  ],
  revenuePriceIncrease: [
    {
      planSubmissionId: 1,
      productSegmentId: 1,
      year: 2023,
      value: "10",
    },
    {
      planSubmissionId: 1,
      productSegmentId: 1,
      year: 2022,
      value: "10",
    },
  ],
};

export const DEFAULT_SEC_REMOVE_PAYLOAD = {
  shortlistId: 0,
  esgReport: {
    reportId: null,
  },
  sectionItem: {
    filingId: null,
    sectionId: null,
  },
  shortlistItemType: 1,
  contentKeywords: "",
  displayOrder: 0,
};
export const REVENUE_AND_PL_TABLE_IDS = [1, 3, 4, 5, 6, 7, 13, 21, 25, 33];
export const HEAD_COUNTS_TABLE_IDS = [8, 10, 12, 16, 17, 18, 19, 20, 24, 26];
export const TRANSFERMATION_TABLE_IDS = [22, 23, 31, 32];
export const COMMONTABLE_IDS = {
  hours: 17,
  rateCard: 18,
  utilisation: 19,
  priceIncrease: 24,
};
export const YEARLY_UTILIZATION_TABLE_ID = 9;

export const TABLE_MAPPINGS = {
  fy23Rev: 1,
  techincalStaff: 2,
  plCosts: 3,
  winsNetRev: 4,
  lossesNetRev: 5,
  exitProgramme: 6,
  exitPhasing: 13,
  revPriceIncrease: 7,
  auditAVGSalary: 14,
  assuranceAVGSalary: 15,
  salaryPriceIncrease: 8,
  yearlyUtilRate: 9,
  fullTimeEq: 10,
  fy22UtilDeliveryCentre: 11,
  attrition: 12,
  annualTotalHours: 17,
  rateCard: 18,
  utilRate: 19,
  targetManagedHours: 20,
  annualAvgFTE: 16,
  increaseHoursDeliver: 21,
  promotionRatePerGuide: 24,
  hoursUSI: 28,
  utilRateUSI: 19,
  rateCardUSI: 181,
  priceIncreaseUSI: 24,
  rateCardRADC: 182,
  priceIncreaseRADC: 24,
  promotion: 26,
  hoursRADC: 28,
  utilRateRADC: 19,
  rateCardLocalDC: 183,
  priceIncreaseLocalDC: 24,
  hoursLocalDC: 28,
  utilLocalDC: 19,
  rateCardLocalCOE: 184,
  priceIncreaeLocalCOE: 24,
  hoursLocalCOE: 28,
  utilLocalCOE: 19,
  totalTargetExitHours: 6,
  liberatedHours: 30,
  conversionRatio: 23,
  feePerExit: 25,
  transformationAssumptions: 22,
  omniaTransformation: 42,
  omniaLevvieTransformation: 43,
  omniaTier5Transformation: 44,
  omniaAssuranceTransformation: 45,
  omniaOtherTransformation: 46,
  annualinflation: 33,
  omniaYearlyUtilization: 62,
  LevviaYearlyUtilization: 63,
  Tier5YearlyUtilization: 64,
  AssuranceYearlyUtilization: 65,
  OtherYearlyUtilization: 66,
};

export const revenueTableIdList = [
  TABLE_MAPPINGS.fy23Rev,
  TABLE_MAPPINGS.plCosts,
  TABLE_MAPPINGS.winsNetRev,
  TABLE_MAPPINGS.lossesNetRev,
  TABLE_MAPPINGS.exitProgramme,
  TABLE_MAPPINGS.feePerExit,
  TABLE_MAPPINGS.exitPhasing,
  TABLE_MAPPINGS.revPriceIncrease,
  TABLE_MAPPINGS.increaseHoursDeliver,
  TABLE_MAPPINGS.annualinflation,
];

export const yearlyUtilizationTableIdList = [
  TABLE_MAPPINGS.omniaYearlyUtilization,
  TABLE_MAPPINGS.LevviaYearlyUtilization,
  TABLE_MAPPINGS.Tier5YearlyUtilization,
  TABLE_MAPPINGS.AssuranceYearlyUtilization,
  TABLE_MAPPINGS.OtherYearlyUtilization,
];

export const productSegmentsMappingWithYearlyUtilizationTableId: any = {
  "2": TABLE_MAPPINGS.omniaYearlyUtilization,
  "3": TABLE_MAPPINGS.LevviaYearlyUtilization,
  "4": TABLE_MAPPINGS.Tier5YearlyUtilization,
  "5": TABLE_MAPPINGS.AssuranceYearlyUtilization,
  "6": TABLE_MAPPINGS.OtherYearlyUtilization,
};

export const yearlyutilizationObjectNonYearKeys = [
  "planSubmissionId",
  "gradeLevelId",
  "productSegmentId",
];

export const TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS = {
  costOmnia: 502,
  costLEVVIA: 503,
};
export const TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES = {
  deployementOmnia: 505,
  deployementLEVVIA: 506,
};

export enum LIBERATED_HOURS_TABLE_IDS {
  COST_ADOPTION_ASSUMPTIONS = 1,
  DEPLOYEMENT_HOURS = 2,
}

export const TABLE_MAPPINGS_UTIL_RATE = {
  utilRateUSI: 191,
  utilRateRADC: 192,
  utilLocalDC: 193,
  utilLocalCOE: 194,
};
export const TABLE_MAPPINGS_PRICE_INCREASE = {
  priceIncreaseUSI: 241,
  priceIncreaseRADC: 242,
  priceIncreaseLocalDC: 243,
  priceIncreaeLocalCOE: 244,
};
export const TABLE_MAPPINGS_HOURS = {
  hoursUSI: 281,
  hoursRADC: 282,
  hoursLocalDC: 283,
  hoursLocalCOE: 284,
};

export const DELIVERY_CENTRE_ID_MAPPING = {
  USI: 1,
  RADC: 2,
  LOCAL_DC: 3,
  LOCAL_COE: 4,
};

export const GUIDANCE_NOTES_MAPPING = [
  {
    tableId: 1,
    heading: "revenue.pl.fy23.title",
  },
  {
    tableId: 2,
    heading: "revenue.pl.techical.staff.title",
  },
  {
    tableId: 3,
    heading: "revenue.pl.costs.title",
  },
  {
    tableId: 7,
    heading: "revenue.pl.price.percentage.title",
  },
  {
    tableId: 8,
    heading: "headCounts.salary.title",
  },
  {
    tableId: 9,
    heading: "headCounts.utilisation.title",
  },
  {
    tableId: 10,
    heading: "headCounts.full.time.eq.title",
  },
  {
    tableId: 4,
    heading: "revenue.pl.wins.title",
  },
  {
    tableId: 5,
    heading: "revenue.pl.loses.title",
  },
  {
    tableId: 6,
    heading: "total.target.exit.hours",
  },
  {
    tableId: 11,
    heading: "headCounts.fy22.hours.title",
  },
  {
    tableId: 12,
    heading: "headCounts.attrition.title",
  },
  {
    tableId: 13,
    heading: "revenue-pl.exit.phasing.main.header",
  },
  {
    tableId: 14,
    heading: "headCounts.audit.title",
  },
  {
    tableId: 15,
    heading: "headCounts.assurance.title",
  },
  {
    tableId: 16,
    heading: "avg-annual-fte-salary",
  },
  {
    tableId: 17,
    heading: "headcounts.annual.total.hours.heading",
  },
  {
    tableId: 18,
    heading: "rate.card.title",
  },
  {
    tableId: 19,
    heading: "util.rate.title",
  },
  {
    tableId: 20,
    heading: "headcounts.target.managed.hours",
  },
  {
    tableId: 21,
    heading: "increase.deliver.jobs",
  },
  {
    tableId: 24,
    heading: "price.increase.title",
  },
  {
    tableId: 25,
    heading: "revenue-pl.fee.per.exit.hors.main.header",
  },
  {
    tableId: 22,
    heading: "transformation.assumptions.guidance",
  },
  {
    tableId: 26,
    heading: "headcounts.promotion.rate.per.grade",
  },
  {
    tableId: 28,
    heading: "hours.title",
  },
  {
    tableId: 23,
    heading: "converion.ratios.title",
  },
];

export const MOCK_DATA_GUIDE_NOTES = [
  {
    guidanceId: 1,
    inputFormMasterTableId: 1,
    guidanceText:
      "1. Complete using actual FY22 information per revenue product (note if you have said yes for engagements under 50 hours or integrated services, they will be included as a product).<br>2. Total should reconcile with your Management Accounts.",
    notesText:
      "All engagements will not currently be sitting on Omnia or Levvia, however you are categorising them based on the platform they are intended to be supported on at a future date. The transformation levers will provide you the ability to model the timing at when engagements will actually move from EMS to Levvia/Omnia.<br>For those geographies that use local delivery centres to support their engagements, please allocate based on task based model versus extended team model:<br>- Omnia - USI - this is extended team based model delivery centre<br>- Omnia - RADC - this is task based model delivery centre<br>Global have made a number of requests to geographies to split their revenue by product, if you have done this before you will be in a great starting position. However it is highly recommended that geographies invest time in doing an accurate split in order to derive valuable outputs from the model. Geographies should take their client engagements from FY22, and accurately classify them, maintain that sheet as a source of truth (will be utilised again for a later step). As clients are added/removed, this list should be updated regularly for this to limit the time for developing this list again. For geographies who ran the model using revenues that were roughly split from the management accounts, the projection results were distorted and not as meaningful as those who took the time upfront to properly segregate their book of work.",
  },
  {
    guidanceId: 1,
    inputFormMasterTableId: 2,
    guidanceText:
      "1. Enter the total technical staff costs for FY22. This should reconcile with your Management Accounts.",
    notesText: null,
  },
  {
    guidanceId: 1,
    inputFormMasterTableId: 3,
    guidanceText:
      "1.This section deals with allocating direct expenses across key drivers, including headcount and revenue drivers. The starting point should be your detailed Management Accounts, and then determining the driver of the direct expenses to categorise appropriately.<br>2. Claims are sensitive, but given the cost base they can absorb, these are required for the model.<br>3. Total should reconcile with your Management Accounts.",
    notesText:
      "Geographies have had to spend time understanding the costs and their drivers. Categorising these correctly is important, as they interlink with the transformation levers that derive the output Gross Margin.",
  },
  {
    guidanceId: 1,
    inputFormMasterTableId: 4,
    guidanceText:
      "1. Please complete the relevant information related to a 4 year plan estimate of wins/losses and planned exits.<br>2. For the avoidance of doubt, where you expect to be part of a successful global win, please only include your geography's share of revenues.<br>3. Please include any additional revenue for known/expected scope change for current engagements.<br>Wins: this should be populated for new clients/engagements and scope changes. All fees should be input at todays pricing (i.e. if you have agreed for an audit commencing in FY25, at a set fee, you need to discount back to current day rates, as annual price increases will be applied on this)<br>Losses: this should be populated for known audit rotations<br>Exits: this relates to clients you are choosing to exit due to low margin",
    notesText:
      "All engagements will not currently be sitting on Omnia or Levvia, however you are categorising them based on the platform they are intended (or would have intended) to be supported on at a future date. The transformation levers will provide you the ability to model the timing at when engagements will actually move from EMS to Levvia/Omnia.<br>For those geographies that use local delivery centres to support their engagements, please allocate based on task based model versus extended team model:<br>- Omnia - USI - this is extended team based model delivery centre<br> - Omnia - RADC - this is task based model delivery centre<br>Contributors were very ambitious/optimistic on wins, which were not fair representations. Historical trend analysis, and challenge of those individuals assessments was needed to give a more reflective view.",
  },
  {
    guidanceId: 1,
    inputFormMasterTableId: 5,
    guidanceText:
      "1. Please complete the relevant information related to a 4 year plan estimate of wins/losses and planned exits.<br>2. For the avoidance of doubt, where you expect to be part of a successful global win, please only include your geography's share of revenues.<br>3. Please include any additional revenue for known/expected scope change for current engagements.<br>Wins: this should be populated for new clients/engagements and scope changes. All fees should be input at todays pricing (i.e. if you have agreed for an audit commencing in FY25, at a set fee, you need to discount back to current day rates, as annual price increases will be applied on this)<br>Losses: this should be populated for known audit rotations<br>Exits: this relates to clients you are choosing to exit due to low margin",
    notesText:
      "All engagements will not currently be sitting on Omnia or Levvia, however you are categorising them based on the platform they are intended (or would have intended) to be supported on at a future date. The transformation levers will provide you the ability to model the timing at when engagements will actually move from EMS to Levvia/Omnia.<br>For those geographies that use local delivery centres to support their engagements, please allocate based on task based model versus extended team model:<br>- Omnia - USI - this is extended team based model delivery centre<br> - Omnia - RADC - this is task based model delivery centre<br>Contributors were very ambitious/optimistic on wins, which were not fair representations. Historical trend analysis, and challenge of those individuals assessments was needed to give a more reflective view.",
  },
  {
    guidanceId: 1,
    inputFormMasterTableId: 6,
    guidanceText:
      "1. Extract all the time charged to those clients in FY22 which will be exited over the next four years<br>2. Extract the fee gained for those clients in FY22 which will be exited<br>3. Identify the average fee per exit hours (Fee gained/time charged)",
    notesText:
      "Exits relates to clients you are choosing to exit due to low margin. This would not include a current client that we have already lost at a tender process (this is a Loss), or a client we have strategically elected to not tender for in the next round (that is a Loss). Hours relating to exit clients should be below your average fee per hour.<br>If your fee per exit hour is higher than your average/target rate per hour, it indicates you have not correctly identified the 'exit' clients, given they should be all low margin clients.",
  },
  {
    guidanceId: 1,
    inputFormMasterTableId: 7,
    guidanceText:
      "1. Please allocate the total exit hours (per cell C44) across product according to the year expected to be exited.",
    notesText:
      "All engagements will not currently be sitting on Omnia or Levvia, however you are categorising them based on the platform they are intended (or would have intended) to be supported on at a future date. The transformation levers will provide you the ability to model the timing at when engagements will actually move from EMS to Levvia/Omnia.<br>For those geographies that use local delivery centres to support their engagements, please allocate based on task based model versus extended team model:<br>- Omnia - USI - this is extended team based model delivery centre<br>- Omnia - RADC - this is task based model delivery centre",
  },
  {
    guidanceId: 1,
    inputFormMasterTableId: 8,
    guidanceText:
      "1. Price increases should only be amounts which are passed on to the client, and should exclude scope change and efficiency assumptions.<br>2. Scope changes should be captured in the wins and losses section.",
    notesText: null,
  },
  {
    guidanceId: 1,
    inputFormMasterTableId: 9,
    guidanceText:
      "1. Please complete with total annual salary for Assurance and Audit split by grade.<br>2. Please note these numbers multiplied by the headcount per grade should roughly reconcile with your Management Accounts.",
    notesText:
      "Annual salary is reflective of the total cost the firm will pay in respect to that employee (i.e. base salary, national insurance, pension, etc.) <br>Years experience does not reflect years in Deloitte, but essentially total years experience that a grade is expected to have.For example 1 years' experience would be your new graduate intake year, but if you had a mid year join that joined Deloitte at a grade typical of three years experience you would classify them under 3 years' experience.",
  },
  {
    guidanceId: 1,
    inputFormMasterTableId: 10,
    guidanceText:
      "1. Please complete with total annual salary for Assurance and Audit split by grade.<br>2. Please note these numbers multiplied by the headcount per grade should roughly reconcile with your Management Accounts.",
    notesText:
      "Annual salary is reflective of the total cost the firm will pay in respect to that employee (i.e. base salary, national insurance, pension, etc.) <br>Years experience does not reflect years in Deloitte, but essentially total years experience that a grade is expected to have.For example 1 years' experience would be your new graduate intake year, but if you had a mid year join that joined Deloitte at a grade typical of three years experience you would classify them under 3 years' experience.",
  },
  {
    guidanceId: 1,
    inputFormMasterTableId: 11,
    guidanceText:
      "1. Please input the expected % change you expect to see each year in respect to base average annual salary split be grade",
    notesText: null,
  },
  {
    guidanceId: 2,
    inputFormMasterTableId: 12,
    guidanceText:
      "Please input employee yearly utilization rate (%) split by grade and by product",
    notesText:
      "This will be used to build a synthetic headcount (below table), as it is identified that employees work across platforms and product segments. To build this, identify the number of chargeable hours that reflect 100% utilization in your geography. Using the segmented revenue information, identify the total chargeable hours under each category for FY22 but grade and derive the utilization rate.",
  },
  {
    guidanceId: 3,
    inputFormMasterTableId: 13,
    guidanceText:
      "Using the utilization rate for the grades, as generated above, calculated the implied headcount to service that product category.  Please enter the FY22 base headcount by grade and by product.",
    notesText:
      "This will look different to your actual headcount, as you are deconstructing what it takes to service that product. Once derived, you may need to flex the headcount a small bit, by comparing the total salary costs based on this headcount against the management accounts. The aim is not to tie this 100%, but it should be within a few percentage points (approximately 2%).",
  },
  {
    guidanceId: 3,
    inputFormMasterTableId: 14,
    guidanceText:
      "1. Please enter the FY22 utilised delivery centre hours by delivery centre type and product type.",
    notestText:
      "For delivery centres, please use consistent classification as above here (i.e. local delivery centres that meet the criteria for Omnia -USI, please classify them here as USI). For local delivery centres that are not aligned with the extended team model (USI) or task based model (RADC) please include their hours under Local DCs.",
  },
  {
    guidanceId: 4,
    inputFormMasterTableId: 15,
    guidanceText: "Please enter the average attrition rate per grade.",
    notesText: null,
  },
];

export const ScrollOffset = 10;

export const overlay = {
  MAX_SELECTION_OFFSET: 30,
  NEXT_WORD_DELTA: 1,
  BREAK_CHARACTERS: [32],
};

export const shareItemType = {
  search: 1,
  shortlist: 2,
  compare: 3,
  peerbenchmark: 4,
};

export const comparisonItemTypes = {
  ESG: 0,
  SEC: 1,
};

export const shortlistItemType = {
  ESG: 0,
  SEC: 1,
};

export const shortlistTableFilter = {
  companyName: "Company",
  shortlistItemTitle: "Title",
  shortlistItemDocumentType: "Document Type",
  shortlistItemPeriod: "Period",
  // eslint-disable-next-line no-useless-computed-key
  ["esgReport.reportCompany.ivaCompanyRating"]: "ESG Rating",
  // eslint-disable-next-line no-useless-computed-key
  ["esgReport.reportCompany.corpGovernanceScore"]: "Governance Score",
};

export const comparisonTableFilter = {
  ComparisonName: "Name",
  ComparisonDescription: "Description",
  CreatedDate: "Created Date",
  LastUpdatedDate: "Last Saved",
};

export const peerBenchmarkTableFilter = {
  company: {
    type: "companyName",
    name: "Company",
  },
};

export const CHART_TYPES = {
  predefined: 3,
  custom: 1,
};

export const CONFIGURATION_MOCK_DATA = {
  data: {
    currency: {
      currencyCode: "USD",
      currencyName: "United State Dollar",
    },
    countryCode: "US",
    countryName: "United States",
    hasOther: true,
    hasTier5: true,
  },
  messages: [],
};
export const CONFIGURATION_ANNUAL_UTILIZATION_HOURS_ERROR =
  "Invalid Input. Value must be between '1' and '10000' ";

export const CURRENCY_MOCK_DATA = {
  data: [
    {
      currencyCode: "INR",
      currencyName: "Indian Rupees",
    },
    {
      currencyCode: "USD",
      currencyName: "United State Dollar",
    },
  ],
  messages: [],
};

export const manageFilesFilter = {
  CIKNumber: "CIK No",
  CompanyName: "Company",
  ReportTitle: "Title",
  FormTypeName: "Document Type",
  ReportStatus: "Status",
  CreatedDate: "Upload Date",
};

export const REPORT_STATUS_ID = {
  PRELOADED: 0,
  FILE_NOT_FOUND: 5,
  METADATA_MISSING: 6,
  VIRUS_INFECTED: 8,
};

export const publishSearchType = {
  savedSearches: 1,
  publishedSearches: 2,
};

export const comparisonNameLimit = 200;
export const reportNameLimit = 100;
export const pageSelectorPlaceholder = "e.g. 1-5, 8, 13";
export const pageSelectorSource = {
  SHORTLIST: 1,
  COMPARISON: 2,
};

export const Roles = {
  admin: 1,
  globalAdmin: 1,
  memberFirmAdmin: 2,
  preparer: 3,
  reviewer: 4,
};

export const not_allowed_input_vals = ["e", "E", "+"];

export const neg_value_columns_id = {
  OMNIA_EFFICIENCY: "2",
  LEVVIA_EFFICIENCY: "3",
  FREE_EFFICIENCY: "4",
  STRAT_SHIFT: "5",
  USI_SHIFT: "6",
  RADC_SHIFT: "7",
  LOCAL_DC_SHIFT: "8",
  LOCAL_COE_SHIFT: "9",
};
export const NEGATIVE_ALLOW_OVERHEAD_TYPE_ID = "1";

export const test_email = {
  preparerEmail: "preetshah7@deloitte.com",
  reviewerEmail: "preetshah7@deloitte.com",
  mfAdminEmail: "preetshah7@deloitte.com",
};

export const STATUS_ID_MAPPING = {
  ERROR: {
    id: 0,
    text: "---",
    icon: "blue-oval",
  },
  IN_PROGRESS: {
    id: 1,
    text: "In Progress",
    icon: "blue-oval",
  },
  PREPARED: {
    id: 2,
    text: "Prepared",
    icon: "teal-oval",
  },
  REVIEWED: {
    id: 3,
    text: "Reviewed",
    icon: "green-oval",
  },
  SUBMITTED: {
    id: 4,
    text: "Submitted",
    icon: "grey-oval",
  },
  ARCHIVED: {
    id: 5,
    text: "Archived",
    icon: "secondaryBlue-oval",
  },
  ROLL_FORWARD_IN_PROGRESS: {
    id: 6,
    text: "Roll Forward In-Progress",
    icon: "blue-oval",
  },
  ROLL_FORWARD_HALTED: {
    id: 7,
    text: "Roll Forward Halted",
    icon: "yellow-oval",
    tooltip:
      "Please go to the input form to refresh calculations to complete the roll forward",
  },
};

export const WiNS_NET_Four_Years_Objects = [
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
];
export const Losses_NET_Four_Years_Objects = [
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
];
export const Revenue_Price_Inc_Four_Years_Objects = [
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
];
export const Exit_Phasing_Four_Years_Objects = [
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
  {
    planSubmissionId: 0,
    productSegment: null,
    productSegmentId: 0,
    year1_value: "",
    year2_value: "",
    year3_value: "",
    year4_value: "",
    year5_value: "",
    year1_id: 0,
    year2_id: 0,
    year3_id: 0,
    year4_id: 0,
    year5_id: 0,
    year: 0,
  },
];
export const FY22_Revenue = [
  {
    revenueType: "Omnia - USI",
    fee: "usiRevenueFee",
    hours: "usiRevenueHours",
  },
  {
    revenueType: "Omnia - RADC",
    fee: "radcRevenueFee",
    hours: "radcrevenueHours",
  },
  {
    revenueType: "Levvia",
    fee: "levviaRevenueFee",
    hours: "levviaRevenueHours",
  },
  {
    revenueType: "Assurance",
    fee: "assuranceRevenueFee",
    hours: "assurenceRevenueHours",
  },
  {
    revenueType: "Integrated Services",
    fee: "integratedRevenueFee",
    hours: "integratedRevenueHours",
  },
  {
    revenueType: "Total",
    fee: "totalRevenueFee",
    hours: "toatalRevenueHours",
  },
];

export const Staff_Costs = [
  {
    costType: "Omnia - USI",
    year: "yearCoast",
  },
];
export const PL_Costs = [
  {
    costType: "Claims",
    year: "yearCoast",
  },
  {
    costType: "Direct Overheads - Headcount Driven*",
    year: "yearCoast",
  },
  {
    costType: "Direct Overheads - Revenue Driven*",
    year: "yearCoast",
  },
  {
    costType: "Total Direct Overheads",
    year: "totalyearCoast",
  },
];

export const Win_Net_Revenue = [
  {
    WinRevenueType: "Omnia - USI",
    WinUsiFy22: "fy22",
    WinUsiFy23: "fy22",
    WinUsiFy24: "fy22",
    WinUsiFy25: "fy22",
    WinUsiFy26: "fy22",
  },
  {
    WinRevenueType: "Omnia - RADC",
    WinRADCFy22: "fy22",
    WinRADCFy23: "fy22",
    WinRADCFy24: "fy22",
    WinRADCFy25: "fy22",
    WinRADCFy26: "fy22",
  },
  {
    WinRevenueType: "Levvia",
    WinLevviaFy22: "fy22",
    WinLevviaFy23: "fy22",
    WinLevviaFy24: "fy22",
    WinLevviaFy25: "fy22",
    WinLevviaFy26: "fy22",
  },
  {
    WinRevenueType: "Assurance",
    WinAssuranceFy22: "fy22",
    WinAssuranceFy23: "fy22",
    WinAssuranceFy24: "fy22",
    WinAssuranceFy25: "fy22",
    WinAssuranceFy26: "fy22",
  },
];
export const Loss_Net_Revenue = [
  {
    LossRevenueType: "Omnia - USI",
    LossUsiFy22: "fy22",
    LossUsiFy23: "fy22",
    LossUsiFy24: "fy22",
    LossUsiFy25: "fy22",
    LossUsiFy26: "fy22",
  },
  {
    LossRevenueType: "Omnia - RADC",
    LossRADCFy22: "fy22",
    LossRADCFy23: "fy22",
    LossRADCFy24: "fy22",
    LossRADCFy25: "fy22",
    LossRADCFy26: "fy22",
  },
  {
    LossRevenueType: "Levvia",
    LossLevviaFy22: "fy22",
    LossLevviaFy23: "fy22",
    LossLevviaFy24: "fy22",
    LossLevviaFy25: "fy22",
    LossLevviaFy26: "fy22",
  },
  {
    LossRevenueType: "Assurance",
    LossAssuranceFy22: "fy22",
    LossAssuranceFy23: "fy22",
    LossAssuranceFy24: "fy22",
    LossAssuranceFy25: "fy22",
    LossAssuranceFy26: "fy22",
  },
];
export const Exit_Programme_Costs = [
  {
    exitType: "Total Hours of work to exit",
    amount: "amount",
  },
  {
    exitType: "Fee rep Exit Hours",
    amount: "amount",
  },
  {
    exitType: "Price Increase (%)",
    amount: "amount",
  },
];
export const Exit_Phasing = [
  {
    exitPhasingType: "Omnia - USI",
    LossUsiFy22: "fy22",
    LossUsiFy23: "fy22",
    LossUsiFy24: "fy22",
    LossUsiFy25: "fy22",
    LossUsiFy26: "fy22",
  },
  {
    exitPhasingType: "Omnia - RADC",
    LossRADCFy22: "fy22",
    LossRADCFy23: "fy22",
    LossRADCFy24: "fy22",
    LossRADCFy25: "fy22",
    LossRADCFy26: "fy22",
  },
  {
    exitPhasingType: "Levvia",
    LossLevviaFy22: "fy22",
    LossLevviaFy23: "fy22",
    LossLevviaFy24: "fy22",
    LossLevviaFy25: "fy22",
    LossLevviaFy26: "fy22",
  },
  {
    exitPhasingType: "Assurance",
    LossAssuranceFy22: "fy22",
    LossAssuranceFy23: "fy22",
    LossAssuranceFy24: "fy22",
    LossAssuranceFy25: "fy22",
    LossAssuranceFy26: "fy22",
  },

  {
    exitPhasingType: "Total",
    LossToatalFy22: "fy22",
    LossToatalFy23: "fy22",
    LossToatalFy24: "fy22",
    LossToatalFy25: "fy22",
    LossToatalFy26: "fy22",
  },
  {
    exitPhasingType: "Total Exit Phasing Yearly Hours",
    LossToatalFy22: "fy22",
    LossToatalFy23: "fy22",
    LossToatalFy24: "fy22",
    LossToatalFy25: "fy22",
    LossToatalFy26: "fy22",
  },
];

export const Price_Increse_Revenue = [
  {
    revenueType: "Omnia - USI",
    PriceUsiFy22: "fy22",
    PriceUsiFy23: "fy22",
    PriceUsiFy24: "fy22",
    PriceUsiFy25: "fy22",
    PriceUsiFy26: "fy22",
  },
  {
    revenueType: "Omnia - RADC",
    PriceRADCFy22: "fy22",
    PriceRADCFy23: "fy22",
    PriceRADCFy24: "fy22",
    PriceRADCFy25: "fy22",
    PriceRADCFy26: "fy22",
  },
  {
    revenueType: "Levvia",
    PriceLevviaFy22: "fy22",
    PriceLevviaFy23: "fy22",
    PriceLevviaFy24: "fy22",
    PriceLevviaFy25: "fy22",
    PriceLevviaFy26: "fy22",
  },
  {
    revenueType: "Assurance",
    PriceAssuranceFy22: "fy22",
    PriceAssuranceFy23: "fy22",
    PriceAssuranceFy24: "fy22",
    PriceAssuranceFy25: "fy22",
    PriceAssuranceFy26: "fy22",
  },
];
export const DASHBOARD_DEFAULT_SORT_BY: any = {
  sortBy: "geo",
  sortOrder: "asc",
};
export const LIBERATED_HOURS_DEFAULT_PRODUCTSEGMENT: any = {
  productSegment: "Omnia",
  id: 2,
};
export const YEARLY_UTLIZATION_DEFAULT_PRODUCTSEGMENT_ID = "2";
export const YEARLY_UTLIZATION_TOGGLE_TOOLTIP =
  "Toggle on allows you to customize data for each FY.<br/> <br/>  Toggle off allows you to customize the  base year and <br />automatically copies to each FY.";

export const DASHBOARD_TABLE_TEST_VALUES = [
  {
    planSubmissionId: 1,
    geographyId: 1,
    geographyCode: null,
    countryName: "Italy",
    countryCode: "ITLY",
    containerCode: "ITLY",
    memberFirmCode: "NSE",
    baseYear: 2021,
    status: 1,
    memberFirmName: "Kobi, Johns (tesdaf)",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 2,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 1,
  },
  {
    planSubmissionId: 2,
    geographyId: 2,
    geographyCode: null,
    countryName: "Spain",
    countryCode: "SPA",
    baseYear: 2022,
    containerCode: "SPA",
    memberFirmCode: "NSE",
    status: 4,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 34,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 4,
  },
  {
    planSubmissionId: 3,
    geographyId: 3,
    geographyCode: null,
    countryName: "Canada",
    countryCode: "CAN",
    baseYear: 2022,
    containerCode: "CAN",
    memberFirmCode: "NSE",
    status: 1,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 4,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 1,
  },
  {
    planSubmissionId: 4,
    geographyId: 4,
    geographyCode: null,
    countryName: "USA",
    countryCode: "US",
    containerCode: "US",
    baseYear: 2022,
    memberFirmCode: "NSE",
    status: 4,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 100,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 4,
  },
  {
    planSubmissionId: 5,
    geographyId: 5,
    geographyCode: null,
    baseYear: 2022,
    countryName: "Zimbawae",
    countryCode: "ZIB",
    containerCode: "ZIB",
    memberFirmCode: "NSE",
    status: 2,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 98,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 2,
  },
  {
    planSubmissionId: 6,
    geographyId: 6,
    geographyCode: null,
    countryName: "Indian",
    baseYear: 2022,
    countryCode: "IND",
    containerCode: "IND",
    memberFirmCode: "NSE",
    status: 3,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 19,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 3,
  },
  {
    planSubmissionId: 7,
    geographyId: 7,
    geographyCode: null,
    countryName: "Mexico",
    countryCode: "MEX",
    baseYear: 2022,
    containerCode: "MEX",
    memberFirmCode: "NSE",
    status: 2,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 27,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 2,
  },
  {
    planSubmissionId: 8,
    geographyId: 8,
    geographyCode: null,
    countryName: "United Kingdom",
    countryCode: "UK",
    baseYear: 2022,
    containerCode: "UK",
    memberFirmCode: "NSE",
    status: 4,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 10,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 4,
  },
  {
    planSubmissionId: 9,
    geographyId: 9,
    geographyCode: null,
    countryName: "Greece",
    baseYear: 2022,
    countryCode: "GRE",
    containerCode: "GRE",
    memberFirmCode: "NSE",
    status: 3,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 87,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 3,
  },
  {
    planSubmissionId: 10,
    geographyId: 10,
    geographyCode: null,
    countryName: "Egypt",
    countryCode: "EPY",
    containerCode: "EPY",
    memberFirmCode: "NSE",
    baseYear: 2022,
    status: 4,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 40,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 4,
  },
  {
    planSubmissionId: 11,
    geographyId: 11,
    geographyCode: null,
    baseYear: 2022,
    countryName: "China",
    countryCode: "CHN",
    containerCode: "CHN",
    memberFirmCode: "NSE",
    status: 1,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 69,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 1,
  },
  {
    planSubmissionId: 12,
    geographyId: 12,
    geographyCode: null,
    countryName: "Angolia",
    countryCode: "AGN",
    containerCode: "AGN",
    memberFirmCode: "NSE",
    baseYear: 2022,
    status: 2,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 69,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 2,
  },
  {
    planSubmissionId: 13,
    geographyId: 13,
    geographyCode: null,
    countryName: "Yemen",
    countryCode: "AGN",
    containerCode: "AGN",
    baseYear: 2022,
    memberFirmCode: "NSE",
    status: 2,
    memberFirmName: "Kobi, Johns",
    memberFirmEmailId: "testasdfdsafdsfadsfdsf@email.com",
    preparerId: null,
    preparerName: "John, Doe",
    preparerEmailId: "testPREP@email.com",
    reviewerName: "Reviewr, Name",
    reviewerEmailId: "reviewer@email.com",
    percentCompletion: 69,
    dateLastModified: "2022-03-09T20:19:02.773",
    dateSubmitted: "2022-05-12T07:59:57.033",
    order: 2,
  },
];

export const FILE_FORMATS = [{ label: "ZIP", extension: ".zip", icon: "zip" }];

export enum UPLOAD_STATUS {
  UPLOADING = "uploading",
  UPLOADED = "uploaded",
  FAILED = "failed",
}

export const FILE_VALIDATION_ERRORS = [
  {
    errorId: 1,
    code: "file-invalid-size",
  },
  {
    errorId: 2,
    code: "file-invalid-type",
  },
  {
    errorId: 3,
    code: "too-many-files",
  },
];

export const FILE_REPORT_STATUS_ERRORS = [
  {
    reportStatus: 5,
    text: "File Not Found",
  },
  {
    reportStatus: 10,
    text: "Specified file format not supported",
  },
  {
    reportStatus: 20,
    text: "File exceeds maximum size of 1GB",
  },
];
export enum REPORT_STATUS {
  SUCCESS = "success",
  FAILED = "failed",
  NOT_STARTED = "not started",
  IN_PROGRESS = "in progress",
}

export const ESG_REPORT_STATUS = {
  0: {
    status: "Not started",
    statusId: 0,
    className: "not-started",
    errorId: "",
    actions: ["delete"],
  },
  1: {
    status: "In progress",
    statusId: 1,
    className: "in-progress",
    errorId: "",
    actions: ["delete"],
  },
  2: {
    status: "In progress",
    statusId: 2,
    className: "in-progress",
    errorId: "",
    actions: ["delete"],
  },
  3: {
    status: "Success",
    statusId: 3,
    className: "success",
    errorId: "",
    actions: ["download", "edit", "delete"],
  },
  4: {
    status: "Failed",
    statusId: 4,
    className: "failed",
    errorId: "manage-files.processing.failed",
    actions: ["delete"],
  },
  5: {
    status: "Failed",
    statusId: 5,
    className: "failed",
    errorId: "manage-files.file.not.found",
    actions: ["delete"],
  },
  6: {
    status: "Failed",
    statusId: 6,
    className: "failed",
    errorId: "manage-files.invalid.cik",
    actions: ["delete"],
  },
  7: {
    status: "Failed",
    statusId: 7,
    className: "failed",
    errorId: "manage-files.missing.metadata",
    actions: ["delete"],
  },
  8: {
    status: "Failed",
    statusId: 8,
    className: "failed",
    errorId: "manage-files.duplicate.files",
    actions: ["delete"],
  },
  9: {
    status: "Failed",
    statusId: 9,
    className: "failed",
    errorId: "manage-files.virus.scan",
    actions: ["delete"],
  },
  10: {
    status: "Failed",
    statusId: 10,
    className: "failed",
    errorId: "manage-files.unsupported.file",
    actions: ["delete"],
  },
  11: {
    status: "Draft",
    statusId: 11,
    className: "draft",
    errorId: "manage-files.draft.state",
    actions: ["delete"],
  },
};

export const DEFAULT_ESG_REPORT_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "CreatedDate",
  sortOrder: "desc",
};

// Visualizations
export const textColor = "#000000";
export const subTextColor = "#757B7B";
export const lightGrey = "#D0D0CE";
export const lightColor = "#F0F0F0";

export const GRAPH_OPTIONS: any = {
  bar: "bar",
  column: "column",
  pie: "pie",
  bubble: "bubble",
  donut: "donut",
  scatter: "scatter plot",
  line: "line chart",
};

export const welcomeDashboardPathname = "/home";

export const PROGRESS_BAR_DIVISOR = 5;

export const DELETE_MAP_FILES_STATUS = 12;

export const PRE_UPLOAD_STATUS = 0;

export const IGNORADED_MESSAGES = {
  INVALID_CIK: "Invalid.CIK",
  DUPLICATE_NAME: "Duplicate.Name",
};

export const STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIMEOUT: 408,
  INTERNAL_SERVER_ERROR: 500,
};

export const BENCHMARK_METRICS = {
  STANDAR_RAITINGS: {
    label: "peer-benchmarks.standard.ratings",
    metricCategoryID: 2,
    payload: "Standard_Ratings",
  },
  DETAILED_FACTORS: {
    label: "peer-benchmarks.standard.factors",
    metricCategoryID: 3,
    payload: "Detailed_Factors",
  },
  OTHER_INDICATORS: {
    label: "peer-benchmarks.other.indicators",
    metricCategoryID: 4,
    payload: "Other_Indicators",
  },
};

export const DEFAULT_METRICS_PAYLOAD = [
  BENCHMARK_METRICS.STANDAR_RAITINGS.payload,
  BENCHMARK_METRICS.DETAILED_FACTORS.payload,
  BENCHMARK_METRICS.OTHER_INDICATORS.payload,
];
export const BENCHMARK_TYPE = {
  PEER_BENCHMARK: 2,
};

export const DEFAULT_SELECTED_FIELD_PEER_BENCHMARK = {
  fieldName: "IvaRatingDate",
  fieldGroupName: null,
  displayOrder: 1,
};

export const DEFAULT_VISUALIZATION_EXCEL_EXPORT_PAYLOAD = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 8,
  exportFormat: 0,
  searchCriteria: { contentText: "" },
};

export const PREDEFINED_CHART_EXPORT_TYPE = 7;
export const CUSTOM_CHART_EXPORT_TYPE = 8;
export const CHART_EXPORT_TYPE_UI = 10;
export const DASHBOARD_PDF_ALL_EPXORT_TITLE = "Dashboard";

export const SIDE_CUSTOM_TYPE_EDIT = 1;
export const SIDE_CUSTOM_TYPE_SAVE = 0;

export const UPDATE_VALUE_TYPE = {
  GRAPH_OPTION: "graphOption",
  FILTER: "filter",
  FILTER_INDUSTRY: "industryFilter",
};

export const APP_SETTING = {
  DashboardColorCodes: "DashboardColorCodes",
  LowestScoringColorCodes: "LowestScoringColorCodes",
  HighestScoringColorCodes: "HighestScoringColorCodes",
  IvaCompanyRatingColorCodes: "IvaCompanyRatingColorCodes",
  MSCIDataSourceLabel: "MSCIDataSourceLabel",
  DashboardExportDisclaimer: "DashboardExportDisclaimer",
};

export const peerBenchmarkColor: { [key: number]: string } = {
  309: APP_SETTING.HighestScoringColorCodes,
  310: APP_SETTING.LowestScoringColorCodes,
};

export const peerBenchmarkNoResultMesages: { [key: number]: string } = {
  309: "peer-benchmarks.highest.metric.not.results",
  310: "peer-benchmarks.lowest.metric.not.results",
};

export const peerBenchmarkShowHover = ["Integer/Boolean"];
export const peerBenchmarkIvaRatingTrend = "IvaRatingTrend";

export const OVERLAY_DASHBOARD = [
  {
    name: "img1",
    imgUrl: "assets/overlay-dashboard-1.jpg",
    alt: "img1",
  },
  {
    name: "img2",
    imgUrl: "assets/overlay-dashboard-2.jpg",
    alt: "img2",
  },
];

export const OVERLAY_PEER_BENCHMARKS = [
  {
    name: "img1",
    imgUrl: "assets/overlay-peer-benchmark-1.jpg",
    alt: "img1",
  },
];

export const OVERLAY_SEARCHES = [
  {
    name: "img1",
    imgUrl: "assets/overlay-search-1.jpg",
    alt: "img1",
  },
  {
    name: "img2",
    imgUrl: "assets/overlay-search-2.jpg",
    alt: "img2",
  },
];

export const consolidationsData = [
  {
    Opening: null,
    FY24: null,
    FY25: null,
    FY26: null,
    FY27: null,
  },
  {
    opening: null,
    FY24: null,
    FY25: null,
    FY26: null,
    FY27: null,
  },
  {
    opening: null,
    FY24: null,
    FY25: null,
    FY26: null,
    FY27: null,
  },
  {
    opening: null,
    FY24: null,
    FY25: null,
    FY26: null,
    FY27: null,
  },
];

export const consolidationRows = {
  0: "DC Total % Usage",
  1: "Net Transformation Initiative: YoY Net Hours Change",
  2: "Net Transformation Initiative: YoY Efficiency Improvement (%)",
  3: "Net Transformation Initiative: Accumulated Net Hours Change",
};

export enum ClosingPositions {
  DC_Expanded_View = 0,
  Opening_Position = 1,
  Omnia_Efficiency_Improvement = 2,
  Levvia_Efficiency_Improvement = 3,
  Free_Efficiency_Improvement = 4,
  Strategic_Resourcing_Core_Audit_Team_Shift = 5,
  USI_SHIFT = 6,
  RADC_SHIFT = 7,
  Local_DC_SHIFT = 8,
  Local_COE_SHIFT = 9,
  Closing_Opening_Position = 10,
}
export enum ProductSegmentIds {
  OMNIA_USI = 1,
  OMNIA_RADC = 2,
  LEVVIA = 3,
  TIER_5 = 4,
  ASSURANCE = 5,
  OTHER = 6,
}
export const Consolidation_P_AND_L_Mock_data = {
  consolidatedProfitAndLossDetails: [
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 1,
      productSegment: "Omnia - USI",
      year: 23,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 1,
      productSegment: "Omnia - USI",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 1,
      productSegment: "Omnia - USI",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 1,
      productSegment: "Omnia - USI",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 1,
      productSegment: "Omnia - USI",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 2,
      productSegment: "Omnia - RADC",
      year: 23,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 2,
      productSegment: "Omnia - RADC",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 2,
      productSegment: "Omnia - RADC",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 2,
      productSegment: "Omnia - RADC",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 2,
      productSegment: "Omnia - RADC",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 3,
      productSegment: "Levvia",
      year: 23,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 3,
      productSegment: "Levvia",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 3,
      productSegment: "Levvia",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 3,
      productSegment: "Levvia",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 3,
      productSegment: "Levvia",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 4,
      productSegment: "Tier 5",
      year: 23,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 4,
      productSegment: "Tier 5",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 4,
      productSegment: "Tier 5",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 4,
      productSegment: "Tier 5",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 4,
      productSegment: "Tier 5",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 5,
      productSegment: "Assurance",
      year: 23,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 5,
      productSegment: "Assurance",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 5,
      productSegment: "Assurance",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 5,
      productSegment: "Assurance",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 5,
      productSegment: "Assurance",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 6,
      productSegment: "Other",
      year: 23,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 6,
      productSegment: "Other",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 6,
      productSegment: "Other",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 6,
      productSegment: "Other",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 6,
      productSegment: "Other",
      year: 27,
      value: null,
      countryName: null,
    },
  ],
  subConsolidatedProfitAndLossDetails: [],
  subTotalProfitAndLoss: [
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 0,
      productSegment: null,
      year: 23,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 0,
      productSegment: null,
      year: 24,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 0,
      productSegment: null,
      year: 25,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 0,
      productSegment: null,
      year: 26,
      value: null,
      countryName: null,
    },
    {
      subTotalProfitAndLoss: [],
      consolidatedProfitAndLossDetails: [],
      subConsolidatedProfitAndLossDetails: [],
      productSegmentId: 0,
      productSegment: null,
      year: 27,
      value: null,
      countryName: null,
    },
  ],
};

export enum DashBoardCurrencyHeader {
  NET_REVENUE = "Net Revenue",
  PRICING_IMPROVEMENT = "Pricing Improvement",
  ACCUM_EXITS = "Accumulated Exits Including Pricing Forgone",
  NET_REVENUE_AFTER_PRICING_EXITS = "Net Revenue After Pricing and Exits",
  TECHNICAL_STAFF_COST = "Technical Staff Cost - Total",
  TECHNICAL_STAFF_COST_ONSHORE = "Technical Staff Cost - Onshore",
  TECHNICAL_STAFF_COST_USI = "Technical Staff Cost - USI",
  TECHNICAL_STAFF_COST_RADC = "Technical Staff Cost - RADC",
  TECHNICAL_STAFF_COST_LOCAL_DC = "Technical Staff Cost - Local DC",
  TECHNICAL_STAFF_COST_LOCAL_COE = "Technical Staff Cost - Local COE",
  GROSS_PROFIT = "Gross Profit",
  GROSS_MARGIN = "Gross Margin (%)",
  OVER_HEAD_TOTAL = "Overheads - Total",
  DIRECT_OVER_HEAD_FTE = "Overheads - Headcount and Inflation Driven",
  DIRECT_OVER_HEAD_REVENUE = "Overheads - Inflation Driven",
  OVER_HEADS_OTHER = "Overheads - Other (manual inputs)",
  OVER_HEADS_ALLOCATION_EXPENSES = "Overheads - Allocations (manual inputs)",
  NET_PROFIT = "Net Profit",
  NET_MARGIN = "Net Margin (%)",
  HEADCOUNT = "Total Headcount (Cohort)",
  HEADCOUNT_GRADE = "Total Headcount (Grade)",
  PROMOTED_HEADCOUNT = "Promoted Headcount (lost this year due to promotion up 1)",
  HEADCOUNT_ATTRITION = "Attrition Loss",
  TOTAL_HOURS = "Total Hours",
  NET_FEE_PER_HOUR = "Net Fee Per Hour",
  COST_PER_HOUR = "Cost Per Hour",
}
export enum DashBoardTablesSection {
  PROFIT_AND_LOSSES = "'M",
  GM_ANALYSIS = "/h",
  HEADCOUNT = "Hours 'M",
}

export enum ConsolidationsKeyInitial {
  NET_FEE_REVENUE = "netRevenue",
  PRICING_IMPROVEMENT = "pricingImprovement",
  TECHNICAL_STAFF_COST = "technicalStaffCost",
  TECHNICAL_STAFF_COST_ONSHORE = "technicalStaffCostOnshore",
  TECHNICAL_STAFF_COST_USI = "technicalStaffCostUSI",
  TECHNICAL_STAFF_COST_RADC = "technicalStaffCostRADC",
  TECHNICAL_STAFF_COST_LOCAL_DC = "technicalStaffCostLocalDC",
  TECHNICAL_STAFF_COST_LOCAL_COE = "technicalStaffCostLocalCOE",
  OVERHEADS_TOTAL = "overHeadsTotal",
  DIRECT_OVERHEADS_FTE = "directOverHeadsFTE",
  DIRECT_OVERHEADS_REVENUE = "directOverHeadsRevenue",
  ACCUM_EXITS = "accumExits",
  NET_PRICING_EXITS = "netPricingExits",
  OVER_HEADS_OTHER = "Other",
  OVER_HEADS_ALLOCATION_EXPENSES = "overHeadsAllocationExpenses",
  NET_PROFIT = "netProfit",
  NET_MARGIN = "netMargin",
  GROSS_PROFIT = "grossProfit",
  GROSS_MARGIN = "grossMargin",
  TOTAL_HOURS = "totalHours",
  NET_FEE_PER_HOUR = "netFeePerHour",
  COST_PER_HOUR = "costPerHour",
  TOTAL_HEADCOUNT_COHORT = "totalheadcountCohort",
  ONSHORE_HEADCOUNT_COHORT = "onshoreheadcountCohort",
  USI_HEADCOUNT_COHORT = "usiheadcountCohort",
  RADC_HEADCOUNT_COHORT = "radcheadcountCohort",
  LOCAL_DC_HEADCOUNT_COHORT = "localdcheadcountCohort",
  LOCAL_COE_HEADCOUNT_COHORT = "localcoeheadcountCohort",
  TOTAL_HEADCOUNT_GRADE = "totalheadcountGrade",
  ONSHORE_HEADCOUNT_GRADE = "onshoreheadcountGrade",
  USI_HEADCOUNT_GRADE = "usiheadcountGrade",
  RADC_HEADCOUNT_GRADE = "radcheadcountGrade",
  LOCAL_DC_HEADCOUNT_GRADE = "localdcheadcountGrade",
  LOCAL_COE_HEADCOUNT_GRADE = "localcoeheadcountGrade",
  PROMOTED_HEADCOUNT = "Promotedheadcount",
  HEADCOUNT_ATTRITION_LOSS = "headCountAttritionLoss",
}

export enum TransformationConsolidationsColumns {
  DC_TOTAL = "DC Total % Usage",
  NET_TRANSFORMATION_NET_HOURS = "Net Transformation Initiative: YoY Net Hours Change",
  NET_TRANSFORMATION_EFFICIENCY = "Net Transformation Initiative: YoY Efficiency Improvement (%)",
  NET_TRANSFORMATION_ACCUMULATED = "Net Transformation Initiative: Accumulated Net Hours Change",
}

export const gorssMarginDummyTable = [
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 1,
    productSegment: "Omnia - USI",
    year: 23,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 1,
    productSegment: "Omnia - USI",
    year: 24,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 1,
    productSegment: "Omnia - USI",
    year: 25,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 1,
    productSegment: "Omnia - USI",
    year: 26,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 1,
    productSegment: "Omnia - USI",
    year: 27,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 2,
    productSegment: "Omnia - RADC",
    year: 23,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 2,
    productSegment: "Omnia - RADC",
    year: 24,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 2,
    productSegment: "Omnia - RADC",
    year: 25,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 2,
    productSegment: "Omnia - RADC",
    year: 26,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 2,
    productSegment: "Omnia - RADC",
    year: 27,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 3,
    productSegment: "Levvia",
    year: 23,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 3,
    productSegment: "Levvia",
    year: 24,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 3,
    productSegment: "Levvia",
    year: 25,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 3,
    productSegment: "Levvia",
    year: 26,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 3,
    productSegment: "Levvia",
    year: 27,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 4,
    productSegment: "Tier 5",
    year: 23,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 4,
    productSegment: "Tier 5",
    year: 24,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 4,
    productSegment: "Tier 5",
    year: 25,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 4,
    productSegment: "Tier 5",
    year: 26,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 4,
    productSegment: "Tier 5",
    year: 27,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 5,
    productSegment: "Assurance",
    year: 23,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 5,
    productSegment: "Assurance",
    year: 24,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 5,
    productSegment: "Assurance",
    year: 25,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 5,
    productSegment: "Assurance",
    year: 26,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 5,
    productSegment: "Assurance",
    year: 27,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 6,
    productSegment: "Other",
    year: 23,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 6,
    productSegment: "Other",
    year: 24,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 6,
    productSegment: "Other",
    year: 25,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 6,
    productSegment: "Other",
    year: 26,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 6,
    productSegment: "Other",
    year: 27,
    value: "",
    countryName: null,
  },
];

export const grossMarginDummyTableHeaders = [
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 0,
    productSegment: null,
    year: 23,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 0,
    productSegment: null,
    year: 24,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 0,
    productSegment: null,
    year: 25,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 0,
    productSegment: null,
    year: 26,
    value: "",
    countryName: null,
  },
  {
    subTotalProfitAndLoss: [],
    consolidatedProfitAndLossDetails: [],
    subConsolidatedProfitAndLossDetails: [],
    productSegmentId: 0,
    productSegment: null,
    year: 27,
    value: "",
    countryName: null,
  },
];

export const consolidationHeadcountsMockData = {
  consolidatedHeadCountDetails: [
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 11,
      productSegment: "Omnia - USI",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 11,
      productSegment: "Omnia - USI",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 11,
      productSegment: "Omnia - USI",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 11,
      productSegment: "Omnia - USI",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 11,
      productSegment: "Omnia - USI",
      year: 28,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 10,
      productSegment: "Omnia - RADC",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 10,
      productSegment: "Omnia - RADC",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 10,
      productSegment: "Omnia - RADC",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 10,
      productSegment: "Omnia - RADC",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 10,
      productSegment: "Omnia - RADC",
      year: 28,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 9,
      productSegment: "Levvia",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 9,
      productSegment: "Levvia",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 9,
      productSegment: "Levvia",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 9,
      productSegment: "Levvia",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 9,
      productSegment: "Levvia",
      year: 28,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 8,
      productSegment: "Tier 5",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 8,
      productSegment: "Tier 5",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 8,
      productSegment: "Tier 5",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 8,
      productSegment: "Tier 5",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 8,
      productSegment: "Tier 5",
      year: 28,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 7,
      productSegment: "Assurance",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 7,
      productSegment: "Assurance",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 7,
      productSegment: "Assurance",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 7,
      productSegment: "Assurance",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 7,
      productSegment: "Assurance",
      year: 28,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 6,
      productSegment: "Other",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 6,
      productSegment: "Other",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 6,
      productSegment: "Other",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 6,
      productSegment: "Other",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 6,
      productSegment: "Other",
      year: 28,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 5,
      productSegment: "Other",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 5,
      productSegment: "Other",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 5,
      productSegment: "Other",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 5,
      productSegment: "Other",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 5,
      productSegment: "Other",
      year: 28,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 4,
      productSegment: "Other",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 4,
      productSegment: "Other",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 4,
      productSegment: "Other",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 4,
      productSegment: "Other",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 4,
      productSegment: "Other",
      year: 28,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 3,
      productSegment: "Other",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 3,
      productSegment: "Other",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 3,
      productSegment: "Other",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 3,
      productSegment: "Other",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 3,
      productSegment: "Other",
      year: 28,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 2,
      productSegment: "Other",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 2,
      productSegment: "Other",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 2,
      productSegment: "Other",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 2,
      productSegment: "Other",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 2,
      productSegment: "Other",
      year: 28,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 1,
      productSegment: "Other",
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 1,
      productSegment: "Other",
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 1,
      productSegment: "Other",
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 1,
      productSegment: "Other",
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 1,
      productSegment: "Other",
      year: 28,
      value: null,
      countryName: null,
    },
  ],
  subConsolidatedHeadCountDetails: [],
  subTotalHeadCount: [
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 0,
      productSegment: null,
      year: 24,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 0,
      productSegment: null,
      year: 25,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 0,
      productSegment: null,
      year: 26,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 0,
      productSegment: null,
      year: 27,
      value: null,
      countryName: null,
    },
    {
      consolidatedHeadCountDetails: [],
      subConsolidatedHeadCountDetails: [],
      subTotalHeadCount: [],
      gradeLevelId: 0,
      productSegment: null,
      year: 28,
      value: null,
      countryName: null,
    },
  ],
};

export const COST_ASSUMPTION_TABLE: any = {
  1: "Net cost/efficiency in year 1",
  2: "Efficiency in year 2",
  3: "Efficiency in year 3",
  4: "Efficiency in year 4",
  5: "Ongoing",
  6: "Total Efficiency from adoption",
  7: "Deployment - % of files on {ProductSegment} (cumulative)",
  8: "Deployment - % of files on {ProductSegment} (in-year transition)",
  9: "Opening audit hours",
  10: "Net cost/efficiency in transition year",
  11: "Liberated hours",
  12: "Net in year liberated hours",
  13: "Closing audit hours",
  14: "Net in year liberated Hours (%)",
};

export const deployementTableReference = [
  {
    leverId: 7,
  },
  {
    leverId: 8,
  },
];
export const productSegmentOmnia = "Omnia";
export const totalEfficiencyText = "Total Efficiency from adoption";

export const costAdoptionMockData = [
  {
    adoptionAssumptionId: null,
    productSegmentId: 1, //USI
    planSubmissionId: 3188,
    value: 100,
    leverId: 6, //Transition cost in year 1
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 1, //USI
    planSubmissionId: 3188,
    value: 10,
    leverId: 2, //Efficiency in year 2
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 1, //USI
    planSubmissionId: 3188,
    value: 10,
    leverId: 3, //Efficiency in year 3
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 1, //USI
    planSubmissionId: 3188,
    value: 10,
    leverId: 5, //Ongoing
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 1, //USI
    planSubmissionId: 3188,
    value: 10,
    leverId: 7, //Total Efficiency from adoption
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 2, //RADC
    planSubmissionId: 3188,
    value: 10,
    leverId: 6, //Transition cost in year 1
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 2, //RADC
    planSubmissionId: 3188,
    value: 10,
    leverId: 2, //Efficiency in year 2
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 2, //RADC
    planSubmissionId: 3188,
    value: 10,
    leverId: 3, //Efficiency in year 3
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 2, //RADC
    planSubmissionId: 3188,
    value: 10,
    leverId: 5, //Ongoing
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 2, //RADC
    planSubmissionId: 3188,
    value: 10,
    leverId: 7, //Total Efficiency from adoption
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 3, //Levvia
    planSubmissionId: 3188,
    value: 4,
    leverId: 1, //Net cost/efficiency in year 1
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 3, //Levvia
    planSubmissionId: 3188,
    value: 5,
    leverId: 2, //Efficiency in year 2
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 3, //Levvia
    planSubmissionId: 3188,
    value: 11,
    leverId: 3, //Efficiency in year 3
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 3, //Levvia
    planSubmissionId: 3188,
    value: 12,
    leverId: 4, //Efficiency in year 4
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 3, //Levvia
    planSubmissionId: 3188,
    value: 20,
    leverId: 5, //Ongoing
  },
  {
    adoptionAssumptionId: null,
    productSegmentId: 3, //Levvia
    planSubmissionId: 3188,
    value: 15,
    leverId: 7, //Total Efficiency from adoption
  },
];

export const configurationProjectedYearsList = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

export const GMBridgeX = [
  "startYearValue",
  "wins",
  "losses",
  "exits",
  "pricing",
  "efficiency",
  "onshore",
  "offshore",
  "rateIncrease",
  "endYearValue",
];

export const GMBridgeY = {
  REVENUE: "REVENUE",
  COST: "COST",
  GROSS: "GROSS PROFIT",
};

export const GMBridgeToggle = {
  CURRENCY: "Currency",
  PERCENTAGE: "Percentages",
};
export const GMBridgeBreakdown = ["onshore", "offshore", "rateIncrease"];

export const overHeadTypeIdList: any = {
  "1": "Overheads - Other (manual inputs)",
  "2": "Overheads - Headcount and inflation driven",
  "3": "Overheads - Inflation driven",
  "4": "Overheads - Controllable Expenses",
  "5": "Overheads - Allocations (manual inputs)",
};
export const overHeadTypedIdOrderList = [2, 3, 1, 5];

export const pAndLCostMockData = [
  {
    planSubmissionId: 2897,
    overheadTypeId: 1,
    productSegmentId: null,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {
      "24": true,
      "25": true,
      "26": true,
      "27": true,
      "28": true,
      "29": true,
    },
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 1,
    productSegmentId: 1,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {},
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 1,
    productSegmentId: 2,
    "24": 45.0,
    "25": 45.0,
    "26": 45.0,
    "27": 45.0,
    "28": 45.0,
    "29": 45.0,
    disabled: {},
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 1,
    productSegmentId: 3,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {},
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 1,
    productSegmentId: 4,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {},
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 1,
    productSegmentId: 5,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {},
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 1,
    productSegmentId: 6,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {},
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 2,
    productSegmentId: null,
    "24": 45.0,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {
      "25": true,
      "26": true,
      "27": true,
      "28": true,
      "29": true,
    },
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 3,
    productSegmentId: null,
    "24": 90.0,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {
      "25": true,
      "26": true,
      "27": true,
      "28": true,
      "29": true,
    },
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 5,
    productSegmentId: null,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {
      "24": true,
      "25": true,
      "26": true,
      "27": true,
      "28": true,
      "29": true,
    },
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 5,
    productSegmentId: 1,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {},
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 5,
    productSegmentId: 2,
    "24": 45.0,
    "25": 45.0,
    "26": 45.0,
    "27": 45.0,
    "28": 45.0,
    "29": 45.0,
    disabled: {},
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 5,
    productSegmentId: 3,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {},
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 5,
    productSegmentId: 4,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {},
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 5,
    productSegmentId: 5,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {},
  },
  {
    planSubmissionId: 2897,
    overheadTypeId: 5,
    productSegmentId: 6,
    "24": null,
    "25": null,
    "26": null,
    "27": null,
    "28": null,
    "29": null,
    disabled: {},
  },
];

export const cohorts: any = {
  All: 0,
  Omnia: 2,
  Levvia: 3,
  "Tier 5": 4,
  Assurance: 5,
  Other: 6,
};

export const Indentation_Require: string[] = [
  "Assurance",
  "Tier 5",
  "Levvia",
  "Omnia",
  "Other",
];

export const dashboardRefreshIconTootlTipText = {
  disabledText: "Dashboard is up to date",
  enabledText: "Refresh dashboard",
};
