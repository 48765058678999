import { useEffect, useState, createContext, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import { productSegmentsMapping } from "utils/constants";

const LiberatedHoursContext: any = createContext(null);

type liberatedHoursContext = {
  children: React.ReactNode;
};

export const liberateHoursCostAssumptionSectionWiseTableData = (data: any) => {
  let liberateHoursCostAssumptionSectionWiseData: any = {};

  let tableVals: any = [];

  data.map((item: any) => {
    const { error, errorList, ...newObject } = item;
    tableVals.push(newObject);
  });

  tableVals?.map((item: any) => {
    if (
      liberateHoursCostAssumptionSectionWiseData[
        productSegmentsMapping[item.productSegmentId]
      ]
    ) {
      liberateHoursCostAssumptionSectionWiseData[
        productSegmentsMapping[item.productSegmentId]
      ].push(item);
    } else {
      liberateHoursCostAssumptionSectionWiseData[
        productSegmentsMapping[item.productSegmentId]
      ] = [item];
    }
  });

  return liberateHoursCostAssumptionSectionWiseData;
};

export const LiberatedHoursContextProvider = ({
  children,
}: liberatedHoursContext) => {
  const params: any = useParams();
  const locationName = useLocation().pathname;

  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);

  let planSubmissionId = inputFormDataState?.metaData?.planSubmissionId
    ? inputFormDataState?.metaData?.planSubmissionId
    : Number(params?.id);

  const [costAssumptionCenterData, setCostAssumptionCenterData] = useState<any>(
    {}
  );
  const [
    deployementAndLiberatedHoursData,
    setDeployementAndLiberatedHoursData,
  ] = useState<any>({});

  const [compareTableData, setCompareTableData] = useState<any>({
    costAssumptionCenterData: [],
    deployementAndLiberatedHoursData: [],
  });
  const [costAssumptionLoader, setCostAssumptionLoader] = useState<any>(true);
  const [
    deploymentAndLiberatedHoursLoader,
    setDeploymentAndLiberatedHoursLoader,
  ] = useState<any>(true);
  const [deployementCalData, setDeployementCalData] = useState<any>();

  return (
    <LiberatedHoursContext.Provider
      value={{
        costAssumptionCenterData,
        setCostAssumptionCenterData,
        deployementAndLiberatedHoursData,
        setDeployementAndLiberatedHoursData,
        deploymentAndLiberatedHoursLoader,
        setDeploymentAndLiberatedHoursLoader,
        compareTableData,
        setCompareTableData,
        costAssumptionLoader,
        deployementCalData,
        setDeployementCalData,
        setCostAssumptionLoader,
      }}
    >
      {children}
    </LiberatedHoursContext.Provider>
  );
};

export function useAPI() {
  const context = useContext(LiberatedHoursContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
