import classNames from "classnames";
import ReactTooltip from "react-tooltip";

type Props = {
  label?: any;
  value: boolean;
  onChange: any;
  disabled?: boolean;
  tooltip?: any;
  color?: string;
  isLabelFront?: boolean;
};

const Toggle = ({
  label,
  value,
  onChange,
  disabled = false,
  tooltip,
  color,
  isLabelFront = false,
}: Props) => {
  const BLOCK = "toggle";
  const labelDiv = () => {
    return <span className={`${BLOCK}__label`}>{label}</span>;
  };
  return (
    <div
      className={BLOCK}
      data-testid="toggle-container"
      data-tip={tooltip}
      data-for="toggletooltip"
    >
      {isLabelFront && labelDiv()}
      <label
        className={classNames(`${BLOCK}__toggle`, {
          [`${BLOCK}__slider-enable `]: !disabled,
        })}
      >
        {tooltip && <ReactTooltip id="toggletooltip" html={true} />}
        <input
          className={BLOCK}
          type="checkbox"
          checked={value}
          onChange={onChange}
          disabled={disabled}
          data-test="checkbox-item"
        />
        <div
          className={`slider slider-${
            disabled ? "transparent" : color ? color : "primary"
          }`}
        ></div>
      </label>
      {!isLabelFront && labelDiv()}
    </div>
  );
};

export default Toggle;
