import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
type GrphLegendInfo = {
  BLOCK: string;
  isGMBridge?: boolean;
};
const GraphLegends = ({ BLOCK, isGMBridge }: GrphLegendInfo) => {
  return (
    <div
      className={classNames(`${BLOCK}__legends`, {
        [`${BLOCK}__legends-GMBridge`]: isGMBridge,
      })}
    >
      {!isGMBridge && (
        <div className={`${BLOCK}__legend`}>
          <span className={`${BLOCK}__legend__total`}></span>
          <div className={`${BLOCK}__legends--name`}>
            <FormattedMessage id="graph_legend_total" />
          </div>
        </div>
      )}
      <div className={`${BLOCK}__legend`}>
        <span className={`${BLOCK}__legend__decrease`}></span>
        <div className={`${BLOCK}__legends--name`}>
          <FormattedMessage id="graph_legend_decrease" />
        </div>
      </div>
      <div className={`${BLOCK}__legend`}>
        <span className={`${BLOCK}__legend__increase`}></span>
        <div className={`${BLOCK}__legends--name`}>
          <FormattedMessage id="grapg_legend_increase" />
        </div>
      </div>
    </div>
  );
};
export default GraphLegends;
