import react, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootStore } from "services/store.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import classNames from "classnames";
import {
  loadCurrency,
  setCurrency,
  setConfigurationDataSubmit,
} from "services/inputForm/inputFormSlice";
import DropDown from "../search/filters/drop-down";
import {
  STATUS_ID_MAPPING,
  ConfigurationAnuualUtilizationToolTip,
  CONFIGURATION_ANNUAL_UTILIZATION_HOURS_ERROR,
} from "utils/constants";
import ReactTooltip from "react-tooltip";
import { addToastMessage } from "services/common/commonsSlice";
import { checkConfigurationDirty } from "utils/useInputForm";
interface configuration {
  toggleNavigation: boolean;
  setSaved: any;
  setConfigurationDirty: Function;
}

const Configuration = ({
  toggleNavigation,
  setSaved,
  setConfigurationDirty,
}: configuration) => {
  const BLOCK = "configuration";
  const dispatch = useDispatch();

  const configuration = useSelector((state: any) => state.inputForm);
  const [annualUtilizationHours, setAnnualUtilizationHours] = useState<
    number | null
  >(2008); //Defaulted to 2008
  const [isAnualUtilizationError, setIsAnualUtilizationError] =
    useState<boolean>(false);
  const [signedOpinionsOption, setSignedOpinionsOption] = useState<string>("");
  const [hasTier5Option, setHasTier5Option] = useState<string>("");
  const [userCountry, setUserCountry] = useState<string>("");
  const [otherServiceDescription, setOtherServiceDescription] =
    useState<string>("");
  const inputFormDataState = useSelector((state: any) => state.inputForm);
  const [disableConfiguration, setDisableConfiguration] =
    useState<boolean>(false);
  const handleChange = (e: any) => {
    const regex = /^[+@=-].*/;
    setSaved(false);
    setOtherServiceDescription(
      !regex.test(e.target.value) ? e.target.value : ""
    );
  };

  useEffect(() => {
    setDisableConfiguration(
      inputFormDataState?.inputFormStatusId === STATUS_ID_MAPPING.ARCHIVED.id
        ? true
        : false
    );
  }, [inputFormDataState?.inputFormStatusId]);
  useEffect(() => {
    let configurationData = {
      currency:
        configuration?.currency?.length > 0
          ? {
              currencyCode: String(configuration?.currency[0])?.split(" - ")[0],
              name: String(configuration?.currency[0])?.split(" - ")[1],
              exchangeRate:
                configuration?.configurationData?.currency?.exchangeRate,
            }
          : null,
      baseYear: configuration?.configurationData?.baseYear,
      annualUtilisationHours: annualUtilizationHours
        ? annualUtilizationHours
        : null,
      countryCode: configuration.configurationData.countryCode
        ? configuration.configurationData.countryCode
        : null,
      countryName: configuration.configurationData.countryName
        ? configuration.configurationData.countryName
        : null,
      hasOther: hasTier5Option
        ? hasTier5Option === "Yes"
          ? true
          : false
        : null,
      hasTier5: signedOpinionsOption
        ? signedOpinionsOption === "Yes"
          ? true
          : false
        : null,
      otherServiceDescription: otherServiceDescription,
      numberFY: configuration.configurationData.numberFY,
    };
    dispatch(setConfigurationDataSubmit(configurationData));
    checkConfigurationDirty(
      configurationData,
      configuration?.configurationData,
      setConfigurationDirty
    );
  }, [
    signedOpinionsOption,
    hasTier5Option,
    configuration.currency,
    otherServiceDescription,
    annualUtilizationHours,
  ]);

  useEffect(() => {
    if (configuration.configurationData.hasTier5 === false) {
      setSignedOpinionsOption("No");
    } else if (configuration.configurationData.hasTier5 === true) {
      setSignedOpinionsOption("Yes");
    } else {
      setSignedOpinionsOption("");
    }

    if (configuration.configurationData.hasOther === false) {
      setHasTier5Option("No");
    } else if (configuration.configurationData.hasOther === true) {
      setHasTier5Option("Yes");
    } else {
      setHasTier5Option("");
    }

    if (
      configuration.configurationData.currency?.currencyCode &&
      configuration.configurationData.currency?.name
    ) {
      let currency =
        configuration.configurationData.currency.currencyCode +
        " - " +
        configuration.configurationData.currency.name;
      dispatch(setCurrency([currency]));
    } else {
      dispatch(setCurrency([]));
    }
    if (configuration.configurationData?.annualUtilisationHours) {
      setAnnualUtilizationHours(
        configuration.configurationData?.annualUtilisationHours
      );
    } else {
      setAnnualUtilizationHours(2008);
    }
    setOtherServiceDescription(
      configuration.configurationData.otherServiceDescription
    );

    setUserCountry(configuration.configurationData.countryName);
  }, [configuration.configurationData]);

  useEffect(() => {
    dispatch(loadCurrency());
  }, []);

  const onValueChangeSignedOpinions = (e: any) => {
    setSaved(false);
    setSignedOpinionsOption(e.target.value);
  };
  const onValueChangeHasTier5 = (e: any) => {
    setSaved(false);
    setHasTier5Option(e.target.value);
  };

  const selectCurrency = (currency: any) => {
    setSaved(false);
    dispatch(setCurrency([currency]));
  };
  const changeAnnualUtilizationHours = (e: any, isBlur: boolean = false) => {
    setSaved(false);
    e.preventDefault();
    if (
      isBlur &&
      (e.target.value <= 0 || !e.target.value || e.target.value > 10000)
    ) {
      setIsAnualUtilizationError(true);
      setAnnualUtilizationHours(null);
    } else {
      setAnnualUtilizationHours(Number(e.target.value));
      setIsAnualUtilizationError(false);
    }
  };
  useEffect(() => {
    if (isAnualUtilizationError) {
      dispatch(
        addToastMessage({
          description: <>{CONFIGURATION_ANNUAL_UTILIZATION_HOURS_ERROR}</>,
        })
      );
    }
  }, [isAnualUtilizationError]);
  return (
    <div
      className={`${BLOCK}`}
      style={{ width: !toggleNavigation ? "100%" : "75%" }}
      data-test="configuration"
    >
      <div className="scroll-add-remove">
        <div className={`${BLOCK}__header`}>
          <div className={`${BLOCK}__header--title`}>
            <FormattedMessage id={`configuration.main.title`} />
          </div>
        </div>
        {configuration.loading?.filter(
          (filter: any) => filter === "configurationLoading"
        ).length > 0 ? (
          <div className={`${BLOCK}__spinner-container`}>
            <Icon
              name={"loading"}
              width={50}
              height={50}
              className={`${BLOCK}--loading`}
            />
          </div>
        ) : (
          <div className={`${BLOCK}--input`}>
            <div className={`AddNewGeo__required`}>
              <FormattedMessage id="configuration.geography.heading" />
            </div>
            <div className={`${BLOCK}__user-country primary-disabled`}>
              {userCountry}
              <div style={{ float: "right" }}>
                <Icon name="chevron-down" height={24} />
              </div>
            </div>
            <div style={{ width: "350px" }}>
              <DropDown
                className={`AddNewGeo__drop-down`}
                label={
                  <div className={`AddNewGeo__required`}>
                    <FormattedMessage id="reporting.currency" />
                  </div>
                }
                placeholder={
                  <FormattedMessage id="add.member-modal.geo.placehoder" />
                }
                loading={
                  configuration.loading.filter(
                    (filter: any) => filter === "currencyLoading"
                  ).length > 0
                }
                disabled={disableConfiguration}
                objectKeys={{
                  name: "name",
                  id: "currencyCode",
                }}
                values={configuration.currency}
                options={configuration.currencyList}
                handleSelect={selectCurrency}
                displayFunction={(option: any) =>
                  option.currencyCode + " - " + option.name
                }
              />
            </div>
            {/* Annual utilization (hours) Field */}
            <ReactTooltip effect="solid" />
            <div className={`${BLOCK}__utilization-container`}>
              <div className={`${BLOCK}__required`}>
                <FormattedMessage id="configuration.annual.utilization" />
              </div>
              <div className={`${BLOCK}__utilization-container--tool_tip_icon`}>
                <input
                  data-tip={
                    isAnualUtilizationError
                      ? CONFIGURATION_ANNUAL_UTILIZATION_HOURS_ERROR
                      : ""
                  }
                  data-test="utilization-inputbox"
                  disabled={disableConfiguration}
                  className={classNames(
                    `${BLOCK}__utilization-container--input`,
                    {
                      [`${BLOCK}__utilization-container--input--disable`]:
                        disableConfiguration,
                      [`${BLOCK}__utilization-container--input--default`]:
                        !isAnualUtilizationError,
                      [`${BLOCK}__utilization-container--input--error`]:
                        isAnualUtilizationError,
                    }
                  )}
                  type="number"
                  onKeyDown={(e) => {
                    if (
                      e.key === "." ||
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "+" ||
                      e.key === "-" ||
                      (e.keyCode >= 65 && e.keyCode <= 90) ||
                      e.shiftKey === true ||
                      e.keyCode === 38 ||
                      e.keyCode === 40
                    ) {
                      e.preventDefault();
                    }
                  }}
                  value={annualUtilizationHours ? annualUtilizationHours : ""}
                  onChange={(e) => changeAnnualUtilizationHours(e)}
                  onBlur={(e) => changeAnnualUtilizationHours(e, true)}
                ></input>
                <div>
                  <div
                    data-tip={ConfigurationAnuualUtilizationToolTip}
                    className={`${BLOCK}__utilization-container--icon-div`}
                  >
                    <Icon name="information_blue" height={20} width={20} />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "15px", fontWeight: "600", fontSize: 14 }}>
              <span className={`AddNewGeo__required`}>
                <FormattedMessage id="configuration.other.services" />
              </span>
              <br />
              <div className="radio-class">
                <input
                  type="radio"
                  className="radio"
                  value="Yes"
                  disabled={disableConfiguration}
                  checked={hasTier5Option === "Yes"}
                  onChange={onValueChangeHasTier5}
                />
                <label
                  className="inputLabel"
                  data-test="radioButtonYES"
                  style={{ marginRight: "30px" }}
                  onClick={() => {
                    if(inputFormDataState.inputFormStatusId !== STATUS_ID_MAPPING.ARCHIVED.id){
                      setSaved(false);
                      setHasTier5Option("Yes");
                    }
                  }}
                >
                  Yes
                </label>
                <input
                  type="radio"
                  className="radio"
                  value="No"
                  disabled={disableConfiguration}
                  checked={hasTier5Option === "No"}
                  onChange={onValueChangeHasTier5}
                />
                <label
                  className="inputLabel"
                  data-test="radioButtonNO"
                  onClick={() => {
                    if(inputFormDataState.inputFormStatusId !== STATUS_ID_MAPPING.ARCHIVED.id){
                      setSaved(false);
                      setHasTier5Option("No");
                    }
                  }}
                >
                  No
                </label>
              </div>
            </div>
            <br />
            <div style={{ marginTop: "15px", fontWeight: "600", fontSize: 14 }}>
              <span>
                <FormattedMessage id="configuration.signed.opinions" />
              </span>
              <br />
              <div className="radio-class">
                <input
                  type="radio"
                  className="radio"
                  value="Yes"
                  disabled={disableConfiguration}
                  checked={signedOpinionsOption === "Yes"}
                  onChange={onValueChangeSignedOpinions}
                />
                <label
                  className="inputLabel"
                  data-test="radioButton-YES"
                  style={{ marginRight: "30px" }}
                  onClick={() => {
                    if(inputFormDataState.inputFormStatusId !== STATUS_ID_MAPPING.ARCHIVED.id){
                      setSaved(false);
                      setSignedOpinionsOption("Yes");
                    }
                  }}
                >
                  Yes
                </label>
                <input
                  type="radio"
                  className="radio"
                  value="No"
                  disabled={disableConfiguration}
                  checked={signedOpinionsOption === "No"}
                  onChange={onValueChangeSignedOpinions}
                />
                <label
                  className="inputLabel"
                  data-test="radioButton-NO"
                  onClick={() => {
                    if(inputFormDataState.inputFormStatusId !== STATUS_ID_MAPPING.ARCHIVED.id){
                      setSaved(false);
                      setSignedOpinionsOption("No");
                    }
                  }}
                >
                  No
                </label>
              </div>
            </div>
            <div style={{ marginTop: 60 }}>
              <div className={`${BLOCK}__text-area-label`}>
                Other service descriptions
              </div>
              <textarea
                value={otherServiceDescription}
                className={`${BLOCK}__text-area`}
                placeholder="If applicable, please provide a description of other services. (Optional)"
                maxLength={2000}
                disabled={disableConfiguration}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Configuration;
