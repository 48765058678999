import api from "../api.service";

export const getCurrentUser = () =>
  api.get(`users/me`).then(({ data }) => data.data);

export const saveUserLogs = () =>
  api.post(`users/logs`).then(({ data }) => data.data);

export const logoutCurrentUser = () =>
  api
    .get(`users/auth/logout`)
    .then(({ data }) => data.data)
    .catch((e) => {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    });
export const getEsgAssets = () =>
  api.get(`/applications`).then(({ data }) => data.data);

export const getAppSetting = (appSettingName: string) =>
  api.get(`/appsettings/${appSettingName}`).then(({ data }) => data.data);
