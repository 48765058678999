import React, { useEffect, useRef, useState } from "react";
import Icon from "components/shared/icon/icon";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import classNames from "classnames";

interface KebabMenuProps {
  onClickCopyToOption: () => void;
  onClickClearContentsOption: () => void;
  isAllValuesNull: boolean;
}

const KebabMenu: React.FC<KebabMenuProps> = ({
  onClickCopyToOption,
  onClickClearContentsOption,
  isAllValuesNull,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const copyToHandleClick = () => {
    if (!isAllValuesNull) {
      onClickCopyToOption();
      setIsOpen(false);
    }
  };

  const clearContentsHandleClick = () => {
    if (!isAllValuesNull) {
      onClickClearContentsOption();
      setIsOpen(false);
    }
  };

  return (
    <div className="kebab-menu" ref={menuRef} data-test="KebabMenue">
      <div className="kebab-icon" onClick={toggleMenu}>
        <Icon name={"Chevron"} width={24} height={24} />
      </div>
      {isOpen && (
        <div className="menu-popup" data-test="kebab-menu-popup">
          <ul
            className={classNames(`menu-popup-ul`, {
              [`menu-popup-ul--disabled`]: isAllValuesNull,
            })}
          >
            <li
              onClick={copyToHandleClick}
              className={classNames(`menu-popup-ul-li`, {
                [`menu-popup-ul-li--disabled`]: isAllValuesNull,
              })}
            >
              <FormattedMessage id={`copy.to.option`} />
            </li>
            <li
              onClick={clearContentsHandleClick}
              className={classNames(`menu-popup-ul-li`, {
                [`menu-popup-ul-li--disabled`]: isAllValuesNull,
              })}
            >
              <FormattedMessage id={`clear.contents.option`} />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default KebabMenu;
