import React from "react";
import data from "./messages.json";

type Props = {
  id: string;
  values?: {
    [key: string]: React.ReactNode;
  };
};

const FormattedMessage = ({ id, values }: Props) => {
  const key = id in data ? id : "default";
  let innerData = (data as any)[key].split(/\{(.*?)\}/i);
  if (values) {
    innerData = innerData.map((part: string, index: number) => {
      if (values.hasOwnProperty(part)) {
        return <React.Fragment key={index}>{values[part]}</React.Fragment>;
      }
      return part;
    });
  }

  return <React.Fragment key={id}>{innerData}</React.Fragment>;
};

export default FormattedMessage;
