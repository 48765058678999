import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { loadTotalTechnicalStaffCost } from "services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { ConsolidationsKeyInitial } from "utils/constants";

type TechnicalStaffCostTotal = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const TechnicalStaffCostTotalTable = ({
  geographies,
  currencyCode,
  baseYear,
}: TechnicalStaffCostTotal) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );

  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        loadTotalTechnicalStaffCost({
          countryCodes: geographies,
          currencyCode,
          baseYear,
        })
      );
    }
  }, [geographies, currencyCode, baseYear]);
  return (
    <div data-test="TechnicalStaffCostTotalTable-component">
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.totalTechnicalStaffCostData
            ?.consolidatedProfitAndLossDetails
        }
        tableHeader={
          consolidationData?.totalTechnicalStaffCostData?.subTotalProfitAndLoss
        }
        mainTableHeader="consolidation.technical.staff.cost"
        subConsolidationTableData={
          consolidationData?.totalTechnicalStaffCostData
            ?.subConsolidatedProfitAndLossDetails
        }
        keyInitial={ConsolidationsKeyInitial.TECHNICAL_STAFF_COST}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return (
              item ===
              VISUAL_DASHBOARD_OPTIONS.TOTAL_TECHNICAL_STAFF_COST_LOADING
            );
          })?.length > 0
        }
      />
    </div>
  );
};

export default TechnicalStaffCostTotalTable;
