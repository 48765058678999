import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
type GrphLegendInfo = {
  BLOCK: string;
};
const RevenueGraphLegends = ({ BLOCK }: GrphLegendInfo) => {
  return (
    <div className={`${BLOCK}__legends`}>
      <div className={`${BLOCK}__legend`}>
        <span className={`${BLOCK}__legend__omniaRADC`}></span>
        <div className={`${BLOCK}__legends--name3`}>
          <FormattedMessage id="graph_legend_omnia" />
        </div>
      </div>
      <div className={`${BLOCK}__legend`}>
        <span className={`${BLOCK}__legend__levvia`}></span>
        <div className={`${BLOCK}__legends--name3`}>
          <FormattedMessage id="graph_legend_levvia" />
        </div>
      </div>
      <div className={`${BLOCK}__legend`}>
        <span className={`${BLOCK}__legend__tier5`}></span>
        <div className={`${BLOCK}__legends--name3`}>
          <FormattedMessage id="graph_legend_tier5" />
        </div>
      </div>
      <div className={`${BLOCK}__legend`}>
        <span className={`${BLOCK}__legend__assurance`}></span>
        <div className={`${BLOCK}__legends--name2`}>
          <FormattedMessage id="graph_legend_assurance" />
        </div>
      </div>
      <div className={`${BLOCK}__legend`}>
        <span className={`${BLOCK}__legend__other`}></span>
        <div className={`${BLOCK}__legends--name`}>
          <FormattedMessage id="graph_legend_other" />
        </div>
      </div>
    </div>
  );
};
export default RevenueGraphLegends;
