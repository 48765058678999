import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { loadTotalHours } from "services/visualDashboard/visualDashBoardSlice";
import {
  ConsolidationsKeyInitial,
  DashBoardCurrencyHeader,
} from "utils/constants";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";

type TotalHours = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const TotalHoursTable = ({
  geographies,
  currencyCode,
  baseYear,
}: TotalHours) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );

  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        loadTotalHours({ countryCodes: geographies, currencyCode, baseYear })
      );
    }
  }, [geographies, currencyCode, baseYear]);
  return (
    <div>
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.totalHoursData?.consolidatedGrossMarginDetails
        }
        tableHeader={consolidationData?.totalHoursData?.subTotalGrossMargin}
        mainTableHeader={`${DashBoardCurrencyHeader.TOTAL_HOURS}`}
        subConsolidationTableData={
          consolidationData?.totalHoursData?.subConsolidatedGrossMarginDetails
        }
        keyInitial={ConsolidationsKeyInitial.TOTAL_HOURS}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return item === VISUAL_DASHBOARD_OPTIONS.TOTAL_HOURS_LOADING;
          })?.length > 0
        }
        isFormattedText={false}
      />
    </div>
  );
};

export default TotalHoursTable;
