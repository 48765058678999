import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { comparisonSelectedListMockStore } from "mocks/store.mock";
import DocumentLink from "../shared/document-link/document-link";
import { useLocation } from "react-router";

const Footer = () => {
  const BLOCK = "footer";
  const location = useLocation();
  let locationName = location.pathname?.split("/").pop();
  const termsOfUse = (
    <DocumentLink
      key="footer-termsOfUse"
      className={`${BLOCK}__link`}
      disable={false}
      documentUrl="https://deloittenet.deloitte.com/system/pages/Use.aspx"
      target="_blank"
      linkBody={<FormattedMessage id="footer.terms-of-use" />}
    />
  );
  const privacy = (
    <DocumentLink
      key="footer-privacy"
      className={`${BLOCK}__link`}
      disable={false}
      documentUrl={`${process.env.PUBLIC_URL}/assets/privacy.pdf`}
      target="_blank"
      linkBody={<FormattedMessage id="footer.privacy" />}
    />
  );
  const Cookies = (
    // need to add cookies Pdf here
    <u>
      <a
        target="_blank"
        className="footer__link"
        href="https://cookienotice.deloitte.com/"
        style={{ cursor: "pointer" }}
      >
        <FormattedMessage id="footer.Cookies" />
      </a>
    </u>
  );

  const openCookiePrivacyCenter = () => {
    console.log("document", document.getElementById("ot-sdk-btn"));
    document.getElementById("ot-sdk-btn")!.click();
  };
  const cookiesSetting = (
    <u>
      <a
        onClick={openCookiePrivacyCenter}
        className="footer__link"
        rel="noopener noreferrer"
        target="_blank"
        style={{ cursor: "pointer" }}
      >
        <FormattedMessage id="footer.cookiesSetting" />
      </a>
    </u>
  );

  const notices = (
    <DocumentLink
      key="footer-notices"
      className={`${BLOCK}__link`}
      disable={false}
      documentUrl={`${process.env.PUBLIC_URL}/assets/notices.pdf`}
      target="_blank"
      linkBody={<FormattedMessage id="footer.notices" />}
    />
  );
  const deloitteUrl = (
    <DocumentLink
      key="footer-deloiteURL"
      className={`${BLOCK}__link`}
      disable={false}
      documentUrl="https://www.deloitte.com/about"
      target="_blank"
      linkBody={<FormattedMessage id="footer.about" />}
    />
  );

  return (
    <div className={`${BLOCK}`}>
      <p>
        <FormattedMessage
          id={
            locationName === "login"
              ? "footer.message.welcome.first"
              : "footer.message.first"
          }
          values={
            locationName === "login"
              ? {
                  date: new Date().getFullYear(),
                  deloitteUrl,
                  privacy,
                  notices,
                }
              : {
                  date: new Date().getFullYear(),
                  deloitteUrl,
                  privacy,
                  notices,
                  Cookies,
                  cookiesSetting,
                }
          }
        />
        <br />

        <FormattedMessage id="footer.message.last" values={{ deloitteUrl }} />
      </p>
    </div>
  );
};

export default Footer;
