import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { loadGrossMargin } from "services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import {
  ConsolidationsKeyInitial,
  gorssMarginDummyTable,
  grossMarginDummyTableHeaders,
} from "utils/constants";

type GrossMargin = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const GrossMarginTable = ({
  geographies,
  currencyCode,
  baseYear,
}: GrossMargin) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );

  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        loadGrossMargin({ countryCodes: geographies, currencyCode, baseYear })
      );
    }
  }, [geographies, currencyCode, baseYear]);

  return (
    <div data-test="grossMargin-component">
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.grossMargin?.consolidatedProfitAndLossDetails
        }
        tableHeader={consolidationData?.grossMargin?.subTotalProfitAndLoss}
        mainTableHeader="consolidation.gross.margin"
        subConsolidationTableData={
          consolidationData?.grossMargin?.subConsolidatedProfitAndLossDetails
        }
        keyInitial={ConsolidationsKeyInitial.GROSS_MARGIN}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return item === VISUAL_DASHBOARD_OPTIONS.GROSS_MARGIN_LOADING;
          })?.length > 0
        }
      />
    </div>
  );
};

export default GrossMarginTable;
