import Modal from "../shared/modal/modal";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useEffect, useState } from "react";
import classNames from "classnames";

type CopyToModalProps = {
  showModal: Function;
  setShowCopyToAttentionPopup: Function;
  data: string[];
  selectedItems: string[];
  setSelectedItems: Function;
};

const CopyToModal = ({
  showModal,
  setShowCopyToAttentionPopup,
  data,
  selectedItems,
  setSelectedItems,
}: CopyToModalProps) => {
  const BLOCK = "copyToModal";
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

  useEffect(() => {
    data?.length == selectedItems?.length
      ? setSelectAllChecked(true)
      : setSelectAllChecked(false);
  }, [selectedItems]);

  // Function to handle individual checkbox selection
  const handleCheckboxChange = (item: string) => {
    if (selectedItems?.includes(item)) {
      setSelectedItems(
        selectedItems?.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  // Function to handle "Select All" checkbox selection
  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      setSelectedItems([]);
    } else {
      setSelectedItems(data);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const handleClose = () => {
    showModal(false);
    setSelectedItems([]);
  };

  const handleConfirm = () => {
    showModal(false);
    setShowCopyToAttentionPopup(true);
  };

  return (
    <Modal
      show={true}
      header={"copy.to.pop.up.header"}
      handleClose={handleClose}
      config={false}
      hideBackgroud={false}
      data-test="copyToModal-popup"
    >
      <div className={BLOCK}>
        <div className={`${BLOCK}__content`}>
          <div className={`${BLOCK}__content--text`}>
            <p>Select Product Segments</p>
            <div className={`${BLOCK}__content--text--selectAll`}>
              <label>
                <input
                  type="checkbox"
                  checked={selectAllChecked}
                  onChange={handleSelectAllChange}
                />
                <span className={`${BLOCK}__content--text--selectAll--label`}>
                  Select All
                </span>
              </label>
            </div>
            <div className={`${BLOCK}__content--text--checkboxes`}>
              {data?.map((item) => (
                <div>
                  <label key={item}>
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                    <span
                      className={`${BLOCK}__content--text--checkboxes--label`}
                    >
                      {item}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className={`${BLOCK}__content--footer`}>
            <div className={`${BLOCK}__content--footer--actions--warning`}>
              <button
                className={`${BLOCK}__content--footer--actions--cancel`}
                onClick={() => handleClose()}
                data-test="closeButton"
              >
                <FormattedMessage id={`input.form.submit.review.cancel`} />
              </button>
              <button
                className={classNames(
                  `${BLOCK}__content--footer--actions--confirm`,
                  {
                    [`${BLOCK}__content--footer--actions--confirm--disabled`]:
                      selectedItems.length === 0,
                  }
                )}
                disabled={selectedItems.length === 0}
                onClick={() => handleConfirm()}
                data-test="continueButton"
              >
                <FormattedMessage id={`input.form.submit.review.confirm`} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CopyToModal;
