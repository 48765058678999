import { useEffect } from "react";
import { homePath } from "../../utils/constants";
import history from "../../utils/history";

const Home = () => {
  useEffect(() => {
    history.push(homePath);
  }, []);

  return <div data-testid="home-container"></div>;
};

export default Home;
