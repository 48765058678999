import { useEffect } from "react";
import { SORT_ORDERS } from "./constants";

export const isNumeric = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const mod = (n: number, m: number) => ((n % m) + m) % m;

export const useClickOutside = (ref: any, fn: any) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) fn();
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export const dollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const sortDashboardGeoList = (geoList: any, sortInfo: any) => {
  let orderedGeoList: any = [];
  if (sortInfo.sortBy === "geo") {
    if (sortInfo.sortOrder === SORT_ORDERS.ASC) {
      //sort geo by asc (a->z);
      orderedGeoList = geoList.slice().sort((a: any, b: any) => {
        let fa = a?.countryName?.toLowerCase(),
          fb = b?.countryName?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    } else {
      //sort by geo desc (z->a)
      orderedGeoList = geoList.slice().sort((a: any, b: any) => {
        let fa = a?.countryName?.toLowerCase(),
          fb = b?.countryName?.toLowerCase();

        if (fa < fb) {
          return 1;
        }
        if (fa > fb) {
          return -1;
        }
        return 0;
      });
    }
  } else if (sortInfo.sortBy === "percentCompletion") {
    if (sortInfo.sortOrder === SORT_ORDERS.ASC) {
      //sort completion by asc
      orderedGeoList = geoList.slice().sort((a: any, b: any) => {
        return a.percentCompletion - b.percentCompletion;
      });
    } else {
      //sort completion by dsc
      orderedGeoList = geoList.slice().sort((a: any, b: any) => {
        return b.percentCompletion - a.percentCompletion;
      });
    }
  } else if (sortInfo.sortBy === "fiscalYear") {
    if (sortInfo.sortOrder === SORT_ORDERS.ASC) {
      //sort fiscal year by asc
      orderedGeoList = geoList.slice().sort((a: any, b: any) => {
        return a.baseYear - b.baseYear;
      });
    } else {
      //sort fiscal year by dsc
      orderedGeoList = geoList.slice().sort((a: any, b: any) => {
        return b.baseYear - a.baseYear;
      });
    }
  } else if (sortInfo.sortBy === "status") {
    if (sortInfo.sortOrder === SORT_ORDERS.ASC) {
      orderedGeoList = geoList.slice().sort((a: any, b: any) => {
        var o1 = a.order ? a.order : 0;
        var o2 = b.order ? b.order : 0;

        var p1 = a?.countryName?.toLowerCase();
        var p2 = b?.countryName?.toLowerCase();

        if (o1 < o2) return -1;
        if (o1 > o2) return 1;
        if (p1 < p2) return -1;
        if (p1 > p2) return 1;
        return 0;
      });
    } else {
      orderedGeoList = geoList.slice().sort((a: any, b: any) => {
        var o1 = a.order ? a.order : 0;
        var o2 = b.order ? b.order : 0;

        var p1 = a?.countryName?.toLowerCase();
        var p2 = b?.countryName?.toLowerCase();

        if (o1 > o2) return -1;
        if (o1 < o2) return 1;
        if (p1 < p2) return -1;
        if (p1 > p2) return 1;
        return 0;
      });
    }
  }

  return orderedGeoList;
};
