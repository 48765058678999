import { productSegmentsMapping, transformationsPath } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { productSegmentOtherId, productSegmentTier5Id } from "utils/constants";
import { useEffect, useState } from "react";
import { ChangeTable } from "services/inputForm/inputFormSlice";
import { useLocation } from "react-router";
type transformationTab = {
  BLOCK: string;
  selectedTab: number;
  setSelectedTab: any;
  changeTabs: Function;
  setDestination: Function;
  setTransformationDirtyTables: Function;
};
const TransformationTab = ({
  BLOCK,
  setSelectedTab,
  selectedTab,
  changeTabs,
  setDestination,
  setTransformationDirtyTables,
}: transformationTab) => {
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const dispatch = useDispatch();
  const location = useLocation();
  let locationName = location.pathname;

  useEffect(() => {
    if (inputFormDataState?.tableNumber) {
      setSelectedTab(
        inputFormDataState?.tableNumber > 10
          ? inputFormDataState?.tableNumber / 100
          : inputFormDataState?.tableNumber
      );
      setTransformationDirtyTables([]);
    }
  }, [inputFormDataState?.tableNumber]);

  const callChangeTab = (val: number) => {
    if (locationName?.includes(`${transformationsPath}/FY`)) {
      let yearPath = locationName?.split("/");
      let baseYearName = yearPath?.pop();
      let dynamicBaseYear = baseYearName?.replace(/\D/g, "");

      let response = changeTabs(
        `${transformationsPath}/FY${dynamicBaseYear}`,
        false,
        -1
      );
      setDestination({
        pathName: `${transformationsPath}/FY${dynamicBaseYear}`,
        index: -1,
      });
      if (response) {
        setSelectedTab(val);
      } else {
        dispatch(ChangeTable(val));
      }
    }
  };
  return (
    <div className={`${BLOCK}__tab`} data-test="transformation-tab-component">
      {Object.keys(productSegmentsMapping)
        .filter((item: any) => {
          if (
            !inputFormDataState?.configurationData?.hasOther &&
            !inputFormDataState?.configurationData?.hasTier5
          ) {
            return (
              item !== productSegmentOtherId && item !== productSegmentTier5Id
            );
          } else if (!inputFormDataState?.configurationData?.hasOther) {
            return item !== productSegmentOtherId;
          } else if (!inputFormDataState?.configurationData?.hasTier5) {
            return item !== productSegmentTier5Id;
          } else {
            return item;
          }
        })
        .map((item: any, index: number) => {
          return (
            <div
              data-test="transformation-tab-change"
              key={`tab-item-${index}`}
              onClick={() => callChangeTab(Number(item))}
              className={`${BLOCK}__tab--item`}
              style={{
                color: Number(item) === selectedTab ? "#86BC25" : "black",
                cursor: "pointer",
                boxShadow:
                  Number(item) === selectedTab
                    ? "inset 0px -3px 0px #86BC25"
                    : "none",
              }}
            >
              {productSegmentsMapping[item]}
            </div>
          );
        })}
    </div>
  );
};

export default TransformationTab;
