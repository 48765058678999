import axios from "axios";
import { planSubmission } from "utils/constants";
import api from "../../services/api.service";

export const getConfiguration = (planSubmissionId: number) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/configurations`)
    .then(({ data }) => {
      return data;
    });
};

export const getCurrency = () => {
  return api.get("currencies").then(({ data }) => data);
};

export const addConfiguration = (payload: any, planSubmissionId: number) => {
  return api.put(`plansubmissions/${planSubmissionId}/configurations`, payload);
};

export const getInputFormStatus = (planSubmissionId: number) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/status`)
    .then(({ data }) => data);
};
export const getInputFormProgress = (planSubmissionId: number) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/fieldcounts`)
    .then(({ data }) => data);
};
export const submitForReview = (planSubmissionId: number) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/submitreview`)
    .then(({ data }) => data);
};

export const completeReview = (planSubmissionId: number) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/completereview`)
    .then(({ data }) => data);
};

export const submitInputForm = (planSubmissionId: number) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/submitform`)
    .then(({ data }) => data);
};

export const updateInputFormStatus = (
  planSubmissionId: number,
  statusId: number
) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/status/${statusId}`)
    .then(({ data }) => data);
};

export const getRevenueAndPLTableData = (id: number) => {
  return api
    .get(`plansubmissions/${id}/yearlyrevenues`)
    .then(({ data }) => data);
};
export const getPandLCostsTableData = (id: number) => {
  return api
    .get(`plansubmissions/${id}/profitandLosscosts`)
    .then(({ data }) => data);
};
export const getWinsNetRevenueTableData = (id: number) => {
  return api
    .get(`plansubmissions/${id}/winsnetrevenues`)
    .then(({ data }) => data);
};
export const getLossNetRevenueTableData = (id: number) => {
  return api
    .get(`plansubmissions/${id}/lossesnetrevenues`)
    .then(({ data }) => data);
};

export const getExitProgrammePhasesTableData = (id: number) => {
  return api
    .get(`plansubmissions/${id}/exitprogrammephases`)
    .then(({ data }) => data);
};
export const getFeePerExitHoursTableData = (id: number) => {
  return api
    .get(`plansubmissions/${id}/feeperexithours`)
    .then(({ data }) => data);
};
export const getExitPhasingTableData = (id: number) => {
  return api.get(`plansubmissions/${id}/exitphasings`).then(({ data }) => data);
};
export const getRevenuePriceIncreaseTableData = (id: number) => {
  return api
    .get(`plansubmissions/${id}/revenuepriceincreases`)
    .then(({ data }) => data);
};

export const getIncreaseInHoursTableData = (id: number) => {
  return api
    .get(`plansubmissions/${id}/IncreaseHoursToDeliverJobs`) //not Yet confirmed
    .then(({ data }) => data);
};
//Revenue and PL save Api's

export const setRevenueAndPLTableData = (id: number, InfoData: any) => {
  return api
    .put(`plansubmissions/${id}/yearlyrevenues`, InfoData)
    .then(({ data }) => data.data);
};
export const setPandLCostsTableData = (id: number, InfoData: any) => {
  return api
    .put(`plansubmissions/${id}/profitandLosscosts`, InfoData)
    .then(({ data }) => data.data);
};

export const setWinsNetRevenueTableData = (id: number, InfoData: any) => {
  return api
    .put(`plansubmissions/${id}/winsnetrevenues`, InfoData)
    .then(({ data }) => data.data);
};
export const setLossNetRevenueTableData = (id: number, InfoData: any) => {
  return api
    .put(`plansubmissions/${id}/lossesnetrevenues`, InfoData)
    .then(({ data }) => data.data);
};

export const setExitProgrammePhasesTableData = (id: number, InfoData: any) => {
  return api
    .put(`plansubmissions/${id}/exitprogrammephases`, InfoData)
    .then(({ data }) => data.data);
};
export const setFeePerExitHoursTableData = (id: number, InfoData: any) => {
  return api
    .put(`plansubmissions/${id}/feeperexithours`, InfoData)
    .then(({ data }) => data);
};
export const setExitPhasingTableData = (id: number, InfoData: any) => {
  return api
    .put(`plansubmissions/${id}/exitphasings`, InfoData)
    .then(({ data }) => data.data);
};
export const setRevenuePriceIncreaseTableData = (id: number, InfoData: any) => {
  return api
    .put(`plansubmissions/${id}/revenuepriceincreases`, InfoData)
    .then(({ data }) => data.data);
};
export const setIncreaseInHoursTableData = (id: number, InfoData: any) => {
  return api
    .put(`plansubmissions/${id}/IncreaseHoursToDeliverJobs`, InfoData) //not Yet confirmed
    .then(({ data }) => data.data);
};

export const getYearlyUtilizationConfig = (planSubmissionId: number) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/yearlyutilizationrateflag`)
    .then(({ data }) => data);
};
export const submitYearlyUtilizationConfig = (
  planSubmissionId: number,
  flag: boolean
) => {
  return api
    .post(
      `plansubmissions/${planSubmissionId}/yearlyutilizationrateflag/${flag}`
    )
    .then(({ data }) => data.data);
};

export const getAvgAnnualFTESalary = (planSubmissionId: number) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/annualavgsalaries`)
    .then(({ data }) => data);
};

export const getYearlyUtilisationRates = (planSubmissionId: number) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/yearlyutilizationrates`)
    .then(({ data }) => data);
};

export const getSalaryPriceIncreases = (planSubmissionId: number) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/salarypriceincreases`)
    .then(({ data }) => data);
};

export const getFY22BasedHeadCounts = (planSubmissionId: number) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/baseheadcounts`)
    .then(({ data }) => data);
};

export const getAnnualTotalHours = (
  planSubmissionId: number,
  deliveryCenterId: number
) => {
  return api
    .get(
      `plansubmissions/${planSubmissionId}/deliverycenters/${deliveryCenterId}/annualtotalhours`
    )
    .then(({ data }) => data);
};

export const getRateCards = (
  planSubmissionId: number,
  deliveryCenterId: number
) => {
  return api
    .get(
      `plansubmissions/${planSubmissionId}/deliverycenters/${deliveryCenterId}/ratecards`
    )
    .then(({ data }) => data);
};

export const getUtilisationrates = (
  planSubmissionId: number,
  deliveryCenterId: number
) => {
  return api
    .get(
      `plansubmissions/${planSubmissionId}/deliverycenters/${deliveryCenterId}/utilizationrates`
    )
    .then(({ data }) => data);
};

export const getPriceIncreaseRates = (
  planSubmissionId: number,
  deliveryCenterId: number
) => {
  return api
    .get(
      `plansubmissions/${planSubmissionId}/deliverycenters/${deliveryCenterId}/priceincreases`
    )
    .then(({ data }) => data);
};

export const submitPriceIncrease = (
  planSubmissionId: number,
  deliveryCenterId: number,
  payload: any
) => {
  return api
    .put(
      `plansubmissions/${planSubmissionId}/deliverycenters/${deliveryCenterId}/priceincreases`,
      payload
    )
    .then(({ data }) => data);
};

export const getRates = (planSubmissionId: number, rate: string) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/${rate}`)
    .then(({ data }) => data);
};

export const getTargetManagedHours = (planSubmissionId: number) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/targetmanagedhours`)
    .then(({ data }) => data);
};

export const setTargetManagedHours = (
  planSubmissionId: number,
  payload: any
) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/targetmanagedhours`, payload)
    .then(({ data }) => data);
};

export const setAvgAnualFTESalary = (
  planSubmissionId: number,
  payload: any
) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/annualavgsalaries`, payload)
    .then(({ data }) => data);
};

export const setSalaryPriceIncreases = (
  planSubmissionId: number,
  payload: any
) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/salarypriceincreases`, payload)
    .then(({ data }) => data);
};

export const setYearlyUtilisationRate = (
  planSubmissionId: number,
  payload: any
) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/yearlyutilizationrates`, payload)
    .then(({ data }) => data);
};

export const setFy22BasedHeadCounts = (
  planSubmissionId: number,
  payload: any
) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/baseheadcounts`, payload)
    .then(({ data }) => data);
};

export const setAnnualTotalHours = (
  planSubmissionId: number,
  deliveryCenterId: any,
  payload: any
) => {
  return api
    .put(
      `plansubmissions/${planSubmissionId}/deliverycenters/${deliveryCenterId}/annualtotalhours`,
      payload
    )
    .then(({ data }) => data);
};

export const setUtilisationRates = (
  planSubmissionId: number,
  deliveryCenterId: any,
  payload: any
) => {
  return api
    .put(
      `plansubmissions/${planSubmissionId}/deliverycenters/${deliveryCenterId}/utilizationrates`,
      payload
    )
    .then(({ data }) => data);
};

export const setRadeCard = (
  planSubmissionId: number,
  deliveryCenterId: any,
  payload: any
) => {
  return api
    .put(
      `plansubmissions/${planSubmissionId}/deliverycenters/${deliveryCenterId}/ratecards`,
      payload
    )
    .then(({ data }) => data);
};

export const submitAttritionrate = (planSubmissionId: number, payload: any) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/attritionrates`, payload)
    .then(({ data }) => data);
};
export const submitRate = (
  planSubmissionId: number,
  payload: any,
  rate: string
) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/${rate}`, payload)
    .then(({ data }) => data);
};

export const getConversionRatiosData = (id: number) => {
  return api
    .get(`plansubmissions/${id}/conversionratios`)
    .then(({ data }) => data.data);
};
export const setConversionRatiosData = (
  planSubmissionId: number,
  payload: any
) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/conversionratios`, payload)
    .then(({ data }) => data);
};
export const getTransformationsData = (
  planSubmissionId: number,
  productSegmentId: number,
  year: number
) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/TransformationAssumptions`, {
      params: { productSegmentId, year },
    })
    .then(({ data }) => data);
};
export const getLiberatedHoursDropDown = (planSubmissionId: number) => {
  return api
    .get(`plansubmissions/${planSubmissionId}/liberatedhours/productSegment`)
    .then(({ data }) => data);
};

export const submitTransformationData = (
  planSubmissionId: number,
  payload: any
) => {
  return api
    .put(
      `plansubmissions/${planSubmissionId}/TransformationAssumptions`,
      payload
    )
    .then(({ data }) => data.data);
};

export const calculateOpeningAndClosingPosition = (
  planSubmissionId: number
) => {
  return api
    .put(`plansubmissions/${planSubmissionId}/CalculateOpeningClosingPositions`)
    .then(({ data }) => data);
};

export const calculateEngineApi = (
  planSubmissionId: any,
  inputChangeType: any
) => {
  return api.post(`plansubmissions/${planSubmissionId}/calculateengine`, {
    PlanSubmissionID: planSubmissionId,
    InputChangeType: inputChangeType,
  });
};

export const costAdoptionCenter = (planSubmissionId: any) => {
  return api.get(
    `plansubmissions/${planSubmissionId}/liberatedhours/costAdoptionAssumptions`
  );
};
export const deploymentAndLiberatedHours = (planSubmissionId: any) => {
  return api.get(
    `plansubmissions/${planSubmissionId}/liberatedhours/liberatedHoursModel`
  );
};

export const submitLiberatedHoursData = (
  payload: any,
  planSubmissionId: any
) => {
  return api.put(
    `plansubmissions/${planSubmissionId}/liberatedhours/costAdoptionAssumptions`,
    payload
  );
};
export const submitLiberatedHoursDeployementData = (
  payload: any,
  planSubmissionId: any
) => {
  return api.put(
    `plansubmissions/${planSubmissionId}/liberatedhours/liberatedHoursModel`,
    payload
  );
};
export const getAnnualInflationTableData = (id: number) => {
  return api
    .get(`plansubmissions/${id}/annualinflation`)
    .then(({ data }) => data);
};
export const setAnnualInflationTableData = (id: number, InfoData: any) => {
  return api
    .post(`plansubmissions/${id}/annualinflation`, InfoData)
    .then(({ data }) => data.data);
};
