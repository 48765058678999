import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { getHeadCountByCohortsUsiData } from "services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { ConsolidationsKeyInitial } from "utils/constants";

type HeadCountCohort = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const UsiHeadCountCohortTable = ({
  geographies,
  currencyCode,
  baseYear,
}: HeadCountCohort) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );

  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        getHeadCountByCohortsUsiData({
          countryCodes: geographies,
          currencyCode,
          baseYear,
        })
      );
    }
  }, [geographies, currencyCode, baseYear]);

  return (
    <>
      <ConsolidationsCommonTable
        tableData={consolidationData?.usiCohort?.consolidatedHeadCountDetails}
        tableHeader={consolidationData?.usiCohort?.subTotalHeadCount}
        mainTableHeader="consolidation.headcount"
        subConsolidationTableData={
          consolidationData?.usiCohort?.subConsolidatedHeadCountDetails
        }
        keyInitial={ConsolidationsKeyInitial.USI_HEADCOUNT_COHORT}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return item === VISUAL_DASHBOARD_OPTIONS.USI_COHORT_LOADING;
          })?.length > 0
        }
      />
    </>
  );
};

export default UsiHeadCountCohortTable;
