import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "./geo.api";
import { FILTER_OPTIONS } from "utils/constants";

const initialState = {
  loaded: false,
  showFilters: true,
  loadingFilter: [],
  geoFilter: [],
  geoFilterOptions: [],
  reviewerFilter: [],
  reviewerFilterOptions: [],
  preparerFilter: [],
  preparerFilterOptions: [],
  memberFirmAdminFilter: [],
  memberFirmAdminFilterOptions: [],
};

const geoSlice = createSlice({
  name: "geography",
  initialState,
  reducers: {
    setLoaded(state: any) {
      state.loaded = true;
    },
    setShowFilters(state: any, action) {
      state.showFilters = action.payload;
    },
    setGeoFilter(state: any, action) {
      state.geoFilter = action.payload;
    },
    setReviewerFilter(state: any, action) {
      state.reviewerFilter = action.payload;
    },
    setPreparerFilter(state: any, action) {
      state.preparerFilter = action.payload;
    },
    setMemberFirmAdmin(state: any, action) {
      state.memberFirmAdminFilter = action.payload;
    },
    searchLoadPreparerGeoFilter(state: any, action) {
      state.preparerFilterOptions = action.payload;
    },
    searchLoadReviewerilter(state: any, action) {
      state.reviewerFilterOptions = action.payload;
    },
    searchMemberFirmAdmin(state: any, action) {
      state.memberFirmAdminFilterOptions = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadGeoFilter.pending, (state: any, action: any) => {
        state.loadingFilter.push(FILTER_OPTIONS.GEOLIST);
      })
      .addCase(loadGeoFilter.fulfilled, (state: any, action: any) => {
        state.loadingFilter = removeLoaders(state, FILTER_OPTIONS.GEOLIST);
        state.geoFilterOptions = action.payload;
      })
      .addCase(loadReviewerFilter.pending, (state: any, action: any) => {
        state.loadingFilter.push(FILTER_OPTIONS.REVIEWER);
      })
      .addCase(loadReviewerFilter.fulfilled, (state: any, action: any) => {
        state.loadingFilter = removeLoaders(state, FILTER_OPTIONS.REVIEWER);
        state.reviewerFilterOptions = action.payload;
      })
      .addCase(loadPreparerFilter.pending, (state: any, action: any) => {
        state.loadingFilter.push(FILTER_OPTIONS.PREPARER);
      })
      .addCase(loadPreparerFilter.fulfilled, (state: any, action: any) => {
        state.loadingFilter = removeLoaders(state, FILTER_OPTIONS.PREPARER);
        state.preparerFilterOptions = action.payload;
      })
      .addCase(loadMemberFirmAdmin.pending, (state: any, action: any) => {
        state.loadingFilter.push(FILTER_OPTIONS.MEMBERFIRMADMIN);
      })
      .addCase(loadMemberFirmAdmin.fulfilled, (state: any, action: any) => {
        state.loadingFilter = removeLoaders(
          state,
          FILTER_OPTIONS.MEMBERFIRMADMIN
        );
        state.memberFirmAdminFilterOptions = action.payload;
      });
  },
});

const removeLoaders = (state: any, loader: any) => {
  return state.loadingFilter.filter((filter: any) => {
    return filter !== loader;
  });
};

export const loadGeoFilter = createAsyncThunk(
  "dashboard/fetchAddGeoFilterData",
  async (_, { getState }: any) => {
    try {
      const res = await api.fetchGeo();
      if (getState()?.dashboard?.geographyList?.length > 0) {
        const data = res.data.filter(
          (x: any) =>
            !getState()?.dashboard?.geographyList.some(
              (y: any) => y.countryName === x.countryName
            )
        );
        return data;
      } else {
        return res.data;
      }
    } catch (e) {
      console.log("Error getting dashboard data");
      console.log(e);
    }
  }
);
export const loadReviewerFilter = createAsyncThunk(
  "dashboard/fetchReviewerFilterData",
  async (data?: any) => {
    const { countryCode, payload } = data;
    try {
      if (payload.ContentText) {
        const res = await api.fetchMFAPreparerReviewer(countryCode, payload);
        return res.data;
      } else {
        return [];
      }
    } catch (error) {}
  }
);
export const loadPreparerFilter = createAsyncThunk(
  "dashboard/fetchPreparerFilterData",
  async (data?: any) => {
    const { countryCode, payload } = data;
    try {
      if (payload.ContentText) {
        const res = await api.fetchMFAPreparerReviewer(countryCode, payload);
        return res.data;
      } else {
        return [];
      }
    } catch (error) {}
  }
);
export const loadMemberFirmAdmin = createAsyncThunk(
  "dashboard/fetchMemberFirmAdminFilterData",
  async (data?: any) => {
    const { countryCode, payload } = data;
    try {
      if (payload.ContentText) {
        const res = await api.fetchMFAPreparerReviewer(countryCode, payload);
        return res.data;
      } else {
        return [];
      }
    } catch (error) {}
  }
);

export const addGeo = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .addGeo(payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const setExistingMemberFirmAdmin = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .setExistingMemberFirmAdmin(payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateGeoData = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .updateGeo(payload)
      .then(({ data }) => {
        console.log("edit response", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const {
  setLoaded,
  setShowFilters,
  setGeoFilter,
  setReviewerFilter,
  setPreparerFilter,
  setMemberFirmAdmin,
  searchLoadPreparerGeoFilter,
  searchLoadReviewerilter,
  searchMemberFirmAdmin,
} = geoSlice.actions;

export default geoSlice.reducer;
