import { useEffect, useState } from "react";
import CommonAssumptionsSubItems from "../transformations/commonAssumptionsSubItems";
import { GENERAL_INPUT_ERROR, productSegments } from "utils/constants";
import { RootStore } from "services/store.service";
import { useDispatch, useSelector } from "react-redux";
import {
  CALCULATE_ENGINE,
  liberatedHours,
  deployementTableReference,
} from "utils/constants";
import {
  getStatusId,
  calculateOpeningAndClosingPositionAPI,
  submitTransformationAssumptions,
  submitLiberatedHoursTableData,
  submitDeployementAndLiberatedHoursTableData,
  setInputFormProgressDataLocal,
  getProgressPercentage,
} from "services/inputForm/inputFormSlice";
import UpdateInputsModal from "../inputform/updateMultipleInputsModal";
import { useAPI as transformationAPI } from "../updatedTransformationAssumptions/transformationAssumptionContextProvider";
import {
  useAPI as liberatedHoursAPI,
  liberateHoursCostAssumptionSectionWiseTableData,
} from "../liberatedHours/liberatedHoursContextProvider";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { addToastMessage } from "services/common/commonsSlice";
import { useParams } from "react-router-dom";
import { cleanTableData } from "components/shared/commons/globalFunctions";

type transformationSubItems = {
  toggleNavigation: boolean;
  planSubmissionId: number;
  changeTabs: Function;
  setDestination: Function;
  setSaved: any;
  saved: boolean;
  savingData: boolean;
  setSavingData: Function;
  transformationAssumptionsApiCall: boolean;
  setTransformationAssumptionsApiCall: Function;
  setTotalFilledTransformation: any;
  setTotalFieldsTransformation: any;
  setTransformationPercent: any;
  transformationPercent: any;
  totalPercentage: number;
  transformationDirtyTables: any;
  setTransformationDirtyTables: any;
  liberatedHoursApiCall: any;
  setLiberatedHoursApiCall: any;
  liberatedHoursDirtyTables: any;
  setLiberatedHoursDirtyTables: any;
  setStepSelectedTransformation: any;
};

const Transformations = ({
  toggleNavigation,
  planSubmissionId,
  changeTabs,
  setDestination,
  saved,
  setSaved,
  savingData,
  setSavingData,
  transformationAssumptionsApiCall,
  setTransformationAssumptionsApiCall,
  setTotalFilledTransformation,
  setTotalFieldsTransformation,
  setTransformationPercent,
  transformationPercent,
  totalPercentage,
  transformationDirtyTables,
  setTransformationDirtyTables,
  liberatedHoursApiCall,
  setLiberatedHoursApiCall,
  liberatedHoursDirtyTables,
  setLiberatedHoursDirtyTables,
  setStepSelectedTransformation,
}: transformationSubItems) => {
  const [showUpdateInputsPopup, setShowUpdateInputsPopup] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const {
    transformationAssumptionsTableData,
    setTransformationAssumptionsTableDataCompare,
    setMultipleErrors,
    multipleErrors,
    initialCompletedFields,
    setInitialCompletedFields,
  }: any = transformationAPI();
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [year, setYear] = useState<any>(null);
  const params: any = useParams();

  const {
    costAssumptionCenterData,
    setCompareTableData,
    deployementAndLiberatedHoursData,
  }: any = liberatedHoursAPI();

  useEffect(() => {
    if (multipleErrors) {
      dispatch(
        addToastMessage({
          description: <>{GENERAL_INPUT_ERROR}</>,
        })
      );
      setMultipleErrors(false);
    }
  }, [multipleErrors]);

  useEffect(() => {
    setYear(params?.subsection?.match(/\d+/)?.[0]);
    setStepSelectedTransformation(params?.subsection);
  }, [params]);

  useEffect(() => {
    let inputFormProgressData =
      inputFormDataState?.InputFormProgressData?.fieldCountDetails;

    let costAssumptionCompletedFields = 0;
    let deployementCompletedFields = 0;
    let convertionRatioCompletedFields = 0;
    let transformationCompletedFields = 0;

    if (Object.keys(costAssumptionCenterData || {}).length > 0) {
      Object.keys(costAssumptionCenterData || {})?.map((item: any) => {
        costAssumptionCenterData[item]?.map((data: any) => {
          data?.leverId !== deployementTableReference[0].leverId - 1 &&
            data?.value !== null &&
            costAssumptionCompletedFields++;
        });
      });
    }

    if (Object.keys(deployementAndLiberatedHoursData || {}).length > 0) {
      Object.keys(deployementAndLiberatedHoursData || {})?.map((item: any) => {
        deployementAndLiberatedHoursData[item]?.map((data: any) => {
          data?.leverId === deployementTableReference[0].leverId &&
            data?.value !== null &&
            deployementCompletedFields++;
        });
      });
    }

    const conversionData: any =
      inputFormDataState?.conversionRatiosGetData?.length > 0
        ? inputFormDataState?.conversionRatiosGetData
        : null;
    conversionData?.map((item: any) => {
      Object.keys(item || {})
        ?.filter(Number)
        ?.map((row: any) => {
          if (item[row] !== null) convertionRatioCompletedFields++;
        });
    });

    transformationAssumptionsTableData[`${year}-${selectedTab}`]?.map(
      (item: any) => {
        Object.keys(item || {})
          ?.filter(Number)
          ?.map((num: any) => {
            !item?.disabled[num] &&
              item[num] !== null &&
              transformationCompletedFields++;
          });
      }
    );

    let updatedData: any = [];

    if (inputFormProgressData?.length) {
      updatedData = inputFormProgressData?.map((item: any) => {
        switch (item.inputFormMasterTableId) {
          case 31:
            return costAssumptionCenterData &&
              Object.keys(costAssumptionCenterData || {})?.length > 0
              ? {
                  ...item,
                  completedFields: costAssumptionCompletedFields,
                }
              : item;
          case 32:
            return deployementAndLiberatedHoursData &&
              Object.keys(deployementAndLiberatedHoursData || {})?.length > 0
              ? {
                  ...item,
                  completedFields: deployementCompletedFields,
                }
              : item;
          case 23:
            return conversionData &&
              Object.keys(conversionData || {})?.length > 0
              ? {
                  ...item,
                  completedFields: convertionRatioCompletedFields,
                }
              : item;
          case 22:
            return !saved &&
              transformationAssumptionsTableData[`${year}-${selectedTab}`] &&
              transformationAssumptionsTableData[`${year}-${selectedTab}`]
                ?.length > 0
              ? {
                  ...item,
                  completedFields:
                    item.completedFields -
                    initialCompletedFields +
                    transformationCompletedFields,
                }
              : item;

          default:
            return item;
        }
      });
    }

    let dataObj = {
      fieldCountDetails: updatedData,
      percentCompletion:
        inputFormDataState?.InputFormProgressData?.percentCompletion,
      planSubmissionId:
        inputFormDataState?.InputFormProgressData?.planSubmissionId,
    };
    dispatch(setInputFormProgressDataLocal(dataObj));
  }, [
    saved,
    inputFormDataState?.InputFormProgressData,
    inputFormDataState.conversionRatiosGetData,
    costAssumptionCenterData,
    deployementAndLiberatedHoursData,
    transformationAssumptionsTableData,
    initialCompletedFields,
    year,
    selectedTab,
  ]);

  useEffect(() => {
    if (savingData && transformationAssumptionsApiCall) {
      let promiseArray: any = [];
      transformationDirtyTables.map((item: any) => {
        promiseArray.push(
          submitTransformationAssumptions(
            cleanTableData(
              transformationAssumptionsTableData[`${year}-${item}`]
            ),
            planSubmissionId
          )
        );

        let transformationCompletedFields = 0;
        transformationAssumptionsTableData[`${year}-${selectedTab}`]?.map(
          (item: any) => {
            Object.keys(item || {})
              ?.filter(Number)
              ?.map((num: any) => {
                !item?.disabled[num] &&
                  item[num] !== null &&
                  transformationCompletedFields++;
              });
          }
        );

        setInitialCompletedFields(transformationCompletedFields);

        setTransformationAssumptionsTableDataCompare((prevVal: any) => {
          return {
            ...prevVal,
            [`${year}-${item}`]: cleanTableData(
              transformationAssumptionsTableData[`${year}-${item}`]
            ),
          };
        });
      });

      Promise.all([...promiseArray])
        .then(() => {
          setSavingData(false);
          setTransformationAssumptionsApiCall(false);
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="form.save.toaster.message" />,
            })
          );
          if (promiseArray.length > 0) {
            totalPercentage &&
              totalPercentage === 100 &&
              dispatch(getStatusId(planSubmissionId));

            let engineObject = {
              tableProgress: transformationPercent == 100,
              inputFormSection: CALCULATE_ENGINE.TRANSFORMATION_ASSUMPTIONS,
            };
            dispatch(getProgressPercentage(planSubmissionId));
            dispatch(
              calculateOpeningAndClosingPositionAPI(
                planSubmissionId,
                false,
                engineObject
              )
            );
          }
          setTransformationDirtyTables([]);
        })
        .catch(() => {
          setSavingData(false);
          setTransformationAssumptionsApiCall(false);
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="form.error.toaster.message" />,
            })
          );
        });
    }
    if (savingData && liberatedHoursApiCall) {
      let promiseArray: any = [];
      let costBenifitData: any = [];
      let deployementAndLiberatedHoursTableData: any = [];

      Object.keys(costAssumptionCenterData || {}).map((item: any) => {
        costBenifitData = [
          ...costBenifitData,
          ...costAssumptionCenterData[item],
        ];
      });
      Object.keys(deployementAndLiberatedHoursData || {}).map((item: any) => {
        deployementAndLiberatedHoursTableData = [
          ...deployementAndLiberatedHoursTableData,
          ...deployementAndLiberatedHoursData[item],
        ];
      });

      const costAssumptionAPIcall = () => {
        promiseArray.push(
          submitLiberatedHoursTableData(
            [...cleanTableData(costBenifitData)],
            planSubmissionId
          )
        );
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          costAssumptionCenterData:
            liberateHoursCostAssumptionSectionWiseTableData(
              cleanTableData(costBenifitData)
            ),
        }));
      };
      const liberatedHoursModelAPIcall = () => {
        promiseArray.push(
          submitDeployementAndLiberatedHoursTableData(
            [...cleanTableData(deployementAndLiberatedHoursTableData)],
            planSubmissionId
          )
        );
        setCompareTableData((prevVal: any) => ({
          ...prevVal,
          deployementAndLiberatedHoursData:
            liberateHoursCostAssumptionSectionWiseTableData(
              cleanTableData(deployementAndLiberatedHoursTableData)
            ),
        }));
      };

      liberatedHoursDirtyTables.map((item: any) => {
        if (item == 1) {
          costAssumptionAPIcall();
          liberatedHoursModelAPIcall();
        } else if (item == 2) {
          liberatedHoursModelAPIcall();
        }
      });

      Promise.all([...promiseArray])
        .then(() => {
          setSavingData(false);
          setLiberatedHoursApiCall(false);
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="form.save.toaster.message" />,
            })
          );
          promiseArray.length > 0 &&
            dispatch(getProgressPercentage(planSubmissionId));
          setLiberatedHoursDirtyTables([]);
        })
        .catch(() => {
          setSavingData(false);
          setLiberatedHoursApiCall(false);
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="form.error.toaster.message" />,
            })
          );
        });
    }
  }, [savingData, transformationAssumptionsApiCall, liberatedHoursApiCall]);

  return (
    <div
      style={{ width: !toggleNavigation ? "100%" : "75%" }}
      data-test="transformation-component"
    >
      <CommonAssumptionsSubItems
        toggleNavigation={toggleNavigation}
        planSubmissionId={planSubmissionId}
        changeTabs={changeTabs}
        setDestination={setDestination}
        setSaved={setSaved}
        transformationDirtyTables={transformationDirtyTables}
        setTransformationDirtyTables={setTransformationDirtyTables}
        showModal={setShowUpdateInputsPopup}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        showUpdateInputsPopup={showUpdateInputsPopup}
        liberatedHoursDirtyTables={liberatedHoursDirtyTables}
        setLiberatedHoursDirtyTables={setLiberatedHoursDirtyTables}
      />
      {showUpdateInputsPopup && inputFormDataState.selectedCells.length > 0 && (
        <UpdateInputsModal
          setSaved={setSaved}
          showModal={setShowUpdateInputsPopup}
          selectedCells={inputFormDataState.selectedCells}
          selectedTab={selectedTab}
          dirtyTables={
            params?.subsection == liberatedHours
              ? liberatedHoursDirtyTables
              : transformationDirtyTables
          }
          setDirtyTables={
            params?.subsection == liberatedHours
              ? setLiberatedHoursDirtyTables
              : setTransformationDirtyTables
          }
        />
      )}
    </div>
  );
};

export default Transformations;
