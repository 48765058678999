import { scaleLinear } from "d3-scale";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
  Text,
  ResponsiveContainer,
} from "recharts";
import { ConsolidatedNetRvenueByYearLabels } from "../../utils/constants";

const customLabelList = (props: any) => {
  const { x, y, width, value } = props;
  const radius = 20;
  return (
    <Text
      className="visual_graph__LabelList"
      x={x + width / 2}
      y={value < 0 ? y + radius : y - radius}
      angle={-50}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {value === 0 || isNaN(value)
        ? "__"
        : new Intl.NumberFormat("en-EN", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }).format(value)}
    </Text>
  );
};
const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text className="visual_graph__Label" x={0} y={0} textAnchor="middle">
        {payload?.value?.toLocaleString("us-EN", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}
      </text>
    </g>
  );
};
const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        className="visual_graph__Label1"
        x={0}
        y={0}
        dy={10}
        textAnchor="middle"
      >
        {payload?.value}
      </text>
    </g>
  );
};

type VerticalBarChartProps = {
  data: any;
  BLOCK: string;
  ConsolidatioRevenue: boolean;
  loopYears?: any;
};

const VerticalBarChart = ({
  data,
  BLOCK,
  ConsolidatioRevenue,
  loopYears,
}: VerticalBarChartProps) => {
  return (
    <>
      {data && Object.keys(data).length && (
        <ResponsiveContainer
          width={loopYears > 4 ? "140%" : "100%"}
          height="75%"
          data-test="veticalBarChart"
        >
          <BarChart
            data={data}
            margin={{
              top: 35,
              left: -5,
            }}
          >
            <CartesianGrid
              strokeDasharray="1 10"
              vertical={false}
              horizontalCoordinatesGenerator={(args) => {
                let hPoints: any = [];
                const totalLines = Math.ceil(args.offset.height / 50);
                const hScale = scaleLinear()
                  .range([args.offset.top, args.height - args.offset.bottom])
                  .domain([0, totalLines]);

                for (let i = 0; i <= totalLines; i++) {
                  hPoints = [...hPoints, hScale(i)];
                }
                return hPoints;
              }}
            />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tickMargin={25}
              height={50}
              tick={CustomizedXAxisTick}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              height={20}
              tick={<CustomizedYAxisTick />}
            />
            <Tooltip
              cursor={false}
              formatter={(value: any) =>
                value === 0 || isNaN(value)
                  ? "__"
                  : new Intl.NumberFormat("en-EN", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }).format(value)
              }
            />
            {ConsolidatioRevenue ? (
              <>
                <Bar
                  dataKey={ConsolidatedNetRvenueByYearLabels[0]}
                  fill="#43B02A"
                  isAnimationActive={false}
                >
                  <LabelList
                    content={customLabelList}
                    position="top"
                    dataKey={ConsolidatedNetRvenueByYearLabels[0]}
                  />
                </Bar>
                <Bar
                  dataKey={ConsolidatedNetRvenueByYearLabels[1]}
                  fill="#2A5599"
                  isAnimationActive={false}
                >
                  <LabelList
                    content={customLabelList}
                    position="top"
                    dataKey={ConsolidatedNetRvenueByYearLabels[1]}
                  />
                </Bar>
                <Bar
                  dataKey={ConsolidatedNetRvenueByYearLabels[2]}
                  fill="#A0DCFF"
                  isAnimationActive={false}
                >
                  <LabelList
                    content={customLabelList}
                    position="top"
                    dataKey={ConsolidatedNetRvenueByYearLabels[2]}
                  />
                </Bar>
                <Bar
                  dataKey={ConsolidatedNetRvenueByYearLabels[3]}
                  fill="#0097A9"
                  isAnimationActive={false}
                >
                  <LabelList
                    content={customLabelList}
                    position="top"
                    dataKey={ConsolidatedNetRvenueByYearLabels[3]}
                  />
                </Bar>
                <Bar
                  dataKey={ConsolidatedNetRvenueByYearLabels[4]}
                  fill="#6FC2B4"
                  isAnimationActive={false}
                >
                  <LabelList
                    content={customLabelList}
                    position="top"
                    dataKey={ConsolidatedNetRvenueByYearLabels[4]}
                  />
                </Bar>
              </>
            ) : (
              <>
                <Bar dataKey="Fee" fill="#00ABAB" isAnimationActive={false}>
                  <LabelList
                    content={customLabelList}
                    position="top"
                    dataKey="Fee"
                  />
                </Bar>
                <Bar dataKey="Cost" fill="#3984B6" isAnimationActive={false}>
                  <LabelList
                    content={customLabelList}
                    position="top"
                    dataKey="Cost"
                  />
                </Bar>
              </>
            )}
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
export default VerticalBarChart;
