import axios from "axios";
import { planSubmission } from "utils/constants";
import api from "../api.service";

export const getDashboardTableList = (fiscalYear: number | null) => {
  return api
    .get(`plansubmissions?fiscalYear=${fiscalYear}`)
    .then(({ data }) => data);
};

export const getFiscalYearList = () => {
  return api.get(`plansubmissions/fiscalYears`).then(({ data }) => data);
};
export const getRollForward = () => {
  return api.get(`/plansubmissions/rollforwards`).then(({ data }) => data);
};
export const setRollForwardProcess = (fiscalYear: number, config: any) => {
  return api.post(`/plansubmissions/rollforwards`, { fiscalYear }, config);
};

export const getConfiguredYear = (baseYear: number | null) => {
  return api
    .get(`/plansubmissions/configurableFY?baseYear=${baseYear}`)
    .then(({ data }) => data);
};

export const setConfiguredYear = (payload: any) => {
  return api.post(`/plansubmissions/configurableFY`, payload);
};

export const uploadFile = (
  planSubmissionId: number | null,
  payload: any,
  config: any
) => {
  return api.post(
    `plansubmissions/${planSubmissionId}/imports`,
    payload,
    config
  );
};
