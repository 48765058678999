import { RootStore } from "services/store.service";
import { useState, useEffect, useRef, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../shared/table/table";
import { CONVERSION_RATIOS_ERROR, conversionRatios } from "utils/constants";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "../shared/icon/icon";
import {
  validateInputValueLengthPercentage,
  setConversionRatiosGetTableData,
  validateConvertionRatiosTableInputs,
  getConversionRatiosTableData,
} from "../../services/inputForm/inputFormSlice";
import { addToastMessage } from "services/common/commonsSlice";
import { useAreaSelection } from "utils/area-selection";
import { inputValueValidation } from "utils/useInputForm";

const SelectionContext = createContext<DOMRect | null>(null);

const ConversionRatios = ({
  setSaved,
  BLOCK,
  planSubmissionId,
  tableId,
  showModal,
  showUpdateInputsPopup,
}: any) => {
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const [state, setState] = useState<any>();
  const [prevValue, setPrevValue] = useState<any>("");
  const dispatch = useDispatch();
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });
  useEffect(() => {
    dispatch(getConversionRatiosTableData(planSubmissionId));

    return () => {
      dispatch(setConversionRatiosGetTableData([]));
    };
  }, []);

  useEffect(() => {
    if (inputFormDataState?.conversionRatiosGetData?.length) {
      setState(inputFormDataState?.conversionRatiosGetData);
    }
  }, [inputFormDataState.conversionRatiosGetData]);

  const getTableHeaders = () => {
    let dynamicTableHeaders: any =
      state &&
      state?.length > 0 &&
      Object.keys(state[0] || {})
        .filter(Number)
        .map((item) => {
          return {
            text: `FY${item}`,
            isFormattedText: false,
          };
        });

    const tableHeaders = dynamicTableHeaders &&
      dynamicTableHeaders.length > 0 && [
        {
          text: "conversion-rations-table-delivery-centre",
          isFormattedText: true,
        },
        ...dynamicTableHeaders,
      ];

    return tableHeaders;
  };

  const InputChangeHandler = (
    inputVal: any,
    deliveryCenterId: number,
    columnId: any,
    roundOff: boolean
  ) => {
    if (parseInt(inputVal) < 0) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="neg.values.not.allowed" />,
        })
      );
      return;
    }
    setPrevValue(inputVal);
    let newVal = inputValueValidation(inputVal, prevValue, setPrevValue, true);
    let value: any;
    if (roundOff) {
      value = validateInputValueLengthPercentage(newVal, prevValue, roundOff);
    } else {
      value = validateInputValueLengthPercentage(newVal, prevValue);
    }

    let newTableValues =
      inputFormDataState.conversionRatiosGetData.length > 0 &&
      inputFormDataState?.conversionRatiosGetData?.map((item: any) => {
        if (item.deliveryCenterId === deliveryCenterId) {
          return {
            ...item,
            [columnId]: value,
          };
        }
        return item;
      });
    dispatch(setConversionRatiosGetTableData(newTableValues));
    if (roundOff)
      dispatch(validateConvertionRatiosTableInputs(newTableValues, columnId));
  };

  const FocusChage = (inputVal: any, deliveryCenterId: number, id: any) => {
    setSaved(false);
    InputChangeHandler(inputVal, deliveryCenterId, id, true);
  };

  const handleEnter = (evt: any, keyIdentifier: any) => {
    if (evt.key === "Enter") {
      let localKeyIdentifier: any = [];
      let tableData = inputFormDataState?.conversionRatiosGetData;

      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any, index: number) => {
            localKeyIdentifier.push(
              `${tableId}#${item.deliveryCenterId}#${id}`
            );
          });
      });
      localKeyIdentifier.sort((a: any, b: any) => {
        return a.split("#")[2] - b.split("#")[2];
      });

      let index = localKeyIdentifier.findIndex(
        (item: any) => item === keyIdentifier
      );
      let element;
      if (index > -1) {
        element = document.getElementById(localKeyIdentifier[index + 1]);
      }

      if (!element) {
        document
          .getElementById(
            `${tableId}#1#${Object.keys(tableData[0] || {}).filter(Number)[0]}`
          )
          ?.focus();
      } else {
        element?.focus();
      }
    }
  };

  const getRowItems = () => {
    let tableData =
      state &&
      Object.keys(state).length &&
      state?.map((item: any, index: number) => {
        let dynamicRows = Object.keys(item || {})
          .filter(Number)
          .map((id) => {
            if (id) {
              return {
                customIndex: id,
                textInput: true,
                onBlur: (e: any) => {
                  FocusChage(e.target.value, item.deliveryCenterId, id);
                },
                onchangeHandler: (e: any) => {
                  let columnId = id;
                  InputChangeHandler(
                    e.target.value,
                    item.deliveryCenterId,
                    columnId,
                    false
                  );
                },
                onHandleEnter: (evt: any, keyIdentifier: any) => {
                  setPrevValue(evt?.target?.value);
                  handleEnter(evt, keyIdentifier);
                },
                error: item["error" + id],
                errorText: CONVERSION_RATIOS_ERROR,
                errorToastNotRequired: item?.errorToastNotRequired
                  ? true
                  : false,
                placholder: "-",
                inputValue: item[id],
              };
            }
          });

        let tableRows = {
          id: item.deliveryCenterId,
          key: `${item.deliveryCenterId}-${index}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: conversionRatios[item.deliveryCenterId],
            },
            ...dynamicRows,
          ],
        };
        return tableRows;
      });
    return tableData;
  };

  return (
    <div data-test="conversion-ratio-component">
      <SelectionContext.Provider value={selection}>
        <div ref={selectContainerRef}>
          {inputFormDataState.conversionRatiosLoading ? (
            <div className={`revenueTable__spinner-container`}>
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className={`revenueTable--loading`}
              />
            </div>
          ) : (
            <div>
              <div>
                <Table
                  headerItems={getTableHeaders()}
                  rowItems={getRowItems()}
                  innerClassName={"revenueTable"}
                  selection={selection}
                  tableId={tableId}
                />
              </div>
            </div>
          )}
        </div>
      </SelectionContext.Provider>
    </div>
  );
};

export default ConversionRatios;
