import DownloadsHeader from "./downloads-header";
import DownloadItem from "./download-item";
import { useState, useEffect } from "react";
import socket, { rooms } from "utils/socket";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { DOWNLOAD_EXPORT_TYPES, EXPORT_TYPE_MAPPING } from "utils/constants";
import { addToastMessage } from "services/common/commonsSlice";
import { updateDownloadState } from "services/download/downloadSlice";
import classNames from "classnames";

type Props = {
  className?: string;
};

const Downloads = ({ className }: Props) => {
  const BLOCK = "Downloads";
  const dispatch = useDispatch();
  const downloadState = useSelector((state: RootStore) => state.download);
  const commonsState = useSelector((state: RootStore) => state.commons);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [exportModelSuccess, setExportModelSuccess] = useState<boolean>(false);

  /*istanbul ignore next*/
  useEffect(() => {
    if (commonsState.currentUser.userId) {
      socket.on("export.update", updateDownloads);
      socket.join(rooms.exports(commonsState.currentUser.userId));

      return () => {
        socket.leave(rooms.exports(commonsState.currentUser.userId));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonsState.currentUser.userId]);

  useEffect(() => {
    if (exportModelSuccess && downloadState.downloads) {
      const newDocReadyForDownload = downloadState.downloads[0];
      if (
        newDocReadyForDownload.exportType === EXPORT_TYPE_MAPPING.ENGINE_EXPORT
      ) {
        const geoName = newDocReadyForDownload.exportFileName.split("_Model");
        dispatch(
          addToastMessage({
            description: (
              <FormattedMessage
                id="downloads.engine.model.sucess"
                values={{ geoName: geoName[0] }}
              />
            ),
          })
        );
      } else if (
        newDocReadyForDownload.exportType ===
        EXPORT_TYPE_MAPPING.CONSOLIDATION_DASHBOARD
      ) {
        dispatch(
          addToastMessage({
            description: (
              <FormattedMessage id="consolidation.success.download" />
            ),
          })
        );
      } else {
        dispatch(
          addToastMessage({
            description: (
              <FormattedMessage id="downloads.toaster.progress.success" />
            ),
          })
        );
      }
      setExportModelSuccess(false);
    }
  }, [downloadState.downloads, exportModelSuccess]);

  /*istanbul ignore next*/
  const updateDownloads = (payload: any) => {
    const exportState = payload.data.exportState;

    if (exportState === DOWNLOAD_EXPORT_TYPES.READY_TO_DOWNLOAD) {
      setExportModelSuccess(true);
    } else if (exportState === DOWNLOAD_EXPORT_TYPES.ERROR) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="downloads.toaster.progress.error" />
          ),
        })
      );
    }
    dispatch(updateDownloadState(payload.data));
  };

  return downloadState.showDownloads ? (
    <div
      className={classNames(`${BLOCK}`, className)}
      data-test="component-downloads"
    >
      <div className={`${BLOCK}__header`} data-test="downloads-header">
        <DownloadsHeader BLOCK={`${BLOCK}__header`} />
      </div>
      <div className={`${BLOCK}__body`} data-test="downloads-body">
        {downloadState.downloads?.map((item: any) => (
          <DownloadItem
            key={item.exportDetailId}
            BLOCK={`${BLOCK}__body`}
            item={item}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default Downloads;
