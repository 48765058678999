import {
  useState,
  useEffect,
  useRef,
  createContext,
  useCallback,
  memo,
} from "react";
import Table, { TableRowItem } from "../shared/table/table";
import { REVENUE_TABLE_ERR0R_MSG } from "../../utils/constants";
import Icon from "../shared/icon/icon";
import { Fee, hours } from "utils/constants";
import { validateInputValueLength } from "../../services/inputForm/inputFormSlice";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useAreaSelection } from "utils/area-selection";
import { valueConvertor } from "../shared/commons/globalFunctions";
import {
  setDirtyTables,
  inputValueValidation,
  handleEnterTable,
} from "utils/useInputForm";
import { useDispatch } from "react-redux";

const SelectionContext = createContext<DOMRect | null>(null);

const RevenuePLTable = ({
  currency,
  setSaved,
  tableId,
  showModal,
  subHeadingBlock,
  subTitle,
  showCurrencyHeader,
  baseYear,
  showUpdateInputsPopup,
  revenueDirtyTables,
  setRevenueDirtyTables,
  tableData,
  compareTableData,
  setTableData,
  revenueTableIdList,
  revenueData,
}: any) => {
  const BLOCK = "headcountsTable";
  const [prevValue, setPrevValue] = useState<any>("");
  const dispatch = useDispatch();
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });

  const [totalFeeHours, setTotalFeeHours] = useState<any>({
    totalFee: 0,
    totalHours: 0,
  });

  useEffect(() => {
    let totalFee: any = 0;
    let totalHours: any = 0;
    tableData &&
      tableData.length > 0 &&
      tableData.map((item: any) => {
        totalFee += valueConvertor(item.fee);

        totalHours += valueConvertor(item.hours);
      });
    setTotalFeeHours({
      totalFee,
      totalHours,
    });
  }, [tableData]);

  const changeTableValue = useCallback(
    (newTableValue: any) => {
      setTableData(newTableValue);
    },
    [tableData]
  );

  const getTableHeaders = () => {
    const tableHeaders = [
      {
        text: "FY" + `${baseYear} ` + "Revenue",
        isFormattedText: false,
      },
      {
        text: "Fee" + ` (${currency} 'M)`,
        isFormattedText: false,
      },
      {
        text: "revenue-pl-inputs.table.header.hour",
        isFormattedText: true,
      },
    ];

    return tableHeaders;
  };

  const handleOnChange = (
    inputVal: any,
    id: string,
    itemType: String,
    roundOff: boolean
  ) => {
    setPrevValue(inputVal);
    let newVal = inputValueValidation(inputVal, prevValue, setPrevValue);
    let value: any;
    if (roundOff) {
      value = validateInputValueLength(newVal, prevValue, roundOff);
    } else {
      value = validateInputValueLength(newVal, prevValue);
    }

    let newTableValue: any;
    if (itemType === Fee) {
      newTableValue =
        tableData &&
        tableData.length > 0 &&
        tableData.map((item: any) => {
          delete item.errorToastNotRequired;
          if (id === item.productSegmentId) {
            if (item.fee === 0 && item.hours === 0) {
              return {
                ...item,
                fee: value,
                error: false,
              };
            } else if (roundOff && item.fee === 0 && item.hours !== 0) {
              return { ...item, fee: null, hours: null, error: true };
            } else if (roundOff && item.fee !== 0 && item.hours === 0) {
              return { ...item, fee: null, hours: null, error: true };
            } else {
              return {
                ...item,
                fee: value,
                error: false,
              };
            }
          }
          return item;
        });
    } else {
      newTableValue =
        tableData &&
        tableData.length > 0 &&
        tableData.map((item: any) => {
          if (id === item.productSegmentId) {
            if (item.fee === 0 && item.hours === 0) {
              return {
                ...item,
                hours: value,
                error: false,
              };
            } else if (roundOff && item.fee === 0 && item.hours !== 0) {
              return { ...item, fee: null, hours: null, error: true };
            } else if (roundOff && item.fee !== 0 && item.hours === 0) {
              return { ...item, fee: null, hours: null, error: true };
            } else {
              return {
                ...item,
                hours: value,
                error: false,
              };
            }
          }
          return item;
        });
    }
    changeTableValue(newTableValue);
    setDirtyTables(
      compareTableData,
      newTableValue,
      revenueDirtyTables,
      setRevenueDirtyTables,
      tableId
    );
  };

  const FocusChage = (inputValue: any, id: any, itemType: String) => {
    setSaved(false);
    handleOnChange(inputValue, id, itemType, true);
  };

  const getRowItems = () => {
    let rowItems = tableData?.map((item: any, index: number) => {
      let tableRows = {
        id: item.productSegmentId,
        key: `${index}`,
        className: `${BLOCK}__row`,
        columnItems: [
          {
            text: item.productSegment,
          },
          {
            textInput: true,
            inputValue: item.fee,
            inputName: item.productSegment,
            error: item.error,
            errorText: REVENUE_TABLE_ERR0R_MSG,
            errorToastNotRequired: item?.errorToastNotRequired ? true : false,
            onBlur: (e: any) => {
              FocusChage(e.target.value, item.productSegmentId, Fee);
            },
            onchangeHandler: (e: any) => {
              handleOnChange(e.target.value, item.productSegmentId, Fee, false);
            },
            onHandleEnter: (evt: any, keyIdentifier: any) => {
              setPrevValue(evt?.target?.value);
              handleEnterTable(
                evt,
                keyIdentifier,
                tableData,
                tableId,
                revenueTableIdList,
                baseYear,
                revenueData
              );
            },
          },
          {
            textInput: true,
            inputValue: item.hours,
            error: item.error,
            errorText: REVENUE_TABLE_ERR0R_MSG,
            errorToastNotRequired: true,
            onBlur: (e: any) => {
              FocusChage(e.target.value, item.productSegmentId, hours);
            },
            onchangeHandler: (e: any) => {
              handleOnChange(
                e.target.value,
                item.productSegmentId,
                hours,
                false
              );
            },
            onHandleEnter: (evt: any, keyIdentifier: any) => {
              setPrevValue(evt?.target?.value);
              handleEnterTable(
                evt,
                keyIdentifier,
                tableData,
                tableId,
                revenueTableIdList,
                baseYear,
                revenueData
              );
            },
          },
        ],
      } as TableRowItem;

      return tableRows;
    });

    let updatedRowItems: any = rowItems?.length > 0 && [
      ...rowItems,
      {
        className: `${BLOCK}__row`,
        columnItems: [
          { text: "Total", highlight: true },
          { text: totalFeeHours.totalFee.toLocaleString(), isTotal: true },
          { text: totalFeeHours.totalHours.toLocaleString(), isTotal: true },
        ],
        id: 99999,
        key: "1-9999",
      },
    ];
    return updatedRowItems;
  };

  return (
    <>
      <div
        data-test="RevenueAndPL-table"
        className={`${subHeadingBlock}__sub-header`}
      >
        <span className={`${subHeadingBlock}__sub-header--title`}>
          {tableId === 1 ? (
            subTitle
          ) : (
            <>
              <FormattedMessage id={subTitle} />{" "}
              {currency && showCurrencyHeader ? `(${currency} 'M)` : ""}
            </>
          )}
        </span>
      </div>
      <SelectionContext.Provider value={selection}>
        <div ref={selectContainerRef} id={tableId}>
          {tableData && tableData?.length === 0 ? (
            <div className={`${subHeadingBlock}__spinner-container`}>
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className={`${subHeadingBlock}--loading`}
              />
            </div>
          ) : (
            <Table
              className={`${BLOCK}--innerWrapper`}
              headerItems={getTableHeaders()}
              rowItems={getRowItems()}
              innerClassName={BLOCK}
              selection={selection}
              tableId={tableId}
            />
          )}
        </div>
      </SelectionContext.Provider>
    </>
  );
};

export default memo(RevenuePLTable);
