import moment from "moment";
import { useState } from "react";
import { RootStore } from "services/store.service";
import { useDispatch, useSelector } from "react-redux";
import { planSubmission, transformationsPath } from "utils/constants";
import history from "utils/history";
import FormattedMessage from "../shared/formatted-message/formatted-message";

type transformationAssumptionsSubItems = {
  BLOCK: string;
  toggleNavigation: boolean;
  planSubmissionId: number;
  stepSelectedTransformation: string | null;
  changeTabs: any;
};

const TransformationAssumtionSubItems = ({
  BLOCK,
  toggleNavigation,
  planSubmissionId,
  stepSelectedTransformation,
  changeTabs,
}: transformationAssumptionsSubItems) => {
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const yearItems = () => {
    if (inputFormDataState && inputFormDataState?.configurationData) {
      return Array(
        inputFormDataState.configurationData.numberFY
          ? Number(inputFormDataState.configurationData.numberFY)
          : 1
      )
        .fill("1")
        .map((item, index) => {
          return (
            <div
              key={`navigation-item-${index}`}
              className={`${BLOCK}__navigation-panel--item`}
            >
              <span
                className={`${BLOCK}__sub-items`}
                onClick={() => {
                  changeTabs(
                    `${transformationsPath}/FY${
                      inputFormDataState?.metaData?.baseYear + 1 + index
                    }`,
                    index
                  );
                }}
                style={{
                  color:
                    stepSelectedTransformation ===
                    `FY${inputFormDataState?.metaData?.baseYear + 1 + index}`
                      ? "#00A3E0"
                      : "black",
                }}
              >
                FY
                {inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear + 1 + index
                  : "__"}
              </span>
            </div>
          );
        });
    }
  };

  return (
    <div
      className={`${BLOCK}__container`}
      data-test="transformationAssumption-subItem-test"
    >
      <div className={`${BLOCK}__container ${BLOCK}__navigation-panel--item`}>
        <span
          className={`${BLOCK}__sub-items`}
          onClick={() => {
            changeTabs(`${transformationsPath}/liberatedhours`);
          }}
          data-test="transformationAssumption-subItem-test-onClick"
          style={{
            color:
              stepSelectedTransformation === "liberatedhours"
                ? "#00A3E0"
                : "black",
          }}
        >
          <FormattedMessage id={`liberated.hours`} />
        </span>
      </div>
      <div className={`${BLOCK}__container ${BLOCK}__navigation-panel--item`}>
        <span
          className={`${BLOCK}__sub-items`}
          onClick={() => {
            changeTabs(`${transformationsPath}/conversion`);
          }}
          style={{
            color:
              stepSelectedTransformation === "conversion" ? "#00A3E0" : "black",
          }}
        >
          Conversion Ratios
        </span>
      </div>
      {yearItems()}
    </div>
  );
};

export default TransformationAssumtionSubItems;
