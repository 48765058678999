import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { getHeadCountByGradesUsiData } from "services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import {
  ConsolidationsKeyInitial,
  consolidationHeadcountsMockData,
} from "utils/constants";

type PromotedHeadCount = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const UsiHeadCountGrade = ({
  geographies,
  currencyCode,
  baseYear,
}: PromotedHeadCount) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );

  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        getHeadCountByGradesUsiData({
          countryCodes: geographies,
          currencyCode,
          baseYear,
        })
      );
    }
  }, [geographies, currencyCode, baseYear]);

  return (
    <div>
      <ConsolidationsCommonTable
        tableData={consolidationData?.usiGrade?.consolidatedHeadCountDetails}
        tableHeader={consolidationData?.usiGrade?.subTotalHeadCount}
        mainTableHeader="consolidation.headcount.grade"
        subConsolidationTableData={
          consolidationData?.usiGrade?.subConsolidatedHeadCountDetails
        }
        keyInitial={ConsolidationsKeyInitial.USI_HEADCOUNT_GRADE}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return item === VISUAL_DASHBOARD_OPTIONS.USI_GRADE_LOADING;
          })?.length > 0
        }
        isGradeLevelTable={true}
      />
    </div>
  );
};

export default UsiHeadCountGrade;
