import { useState, useEffect, useRef, createContext, memo } from "react";
import { useDispatch } from "react-redux";
import Table from "../shared/table/table";
import { headCountsTableGradeLevels } from "utils/constants";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import { useAreaSelection } from "utils/area-selection";
import {
  ratePerGradeTableUpdate,
  ratePerGradeRowItems,
  useCheckTableBottomVisible,
} from "utils/useInputForm";

const SelectionContext = createContext<DOMRect | null>(null);

const RatePerGrade = ({
  subHeadingBlock,
  tableId,
  setSaved,
  showModal,
  tableHeader,
  tableIdMapping,
  showUpdateInputsPopup,
  headCountsDirtyTables,
  setHeadCountsDirtyTables,
  tableData,
  compareTableData,
  setTableData,
  baseYear,
  headCountTableIdList,
  headCountsData,
  tableScrollIds,
  setTableScrolledIds,
}: any) => {
  const BLOCK = "headcountsTable";
  const [prevValue, setPrevValue] = useState<any>("");
  const [deleteErrors, setDeleteErrors] = useState<boolean>(false);
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });
  const dispatch = useDispatch();

  const getTableHeaders = () => {
    let dynamicTableHeaders: any =
      tableData &&
      tableData?.length > 0 &&
      Object.keys(tableData[0] || {})
        .filter(Number)
        .map((item) => {
          return {
            text: `FY${item}`,
            isFormattedText: false,
          };
        });

    const tableHeaders = dynamicTableHeaders &&
      dynamicTableHeaders.length > 0 && [
        {
          text: tableHeader,
          isFormattedText: true,
        },
        ...dynamicTableHeaders,
      ];
    return tableHeaders;
  };

  useEffect(() => {
    if (!deleteErrors && tableData && Object.keys(tableData)) {
      tableData.map((item: any) => {
        delete item.error;
      });
      setDeleteErrors(true);
    }
  }, [tableData]);

  const handleOnChange = (
    inputVal: any,
    gradeLevelId: number,
    columnId: any,
    roundOff: boolean = false
  ) => {
    setPrevValue(inputVal);
    ratePerGradeTableUpdate(
      gradeLevelId,
      inputVal,
      roundOff,
      columnId,
      tableData,
      setTableData,
      compareTableData,
      headCountsDirtyTables,
      setHeadCountsDirtyTables,
      tableId,
      true,
      setPrevValue,
      prevValue
    );
  };

  const FocusChage = (inputVal: any, gradeLevelId: any, columnId: any) => {
    setSaved(false);
    handleOnChange(inputVal, gradeLevelId, columnId, true);
  };

  useCheckTableBottomVisible(
    selectContainerRef,
    tableScrollIds,
    setTableScrolledIds
  );

  return (
    <div style={{ marginTop: 30 }} data-test="ratePerGrade-table">
      <div className={`${subHeadingBlock}__sub-header`}>
        <div className={`${subHeadingBlock}__sub-header--title`}>
          <FormattedMessage id={tableHeader} />
        </div>
      </div>
      <SelectionContext.Provider value={selection}>
        <div ref={selectContainerRef} id={tableId}>
          {tableData?.length === 0 ? (
            <div className={`${subHeadingBlock}__spinner-container`}>
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className={`${subHeadingBlock}--loading`}
              />
            </div>
          ) : (
            <Table
              className={`${BLOCK}--innerWrapper`}
              headerItems={getTableHeaders()}
              rowItems={ratePerGradeRowItems(
                deleteErrors,
                tableData,
                FocusChage,
                handleOnChange,
                BLOCK,
                headCountsTableGradeLevels,
                tableIdMapping,
                baseYear,
                headCountTableIdList,
                headCountsData,
                tableId,
                setPrevValue
              )}
              innerClassName={BLOCK}
              selection={selection}
              tableId={tableId}
            />
          )}
        </div>
      </SelectionContext.Provider>
    </div>
  );
};

export default memo(RatePerGrade);
