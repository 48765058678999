import { useState, useEffect, useRef, createContext } from "react";
import { useAPI } from "./liberatedHoursContextProvider";
import { useAreaSelection } from "utils/area-selection";
import Icon from "components/shared/icon/icon";
import Table, { TableRowItem } from "../shared/table/table";
import {
  productSegmentsMapping,
  COST_ASSUMPTION_TABLE,
  PERCENTAGE_ABOVE_100_ERROR,
  TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES,
  LIBERATED_HOURS_TABLE_IDS,
  deployementTableReference,
  productSegmentOmnia,
  TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS,
} from "utils/constants";
import { updateDeployementTableData } from "utils/useInputForm";
import { cleanTableData } from "components/shared/commons/globalFunctions";

const SelectionContext = createContext<DOMRect | null>(null);

const DeployementOfFile = ({
  dropDownSelected,
  showModal,
  showUpdateInputsPopup,
  liberatedHoursDirtyTables,
  setLiberatedHoursDirtyTables,
}: any) => {
  const BLOCK = "headcountsTable";
  const subHeadingBlock = "headcounts";

  const {
    deployementAndLiberatedHoursData,
    setDeployementAndLiberatedHoursData,
    compareTableData,
    deploymentAndLiberatedHoursLoader,
    setDeployementCalData,
    deployementCalData,
    costAssumptionCenterData,
  }: any = useAPI();

  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });
  const [prevValue, setPrevValue] = useState<any>("");
  const [dropDown, setDropDown] = useState<any>("");

  let tableId =
    dropDownSelected === 2
      ? TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES.deployementOmnia
      : TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES.deployementLEVVIA;

  useEffect(() => {
    setDropDown((prevValue: any) => {
      if (prevValue && prevValue !== dropDownSelected) {
        setDeployementAndLiberatedHoursData(
          (deployementAndLiberatedHoursPrevVal: any) => {
            return {
              ...deployementAndLiberatedHoursPrevVal,
              [productSegmentsMapping[prevValue]]: cleanTableData(
                deployementAndLiberatedHoursData[
                  productSegmentsMapping[prevValue]
                ]
              ),
            };
          }
        );
      }
      return dropDownSelected;
    });
  }, [dropDownSelected]);

  let tableData =
    deployementAndLiberatedHoursData &&
    Object.keys(deployementAndLiberatedHoursData).length &&
    deployementAndLiberatedHoursData[productSegmentsMapping[dropDownSelected]]
      ? deployementAndLiberatedHoursData[
          productSegmentsMapping[dropDownSelected]
        ]
      : [];

  let costAssumptioTableId =
    dropDownSelected === 2
      ? TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costOmnia
      : TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costLEVVIA;

  let costAssumptioTableData =
    costAssumptionCenterData &&
    Object.keys(costAssumptionCenterData).length &&
    costAssumptionCenterData[productSegmentsMapping[dropDownSelected]]
      ? costAssumptionCenterData[productSegmentsMapping[dropDownSelected]]
      : [];

  const [newTableData, setNewTableData] = useState<any>([]);

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      let deploymentTableDataLeverId8 = tableData?.filter((item: any) => {
        return item.leverId === deployementTableReference[1]?.leverId;
      });
      let deploymentTableDataLeverId7 = tableData?.filter((item: any) => {
        return item.leverId === deployementTableReference[0]?.leverId;
      });

      let inYearObj = {
        textInput: true,
        allowNegValues: true,
        placholder: "-",
        inputIcon: true,
        inputValue: null,
        inputDisable: true,
        year: null,
        leverId: null,
      };

      let updatedVal = deploymentTableDataLeverId8?.map(
        (item: any, index: number) => {
          if (index === 0) {
            return {
              ...inYearObj,
              inputValue: 0,
              year: item.year,
              leverId: item.leverId,
            };
          } else {
            return {
              ...inYearObj,
              year: item.year,
              leverId: item.leverId,
              inputValue:
                (deploymentTableDataLeverId7[index]?.value !== null
                  ? deploymentTableDataLeverId7[index]?.value
                  : 0) -
                (deploymentTableDataLeverId7[index - 1]?.value !== null
                  ? deploymentTableDataLeverId7[index - 1]?.value
                  : 0),
              roundNumbers: 2,
            };
          }
        }
      );
      setDeployementCalData(updatedVal);
    }
  }, [tableData]);

  useEffect(() => {
    if (deployementCalData && deployementCalData.length > 0) {
      let newData = [...tableData];
      newData.map((item: any) => {
        if (item.leverId === 8) {
          let newObj = deployementCalData.find(
            (val: any) => val.year === item.year
          );

          if (newObj) {
            item.value = newObj.inputValue;
          }
        }
      });
      setNewTableData(newData);
    }
  }, [deployementCalData]);

  const findYearsList = (data: any) => {
    const result: any = [];
    data.map((item: any) => {
      if (!result.includes(item.year)) {
        result.push(item.year);
      }
    });
    return result;
  };

  const getTableHeaders = () => {
    const baseYearList: any = findYearsList(newTableData);
    let dynamicHeaders: any = [];
    baseYearList?.map((item: any, index: number) => {
      dynamicHeaders.push(
        index === 0
          ? {
              text: "liberatedHours.deployement.cumulative.adoption",
              isFormattedText: true,
            }
          : {
              text: "FY" + item,
              isFormattedText: false,
            }
      );
    });
    const tableHeaders = [
      {
        text: "",
      },
      ...dynamicHeaders,
    ];
    return tableHeaders;
  };

  const handleOnChange = (
    leverId: number,
    year: number,
    inputVal: any,
    roundOff: boolean = false
  ) => {
    setPrevValue(inputVal);
    updateDeployementTableData(
      leverId,
      year,
      inputVal,
      roundOff,
      newTableData,
      setDeployementAndLiberatedHoursData,
      compareTableData?.deployementAndLiberatedHoursData[
        productSegmentsMapping[dropDownSelected]
      ],
      liberatedHoursDirtyTables,
      setLiberatedHoursDirtyTables,
      LIBERATED_HOURS_TABLE_IDS.DEPLOYEMENT_HOURS,
      setPrevValue,
      prevValue,
      true,
      dropDownSelected
    );
  };

  const FocusChage = (inputVal: any, gradeLevelId: any, year: number) => {
    handleOnChange(gradeLevelId, year, inputVal, true);
  };
  const createStringFromTemplate = (template: any, variables: any) => {
    return template?.replace(
      new RegExp("{([^{]+)}", "g"),
      function (_unused: any, varName: any) {
        return variables[varName];
      }
    );
  };

  const handleEnter = (evt: any, keyIdentifier: any) => {
    if (evt.key === "Enter") {
      let localKeyIdentifier: any = [];

      let newTableData = tableData?.filter((item: any) => {
        return item.leverId === 7;
      });

      newTableData?.map((item: any) => {
        item.leverId !== 6 &&
          localKeyIdentifier.push(`${tableId}#${item.leverId}#${item.year}`);
      });

      let index = localKeyIdentifier.findIndex(
        (item: any) => item === keyIdentifier
      );
      let element;
      if (index > -1) {
        element = document.getElementById(localKeyIdentifier[index + 1]);
      }

      if (!element) {
        document
          .getElementById(
            `${costAssumptioTableId}#${costAssumptioTableData[0]?.leverId}#${1}`
          )
          ?.focus();
      } else {
        element?.focus();
      }
    }
  };

  const leverIdwiseData = (data: any) => {
    const leverIdwiseData: any = {
      [deployementTableReference[0]?.leverId]: [],
      [deployementTableReference[1]?.leverId]: [],
    };
    data?.map((item: any) => {
      let cumulativeObj = {
        textInput: true,
        customIndex: item.year,
        error: item.error,
        errorText: PERCENTAGE_ABOVE_100_ERROR,
        errorToastNotRequired: item?.errorToastNotRequired ? true : false,
        onBlur: (e: any) => {
          FocusChage(e.target.value, item.leverId, item.year);
        },
        onchangeHandler: (e: any) => {
          handleOnChange(item.leverId, item.year, e.target.value);
        },
        onHandleEnter: (evt: any, keyIdentifier: any) => {
          setPrevValue(evt?.target?.value);
          handleEnter(evt, keyIdentifier);
        },
        placholder: "-",
        inputIcon: true,
        inputValue: item.value,
      };

      if (item.leverId === deployementTableReference[0]?.leverId) {
        leverIdwiseData[deployementTableReference[0]?.leverId].push(
          cumulativeObj
        );
      }
    });

    leverIdwiseData[deployementTableReference[1]?.leverId] = deployementCalData;

    return leverIdwiseData;
  };

  const getRowItems = () => {
    let resultData: any = leverIdwiseData(newTableData);
    let rowItems =
      newTableData &&
      newTableData?.length > 0 &&
      deployementTableReference?.map((item: any, index: number) => {
        let tableRows = {
          id: item.leverId,
          key: `${item.leverId}-${index}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: createStringFromTemplate(
                COST_ASSUMPTION_TABLE[item.leverId],
                {
                  ProductSegment:
                    dropDownSelected === 2
                      ? productSegmentOmnia
                      : productSegmentsMapping[dropDownSelected],
                }
              ),
            },
            ...resultData[item.leverId],
          ],
        };
        return tableRows;
      });
    return rowItems;
  };

  return (
    <div style={{ marginTop: 30 }} data-test="deployement-table">
      <SelectionContext.Provider value={selection}>
        <div ref={selectContainerRef}>
          {deploymentAndLiberatedHoursLoader ? (
            <div className={`${subHeadingBlock}__spinner-container`}>
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className={`${subHeadingBlock}--loading`}
              />
            </div>
          ) : (
            <Table
              className={`${BLOCK}--innerWrapper`}
              headerItems={getTableHeaders()}
              rowItems={getRowItems()}
              innerClassName={BLOCK}
              selection={selection}
              tableId={tableId}
            />
          )}
        </div>
      </SelectionContext.Provider>
    </div>
  );
};

export default DeployementOfFile;
