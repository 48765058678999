import { scaleLinear } from "d3-scale";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
  Text,
  ResponsiveContainer,
  ErrorBar,
} from "recharts";

const customLabelList = (props: any, isPercentage: boolean = false) => {
  const { x, y, width, value } = props;
  const radius = 20;
  return (
    <Text
      className="visual_graph__LabelList"
      x={x + width / 2}
      y={value < 0 ? y + radius : y - radius}
      angle={-50}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {value === 0 || isNaN(value)
        ? "__"
        : new Intl.NumberFormat("en-EN", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            style: isPercentage ? "percent" : "decimal",
          }).format(value)}
    </Text>
  );
};
const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload, isPercentage } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text className="visual_graph__Label" x={0} y={0} textAnchor="middle">
        {payload?.value?.toLocaleString("us-EN", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
          style: isPercentage ? "percent" : "decimal",
        })}
      </text>
    </g>
  );
};
const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        className="visual_graph__Label1"
        x={0}
        y={0}
        dy={10}
        textAnchor="middle"
      >
        {payload?.value}
      </text>
    </g>
  );
};

type WaterFallChartProps = {
  data: any;
  gmBridge?: boolean;
  percentageToggle?: boolean;
};

const selectColor = (data: any, entry: any, index: any, gmBridge: boolean) => {
  let color: string;

  if (gmBridge) {
    color =
      entry.label === "___"
        ? "#ffffff"
        : index === 0 || index === data.length - 1
        ? "#2A5599"
        : Number(entry.label) < 0
        ? "#E35A0E"
        : "#62B5E5";
    return color;
  } else {
    color =
      entry.label === "___"
        ? "#ffffff"
        : index === 0 || (data.length > 6 ? index === 7 : index === 5)
        ? "#2A5599"
        : Number(entry.label) < 0
        ? "#E35A0E"
        : "#62B5E5";
    return color;
  }
};

const WaterFallChart = ({
  data,
  gmBridge = false,
  percentageToggle = false,
}: WaterFallChartProps) => {
  return (
    <>
      {data && Object.keys(data).length && (
        <ResponsiveContainer width="95%" height="70%">
          <BarChart
            data={data}
            margin={{
              top: 35,
              left: -5,
            }}
          >
            <CartesianGrid
              strokeDasharray="1 10"
              vertical={false}
              horizontalCoordinatesGenerator={(args) => {
                let hPoints: any = [];
                const totalLines = Math.ceil(args.offset.height / 50);
                const hScale = scaleLinear()
                  .range([args.offset.top, args.height - args.offset.bottom])
                  .domain([0, totalLines]);

                for (let i = 0; i <= totalLines; i++) {
                  hPoints = [...hPoints, hScale(i)];
                }
                return hPoints;
              }}
            />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tickMargin={25}
              height={50}
              tick={CustomizedXAxisTick}
            />
            <YAxis
              dataKey="value"
              axisLine={false}
              tickLine={false}
              height={20}
              tick={<CustomizedYAxisTick isPercentage={percentageToggle} />}
            />
            <Tooltip
              cursor={false}
              separator=""
              viewBox={{ x: 0, y: 0, width: 600, height: 200 }}
              formatter={(value: any, name: any, props: any) => {
                return [
                  props.payload.label === 0 || isNaN(props.payload.label)
                    ? "__"
                    : new Intl.NumberFormat("en-EN", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                        style: percentageToggle ? "percent" : "decimal",
                      }).format(props.payload.label),
                  "",
                ];
              }}
            />
            <Bar
              dataKey="value"
              fill="#8884d8"
              maxBarSize={70}
              minPointSize={3}
              isAnimationActive={false}
            >
              {data?.map((entry: any, index: number) => {
                const color = selectColor(data, entry, index, gmBridge);

                return <Cell key={index} fill={color} />;
              })}
              <ErrorBar
                dataKey="error"
                width={30}
                strokeWidth={1}
                stroke="#97999B"
                direction="y"
              />
              <LabelList
                content={(e) => customLabelList(e, percentageToggle)}
                position="top"
                dataKey="label"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
export default WaterFallChart;
