import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { getOverHeadAllocationsData } from "services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { ConsolidationsKeyInitial } from "utils/constants";

type OverHeadAllocationExp = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const OverHeadAllocationExpTable = ({
  geographies,
  currencyCode,
  baseYear,
}: OverHeadAllocationExp) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );

  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        getOverHeadAllocationsData({
          countryCodes: geographies,
          currencyCode,
          baseYear,
        })
      );
    }
  }, [geographies, currencyCode, baseYear]);

  return (
    <div data-test="overHeadAllocationExpTable-component">
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.overHeadsAllocationsData
            ?.consolidatedProfitAndLossDetails
        }
        tableHeader={
          consolidationData?.overHeadsAllocationsData?.subTotalProfitAndLoss
        }
        mainTableHeader="consolidation.overheads.allocation.expense"
        subConsolidationTableData={
          consolidationData?.overHeadsAllocationsData
            ?.subConsolidatedProfitAndLossDetails
        }
        keyInitial={ConsolidationsKeyInitial.OVER_HEADS_ALLOCATION_EXPENSES}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return (
              item === VISUAL_DASHBOARD_OPTIONS.OVERHEADS_ALLOCATIONS_LOADING
            );
          })?.length > 0
        }
      />
    </div>
  );
};

export default OverHeadAllocationExpTable;
