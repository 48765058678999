import { useState, useEffect, useRef, createContext } from "react";
import { useAPI } from "./liberatedHoursContextProvider";
import Icon from "components/shared/icon/icon";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Table, { TableRowItem } from "../shared/table/table";
import {
  productSegmentsMapping,
  COST_ASSUMPTION_TABLE,
  deployementTableReference,
} from "utils/constants";
import { useCalculateLiberatedHoursTable } from "utils/useInputForm";

const LiberatedHoursTable = ({ dropDownSelected }: any) => {
  const BLOCK = "headcountsTable";
  const subHeadingBlock = "headcounts";
  const [costAdoptionAssumptionList, setCostAdoptionAssumptionList] =
    useState<any>([]);

  const {
    costAssumptionCenterData,
    deployementAndLiberatedHoursData,
    deploymentAndLiberatedHoursLoader,
    deployementCalData,
  }: any = useAPI();

  let tableData =
    deployementAndLiberatedHoursData &&
    Object.keys(deployementAndLiberatedHoursData).length &&
    deployementAndLiberatedHoursData[productSegmentsMapping[dropDownSelected]]
      ? deployementAndLiberatedHoursData[
          productSegmentsMapping[dropDownSelected]
        ]
      : [];

  let costAssumptionTableData =
    costAssumptionCenterData &&
    Object.keys(costAssumptionCenterData).length &&
    costAssumptionCenterData[productSegmentsMapping[dropDownSelected]]
      ? costAssumptionCenterData[productSegmentsMapping[dropDownSelected]]
      : [];

  useEffect(() => {
    if (
      costAssumptionTableData &&
      Object.keys(costAssumptionTableData).length
    ) {
      let temperaryArray: any = [];
      costAssumptionTableData.map((item: any, index: number) => {
        if (item.leverId === index + 1 && index < 5) {
          temperaryArray.push(item.value);
        }
      });
      setCostAdoptionAssumptionList(temperaryArray);
    }
  }, [costAssumptionTableData]);

  const newTableData = useCalculateLiberatedHoursTable(
    tableData,
    deployementCalData,
    costAdoptionAssumptionList
  );

  const findYearsList = (data: any) => {
    if (data && Object.keys(data).length) {
      const result: any = [];
      data.map((item: any) => {
        if (
          !result.includes(item.year) &&
          item.leverId !== deployementTableReference[0]?.leverId &&
          item.leverId !== deployementTableReference[1]?.leverId
        ) {
          result.push(item.year);
        }
      });
      return result;
    }
  };
  const findLeverIdsList = (data: any) => {
    if (data && Object.keys(data).length) {
      const result: any = [];
      data.map((item: any) => {
        if (!result.includes(item.leverId) && item.leverId > 8) {
          result.push(item.leverId);
        }
      });
      return result;
    }
  };
  const getTableHeaders = () => {
    const leverIdsList: any = findLeverIdsList(newTableData);
    let dynamicHeaders: any = [];
    leverIdsList?.map((item: any, index: number) => {
      dynamicHeaders.push({
        text: COST_ASSUMPTION_TABLE[item],
        isFormattedText: false,
      });
    });
    const tableHeaders = [
      {
        text: "",
      },
      ...dynamicHeaders,
    ];
    return tableHeaders;
  };

  const getLeverIdwiseData = (data: any) => {
    if (data && data.length > 0) {
      let leverIdwiseData: any = {};
      const baseYearList: any = findYearsList(data);
      data?.map((item: any) => {
        let inputObj = {
          textInput: true,
          allowNegValues: true,
          customIndex: item.year,
          placholder: "-",
          inputValue: item.value,
          inputDisable: true,
          roundNumbers: 2,
        };

        baseYearList.map((yearItem: any) => {
          if (yearItem === item.year && item.leverId === 14) {
            if (leverIdwiseData[yearItem]) {
              leverIdwiseData[yearItem].push({
                ...inputObj,
                inputIcon: true,
              });
            } else {
              leverIdwiseData[yearItem] = [{ ...inputObj, inputIcon: true }];
            }
          } else if (
            yearItem === item.year &&
            item.leverId !== deployementTableReference[0]?.leverId &&
            item.leverId !== deployementTableReference[1]?.leverId
          ) {
            if (leverIdwiseData[yearItem]) {
              leverIdwiseData[yearItem]?.push(inputObj);
            } else {
              leverIdwiseData[yearItem] = [inputObj];
            }
          }
        });
      });
      return leverIdwiseData;
    }
  };

  const getRowItems = (tableData: any) => {
    let resultData: any = getLeverIdwiseData(tableData);
    const baseYearList: any = findYearsList(tableData);
    let rowItems =
      baseYearList &&
      baseYearList?.length > 0 &&
      baseYearList?.map((item: any, index: number) => {
        let tableRows = {
          id: item.leverId,
          key: `${item.leverId}-${index}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: "FY" + item + " Impact",
            },
            ...resultData[item],
          ],
        };
        return tableRows;
      });
    return rowItems;
  };

  return (
    <div style={{ marginTop: 30 }} data-test="liberatedHours-table">
      <div>
        {deploymentAndLiberatedHoursLoader ? (
          <div className={`${subHeadingBlock}__spinner-container`}>
            <Icon
              name={"loading"}
              width={50}
              height={50}
              className={`${subHeadingBlock}--loading`}
            />
          </div>
        ) : (
          <>
            <Table
              className={`${BLOCK}--innerWrapper`}
              headerItems={getTableHeaders()}
              rowItems={getRowItems(newTableData)}
              innerClassName={BLOCK}
              selectedRow={0}
            />
            <div className="liberatedHours__footNote">
              <FormattedMessage id="liberatedHours.foot.note" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LiberatedHoursTable;
