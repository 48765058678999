import {
  useState,
  useEffect,
  EventHandler,
  ChangeEventHandler,
  FocusEventHandler,
} from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Checkbox from "components/shared/checkbox/checkbox";
import Icon from "components/shared/icon/icon";
import Spinner from "images/spinner.gif";
import { MouseEventHandler, useRef } from "react";
import { RootStore } from "services/store.service";
import DropDown from "components/shared/dropdown/dropdown";
import TableRow from "./table-row";

export type CheckboxOptions = {
  onChange: any;
  value?: boolean;
  indeterminate?: boolean;
};

type Filter = {
  activeFilter: string;
  currentFilterText: string;
  currentFilter: string;
  setFilterPop: Function;
  handleClose: Function;
  handleApply: Function;
  handleDateFilter?: Function;
};

type TableProps = {
  className?: string;
  innerClassName?: string;
  headerItems?: TableHeaderItem[];
  secondHeaderItems?: TableHeaderItem[];
  rowItems?: TableRowItem[];
  showSpinner?: boolean;
  selectedRow?: number;
  sortBy?: string;
  sortOrder?: string;
  onSort?: Function;
  defaultSelectedItems?: Partial<TableRowItem>[];
  filter?: Filter;
  selection?: any;
  tableId?: any;
  subTitle?: any;
};

export type TableHeaderItem = {
  icon?: string;
  iconWithText?: any;
  iconSize?: number;
  textInput?: any;
  isTotal?: boolean;
  placeHolder?: string;
  text?: string;
  isFormattedText?: boolean;
  className?: string;
  sortId?: string;
  filterId?: string;
  dateRange?: boolean;
  filterPosition?: string;
  type?: string;
  hidden?: boolean;
  checkbox?: CheckboxOptions;
  filterYears?: any;
  setSelectedYear?: Function;
  selectedYear?: any;
};

export type TableRowItem = {
  id: any;
  tableId?: any;
  columnItems: TableColumnItem[];
  icon?: string;
  iconSize?: number;
  iconClassName?: string;
  InfoIconTooltip?: string;
  addInitials?: any;
  key?: string;
  rowSelected?: boolean;
  isSelected?: boolean;
  className?: string;
  updateSelected?: Function;
  showItems?: any;
  setShowItems?: Function;
  filterContent?: boolean;
  onClick?: MouseEventHandler;
  actions?: any;
  isGlobalOrMFAdmin?: boolean;
  selection?: any;
  setSelectedCells?: any;
  selectedCells?: any;
  indexKey?: any;
  revenue_Pl_SubTitle?: any;
};

export type TableColumnItem = {
  id?: number;
  keyIdentifier?: any;
  tableId?: number;
  setSelectedCells?: any;
  selectedCells?: any;
  selection?: any;
  textInput?: any;
  error?: boolean;
  errorText?: string;
  errorToastNotRequired?: boolean;
  largeError?: boolean;
  isTotal?: boolean;
  placeHolder?: string;
  inputValue?: any;
  inputName?: string;
  inputDisable?: boolean;
  onchangeHandler?: ChangeEventHandler;
  onHandleEnter?: any;
  onBlur?: FocusEventHandler;
  icon?: string;
  iconSize?: number;
  iconTooltip?: any;
  iconClassName?: string;
  InfoIconTooltip?: string;
  addInitials?: any;
  text?: string | string[];
  highlight?: boolean;
  allowNegValues?: boolean;
  textTooltip?: any;
  subTitle?: string;
  isFormattedText?: boolean;
  renderContent?: Function;
  className?: string;
  onClick?: MouseEventHandler;
  type?: string;
  wrapperClassName?: string;
  hidden?: boolean;
  checkbox?: CheckboxOptions;
  noteId?: number;
  commentNodeID?: number;
  filterContent?: boolean;
  updateSelected?: Function;
  isSelected?: boolean;
  setShowNotes?: Function;
  onChange?: ChangeEventHandler;
  placholder?: string;
  preparerEmailId?: string[];
  reviewerEmailId?: string;
  memberFirmEmailId?: string;
  inputIcon?: boolean;
  percentIconDisabledField?: boolean;
  customIndex?: any;
  setPreparerList?: Function;
  preparerList?: any;
  indexKey?: any;
  showExpandIcon?: boolean;
  iconDirection?: any;
  iconOnlick?: any;
  roundNumbers?: number;
  revenue_Pl_SubTitle?: any;
};

const Table = ({
  className,
  innerClassName,
  headerItems,
  secondHeaderItems,
  rowItems,
  showSpinner,
  selectedRow,
  sortBy,
  sortOrder,
  onSort,
  defaultSelectedItems,
  filter,
  selection,
  tableId,
  subTitle,
}: TableProps) => {
  const BLOCK = "Table";
  const headerRef = useRef(null);
  const dispatch = useDispatch();
  const dashboardTableState = useSelector(
    (state: RootStore) => state.dashboard
  );
  const [selectedItems, setSelectedItems] = useState<Partial<TableRowItem>[]>(
    defaultSelectedItems ? defaultSelectedItems : []
  );

  const [showItems, setshowItems] = useState<Partial<any>[]>([]);
  const [selectedAll, setSelectedAll] = useState(false);
  // const [filterContent, setFilterContent] = useState<boolean>(
  //   searchResults.searchPayloadSEC.contentText.length !== 0
  // );

  const isSelected = (itemId: number): boolean =>
    selectedItems.filter(({ id }) => id === itemId).length > 0;

  const updateSelected = (item: TableRowItem) => {
    let selectedItemsTmp = [];
    if (isSelected(item.id)) {
      selectedItemsTmp = selectedItems.filter(({ id }) => id !== item.id);
    } else {
      selectedItemsTmp = [...selectedItems, item];
    }
    setSelectedItems(selectedItemsTmp);

    if (selectedAll) {
      setSelectedAll(!selectedAll);
    }
    if (!selectedAll && selectedItemsTmp.length === rowItems?.length) {
      setSelectedAll(!selectedAll);
    }

    return selectedItemsTmp;
  };

  const updateAllSelected = (item: any, selected: boolean) => {
    let selectedItemsTmp = [];
    //let notAllSelected = rowItems && rowItems.length !== item.length;
    if (!selected) {
      selectedItemsTmp = item;
    }
    setSelectedItems(selectedItemsTmp);
    return selectedItemsTmp;
  };

  useEffect(() => {
    setSelectedItems(defaultSelectedItems ? defaultSelectedItems : []);
  }, [defaultSelectedItems]);

  useEffect(() => {
    if (selectedItems.length !== rowItems?.length) {
      setSelectedAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowItems]);

  // useEffect(() => {
  //   setFilterContent(searchResults.searchPayloadSEC.contentText.length !== 0);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchResults.searchPayloadSEC.contentText]);

  const renderHeaderItemContents = (item: TableHeaderItem) => {
    let icon;

    const contents = (
      <>
        {item.text && item.text !== "HIDDENCOLUMN" && (
          <div
            className={classNames(`${BLOCK}__header-text`, {
              [`${innerClassName}__header-text`]: innerClassName,
            })}
          >
            <span
              onClick={() => item.sortId && onSort?.(item.sortId)}
              id={item.text}
            >
              {item.isFormattedText ? (
                <FormattedMessage id={item.text} />
              ) : (
                item.text
              )}
            </span>
            {item.iconWithText}
            {item.filterId && filter && (
              <>
                <div
                  className={classNames(`filter`, {
                    [`filter__active`]:
                      filter.activeFilter === item.filterId ||
                      filter.currentFilter === item.text,
                  })}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    filter.setFilterPop(item.text);
                  }}
                >
                  <Icon name="filter" height={14} width={14} />
                </div>
              </>
            )}
          </div>
        )}
        {item.text === "dashboard.table.header.year" && (
          <>
            <DropDown
              className={`year`}
              parentBlock={""}
              data={
                dashboardTableState.fiscalYearList
                  ? dashboardTableState.fiscalYearList
                      .slice()
                      .sort(
                        (a: { year: number }, b: { year: number }) =>
                          b.year - a.year
                      )
                      .map((item: any, index: any) => {
                        return { year: item.year, id: index + 1 };
                      })
                  : []
              }
              selected={item.selectedYear}
              keys={{
                displayName: "year",
                id: "id",
              }}
              onChange={(e: any) => {
                dispatch(
                  item.setSelectedYear?.(
                    e.id === item.selectedYear.id
                      ? {
                          year: dashboardTableState.fiscalYearList[0]?.year,
                          id: 1,
                        }
                      : e
                  )
                );
              }}
            />
          </>
        )}
      </>
    );

    if (onSort && item.sortId) {
      const className =
        sortBy === item.sortId
          ? `${BLOCK}__header-inner-content ${BLOCK}__header-button ${BLOCK}__header-button--sorted`
          : `${BLOCK}__header-inner-content ${BLOCK}__header-button`;
      if (sortBy === item.sortId && sortOrder && sortOrder === "asc") {
        icon = (
          <Icon
            name="sort-ascending"
            width={16}
            height={16}
            className={`${BLOCK}__sort-icon`}
          />
        );
      } else if (sortBy === item.sortId && sortOrder && sortOrder === "desc") {
        icon = (
          <Icon
            name="sort-descending"
            width={16}
            height={16}
            className={`${BLOCK}__sort-icon`}
          />
        );
      }

      return (
        <div className={className}>
          {
            <div
              onClick={() => item.sortId && onSort?.(item.sortId)}
              id={item.text}
            >
              {icon}
            </div>
          }
          {contents}
        </div>
      );
    } else {
      return <div className={`${BLOCK}__header-inner-content`}>{contents}</div>;
    }
  };

  return (
    <div className={`${BLOCK} ${className || ""}`}>
      <table className={`${BLOCK}__table ${innerClassName || ""}`}>
        <thead ref={headerRef}>
          {secondHeaderItems && secondHeaderItems.length > 0 && (
            <tr
              className={classNames(`${BLOCK}__header`, {
                [`${innerClassName}__header`]: innerClassName,
              })}
            >
              {secondHeaderItems?.map((item, index) =>
                item ? (
                  item?.hidden ? null : (
                    <th
                      className={classNames(`${BLOCK}__header-content`, {
                        [`${item.className}`]: item.className,
                        [`${innerClassName}__header-content`]: innerClassName,
                      })}
                      key={index}
                    >
                      {renderHeaderItemContents(item)}
                    </th>
                  )
                ) : null
              )}
            </tr>
          )}

          <tr
            className={classNames(`${BLOCK}__header`, {
              [`${innerClassName}__header`]: innerClassName,
            })}
          >
            {headerItems &&
              headerItems.map((item, index) =>
                item ? (
                  item?.hidden ? null : (
                    <th
                      className={classNames(`${BLOCK}__header-content`, {
                        [`${item.className}`]: item.className,
                        [`${innerClassName}__header-content`]: innerClassName,
                      })}
                      key={index}
                    >
                      {renderHeaderItemContents(item)}
                    </th>
                  )
                ) : null
              )}
          </tr>
        </thead>
        <tbody>
          {rowItems &&
            rowItems.map((row) => (
              <TableRow
                key={row.key || row.id.toString()}
                {...row}
                tableId={tableId}
                rowSelected={row.id === selectedRow}
                isSelected={isSelected(row.id)}
                updateSelected={() => updateSelected(row)}
                showItems={showItems}
                setShowItems={setshowItems}
                // filterContent={filterContent}
                selection={selection}
                revenue_Pl_SubTitle={subTitle}
              />
            ))}
        </tbody>
      </table>
      {showSpinner && (
        <div className={`${BLOCK}__spinner-container`}>
          <img className={`${BLOCK}__spinner`} src={Spinner} alt={"Loading"} />
        </div>
      )}
    </div>
  );
};

export default Table;
