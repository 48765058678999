import { useEffect, useState } from "react";
import ConvertionRation from "./conversion-ratios";
import TransformationTab from "./transformationTab";
import { useDispatch, useSelector } from "react-redux";
import {
  TRANSFORMATION_OPTIONS,
  transformationsHeaders,
  TABLE_MAPPINGS,
  TRANSFORMATION_MODEL,
  ClosingPositions,
  conversion,
  liberatedHours,
  DELIVERY_CENTRE_ID_MAPPING,
} from "utils/constants";
import {} from "utils/constants";
import { RootStore } from "services/store.service";
import TransformationTable from "./transformationTables";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useParams } from "react-router-dom";
import {
  loadTransformationTablesContext,
  getConversionRatiosTableData,
} from "services/inputForm/inputFormSlice";
import { useAPI } from "../updatedTransformationAssumptions/transformationAssumptionContextProvider";
import {
  transformationAssumptionsTableConvertor,
  valueConvertor,
} from "../shared/commons/globalFunctions";
import LiberatedHours from "../liberatedHours/liberatedHours";
import LiberatedHoursHeader from "components/liberatedHours/liberatedHoursHeader";

type transformationSubItems = {
  toggleNavigation: boolean;
  planSubmissionId: number;
  changeTabs: Function;
  setDestination: Function;
  setSaved: any;
  transformationDirtyTables: any;
  setTransformationDirtyTables: Function;
  showModal?: any;
  selectedTab: number;
  setSelectedTab: Function;
  showUpdateInputsPopup: boolean;
  liberatedHoursDirtyTables: any;
  setLiberatedHoursDirtyTables: Function;
};
const CommonAssumptionsSubItems = ({
  toggleNavigation,
  changeTabs,
  setDestination,
  setSaved,
  transformationDirtyTables,
  setTransformationDirtyTables,
  showModal,
  selectedTab,
  setSelectedTab,
  showUpdateInputsPopup,
  liberatedHoursDirtyTables,
  setLiberatedHoursDirtyTables,
}: transformationSubItems) => {
  const dispatch = useDispatch();
  const BLOCK = "transformation";
  const [tableData, setTableData] = useState<[]>([]);
  const [tableName, setTableName] = useState<String>("");
  const [loader, setLoader] = useState<string>();
  const [expandedRowIds, setExpandedRowIds] = useState<any>([]);
  const inputFormDataState: any = useSelector(
    (state: RootStore) => state.inputForm
  );
  const [updatedTable, setUpdatedTable] = useState<[]>();
  const params: any = useParams();
  let planSubmissionId = inputFormDataState?.metaData?.planSubmissionId
    ? inputFormDataState?.metaData?.planSubmissionId
    : Number(params?.id);
  let subSection = params?.subsection;
  let year = subSection?.match(/\d+/)?.[0];

  const {
    transformationAssumptionsTableData,
    setTransformationAssumptionsTableData,
    setTransformationAssumptionsTableDataCompare,
    setInitialCompletedFields,
  }: any = useAPI();

  useEffect(() => {
    if (tableData && Object.keys(tableData)) {
      tableData?.map((item: any) => {
        delete item.errorList;
      });
    }
    let domArray = document.getElementsByTagName("tr");

    for (let i = 0; i < domArray.length; i++) {
      domArray[i].style.display = "table-row";
    }

    setExpandedRowIds([]);
  }, [tableName]);

  useEffect(() => {
    const fetchData = async () => {
      let tableData: any = {};
      if (year) {
        let data: any = await loadTransformationTablesContext(
          planSubmissionId,
          Number(selectedTab),
          year
        );
        tableData = transformationAssumptionsTableConvertor(
          year,
          selectedTab,
          data
        );
      }

      let fieldCounts = 0;

      tableData[`${year}-${selectedTab}`]?.map((item: any) => {
        Object.keys(item || {})
          ?.filter(Number)
          ?.map((num: any) => {
            !item?.disabled[num] && item[num] !== null && fieldCounts++;
          });
      });

      setInitialCompletedFields(fieldCounts);

      setTransformationAssumptionsTableData(tableData);
      setTransformationAssumptionsTableDataCompare(tableData);
    };
    fetchData();
  }, [tableName]);

  const calculateClosingPosition = (
    openingPosition: number,
    omniaEfficiencyImprovement: number,
    freeEffeciencyImprovement: number,
    levviaEfficiencyImprovement: number,
    strategicResourceCoreAuditTeamShift: number,
    usiShift: number,
    radcShift: number,
    localDCShift: number,
    localCOEShift: number,
    commonConversionMultiplier: number = 1
  ) => {
    //Calculation for USI,RADC,Free Efficiency -  EX: (1-value/100)
    let percentageCalculation =
      (valueConvertor(omniaEfficiencyImprovement)
        ? 1 - valueConvertor(omniaEfficiencyImprovement) / 100
        : 1) *
      valueConvertor(
        valueConvertor(levviaEfficiencyImprovement)
          ? 1 - valueConvertor(levviaEfficiencyImprovement) / 100
          : 1
      ) *
      (valueConvertor(freeEffeciencyImprovement)
        ? 1 - valueConvertor(freeEffeciencyImprovement) / 100
        : 1);

    //sum of shift's
    let totalAddition =
      (valueConvertor(strategicResourceCoreAuditTeamShift)
        ? valueConvertor(strategicResourceCoreAuditTeamShift)
        : 0) +
      (valueConvertor(usiShift) ? valueConvertor(usiShift) : 0) +
      (valueConvertor(radcShift) ? valueConvertor(radcShift) : 0) +
      (valueConvertor(localDCShift) ? valueConvertor(localDCShift) : 0) +
      (valueConvertor(localCOEShift) ? valueConvertor(localCOEShift) : 0);

    let cal =
      valueConvertor(openingPosition) * percentageCalculation +
      totalAddition * valueConvertor(commonConversionMultiplier);

    return cal;
  };

  useEffect(() => {
    if (
      subSection !== conversion &&
      subSection !== liberatedHours &&
      inputFormDataState?.conversionRatiosGetData?.length == 0
    ) {
      dispatch(getConversionRatiosTableData(planSubmissionId));
    }
  }, [tableName]);

  const conversionMultiYearValue = (data: any, currentItem: any) => {
    return (
      data?.length > 0 &&
      data?.[currentItem.deliveryCenterId - 1]?.[currentItem?.year]
    );
  };

  useEffect(() => {
    let usiVal: any = 0;
    let usiShift: any = 0;
    let radcShift: any = 0;
    let localDCShift: any = 0;
    let localCOEShift: any = 0;
    let radcVal: any = 0;
    let localDC: any = 0;
    let localCOE: any = 0;
    let usiValClosing: any = 0;
    let radcValClosing: any = 0;
    let localDCClosing: any = 0;
    let localCOEClosing: any = 0;
    let prevEfficiencyImprovementObj: any = {};
    let updatedTableData: any = tableData?.map((item: any) => {
      if (item[ClosingPositions.Opening_Position] === null) {
        item[ClosingPositions.Opening_Position] = 5000;
      }
      if (item.deliveryCenterId === 1 && item.gradeLevelId === 0) {
        if (item[ClosingPositions.Omnia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["omniaUSIEfficiency"] =
            item[ClosingPositions.Omnia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["omniaUSIEfficiency"] = null;
        }
        if (item[ClosingPositions.Free_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["freeUSIEfficiency"] =
            item[ClosingPositions.Free_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["freeUSIEfficiency"] = null;
        }
        if (item[ClosingPositions.Levvia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["levviaUSIEfficiency"] =
            item[ClosingPositions.Levvia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["levviaUSIEfficiency"] = null;
        }
      }
      if (item.deliveryCenterId === 2 && item.gradeLevelId === 0) {
        if (item[ClosingPositions.Omnia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["omniaRADCEfficiency"] =
            item[ClosingPositions.Omnia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["omniaRADCEfficiency"] = null;
        }
        if (item[ClosingPositions.Free_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["freeRADCEfficiency"] =
            item[ClosingPositions.Free_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["freeRADCEfficiency"] = null;
        }
        if (item[ClosingPositions.Levvia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["levviaRADCEfficiency"] =
            item[ClosingPositions.Levvia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["levviaRADCEfficiency"] = null;
        }
      }
      if (item.deliveryCenterId === 3 && item.gradeLevelId === 0) {
        if (item[ClosingPositions.Omnia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["omniaLOCALDCEfficiency"] =
            item[ClosingPositions.Omnia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["omniaLOACLDCEfficiency"] = null;
        }
        if (item[ClosingPositions.Free_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["freeLOCALDCEfficiency"] =
            item[ClosingPositions.Free_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["freeLOCALDCEfficiency"] = null;
        }
        if (item[ClosingPositions.Levvia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["levviaLOCALDCEfficiency"] =
            item[ClosingPositions.Levvia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["levviaLOCALDCEfficiency"] = null;
        }
      }
      if (item.deliveryCenterId === 4 && item.gradeLevelId === 0) {
        if (item[ClosingPositions.Omnia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["omniaLOCALCOEEfficiency"] =
            item[ClosingPositions.Omnia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["omniaLOACLDCEfficiency"] = null;
        }
        if (item[ClosingPositions.Free_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["freeLOCALCOEEfficiency"] =
            item[ClosingPositions.Free_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["freeLOCALCOEEfficiency"] = null;
        }
        if (item[ClosingPositions.Levvia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["levviaLOCALCOEEfficiency"] =
            item[ClosingPositions.Levvia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["levviaLOCALCOEEfficiency"] = null;
        }
      }
      if (item.deliveryCenterId === 1 && item.gradeLevelId === 0) {
        if (item[ClosingPositions.Omnia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["omniaUSIEfficiency"] =
            item[ClosingPositions.Omnia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["omniaUSIEfficiency"] = null;
        }
        if (item[ClosingPositions.Free_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["freeUSIEfficiency"] =
            item[ClosingPositions.Free_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["freeUSIEfficiency"] = null;
        }
        if (item[ClosingPositions.Levvia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["levviaUSIEfficiency"] =
            item[ClosingPositions.Levvia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["levviaUSIEfficiency"] = null;
        }
      }
      if (item.deliveryCenterId === 2 && item.gradeLevelId === 0) {
        if (item[ClosingPositions.Omnia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["omniaRADCEfficiency"] =
            item[ClosingPositions.Omnia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["omniaRADCEfficiency"] = null;
        }
        if (item[ClosingPositions.Free_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["freeRADCEfficiency"] =
            item[ClosingPositions.Free_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["freeRADCEfficiency"] = null;
        }
        if (item[ClosingPositions.Levvia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["levviaRADCEfficiency"] =
            item[ClosingPositions.Levvia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["levviaRADCEfficiency"] = null;
        }
      }
      if (item.deliveryCenterId === 3 && item.gradeLevelId === 0) {
        if (item[ClosingPositions.Omnia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["omniaLOCALDCEfficiency"] =
            item[ClosingPositions.Omnia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["omniaLOACLDCEfficiency"] = null;
        }
        if (item[ClosingPositions.Free_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["freeLOCALDCEfficiency"] =
            item[ClosingPositions.Free_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["freeLOCALDCEfficiency"] = null;
        }
        if (item[ClosingPositions.Levvia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["levviaLOCALDCEfficiency"] =
            item[ClosingPositions.Levvia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["levviaLOCALDCEfficiency"] = null;
        }
      }
      if (item.deliveryCenterId === 4 && item.gradeLevelId === 0) {
        if (item[ClosingPositions.Omnia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["omniaLOCALCOEEfficiency"] =
            item[ClosingPositions.Omnia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["omniaLOACLDCEfficiency"] = null;
        }
        if (item[ClosingPositions.Free_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["freeLOCALCOEEfficiency"] =
            item[ClosingPositions.Free_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["freeLOCALCOEEfficiency"] = null;
        }
        if (item[ClosingPositions.Levvia_Efficiency_Improvement]) {
          prevEfficiencyImprovementObj["levviaLOCALCOEEfficiency"] =
            item[ClosingPositions.Levvia_Efficiency_Improvement];
        } else {
          prevEfficiencyImprovementObj["levviaLOCALCOEEfficiency"] = null;
        }
      }
      Object.keys(item || {})
        .filter(Number)
        .map((id) => {
          if (
            id === ClosingPositions.Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.USI &&
            item.gradeLevelId !== 0
          ) {
            usiVal += item[id];
          }
          if (
            id === ClosingPositions.Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.RADC &&
            item.gradeLevelId !== 0
          ) {
            radcVal += item[id];
          }
          if (
            id === ClosingPositions.Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_DC &&
            item.gradeLevelId !== 0
          ) {
            localDC += item[id];
          }
          if (
            id === ClosingPositions.Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_COE &&
            item.gradeLevelId !== 0
          ) {
            localCOE += item[id];
          }
          if (
            id === ClosingPositions.USI_SHIFT.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.USI &&
            item.gradeLevelId !== 0
          ) {
            usiShift += valueConvertor(item[id]);
          }
          if (
            id === ClosingPositions.RADC_SHIFT.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.RADC &&
            item.gradeLevelId !== 0
          ) {
            radcShift += valueConvertor(item[id]);
          }
          if (
            id === ClosingPositions.Local_DC_SHIFT.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_DC &&
            item.gradeLevelId !== 0
          ) {
            localDCShift += valueConvertor(item[id]);
          }
          if (
            id === ClosingPositions.Local_COE_SHIFT.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_COE &&
            item.gradeLevelId !== 0
          ) {
            localCOEShift += valueConvertor(item[id]);
          }
        });
      return item;
    });
    updatedTableData?.map((item: any) => {
      Object.keys(item || {})
        .filter(Number)
        .map((id) => {
          if (
            id === ClosingPositions.Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.USI &&
            item.gradeLevelId === 0
          ) {
            item[id] = usiVal;
          }
          if (
            id === ClosingPositions.Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.RADC &&
            item.gradeLevelId === 0
          ) {
            item[id] = radcVal;
          }
          if (
            id === ClosingPositions.Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_DC &&
            item.gradeLevelId === 0
          ) {
            item[id] = localDC;
          }
          if (
            id === ClosingPositions.Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_COE &&
            item.gradeLevelId === 0
          ) {
            item[id] = localCOE;
          }
          let dynamicOmniaEfficiencyValue: any = null;
          let dynamicFreeEfficiencyValue: any = null;
          let dynamicLevviaEfficiencyValue: any = null;
          let commonConversionMultiplier: any = 1;
          if (
            item.deliveryCenterId === 1 &&
            conversionMultiYearValue(
              inputFormDataState?.conversionRatiosGetData,
              item
            )
          ) {
            commonConversionMultiplier = valueConvertor(
              conversionMultiYearValue(
                inputFormDataState?.conversionRatiosGetData,
                item
              )
            );
          }
          if (
            item.deliveryCenterId === 2 &&
            conversionMultiYearValue(
              inputFormDataState?.conversionRatiosGetData,
              item
            )
          ) {
            commonConversionMultiplier = valueConvertor(
              conversionMultiYearValue(
                inputFormDataState?.conversionRatiosGetData,
                item
              )
            );
          }
          if (
            item.deliveryCenterId === 3 &&
            conversionMultiYearValue(
              inputFormDataState?.conversionRatiosGetData,
              item
            )
          ) {
            commonConversionMultiplier = valueConvertor(
              conversionMultiYearValue(
                inputFormDataState?.conversionRatiosGetData,
                item
              )
            );
          }
          if (
            item.deliveryCenterId === 4 &&
            conversionMultiYearValue(
              inputFormDataState?.conversionRatiosGetData,
              item
            )
          ) {
            commonConversionMultiplier = valueConvertor(
              conversionMultiYearValue(
                inputFormDataState?.conversionRatiosGetData,
                item
              )
            );
          }
          if (item.deliveryCenterId === 1 && item.gradeLevelId !== 0) {
            dynamicOmniaEfficiencyValue =
              prevEfficiencyImprovementObj["omniaUSIEfficiency"];
            dynamicFreeEfficiencyValue =
              prevEfficiencyImprovementObj["freeUSIEfficiency"];
            dynamicLevviaEfficiencyValue =
              prevEfficiencyImprovementObj["levviaUSIEfficiency"];
          }
          if (item.deliveryCenterId === 2 && item.gradeLevelId !== 0) {
            dynamicOmniaEfficiencyValue =
              prevEfficiencyImprovementObj["omniaRADCEfficiency"];
            dynamicFreeEfficiencyValue =
              prevEfficiencyImprovementObj["freeRADCEfficiency"];
            dynamicLevviaEfficiencyValue =
              prevEfficiencyImprovementObj["levviaRADCEfficiency"];
          }
          if (item.deliveryCenterId === 3 && item.gradeLevelId !== 0) {
            dynamicOmniaEfficiencyValue =
              prevEfficiencyImprovementObj["omniaLOCALDCEfficiency"];
            dynamicFreeEfficiencyValue =
              prevEfficiencyImprovementObj["freeLOCALDCEfficiency"];
            dynamicLevviaEfficiencyValue =
              prevEfficiencyImprovementObj["levviaLOCALDCEfficiency"];
          }
          if (item.deliveryCenterId === 4 && item.gradeLevelId !== 0) {
            dynamicOmniaEfficiencyValue =
              prevEfficiencyImprovementObj["omniaLOCALCOEEfficiency"];
            dynamicFreeEfficiencyValue =
              prevEfficiencyImprovementObj["freeLOCALCOEEfficiency"];
            dynamicLevviaEfficiencyValue =
              prevEfficiencyImprovementObj["levviaLOCALCOEEfficiency"];
          }
          if (
            id === ClosingPositions.USI_SHIFT.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.USI &&
            item.gradeLevelId === 0
          ) {
            item[id] =
              conversionMultiYearValue(
                inputFormDataState?.conversionRatiosGetData,
                item
              ) && usiShift
                ? valueConvertor(usiShift) *
                  valueConvertor(commonConversionMultiplier)
                : null;
          }
          if (
            id === ClosingPositions.RADC_SHIFT.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.RADC &&
            item.gradeLevelId === 0
          ) {
            item[id] =
              conversionMultiYearValue(
                inputFormDataState?.conversionRatiosGetData,
                item
              ) && radcShift
                ? valueConvertor(radcShift) *
                  valueConvertor(commonConversionMultiplier)
                : null;
          }
          if (
            id === ClosingPositions.Local_DC_SHIFT.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_DC &&
            item.gradeLevelId === 0
          ) {
            item[id] =
              conversionMultiYearValue(
                inputFormDataState?.conversionRatiosGetData,
                item
              ) && localDCShift
                ? valueConvertor(localDCShift) *
                  valueConvertor(commonConversionMultiplier)
                : null;
          }
          if (
            id === ClosingPositions.Local_COE_SHIFT.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_COE &&
            item.gradeLevelId === 0
          ) {
            item[id] =
              conversionMultiYearValue(
                inputFormDataState?.conversionRatiosGetData,
                item
              ) && localCOEShift
                ? valueConvertor(localCOEShift) *
                  valueConvertor(commonConversionMultiplier)
                : null;
          }
          item[ClosingPositions.Closing_Opening_Position] =
            item.gradeLevelId !== 0
              ? calculateClosingPosition(
                  item[ClosingPositions.Opening_Position],
                  dynamicOmniaEfficiencyValue
                    ? dynamicOmniaEfficiencyValue
                    : item[ClosingPositions.Omnia_Efficiency_Improvement],
                  dynamicFreeEfficiencyValue
                    ? dynamicFreeEfficiencyValue
                    : item[ClosingPositions.Free_Efficiency_Improvement],
                  dynamicLevviaEfficiencyValue
                    ? dynamicLevviaEfficiencyValue
                    : item[ClosingPositions.Levvia_Efficiency_Improvement],
                  item[
                    ClosingPositions.Strategic_Resourcing_Core_Audit_Team_Shift
                  ],
                  item[ClosingPositions.USI_SHIFT],
                  item[ClosingPositions.RADC_SHIFT],
                  item[ClosingPositions.Local_DC_SHIFT],
                  item[ClosingPositions.Local_COE_SHIFT],
                  commonConversionMultiplier
                )
              : item[ClosingPositions.Closing_Opening_Position];
        });
    });

    updatedTableData?.map((item: any) => {
      Object.keys(item || {})
        .filter(Number)
        .map((id) => {
          if (
            id === ClosingPositions.Closing_Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.USI &&
            item.gradeLevelId !== 0
          ) {
            usiValClosing += valueConvertor(item[id]);
          }
          if (
            id === ClosingPositions.Closing_Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.RADC &&
            item.gradeLevelId !== 0
          ) {
            radcValClosing += valueConvertor(item[id]);
          }
          if (
            id === ClosingPositions.Closing_Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_DC &&
            item.gradeLevelId !== 0
          ) {
            localDCClosing += valueConvertor(item[id]);
          }
          if (
            id === ClosingPositions.Closing_Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_COE &&
            item.gradeLevelId !== 0
          ) {
            localCOEClosing += valueConvertor(item[id]);
          }
        });
    });

    updatedTableData?.map((item: any) => {
      Object.keys(item || {})
        .filter(Number)
        .map((id) => {
          if (
            id === ClosingPositions.Closing_Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.USI &&
            item.gradeLevelId === 0
          ) {
            item[id] = usiValClosing;
          }
          if (
            id === ClosingPositions.Closing_Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.RADC &&
            item.gradeLevelId === 0
          ) {
            item[id] = radcValClosing;
          }
          if (
            id === ClosingPositions.Closing_Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_DC &&
            item.gradeLevelId === 0
          ) {
            item[id] = localDCClosing;
          }
          if (
            id === ClosingPositions.Closing_Opening_Position.toString() &&
            item.deliveryCenterId === DELIVERY_CENTRE_ID_MAPPING.LOCAL_COE &&
            item.gradeLevelId === 0
          ) {
            item[id] = localCOEClosing;
          }
        });
    });
    setUpdatedTable(updatedTableData);
  }, [tableData, inputFormDataState?.conversionRatiosGetData]);

  useEffect(() => {
    switch (selectedTab) {
      case 1:
        setTableData(
          transformationAssumptionsTableData[`${year}-${selectedTab}`]
        );
        setLoader(TRANSFORMATION_OPTIONS.OMNIA_USI);
        setTableName(`${TRANSFORMATION_MODEL.OMNIA_USI}${year}`);
        break;
      case 2:
        setTableData(
          transformationAssumptionsTableData[`${year}-${selectedTab}`]
        );
        setLoader(TRANSFORMATION_OPTIONS.OMNIA_RADC);
        setTableName(`${TRANSFORMATION_MODEL.OMNIA_RADC}${year}`);
        break;
      case 3:
        setTableData(
          transformationAssumptionsTableData[`${year}-${selectedTab}`]
        );
        setLoader(TRANSFORMATION_OPTIONS.LEVVIA);
        setTableName(`${TRANSFORMATION_MODEL.LEVVIA}${year}`);
        break;
      case 4:
        setTableData(
          transformationAssumptionsTableData[`${year}-${selectedTab}`]
        );
        setLoader(TRANSFORMATION_OPTIONS.TIER_5);
        setTableName(`${TRANSFORMATION_MODEL.TIER_5}${year}`);
        break;
      case 5:
        setTableData(
          transformationAssumptionsTableData[`${year}-${selectedTab}`]
        );
        setLoader(TRANSFORMATION_OPTIONS.ASSURANCE);
        setTableName(`${TRANSFORMATION_MODEL.ASSURANCE}${year}`);
        break;
      case 6:
        setTableData(
          transformationAssumptionsTableData[`${year}-${selectedTab}`]
        );
        setLoader(TRANSFORMATION_OPTIONS.OTHERS);
        setTableName(`${TRANSFORMATION_MODEL.OTHERS}${year}`);
        break;

      default:
        break;
    }
  }, [selectedTab, transformationAssumptionsTableData, year]);

  return (
    <div
      data-test="commonAssumption-component"
      className={`${BLOCK} scroll-add-remove`}
    >
      <div className={`${BLOCK}__divider-header`}>
        <div className={`${BLOCK}__divider-header--title`}>
          {subSection === "conversion" ? (
            <FormattedMessage id={`conversion.ratios`} />
          ) : subSection === "liberatedhours" ? (
            <LiberatedHoursHeader BLOCK={BLOCK} />
          ) : (
            subSection
          )}
        </div>
      </div>
      {subSection !== "conversion" && subSection !== "liberatedhours" && (
        <>
          <TransformationTab
            BLOCK={BLOCK}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            changeTabs={changeTabs}
            setDestination={setDestination}
            setTransformationDirtyTables={setTransformationDirtyTables}
          />
          <TransformationTable
            year={year}
            tableData={updatedTable}
            loader={loader}
            transformationsHeaders={transformationsHeaders}
            setSaved={setSaved}
            tableId={TABLE_MAPPINGS.transformationAssumptions}
            selectedTab={selectedTab}
            transformationDirtyTables={transformationDirtyTables}
            setTransformationDirtyTables={setTransformationDirtyTables}
            tableName={tableName}
            showModal={showModal}
            setExpandedRowIds={setExpandedRowIds}
            expandedRowIds={expandedRowIds}
            showUpdateInputsPopup={showUpdateInputsPopup}
          />
        </>
      )}
      {subSection === "conversion" && (
        <ConvertionRation
          setSaved={setSaved}
          BLOCK={BLOCK}
          planSubmissionId={planSubmissionId}
          tableId={TABLE_MAPPINGS.conversionRatio}
          showModal={showModal}
          showUpdateInputsPopup={showUpdateInputsPopup}
        />
      )}
      {subSection === "liberatedhours" && (
        <LiberatedHours
          showModal={showModal}
          showUpdateInputsPopup={showUpdateInputsPopup}
          liberatedHoursDirtyTables={liberatedHoursDirtyTables}
          setLiberatedHoursDirtyTables={setLiberatedHoursDirtyTables}
        />
      )}
    </div>
  );
};

export default CommonAssumptionsSubItems;
