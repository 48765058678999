import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { headCountInputsPath, headCountTablesList } from "utils/constants";
import { tableNaviagtionEventListener } from "../shared/commons/globalFunctions";
import { setCurrentHeadCountTab } from "services/inputForm/inputFormSlice";
type headCountSubItems = {
  BLOCK: string;
  changeTabs: any;
};

const HeadCountSubItems = ({ BLOCK, changeTabs }: headCountSubItems) => {
  let sectionSelector = "#headcounts";
  let locationName = useLocation().pathname;
  const [subsectionName, setSubSectionName] = useState<any>(null);
  const [initialClick, setInitialClick] = useState<any>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!subsectionName) {
      setSubSectionName(
        locationName.includes(headCountInputsPath)
          ? headCountTablesList[0].refId
          : null
      );
    }
    if (!locationName.includes(headCountInputsPath)) {
      setSubSectionName(null);
      setInitialClick(null);
    }
  }, [locationName]);

  const handleCLick = (tableId: any, index: number) => {
    const element = document.getElementById(tableId);
    setInitialClick(tableId);
    if (element) {
      setSubSectionName(tableId);
      element.scrollIntoView();
    } else if (!locationName.includes(headCountInputsPath)) {
      changeTabs(`${headCountInputsPath}`, index);
    }
  };

  useEffect(() => {
    const main: any = document?.querySelector(sectionSelector);
    let sections = main?.querySelectorAll(`.headcounts__scroll-margin`);
    if (sections && initialClick) {
      setSubSectionName(initialClick);
      const element = document.getElementById(initialClick);
      element?.scrollIntoView();
    }
  });
  useEffect(() => {
    dispatch(setCurrentHeadCountTab(subsectionName));
  }, [subsectionName]);

  const getActiveId = () => {
    const main: any = document?.querySelector(sectionSelector);
    let sections = main?.querySelectorAll(`.headcounts__scroll-margin`);
    let activeId = 0;
    sections?.forEach((section: any) => {
      const rect = section?.getBoundingClientRect();
      if (
        rect.top + (window.innerHeight - window.innerHeight / 1.5) <
          window.innerHeight &&
        rect.bottom >= 0
      ) {
        setInitialClick(null);
        activeId = Number(section.id);
      }
    });
    setSubSectionName(activeId);
  };
  useEffect(() => {
    const element = document?.querySelector(sectionSelector);
    tableNaviagtionEventListener(element, getActiveId);
    return () => {
      tableNaviagtionEventListener(element, getActiveId, false);
    };
  }, [locationName]);

  return (
    <div className={`${BLOCK}__container`} data-test="headCount-subItem-test">
      {headCountTablesList.map((item: any, index: number) => {
        return (
          <div
            key={`navigation-item-${index}`}
            className={`${BLOCK}__navigation-panel--item`}
          >
            <span
              data-test="headCount-subItem-onClick"
              className={`${BLOCK}__sub-items`}
              onClick={() => {
                handleCLick(item.refId, index);
              }}
              style={{
                color: subsectionName === item.refId ? "#00A3E0" : "black",
              }}
            >
              {item.tableName}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default HeadCountSubItems;
