import React, { useEffect, useState, useRef } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RevenuePLTable from "./revenue&PL-table";
import AnnualInflationTable from "./annual_inflation-table";
import PandL_CostTable from "./P&L_cost-table";
import NetRevenueTable from "./netRevenue-table";
import LossNetRevenueTable from "./lossNetRevenue";
import ExitProgrammePhaceTable from "./exit_programme-table";
import FeePerExitHoursTable from "./feePerExitHours-table";
import ExitPhasingTable from "./exit_phasing-table";
import RevenuePriceIncreaseTable from "./price_increase-table";
import IncreaseInHoursTable from "./increaseInHours-table";
import {
  TABLE_MAPPINGS,
  CALCULATE_ENGINE,
  GENERAL_INPUT_ERROR,
  revenueAndPlTablesList,
} from "utils/constants";
import { useAPI } from "../updatedRevenuePL/revenuePnlContextProvider";
import UpdateInputsModal from "../inputform/updateMultipleInputsModal";
import {
  getStatusId,
  calculateEngineApi,
  submitRevenueAndPlTableData,
  submitProfitsAndLossesData,
  submitWinsNetRevenueData,
  submitLossesNetRevenueData,
  submitExitProgrammePhasingData,
  submitFeePerExitHours,
  submitExitPhasingData,
  submitRevenuePriceIncreasesData,
  submitIncreaseInHoursgData,
  setInputFormProgressDataLocal,
  getProgressPercentage,
  submitAnnualInflationData,
} from "services/inputForm/inputFormSlice";
import { addToastMessage } from "services/common/commonsSlice";
import { cleanTableData } from "components/shared/commons/globalFunctions";

const RevenueAndPLTab = ({
  toggleNavigation,
  setSaved,
  setSavingData,
  savingData,
  revenueApiCall,
  setRevenuaApiCall,
  revenuePLTableProgress,
  totalPercentage,
  revenueDirtyTables,
  setRevenueDirtyTables,
}: any) => {
  const BLOCK = "headcounts";
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const dispatch = useDispatch();
  const params: any = useParams();
  let planSubmissionId = inputFormDataState?.metaData?.planSubmissionId
    ? inputFormDataState?.metaData?.planSubmissionId
    : Number(params?.id);

  const [showUpdateInputsPopup, setShowUpdateInputsPopup] =
    useState<boolean>(false);

  const [revenueData, setRevenueData] = useState<any>({});

  const {
    revenuePnlData,
    revenuePriceIncrease,
    profitsAndLossesTableData,
    winsNetRevenueData,
    lossesNetRevenueData,
    feePerExitData,
    exitProgrammePhasesData,
    increaseInHoursData,
    exitPhasingData,
    winsNetRevenueCompareData,
    exitPhasingCompareTableData,
    revenuePriceIncreaseCompare,
    lossesNetRevenueCompareTableData,
    setCompareTableData,
    compareTableData,
    setRevenuePnlData,
    setProfitsAndLossesTableData,
    setWinsNetRevenueTableData,
    setLossesNetRevenueTableData,
    setExitProgrammePhasesTableData,
    setFeePerExitTableData,
    setExitPhasingTableData,
    setRevenuePriceIncrease,
    setIncreaseInHoursTableData,
    setMultipleErrorsRev,
    multipleErrorsRev,
    revenueTableIdList,
    annualInflationData,
    setAnnualInflationTableData,
    annualInflationCompareTableData,
  }: any = useAPI();

  useEffect(() => {
    setRevenueData({
      revenuePnlData,
      revenuePriceIncrease,
      profitsAndLossesTableData,
      winsNetRevenueData,
      lossesNetRevenueData,
      feePerExitData,
      exitProgrammePhasesData,
      increaseInHoursData,
      exitPhasingData,
      annualInflationData,
    });
  }, [
    revenuePnlData,
    revenuePriceIncrease,
    profitsAndLossesTableData,
    winsNetRevenueData,
    lossesNetRevenueData,
    feePerExitData,
    exitProgrammePhasesData,
    increaseInHoursData,
    exitPhasingData,
    winsNetRevenueCompareData,
    exitPhasingCompareTableData,
    revenuePriceIncreaseCompare,
    lossesNetRevenueCompareTableData,
    annualInflationData,
    annualInflationCompareTableData,
  ]);

  useEffect(() => {
    if (multipleErrorsRev) {
      dispatch(
        addToastMessage({
          description: <>{GENERAL_INPUT_ERROR}</>,
        })
      );
      setMultipleErrorsRev(false);
    }
  }, [multipleErrorsRev]);

  useEffect(() => {
    let inputFormProgressData =
      inputFormDataState?.InputFormProgressData?.fieldCountDetails;

    let revenueCompletedFields = 0;
    let profitAndLossCompletedFields = 0;
    let winsNetRevCompletedFields = 0;
    let lossesNetRevCompletedFields = 0;
    let exitProgrammePhasesCompletedFields = 0;
    let feePerExitCompletedFields = 0;
    let exitPhasingCompletedFields = 0;
    let revenuePriceIncreaseCompletedFields = 0;
    let increaseInHoursCompletedFields = 0;
    let annualInflationCompletedFields = 0;

    revenuePnlData?.map((row: any) => {
      if (row.fee !== null) revenueCompletedFields++;
      if (row.hours !== null) revenueCompletedFields++;
    });

    profitsAndLossesTableData?.map((item: any) => {
      Object.keys(item || {})
        ?.filter(Number)
        ?.map((row: any) => {
          if (item[row] !== null) profitAndLossCompletedFields++;
        });
    });

    winsNetRevenueData?.map((item: any) => {
      Object.keys(item || {})
        ?.filter(Number)
        ?.map((row: any) => {
          if (item[row] !== null) winsNetRevCompletedFields++;
        });
    });
    lossesNetRevenueData?.map((item: any) => {
      Object.keys(item || {})
        ?.filter(Number)
        ?.map((row: any) => {
          if (item[row] !== null) lossesNetRevCompletedFields++;
        });
    });

    exitProgrammePhasesData?.map((row: any) => {
      if (row.totalHoursofWork !== null) exitProgrammePhasesCompletedFields++;
    });
    feePerExitData?.map((row: any) => {
      if (row.value !== null) feePerExitCompletedFields++;
    });
    exitPhasingData?.map((item: any) => {
      Object.keys(item || {})
        ?.filter(Number)
        ?.map((row: any) => {
          if (item[row] !== null) exitPhasingCompletedFields++;
        });
    });
    revenuePriceIncrease?.map((item: any) => {
      Object.keys(item || {})
        ?.filter(Number)
        ?.map((row: any) => {
          if (item[row] !== null) revenuePriceIncreaseCompletedFields++;
        });
    });
    increaseInHoursData?.map((row: any) => {
      if (row.amount !== null) increaseInHoursCompletedFields++;
    });

    annualInflationData?.map((item: any) => {
      Object.keys(item || {})
        ?.filter(Number)
        ?.map((row: any) => {
          if (item[row] !== null) annualInflationCompletedFields++;
        });
    });

    let updatedData: any = [];

    if (inputFormProgressData?.length) {
      updatedData = inputFormProgressData?.map((item: any) => {
        switch (item.inputFormMasterTableId) {
          case TABLE_MAPPINGS.fy23Rev:
            return { ...item, completedFields: revenueCompletedFields };
          case TABLE_MAPPINGS.plCosts:
            return profitsAndLossesTableData &&
              profitsAndLossesTableData?.length
              ? {
                  ...item,
                  completedFields: profitAndLossCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.winsNetRev:
            return winsNetRevenueData && winsNetRevenueData?.length
              ? {
                  ...item,
                  completedFields: winsNetRevCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.lossesNetRev:
            return lossesNetRevenueData && lossesNetRevenueData?.length
              ? {
                  ...item,
                  completedFields: lossesNetRevCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.exitProgramme:
            return exitProgrammePhasesData && exitProgrammePhasesData?.length
              ? {
                  ...item,
                  completedFields: exitProgrammePhasesCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.feePerExit:
            return feePerExitData && feePerExitData?.length
              ? {
                  ...item,
                  completedFields: feePerExitCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.exitPhasing:
            return exitPhasingData && exitPhasingData?.length
              ? {
                  ...item,
                  completedFields: exitPhasingCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.revPriceIncrease:
            return revenuePriceIncrease && revenuePriceIncrease?.length
              ? {
                  ...item,
                  completedFields: revenuePriceIncreaseCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.increaseHoursDeliver:
            return increaseInHoursData && increaseInHoursData?.length
              ? {
                  ...item,
                  completedFields: increaseInHoursCompletedFields,
                }
              : item;
          case TABLE_MAPPINGS.annualinflation:
            return annualInflationData && annualInflationData?.length
              ? {
                  ...item,
                  completedFields: annualInflationCompletedFields,
                }
              : item;

          default:
            return item;
        }
      });
    }

    let dataObj = {
      fieldCountDetails: updatedData,
      percentCompletion:
        inputFormDataState?.InputFormProgressData?.percentCompletion,
      planSubmissionId:
        inputFormDataState?.InputFormProgressData?.planSubmissionId,
    };
    dispatch(setInputFormProgressDataLocal(dataObj));
  }, [
    inputFormDataState?.InputFormProgressData,
    revenuePnlData,
    revenuePriceIncrease,
    profitsAndLossesTableData,
    winsNetRevenueData,
    lossesNetRevenueData,
    feePerExitData,
    exitProgrammePhasesData,
    increaseInHoursData,
    exitPhasingData,
    annualInflationData,
  ]);

  const rearrangingArrayWithFourYearsPostCall = (arr: any) => {
    let dataArray: any = [];

    arr.map((item: any) => {
      Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          dataArray.push({
            exitPhasingId: item.exitPhasingId,
            planSubmissionId: item.planSubmissionId,
            productSegment: item.productSegment,
            productSegmentId: item.productSegmentId,
            value: item[id],
            year: Number(id),
          });
        });
    });

    return dataArray;
  };

  useEffect(() => {
    if (savingData && revenueApiCall) {
      let promiseArray: any = [];
      revenueDirtyTables.map((item: any) => {
        switch (item) {
          case TABLE_MAPPINGS.fy23Rev:
            promiseArray.push(
              submitRevenueAndPlTableData(
                cleanTableData(revenuePnlData),
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              revenuePnlData: cleanTableData(revenuePnlData),
            }));
            break;
          case TABLE_MAPPINGS.plCosts:
            promiseArray.push(
              submitProfitsAndLossesData(
                cleanTableData(profitsAndLossesTableData),
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              profitsAndLosses: cleanTableData(profitsAndLossesTableData),
            }));
            break;
          case TABLE_MAPPINGS.winsNetRev:
            promiseArray.push(
              submitWinsNetRevenueData(
                rearrangingArrayWithFourYearsPostCall(winsNetRevenueData),
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              winsNetRevenueData: winsNetRevenueData,
            }));
            break;
          case TABLE_MAPPINGS.lossesNetRev:
            promiseArray.push(
              submitLossesNetRevenueData(
                rearrangingArrayWithFourYearsPostCall(lossesNetRevenueData),
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              lossesNetRevenueData: lossesNetRevenueData,
            }));
            break;
            break;
          case TABLE_MAPPINGS.exitProgramme:
            promiseArray.push(
              submitExitProgrammePhasingData(
                cleanTableData(exitProgrammePhasesData),
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              exitProgrammePhasesData: cleanTableData(exitProgrammePhasesData),
            }));
            break;
          case TABLE_MAPPINGS.revPriceIncrease:
            promiseArray.push(
              submitRevenuePriceIncreasesData(
                rearrangingArrayWithFourYearsPostCall(revenuePriceIncrease),
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              revenuePriceIncrease: revenuePriceIncrease,
            }));
            break;
          case TABLE_MAPPINGS.exitPhasing:
            promiseArray.push(
              submitExitPhasingData(
                rearrangingArrayWithFourYearsPostCall(exitPhasingData),
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              exitPhasingData: exitPhasingData,
            }));
            break;
          case TABLE_MAPPINGS.feePerExit:
            promiseArray.push(
              submitFeePerExitHours(
                cleanTableData(feePerExitData),
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              feePerExitTableData: cleanTableData(feePerExitData),
            }));
            break;
          case TABLE_MAPPINGS.increaseHoursDeliver:
            promiseArray.push(
              submitIncreaseInHoursgData(
                cleanTableData(increaseInHoursData),
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              increaseInHoursData: cleanTableData(increaseInHoursData),
            }));
            break;
          case TABLE_MAPPINGS.annualinflation:
            promiseArray.push(
              submitAnnualInflationData(
                rearrangingArrayWithFourYearsPostCall(annualInflationData),
                planSubmissionId
              )
            );
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              annualInflationData: annualInflationData,
            }));
            break;
          default:
            break;
        }
      });

      Promise.all([...promiseArray])
        .then(() => {
          setSavingData(false);
          setRevenuaApiCall(false);
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="form.save.toaster.message" />,
            })
          );
          if (promiseArray.length > 0) {
            totalPercentage === 100 && dispatch(getStatusId(planSubmissionId));
            revenuePLTableProgress == 100 &&
              calculateEngineApi(
                planSubmissionId,
                CALCULATE_ENGINE.REVENUE_PNL
              );
            dispatch(getProgressPercentage(planSubmissionId));
          }

          setRevenueDirtyTables([]);
        })
        .catch(() => {
          setSavingData(false);
          setRevenuaApiCall(false);
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="form.error.toaster.message" />,
            })
          );
          setRevenueDirtyTables([]);
        });
    }
  }, [savingData, revenueApiCall]);

  return (
    <>
      <div
        className={`${BLOCK}`}
        style={{ width: !toggleNavigation ? "100%" : "75%" }}
        data-test="component-RevenueAndPLTab"
        id="revenuepl"
      >
        <div className="scroll-add-remove">
          <div className={`${BLOCK}__divider-header`}>
            <span className={`${BLOCK}__divider-header--title`}>
              <FormattedMessage id={"revenue-pl.main.title"} />
            </span>
          </div>
          <div
            className={`${BLOCK}__scroll-margin`}
            id={revenueAndPlTablesList[0].refId}
          >
            <RevenuePLTable
              setSaved={setSaved}
              currency={
                inputFormDataState?.configurationData?.currency?.currencyCode
              }
              subTitle={
                inputFormDataState?.metaData?.baseYear
                  ? "FY" +
                    `${inputFormDataState?.metaData?.baseYear} ` +
                    "Revenue"
                  : "FY__Revenue"
              }
              baseYear={
                inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear
                  : ""
              }
              tableId={TABLE_MAPPINGS.fy23Rev}
              showModal={setShowUpdateInputsPopup}
              subHeadingBlock={BLOCK}
              showCurrencyHeader={false}
              showUpdateInputsPopup={showUpdateInputsPopup}
              revenueDirtyTables={revenueDirtyTables}
              setRevenueDirtyTables={setRevenueDirtyTables}
              tableData={revenuePnlData}
              compareTableData={compareTableData?.revenuePnlData}
              setTableData={setRevenuePnlData}
              revenueTableIdList={revenueTableIdList}
              revenueData={revenueData}
            />
          </div>
          <div
            className={`${BLOCK}__scroll-margin`}
            id={revenueAndPlTablesList[1].refId}
          >
            <AnnualInflationTable
              subTitle={`revenue-pl.annual.inflation.main.subheader`}
              tableId={TABLE_MAPPINGS.annualinflation}
              setSaved={setSaved}
              baseYear={
                inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear
                  : ""
              }
              showModal={setShowUpdateInputsPopup}
              subHeadingBlock={BLOCK}
              showCurrencyHeader={false}
              showUpdateInputsPopup={showUpdateInputsPopup}
              revenueDirtyTables={revenueDirtyTables}
              setRevenueDirtyTables={setRevenueDirtyTables}
              tableData={annualInflationData}
              compareTableData={compareTableData?.annualInflationData}
              setTableData={setAnnualInflationTableData}
              revenueTableIdList={revenueTableIdList}
              revenueData={revenueData}
            />
          </div>
          <div
            className={`${BLOCK}__scroll-margin`}
            id={revenueAndPlTablesList[2].refId}
          >
            <PandL_CostTable
              subTitle={`revenue-pl.staff.table.pl.main.title`}
              tableId={TABLE_MAPPINGS.plCosts}
              setSaved={setSaved}
              baseYear={
                inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear
                  : ""
              }
              currency={
                inputFormDataState?.configurationData?.currency?.currencyCode
              }
              showModal={setShowUpdateInputsPopup}
              subHeadingBlock={BLOCK}
              showCurrencyHeader={true}
              showUpdateInputsPopup={showUpdateInputsPopup}
              revenueDirtyTables={revenueDirtyTables}
              setRevenueDirtyTables={setRevenueDirtyTables}
              tableData={profitsAndLossesTableData}
              compareTableData={compareTableData?.profitsAndLosses}
              setTableData={setProfitsAndLossesTableData}
              revenueTableIdList={revenueTableIdList}
              revenueData={revenueData}
            />
          </div>
          <div
            className={`${BLOCK}__scroll-margin`}
            id={revenueAndPlTablesList[3].refId}
          >
            <NetRevenueTable
              subTitle={`revenue-pl.staff.table.net.main.title`}
              tableId={TABLE_MAPPINGS.winsNetRev}
              setSaved={setSaved}
              baseYear={
                inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear
                  : ""
              }
              currency={
                inputFormDataState?.configurationData?.currency?.currencyCode
              }
              showModal={setShowUpdateInputsPopup}
              subHeadingBlock={BLOCK}
              showCurrencyHeader={true}
              showUpdateInputsPopup={showUpdateInputsPopup}
              revenueDirtyTables={revenueDirtyTables}
              setRevenueDirtyTables={setRevenueDirtyTables}
              tableData={winsNetRevenueData}
              compareTableData={compareTableData?.winsNetRevenueData}
              setTableData={setWinsNetRevenueTableData}
              revenueTableIdList={revenueTableIdList}
              revenueData={revenueData}
            />
          </div>
          <div
            className={`${BLOCK}__scroll-margin`}
            id={revenueAndPlTablesList[4].refId}
          >
            <LossNetRevenueTable
              subTitle={`revenue-pl.loss.net.main.header`}
              tableId={TABLE_MAPPINGS.lossesNetRev}
              setSaved={setSaved}
              baseYear={
                inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear
                  : ""
              }
              currency={
                inputFormDataState?.configurationData?.currency?.currencyCode
              }
              showModal={setShowUpdateInputsPopup}
              subHeadingBlock={BLOCK}
              showCurrencyHeader={true}
              showUpdateInputsPopup={showUpdateInputsPopup}
              revenueDirtyTables={revenueDirtyTables}
              setRevenueDirtyTables={setRevenueDirtyTables}
              tableData={lossesNetRevenueData}
              compareTableData={compareTableData?.lossesNetRevenueData}
              setTableData={setLossesNetRevenueTableData}
              revenueTableIdList={revenueTableIdList}
              revenueData={revenueData}
            />
          </div>
          <div
            className={`${BLOCK}__scroll-margin`}
            id={revenueAndPlTablesList[5].refId}
          >
            <ExitProgrammePhaceTable
              subTitle={`revenue-pl.exit.programme.main.header`}
              tableId={TABLE_MAPPINGS.exitProgramme}
              setSaved={setSaved}
              baseYear={
                inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear
                  : ""
              }
              currency={
                inputFormDataState?.configurationData?.currency?.currencyCode
              }
              subHeadingBlock={BLOCK}
              showCurrencyHeader={false}
              revenueDirtyTables={revenueDirtyTables}
              setRevenueDirtyTables={setRevenueDirtyTables}
              tableData={exitProgrammePhasesData}
              compareTableData={compareTableData?.exitProgrammePhasesData}
              setTableData={setExitProgrammePhasesTableData}
              revenueTableIdList={revenueTableIdList}
              revenueData={revenueData}
            />
          </div>
          <div
            className={`${BLOCK}__scroll-margin`}
            id={revenueAndPlTablesList[6].refId}
          >
            <FeePerExitHoursTable
              subTitle={`revenue-pl.fee.per.exit.hors.main.header`}
              tableId={TABLE_MAPPINGS.feePerExit}
              setSaved={setSaved}
              baseYear={
                inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear
                  : ""
              }
              currency={
                inputFormDataState?.configurationData?.currency?.currencyCode
              }
              showModal={setShowUpdateInputsPopup}
              subHeadingBlock={BLOCK}
              showCurrencyHeader={true}
              showUpdateInputsPopup={showUpdateInputsPopup}
              revenueDirtyTables={revenueDirtyTables}
              setRevenueDirtyTables={setRevenueDirtyTables}
              tableData={feePerExitData}
              compareTableData={compareTableData?.feePerExitTableData}
              setTableData={setFeePerExitTableData}
              displayMillionSymbol={false}
              revenueTableIdList={revenueTableIdList}
              revenueData={revenueData}
            />
          </div>
          <div
            className={`${BLOCK}__scroll-margin`}
            id={revenueAndPlTablesList[7].refId}
          >
            <ExitPhasingTable
              subTitle={`revenue-pl.exit.phasing.main.header`}
              tableId={TABLE_MAPPINGS.exitPhasing}
              setSaved={setSaved}
              baseYear={
                inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear
                  : ""
              }
              currency={
                inputFormDataState?.configurationData?.currency?.currencyCode
              }
              showModal={setShowUpdateInputsPopup}
              subHeadingBlock={BLOCK}
              showCurrencyHeader={false}
              showUpdateInputsPopup={showUpdateInputsPopup}
              revenueDirtyTables={revenueDirtyTables}
              setRevenueDirtyTables={setRevenueDirtyTables}
              tableData={exitPhasingData}
              compareTableData={compareTableData?.exitPhasingData}
              setTableData={setExitPhasingTableData}
              revenueTableIdList={revenueTableIdList}
              revenueData={revenueData}
            />
          </div>
          <div
            className={`${BLOCK}__scroll-margin`}
            id={revenueAndPlTablesList[8].refId}
          >
            <RevenuePriceIncreaseTable
              subTitle={`revenue-pl.price.increase.main.header`}
              tableId={TABLE_MAPPINGS.revPriceIncrease}
              setSaved={setSaved}
              baseYear={
                inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear
                  : ""
              }
              currency={
                inputFormDataState?.configurationData?.currency?.currencyCode
              }
              showModal={setShowUpdateInputsPopup}
              subHeadingBlock={BLOCK}
              showCurrencyHeader={false}
              showUpdateInputsPopup={showUpdateInputsPopup}
              revenueDirtyTables={revenueDirtyTables}
              setRevenueDirtyTables={setRevenueDirtyTables}
              tableData={revenuePriceIncrease}
              compareTableData={compareTableData?.revenuePriceIncrease}
              setTableData={setRevenuePriceIncrease}
              revenueTableIdList={revenueTableIdList}
              revenueData={revenueData}
            />
          </div>
          <div
            className={`${BLOCK}__scroll-margin`}
            id={revenueAndPlTablesList[9].refId}
          >
            <IncreaseInHoursTable
              subTitle={`revenue-pl.increase.hours.main.header`}
              tableId={TABLE_MAPPINGS.increaseHoursDeliver}
              setSaved={setSaved}
              baseYear={
                inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear
                  : ""
              }
              currency={
                inputFormDataState?.configurationData?.currency?.currencyCode
              }
              showModal={setShowUpdateInputsPopup}
              subHeadingBlock={BLOCK}
              showCurrencyHeader={false}
              showUpdateInputsPopup={showUpdateInputsPopup}
              revenueDirtyTables={revenueDirtyTables}
              setRevenueDirtyTables={setRevenueDirtyTables}
              tableData={increaseInHoursData}
              compareTableData={compareTableData?.increaseInHoursData}
              setTableData={setIncreaseInHoursTableData}
              revenueTableIdList={revenueTableIdList}
              revenueData={revenueData}
            />
          </div>
        </div>
      </div>
      {showUpdateInputsPopup && inputFormDataState.selectedCells.length > 0 && (
        <UpdateInputsModal
          setSaved={setSaved}
          showModal={setShowUpdateInputsPopup}
          selectedCells={inputFormDataState.selectedCells}
          dirtyTables={revenueDirtyTables}
          setDirtyTables={setRevenueDirtyTables}
        />
      )}
    </>
  );
};

export default RevenueAndPLTab;
