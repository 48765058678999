import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { loadNetMargin } from "services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { ConsolidationsKeyInitial } from "utils/constants";

type NetMargin = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const NetMarginTable = ({ geographies, currencyCode, baseYear }: NetMargin) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        loadNetMargin({ countryCodes: geographies, currencyCode, baseYear })
      );
    }
  }, [geographies, currencyCode, baseYear]);
  return (
    <div data-test="netMargin-component">
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.netMarginData?.consolidatedProfitAndLossDetails
        }
        tableHeader={consolidationData?.netMarginData?.subTotalProfitAndLoss}
        mainTableHeader="consolidation.technical.net.margin"
        subConsolidationTableData={
          consolidationData?.netMarginData?.subConsolidatedProfitAndLossDetails
        }
        keyInitial={ConsolidationsKeyInitial.NET_MARGIN}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return item === VISUAL_DASHBOARD_OPTIONS.NET_MARGIN_LOADING;
          })?.length > 0
        }
      />
    </div>
  );
};

export default NetMarginTable;
