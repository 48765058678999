export const handleBlurChange = (e: any) => {
  let value: string;
  if (e.target.value.split(",").length > 0) {
    value = e.target.value.split(",").join("");
  } else {
    value = e.target.value;
  }
  const isFloat = (value: any) => {
    var er = /^-?[0-9]+$/;
    return !er.test(value);
  };
  let subjectOf0 = /^0*$/g;
  let isAllZeroes = isFloat(value) && subjectOf0.test(value?.split(".")[1]);

  let floatLength: any = isFloat(value) && value?.split(".")[1]?.length;

  let toFixedVal: any;

  if (floatLength > 5 && !isAllZeroes) {
    toFixedVal = 5;
  } else if (floatLength <= 5 && !isAllZeroes) {
    toFixedVal = floatLength;
  } else {
    toFixedVal = 0;
  }
  let val: any;
  val = parseFloat(value);
  let inputVal = !isNaN(val) ? Number(val) : "";
  return (e.target.value = inputVal.toLocaleString("us-EN", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 5,
  }));
};

export const inputValueReturn = (
  inpuVal: any,
  focus: any,
  roundNumbers: any = 0,
  isDisabled: boolean = false,
) => {
  let val: any =
    /e/.test(inpuVal) ||
    (inpuVal !== null &&
      inpuVal !== undefined &&
      inpuVal !== "" &&
      /\b0\.00000[0-9]/.test(inpuVal) &&
      !focus)
      ? "0"
      : /^-0(\.0{0,10})?$/.test(inpuVal)
      ? inpuVal
      : 1 / inpuVal === -Infinity
      ? "-0"
      : inpuVal !== null && inpuVal !== undefined && inpuVal !== "" && !focus
      ? inpuVal.toLocaleString("us-EN", {
          minimumFractionDigits: 0,
          maximumFractionDigits: roundNumbers ? roundNumbers : 5,
        })
      : inpuVal !== null && inpuVal !== undefined && inpuVal !== "" && focus
      ? inpuVal
      : "";

    // to handle -0 values after roundoff
    if(/^-0(\.0{0,10})?$/.test(val) && isDisabled){ // not using === as typeOf(val) is string. The regular expression checks for -0, -0.0, -0.00, -0.000 and so on till -0.0000000000
      val = 0
    }

  return val;
};

export const transformationAssumptionsTableConvertor = (
  year: any,
  productSegmentId: any,
  data: any
) => {
  let dataObj: any = {};
  if (dataObj[`${year}-${productSegmentId}`]) {
    dataObj[`${year}-${productSegmentId}`] = {
      ...dataObj[`${year}-${productSegmentId}`],
      data,
    };
  } else {
    dataObj[`${year}-${productSegmentId}`] = data;
  }

  return dataObj;
};

export const transformationAssumptionsTableConvertorPromise = (
  data: any,
  tableData: any
) => {
  data?.map((item: any) => {
    item?.map((taData: any) => {
      tableData[`${taData?.year}-${taData?.productSegmentId}`] = item;
    });
  });

  return tableData;
};

export const valueConvertor = (val: any) => {
  return ["-", ".", "-."].includes(val)
    ? 0
    : typeof val === "string"
    ? Number(val.split(",").join(""))
    : isNaN(Number(val))
    ? 0
    : Number(val);
};

export const isDecimalsZero = (value: string) => {
  let subjectOf0 = /[0]/g;
  return subjectOf0.test(value?.toString()?.split(".")[1]);
};

export const convertCommaSeperator = (val: any) => {
  let updatedVal = "";

  for (let i = 0; i < val?.toString()?.split(",").length; i++) {
    updatedVal += val?.toString()?.split(",")[i];
  }

  return val === "-" ? val : Number(updatedVal);
};

export const cleanTableData = (data: any) => {
  let tableVals: any = [];

  data.map((item: any) => {
    const newObject: any = {};

    for (const key in item) {
      if (!key.includes("error")) {
        newObject[key] = item[key];
      }
    }

    tableVals.push(newObject);
  });

  return tableVals;
};

export const tableNaviagtionEventListener = (
  element: any,
  callBackFunction: Function,
  add: boolean = true
) => {
  if (add) {
    element?.addEventListener("touchstart", callBackFunction);
    element?.addEventListener("touchmove", callBackFunction);
    element?.addEventListener("touchend", callBackFunction);
    element?.addEventListener("wheel", callBackFunction);
    element?.addEventListener("keydown", callBackFunction);
    element?.addEventListener("mousedown", callBackFunction);
    element?.addEventListener("mouseup", callBackFunction);
  } else {
    element?.removeEventListener("touchstart", callBackFunction);
    element?.removeEventListener("touchmove", callBackFunction);
    element?.removeEventListener("touchend", callBackFunction);
    element?.removeEventListener("wheel", callBackFunction);
    element?.removeEventListener("keydown", callBackFunction);
    element?.removeEventListener("mousedown", callBackFunction);
    element?.removeEventListener("mouseup", callBackFunction);
  }
};

const getCurrentCentury = () => {
  const currentYear = new Date().getFullYear();
  const currentCentury = Math.floor(currentYear / 100) * 100;

  return currentCentury;
};

export const completeYear = (lastTwoDigits: any) => {
  const currentCentury = getCurrentCentury();

  const fullYear = currentCentury + lastTwoDigits;

  return fullYear;
};
