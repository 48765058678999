import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "./guidanceNotes.api";

const initialState = {
  loading: false,
  guidanceNotesList: null,
};

const guidanceNotesSlice = createSlice({
  name: "guidanceNotes",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getGuidanceNotesList.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(
      getGuidanceNotesList.fulfilled,
      (state: any, action: any) => {
        state.loading = false;
        state.guidanceNotesList = action.payload ? action.payload : null;
      }
    );
    builder.addCase(
      getGuidanceNotesList.rejected,
      (state: any, action: any) => {
        state.loading = false;
        state.guidanceNotesList = action.payload;
      }
    );
  },
});

export const getGuidanceNotesList = createAsyncThunk(
  "visualDashboard/fetchGuidenceNotes",
  async () => {
    try {
      const res = await api.getGuidanceNotesInfo();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export default guidanceNotesSlice.reducer;
