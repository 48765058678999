import { useEffect, useState, useRef } from "react";
import { TableRowItem } from "./table";
import classNames from "classnames";
import TableCell from "./table-cell";
import Icon from "../../shared/icon/icon";
import ReactTooltip from "react-tooltip";

const TableRow = ({
  id,
  columnItems,
  rowSelected,
  isSelected,
  className,
  updateSelected,
  showItems,
  setShowItems,
  filterContent,
  onClick,
  actions,
  isGlobalOrMFAdmin,
  selection,
  tableId,
  indexKey,
  revenue_Pl_SubTitle,
}: TableRowItem) => {
  const BLOCK = "Table";
  const [preparerList, setPreparerList] = useState<any>("");
  const [actionContainerZIndex, setActionContainerZIndex] = useState<number>(0);
  const ref: any = useRef(null);

  const [actionContainerWidth, setActionContainerWidth] = useState<any>(0);

  useEffect(() => {
    ref.current
      ? setActionContainerWidth(ref.current.offsetWidth)
      : setActionContainerWidth(0);
  }, [ref.current, rowSelected]);

  const renderActions = () => {
    return actions.map((action: any) => (
      <button
        data-testid={`${action.id}-search-button`}
        key={action.name}
        className={`${className}__${action.name}`}
        onClick={(e: any) => {
          action.onClick();
        }}
        onMouseEnter={() => {
          setActionContainerZIndex(2);
        }}
        onMouseLeave={() => {
          setActionContainerZIndex(0);
        }}
      >
        <ReactTooltip
          id={`tooltip-${action.id}`}
          effect="solid"
          className={
            action.tooltipWidth
              ? `${BLOCK}__actions-tooltip ${BLOCK}__actions-tooltip-common`
              : `${BLOCK}__actions-tooltip-common`
          }
        />
        <div data-tip={action.tooltipText} data-for={`tooltip-${action.id}`}>
          <Icon name={action.iconName} height={25} width={25} />
        </div>
      </button>
    ));
  };

  return (
    <>
      <tr
        className={classNames(`${BLOCK}__row`, {
          [`${className}`]: className,
          [`${className}--selected`]: rowSelected,
          [`__indexKey`]: indexKey,
        })}
        onClick={(e) => !preparerList && onClick?.(e)}
        id={id}
      >
        {columnItems &&
          columnItems.map((item, index) => (
            <TableCell
              key={`${id}-${index}`}
              keyIdentifier={`${tableId}#${item?.id ? item?.id : id}#${
                item?.customIndex ? item?.customIndex : index
              }`}
              {...item}
              id={id}
              updateSelected={updateSelected}
              isSelected={item.checkbox ? isSelected : rowSelected}
              setShowNotes={setShowItems}
              filterContent={filterContent}
              selection={selection}
              preparerList={preparerList}
              setPreparerList={setPreparerList}
              indexKey={indexKey}
              revenue_Pl_SubTitle={revenue_Pl_SubTitle}
            />
          ))}
        {rowSelected && actions && (
          <td
            ref={ref}
            className={classNames(`${className}--actions--container`, {
              [`${className}--actions--container--globalAdmin`]:
                isGlobalOrMFAdmin,
            })}
            style={{
              zIndex: actionContainerZIndex,
              marginLeft: `-${actionContainerWidth}px`,
            }}
          >
            <div className={`${className}--actions`}>{renderActions()}</div>
          </td>
        )}
      </tr>
    </>
  );
};

export default TableRow;
