import { useState, useRef, createContext, memo } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Table from "../shared/table/table";
import { headCountsTableGradeLevels } from "utils/constants";
import Icon from "components/shared/icon/icon";
import { productSegmentsMapping } from "utils/constants";
import { useAreaSelection } from "utils/area-selection";
import {
  updateTables,
  getHeadCountsRowItems,
  useCheckTableBottomVisible,
} from "utils/useInputForm";

const SelectionContext = createContext<DOMRect | null>(null);

const AvgAnnualFTESalary = ({
  subHeadingBlock,
  tableId,
  setSaved,
  showModal,
  showUpdateInputsPopup,
  headCountsDirtyTables,
  setHeadCountsDirtyTables,
  tableData,
  compareTableData,
  setTableData,
  baseYear,
  headCountTableIdList,
  headCountsData,
  tableScrollIds,
  setTableScrolledIds,
}: any) => {
  const BLOCK = "headcountsTable";
  const [prevValue, setPrevValue] = useState<any>("");
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });

  const getTableHeaders = () => {
    let dynamicTableHeaders: any =
      tableData &&
      tableData?.length > 0 &&
      Object.keys(tableData[0] || {})
        .filter(Number)
        .map((item) => {
          if (item in productSegmentsMapping) {
            return {
              text: productSegmentsMapping[item],
              isFormattedText: false,
            };
          }
        });

    const tableHeaders = dynamicTableHeaders &&
      dynamicTableHeaders.length > 0 && [
        {
          text: "headcounts.table.header.avgsalaray",
          isFormattedText: true,
        },
        ...dynamicTableHeaders,
      ];

    return tableHeaders;
  };

  const handleOnChange = (
    gradeLevelId: number,
    inputVal: any,
    columnId: any,
    roundOff: boolean = false
  ) => {
    setPrevValue(inputVal);
    updateTables(
      gradeLevelId,
      inputVal,
      columnId,
      roundOff,
      tableData,
      setTableData,
      compareTableData,
      headCountsDirtyTables,
      setHeadCountsDirtyTables,
      tableId,
      setPrevValue,
      prevValue
    );
  };

  const FocusChage = (inputVal: any, gradeLevelId: any, columnId: any) => {
    setSaved(false);
    handleOnChange(gradeLevelId, inputVal, columnId, true);
  };

  useCheckTableBottomVisible(
    selectContainerRef,
    tableScrollIds,
    setTableScrolledIds
  );

  return (
    <div data-test="avgAnnualFTESalary-table">
      <div className={`${subHeadingBlock}__sub-header`}>
        <div className={`${subHeadingBlock}__sub-header--title`}>
          <FormattedMessage id={`headcounts.avg.annual.fte.salary`} />
        </div>
      </div>
      <SelectionContext.Provider value={selection}>
        <div ref={selectContainerRef} id={tableId}>
          {tableData && tableData?.length === 0 ? (
            <div className={`${subHeadingBlock}__spinner-container`}>
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className={`${subHeadingBlock}--loading`}
              />
            </div>
          ) : (
            <Table
              className={`${BLOCK}--innerWrapper`}
              headerItems={getTableHeaders()}
              rowItems={getHeadCountsRowItems(
                tableData,
                FocusChage,
                handleOnChange,
                BLOCK,
                headCountsTableGradeLevels,
                baseYear,
                tableId,
                headCountTableIdList,
                headCountsData,
                setPrevValue
              )}
              innerClassName={BLOCK}
              selection={selection}
              tableId={tableId}
            />
          )}
        </div>
      </SelectionContext.Provider>
    </div>
  );
};

export default memo(AvgAnnualFTESalary);
