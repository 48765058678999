import { useState } from "react";
import ConfigurationProjectedYearsModal from "./configurationProjectedYearsModal";

const ConfigurationProjectedYears = ({
  setConfigurProjectedYearsModal,
}: any) => {
  const [confirmationCount, setConfirmationCount] = useState<number>(1);

  let content1 =
    "Select the forecasted period range to be applied to all geographies.";
  let content2 = "Are you sure you want to do this?";

  switch (confirmationCount) {
    case 1:
      return (
        <ConfigurationProjectedYearsModal
          confirmationCount={confirmationCount}
          setConfirmationCount={setConfirmationCount}
          setConfigurProjectedYearsModal={setConfigurProjectedYearsModal}
          buttonNamme={`configuration.projected.year.step1`}
          content={content1}
        />
      );
    case 2:
      return (
        <ConfigurationProjectedYearsModal
          confirmationCount={confirmationCount}
          setConfirmationCount={setConfirmationCount}
          setConfigurProjectedYearsModal={setConfigurProjectedYearsModal}
          buttonNamme={`configuration.projected.year.step2`}
          content={content2}
        />
      );

    default:
      return <></>;
  }
};

export default ConfigurationProjectedYears;
