import Icon from "../shared/icon/icon";
import { useState, useEffect, useRef } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { useClickOutside } from "utils/functions";
import history from "utils/history";
import { logout, userGuidePath } from "utils/constants";

const MainHeader = () => {
  const BLOCK = "Header-Components";
  const supportMenuRef = useRef<HTMLDivElement>(null);
  const logoutMenuRef = useRef<HTMLDivElement>(null);
  const [userInitials, setUserInitials] = useState("");
  const [userName, setUserName] = useState("");
  const [logoutMenu, setlogoutMenu] = useState(false);
  const [supportMenu, setSupportMenu] = useState(false);

  const commons = useSelector((state: RootStore) => state.commons);
  const currentUser = commons.currentUser;

  useEffect(() => {
    // Adobe Analytics
    const adobeAnalytics = document.createElement("script");
    adobeAnalytics.src = `${window.ESG.ENV.ADOBE_URL}`;
    document.head.appendChild(adobeAnalytics);

    const adobeSatellite = document.createElement("div");
    adobeSatellite.innerHTML = `<script type="text/javascript">_satellite.pageBottom();</script>`;
    document.body.appendChild(adobeSatellite);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userInitials =
      commons.currentUser.firstName?.charAt(0) +
      commons.currentUser.lastName?.charAt(0);
    setUserInitials(userInitials);
    const userName =
      commons.currentUser.firstName + " " + commons.currentUser.lastName;
    setUserName(userName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useClickOutside(supportMenuRef, () => setSupportMenu(false));
  useClickOutside(logoutMenuRef, () => setlogoutMenu(false));

  const logoutHandler = () => {
    history.push(logout);
  };

  const supportMenuOptions = [
    {
      title: "support",
      options: [
        {
          optionName: "request.support.link",
          optionLink:
            "https://deloitteus.service-now.com/sp?id=kb_article&sys_id=4b0891c81ba711503078b99e0a4bcb92",
          isVisibleToUser: true,
        },
        {
          optionName: "launch.user.guide",
          optionLink: userGuidePath,
          isVisibleToUser: Boolean(currentUser.userId),
        },
      ],
    },
    // {
    //   title: "create.support.ticket",
    //   options: [
    //     {
    //       optionName: "launch.user.guide",
    //       optionLink: `${process.env.PUBLIC_URL}/assets/Global A&A planning tool guide V2.1 Jan 2024 for DEV adn QA teams.pdf`,
    //     },
    //     // {
    //     //   optionName: "learning.materials.ref",
    //     //   optionLink:
    //     //     "https://disclosureanalytics.deloitte.com/api/v1/file?key=Asset.ESGInsights.Help.FAQ",
    //     // },
    //   ],
    // },
  ];

  const renderSupportTitle = (option: any) => {
    return (
      <div className={`${BLOCK}__request-support`}>
        <span>
          <FormattedMessage id={option.title} />
        </span>
      </div>
    );
  };

  const renderSupportOptions = (options: any) => {
    return options
      .filter((option: any) => option.isVisibleToUser)
      .map((link: any) => (
        <div className={`${BLOCK}__request-support-link`}>
          <span
            onClick={() => {
              window.open(link.optionLink);
              setSupportMenu(false);
            }}
          >
            <FormattedMessage id={link.optionName} />
          </span>
        </div>
      ));
  };

  return (
    <div className={`${BLOCK}`} data-test="component-Main Header">
      <div className={`${BLOCK}__header`}>
        <div className={`${BLOCK}__app`}>
          <Icon name="deloitte" height={16} className="icon" />
          <Icon
            name="4yp-new-logo"
            height={20}
            className={`${BLOCK}__app--4yp-logo`}
          />
        </div>
        <div className={`${BLOCK}__help-menu`} data-test="help-menu">
          <div ref={supportMenuRef}>
            <span
              className={`${BLOCK}__menu-icon`}
              data-test="help-menu"
              onClick={() => setSupportMenu(!supportMenu)}
            >
              <Icon
                name="question-mark"
                height={16}
                width={16}
                className="icon"
              />
            </span>
            {supportMenu ? (
              <div
                className={`${BLOCK}__support-menu-container`}
                data-test="help-menu-container"
              >
                {supportMenuOptions.map((option: any) => (
                  <div>
                    {renderSupportTitle(option)}
                    {renderSupportOptions(option.options)}
                  </div>
                ))}
                {/* <div className={`${BLOCK}__request-support`}>
                                <span>
                                <FormattedMessage id="request.support" />
                                </span>
                            </div>
                            <div className={`${BLOCK}__request-support-link`}>
                                <span
                                onClick={() => {
                                    window.open(
                                    "https://deloitteus.service-now.com/sp?id=sp_kb_article&sys_id=c99cd2d0dbbefc1c9275a478139619a7",
                                    "_blank"
                                    );
                                    setSupportMenu(false);
                                }}
                                >
                                <FormattedMessage id="request.support.link" />
                                </span>
                            </div> */}
              </div>
            ) : (
              ""
            )}
          </div>

          <div ref={logoutMenuRef}>
            {userInitials ? (
              <span
                onClick={() => setlogoutMenu(!logoutMenu)}
                className={`${BLOCK}__user-section`}
                data-testid="user-section"
              >
                {userInitials}
              </span>
            ) : (
              ""
            )}

            {logoutMenu ? (
              <div
                className={`${BLOCK}__menu-container`}
                data-testid="logout-menu"
              >
                <div className={`${BLOCK}__user-details`}>
                  <div className={`${BLOCK}__user-avtar`}>
                    <span> {userInitials}</span>
                  </div>
                  <div className={`${BLOCK}__user-name`}>
                    <span>{userName}</span>
                  </div>
                </div>
                <div className={`${BLOCK}__logout`} onClick={logoutHandler}>
                  <div>
                    <span>
                      <Icon
                        name="exit"
                        height={16}
                        width={16}
                        className="icon"
                      />
                    </span>
                  </div>
                  <div>
                    <span className={`${BLOCK}__logout-user`}>
                      {" "}
                      <FormattedMessage id="logout" />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className={`${BLOCK}__app-name`} data-test="esg-portal">
        <FormattedMessage id="tool.yp.name" />
      </div>
    </div>
  );
};

export default MainHeader;
