import { useEffect, useState } from "react";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import Icon from "../shared/icon/icon";
import { REFRESH_NOTIFICATION_CONTENT } from "../../utils/constants";
type Props = {
  show: boolean;
  refreshDashboard: any;
  geographyName: string;
};
const RefreshNotification = ({
  show,
  refreshDashboard,
  geographyName,
}: Props) => {
  const BLOCK = "refreshNotification";
  const [showModel, setShowModel] = useState(show);
  const handleClose = () => {
    setShowModel(false);
  };
  const createStringFromTemplate = (template: any, variables: any) => {
    return template?.replace(
      new RegExp("{([^{]+)}", "g"),
      function (_unused: any, varName: any) {
        return variables[varName];
      }
    );
  };
  let content = createStringFromTemplate(REFRESH_NOTIFICATION_CONTENT, {
    Geography: geographyName,
  });

  return (
    <>
      {showModel && (
        <div className={`${BLOCK}`} data-test="refreshNotification-container">
          <div
            className={`${BLOCK}__header-container`}
            data-test="refreshNotification-header-container"
          >
            <div className={`${BLOCK}__header-container--text`}>
              <FormattedMessage id="dashboard.refresh.notification.header" />
            </div>
            <div
              className={`${BLOCK}__cross`}
              onClick={handleClose}
              data-test="refreshNotification-cross-icon"
            >
              <Icon name="cross" width={20} height={20} />
            </div>
          </div>
          <p
            className={`${BLOCK}__content-text`}
            data-test="refreshNotification-content-text"
          >
            {content}
          </p>
          <div
            className={`${BLOCK}__button-contanier`}
            data-test="refreshNotification-button-container"
          >
            <button
              className={`${BLOCK}__button-contanier--cancel ${BLOCK}__button-contanier--refresh-button`}
              onClick={handleClose}
              data-test="refreshNotification-cancel-button"
            >
              <FormattedMessage id="dashboard.refresh.notification.cancel" />
            </button>
            <button
              className={`${BLOCK}__button-contanier--refresh ${BLOCK}__button-contanier--refresh-button`}
              onClick={refreshDashboard}
            >
              <FormattedMessage id="dashboard.refresh.notification.refresh" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default RefreshNotification;
