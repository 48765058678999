import { useState, useEffect, useRef, createContext, memo } from "react";
import Table from "../shared/table/table";
import Icon from "../shared/icon/icon";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useAreaSelection } from "utils/area-selection";
import { useAPI } from "../updatedRevenuePL/revenuePnlContextProvider";
import { valueConvertor } from "../shared/commons/globalFunctions";
import {
  revenueUpdateTables,
  inputValueValidation,
  handleEnterTable,
} from "utils/useInputForm";

const SelectionContext = createContext<DOMRect | null>(null);

const ExitPhasingTable = ({
  currency,
  setSaved,
  tableId,
  showModal,
  subHeadingBlock,
  baseYear,
  subTitle,
  showCurrencyHeader,
  showUpdateInputsPopup,
  revenueDirtyTables,
  setRevenueDirtyTables,
  tableData,
  compareTableData,
  setTableData,
  revenueTableIdList,
  revenueData,
}: any) => {
  const BLOCK = "headcountsTable";
  const [prevValue, setPrevValue] = useState<any>("");
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });
  const { exitProgrammePhasesData }: any = useAPI();

  const [totalYearVals, setTotalYearVals] = useState<any>([]);
  const [totalExitHoursVals, setTotalExitHoursVals] = useState<any>([]);

  useEffect(() => {
    let totalYears: any = {};
    tableData &&
      tableData.length > 0 &&
      tableData.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            if (totalYears[id]) {
              totalYears[id] = Number(totalYears[id]) + Number(item[id]);
            } else {
              totalYears[id] = item[id];
            }
          });
      });

    let totalYearsArray = Object.keys(totalYears).map((key: any) => {
      return {
        text: totalYears[key]
          ? Number(totalYears[key]).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0",
        isTotal: true,
      };
    });
    setTotalYearVals(totalYearsArray);
  }, [tableData]);

  useEffect(() => {
    let totalHoursofWork = exitProgrammePhasesData?.[0]?.totalHoursofWork;

    let totalExitHours =
      totalYearVals &&
      totalYearVals.length > 0 &&
      totalYearVals.map((item: any) => {
        return {
          ...item,
          text:
            item.text == 0
              ? "0"
              : Number(
                  valueConvertor(item.text) *
                    (valueConvertor(totalHoursofWork) / 100)
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
        };
      });

    setTotalExitHoursVals(totalExitHours);
  }, [totalYearVals, exitProgrammePhasesData]);

  const getTableHeaders = () => {
    let dynamicTableHeaders: any =
      tableData &&
      tableData?.length > 0 &&
      Object.keys(tableData[0] || {})
        .filter(Number)
        .map((item) => {
          return {
            text: `FY${item}`,
            isFormattedText: false,
          };
        });

    const tableHeaders = dynamicTableHeaders &&
      dynamicTableHeaders.length > 0 && [
        {
          text: "revenue-pl.exit.phasing.main.header",
          isFormattedText: true,
        },
        ...dynamicTableHeaders,
      ];

    return tableHeaders;
  };

  const handleOnChange = (
    inputVal: any,
    segmentId: any,
    columnId: any,
    roundOff: boolean = false
  ) => {
    setPrevValue(inputVal);
    let newVal = inputValueValidation(inputVal, prevValue, setPrevValue, true);

    revenueUpdateTables(
      segmentId,
      newVal,
      columnId,
      roundOff,
      tableData,
      setTableData,
      compareTableData,
      revenueDirtyTables,
      setRevenueDirtyTables,
      tableId,
      prevValue,
      true
    );
  };
  const FocusChage = (e: any, segmentId: number, id: number) => {
    setSaved(false);
    handleOnChange(e, segmentId, id, true);
  };

  const getRowItems = () => {
    let rowItems =
      tableData &&
      tableData.length > 0 &&
      tableData.map((item: any, index: number) => {
        let dynamicRows = Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            return {
              customIndex: id,
              textInput: true,
              onBlur: (e: any) => {
                FocusChage(e.target.value, item.productSegmentId, id);
              },
              onchangeHandler: (e: any) => {
                let columnId = id;
                handleOnChange(e.target.value, item.productSegmentId, columnId);
              },
              onHandleEnter: (evt: any, keyIdentifier: any) => {
                setPrevValue(evt?.target?.value);
                handleEnterTable(
                  evt,
                  keyIdentifier,
                  tableData,
                  tableId,
                  revenueTableIdList,
                  baseYear,
                  revenueData
                );
              },
              placholder: "-",
              inputValue: item[id],
              inputDisable: id === `${baseYear}` ? true : false,
              inputIcon: true,
            };
          });
        let tableRows = {
          id: item.productSegmentId,
          key: `${item.productSegmentId}-${index}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: item.productSegment,
            },
            ...dynamicRows,
          ],
        };

        return tableRows;
      });

    let updatedRowItems: any = rowItems?.length > 0 && [
      ...rowItems,
      {
        className: `${BLOCK}__row`,
        columnItems: [
          { text: "Total", highlight: true },
          ...(totalYearVals || []),
        ],
        id: 99999,
        key: "1-9999",
      },
      {
        className: `${BLOCK}__row`,
        columnItems: [
          { text: "Total Exit Phasing Yearly Hours", highlight: true },
          ...(totalExitHoursVals || []),
        ],
        id: 99998,
        key: "1-9998",
      },
    ];

    return updatedRowItems;
  };

  return (
    <>
      <div data-test="ExitPhasingTable" style={{ marginTop: 30 }}>
        <div className={`${subHeadingBlock}__sub-header`}>
          <div className={`${subHeadingBlock}__sub-header--title`}>
            {tableId === 1 ? (
              subTitle
            ) : (
              <>
                <FormattedMessage id={subTitle} />{" "}
                {currency && showCurrencyHeader ? `(${currency} 'M)` : ""}
              </>
            )}
          </div>
        </div>
        <SelectionContext.Provider value={selection}>
          <div
            ref={selectContainerRef}
            id={tableId}
            data-test="component-winsNetRevenue"
          >
            {tableData && tableData.length === 0 ? (
              <div className={`${subHeadingBlock}__spinner-container`}>
                <Icon
                  name={"loading"}
                  width={50}
                  height={50}
                  className={`${subHeadingBlock}--loading`}
                />
              </div>
            ) : (
              <Table
                className={`${BLOCK}--innerWrapper`}
                headerItems={getTableHeaders()}
                rowItems={getRowItems()}
                innerClassName={BLOCK}
                selection={selection}
                tableId={tableId}
              />
            )}
          </div>
        </SelectionContext.Provider>
      </div>
    </>
  );
};

export default memo(ExitPhasingTable);
