import FormattedMessage from "../formatted-message/formatted-message";
import { MouseEventHandler, useEffect, useRef } from "react";
import Icon from "../icon/icon";
import classNames from "classnames";

type Props = {
  show: boolean;
  handleClose?: MouseEventHandler;
  headerParams?: any;
  header?: any;
  children: JSX.Element;
  config?: boolean;
  fullscreen?: boolean;
  hideBackgroud?: boolean;
  showHeaderIcon?: boolean;
};

const Modal = ({
  show,
  handleClose,
  headerParams,
  header,
  children,
  config = true,
  fullscreen = false,
  hideBackgroud,
  showHeaderIcon,
}: Props) => {
  const BLOCK = "modalESG";
  const contentRef = useRef(null);

  useEffect(() => {
    const handleCloseOnEscape = (e: any) =>
      handleClose && (e.charCode || e.keyCode) === 27 ? handleClose(e) : null;

    document.body.addEventListener("keydown", handleCloseOnEscape);
    return () => {
      document.body.removeEventListener("keydown", handleCloseOnEscape);
    };
  }, [handleClose]);

  return (
    <div
      className={classNames(BLOCK, { [`${BLOCK}--show`]: show })}
      onClick={config ? handleClose : () => {}}
      data-test="component-modal-show"
      data-testid="component-modal-show"
    >
      <div
        ref={contentRef}
        className={classNames(`${BLOCK}__content`, {
          [`${BLOCK}__content--show`]: show,
          [`${BLOCK}__content--fullscreen`]: fullscreen,
          [`${BLOCK}__content--showBackground`]: !hideBackgroud,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        {header ? (
          <div
            className={classNames(`${BLOCK}__header`, {
              [`${BLOCK}__header--withoutIcon`]: !showHeaderIcon,
            })}
            data-test="modal-header"
          >
            {showHeaderIcon && (
              <span className={`${BLOCK}__header--icon--container`}>
                <Icon name="WarningCircle" height={17} width={17} />
              </span>
            )}
            <h1 className={`${BLOCK}__header--h1--title`}>
              {headerParams ? (
                <FormattedMessage id={header} values={headerParams} />
              ) : (
                <FormattedMessage id={header} />
              )}
            </h1>
            {handleClose && (
              <button
                className={`${BLOCK}__close`}
                data-test="close-modal"
                data-testid="close-modal"
                onClick={handleClose}
              >
                <Icon name="cross" height={24} />
              </button>
            )}
          </div>
        ) : null}
        {fullscreen ? (
          children
        ) : (
          <div
            className={classNames(`${BLOCK}__body`, {
              [`${BLOCK}__body--hideBorder`]: hideBackgroud,
            })}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
