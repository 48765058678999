import { useState, useRef, createContext, useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import Table, { TableRowItem } from "../shared/table/table";
import Icon from "../shared/icon/icon";
import { validateInputValueLength } from "../../services/inputForm/inputFormSlice";
import { addToastMessage } from "services/common/commonsSlice";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useAreaSelection } from "utils/area-selection";
import {
  setDirtyTables,
  inputValueValidation,
  handleEnterTable,
} from "utils/useInputForm";

const SelectionContext = createContext<DOMRect | null>(null);

const FeePerExitHoursTable = ({
  currency,
  setSaved,
  tableId,
  showModal,
  subHeadingBlock,
  subTitle,
  showCurrencyHeader,
  baseYear,
  showUpdateInputsPopup,
  revenueDirtyTables,
  setRevenueDirtyTables,
  tableData,
  compareTableData,
  setTableData,
  revenueTableIdList,
  revenueData,
  displayMillionSymbol = true,
}: any) => {
  const dispatch = useDispatch();

  const BLOCK = "headcountsTable";
  const [prevValue, setPrevValue] = useState<any>("");
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });

  const changeTableValue = useCallback(
    (newTableValue: any) => {
      setTableData(newTableValue);
    },
    [tableData]
  );

  const getTableHeaders = () => {
    const tableHeaders = [
      {
        text: "revenue-pl.fee.per.exit.hors.main.header",
        isFormattedText: true,
      },
      {
        text: "Fee",
        isFormattedText: false,
      },
    ];

    return tableHeaders;
  };

  const InputChangeHandler = (
    inputVal: any,
    columnId: string,
    roundOff: boolean = false
  ) => {
    if (parseInt(inputVal) < 0) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="neg.values.not.allowed" />,
        })
      );
      return;
    }
    setPrevValue(inputVal);
    let newVal = inputValueValidation(inputVal, prevValue, setPrevValue);
    let value: any;
    if (roundOff) {
      value = validateInputValueLength(newVal, prevValue, roundOff);
    } else {
      value = validateInputValueLength(newVal, prevValue);
    }

    let newTableValues =
      tableData &&
      tableData.length > 0 &&
      tableData.map((item: any) => {
        if (item.productSegmentId === columnId) {
          return {
            ...item,
            value: value,
          };
        }
        return item;
      });

    changeTableValue(newTableValues);
    setDirtyTables(
      compareTableData,
      newTableValues,
      revenueDirtyTables,
      setRevenueDirtyTables,
      tableId
    );
  };
  const FocusChage = (inputValue: any, columnId: any) => {
    setSaved(false);
    InputChangeHandler(inputValue, columnId, true);
  };

  const getRowItems = () => {
    return (
      tableData &&
      tableData.length > 0 &&
      tableData.map((item: any, index: number) => {
        let tableRows = {
          id: item.productSegmentId,
          key: `${index}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: item.productSegment,
            },
            {
              textInput: true,
              inputValue: item.value,
              inputName: item.productSegment,
              onBlur: (e: any) => {
                FocusChage(e.target.value, item.productSegmentId);
              },
              onchangeHandler: (e: any) => {
                InputChangeHandler(e.target.value, item.productSegmentId);
              },
              onHandleEnter: (evt: any, keyIdentifier: any) => {
                setPrevValue(evt?.target?.value);
                handleEnterTable(
                  evt,
                  keyIdentifier,
                  tableData,
                  tableId,
                  revenueTableIdList,
                  baseYear,
                  revenueData
                );
              },
            },
          ],
        } as TableRowItem;

        return tableRows;
      })
    );
  };

  return (
    <>
      <div
        className={`${subHeadingBlock}__sub-header`}
        style={{ marginTop: 30 }}
      >
        <div className={`${subHeadingBlock}__sub-header--title`}>
          {tableId === 1 ? (
            subTitle
          ) : (
            <>
              <FormattedMessage id={subTitle} />{" "}
              {currency && showCurrencyHeader
                ? `(${currency}${displayMillionSymbol ? "'M" : ""})`
                : ""}
            </>
          )}
        </div>
      </div>
      <SelectionContext.Provider value={selection}>
        <div
          ref={selectContainerRef}
          id={tableId}
          data-test="component-feePerExitHours"
        >
          {tableData && tableData.length === 0 ? (
            <div className={`${subHeadingBlock}__spinner-container`}>
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className={`${subHeadingBlock}--loading`}
              />
            </div>
          ) : (
            <Table
              headerItems={getTableHeaders()}
              rowItems={getRowItems()}
              innerClassName={BLOCK}
              selection={selection}
              tableId={tableId}
            />
          )}
        </div>
      </SelectionContext.Provider>
    </>
  );
};

export default memo(FeePerExitHoursTable);
