import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import Consolidations from "./consolidations";

const Tier5 = () => {
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  return (
    <div data-test="Tier5-component">
      <Consolidations
        tableData={
          consolidationData?.consolidationTransformationAssumptionsData
            ?.accumulatedTier5Details
        }
      />
    </div>
  );
};

export default Tier5;
