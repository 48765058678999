import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Icon from "components/shared/icon/icon";
import laptop from "images/landingPage.png";
import { useIsAuthenticated } from "@azure/msal-react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { signInClickHandler } from "../../services/api.service";

const Login = () => {
  const BLOCK = "login";
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      history.goBack();
    }
  }, []);

  return (
    <div className={`${BLOCK}`} data-test="component-Login Page">
      <div className={`${BLOCK}__container`}>
        <div className={`${BLOCK}__info`}>
          <h1 className={`${BLOCK}__title`}>
            <FormattedMessage id={`login.page.heading`} />
          </h1>
          <button
            className={`${BLOCK}__login-button`}
            onClick={() => signInClickHandler()}
          >
            <FormattedMessage id={`login.page.login`} />
          </button>
          <div className={`${BLOCK}__additional-info`}>
            <div className={`${BLOCK}__icon-container`}>
              <div className={`${BLOCK}__icon-wrapper`}>
                <Icon
                  name="login-page-bar-chart"
                  width={19}
                  height={15}
                  className={`${BLOCK}__icon`}
                />
              </div>
              <div className={`${BLOCK}__icon-text`}>
                <FormattedMessage id={`login.page.sub-heading`} />
              </div>
            </div>
            <div className={`${BLOCK}__icon-container`}>
              <div className={`${BLOCK}__icon-wrapper`}>
                <Icon
                  name="Lock"
                  width={19}
                  height={15}
                  className={`${BLOCK}__icon`}
                />
              </div>
              <div className={`${BLOCK}__icon-text`}>
                <FormattedMessage id={`login.page.feature.secure-data`} />
              </div>
            </div>
            <div className={`${BLOCK}__icon-container`}>
              <div className={`${BLOCK}__icon-wrapper`}>
                <Icon
                  name="Clock"
                  width={19}
                  height={15}
                  className={`${BLOCK}__icon`}
                />
              </div>
              <div className={`${BLOCK}__icon-text`}>
                <FormattedMessage id={`login.page.feature.realt-time`} />
              </div>
            </div>
          </div>
        </div>
        <div className={`${BLOCK}__visual`}>
          <div className={`${BLOCK}__image-block`}>
            <img
              className={`${BLOCK}__image`}
              data-testid="empty-img"
              src={laptop}
              alt={laptop}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
