import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "./download.api";

const initialState = { loading: false, showDownloads: false, downloads: null };

const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    showDownloadTab(state: any, action) {
      state.showDownloads = !state.showDownloads;
    },
    setShowDownloadTab(state: any, action) {
      state.showDownloads = action.payload;
    },
    updateDownloadState(state: any, action) {
      const downloads = [...state.downloads];
      const download = downloads.find(
        (d: any) => d.exportDetailId === action.payload.exportDetailId
      );
      if (download) download.exportState = action.payload.exportState;
      state.downloads = downloads;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDownloads.pending, (state: any, action: any) => {
        state.loading = true;
      })
      .addCase(getDownloads.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.downloads = action.payload ? action.payload : [];
      })
      .addCase(getDownloads.rejected, (state: any, action: any) => {
        state.loading = false;
      })
      .addCase(sendExportItem.pending, (state: any, action: any) => {
        state.loading = true;
      })
      .addCase(sendExportItem.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.downloads = action.payload ? action.payload : [];
      })
      .addCase(sendExportItem.rejected, (state: any, action: any) => {
        state.loading = false;
      });
  },
});

export const getDownloads = createAsyncThunk(
  "visualDashboard/fetchDownloadsData",
  async () => {
    try {
      const res: any = await api.getDownloadsList();
      return res.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const sendExportItem = createAsyncThunk(
  "visualDashboard/sendExportItemData",
  async (payload: any, { dispatch }) => {
    try {
      await api.exportItem(payload);
      const res: any = await api.getDownloadsList();
      if (!payload.downloadTabOpen) {
        //only call when its not open
        dispatch(showDownloadTab("My Download"));
      }
      return res.data;
    } catch (e) {
      console.log("there was an error in sending item", e);
    }
  }
);

export const { showDownloadTab, setShowDownloadTab, updateDownloadState } =
  downloadSlice.actions;

export default downloadSlice.reducer;
