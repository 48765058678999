import { useState, useEffect, useRef, createContext } from "react";
import {
  productSegmentsMapping,
  headCountsTableGradeLevelsMini,
  productSegmentOtherId,
  productSegmentTier5Id,
  productSegmentsToIdsMapping,
  yearlyutilizationObjectNonYearKeys,
  STATUS_ID_MAPPING,
} from "utils/constants";
import { useAreaSelection } from "utils/area-selection";
import { useAPI } from "./yearlyUtilizationContextProvider";
import Table from "../shared/table/table";
import CopyToModal from "components/inputform/copyToModal";
import KebabMenu from "components/inputform/kebabMenu";
import AttentionPopup from "components/shared/attention-popup/attentionPopup";
import Icon from "components/shared/icon/icon";
import { useDispatch, useSelector } from "react-redux";
import { setExpandedItems } from "services/inputForm/inputFormSlice";
import { RootStore } from "services/store.service";
import {
  getYearlyUtilizationRowItems,
  updateyearlyUtilizationTables,
} from "utils/useInputForm";
import { addToastMessage } from "services/common/commonsSlice";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

const SelectionContext = createContext<DOMRect | null>(null);

const ProductSegmentTab = ({
  BLOCK,
  productSegmentId,
  tableId,
  baseYear,
  showModal,
  showUpdateInputsPopup,
  tablesData,
  setTablesData,
  compareTablesData,
  yearlyUtilizationDirtyTables,
  setYearlyUtilizationDirtyTables,
  setSaved,
  yearlyUtilizationTableIdList,
}: any) => {
  const BLOCK_TABLE = "yearlyUtilizationTable";
  const [prevValue, setPrevValue] = useState<any>("");
  const [showKebabMenuPopup, setShowKebabMenuPopup] = useState<boolean>(false);
  const [showCopyToPopup, setShowCopyToPopup] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isAllValuesNull, setIsAllValuesNull] = useState<boolean>(false);
  const [showCopyToAttentionPopup, setShowCopyToAttentionPopup] =
    useState<boolean>(false);
  const [showClearContentAttentionPopup, setShowClearContentAttentionPopup] =
    useState<boolean>(false);

  const { configToggle }: any = useAPI();
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const dispatch = useDispatch();
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });

  useEffect(() => {
    function allNumericKeysNull(obj: any) {
      for (const key in obj) {
        if (
          !isNaN(parseInt(key)) &&
          !yearlyutilizationObjectNonYearKeys.includes(key) &&
          obj[key] !== null
        ) {
          return false; // If any numeric key value is not null, return false
        }
      }
      return true; // If all numeric key values are null, return true
    }

    const isAllNullValues = tablesData
      ?.filter((obj: any) => obj.productSegmentId == productSegmentId)
      .every((obj: any) => allNumericKeysNull(obj));

    setIsAllValuesNull(isAllNullValues);
  }, [tablesData]);

  const availableProductSegmentsExceptThisSectionOne = Object.keys(
    productSegmentsMapping
  )
    .filter((item: any) => {
      if (
        !inputFormDataState?.configurationData?.hasOther &&
        !inputFormDataState?.configurationData?.hasTier5
      ) {
        return (
          item !== productSegmentOtherId &&
          item !== productSegmentTier5Id &&
          item !== productSegmentId
        );
      } else if (!inputFormDataState?.configurationData?.hasOther) {
        return item !== productSegmentOtherId && item !== productSegmentId;
      } else if (!inputFormDataState?.configurationData?.hasTier5) {
        return item !== productSegmentTier5Id && item !== productSegmentId;
      } else {
        return item !== productSegmentId;
      }
    })
    .map((item: any) => productSegmentsMapping[item]);

  const getTableHeaders = () => {
    let dynamicTableHeaders: any =
      tablesData &&
      tablesData?.length > 0 &&
      Object.keys(tablesData[0] || {})
        .filter(Number)
        .map((item) => {
          return {
            text: `FY${item}`,
            isFormattedText: false,
          };
        });

    const tableHeaders = dynamicTableHeaders &&
      dynamicTableHeaders.length > 0 && [
        {
          text: "Yearly Utilisation Rate (%) ",
          isFormattedText: false,
        },
        ...dynamicTableHeaders,
      ];

    return tableHeaders;
  };

  const handleOnChange = (
    gradeLevelId: number,
    inputVal: any,
    columnId: any,
    roundOff: boolean = false
  ) => {
    setPrevValue(inputVal);
    updateyearlyUtilizationTables(
      gradeLevelId,
      inputVal,
      columnId,
      roundOff,
      tablesData,
      setTablesData,
      compareTablesData,
      yearlyUtilizationDirtyTables,
      setYearlyUtilizationDirtyTables,
      productSegmentId,
      tableId,
      setPrevValue,
      prevValue,
      true,
      configToggle
    );
  };

  const FocusChange = (inputVal: any, gradeLevelId: any, columnId: any) => {
    setSaved(false);
    handleOnChange(gradeLevelId, inputVal, columnId, true);
  };

  const onClickCopyToOption = () => {
    setShowCopyToPopup(true);
  };

  const onClickClearContentsOption = () => {
    setShowClearContentAttentionPopup(true);
  };

  const onConfirmCopyToInsideAttentionPopup = () => {
    const sourceProductSegmentObjects = tablesData?.filter(
      (item: any) => item.productSegmentId == productSegmentId
    );

    const selectedTargetProductSegmentIds = selectedItems.map(
      (key) => productSegmentsToIdsMapping[key]
    );

    const selectedTargetProductSegmentsObjects: any = {};

    tablesData?.forEach((obj: any) => {
      const productSegmentId = obj.productSegmentId.toString();

      if (selectedTargetProductSegmentIds.includes(productSegmentId)) {
        if (!selectedTargetProductSegmentsObjects[productSegmentId]) {
          selectedTargetProductSegmentsObjects[productSegmentId] = [];
        }

        selectedTargetProductSegmentsObjects[productSegmentId].push(obj);
      }
    });

    const A: any = sourceProductSegmentObjects;
    const B: any = selectedTargetProductSegmentsObjects;

    for (const productSegmentId in B) {
      const arrayB = B[productSegmentId];

      arrayB?.forEach((objB: any) => {
        const correspondingObjA = A.find(
          (objA: any) => objA.gradeLevelId === objB.gradeLevelId
        );

        if (correspondingObjA) {
          const newObjB = JSON.parse(JSON.stringify(objB));

          for (const key in correspondingObjA) {
            if (
              !isNaN(parseInt(key)) &&
              !yearlyutilizationObjectNonYearKeys.includes(key)
            ) {
              if (
                !(correspondingObjA.disabled && correspondingObjA.disabled[key])
              ) {
                newObjB[key] = correspondingObjA[key];
              }
            }
          }
          const index = arrayB.findIndex((obj: any) => obj === objB);
          arrayB[index] = newObjB;
        }
      });
    }

    const newTablesData = tablesData?.map((obj: any) => {
      const correspondingObj = B[obj.productSegmentId]?.find(
        (item: any) => item.gradeLevelId === obj.gradeLevelId
      );

      return correspondingObj ? correspondingObj : obj;
    });

    let replicatedFieldsData = configToggle ? newTablesData : newTablesData?.map((item: any) => {
      for (let productSegmentName of selectedItems) {
        if (
          item.productSegmentId ==
          productSegmentsToIdsMapping[productSegmentName]
        ) {
          let yearsList = Object.keys(item || {})
            .filter(Number)
            .slice(1);

          for (let year of yearsList) {
            if (item[year] !== null) {
              return item;
            }
          }
          let updatedItem = { ...item };
          for (let year of yearsList) {
            updatedItem[year] = item[Number(yearsList[0]) - 1];
          }
          return updatedItem;
        }
      }
      return item;
    });
    setTablesData(replicatedFieldsData);
    setYearlyUtilizationDirtyTables([tableId]);
    setSelectedItems([]);
    dispatch(
      addToastMessage({
        description: (
          <FormattedMessage id="after.copy.contents.toaster.message" />
        ),
      })
    );
  };

  const onConfirmClearContentsInsideAttentionPopup = () => {
    setYearlyUtilizationDirtyTables([tableId]);
    setTablesData((prevData: any) =>
      prevData.map((item: any) => {
        if (item.productSegmentId == productSegmentId) {
          const updatedItem: any = {};
          for (const key in item) {
            updatedItem[key] = !isNaN(parseInt(key)) ? null : item[key];
          }
          return updatedItem;
        } else {
          return item;
        }
      })
    );
    dispatch(
      addToastMessage({
        description: (
          <FormattedMessage id="after.clear.contents.toaster.message" />
        ),
      })
    );
  };

  const onExpand = (e: any) => {
    e.preventDefault();
    const expandedItems = inputFormDataState?.expandedItems || [];
    const includesProductSegmentId = expandedItems.includes(productSegmentId);

    const updatedExpandedItems = includesProductSegmentId
      ? expandedItems.filter((item: any) => item !== productSegmentId)
      : [...expandedItems, productSegmentId];

    dispatch(setExpandedItems(updatedExpandedItems));
  };
  return (
    <>
      <div className={`${BLOCK}__sub-header`} onClick={onExpand}>
        <div className={`${BLOCK}__sub-header--expand-icon`}>
          <Icon
            name={
              inputFormDataState?.expandedItems?.includes(productSegmentId)
                ? "down"
                : "caretright"
            }
            width={16}
            height={16}
          />
        </div>
        <span className={`${BLOCK}__sub-header--title`}>
          {productSegmentsMapping[productSegmentId]}
        </span>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowKebabMenuPopup(true);
          }}
          className={`${BLOCK}__sub-header--expand-icon`}
        >
          { inputFormDataState.inputFormStatusId !== STATUS_ID_MAPPING.ARCHIVED.id && 
            <KebabMenu
              onClickCopyToOption={onClickCopyToOption}
              onClickClearContentsOption={onClickClearContentsOption}
              isAllValuesNull={isAllValuesNull}
            />
          }
        </div>
      </div>
      <div
        style={
          !inputFormDataState?.expandedItems?.includes(productSegmentId)
            ? { display: "none" }
            : {}
        }
      >
        <SelectionContext.Provider value={selection}>
          <div ref={selectContainerRef} id={tableId}>
            {tablesData && tablesData?.length === 0 ? (
              <div className={`${BLOCK}__spinner-container`}>
                <Icon
                  name={"loading"}
                  width={50}
                  height={50}
                  className={`${BLOCK}--loading`}
                />
              </div>
            ) : (
              <Table
                className={`${BLOCK_TABLE}--innerWrapper`}
                headerItems={getTableHeaders()}
                rowItems={getYearlyUtilizationRowItems(
                  tablesData,
                  FocusChange,
                  handleOnChange,
                  BLOCK_TABLE,
                  headCountsTableGradeLevelsMini,
                  baseYear,
                  productSegmentId,
                  tableId,
                  yearlyUtilizationTableIdList,
                  setPrevValue,
                  {
                    showPercentageInsideInputIcon: true,
                    allowNegValues: false,
                  },
                  false,
                  inputFormDataState?.expandedItems
                )}
                innerClassName={BLOCK_TABLE}
                selection={selection}
                tableId={tableId}
              />
            )}
          </div>
        </SelectionContext.Provider>
      </div>
      {showCopyToPopup && (
        <CopyToModal
          showModal={setShowCopyToPopup}
          setShowCopyToAttentionPopup={setShowCopyToAttentionPopup}
          data={availableProductSegmentsExceptThisSectionOne}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      )}
      {showCopyToAttentionPopup && (
        <AttentionPopup
          showModal={setShowCopyToAttentionPopup}
          message={`copy.to.attention.pop.up.message`}
          onConfirm={onConfirmCopyToInsideAttentionPopup}
          setShowCopyToPopup={setShowCopyToPopup}
        />
      )}
      {showClearContentAttentionPopup && (
        <AttentionPopup
          showModal={setShowClearContentAttentionPopup}
          message={`clear.content.attention.pop.up.message`}
          onConfirm={onConfirmClearContentsInsideAttentionPopup}
          setShowCopyToPopup={setShowCopyToPopup}
        />
      )}
    </>
  );
};

export default ProductSegmentTab;
