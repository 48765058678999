import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import { tableNaviagtionEventListener } from "../shared/commons/globalFunctions";
import { VISUAL_DASHBOARD_MENU } from "utils/constants";

type commonSubItems = {
  BLOCK: string;
  selection: null | number;
  changeSelection: any;
  subItemsList: any;
  orderId: number;
};

const CommonSubItems = ({
  BLOCK,
  selection,
  changeSelection,
  subItemsList,
  orderId,
}: commonSubItems) => {
  let sectionSelector = "#consolidatedTableData";
  let locationName = useLocation()?.pathname;
  const [subsectionName, setSubSectionName] = useState<any>(null);
  const [initialClick, setInitialClick] = useState<any>(null);
  useEffect(() => {
    if (!subsectionName) {
      setSubSectionName(subItemsList[0]?.tablePath);
    }
    if (selection !== orderId) {
      setInitialClick(null);
      setSubSectionName(null);
    }
  }, [selection]);

  const handleCLick = (tableId: any, index: number) => {
    const element = document.getElementById(tableId);
    setInitialClick(tableId);
    if (element) {
      setSubSectionName(tableId);
      element.scrollIntoView();
    } else {
      changeSelection(orderId);
    }
  };
  useEffect(() => {
    const main: any = document?.querySelector(sectionSelector);
    if (main) {
      const sections = [
        ...main?.querySelectorAll(
          `.consolidatedTableData__scroll_margin_head_counts`
        ),
        ...main?.querySelectorAll(`.consolidatedTableData__scroll_margin`),
      ];

      if (sections && initialClick && selection == orderId) {
        setSubSectionName(initialClick);
        const element = document.getElementById(initialClick);
        element?.scrollIntoView();
      }
    }
  });
  const getActiveId = () => {
    const main: any = document?.querySelector(sectionSelector);
    const sections = [
      ...main?.querySelectorAll(
        `.consolidatedTableData__scroll_margin_head_counts`
      ),
      ...main?.querySelectorAll(`.consolidatedTableData__scroll_margin`),
    ];
    let activeId = "";
    sections?.forEach((section: any) => {
      const rect = section?.getBoundingClientRect();

      if (
        rect.top + (window.innerHeight - window.innerHeight / 1.5) <
          window.innerHeight &&
        rect.bottom >= 0
      ) {
        setInitialClick(null);
        activeId = section.id;
      }
    });
    setSubSectionName(activeId);
  };

  useEffect(() => {
    const element = document?.querySelector(sectionSelector);
    tableNaviagtionEventListener(element, getActiveId);
    return () => {
      tableNaviagtionEventListener(element, getActiveId, false);
    };
  }, [locationName]);

  return (
    <div
      className={`${BLOCK}__container`}
      data-test="consolidation-subItem-test"
    >
      {subItemsList?.map((item: any, index: number) => {
        return (
          <div
            key={`navigation-item-${index}`}
            className={`${BLOCK}__navigation-panel--item`}
          >
            <span
              data-test="consolidation-subItem-test-onClick"
              className={`${BLOCK}__sub-items`}
              onClick={() => {
                handleCLick(item.tablePath, index);
              }}
              style={{
                color:
                  subsectionName === `${item.tablePath}` ? "#00A3E0" : "black",
              }}
            >
              {item.tableName}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default CommonSubItems;
