import { useEffect, useState, createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import {
  getRevenuePnlTableDataContext,
  getRevenuePriceIncreaseDataContext,
  getProfitsAndLossesTableDataContext,
  getWinsNetRevenueTableDataContext,
  getLossesNetRevenueTableDataContext,
  getExitProgrammePhasesTableDataContext,
  getFeePerExitHoursTableDataContext,
  getIncreaseInHoursTableDataContext,
  RearrangingTheArrWithFourYears,
  getExitPhasingTableDataContext,
  getAnnualInflationTableDataContext,
  RearrangingTheArrWithFourYearsWithoutPS,
} from "services/inputForm/inputFormSlice";
import {
  REVENUE_PNL,
  TABLE_MAPPINGS,
  revenueAndPlTablesList,
} from "utils/constants";

const RevenuePnlContext: any = createContext(null);

type revenuePnlContext = {
  children: React.ReactNode;
};

export const RevenueContextProvider = ({ children }: revenuePnlContext) => {
  const params: any = useParams();

  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);

  let planSubmissionId = inputFormDataState?.metaData?.planSubmissionId
    ? inputFormDataState?.metaData?.planSubmissionId
    : Number(params?.id);

  const [revenuePnlData, setRevenuePnlData] = useState<any>([]);
  const [revenuePriceIncrease, setRevenuePriceIncrease] = useState<any>([]);
  const [revenuePriceIncreaseCompare, setRevenuePriceIncreaseCompare] =
    useState<any>([]);

  const [profitsAndLossesTableData, setProfitsAndLossesTableData] =
    useState<any>([]);

  const [winsNetRevenueData, setWinsNetRevenueTableData] = useState<any>([]);
  const [winsNetRevenueCompareData, setWinsNetRevenueCompareTableData] =
    useState<any>([]);

  const [lossesNetRevenueData, setLossesNetRevenueTableData] = useState<any>(
    []
  );
  const [
    lossesNetRevenueCompareTableData,
    setLossesNetRevenueCompareTableData,
  ] = useState<any>([]);

  const [feePerExitData, setFeePerExitTableData] = useState<any>([]);
  const [exitProgrammePhasesData, setExitProgrammePhasesTableData] =
    useState<any>([]);

  const [increaseInHoursData, setIncreaseInHoursTableData] = useState<any>([]);

  const [exitPhasingData, setExitPhasingTableData] = useState<any>([]);
  const [exitPhasingCompareTableData, setExitPhasingCompareTableData] =
    useState<any>([]);

  const [annualInflationData, setAnnualInflationTableData] = useState<any>([]);
  const [annualInflationCompareTableData, setAnnualInflationCompareTableData] =
    useState<any>([]);

  const [multipleErrorsRev, setMultipleErrorsRev] = useState<boolean>(false);

  const [compareTableData, setCompareTableData] = useState<any>({
    revenuePnlData: [],
    revenuePriceIncrease: [],
    profitsAndLosses: [],
    winsNetRevenueData: [],
    lossesNetRevenueData: [],
    exitProgrammePhasesData: [],
    feePerExitTableData: [],
    increaseInHoursData: [],
    exitPhasingData: [],
    annualInflationData: [],
  });
  const revenueTableIdList = [
    TABLE_MAPPINGS.fy23Rev,
    TABLE_MAPPINGS.annualinflation,
    TABLE_MAPPINGS.plCosts,
    TABLE_MAPPINGS.winsNetRev,
    TABLE_MAPPINGS.lossesNetRev,
    TABLE_MAPPINGS.exitProgramme,
    TABLE_MAPPINGS.feePerExit,
    TABLE_MAPPINGS.exitPhasing,
    TABLE_MAPPINGS.revPriceIncrease,
    TABLE_MAPPINGS.increaseHoursDeliver,
  ];

  useEffect(() => {
    switch (inputFormDataState?.currentRevPlNavigation) {
      case revenueAndPlTablesList[0].refId:
        revenuePnlData.length == 0 &&
          getRevenuePnlTableDataContext(planSubmissionId).then((data) => {
            setRevenuePnlData(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              revenuePnlData: data,
            }));
          });
        break;
      case revenueAndPlTablesList[1].refId:
        annualInflationData.length == 0 &&
          getAnnualInflationTableDataContext(planSubmissionId).then((data) => {
            setAnnualInflationTableData(
              RearrangingTheArrWithFourYearsWithoutPS(
                data,
                REVENUE_PNL.ANNUAL_INFLATION_ID
              )
            );
            setAnnualInflationCompareTableData(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              annualInflationData: RearrangingTheArrWithFourYearsWithoutPS(
                data,
                REVENUE_PNL.ANNUAL_INFLATION_ID
              ),
            }));
          });
        break;
      case revenueAndPlTablesList[2].refId:
        profitsAndLossesTableData.length == 0 &&
          getProfitsAndLossesTableDataContext(planSubmissionId).then((data) => {
            setProfitsAndLossesTableData(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              profitsAndLosses: data,
            }));
          });
        break;

      case revenueAndPlTablesList[3].refId:
        winsNetRevenueData.length == 0 &&
          getWinsNetRevenueTableDataContext(planSubmissionId).then((data) => {
            setWinsNetRevenueTableData(
              RearrangingTheArrWithFourYears(
                data,
                REVENUE_PNL.WINSET_REVENUE_ID
              )
            );
            setWinsNetRevenueCompareTableData(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              winsNetRevenueData: RearrangingTheArrWithFourYears(
                data,
                REVENUE_PNL.WINSET_REVENUE_ID
              ),
            }));
          });
        break;
      case revenueAndPlTablesList[4].refId:
        lossesNetRevenueData.length == 0 &&
          getLossesNetRevenueTableDataContext(planSubmissionId).then((data) => {
            setLossesNetRevenueTableData(
              RearrangingTheArrWithFourYears(
                data,
                REVENUE_PNL.LOSSES_NET_REVENUE_ID
              )
            );
            setLossesNetRevenueCompareTableData(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              lossesNetRevenueData: RearrangingTheArrWithFourYears(
                data,
                REVENUE_PNL.LOSSES_NET_REVENUE_ID
              ),
            }));
          });
        break;
      case revenueAndPlTablesList[5].refId:
        exitProgrammePhasesData.length == 0 &&
          getExitProgrammePhasesTableDataContext(planSubmissionId).then(
            (data) => {
              setExitProgrammePhasesTableData(data);
              setCompareTableData((prevVal: any) => ({
                ...prevVal,
                exitProgrammePhasesData: data,
              }));
            }
          );
        break;
      case revenueAndPlTablesList[6].refId:
        feePerExitData.length == 0 &&
          getFeePerExitHoursTableDataContext(planSubmissionId).then((data) => {
            setFeePerExitTableData(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              feePerExitTableData: data,
            }));
          });
        break;
      case revenueAndPlTablesList[7].refId:
        exitPhasingData.length == 0 &&
          getExitPhasingTableDataContext(planSubmissionId).then((data) => {
            setExitPhasingTableData(
              RearrangingTheArrWithFourYears(data, REVENUE_PNL.EXIT_PHASING_ID)
            );
            setExitPhasingCompareTableData(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              exitPhasingData: RearrangingTheArrWithFourYears(
                data,
                REVENUE_PNL.EXIT_PHASING_ID
              ),
            }));
          });
        break;
      case revenueAndPlTablesList[8].refId:
        revenuePriceIncrease.length == 0 &&
          getRevenuePriceIncreaseDataContext(planSubmissionId).then((data) => {
            setRevenuePriceIncrease(
              RearrangingTheArrWithFourYears(
                data,
                REVENUE_PNL.REVENUE_PRICE_INCREASE_ID
              )
            );
            setRevenuePriceIncreaseCompare(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              revenuePriceIncrease: RearrangingTheArrWithFourYears(
                data,
                REVENUE_PNL.REVENUE_PRICE_INCREASE_ID
              ),
            }));
          });
        break;
      case revenueAndPlTablesList[9].refId:
        increaseInHoursData.length == 0 &&
          getIncreaseInHoursTableDataContext(planSubmissionId).then((data) => {
            setIncreaseInHoursTableData(data);
            setCompareTableData((prevVal: any) => ({
              ...prevVal,
              increaseInHoursData: data,
            }));
          });
        break;

      default:
        break;
    }
  }, [inputFormDataState?.currentRevPlNavigation]);

  return (
    <RevenuePnlContext.Provider
      value={{
        revenuePnlData,
        setRevenuePnlData,
        revenuePriceIncrease,
        revenuePriceIncreaseCompare,
        setRevenuePriceIncrease,
        profitsAndLossesTableData,
        setProfitsAndLossesTableData,
        winsNetRevenueData,
        winsNetRevenueCompareData,
        setWinsNetRevenueTableData,
        lossesNetRevenueData,
        lossesNetRevenueCompareTableData,
        setLossesNetRevenueTableData,
        feePerExitData,
        setFeePerExitTableData,
        exitProgrammePhasesData,
        setExitProgrammePhasesTableData,
        increaseInHoursData,
        setIncreaseInHoursTableData,
        exitPhasingData,
        exitPhasingCompareTableData,
        setExitPhasingTableData,
        compareTableData,
        setCompareTableData,
        multipleErrorsRev,
        setMultipleErrorsRev,
        revenueTableIdList,
        annualInflationData,
        annualInflationCompareTableData,
        setAnnualInflationTableData,
      }}
    >
      {children}
    </RevenuePnlContext.Provider>
  );
};

export function useAPI() {
  const context = useContext(RevenuePnlContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
