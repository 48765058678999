import { useState, useEffect, useRef, createContext } from "react";
import { useAPI } from "./liberatedHoursContextProvider";
import { useAreaSelection } from "utils/area-selection";
import Icon from "components/shared/icon/icon";
import Table from "../shared/table/table";
import {
  productSegmentsMapping,
  COST_ASSUMPTION_TABLE,
  PERCENTAGE_ABOVE_100_ERROR,
  TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS,
  LIBERATED_HOURS_TABLE_IDS,
  TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES,
} from "utils/constants";
import { updateCostAdoptionTableData } from "utils/useInputForm";
import { cleanTableData } from "components/shared/commons/globalFunctions";

const SelectionContext = createContext<DOMRect | null>(null);

const CostAdoptionAssumptions = ({
  dropDownSelected,
  showModal,
  showUpdateInputsPopup,
  liberatedHoursDirtyTables,
  setLiberatedHoursDirtyTables,
}: any) => {
  const BLOCK = "headcountsTable";
  const subHeadingBlock = "headcounts";

  const {
    costAssumptionCenterData,
    setCostAssumptionCenterData,
    compareTableData,
    costAssumptionLoader,
    deployementAndLiberatedHoursData,
  }: any = useAPI();

  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });
  const [prevValue, setPrevValue] = useState<any>("");
  const [dropDown, setDropDown] = useState<any>("");

  useEffect(() => {
    setDropDown((prevValue: any) => {
      if (prevValue && prevValue !== dropDownSelected) {
        setCostAssumptionCenterData((costAssumptionPrevVal: any) => {
          return {
            ...costAssumptionPrevVal,
            [productSegmentsMapping[prevValue]]: cleanTableData(
              costAssumptionCenterData[productSegmentsMapping[prevValue]]
            ),
          };
        });
      }
      return dropDownSelected;
    });
  }, [dropDownSelected]);

  let tableId =
    dropDownSelected === 2
      ? TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costOmnia
      : TABLE_MAPPINGS_COST_BENIFIT_ADOPTION_ASSUMPTIONS.costLEVVIA;

  let tableData =
    costAssumptionCenterData &&
    Object.keys(costAssumptionCenterData).length &&
    costAssumptionCenterData[productSegmentsMapping[dropDownSelected]]
      ? costAssumptionCenterData[productSegmentsMapping[dropDownSelected]]
      : [];

  let deployementTableId =
    dropDownSelected === 2
      ? TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES.deployementOmnia
      : TABLE_MAPPINGS_DEPLOYEMENT_OF_FILES.deployementLEVVIA;

  let deployementTableData =
    deployementAndLiberatedHoursData &&
    Object.keys(deployementAndLiberatedHoursData).length &&
    deployementAndLiberatedHoursData[productSegmentsMapping[dropDownSelected]]
      ? deployementAndLiberatedHoursData[
          productSegmentsMapping[dropDownSelected]
        ]
      : [];

  const [totalAdoptionEfficiency, setTotalAdoptionEfficiency] =
    useState<any>(0);

  useEffect(() => {
    let totalVal = 0;
    tableData &&
      tableData?.map((item: any) => {
        if (!isNaN(item.value) && item.leverId !== 6) {
          totalVal += Number(item.value);
        }
      });
    setTotalAdoptionEfficiency(totalVal);
  }, [tableData]);

  const getTableHeaders = () => {
    const tableHeaders = [
      {
        text: "liberatedHours.costAdoption",
        isFormattedText: true,
      },
      {
        text: "%",
        isFormattedText: false,
      },
    ];

    return tableHeaders;
  };

  const handleOnChange = (
    leverId: number,
    inputVal: any,
    roundOff: boolean = false
  ) => {
    let totalEfficiencyUpdatedTableData =
      tableData &&
      tableData?.length > 0 &&
      tableData?.map((item: any) => {
        if (item.leverId === 6) {
          return { ...item, value: totalAdoptionEfficiency };
        } else {
          return item;
        }
      });

    setPrevValue(inputVal);
    updateCostAdoptionTableData(
      leverId,
      inputVal,
      roundOff,
      totalEfficiencyUpdatedTableData,
      setCostAssumptionCenterData,
      compareTableData?.costAssumptionCenterData[
        productSegmentsMapping[dropDownSelected]
      ],
      liberatedHoursDirtyTables,
      setLiberatedHoursDirtyTables,
      LIBERATED_HOURS_TABLE_IDS.COST_ADOPTION_ASSUMPTIONS,
      setPrevValue,
      prevValue,
      true,
      dropDownSelected
    );
  };

  const FocusChage = (inputVal: any, gradeLevelId: any) => {
    handleOnChange(gradeLevelId, inputVal, true);
  };

  const handleEnter = (evt: any, keyIdentifier: any) => {
    if (evt.key === "Enter") {
      let localKeyIdentifier: any = [];

      tableData?.map((item: any) => {
        item.leverId !== 6 &&
          localKeyIdentifier.push(`${tableId}#${item.leverId}#${1}`);
      });

      let index = localKeyIdentifier.findIndex(
        (item: any) => item === keyIdentifier
      );
      let element;
      if (index > -1) {
        element = document.getElementById(localKeyIdentifier[index + 1]);
      }

      if (!element) {
        document
          .getElementById(
            `${deployementTableId}#${deployementTableData[0]?.leverId}#${deployementTableData[0]?.year}`
          )
          ?.focus();
      } else {
        element?.focus();
      }
    }
  };

  const getRowItems = () => {
    let rowItems =
      tableData &&
      tableData?.length > 0 &&
      tableData?.map((item: any, index: number) => {
        let tableRows = {
          id: item.leverId,
          key: `${item.leverId}-${index}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: COST_ASSUMPTION_TABLE[item.leverId],
              highlight: item.leverId === 6 ? true : false,
            },
            {
              textInput: true,
              error: item.error,
              errorText: PERCENTAGE_ABOVE_100_ERROR,
              errorToastNotRequired: item?.errorToastNotRequired ? true : false,
              onBlur: (e: any) => {
                FocusChage(e.target.value, item.leverId);
              },
              onchangeHandler: (e: any) => {
                handleOnChange(item.leverId, e.target.value);
              },
              onHandleEnter: (evt: any, keyIdentifier: any) => {
                setPrevValue(evt?.target?.value);
                handleEnter(evt, keyIdentifier);
              },
              allowNegValues: true,
              placholder: "-",
              inputIcon: true,
              inputDisable: item.leverId === 6 ? true : false,
              inputValue:
                item.leverId === 6 ? totalAdoptionEfficiency : item.value,
              roundNumbers: item.leverId === 6 ? 2 : 5,
            },
          ],
        };
        return tableRows;
      });

    return rowItems;
  };

  return (
    <div data-test="costAdoptionAssumption-table">
      <SelectionContext.Provider value={selection}>
        <div ref={selectContainerRef}>
          {costAssumptionLoader ? (
            <div className={`${subHeadingBlock}__spinner-container`}>
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className={`${subHeadingBlock}--loading`}
              />
            </div>
          ) : (
            <Table
              className={`${BLOCK}--innerWrapper`}
              headerItems={getTableHeaders()}
              rowItems={getRowItems()}
              innerClassName={BLOCK}
              selection={selection}
              tableId={tableId}
            />
          )}
        </div>
      </SelectionContext.Provider>
    </div>
  );
};

export default CostAdoptionAssumptions;
