import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { loadPromotedHeadCounts } from "services/visualDashboard/visualDashBoardSlice";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { ConsolidationsKeyInitial } from "utils/constants";

type PromotedHeadCount = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const PromotedHeadCountTable = ({
  geographies,
  currencyCode,
  baseYear,
}: PromotedHeadCount) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        loadPromotedHeadCounts({
          countryCodes: geographies,
          currencyCode,
          baseYear,
        })
      );
    }
  }, [geographies, currencyCode, baseYear]);

  return (
    <div data-test="promotedHeadCountTable-component">
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.promotedHeadcountsData
            ?.consolidatedHeadCountDetails
        }
        tableHeader={
          consolidationData?.promotedHeadcountsData?.subTotalHeadCount
        }
        mainTableHeader="consolidation.promoted.headcount"
        subConsolidationTableData={
          consolidationData?.promotedHeadcountsData
            ?.subConsolidatedHeadCountDetails
        }
        keyInitial={ConsolidationsKeyInitial.PROMOTED_HEADCOUNT}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return (
              item === VISUAL_DASHBOARD_OPTIONS.PROMOTED_HEADCOUNTS_LOADING
            );
          })?.length > 0
        }
        isGradeLevelTable={true}
      />
    </div>
  );
};

export default PromotedHeadCountTable;
