export enum VISUAL_DASHBOARD_OPTIONS {
  CURRENCY_LOADING = "CurrencyLoading",
  CONSOLIDATION_GEOGRAPHIES_LOADING = "ConsolidationGeographyLoading",
  NETREVENUE_BRIDGES_LOADING = "NetRevenueBrigesGraphLoading",
  GM_BRIDGES_LOADING = "GMBridgesLoading",
  NET_REVENUE_LOADING = "NetRevenueLoading",
  PRICING_IMPROVEMENTS_LOADING = "PricingImprovementLoading",
  TOTAL_TECHNICAL_STAFF_COST_LOADING = "TotalTechnicalStaffCostLoading",
  TECHNICAL_STAFF_COST_ONSHORE_LOADING = "TechnicalStaffCostOnshoreLoading",
  TECHNICAL_STAFF_COST_USI_LOADING = "TechnicalStaffCostUSILoading",
  TECHNICAL_STAFF_COST_RADC_LOADING = "TechnicalStaffCostRADCLoading",
  TECHNICAL_STAFF_COST_LOCAL_DC_LOADING = "TechnicalStaffCostLocalDCLoading",
  TECHNICAL_STAFF_COST_LOCAL_COE_LOADING = "TechnicalStaffCostLocalCOELoading",
  NET_PROFIT_LOADING = "NetProfitLoading",
  NET_MARGIN_LOADING = "NetMarginLoading",
  GROSS_PROFIT_LOADING = "GrossProfitLoading",
  COST_PER_HOUR_LOADING = "CostPerHourLoading",
  TOTAL_HOURS_LOADING = "TotalHoursLoading",
  NET_FEE_PER_HOUR_LOADING = "NetFeePerHourLoading",
  TOTAL_HEADCOUNTS_COHORTS_LOADING = "TotalHeadcountsCohortsLoading",
  PROMOTED_HEADCOUNTS_LOADING = "PromotedHeadcountsLoading",
  CONSOLIDATION_TRANSFORMATION_ASSUMPTION_LOADING = "ConsolidationTransformationLoading",
  ATTRITION_LOSS_LOADING = "AttritionLossLoading",
  NET_REVENUE_AFTER_PRICING_LOADING = "NetRevenueAfterPricingLoading",
  ACCUM_EXITS_LOADING = "AccumExitsLoading",
  GROSS_MARGIN_LOADING = "GrossMarginLoading",
  ONSHORE_COHORT_LOADING = "OnshoreCohortLoading",
  USI_COHORT_LOADING = "UsiCohortLoading",
  RADC_COHORT_LOADING = "RadcCohortLoading",
  LOCAL_DC_COHORT_LOADING = "LocalDCCohortLoading",
  LOCAL_COE_COHORT_LOADING = "LocalCOECohortLoading",
  TOTAL_HEADCOUNTS_GRADE_LOADING = "TotalHeadcountsGradeLoading",
  ONSHORE_GRADE_LOADING = "OnshoreCohortLoading",
  USI_GRADE_LOADING = "UsiCohortLoading",
  RADC_GRADE_LOADING = "RadcCohortLoading",
  LOCAL_DC_GRADE_LOADING = "LocalDCCohortLoading",
  LOCAL_COE_GRADE_LOADING = "LocalCOECohortLoading",
  OVERHEADS_TOTAL_LOADING = "OverHeadsTotalLoading",
  OVERHEADS_FTE_LOADING = "OverHeadsFTELoading",
  OVERHEADS_REVENUE_LOADING = "OverHeadsRevenueLoading",
  OVERHEADS_ALLOCATIONS_LOADING = "OverHeadsAllocationsLoading",
  OVERHEADS_OTHER_LOADING = "OverHeadsOtherLoading",
}

export enum DashboardTables {
  NET_REVENUE = "netRevenueDetails",
  NET_REVENUE_AFTER_PRICNG = "netRevenueAfterPricingExitsDetails",
  PRICING_IMPROVEMENTS = "pricingImprovements",
  TOTAL_TECHNICAL_STAFF_COST = "totalTechnicalStaffCosts",
  TECHNICAL_STAFF_COST_DELIVERY_CENTERS = "technicalStaffCosts",
  NET_PROFIT = "netProfits",
  NET_MARGIN = "netMargin",
  GROSS_PROFIT = "grossProfits",
  COST_PER_HOUR = "costPerHours",
  TOTAL_HOURS = "totalHours",
  NET_FEE_PER_HOUR = "netFeePerHours",
  TOTAL_HEADCOUNTS_COHORTS = "totalHeadcountCohorts",
  TOTAL_HEADCOUNTS_GRADES = "totalHeadcountGrades",
  PROMOTED_HEADCOUNTS = "promotedHeadcount",
  PROFIT_AND_LOSS_SECTION = "profitandloss",
  GROSS_MARGIN_SECTION = "grossMargin",
  GROSS_MARGIN = "grossMargin",
  HEADCOUNT_SECTION = "headCount",
  ATTRITION_LOSS = "attritionLoss",
  ACCUM_EXITS = "accumExitsInclPricingForegoneDetails",
  HEAD_COUNT_BY_COHORTS = "headcountByCohorts",
  HEAD_COUNT_BY_GRADES = "headcountByGrades",
  OVER_HEADS_DETAIL = "overheadDetails",
}

export enum DeliveryCenter {
  ONSHORE = 0,
  USI = 1,
  RADC = 2,
  LOCAL_DC = 3,
  LOCAL_COE = 4,
}

export enum OverHeadTypeId {
  Total = 0,
  Overheads_Other = 1,
  Direct_Overheads_Headcount_Driven = 2,
  Direct_Overheads_Revenue_Driven = 3,
  Overheads_Allocations_and_Non_Operating_Expenses = 5,
}

export type CurrencyList = {
  currencyCode: string;
  name: string;
};
export type CurrencySet = {
  currency: string[];
};

export type SetConsolidationGeographies = {
  checked?: boolean;
  parentCheckBoxSelected?: string[];
  subParentCheckBoxSelected?: string[];
  childCheckBoxSelected?: string[];
  childSubConsolidationGeographyCheckBoxSelected?: string[];
};

export type ConsolidationGeographiesData = {
  code: string;
  name: string;
  parentId?: string;
  subParentId?: string;
};

export type SubConsolidationGeographiesData = {
  code: string;
  geographies: ConsolidationGeographiesData[];
  name: string;
  subConsolidations: SubConsolidationGeographiesData[];
  parentId?: string;
};

export type ConsolidationGeographies = {
  code: string;
  geographies: ConsolidationGeographiesData[];
  name: string;
  subConsolidations: SubConsolidationGeographiesData[];
};

export type NetRevenueBridges = {
  productSegmentId: number;
  productSegment: string;
  fyYear: number;
  baseYear: any;
  wins: any;
  losses: any;
  exits: any;
  pricing: any;
  lastForecastedYear: any;
};
export type subTotalGrossMargin = {
  value: Number;
  year: Number;
};

export type ConsolidationTransformationAssumptionsCommonModel = {
  dcTotalUsage: number;
  year: number;
  productSegmentId: number;
  netTransInitiativeEffImprovement: number;
  netTransInitiativeNetHoursChange: number;
  netTransInitiativeAccumulatedNetHoursChange: number;
  accumulatedTASummaryDetails: [];
  accumulatedOmniaRADCDetails: [];
  accumulatedOmniaUSIDetails: [];
  accumulatedLevviaDetails: [];
  accumulatedTier5Details: [];
  accumulatedAssuranceDetails: [];
  accumulatedOtherDetails: [];
};

export type ConsolidationTransformationAssumptions = {
  accumulatedAssuranceDetails: ConsolidationTransformationAssumptionsCommonModel[];
  accumulatedLevviaDetails: ConsolidationTransformationAssumptionsCommonModel[];
  accumulatedOmniaRADCDetails: ConsolidationTransformationAssumptionsCommonModel[];
  accumulatedOmniaUSIDetails: ConsolidationTransformationAssumptionsCommonModel[];
  accumulatedOtherDetails: ConsolidationTransformationAssumptionsCommonModel[];
  accumulatedTier5Details: ConsolidationTransformationAssumptionsCommonModel[];
  dcTotalUsage: number;
};

export type consolidatedProfitAndLossDetails = {
  consolidatedProfitAndLossDetails: any[];
  countryName: string | null;
  productSegment: string;
  productSegmentId: number;
  subConsolidatedProfitAndLossDetails: any[];
  subTotalProfitAndLoss: any[];
  value: number;
  year: number;
};

export type consolidatedGrossMarginDetails = {
  consolidatedGrossMarginDetails: any[];
  countryName: string | null;
  productSegment: string;
  productSegmentId: number;
  subConsolidatedGrossMarginDetails: any[];
  subTotalGrossMargin: any[];
  value: number;
  year: number;
};

export type consolidatedHeadCountDetails = {
  consolidatedGrossMarginDetails: any[];
  countryName: string | null;
  productSegment: string;
  productSegmentId: number;
  subConsolidatedGrossMarginDetails: any[];
  subTotalGrossMargin: any[];
  value: number;
  year: number;
};

export type NetRevenueData = {
  consolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subConsolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subTotalProfitAndLoss: consolidatedProfitAndLossDetails[];
};
export type PricingImprovementData = {
  consolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subConsolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subTotalProfitAndLoss: consolidatedProfitAndLossDetails[];
};
export type TotalTechincalStaffCostsTableData = {
  consolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subConsolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subTotalProfitAndLoss: consolidatedProfitAndLossDetails[];
};
export type OverHeadDetailData = {
  consolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subConsolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subTotalProfitAndLoss: consolidatedProfitAndLossDetails[];
};
export type NetProfitData = {
  consolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subConsolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subTotalProfitAndLoss: consolidatedProfitAndLossDetails[];
};
export type GrossProfitData = {
  consolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subConsolidatedProfitAndLossDetails: consolidatedProfitAndLossDetails[];
  subTotalProfitAndLoss: consolidatedProfitAndLossDetails[];
};
export type CostPerHourData = {
  consolidatedGrossMarginDetails: consolidatedGrossMarginDetails[];
  subConsolidatedGrossMarginDetails: consolidatedGrossMarginDetails[];
  subTotalGrossMargin: consolidatedGrossMarginDetails[];
};
export type NetFeePerHour = {
  consolidatedGrossMarginDetails: consolidatedGrossMarginDetails[];
  subConsolidatedGrossMarginDetails: consolidatedGrossMarginDetails[];
  subTotalGrossMargin: consolidatedGrossMarginDetails[];
};
export type TotalHeadcountCohortData = {
  consolidatedHeadCountDetails: consolidatedHeadCountDetails[];
  subConsolidatedHeadCountDetails: consolidatedHeadCountDetails[];
  subTotalHeadCount: consolidatedHeadCountDetails[];
};
