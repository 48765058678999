import Modal from "../modal/modal";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

type AttentionPopupProps = {
  showModal: Function;
  message: string;
  onConfirm: Function;
  setShowCopyToPopup: Function;
};

const AttentionPopup = ({
  showModal,
  message,
  onConfirm,
  setShowCopyToPopup,
}: AttentionPopupProps) => {
  const BLOCK = "attentionPopup";

  const handleClose = () => {
    showModal(false);
    message === `copy.to.attention.pop.up.message` && setShowCopyToPopup(true);
  };

  const handleConfirm = () => {
    onConfirm();
    showModal(false);
  };

  return (
    <Modal
      show={true}
      header={"attention.pop.up.header"}
      config={false}
      hideBackgroud={false}
      data-test="attention-popup"
      showHeaderIcon={true}
    >
      <div className={BLOCK}>
        <div className={`${BLOCK}__content`}>
          <div className={`${BLOCK}__content--text`}>
            <FormattedMessage id={message} />
          </div>
          <div className={`${BLOCK}__content--footer`}>
            <div className={`${BLOCK}__content--footer--actions--warning`}>
              <button
                className={`${BLOCK}__content--footer--actions--cancel`}
                onClick={() => handleClose()}
                data-test="closeButton"
              >
                <FormattedMessage id={`input.form.submit.review.cancel`} />
              </button>
              <button
                className={`${BLOCK}__content--footer--actions--confirm`}
                onClick={() => handleConfirm()}
                data-test="continueButton"
              >
                <FormattedMessage id={`input.form.submit.review.confirm`} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AttentionPopup;
