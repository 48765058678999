import MainHeader from "components/header/header-new";
import Footer from "../footer/footer";
import SideMenu from "../side-menu/side-menu";
import LoaderPage from "../unauthorized/loaderPage";
import ToastContainer from "../shared/toast/toast-container";
import ToolTipContainer from "../shared/tooltip/tooltip.container";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { useLocation } from "react-router";
import {
  APP_SETTING,
  IGNORADED_MESSAGES,
  logout,
  STATUS_CODE,
  welcomeDashboardPathname,
  loginPath,
} from "../../utils/constants";
import { useDispatch } from "react-redux";
import api from "../../services/api.service";
import { useEffect, useState } from "react";
import Downloads from "components/downloads/downloads";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import SessionTimeoutModal from "../shared/session-timeout-modal/session-timeout-modal";
import {
  loadCurrentUser,
  addToastMessage,
  userLogs,
} from "../../services/common/commonsSlice";

type Props = {
  hideHeader?: boolean;
  hideSideMenu?: boolean;
  children: JSX.Element;
};

const Layout = ({ hideHeader, hideSideMenu, children }: Props) => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const [showNotifications, setShowNotifications] = useState(true);

  useEffect(() => {
    // Interceptor for catching erros and displaying toast messages
    api.interceptors.response.use(undefined, (err) => {
      const { response } = err;
      if (
        response &&
        (response.status === STATUS_CODE.INTERNAL_SERVER_ERROR ||
          response.status === STATUS_CODE.BAD_REQUEST ||
          response.status === STATUS_CODE.REQUEST_TIMEOUT ||
          response.status === STATUS_CODE.NOT_FOUND)
      ) {
        let isIgnored = false;
        if (response.data.messages) {
          isIgnored = Object.values(IGNORADED_MESSAGES).includes(
            response.data.messages[0].code
          );
        }

        if (!isIgnored) {
          dispatch(
            addToastMessage({
              description:
                response.data &&
                response.data.messages &&
                response.data.messages[0].code ? (
                  response.data.messages[0].code
                ) : (
                  <FormattedMessage id={"error.default"} />
                ),
            })
          );
        }
      }

      throw err;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname !== logout && location.pathname !== loginPath) {
      if (isAuthenticated) {
        dispatch(loadCurrentUser());
        dispatch(userLogs());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, dispatch]);

  return (
    <>
      <AuthenticatedTemplate>
        {window === window.parent && <SessionTimeoutModal />}
        {/* {showNotifications && (
          <Notifications setShowNotification={setShowNotifications} />
        )} */}
        {!hideHeader && <MainHeader />}
        {hideSideMenu ? (
          <>
            {children}
            <Footer />
          </>
        ) : (
          <>
            <div
              className="FYP"
              data-test="layout-component-children-esg"
              data-testid="layout-component-children-esg"
            >
              <SideMenu />
              <div className="FYP__content">{children}</div>
            </div>
            {location.pathname === welcomeDashboardPathname && <Footer />}
          </>
        )}
        <Downloads
        // className={
        //   hideSideMenu
        //     ? ""
        //     : location.pathname === dashboard ||
        //       location.pathname === peerBenchmarkPath
        //     ? "dashboard-download"
        //     : `content-download`
        // }
        />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {location.pathname === loginPath ? (
          <>
            <MainHeader />
            <div
              className="FYP"
              data-test="layout-component-children-esg"
              data-testid="layout-component-children-esg"
            >
              {children}
            </div>
            <Footer />
          </>
        ) : (
          <LoaderPage />
        )}
      </UnauthenticatedTemplate>
      <ToastContainer />
      <ToolTipContainer></ToolTipContainer>
    </>
  );
};

export default Layout;
