import { useEffect, useState } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useDispatch, useSelector } from "react-redux";
import { EXPORT_FORMAT, EXPORT_TYPE_MAPPING, Roles } from "utils/constants";
import AddNewGeo from "components/addnewGeo/addNewGeo";
import RollForward from "../roll-forward/roll-forward";
import ConfigurProjectedYearsModal from "../configurationProjectedYears/configurationProjectedYears";
import classNames from "classnames";
import { getRollForwardData } from "../../services/dashboard/dashBoardSlice";
import { sendExportItem } from "services/download/downloadSlice";

type HeaderButtonsProps = {
  BLOCK: string;
};

const DashboardHeaderButtons = ({ BLOCK }: HeaderButtonsProps) => {
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );

  const [showGeographyModal, setShowGeographyModal] = useState(false);
  const [rollForward, setRollForwardModal] = useState(false);
  const [rollForwardStatus, setRollForwardStatus] = useState<boolean>(false);
  const [disableAddGeoBtn, setDisableAddGeoBtn] = useState<boolean>(false);
  const [configurProjectedYearsModal, setConfigurProjectedYearsModal] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const dashboardTableState = useSelector(
    (state: RootStore) => state.dashboard
  );

  useEffect(() => {
    if (
      dashboardTableState.fiscalYearList &&
      Object.keys(dashboardTableState.fiscalYearList).length &&
      dashboardTableState.fiscalYearList[0]?.year &&
      dashboardTableState.selectedYear?.year
    ) {
      if (
        dashboardTableState.selectedYear?.year ===
        dashboardTableState.fiscalYearList[0]?.year
      ) {
        setDisableAddGeoBtn(false);
      } else {
        setDisableAddGeoBtn(true);
      }
    } else {
      setDisableAddGeoBtn(false);
    }
  }, [dashboardTableState.fiscalYearList, dashboardTableState.selectedYear]);

  useEffect(() => {
    setRollForwardStatus(dashboardTableState?.rollForwardData);
  }, [dashboardTableState?.rollForwardData]);

  let currUserRole =
    currentUser && currentUser.userRoles && currentUser.userRoles.length > 0
      ? currentUser.userRoles[0].roleId
      : 0;
  useEffect(() => {
    if (currUserRole === Roles.globalAdmin) dispatch(getRollForwardData());
  }, [currUserRole]);

  const handleOnExport = () => {
    const payload = {
      exportReferenceId: currentUser.userId,
      exportFileName: "User_Logs",
      exportType: EXPORT_TYPE_MAPPING.USER_REPORT,
      exportFormat: EXPORT_FORMAT.xls,
    };
    dispatch(sendExportItem(payload));
  };

  return (
    <>
      {currUserRole === Roles.globalAdmin && (
        <div>
          <button
            className={classNames(`${BLOCK}__user-report-button`)}
            onClick={() => {
              handleOnExport();
            }}
            data-test="userReportDownloadButton"
          >
            <span className={`${BLOCK}__save-text`}>
              <FormattedMessage id={`download.user.report`} />
            </span>
          </button>
        </div>
      )}
      {currUserRole === Roles.globalAdmin && (
        <div className={`${BLOCK}__header--rollForward`}>
          <button
            className={classNames(`${BLOCK}__header--rollForward--btn`, {
              [`${BLOCK}__header--rollForward--btn--enabled`]:
                !disableAddGeoBtn,
              [`${BLOCK}__header--rollForward--btn--disabled`]:
                disableAddGeoBtn,
            })}
            onClick={() => {
              setConfigurProjectedYearsModal(true);
            }}
            data-test="projectedYearButton"
          >
            <span className={`${BLOCK}__save-text`}>
              <FormattedMessage id={`configuration.projected.years`} />
            </span>
          </button>
        </div>
      )}
      {currUserRole === Roles.globalAdmin && (
        <div
          className={`${BLOCK}__header--rollForward`}
          data-test="rollForward"
        >
          <button
            className={classNames(`${BLOCK}__header--rollForward--btn`, {
              [`${BLOCK}__header--rollForward--btn--enabled`]:
                rollForwardStatus,
              [`${BLOCK}__header--rollForward--btn--disabled`]:
                !rollForwardStatus,
            })}
            data-test="rollForwardButton"
            disabled={!rollForwardStatus}
            onClick={() => {
              if (rollForwardStatus) {
                setRollForwardModal(true);
              }
            }}
          >
            <span className={`${BLOCK}__save-text`}>
              <FormattedMessage id={`roll.forward`} />
            </span>
          </button>
        </div>
      )}
      {(currUserRole === Roles.globalAdmin ||
        currUserRole === Roles.memberFirmAdmin) && (
        <div className={`${BLOCK}__geo-section`}>
          <button
            className={classNames(`${BLOCK}__geo-button`, {
              [`${BLOCK}__geo-button`]: !disableAddGeoBtn,
              [`${BLOCK}__header--rollForward--btn--disabled`]:
                disableAddGeoBtn,
            })}
            data-test="addGeoButton"
            onClick={() => setShowGeographyModal(true)}
            disabled={disableAddGeoBtn}
          >
            <FormattedMessage id="geo.button" />
          </button>
        </div>
      )}

      {showGeographyModal && (
        <div>
          <AddNewGeo setShowGeographyModal={setShowGeographyModal} />
        </div>
      )}
      {rollForward && (
        <div>
          <RollForward setRollForwardModal={setRollForwardModal} />
        </div>
      )}
      {configurProjectedYearsModal && (
        <div>
          <ConfigurProjectedYearsModal
            setConfigurProjectedYearsModal={setConfigurProjectedYearsModal}
          />
        </div>
      )}
    </>
  );
};

export default DashboardHeaderButtons;
