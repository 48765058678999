import GMBridgeNav from "./gmBridgeNav";

const GMBridge = ({ yearList }: any) => {
  const BLOCK = "gmBridge";
  return (
    <div className={`${BLOCK}`}>
      <GMBridgeNav BLOCK={BLOCK} yearList={yearList} />
    </div>
  );
};

export default GMBridge;
