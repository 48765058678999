import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  CONSOLIDATED_REVENUE_MOCK,
  GraphLabels,
  ConsolidatedGraphLabels,
  ConsolidatedNetRvenueByYearLabels,
  cohorts,
  GMBridgeY,
  GMBridgeX,
  GMBridgeBreakdown,
} from "utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import { useEffect, useState } from "react";
import Icon from "components/shared/icon/icon";
import GraphLegends from "./graphLegends";
import RevenueGraphLegends from "./revenueGraphLegends";
import WaterFallChart from "./waterFallChart";
import {
  getNetrevenueBridgeGraphsData,
  getConsolidatedNetRevenueGraphsData,
  getNetFeePerHourGraphsData,
  getNetCostPerHourGraphsData,
  getGMBridgeGraphsData,
  setGMBridgeCohort,
  setGMBridgeStartYear,
  setGMBridgeEndYear,
  loadNetRevenueAfterPricing,
} from "services/visualDashboard/visualDashBoardSlice";
import VerticalBarChart from "./verticalBarChart";
import GMBridge from "components/GMBridge/gmBridge";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { completeYear } from "../shared/commons/globalFunctions";
import { getConfiguredProjectedYear } from "services/dashboard/dashBoardSlice";
import GMBridgeChart from "./gmBridgeChart";
import Decimal from "decimal.js";

const ConsolidatedDataGraph = () => {
  const dispatch = useDispatch();
  const BLOCK = "visual_graph";
  const [NetRevenue, setNetRevenue] = useState(null);
  const [ConsolidatedNetRevenue, setConsolidatedNetRevenue] = useState(null);
  const [RevenueOmniaUsi, setRevenueOmniaUsi] = useState(null);
  const [RevenueLevvia, setRevenueLevvia] = useState(null);
  const [RevenueTier5, setRevenueTier5] = useState(null);
  const [RevenueAssurance, setRevenueAssurance] = useState(null);
  const [RevenueOther, setRevenueOther] = useState(null);
  const [RevenueFeeAndCostPerHr, setRevenueFeeAndCostPerHr] = useState(null);
  const [arrayYears, setArrayYears] = useState<any[]>([]);
  const [yearList, setYearList] = useState<any>([]);
  const [GMBridgeData, setGMBridgeData] = useState(null);
  const [grossProfitData, setGrossProfitData] = useState<any[]>([]);

  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  const dashboardTableState = useSelector(
    (state: RootStore) => state.dashboard
  );
  const [currency, setCurrency] = useState<any>("__");
  const [loopYears, setLoopYears] = useState<any>(4);

  useEffect(() => {
    if (
      consolidationData?.consolidationGeographiesSelected &&
      Object.keys(consolidationData?.consolidationGeographiesSelected).length &&
      consolidationData?.currency &&
      Object.keys(consolidationData?.currency).length &&
      dashboardTableState?.fiscalYearList &&
      Object.keys(dashboardTableState?.fiscalYearList).length &&
      dashboardTableState?.fiscalYearList[0]?.year
    ) {
      let currency: any = consolidationData?.currency[0]
        ? consolidationData?.currency[0]
        : null;

      let currencyName = currency?.flat(Infinity);
      let currencyCode: string = currencyName
        ? currencyName[0]?.split("-")[0]?.replace(/\s/g, "")
        : null;

      setCurrency(currencyCode ? currencyCode : "__");
      const Geographies: any =
        consolidationData?.consolidationGeographiesSelected
          ?.childCheckBoxSelected;
      const SubGeographies: any =
        consolidationData?.consolidationGeographiesSelected
          .childSubConsolidationGeographyCheckBoxSelected &&
        Object.keys(
          consolidationData?.consolidationGeographiesSelected
            .childSubConsolidationGeographyCheckBoxSelected
        ).length
          ? consolidationData?.consolidationGeographiesSelected
              .childSubConsolidationGeographyCheckBoxSelected
          : null;
      let customGeographies = SubGeographies
        ? Geographies.concat(SubGeographies)
        : Geographies;

      if (Geographies && currencyCode) {
        dispatch(
          loadNetRevenueAfterPricing({
            countryCodes: customGeographies,
            currencyCode: currencyCode,
            baseYear: dashboardTableState?.fiscalYearList[0]?.year,
          })
        )
        dispatch(
          getNetrevenueBridgeGraphsData({
            countryCodes: customGeographies,
            currencyCode: currencyCode,
            baseYear: dashboardTableState?.fiscalYearList[0]?.year,
          })
        );
        dispatch(
          getConsolidatedNetRevenueGraphsData({
            countryCodes: customGeographies,
            currencyCode: currencyCode,
            baseYear: dashboardTableState?.fiscalYearList[0]?.year,
          })
        );
        dispatch(
          getNetFeePerHourGraphsData({
            countryCodes: customGeographies,
            currencyCode: currencyCode,
            baseYear: dashboardTableState?.fiscalYearList[0]?.year,
          })
        );
        dispatch(
          getNetCostPerHourGraphsData({
            countryCodes: customGeographies,
            currencyCode: currencyCode,
            baseYear: dashboardTableState?.fiscalYearList[0]?.year,
          })
        );
        dispatch(
          getConfiguredProjectedYear(
            dashboardTableState.fiscalYearList[0]?.year
          )
        );
      }
    }
  }, [
    consolidationData?.consolidationGeographiesSelected,
    consolidationData?.currency,
    dashboardTableState.fiscalYearList,
  ]);

  useEffect(() => {
    let arr = [dashboardTableState?.fiscalYearList[0]?.year % 100];

    for (let i = 1; i <= dashboardTableState?.configuredYear; i++) {
      arr.push((dashboardTableState?.fiscalYearList[0]?.year + i) % 100);
    }
    setArrayYears(arr);
  }, [
    dashboardTableState?.configuredYear,
    dashboardTableState?.fiscalYearList[0]?.year,
  ]);

  useEffect(() => {
    let sortedYears = [...arrayYears].sort();
    let yearListObject: any = {};
    sortedYears.map((item: any) => {
      yearListObject[`FY${item}`] = completeYear(item);
    });
    setYearList(yearListObject);
    dispatch(setGMBridgeCohort([Object.keys(cohorts)[0]]));
    dispatch(setGMBridgeStartYear([Object.keys(yearListObject)[0]]));
    dispatch(
      setGMBridgeEndYear([
        Object.keys(yearListObject)[Object.keys(yearListObject).length - 1],
      ])
    );
  }, [cohorts, arrayYears]);

  useEffect(() => {
    let currency: any = consolidationData?.currency[0]
      ? consolidationData?.currency[0]
      : null;
    let currencyName = currency?.flat(Infinity);
    let currencyCode: string = currencyName
      ? currencyName[0]?.split("-")[0]?.replace(/\s/g, "")
      : null;

    const Geographies: any =
      consolidationData?.consolidationGeographiesSelected
        ?.childCheckBoxSelected;
    const SubGeographies: any =
      consolidationData?.consolidationGeographiesSelected
        .childSubConsolidationGeographyCheckBoxSelected &&
      Object.keys(
        consolidationData?.consolidationGeographiesSelected
          .childSubConsolidationGeographyCheckBoxSelected
      ).length
        ? consolidationData?.consolidationGeographiesSelected
            .childSubConsolidationGeographyCheckBoxSelected
        : null;
    let customGeographies = SubGeographies
      ? Geographies.concat(SubGeographies)
      : Geographies;

    if (
      Geographies &&
      currencyCode &&
      yearList &&
      Object.keys(yearList)?.length > 0 &&
      consolidationData?.gmBridgeCohort?.[0] &&
      consolidationData?.gmBridgeStartYear?.[0] &&
      consolidationData?.gmBridgeEndYear?.[0] &&
      consolidationData?.gmBridgeStartYear?.[0] !==
        consolidationData?.gmBridgeEndYear?.[0]
    ) {
      dispatch(
        getGMBridgeGraphsData({
          countryCodes: customGeographies,
          currencyCode: currencyCode,
          baseYear: dashboardTableState?.fiscalYearList[0]?.year,
          startYear: yearList[consolidationData?.gmBridgeStartYear],
          endYear: yearList[consolidationData?.gmBridgeEndYear],
          cohort: cohorts[consolidationData?.gmBridgeCohort],
        })
      );
    }
  }, [
    dashboardTableState.fiscalYearList,
    consolidationData?.currency,
    yearList,
    consolidationData?.gmBridgeCohort,
    consolidationData?.gmBridgeStartYear,
    consolidationData?.gmBridgeEndYear,
  ]);

  useEffect(() => {
    setNetRevenue(null)
    if (
      consolidationData &&
      consolidationData?.netRevenueAfterPricingData &&
      Object.keys(consolidationData?.netRevenueAfterPricingData).length &&
      consolidationData?.netRevenueAfterPricingData
        .consolidatedProfitAndLossDetails &&
      Object.keys(
        consolidationData?.netRevenueAfterPricingData
          .consolidatedProfitAndLossDetails
      ).length
    ) {
      let yearsArray: any = {};
      let baseYear: number = Number(
        consolidationData?.netRevenueAfterPricingData
          ?.consolidatedProfitAndLossDetails[0].year
      );

      let maxYearValue = baseYear;
      let arr = [baseYear];
      consolidationData?.netRevenueAfterPricingData?.consolidatedProfitAndLossDetails.forEach(
        (item: any) => {
          if (item.year > maxYearValue) {
            maxYearValue = item.year;
            arr.push(item.year);
          }
        }
      );
      let loopYears = maxYearValue - baseYear;
      setLoopYears(loopYears);
      let i = 0;
      let j = 0;

      while (
        i <= arr.length &&
        j <
          consolidationData?.netRevenueAfterPricingData
            ?.consolidatedProfitAndLossDetails.length
      ) {
        if (
          arr[i] ===
          consolidationData?.netRevenueAfterPricingData
            ?.consolidatedProfitAndLossDetails[j].year
        ) {
          if (yearsArray[`year${i + 1}`]) {
            yearsArray[`year${i + 1}`].push(
              consolidationData?.netRevenueAfterPricingData
                ?.consolidatedProfitAndLossDetails[j]
            );
          } else {
            yearsArray = {
              ...yearsArray,
              [`year${i + 1}`]: [
                consolidationData?.netRevenueAfterPricingData
                  ?.consolidatedProfitAndLossDetails[j] || [],
              ],
            };
          }
          i++;
          j++;
        } else if (i === arr.length) {
          i = 0;
        }
      }

      let resultNetrevenuData: any = [];
      let count = 1;
      for (let i = count; i <= loopYears + 1; i++) {
        let newKey = "year" + i;
        let yearWiseObj = createYearwiseObject(yearsArray[newKey]);
        resultNetrevenuData.push(yearWiseObj);
      }
      setNetRevenue(
        resultNetrevenuData && Object.keys(resultNetrevenuData).length
          ? resultNetrevenuData
          : null
      );
    }
  }, [consolidationData?.netRevenueAfterPricingData?.subTotalProfitAndLoss]);

  const createYearwiseObject = (Array: any) => {
    let Obj: any = {};
    Array?.map((item: any) => {
      Obj["name"] = "FY" + item.year;
      if (item.productSegmentId)
        Obj[ConsolidatedNetRvenueByYearLabels[item.productSegmentId - 2]] =
          item.value ? item.value : 0;
    });
    return Obj;
  };
  useEffect(() => {
    if (
      consolidationData &&
      consolidationData?.netFeePerHrGraphData &&
      Object.keys(consolidationData?.netFeePerHrGraphData).length &&
      consolidationData?.netCostPerHrGraphData &&
      Object.keys(consolidationData?.netCostPerHrGraphData).length
    ) {
      let FeeCostArray: any = [];
      let yearCount: number = 0;

      let netFeePerHourData = [
        ...(consolidationData?.netFeePerHrGraphData || []),
      ];
      netFeePerHourData?.sort((a: any, b: any) => {
        return a.year - b.year;
      });
      let netCostPerHourData = [
        ...(consolidationData?.netCostPerHrGraphData || []),
      ];
      netCostPerHourData?.sort((a: any, b: any) => {
        return a.year - b.year;
      });

      const baseYear = Math.min(
        ...netFeePerHourData.map((item: any) => item.year)
      );

      netFeePerHourData.map((feePerHourObj: any) => {
        netCostPerHourData.map((costPerHourObj: any) => {
          if (feePerHourObj.year === costPerHourObj.year) {
            let Year = baseYear ? Number(baseYear) + yearCount : "__";
            let graphObj = {
              name: "FY" + Year,
              Fee: feePerHourObj.value,
              Cost: costPerHourObj.value,
            };
            yearCount = yearCount + 1;
            FeeCostArray.push(graphObj);
          }
        });
      });
      setRevenueFeeAndCostPerHr(FeeCostArray ? FeeCostArray : null);
    } else {
      setRevenueFeeAndCostPerHr(null);
    }
  }, [
    consolidationData?.netFeePerHrGraphData,
    consolidationData?.netCostPerHrGraphData,
  ]);

  useEffect(() => {
    if (
      consolidationData &&
      consolidationData?.gmBridgeGraphData &&
      Object.keys(consolidationData?.gmBridgeGraphData).length
    ) {
      let consolidatedGMBridgeGraphData = calculateGMBridge(
        consolidationData?.gmBridgeGraphData,
        consolidationData?.gmBridgeToggle
      );
      setGMBridgeData(consolidatedGMBridgeGraphData);
    } else {
      setGMBridgeData(null);
    }
  }, [consolidationData?.gmBridgeGraphData, consolidationData?.gmBridgeToggle]);
  useEffect(() => {
    if (
      consolidationData?.consolidateNetRevnueGraphData &&
      Object.keys(consolidationData?.consolidateNetRevnueGraphData).length &&
      consolidationData?.consolidateNetRevnueGraphData[0] &&
      Object.keys(consolidationData?.consolidateNetRevnueGraphData[0]).length
    ) {
      let consolidatedNetRevenuegraphData = calculateWatterfall(
        consolidationData?.consolidateNetRevnueGraphData[0],
        true
      );
      setConsolidatedNetRevenue(consolidatedNetRevenuegraphData);
    } else {
      setConsolidatedNetRevenue(null);
    }
  }, [consolidationData?.consolidateNetRevnueGraphData]);

  useEffect(() => {
    if (
      consolidationData?.netRevenueBridgesGraphData &&
      Object.keys(consolidationData?.netRevenueBridgesGraphData).length
    ) {
      consolidationData.netRevenueBridgesGraphData.map((item: any) => {
        if (item.productSegmentId === 2) {
          let omniagraphData = calculateWatterfall(item);
          setRevenueOmniaUsi(omniagraphData);
        } else if (item.productSegmentId === 3) {
          let levviagraphData = calculateWatterfall(item);
          setRevenueLevvia(levviagraphData);
        } else if (item.productSegmentId === 4) {
          let tier5graphData = calculateWatterfall(item);
          setRevenueTier5(tier5graphData);
        } else if (item.productSegmentId === 5) {
          let assurancegraphData = calculateWatterfall(item);
          setRevenueAssurance(assurancegraphData);
        } else if (item.productSegmentId === 6) {
          let othergraphData = calculateWatterfall(item);
          setRevenueOther(othergraphData);
        }
      });
    } else {
      setRevenueOmniaUsi(null);
      setRevenueLevvia(null);
      setRevenueTier5(null);
      setRevenueAssurance(null);
      setRevenueOther(null);
    }
  }, [consolidationData?.netRevenueBridgesGraphData]);

  const calculateWatterfall = (item: any, netRevnue: any = false) => {
    if (item && Object.keys(item).length) {
      var resultList: any = [];
      const LABELS = netRevnue
        ? findConsolidatedLabels(item)
        : findLabels(item);
      const MAX_VALUES = findMaxValues(LABELS);
      const isAllZero = LABELS.every((item: any) => item === 0);
      if (!isAllZero) {
        let baseYearObj = {
          name: item.fyYear ? "FY" + item.fyYear : "FY__",
          value: LABELS[0] ? LABELS[0] : [1.1, 1.1],
          label: LABELS[0] ? LABELS[0] : "___",
          error: LABELS[0] === 0 ? "0" : null,
        };
        let listOfLabels: any = [];
        const Data = netRevnue ? ConsolidatedGraphLabels : GraphLabels;
        Data?.map((value: any, index: number) => {
          let labelObj = {
            name: value,
            value: LABELS[index + 1]
              ? [MAX_VALUES[index], MAX_VALUES[index + 1]]
              : [MAX_VALUES[index], MAX_VALUES[index + 1] + 0.7],
            label: LABELS[index + 1] ? LABELS[index + 1] : "___",
            error: LABELS[index + 1] === 0 ? "0" : null,
          };
          listOfLabels.push(labelObj);
        });
        let lastYearObj = {
          name: item.fyYear ? "FY" + parseInt(item.fyYear + loopYears) : "FY__",
          value: LABELS[LABELS?.length - 1]
            ? [0, LABELS[LABELS?.length - 1]]
            : [0, LABELS[LABELS?.length - 1] + 0.7],
          label: LABELS[LABELS?.length - 1]
            ? LABELS[LABELS?.length - 1]
            : "___",
          error: LABELS[LABELS?.length - 1] === 0 ? "0" : null,
        };
        resultList[0] = baseYearObj;
        resultList = resultList.concat(listOfLabels);
        resultList.push(lastYearObj);

        return resultList;
      } else {
        return null;
      }
    }
  };

  const calculateWaterFallGmBridge = (data: any) => {
    let newData = data?.map((obj: any) => ({ ...obj }));

    if (
      newData &&
      newData.length > 0 &&
      consolidationData &&
      consolidationData?.gmBridgeEndYear?.length > 0 &&
      consolidationData?.gmBridgeStartYear?.length > 0
    ) {
      newData[0].name = consolidationData?.gmBridgeStartYear;
      newData[newData.length - 1].name = consolidationData?.gmBridgeEndYear;

      for (let i = 1; i < newData.length; i++) {
        if (i === 1) {
          let num1 = new Decimal(newData[i].value);
          let num2 = new Decimal(newData[i - 1].value);
          let val = num1.plus(num2);
          let calcVal = [num2.toString(), val.toString()];
          newData[i].value = calcVal;
        } else if (i === newData.length - 1) {
          let calcVal = [0, newData[i].label];
          newData[i].value = calcVal;
        } else {
          let num1 = new Decimal(newData[i - 1].value[1]);
          let num2 = new Decimal(newData[i].label);
          let calcVal = [num1.toString(), num1.plus(num2).toString()];
          newData[i].value = calcVal;
        }
      }
    }

    return newData;
  };

  const calculateGMBridge = (item: any, percentageToggle: boolean) => {
    if (item && Object.keys(item).length) {
      let revenueObject: any = { ...item.moveRevenueData };
      revenueObject["startYearValue"] = item.moveRevenueData["baseYear"];
      revenueObject["baseYear"] = item.moveRevenueData["fyYear"];
      revenueObject["endYearValue"] =
        item.moveRevenueData["lastForecastedYear"];
      let revEndYear = new Decimal(0);
      var revData = GMBridgeX.map((e) => {
        let isEndYear = e === GMBridgeX[GMBridgeX.length - 1];
        revEndYear = revEndYear.plus(revenueObject[e] ? revenueObject[e] : 0);
        return {
          name:
            e === "rate increase"
              ? "Rate Increase"
              : e.charAt(0).toUpperCase() + e.slice(1),
          value: isEndYear
            ? revEndYear.toString()
            : revenueObject[e]
            ? revenueObject[e]
            : 0,
          label: isEndYear
            ? revEndYear.toString()
            : revenueObject[e]
            ? revenueObject[e]
            : 0,
          error: revenueObject[e] === 0 || revenueObject[e] ? null : "0",
        };
      });
      let revUpdatedData = revData.slice(1, -1);

      //Revenue Effect Graph Data
      var revenueEffect: object = {
        name: GMBridgeY.REVENUE,
        data: percentageToggle
          ? createPercentageGraphData(
              revUpdatedData,
              revenueObject?.startYearValue
            )
          : revUpdatedData,
      };
      let costEndYear = new Decimal(0);
      var costData = GMBridgeX.map((e) => {
        let costPerHourObject: any = { ...item.moveCostPerHourData };
        let hourObject: any = { ...item.moveHourData };
        let num: any;
        let isEndYear = e === GMBridgeX[GMBridgeX.length - 1];
        if (GMBridgeBreakdown.includes(e)) {
          let num2 = new Decimal(costPerHourObject[e]);
          costEndYear = costEndYear.plus(costPerHourObject[e]);
          return {
            name: e.charAt(0).toUpperCase() + e.slice(1),
            value: num2.isZero() ? 0 : num2.toString(),
            label: num2.isZero() ? 0 : num2.toString(),
            error: null,
          };
        } else {
          num = isEndYear
            ? new Decimal(costEndYear)
            : new Decimal(hourObject[e] ? hourObject[e] : 0);
          costEndYear = costEndYear.plus(hourObject[e] ? hourObject[e] : 0);
        }
        return {
          name: e.charAt(0).toUpperCase() + e.slice(1),
          value: num.isZero() ? 0 : num.toString(),
          label: num.isZero() ? 0 : num.toString(),
          error: null,
        };
      });
      let costUpdatedData = costData.slice(1, -1);

      //Cost Effect Graph Data
      var costEffect: object = {
        name: GMBridgeY.COST,
        data: percentageToggle
          ? createPercentageGraphData(costUpdatedData, costData[0]?.value)
          : costUpdatedData,
      };
      var grossProfitData = GMBridgeX.map((e, i) => {
        let num1 = new Decimal(revData[i].value);
        let num2 = new Decimal(costData[i].value);
        let numResult = num1.minus(num2);
        let label1 = new Decimal(revData[i].label);
        let label2 = new Decimal(costData[i].label);
        let labelResult = label1.minus(label2);
        return {
          name: e.charAt(0).toUpperCase() + e.slice(1),
          value: numResult.isZero() ? 0 : numResult.toString(),
          label: labelResult.isZero() ? 0 : labelResult.toString(),
          error: labelResult.isZero() || labelResult.toString() ? null : "0",
        };
      });

      //Gross Margin Count Data
      var grossMarginCountData =
        percentageToggle &&
        GMBridgeX.map((e, index) => {
          let sumOfGP = new Decimal(0);
          let sumOfRevenue = new Decimal(0);
          for (let i = 0; i <= index; i++) {
            let num1 = new Decimal(grossProfitData[i].value);
            let num2 = new Decimal(revData[i].value);
            sumOfGP = sumOfGP.add(num1);
            sumOfRevenue = sumOfRevenue.add(num2);
          }
          let calculatedGMValue = Number(sumOfGP)
            ? sumOfGP.dividedBy(sumOfRevenue)
            : sumOfGP;
          return {
            name: e.charAt(0).toUpperCase() + e.slice(1),
            value: calculatedGMValue.isZero()
              ? 0
              : calculatedGMValue.toString(),
          };
        });

      //Gross Margin Percentage Data
      var grossMarginData: any =
        percentageToggle && createGMPercentageData(grossMarginCountData);

      let grossProfitUpdatedData = percentageToggle
        ? grossMarginData.slice(1, -1)
        : grossProfitData.slice(1, -1);

      //Gross Profit Graph Data
      var grossProfitEffect: object = {
        name: GMBridgeY.GROSS,
        data: grossProfitUpdatedData,
      };

      let totalOfGP = new Decimal(0);
      grossProfitData.forEach((e: any, index) => {
        if (index !== grossProfitData.length - 1) {
          let decimalNumber = new Decimal(e.value);
          totalOfGP = totalOfGP.plus(decimalNumber);
        }
      });
      grossProfitData.map((gpItem: any, index: number) => {
        if (index === grossProfitData.length - 1) {
          gpItem.value = totalOfGP.toString();
          gpItem.label = totalOfGP.toString();
          gpItem.error =
            totalOfGP.toString() || totalOfGP.toString() ? null : "0";
        }
      });

      //Gross Margin column Graph Data
      setGrossProfitData(
        calculateWaterFallGmBridge(
          percentageToggle ? grossMarginData : grossProfitData
        )
      );
      var resultList: any = [];
      resultList.push(revenueEffect);
      resultList.push(costEffect);
      resultList.push(grossProfitEffect);
      return resultList;
    }
  };

  const createPercentageGraphData = (data: any, startYearValue: any) => {
    let percentageData: any = [];
    data.map((item: any) => {
      let numerator = new Decimal(item.value);
      let denominator = new Decimal(startYearValue ? startYearValue : 1);
      let percentageValue = Number(item.value)
        ? numerator.dividedBy(denominator)
        : numerator;
      let obj = {
        ...item,
        label: percentageValue.isZero() ? 0 : percentageValue.toString(),
        value: percentageValue.isZero() ? 0 : percentageValue.toString(),
        error:
          percentageValue.isZero() || percentageValue.toString() ? null : "0",
      };
      percentageData.push(obj);
    });
    return percentageData;
  };
  const createGMPercentageData = (data: any) => {
    if (data && data.length > 0) {
      var grossMarginPercentageData: any = [];

      if (data) {
        for (let i = 0; i < data?.length; i++) {
          if (i === 0) {
            let Obj = {
              name: data[i].name,
              value: data[i].value,
              label: data[i].value,
              error: data[i].value === 0 || data[i].value ? null : "0",
            };
            grossMarginPercentageData.push(Obj);
          } else if (i === data?.length - 1) {
            let totalOfGM = new Decimal(0);
            grossMarginPercentageData.forEach((e: any) => {
              let decimalNumber = new Decimal(e.value);
              totalOfGM = totalOfGM.add(decimalNumber);
            });
            let Obj = {
              name: data[i].name,
              value: totalOfGM.isZero() ? 0 : totalOfGM.toString(),
              label: totalOfGM.isZero() ? 0 : totalOfGM.toString(),
              error: totalOfGM.isZero() || totalOfGM.toString() ? null : "0",
            };
            grossMarginPercentageData.push(Obj);
          } else {
            let num1 = new Decimal(data[i].value);
            let num2 = new Decimal(data[i - 1].value);
            let calGMPercentageValue = num1.minus(num2);
            let Obj = {
              name: data[i].name,
              value: calGMPercentageValue.isZero()
                ? 0
                : calGMPercentageValue.toString(),
              label: calGMPercentageValue.isZero()
                ? 0
                : calGMPercentageValue.toString(),
              error:
                calGMPercentageValue.isZero() || calGMPercentageValue.toString()
                  ? null
                  : "0",
            };
            grossMarginPercentageData.push(Obj);
          }
        }
      }
      return grossMarginPercentageData;
    }
  };

  const findConsolidatedLabels = (item: any) => {
    let Obj: any = [
      item.baseYear,
      item.omnia,
      item.levvia,
      item.assurance,
      item.tier5,
      item.other,
      item.pricing,
      item.lastForecastedYear,
    ];
    return Obj;
  };
  const findLabels = (item: any) => {
    let Obj: any = [
      item.baseYear,
      item.wins,
      item.losses,
      item.exits,
      item.pricing,
      item.lastForecastedYear,
    ];
    return Obj;
  };
  const findMaxValues = (list: any) => {
    let filteredList = [];
    let sumValue = 0;
    for (let i = 0; i < list.length; i++) {
      sumValue = sumValue + list[i];
      filteredList.push(sumValue);
    }
    return filteredList;
  };

  return (
    <div style={{ width: "100%" }} className={BLOCK}>
      {consolidationData?.loading.filter(
        (filter: any) =>
          filter === VISUAL_DASHBOARD_OPTIONS.NETREVENUE_BRIDGES_LOADING
      ).length > 0 ? (
        <div className={`${BLOCK}__spinner-container`}>
          <Icon
            name={"loading"}
            width={50}
            height={50}
            className={`${BLOCK}--loading`}
          />
        </div>
      ) : (
        <div className={`${BLOCK}__Graphs`}>
          <div>
            <div className={`${BLOCK}__NetRevenue`}>
              <div className={`${BLOCK}__header`}>
                <div className={`${BLOCK}__header--title`}>
                  <FormattedMessage id="dashboard.net.revenue" />
                </div>
                <div className={`${BLOCK}__header--chevron`}>
                  <Icon name="Chevron" width={24} height={24} />
                </div>
              </div>
              <RevenueGraphLegends BLOCK={BLOCK} />
              <VerticalBarChart
                data={NetRevenue}
                BLOCK={BLOCK}
                ConsolidatioRevenue={true}
                loopYears={loopYears}
              />
            </div>

            <div className={`${BLOCK}__NetRevenue`}>
              <div className={`${BLOCK}__header`}>
                <div className={`${BLOCK}__header--title`}>
                  <FormattedMessage id="cosolidated.net.revenue" />
                </div>
                <div className={`${BLOCK}__header--chevron`}>
                  <Icon name="Chevron" width={24} height={24} />
                </div>
              </div>
              <GraphLegends BLOCK={BLOCK} />
              <WaterFallChart data={ConsolidatedNetRevenue} />
            </div>

            <div className={`${BLOCK}__GraphRow`}>
              <div className={`${BLOCK}__GraphRow--GraphBox`}>
                <div className={`${BLOCK}__header`}>
                  <div className={`${BLOCK}__header--title`}>
                    <FormattedMessage id="revenue.bridge.ominia" />
                  </div>
                  <div className={`${BLOCK}__header--chevron`}>
                    <Icon name="Chevron" width={24} height={24} />
                  </div>
                </div>
                <GraphLegends BLOCK={BLOCK} />
                <WaterFallChart data={RevenueOmniaUsi} />
              </div>

              <div className={`${BLOCK}__GraphRow--GraphBox`}>
                <div className={`${BLOCK}__header`}>
                  <div className={`${BLOCK}__header--title`}>
                    <FormattedMessage id="revenue.bridge.levvia" />
                  </div>
                  <div className={`${BLOCK}__header--chevron`}>
                    <Icon name="Chevron" width={24} height={24} />
                  </div>
                </div>
                <GraphLegends BLOCK={BLOCK} />
                <WaterFallChart data={RevenueLevvia} />
              </div>
            </div>
            <div className={`${BLOCK}__GraphRow`}>
              <div className={`${BLOCK}__GraphRow--GraphBox`}>
                <div className={`${BLOCK}__header`}>
                  <div className={`${BLOCK}__header--title`}>
                    <FormattedMessage id="revenue.bridge.tier5" />
                  </div>
                  <div className={`${BLOCK}__header--chevron`}>
                    <Icon name="Chevron" width={24} height={24} />
                  </div>
                </div>
                <GraphLegends BLOCK={BLOCK} />
                <WaterFallChart data={RevenueTier5} />
              </div>

              <div className={`${BLOCK}__GraphRow--GraphBox`}>
                <div className={`${BLOCK}__header`}>
                  <div className={`${BLOCK}__header--title`}>
                    <FormattedMessage id="revenue.bridge.assurance" />
                  </div>
                  <div className={`${BLOCK}__header--chevron`}>
                    <Icon name="Chevron" width={24} height={24} />
                  </div>
                </div>
                <GraphLegends BLOCK={BLOCK} />
                <WaterFallChart data={RevenueAssurance} />
              </div>
            </div>
            <div className={`${BLOCK}__GraphRow`}>
              <div className={`${BLOCK}__GraphRow--GraphBox`}>
                <div className={`${BLOCK}__header`}>
                  <div className={`${BLOCK}__header--title`}>
                    <FormattedMessage id="revenue.bridge.other" />
                  </div>
                  <div className={`${BLOCK}__header--chevron`}>
                    <Icon name="Chevron" width={24} height={24} />
                  </div>
                </div>
                <GraphLegends BLOCK={BLOCK} />
                <WaterFallChart data={RevenueOther} />
              </div>
              <div className={`${BLOCK}__GraphRow--GraphBox`}>
                <div className={`${BLOCK}__header`}>
                  <div className={`${BLOCK}__header--title`}>
                    <FormattedMessage id="revenue.bridge.fee/hr" />({currency}
                    /h)
                  </div>
                  <div className={`${BLOCK}__header--chevron`}>
                    <Icon name="Chevron" width={24} height={24} />
                  </div>
                </div>
                <div className={`${BLOCK}__legends`}>
                  <div className={`${BLOCK}__legend`}>
                    <span className={`${BLOCK}__legend__fee`}></span>
                    <div className={`${BLOCK}__legends--name1`}>
                      <FormattedMessage id="graph_legend_fee" />({currency}/h)
                    </div>
                  </div>
                  <div className={`${BLOCK}__legend`}>
                    <span className={`${BLOCK}__legend__cost`}></span>
                    <div className={`${BLOCK}__legends--name1`}>
                      <FormattedMessage id="graph_legend_cost" />({currency}/h)
                    </div>
                  </div>
                </div>
                <VerticalBarChart
                  data={RevenueFeeAndCostPerHr}
                  BLOCK={BLOCK}
                  ConsolidatioRevenue={false}
                />
              </div>
            </div>
          </div>
          <div>
            <GMBridge yearList={yearList} />
            {consolidationData?.loading.filter(
              (filter: any) =>
                filter === VISUAL_DASHBOARD_OPTIONS.GM_BRIDGES_LOADING
            ).length > 0 ? (
              <div className={`${BLOCK}__spinner-container`}>
                <Icon
                  name={"loading"}
                  width={50}
                  height={50}
                  className={`${BLOCK}--loading`}
                />
              </div>
            ) : (
              <>
                <div className={`${BLOCK}__GMBridge`}>
                  <div className={`${BLOCK}__header`}>
                    <div className={`${BLOCK}__header--title`}>
                      <FormattedMessage id="cosolidated.gm.bridge" />
                    </div>
                    <div className={`${BLOCK}__header--chevron`}>
                      <Icon name="Chevron" width={24} height={24} />
                    </div>
                  </div>
                  <GraphLegends BLOCK={BLOCK} isGMBridge={true} />
                  <GMBridgeChart
                    data={GMBridgeData}
                    percentageToggle={consolidationData?.gmBridgeToggle}
                  />
                </div>
                <div className={`${BLOCK}__NetRevenue`}>
                  <div className={`${BLOCK}__header`}>
                    <div className={`${BLOCK}__header--title`}>
                      <FormattedMessage id="cosolidated.gm.bridge" />
                    </div>
                    <div className={`${BLOCK}__header--chevron`}>
                      <Icon name="Chevron" width={24} height={24} />
                    </div>
                  </div>
                  <GraphLegends BLOCK={BLOCK} />
                  <WaterFallChart
                    data={grossProfitData}
                    gmBridge={true}
                    percentageToggle={consolidationData?.gmBridgeToggle}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConsolidatedDataGraph;
