import axios from "axios";
import api from "../../services/api.service";

export const getCurrency = () => {
  return api.get("currencies").then(({ data }) => data);
};

export const getConsolidationGeographies = () => {
  return api.get("geographies?format=1").then(({ data }) => data);
};
export const getConsolidatedNetRevenueData = (
  countryCodes: any,
  currencyCode: string,
  baseYear: number
) => {
  return api
    .get(
      `netrevenuebridges/consolidated?CountryCodes=${countryCodes}&CurrencyCode=${currencyCode}&baseYear=${baseYear}`
    )
    .then(({ data }) => data);
};

export const getNetRevenueBridgesData = (
  countryCodes: any,
  currencyCode: string,
  baseYear: number
) => {
  return api(
    `netrevenuebridges?CountryCodes=${countryCodes}&CurrencyCode=${currencyCode}&baseYear=${baseYear}`
  ).then(({ data }) => data);
};

export const getNetFeePerHourData = (
  countryCodes: any,
  currencyCode: string,
  baseYear: number
) => {
  return api(
    `consolidation/grossMargin/netFeePerHours?CountryCodes=${countryCodes}&CurrencyCode=${currencyCode}&baseYear=${baseYear}`
  ).then(({ data }) => data);
};

export const getCostPerHourData = (
  countryCodes: any,
  currencyCode: string,
  baseYear: number
) => {
  return api(
    `consolidation/grossMargin/costPerHours?CountryCodes=${countryCodes}&CurrencyCode=${currencyCode}&baseYear=${baseYear}`
  ).then(({ data }) => data);
};

export const getGMBridgeData = (
  countryCodes: any,
  currencyCode: string,
  baseYear: number,
  startYear: number,
  endYear: number,
  cohort: number
) => {
  return api(
    `consolidation/gmbridge?CountryCodes=${countryCodes}&CurrencyCode=${currencyCode}&baseYear=${baseYear}&startYear=${startYear}&endYear=${endYear}&cohort=${cohort}`
  ).then(({ data }) => data);
};

export const getTableData = (
  countryCodes: string[],
  currencyCode: string,
  baseYear: number,
  tableName: string,
  section: string
) => {
  return api(
    `consolidation/${section}/${tableName}?CountryCodes=${countryCodes}&CurrencyCode=${currencyCode}&baseYear=${baseYear}`
  ).then(({ data }) => data);
};

export const getConsolidationTransformationAssumptionsData = (
  countryCodes: string[],
  currencyCode: string,
  baseYear: number
) => {
  return api(
    `consolidation/TransformationAssumptions?CountryCodes=${countryCodes}&CurrencyCode=${currencyCode}&baseYear=${baseYear}`
  ).then(({ data }) => data);
};

export const getSectionsTableDataByDeliveryCenterID = (
  countryCodes: string[],
  currencyCode: string,
  baseYear: number,
  tableName: string,
  section: string,
  delieveryCenterID: number
) => {
  return api(
    `consolidation/${section}/${tableName}?CountryCodes=${countryCodes}&CurrencyCode=${currencyCode}&baseYear=${baseYear}&deliveryCenter=${delieveryCenterID}`
  ).then(({ data }) => data);
};
export const getOverHeadsData = (
  countryCodes: string[],
  currencyCode: string,
  baseYear: number,
  tableName: string,
  section: string,
  overheadTypeId: number
) => {
  return api(
    `consolidation/${section}/${tableName}?CountryCodes=${countryCodes}&CurrencyCode=${currencyCode}&baseYear=${baseYear}&overheadTypeId=${overheadTypeId}`
  ).then(({ data }) => data);
};
