import { useState, useEffect, MouseEventHandler, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import DropDown from "../search/filters/drop-down";
import {
  loadCurrency,
  setVisualDashboardCurrency,
  loadConsolidationGeographies,
  setConsolidationGeographies,
  applyMetrics,
  MakeGraphDataEmpty,
  setRefreshVisualDashboard,
} from "services/visualDashboard/visualDashBoardSlice";
import {
  ConsolidationGeographies as ConsolidationModel,
  SubConsolidationGeographiesData,
  VISUAL_DASHBOARD_OPTIONS,
} from "services/visualDashboard/visual.dashboard.models";
import ConsolidationGeographies from "./consolidationGeographies";
import Icon from "../shared/icon/icon";
import { getFilterYears } from "services/dashboard/dashBoardSlice";
type MetricsModal = {
  setOpenMetricsModal: Function;
};

const MetricsModal = ({ setOpenMetricsModal }: MetricsModal) => {
  const BLOCK = "metricsModel";
  const dispatch = useDispatch();
  const [enableCollapseAllButton, setEnableCollapseAllButton] =
    useState<boolean>(false);
  const consolidationData: any = useSelector(
    (state: RootStore) => state.visualDashboard
  );

  const dashboardTableState = useSelector(
    (state: RootStore) => state.dashboard
  );
  const [parentCheckBoxSelected, setParentCheckBoxSelected] = useState<any>(
    consolidationData
      ? [
          ...consolidationData?.consolidationGeographiesSelected
            ?.parentCheckBoxSelected!,
        ]
      : []
  );
  const [subParentCheckBoxSelected, setSubParentCheckBoxSelected] =
    useState<any>(
      consolidationData
        ? [
            ...consolidationData?.consolidationGeographiesSelected
              ?.subParentCheckBoxSelected!,
          ]
        : []
    );
  const [
    childSubConsolidationGeographyCheckBoxSelected,
    setChildSubConsolidationGeographyCheckBoxSelected,
  ] = useState<any>(
    consolidationData
      ? [
          ...consolidationData?.consolidationGeographiesSelected
            .childSubConsolidationGeographyCheckBoxSelected!,
        ]
      : []
  );
  const [childCheckBoxSelected, setChildCheckBoxSelected] = useState<any>(
    consolidationData
      ? [
          ...consolidationData?.consolidationGeographiesSelected
            ?.childCheckBoxSelected!,
        ]
      : []
  );
  const [selectedCurrency, setSelectedCurrency] = useState<any>(
    consolidationData ? [...consolidationData?.currency] : []
  );

  const [enableExpandAllButton, setEnableExpandAllButton] =
    useState<boolean>(true);
  const [checkBoxSelected, setCheckBoxSelected] = useState<boolean>(false);

  const selectCurrency = (currency: any) => {
    setSelectedCurrency([currency]);
  };

  useEffect(() => {
    dispatch(loadCurrency());
    dispatch(loadConsolidationGeographies());
    consolidationData?.netRevenueData &&
      Object.keys(consolidationData?.netRevenueData).length &&
      dispatch(setRefreshVisualDashboard(false));
    if (
      dashboardTableState.fiscalYearList &&
      !Object.keys(dashboardTableState.fiscalYearList).length
    ) {
      dispatch(getFilterYears());
    }
  }, []);

  useEffect(() => {
    if (
      parentCheckBoxSelected.length > 0 ||
      subParentCheckBoxSelected.length > 0 ||
      childCheckBoxSelected.length > 0 ||
      childSubConsolidationGeographyCheckBoxSelected.length > 0
    ) {
      setCheckBoxSelected(true);
    } else {
      setCheckBoxSelected(false);
    }
  }, [
    parentCheckBoxSelected,
    subParentCheckBoxSelected,
    childCheckBoxSelected,
    childSubConsolidationGeographyCheckBoxSelected,
  ]);

  const handleReset = async () => {
    setParentCheckBoxSelected([]);
    setSubParentCheckBoxSelected([]);
    setChildCheckBoxSelected([]);
    setChildSubConsolidationGeographyCheckBoxSelected([]);
    setSelectedCurrency([]);
  };

  const handleApply = async () => {
    dispatch(MakeGraphDataEmpty());
    dispatch(applyMetrics());
    dispatch(
      setConsolidationGeographies({
        parentCheckBoxSelected,
        subParentCheckBoxSelected,
        childCheckBoxSelected,
        childSubConsolidationGeographyCheckBoxSelected,
      })
    );
    dispatch(setVisualDashboardCurrency([selectedCurrency]));
  };

  const ref: any = useRef();

  return (
    <div
      data-test="component-Metrics Model"
      className={`${BLOCK}__main-container`}
    >
      <div className={`${BLOCK}__metrics-container`}>
        <div className={`${BLOCK}__metrics-header`}>
          <Icon
            name={enableCollapseAllButton ? "collapseActive" : "collapse"}
            width={11}
          />
          <span
            className={classNames(`${BLOCK}__collapse`, {
              [`${BLOCK}__text-disabled`]: !enableCollapseAllButton,
              [`${BLOCK}__text-enabled ${BLOCK}__hover`]:
                enableCollapseAllButton,
              [`${BLOCK}__text-enabled ${BLOCK}__hover`]:
                enableCollapseAllButton,
            })}
            onClick={() =>
              enableCollapseAllButton && ref.current.collapseAllGeographies()
            }
          >
            <FormattedMessage id="consolidation.geographies.collapse.all" />
          </span>
          <Icon
            name={enableExpandAllButton ? "expandAll" : "expandAllDisabled"}
            width={11}
          />
          <span
            className={classNames(`${BLOCK}__expand`, {
              [`${BLOCK}__text-disabled`]: !enableExpandAllButton,
              [`${BLOCK}__text-enabled ${BLOCK}__hover`]: enableExpandAllButton,
            })}
            onClick={() =>
              enableExpandAllButton && ref.current.expandAllGeographies()
            }
          >
            <FormattedMessage id="consolidation.geographies.expand.all" />
          </span>
        </div>
        <ConsolidationGeographies
          BLOCK="metricsModel"
          consolidationGeographies={consolidationData?.consolidationGeographies}
          setEnableExpandAllButton={setEnableExpandAllButton}
          setEnableCollapseAllButton={setEnableCollapseAllButton}
          parentCheckBoxSelected={parentCheckBoxSelected}
          setParentCheckBoxSelected={setParentCheckBoxSelected}
          subParentCheckBoxSelected={subParentCheckBoxSelected}
          setSubParentCheckBoxSelected={setSubParentCheckBoxSelected}
          childSubConsolidationGeographyCheckBoxSelected={
            childSubConsolidationGeographyCheckBoxSelected
          }
          setChildSubConsolidationGeographyCheckBoxSelected={
            setChildSubConsolidationGeographyCheckBoxSelected
          }
          childCheckBoxSelected={childCheckBoxSelected}
          setChildCheckBoxSelected={setChildCheckBoxSelected}
          ref={ref}
        />
      </div>
      <div className={`${BLOCK}__currency-container`}>
        <DropDown
          className={`${BLOCK}__drop-down`}
          label={
            <div className={`${BLOCK}__label ${BLOCK}__label-after`}>
              <FormattedMessage id="metrics.model.currency.label" />
            </div>
          }
          placeholder={
            <FormattedMessage id="metrics.model.currency.placeholder" />
          }
          loading={
            consolidationData?.loading.filter(
              (filter: any) =>
                filter === VISUAL_DASHBOARD_OPTIONS.CURRENCY_LOADING
            ).length > 0
          }
          objectKeys={{
            name: "name",
            id: "currencyCode",
          }}
          values={selectedCurrency}
          options={consolidationData?.currencyList}
          handleSelect={selectCurrency}
          displayFunction={(option: any) =>
            option.currencyCode + " - " + option.name
          }
        />
      </div>
      <div className={`${BLOCK}__button-container`}>
        <span
          className={classNames({
            [`${BLOCK}__text-enabled ${BLOCK}__hover`]:
              selectedCurrency.length > 0 || checkBoxSelected,
            [`${BLOCK}__text-disabled`]:
              selectedCurrency.length === 0 && !checkBoxSelected,
          })}
          onClick={() =>
            (checkBoxSelected || selectedCurrency.length > 0) && handleReset()
          }
        >
          <FormattedMessage id="consolidation.geographies.reset.button" />
        </span>
        <div className={`${BLOCK}__cancel-apply-button`}>
          <button
            className={`${BLOCK}__cancel-button ${BLOCK}__hover`}
            onClick={(e) => {
              consolidationData?.applyMetrics
                ? setOpenMetricsModal(false)
                : handleReset().then(() => setOpenMetricsModal(false));
            }}
          >
            <FormattedMessage id="consolidation.geographies.cancel.button" />
          </button>
          <button
            onClick={(e) => {
              if (selectedCurrency.length > 0 && checkBoxSelected) {
                handleApply().then(() => setOpenMetricsModal(false));
              }
            }}
            className={classNames(`${BLOCK}__button-common`, {
              [`${BLOCK}__apply-button-disabled`]:
                selectedCurrency.length == 0 || !checkBoxSelected,
              [`${BLOCK}__apply-button-enabled ${BLOCK}__hover`]:
                selectedCurrency.length > 0 && checkBoxSelected,
            })}
          >
            <FormattedMessage id="consolidation.geographies.apply.button" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MetricsModal;
