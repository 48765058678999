import { useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "lodash";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardTableList } from "services/dashboard/dashBoardSlice";
import { RootStore } from "services/store.service";
import { FILTER_OPTIONS } from "utils/constants";
import {
  GoverningBoardFilter,
  GeoFilter,
  PreparerFilter,
  MemberFirmAdmin,
  ReviewerFilter,
} from "services/geoFilters/geo.model";

import Icon from "components/shared/icon/icon";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import DropDown from "../search/filters/drop-down";
import { addToastMessage } from "services/common/commonsSlice";
import {
  setLoaded,
  setGeoFilter,
  loadGeoFilter,
  loadReviewerFilter,
  loadPreparerFilter,
  loadMemberFirmAdmin,
  setPreparerFilter,
  setMemberFirmAdmin,
  setReviewerFilter,
  addGeo,
  setExistingMemberFirmAdmin,
} from "services/geoFilters/geoSlice";

type filterProps = {
  BLOCK: string;
  setShowGeographyModal: (value: boolean) => void;
};

const Filters = ({ BLOCK, setShowGeographyModal }: filterProps) => {
  const dispatch = useDispatch();
  const searchState = useSelector((state: RootStore) => state.filters);
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const [memberFirmFromGeo, setMemberFirm] = useState<string | unknown>("");
  const [addGeoDetails, setAddGeoDetails] = useState<any>(null);
  const [memberFirmAdminID, setMemberFirmAdminId] = useState<any>("");
  const filtersRef = useRef<HTMLDivElement>(null);
  const [reviewerExits, setReviewerExists] = useState<boolean>(true);
  const [preparerExists, setPreparerExists] = useState<boolean>(true);
  const [reviewerError, setReviewerError] = useState(false);
  const [preparerListsOptions, setPreparerListOptions] = useState<any>([]);

  let currUserRole =
    currentUser && currentUser.userRoles && currentUser.userRoles.length > 0
      ? currentUser.userRoles[0].roleId
      : 0;

  useEffect(() => {
    searchState.preparerFilterOptions?.length > 0 &&
      setPreparerListOptions([
        ...preparerListsOptions,
        ...searchState.preparerFilterOptions,
      ]);
  }, [searchState.preparerFilterOptions]);

  useEffect(() => {
    if (!searchState.loaded) {
      const abortController = new AbortController();
      dispatch(loadGeoFilter());
      return () => {
        abortController.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !searchState?.loaded &&
      searchState?.geoFilterOptions?.length &&
      searchState?.preparerFilterOptions?.length &&
      searchState?.memberFirmAdminFilterOptions?.length
    ) {
      dispatch(setLoaded());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState]);

  /* ESg Ratings filter will be hidden for now */

  useEffect(() => {
    searchState.preparerFilter.length > 0 &&
    searchState.preparerFilter.includes(searchState.reviewerFilter[0])
      ? setReviewerError(true)
      : setReviewerError(false);
  }, [searchState.preparerFilter, searchState.reviewerFilter]);

  const selectGeo = (geoFilter: GeoFilter) => {
    const item = searchState.geoFilterOptions.filter((item: any) => {
      return item.countryName === geoFilter;
    });
    setAddGeoDetails(item);
    dispatch(setGeoFilter([geoFilter]));
  };
  const selectReviewer = (reviewerFilter: ReviewerFilter) => {
    dispatch(setReviewerFilter([reviewerFilter]));
  };
  const selectPreparer = (preparerFilter: PreparerFilter) => {
    searchState.preparerFilter.indexOf(preparerFilter) === -1 &&
      dispatch(
        setPreparerFilter([...searchState.preparerFilter, preparerFilter])
      );
  };
  const deletePreparer = (preparer: PreparerFilter) => {
    const updatedPreparerList = searchState.preparerFilter.filter(
      (item: PreparerFilter) => {
        return item !== preparer;
      }
    );
    dispatch(setPreparerFilter([...updatedPreparerList]));
  };
  const selectMemberFirmAdmin = (memberFirmAdminFilter: MemberFirmAdmin) => {
    dispatch(setMemberFirmAdmin([memberFirmAdminFilter]));
  };

  const resetSearch = () => {
    dispatch(setGeoFilter([]));
    dispatch(setPreparerFilter([]));
    dispatch(setReviewerFilter([]));
    dispatch(setMemberFirmAdmin([]));
    setMemberFirm("");
    const payload = { ContentText: "" };
    dispatch(loadPreparerFilter({ countryCode: "", payload }));
    dispatch(loadMemberFirmAdmin({ countryCode: "", payload }));
    dispatch(loadReviewerFilter({ countryCode: "", payload }));
    setPreparerListOptions([]);
  };

  const submitSearch = () => {
    let geoFlag = searchState.geoFilter.length > 0 ? true : false;
    let memberFirmAdmin =
      searchState.memberFirmAdminFilter.length > 0
        ? searchState.memberFirmAdminFilter[0]
        : "";
    let reviewer: any =
      searchState.reviewerFilter.length > 0
        ? searchState.reviewerFilter[0]
        : "";
    let preparer =
      searchState.preparerFilter.length > 0 ? searchState.preparerFilter : [];
    let geoDetails = addGeoDetails[0];

    if (!geoFlag) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="geo.submit.check.false" />,
        })
      );
    } else if (
      searchState.preparerFilter.length > 0 &&
      reviewer !== "" &&
      preparer.includes(reviewer)
    ) {
      setReviewerError(true);
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="same.preparer.reviewer" />,
        })
      );
    } else if (
      searchState.preparerFilter.length > 0 &&
      searchState.reviewerFilter.length === 0
    ) {
      setPreparerExists(true);
      setReviewerExists(false);
    } else if (
      searchState.preparerFilter.length === 0 &&
      searchState.reviewerFilter.length > 0
    ) {
      setReviewerExists(true);
      setPreparerExists(false);
    } else {
      setPreparerExists(true);
      setReviewerExists(true);

      const payload: any = {
        CountryCode: geoDetails.countryCode,
        MemberFirmCode: geoDetails.memberFirmCode,
        NewGlobalPersonRoles: [],
      };

      let memberFirmAdminGlobalPersonId =
        searchState.memberFirmAdminFilterOptions.filter((item: any) => {
          return item.email === memberFirmAdmin;
        });

      let preparerGlobalPersonId = preparerListsOptions?.filter((item: any) => {
        return preparer.includes(item.email);
      });

      let reviewerGlobalPersonId = searchState.reviewerFilterOptions.filter(
        (item: any) => {
          return item.email === reviewer;
        }
      );

      if (memberFirmAdmin !== "") {
        let obj = {
          GlobalPersonId: memberFirmAdminGlobalPersonId[0]?.userId
            ? memberFirmAdminGlobalPersonId[0]?.userId
            : memberFirmAdminID,
          RoleId: 2,
        };
        payload.NewGlobalPersonRoles.push({ ...obj });
      }
      if (preparer.length > 0) {
        preparerGlobalPersonId.map((item: any) => {
          let obj = {
            GlobalPersonId: item?.userId,
            RoleId: 3,
          };
          payload.NewGlobalPersonRoles.push({ ...obj });
        });
      }

      if (reviewer !== "") {
        let obj = {
          GlobalPersonId: reviewerGlobalPersonId[0]?.userId,
          RoleId: 4,
        };
        payload.NewGlobalPersonRoles.push({ ...obj });
      }

      addGeo(payload)
        .then((data) => {
          if (data) {
            dispatch(
              addToastMessage({
                description: <FormattedMessage id="geo.submit.check.true" />,
              })
            );
            resetSearch();
            dispatch(getDashboardTableList(null));
            setShowGeographyModal(false);
          }
        })
        .catch(() => {
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="geo.submit.check.failure" />,
            })
          );
        });
    }
  };

  const editGeographyField = useMemo(() => {
    return debounce((e: any) => {
      if (e.target.value === "") {
        dispatch(setGeoFilter([]));
        setMemberFirm("");
      }
    }, 300);
  }, [dispatch, searchState.geoFilter]);

  const editMemberFirmAdminField = useMemo(() => {
    return debounce((e: any) => {
      if (e.target.value === "") {
        dispatch(setMemberFirmAdmin([]));
      }
    }, 300);
  }, [dispatch, searchState.geoFilter]);

  const editReviewerField = useMemo(() => {
    return debounce((e: any) => {
      if (e.target.value === "") {
        dispatch(setReviewerFilter([]));
      }
    }, 300);
  }, [dispatch, searchState.geoFilter]);

  const debounceChangeHandlerMemberFirmAdmin = useMemo(() => {
    return debounce((e: any) => {
      const item = searchState.geoFilterOptions.filter((item: any) => {
        return item.countryName === searchState.geoFilter[0];
      });
      const payload = { ContentText: e.target.value };
      dispatch(
        loadMemberFirmAdmin({ countryCode: item[0].countryCode, payload })
      );
    }, 300);
  }, [dispatch, searchState.geoFilter]);

  const debounceChangeHandlerReviewer = useMemo(() => {
    return debounce((e: any) => {
      const item = searchState.geoFilterOptions.filter((item: any) => {
        return item.countryName === searchState.geoFilter[0];
      });
      const payload = { ContentText: e.target.value };
      dispatch(
        loadReviewerFilter({ countryCode: item[0].countryCode, payload })
      );
    }, 300);
  }, [dispatch, searchState.geoFilter]);

  const debounceChangeHandlerPreparer = useMemo(() => {
    return debounce((e: any) => {
      const item = searchState.geoFilterOptions.filter((item: any) => {
        return item.countryName === searchState.geoFilter[0];
      });
      const payload = { ContentText: e.target.value };
      dispatch(
        loadPreparerFilter({ countryCode: item[0].countryCode, payload })
      );
    }, 300);
  }, [dispatch, searchState.geoFilter]);

  useEffect(() => {
    const item = searchState?.geoFilterOptions?.filter((item: any) => {
      return item?.countryName === searchState?.geoFilter[0];
    });
    setMemberFirm(item[0]?.memberFirmDisplayValue);
    item[0]?.memberFirmCode &&
      setExistingMemberFirmAdmin(item[0]?.memberFirmCode).then((data: any) => {
        if (data && data?.email) {
          setMemberFirmAdminId(data.userId);
          dispatch(setMemberFirmAdmin([data.email]));
        } else {
          setMemberFirmAdminId("");
          dispatch(setMemberFirmAdmin([]));
        }
      });
  }, [searchState.geoFilter]);

  useEffect(() => {
    if (searchState.reviewerFilter.length > 0) {
      setReviewerExists(true);
    }
    if (searchState.preparerFilter.length > 0) {
      setPreparerExists(true);
    }
    if (
      searchState.reviewerFilter.length === 0 &&
      searchState.preparerFilter.length === 0
    ) {
      setReviewerExists(true);
      setPreparerExists(true);
    }
  }, [searchState.preparerFilter, searchState.reviewerFilter]);

  return (
    <>
      <div className={`${BLOCK}__filter-options`} data-test="addnewgeo-options">
        <div className={`${BLOCK}__filter-content`}>
          <div ref={filtersRef} className={`${BLOCK}__filter-scroll`}>
            <DropDown
              className={`${BLOCK}__drop-down`}
              label={
                <div className={`${BLOCK}__required`}>
                  <FormattedMessage id="add.member-modal.geo" />
                </div>
              }
              placeholder={
                <FormattedMessage id="add.member-modal.geo.placehoder" />
              }
              loading={
                searchState.loadingFilter.filter(
                  (filter: any) => filter === FILTER_OPTIONS.GEOLIST
                ).length > 0
              }
              objectKeys={{
                name: "countryName",
                id: "countryNameId",
                externalId: "externalCountryNameId",
              }}
              flag={true}
              values={searchState.geoFilter}
              options={searchState.geoFilterOptions}
              handleSelect={selectGeo}
              editText={editGeographyField}
              displayFunction={(option: GeoFilter) => option.countryName}
              data-test="geo-dropdown"
            />
            <DropDown
              className={`${BLOCK}__drop-down`}
              label={
                <div
                  className={`${BLOCK}__drop-down-label`}
                  data-test="drop-down-label"
                >
                  <FormattedMessage id="add.member.firm" />
                </div>
              }
              // placeholder={<FormattedMessage id="add.member.firm.placehoder" />}
              customPlaceHolder="Member Firm"
              loading={
                searchState.loadingFilter.filter(
                  (filter: any) => filter === FILTER_OPTIONS.COMPANY
                ).length > 0
              }
              objectKeys={{
                name: "companyName",
                ticker: "ticker",
                id: "companyId",
                externalId: "externalCompanyId",
              }}
              textValue={memberFirmFromGeo}
              options={[]}
              disabled={true}
              data-test="memberFirm-dropdown"
            />
            {currUserRole !== 2 && (
              <DropDown
                className={`${BLOCK}__drop-down`}
                label={<FormattedMessage id="add.memberfirm.admin" />}
                placeholder={
                  <FormattedMessage id="add.memberfirm.admin.placeholder" />
                }
                loading={
                  searchState.loadingFilter.filter(
                    (filter: any) => filter === FILTER_OPTIONS.MEMBERFIRMADMIN
                  ).length > 0
                }
                objectKeys={{
                  name: "preferredFullName",
                  id: "memberFirmId",
                  externalId: "externaluserId",
                }}
                flag={true}
                values={searchState.memberFirmAdminFilter}
                options={searchState.memberFirmAdminFilterOptions}
                handleChange={debounceChangeHandlerMemberFirmAdmin}
                handleSelect={selectMemberFirmAdmin}
                displayFunction={(option: MemberFirmAdmin) => {
                  return `${option.preferredFullName} | ${option.email}`;
                }}
                editText={editMemberFirmAdminField}
                disabled={searchState.geoFilter.length > 0 ? false : true}
                data-test="memberFirmAdmin-dropdown"
              />
            )}
            <DropDown
              className={`${BLOCK}__drop-down`}
              label={
                <span
                  style={
                    !preparerExists
                      ? { color: "red", fontWeight: "500" }
                      : { color: "black" }
                  }
                >
                  <FormattedMessage id="add.preparer.name" />
                </span>
              }
              placeholder={<FormattedMessage id="add.preparer.placeholder" />}
              loading={
                searchState.loadingFilter.filter(
                  (filter: any) => filter === FILTER_OPTIONS.PREPARER
                ).length > 0
              }
              objectKeys={{
                name: "preferredFullName",
                id: "preparerId",
                externalId: "externaluserId",
              }}
              flag={true}
              options={searchState.preparerFilterOptions}
              handleChange={debounceChangeHandlerPreparer}
              handleSelect={selectPreparer}
              displayFunction={(option: MemberFirmAdmin) => {
                return `${option.preferredFullName} | ${option.email}`;
              }}
              disabled={searchState.geoFilter.length > 0 ? false : true}
              preparerFilter={true}
              data-test="preparer-dropdown"
            />
            {!preparerExists && (
              <div
                style={{ color: "red", fontWeight: "500", marginTop: "7px" }}
              >
                <FormattedMessage id="filter.preparer.must.added" />
              </div>
            )}
            <div
              className={`${BLOCK}__preparer-list-container`}
              data-test="preparer-list-container"
            >
              {searchState.preparerFilter.map((item: any) => {
                return (
                  <>
                    <div
                      className={classNames(`${BLOCK}__preparer-list`, {
                        [`${BLOCK}__preparer-reviewer-same`]: item.includes(
                          searchState.reviewerFilter[0]
                        ),
                      })}
                    >
                      <span className={`${BLOCK}__text`}>{item}</span>
                      <div onClick={() => deletePreparer(item)}>
                        <Icon
                          name="remove"
                          width={20}
                          className={`${BLOCK}__delete-icon`}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <DropDown
              className={`${BLOCK}__drop-down`}
              label={
                <span
                  style={
                    reviewerError || !reviewerExits
                      ? { color: "red", fontWeight: "500" }
                      : { color: "black" }
                  }
                >
                  <FormattedMessage id="add.reviewer.name" />
                </span>
              }
              placeholder={<FormattedMessage id="add.reviewer.placehoder" />}
              loading={
                searchState.loadingFilter.filter(
                  (filter: any) => filter === FILTER_OPTIONS.REVIEWER
                ).length > 0
              }
              objectKeys={{
                name: "preferredFullName",
                id: "reviewerId",
                externalId: "externaluserId",
              }}
              flag={true}
              values={searchState.reviewerFilter}
              options={searchState.reviewerFilterOptions}
              handleChange={debounceChangeHandlerReviewer}
              handleSelect={selectReviewer}
              displayFunction={(option: MemberFirmAdmin) => {
                return `${option.preferredFullName} | ${option.email}`;
              }}
              editText={editReviewerField}
              disabled={searchState.geoFilter.length > 0 ? false : true}
              data-test="reviewer-dropdown"
            />
            {reviewerError ? (
              <div
                style={{ color: "red", fontWeight: "500", marginTop: "7px" }}
              >
                <FormattedMessage id="filter.preparer.reveiwer.same" />
              </div>
            ) : (
              !reviewerExits && (
                <div
                  style={{ color: "red", fontWeight: "500", marginTop: "7px" }}
                >
                  <FormattedMessage id="filter.reveiwer.must.added" />
                </div>
              )
            )}
          </div>
        </div>
        <div
          className={`${BLOCK}__filter-buttons-container`}
          data-test="filter-buttons-container"
        >
          <button
            className={`primary-outline`}
            data-test="cancel-button"
            onClick={() => {
              setShowGeographyModal(false);
              resetSearch();
            }}
          >
            <FormattedMessage id="shortlist.edit.button.cancel" />
          </button>
          <button
            onClick={() => submitSearch()}
            className={classNames({
              [`${BLOCK}__continue primary-disabled`]:
                memberFirmFromGeo === "" || memberFirmFromGeo === undefined,
              [`${BLOCK}__submit-button`]:
                memberFirmFromGeo !== "" || memberFirmFromGeo !== undefined,
            })}
            data-test="search-btn"
            disabled={memberFirmFromGeo ? false : true}
          >
            <FormattedMessage id="add.geo-button" />
          </button>
        </div>
      </div>
      <div data-test="filter-tips-modal"></div>
    </>
  );
};

export default Filters;
