import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import ConsolidationsCommonTable from "./consolidationsCommonTable";
import { ConsolidationsKeyInitial } from "utils/constants";
import { VISUAL_DASHBOARD_OPTIONS } from "services/visualDashboard/visual.dashboard.models";
import { getTechnicalStaffCostLocalDCData } from "services/visualDashboard/visualDashBoardSlice";

type TechnicalStaffCostLocalDC = {
  geographies: string[];
  currencyCode: string;
  baseYear: number;
};

const TechnicalStaffCostLocalDCTable = ({
  geographies,
  currencyCode,
  baseYear,
}: TechnicalStaffCostLocalDC) => {
  const dispatch = useDispatch();
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  useEffect(() => {
    if (geographies && currencyCode) {
      dispatch(
        getTechnicalStaffCostLocalDCData({
          countryCodes: geographies,
          currencyCode,
          baseYear,
        })
      );
    }
  }, [geographies, currencyCode, baseYear]);
  return (
    <div data-test="TechnicalStaffCostLocalDCTable-component">
      <ConsolidationsCommonTable
        tableData={
          consolidationData?.technicalStaffCostLocalDCData
            ?.consolidatedProfitAndLossDetails
        }
        tableHeader={
          consolidationData?.technicalStaffCostLocalDCData
            ?.subTotalProfitAndLoss
        }
        mainTableHeader="consolidation.technical.staaff.cost.local.dc"
        subConsolidationTableData={
          consolidationData?.technicalStaffCostLocalDCData
            ?.subConsolidatedProfitAndLossDetails
        }
        keyInitial={ConsolidationsKeyInitial.TECHNICAL_STAFF_COST_LOCAL_DC}
        loading={
          consolidationData?.loading?.filter((item: any) => {
            return (
              item ===
              VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_LOCAL_DC_LOADING
            );
          })?.length > 0
        }
      />
    </div>
  );
};

export default TechnicalStaffCostLocalDCTable;
