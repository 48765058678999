import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { Download } from "services/download/download.model";
import Icon from "../shared/icon/icon";
import { getDownloadBinary } from "services/download/download.api";
import { addToastMessage } from "services/common/commonsSlice";
import { getDownloads } from "services/download/downloadSlice";
import { EXPORT_FORMAT } from "../../utils/constants";
import moment from "moment";

type Props = {
  BLOCK: string;
  item: Download;
  activeTab: number;
  setActiveTab: (id: number) => void;
};

const DownloadItem = ({ BLOCK, item, activeTab, setActiveTab }: Props) => {
  const dispatch = useDispatch();
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [progressState, setProressState] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<boolean>(false);
  useEffect(() => {
    if (item.exportState === 5) {
      setErrorState(true);
    }
  }, [item.exportState]);

  const downloadItem = (e: any) => {
    e.preventDefault();
    setActiveTab(item.exportDetailId);

    setLoadingDownload(true);
    setErrorState(false);
    setProressState(true);
    /* istanbul ignore next */
    getDownloadBinary(item.exportDetailId)
      .then((reportUrl) => {
        var downloadLink = document.createElement("a");
        downloadLink.href = reportUrl;
        downloadLink.download = item.exportFileName || "";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setLoadingDownload(false);
        setProressState(false);
        // dispatch(getDownloads());
      })
      .catch((err) => {
        setLoadingDownload(false);
        setErrorState(true);
        dispatch(
          addToastMessage({
            description: (
              <FormattedMessage id="downloads.toaster.progress.error" />
            ),
          })
        );
      });
  };

  return (
    <>
      <div
        className={classNames(`${BLOCK}__outer`, {
          [`${BLOCK}__outer--selected`]: item.exportDetailId === activeTab,
        })}
      >
        <div className={classNames(`${BLOCK}__outer__container`)}>
          <div
            className={classNames(`${BLOCK}__outer__container__title`, {
              [`${BLOCK}__outer__container--nd`]: item.exportState === 3,
            })}
          >
            <div
              className={`${BLOCK}__outer__container__title-icon`}
              data-test={`${BLOCK}__outer__container__title-icon`}
            >
              {/* For now, we only have pdf export */}
              {item.exportFormat === EXPORT_FORMAT.xls && (
                <span data-test="xlsIcon">
                  <Icon name={"xls"} height={25} width={27} />
                </span>
              )}
              {item.exportFormat === EXPORT_FORMAT.pdf && (
                <span data-test="pdfIcon">
                  <Icon name={"pdf-2"} height={25} width={27} />
                </span>
              )}

              {/*
            {item.exportFormat === 3 && (
              // word
              <span>
                <Icon name={"pdf-2"} height={25} width={27} />
              </span>
            )} */}
            </div>
            <div
              className={`${BLOCK}__outer__container__title-content`}
              data-test={`${BLOCK}__outer__container__title-content`}
            >
              {`${item.exportFileName}`}
            </div>
          </div>
          {(item.exportState === 3 || item.exportState === 4) && (
            <div>
              <div
                className={`${BLOCK}__outer__container-download`}
                data-test={`${BLOCK}__outer__container-download`}
              >
                {loadingDownload ? (
                  <span
                    className={`${BLOCK}__loading`}
                    data-test={`${BLOCK}__loading`}
                  >
                    <Icon
                      className={`${BLOCK}__loading`}
                      name="loading"
                      width={20}
                      height={20}
                    />
                  </span>
                ) : (
                  <span
                    onClick={downloadItem}
                    className={`${BLOCK}__action`}
                    data-test="downloads-tooltip"
                    data-testid="download"
                  >
                    <Icon name="download" width={24} height={24} />
                    <Tooltip
                      position={TooltipPosition.top}
                      data-test="downloads-tooltip-element"
                    >
                      <FormattedMessage id="downloads.tooltip.text" />
                    </Tooltip>
                  </span>
                )}
              </div>
            </div>
          )}
          <div
            className={`${BLOCK}__outer__container__title__timestamp`}
            data-test={`${BLOCK}__outer__container__title__timestamp`}
          >
            {moment(item.createdDate).format("YYYY-MM-DD hh:mm:ss")}
          </div>
        </div>
        <div
          className={classNames(
            {
              [`${BLOCK}__outer__container--started`]: !errorState,
            },
            {
              [`${BLOCK}__outer__container--notDownloaded`]:
                item.exportState === 3 && !progressState && !errorState,
            },
            {
              [`${BLOCK}__outer__container--progress`]:
                item.exportState === 2 && !progressState && !errorState,
            },
            {
              [`${BLOCK}__outer__container--error`]:
                errorState || item.exportState === 5,
            },
            {
              [`${BLOCK}__outer__container--downloaded`]:
                item.exportState === 4 && !errorState && !progressState,
            }
          )}
          data-test={`${BLOCK}__progress-bar`}
        ></div>
      </div>
    </>
  );
};

export default DownloadItem;
