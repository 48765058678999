import { Route, Switch } from "react-router-dom";
import Layout from "./layout";
import Home from "../home/home";
import { useEffect, useState } from "react";
import { authenticate, msalInstance } from "../../services/api.service";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import UnauthorizedPage from "../unauthorized/unauthorized";
import {
  logout,
  unauthorizedPath,
  homePath,
  dashboardPath,
  configurationsdPath,
  revenuePLdPath,
  yearlyUtilizationPath,
  headCountInputsPath,
  transformationsPath,
  loginPath,
  planSubmission,
  userGuidePath,
} from "../../utils/constants";
import history from "../../utils/history";
import { ConnectedRouter } from "connected-react-router";
import Logout from "../logout/logout";
import Login from "../login/login";
import Dashboard from "../dashboard/dashboard";
import VisualDashboard from "../visual_dashboard/visual_dashboard";
import Form from "../inputform/Form";
import UserGuide from "components/user-guide/user-guide";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasDataChanged, setHasDataChanged] = useState<boolean>(false);

  const { inProgress } = useMsal();
  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated)
      authenticate().then(setIsAuthenticated);
  }, [setIsAuthenticated, inProgress, isAuthenticated]);

  useEffect(() => {
    var scriptData = window.ESG.ENV.GEOS.find(
      (geo: any) => geo.GEO_CODE && geo.GEO_CODE !== "NONE"
    );
    if (isAuthenticated) {
      const script = document.createElement("script");
      script.src = scriptData.ONETRUST_URL;
      script.type = "text/javascript";
      script.dataset.domainScript = scriptData.ONETRUST_DOMAIN;
      document.body.appendChild(script);
      // console.log("script->", script);
    }
  }, [isAuthenticated]);

  return (
    <ConnectedRouter history={history}>
      <span className="cookielink" style={{ display: "none" }}>
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie-setting button - not for display
        </button>
      </span>
      <MsalProvider instance={msalInstance}>
        <Switch>
          <Route exact path={unauthorizedPath}>
            <Layout>
              <UnauthorizedPage />
            </Layout>
          </Route>
          <Route exact path={logout}>
            <Logout />
          </Route>
          <Route exact path={loginPath}>
            <Layout hideSideMenu>
              <Login />
            </Layout>
          </Route>
          <Route exact path={homePath}>
            <Layout>
              <Dashboard />
            </Layout>
          </Route>
          <Route exact path={`${planSubmission}/:id${configurationsdPath}`}>
            <Layout>
              <Form
                hasDataChanged={hasDataChanged}
                setHasDataChanged={setHasDataChanged}
                step={1}
              />
            </Layout>
          </Route>
          <Route exact path={`${planSubmission}/:id${revenuePLdPath}`}>
            <Layout>
              <Form
                hasDataChanged={hasDataChanged}
                setHasDataChanged={setHasDataChanged}
                step={2}
              />
            </Layout>
          </Route>
          <Route exact path={`${planSubmission}/:id${yearlyUtilizationPath}`}>
            <Layout>
              <Form
                hasDataChanged={hasDataChanged}
                setHasDataChanged={setHasDataChanged}
                step={3}
              />
            </Layout>
          </Route>
          <Route exact path={`${planSubmission}/:id${headCountInputsPath}`}>
            <Layout>
              <Form
                hasDataChanged={hasDataChanged}
                setHasDataChanged={setHasDataChanged}
                step={4}
              />
            </Layout>
          </Route>
          <Route
            exact
            path={`${planSubmission}/:id${transformationsPath}/:subsection?`}
          >
            <Layout>
              <Form
                hasDataChanged={hasDataChanged}
                setHasDataChanged={setHasDataChanged}
                step={5}
              />
            </Layout>
          </Route>
          <Route exact path={dashboardPath}>
            <Layout>
              <VisualDashboard />
            </Layout>
          </Route>
          <Route exact path={userGuidePath}>
              <UserGuide />
          </Route>
          <Route>
            <Layout>
              <Home />
            </Layout>
          </Route>
        </Switch>
      </MsalProvider>
    </ConnectedRouter>
  );
}

export default App;
