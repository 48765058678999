import { getComparisonData, getFilters, getLocation } from "./utils";
import TRACKING_ACTIONS from "./actions";

declare global {
  interface Window {
    digitalData: any;
  }
}
window.digitalData = window.digitalData || {};

export type TypeLocation = {
  hash: string;
  pageName: string;
  pathname?: string;
};

const getDataLayer = ({ pageName = "", pathname = "", email = "" }) => {
  return {
    page: {
      pageInfo: { pageName: pageName },
      category: { primaryCategory: pathname },
    },
    user: [
      {
        profile: [
          {
            profileInfo: { userName: email },
          },
        ],
      },
    ],
    search: {
      company: [],
      keywords: "",
      reportPeriod: [],
      industry: [],
      auditor: [],
      governingBoard: [],
    },
    visualization: {
      visualizationName: "",
      visualizationFilters: [],
      visualizationStyle: [],
    },
  };
};

class DataLayer {
  _data: {
    page: { pageInfo: { pageName: any }; category: { primaryCategory: any } };
    user: { profile: { profileInfo: { userName: any } }[] }[];
    search: {
      company: number[];
      keywords: string;
      reportPeriod: number[];
      industry: number[];
      auditor: number[];
      governingBoard: number[];
    };
    visualization: {
      visualizationName: string;
      visualizationFilters: any;
      visualizationStyle: any;
    };
  };
  constructor() {
    this._data = getDataLayer({});
  }

  // Assign a fresh data layer to this data with specified merge props
  set(mergeProps: {
    pageName?: string | undefined;
    pathname?: string | undefined;
    email?: string | undefined;
  }) {
    this._data = getDataLayer(mergeProps);
  }

  // Clear existing digital data
  clear() {
    window.digitalData = getDataLayer({});
  }

  // Merge in props to existing data layer
  merge(...props: any[]) {
    Object.assign(this._data, ...props);
  }

  // Update the global digital data to this data
  update() {
    window.digitalData = this._data;
  }
}

const dl = new DataLayer();
// Update digital data based on the event action and paylaod
const updateDataLayer = (reduxState: any) => {
  // User Data is not yet configured, to be taken from the user info in redux store where it will be located

  const user = reduxState.commons.currentUser;
  const page: TypeLocation = getLocation(
    reduxState.router.location.pathname,
    reduxState.router.location
  );

  // User info and location will always be available on the data layer
  dl.merge({
    user: [
      {
        profile: [
          {
            profileInfo: {
              userName: user.email
                ? user?.email?.split("@")?.[0]
                : window?.localStorage?.getItem("userID")?.split("@")?.[0],
            },
          },
        ],
      },
    ],
    page: {
      pageInfo: {
        pageName: page.pageName,
      },
      category: { primaryCategory: page.pathname },
    },
    visualization: {},
  });

  dl.update();

  return dl;
};

export default updateDataLayer;
