import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import Table, { TableRowItem } from "../shared/table/table";
import {
  VISUAL_DASHBOARD_OPTIONS,
  ConsolidationTransformationAssumptionsCommonModel,
} from "services/visualDashboard/visual.dashboard.models";
import Icon from "components/shared/icon/icon";
import { TransformationConsolidationsColumns } from "utils/constants";

type ConsolidationTables = {
  tableData: any;
};

const ConsolidationTables = ({ tableData }: ConsolidationTables) => {
  const BLOCK = "headcountsTable";
  const consolidationData = useSelector(
    (state: RootStore) => state.visualDashboard
  );
  const dashboardTableState = useSelector(
    (state: RootStore) => state.dashboard
  );
  const [TransformationBaseYearHeaders, setTransformationBaseYearHeaders] =
    useState<any>(null);
  const [newTableData, setNewTableData] = useState<any>({});

  useEffect(() => {
    let baseYear: number = 0;
    if (
      consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails &&
      Object.keys(
        consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails
      ).length &&
      consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails[0]
        ?.year
    ) {
      baseYear =
        consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails[0]
          ?.year;
    } else if (
      dashboardTableState?.fiscalYearList &&
      Object.keys(dashboardTableState?.fiscalYearList).length &&
      dashboardTableState?.fiscalYearList[0]?.year
    ) {
      baseYear = parseInt(
        dashboardTableState?.fiscalYearList[0]?.year?.toString().slice(-2)
      );
    }
    if (baseYear) {
      let resultArr: any = [
        {
          text: "",
          isFormattedText: false,
        },
      ];
      const yearsList = findYearsList(
        consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails
      );
      if (yearsList && yearsList.length > 0) {
        for (let i = 0; i < yearsList.length; i++) {
          let year = baseYear ? baseYear + i : 0;

          let Obj =
            i !== 0
              ? {
                  text: "FY" + year,
                  isFormattedText: false,
                }
              : {
                  text: "visual.dashboard.table.consolidation.TA.opening",
                  isFormattedText: true,
                };
          resultArr.push(Obj);
        }
        setTransformationBaseYearHeaders(resultArr);
      } else {
        setTransformationBaseYearHeaders(null);
      }
    }
  }, [
    consolidationData?.netRevenueData?.consolidatedProfitAndLossDetails,
    dashboardTableState?.fiscalYearList,
  ]);
  const findYearsList = (data: any) => {
    if (data && Object.keys(data).length) {
      const result: any = [];
      data.map((item: any) => {
        if (!result.includes(item.year)) {
          result.push(item.year);
        }
      });
      return result;
    }
  };

  useEffect(() => {
    const updatedTableData: any = {};

    tableData?.length > 0 &&
      tableData?.map(
        (item: ConsolidationTransformationAssumptionsCommonModel) => {
          if (updatedTableData[TransformationConsolidationsColumns.DC_TOTAL]) {
            updatedTableData[TransformationConsolidationsColumns.DC_TOTAL] = [
              ...updatedTableData[TransformationConsolidationsColumns.DC_TOTAL],
              {
                value: item.dcTotalUsage.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              },
            ];
          } else {
            updatedTableData[TransformationConsolidationsColumns.DC_TOTAL] = [
              {
                value: item.dcTotalUsage.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              },
            ];
          }
          if (
            updatedTableData[
              TransformationConsolidationsColumns.NET_TRANSFORMATION_NET_HOURS
            ]
          ) {
            updatedTableData[
              TransformationConsolidationsColumns.NET_TRANSFORMATION_NET_HOURS
            ] = [
              ...updatedTableData[
                TransformationConsolidationsColumns.NET_TRANSFORMATION_NET_HOURS
              ],
              {
                value: item.netTransInitiativeNetHoursChange.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                ),
              },
            ];
          } else {
            updatedTableData[
              TransformationConsolidationsColumns.NET_TRANSFORMATION_NET_HOURS
            ] = [
              {
                value: item.netTransInitiativeNetHoursChange.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                ),
              },
            ];
          }
          if (
            updatedTableData[
              TransformationConsolidationsColumns.NET_TRANSFORMATION_EFFICIENCY
            ]
          ) {
            updatedTableData[
              TransformationConsolidationsColumns.NET_TRANSFORMATION_EFFICIENCY
            ] = [
              ...updatedTableData[
                TransformationConsolidationsColumns
                  .NET_TRANSFORMATION_EFFICIENCY
              ],
              {
                value: item.netTransInitiativeEffImprovement.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                ),
              },
            ];
          } else {
            updatedTableData[
              TransformationConsolidationsColumns.NET_TRANSFORMATION_EFFICIENCY
            ] = [
              {
                value: item.netTransInitiativeEffImprovement.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                ),
              },
            ];
          }
          if (
            updatedTableData[
              TransformationConsolidationsColumns.NET_TRANSFORMATION_ACCUMULATED
            ]
          ) {
            updatedTableData[
              TransformationConsolidationsColumns.NET_TRANSFORMATION_ACCUMULATED
            ] = [
              ...updatedTableData[
                TransformationConsolidationsColumns
                  .NET_TRANSFORMATION_ACCUMULATED
              ],
              {
                value:
                  item.netTransInitiativeAccumulatedNetHoursChange.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  ),
              },
            ];
          } else {
            updatedTableData[
              TransformationConsolidationsColumns.NET_TRANSFORMATION_ACCUMULATED
            ] = [
              {
                value:
                  item.netTransInitiativeAccumulatedNetHoursChange.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  ),
              },
            ];
          }
        }
      );
    setNewTableData(updatedTableData);
  }, [tableData]);

  const getRowItems = () => {
    let consolidationRowsData = Object.keys(newTableData || {}).map(
      (item: any, index: number) => {
        const dynamicRows: any = newTableData[item].map((item: any) => {
          return { text: item.value === 0 ? "0" : item.value };
        });
        let tableRows = {
          id: index,
          key: `${item}-${index}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: item,
            },
            ...dynamicRows,
          ],
        };
        return tableRows;
      }
    );
    return consolidationRowsData;
  };
  return (
    <div>
      {tableData?.length > 0 ? (
        <Table
          className={`${BLOCK}--innerWrapper`}
          headerItems={TransformationBaseYearHeaders}
          rowItems={getRowItems()}
          innerClassName={BLOCK}
        />
      ) : consolidationData?.loading?.filter((item: any) => {
          return (
            item ===
            VISUAL_DASHBOARD_OPTIONS.CONSOLIDATION_TRANSFORMATION_ASSUMPTION_LOADING
          );
        })?.length > 0 ? (
        <div className={`${BLOCK}__spinner-container`}>
          <Icon
            name={"loading"}
            width={50}
            height={50}
            className={`${BLOCK}--loading`}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ConsolidationTables;
