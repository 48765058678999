import Modal from "../modal/modal";
import { RootStore } from "services/store.service";
import { conversion } from "utils/constants";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useDispatch, useSelector } from "react-redux";
import { setTableNumberWhenPopup } from "services/inputForm/inputFormSlice";
type NotSavedPopUpProps = {
  showModal: any;
  confirmChangeTabs: any;
  destination: any;
};

const NotSavedPopUp = ({
  showModal,
  confirmChangeTabs,
  destination,
}: NotSavedPopUpProps) => {
  const BLOCK = "submitReviewModal";
  const dispatch = useDispatch();
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const handleClose = () => {
    showModal(false);
  };

  const onContinue = () => {
    confirmChangeTabs(destination.pathName, true, destination.index);
    dispatch(
      setTableNumberWhenPopup(inputFormDataState.changeTableNumber * 100)
    );
    showModal(false);
  };

  return (
    <Modal
      show={true}
      header={"not.saved.pop.up.header"}
      handleClose={handleClose}
      config={false}
      hideBackgroud={false}
      data-test="notSaved-warning-popup"
    >
      <div className={BLOCK}>
        <div className={`${BLOCK}__content`}>
          <div className={`${BLOCK}__content--text`}>
            <FormattedMessage id={`not.saved.pop.message`} />
          </div>
          <div className={`${BLOCK}__content--footer`}>
            <div className={`${BLOCK}__content--footer--actions--warning`}>
              <button
                className={`${BLOCK}__content--footer--actions--cancel`}
                onClick={() => handleClose()}
                data-test="closeButton"
              >
                <FormattedMessage id={`input.form.submit.review.cancel`} />
              </button>
              <button
                className={`${BLOCK}__content--footer--actions--confirm`}
                onClick={() => onContinue()}
                data-test="continueButton"
              >
                <FormattedMessage id={`not.saved.pop.up.continue`} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NotSavedPopUp;
