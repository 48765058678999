import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import NavigationPanel from "./navigationPanel";
import Configuration from "../configuration/configuration";
import RevenueAndPLTab from "../revenue&PL/revenue&PL";
import YearlyUtlizationTab from "../yearlyUtilization/yearlyUtilization";
import Transformations from "../transformations/transformation";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import { useHistory } from "react-router-dom";
import NotSavedPopUp from "../shared/not-saved-warning-modal/notSavedWarningPopUp";
import { getDashboardTableList } from "../../services/dashboard/dashBoardSlice";
import {
  STATUS_ID_MAPPING,
  Roles,
  revenuePLdPath,
  headCountInputsPath,
  yearlyUtilizationPath,
  planSubmission,
  EXPORT_FORMAT,
  EXPORT_TYPE_MAPPING,
  transformationsPath,
  conversion,
  REVENUE_AND_PL_TABLE_IDS,
  HEAD_COUNTS_TABLE_IDS,
  TRANSFERMATION_TABLE_IDS,
  CALCULATE_ENGINE,
  liberatedHours,
  revenueAndPlTablesList,
  headCountTablesList,
  YEARLY_UTILIZATION_TABLE_ID,
} from "utils/constants";
import { useInputForm, useEnableInputForm } from "../../utils/useInputForm";
import { sendExportItem } from "services/download/downloadSlice";
import {
  setMetaData,
  loadConfiguration,
  getStatusId,
  getProgressPercentage,
  setInputformIconClick,
  submitConfigurationData,
  setConfigurationDataSubmit,
  submitForReviewInputForm,
  completeReviewInputForm,
  submitConvertionRatiosTableData,
  setInputFormSaved,
  calculateOpeningAndClosingPositionAPI,
  setHighlightEmptyInput,
  getConversionRatiosTableData,
  TruncateAllInputformRecords,
  setConversionRatiosGetDataCompareData,
} from "services/inputForm/inputFormSlice";
import classNames from "classnames";
import SubmitReviewPopUp from "./submitForReviewModal";
import { addToastMessage } from "services/common/commonsSlice";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import UpdatedHeadcounts from "../updatedHeadCounts/headCounts";
import { HeadCountsContextProvider } from "../updatedHeadCounts/headCountsContextProvider";
import { RevenueContextProvider } from "../updatedRevenuePL/revenuePnlContextProvider";
import { YearlyUtilizationContextProvider } from "../yearlyUtilization/yearlyUtilizationContextProvider";
import { TransformationAssumptionContextProvider } from "../updatedTransformationAssumptions/transformationAssumptionContextProvider";
import Toggle from "components/shared/toggle/toggle";
import { isEqual } from "lodash";
import { LiberatedHoursContextProvider } from "../liberatedHours/liberatedHoursContextProvider";
import { cleanTableData } from "components/shared/commons/globalFunctions";

type FormProps = {
  step: number;
  hasDataChanged: Boolean;
  setHasDataChanged: Function;
};

const Form = ({ step, hasDataChanged, setHasDataChanged }: FormProps) => {
  const BLOCK = "inputform";
  const params: any = useParams();
  const history = useHistory();
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const dispatch = useDispatch();
  const [toggleNavigation, setToggleNavigation] = useState<boolean>(true);
  const [statusIcon, setIconStatus] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [totalRevPnLFields, setTotalRevPnLFields] = useState<number>(0);
  const [totalFilledRevPnL, setTotalFilledRevPnL] = useState<number>(0);
  const [totalYearlyUtilizationFields, setTotalYearlyUtilizationFields] =
    useState<number>(0);
  const [totalFilledYearlyUtilization, setTotalFilledYearlyUtilization] =
    useState<number>(0);
  const [totalFilledHeadcounts, setTotalFilledHeadcounts] = useState<number>(0);
  const [totalFieldsHeadcounts, setTotalFieldsHeadcounts] = useState<number>(0);
  const [totalFieldsConfigurations, setTotalFieldsConfiguraitons] =
    useState<number>(4);
  const [stepSelectedRevenuePl, setStepSelectedRevenuePl] = useState<
    string | null
  >(params?.subsection);
  const [stepSelectedHeadCount, setStepSelectedHeadCount] = useState<
    string | null
  >(params?.subsection);
  const [stepSelectedTransformation, setStepSelectedTransformation] = useState<
    string | null
  >(params?.subsection);
  const [configurationPercent, setConfigurationPercent] = useState<number>(0);
  const [totalFilledConfig, setTotalFilledConfig] = useState<number>(0);
  const [saved, setSaved] = useState<boolean>(true);
  const [yearlyUtilizationTableProgress, setYearlyUtilizationTableProgress] =
    useState<number>(0);
  const [revenuePLTableProgress, setRevenuePLTableProgress] =
    useState<number>(0);
  const [totalPercentage, setTotalPercentage] = useState<number>();
  const [headCountsTableProgress, setHeadCountsTableProgress] =
    useState<number>(0);
  const [transformationPercent, setTransformationPercent] = useState<number>(0);
  const [submitForReviewBtnDisabled, setSubmitForReviewBtnDisabled] =
    useState<boolean>(true);
  const [exportBtnDisabled, setExportBtnDisabled] = useState<boolean>(true);
  const [submitConfirmReviewBtnDisabled, setSubmitConfirmReviewBtnDisabeld] =
    useState<boolean>(true);
  const [showSubmitReviewModal, setShowSubmitReviewModal] =
    useState<boolean>(false);
  const [destination, setDestination] = useState<any>();
  const [showSubmitFormBtn, setShowSubmitFormBtn] = useState<boolean>(false);
  const [submitFormBtnDisabled, setSubmitFormBtnDisabled] =
    useState<boolean>(false);
  const [showSubmittedBtn, setShowSubmittedBtn] = useState<boolean>(false);
  const [userRoleId, setUserRoleId] = useState<number>(-1);
  const [preparerEmail, setPreparerEmail] = useState<string>();
  const [reviewerEmail, setReviewerEmail] = useState<string>();
  const [mfAdminEmail, setMfAdminEmail] = useState<string>();
  const [showWarningPopUp, setShowWarningPopUp] = useState<boolean>(false);
  const [totalFieldsTransformation, setTotalFieldsTransformation] =
    useState<number>(0);
  const [totalFilledTransformation, setTotalFilledTransformation] =
    useState<number>(0);
  const [savingData, setSavingData] = useState<boolean>(false);
  const [pageRefresh, setPageRefresh] = useState<boolean>(true);
  const [oneTimeCall, setOneTimeCall] = useState<boolean>(true);
  const [headCountApiCall, setHeadCountApiCall] = useState<any>(false);
  const [revenuaApiCall, setRevenuaApiCall] = useState<any>(false);
  const [yearlyUtilizationApiCall, setYearlyUtilizationApiCall] =
    useState<any>(false);
  const [
    transformationAssumptionsApiCall,
    setTransformationAssumptionsApiCall,
  ] = useState<any>(false);
  const [liberatedHoursApiCall, setLiberatedHoursApiCall] =
    useState<any>(false);
  const [call_OC_CalculationApi, setCall_OC_CalculationApi] =
    useState<boolean>(true);
  let locationName = useLocation().pathname;
  let planSubmissionId = inputFormDataState?.metaData?.planSubmissionId
    ? inputFormDataState?.metaData?.planSubmissionId
    : Number(params?.id);
  const [revenueDirtyTables, setRevenueDirtyTables] = useState<Number[]>([]);
  const [yearlyUtilizationDirtyTables, setYearlyUtilizationDirtyTables] =
    useState<Number[]>([]);
  const [headCountsDirtyTables, setHeadCountsDirtyTables] = useState<Number[]>(
    []
  );
  const [transformationDirtyTables, setTransformationDirtyTables] =
    useState<any>([]);
  const [liberatedHoursDirtyTables, setLiberatedHoursDirtyTables] =
    useState<any>([]);
  const [isConversionTableDirty, setIsConversionTableDirty] =
    useState<boolean>(false);
  const [configurationDirty, setConfigurationDirty] = useState<boolean>(false);
  let submitConfiguration = useInputForm(inputFormDataState);

  let enableAllInputForms = useEnableInputForm(inputFormDataState);

  const dashboardTableState = useSelector(
    (state: RootStore) => state.dashboard
  );

  useEffect(() => {
    if (
      revenueDirtyTables.length > 0 ||
      yearlyUtilizationDirtyTables.length > 0 ||
      headCountsDirtyTables.length > 0 ||
      transformationDirtyTables.length > 0 ||
      liberatedHoursDirtyTables.length > 0 ||
      isConversionTableDirty ||
      configurationDirty
    ) {
      setHasDataChanged(true);
    } else {
      setHasDataChanged(false);
    }
  }, [
    configurationDirty,
    isConversionTableDirty,
    liberatedHoursDirtyTables,
    transformationDirtyTables,
    headCountsDirtyTables,
    yearlyUtilizationDirtyTables,
    revenueDirtyTables,
  ]);

  useEffect(() => {
    if (!hasDataChanged) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasDataChanged]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === "POP" && hasDataChanged) {
        return "Changes you made may not be saved.";
      }
    });

    return () => {
      unblock();
    };
  }, [history, hasDataChanged]);

  useEffect(() => {
    setIsConversionTableDirty(false);
    setConfigurationDirty(false);
    setRevenueDirtyTables([]);
    setYearlyUtilizationDirtyTables([]);
    setHeadCountsDirtyTables([]);
    setTransformationDirtyTables([]);
    setLiberatedHoursDirtyTables([]);
  }, [history.location]);

  useEffect(() => {
    if (dashboardTableState?.geographyList === null) {
      dispatch(getDashboardTableList(dashboardTableState?.selectedYear.year));
    }
    if (oneTimeCall) {
      dispatch(getProgressPercentage(planSubmissionId));
      dispatch(loadConfiguration(planSubmissionId));
      dispatch(getStatusId(planSubmissionId));
      setOneTimeCall(false);
    }
  }, []);

  useEffect(() => {
    if (
      inputFormDataState?.configurationData &&
      Object.keys(inputFormDataState.configurationData).length &&
      inputFormDataState?.configurationData?.baseYear &&
      Number(params?.id)
    ) {
      let planSubmissionId = Number(params?.id);
      const currentBaseYear: any =
        inputFormDataState?.configurationData?.baseYear?.toString().slice(-2);
      let baseYear = Number(currentBaseYear);
      dispatch(setMetaData({ planSubmissionId, baseYear }));
    }
  }, [inputFormDataState.configurationData]);

  useEffect(() => {
    dispatch(setConfigurationDataSubmit(inputFormDataState.configurationData));
  }, [inputFormDataState.configurationData]);

  useEffect(() => {
    let planSubmissionsForUser =
      currentUser?.planSubmissionRoles?.length > 0 &&
      currentUser.planSubmissionRoles.filter(
        (planSubmission: any) =>
          planSubmission.planSubmissionId === planSubmissionId &&
          (planSubmission.roleId === Roles.preparer ||
            planSubmission.roleId === Roles.reviewer)
      );

    let currUserRole =
      currentUser?.userRoles?.length > 0 ? currentUser.userRoles[0].roleId : 0;

    let currentUserRoleSet =
      planSubmissionsForUser && planSubmissionsForUser.length > 0
        ? planSubmissionsForUser[0].roleId
        : currUserRole;
    setUserRoleId(currentUserRoleSet);
  }, [currentUser, locationName, planSubmissionId]);

  useEffect(() => {
    let tableDataToCompareWithany: any = [];

    inputFormDataState?.conversionRatiosGetData?.map((item: any) => {
      const newObject: any = {};
      for (const key in item) {
        if (!key.includes("error")) {
          newObject[key] = item[key];
        }
      }
      tableDataToCompareWithany.push(newObject);
    });
    if (
      locationName.includes(transformationsPath) &&
      params?.subsection == conversion &&
      !isEqual(
        tableDataToCompareWithany,
        inputFormDataState.conversionRatiosGetDataCompareData
      )
    ) {
      setIsConversionTableDirty(true);
    } else {
      setIsConversionTableDirty(false);
    }
  }, [inputFormDataState]);

  const commonChangeSection = (pathName: any, index: number) => {
    history.push(`${planSubmission}/${planSubmissionId}${pathName}`);
    revenueAndPlTablesList.map((item: any) => {
      if (pathName.includes(`${revenuePLdPath}/${item.tablePath}`))
        setStepSelectedRevenuePl(`${item.tablePath}`);
    });
    headCountTablesList.map((item: any) => {
      if (pathName.includes(`${headCountInputsPath}/${item.tablePath}`))
        setStepSelectedHeadCount(`${item.tablePath}`);
    });
    if (pathName.includes(`${transformationsPath}/conversion`))
      setStepSelectedTransformation("conversion");
    else if (pathName.includes(`${transformationsPath}/liberatedhours`))
      setStepSelectedTransformation("liberatedhours");
    else if (pathName.includes(`${transformationsPath}/FY`) && index !== -1)
      setStepSelectedTransformation(
        `FY${inputFormDataState?.metaData?.baseYear + index + 1}`
      );
    if (
      isConversionTableDirty &&
      pathName.includes(`${transformationsPath}/FY`)
    ) {
      dispatch(getConversionRatiosTableData(planSubmissionId));
    }
    setSaved(true);
    setIsConversionTableDirty(false);
    setConfigurationDirty(false);
    setRevenueDirtyTables([]);
    setYearlyUtilizationDirtyTables([]);
    setHeadCountsDirtyTables([]);
    setTransformationDirtyTables([]);
    setLiberatedHoursDirtyTables([]);
    return true;
  };

  const changeTabs = (
    pathName: any,
    bypass: boolean = false,
    index: number
  ) => {
    if (bypass) {
      return commonChangeSection(pathName, index);
    } else if (
      revenueDirtyTables.length > 0 ||
      yearlyUtilizationDirtyTables.length > 0 ||
      headCountsDirtyTables.length > 0 ||
      transformationDirtyTables.length > 0 ||
      liberatedHoursDirtyTables.length > 0 ||
      isConversionTableDirty ||
      configurationDirty
    ) {
      setShowWarningPopUp(true);
    } else {
      return commonChangeSection(pathName, index);
    }
  };

  const handleInputFormSubmit = (isNavigate: boolean = true) => {
    setSaved(true);
    if (
      submitConfiguration &&
      locationName.split("/").pop() === "configuration" &&
      (configurationDirty || isNavigate)
    ) {
      setSavingData(true);
      const payload = inputFormDataState.configurationDataSubmit;
      submitConfigurationData(payload, planSubmissionId).then(() => {
        dispatch(getProgressPercentage(planSubmissionId));
        setConfigurationDirty(false);
        setSavingData(false);
        dispatch(loadConfiguration(planSubmissionId));
        totalPercentage &&
          totalPercentage === 100 &&
          dispatch(getStatusId(planSubmissionId));
        dispatch(calculateOpeningAndClosingPositionAPI(planSubmissionId));
        dispatch(TruncateAllInputformRecords(true));
        if (isNavigate) {
          history.push(
            `${planSubmission}/${planSubmissionId}${revenuePLdPath}`
          );
          setStepSelectedRevenuePl(`${"fyrevenue"}`);
        }
      });
    }

    if (locationName.includes(revenuePLdPath)) {
      setSavingData(true);
      setRevenuaApiCall(true);
    }

    if (locationName.includes(yearlyUtilizationPath)) {
      setSavingData(true);
      setYearlyUtilizationApiCall(true);
    }

    if (locationName.includes(headCountInputsPath)) {
      setSavingData(true);
      setHeadCountApiCall(true);
    }

    if (
      locationName.includes(transformationsPath) &&
      params?.subsection == conversion
    ) {
      setSavingData(true);
      Promise.all([
        submitConvertionRatiosTableData(
          inputFormDataState.conversionRatiosGetData,
          planSubmissionId
        ),
      ])
        .then(() => {
          setIsConversionTableDirty(false);
          setSavingData(false);
          totalPercentage &&
            totalPercentage === 100 &&
            dispatch(getStatusId(planSubmissionId));
          dispatch(getProgressPercentage(planSubmissionId));

          dispatch(
            setConversionRatiosGetDataCompareData(
              cleanTableData(inputFormDataState?.conversionRatiosGetData)
            )
          );

          let engineObject = {
            tableProgress: transformationPercent == 100,
            inputFormSection: CALCULATE_ENGINE.TRANSFORMATION_ASSUMPTIONS,
          };

          dispatch(
            calculateOpeningAndClosingPositionAPI(
              planSubmissionId,
              false,
              engineObject
            )
          );
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="form.save.toaster.message" />,
            })
          );
        })
        .catch(() => {
          setSavingData(false);
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="form.error.toaster.message" />,
            })
          );
        });
    }

    if (
      locationName.includes(transformationsPath) &&
      params?.subsection == liberatedHours
    ) {
      setSavingData(true);
      setLiberatedHoursApiCall(true);
    }

    if (
      locationName.includes(transformationsPath) &&
      params?.subsection !== conversion &&
      params?.subsection !== liberatedHours
    ) {
      setSavingData(true);
      setTransformationAssumptionsApiCall(true);
    }
  };

  useEffect(() => {
    if (
      revenueDirtyTables.length > 0 ||
      yearlyUtilizationDirtyTables.length > 0 ||
      headCountsDirtyTables.length > 0 ||
      transformationDirtyTables.length > 0 ||
      liberatedHoursDirtyTables.length > 0 ||
      isConversionTableDirty ||
      configurationDirty
    ) {
      dispatch(setInputFormSaved(false));
    } else {
      dispatch(setInputFormSaved(true));
    }
  }, [
    saved,
    revenueDirtyTables,
    yearlyUtilizationDirtyTables,
    headCountsDirtyTables,
    transformationDirtyTables,
    liberatedHoursDirtyTables,
    isConversionTableDirty,
    configurationDirty,
    inputFormDataState?.tableNumber,
  ]);
  useEffect(() => {
    return () => {
      dispatch(setInputFormSaved(true));
    };
  }, []);

  useEffect(() => {
    if (
      inputFormDataState.preparerEmailId !== null &&
      inputFormDataState.reviewerEmailid !== null &&
      inputFormDataState.mfAdminEmailId !== null
    ) {
      setPreparerEmail(inputFormDataState.preparerEmailId);
      setReviewerEmail(inputFormDataState.reviewerEmailid);
      setMfAdminEmail(inputFormDataState.mfAdminEmailId);
    }
  }, [
    inputFormDataState.preparerEmailId,
    inputFormDataState.reviewerEmailid,
    inputFormDataState.mfAdminEmailId,
  ]);

  useEffect(() => {
    if (
      call_OC_CalculationApi &&
      inputFormDataState.inputFormStatusId ===
        STATUS_ID_MAPPING.ROLL_FORWARD_HALTED.id
    ) {
      dispatch(calculateOpeningAndClosingPositionAPI(planSubmissionId, true));
      setCall_OC_CalculationApi(false);
    }
    if (totalPercentage && totalPercentage === 100) {
      //for preparer
      setSubmitForReviewBtnDisabled(false);

      //for reviewer (must be in prepared state)
      if (
        inputFormDataState.inputFormStatusId === STATUS_ID_MAPPING.PREPARED.id
      ) {
        setSubmitConfirmReviewBtnDisabeld(false);
      }

      //for all users
      setExportBtnDisabled(false);
    } else {
      //total percentage not 100
      setExportBtnDisabled(true);
      setSubmitForReviewBtnDisabled(true);
      setSubmitConfirmReviewBtnDisabeld(true);
    }
  }, [
    totalPercentage,
    totalFilledYearlyUtilization,
    totalFieldsHeadcounts,
    totalFilledRevPnL,
    inputFormDataState.inputFormStatusId,
  ]);

  useEffect(() => {
    if (
      inputFormDataState.inputFormStatusId === STATUS_ID_MAPPING.IN_PROGRESS.id
    ) {
      setStatus(STATUS_ID_MAPPING.IN_PROGRESS.text);
      setIconStatus(STATUS_ID_MAPPING.IN_PROGRESS.icon);
    } else if (
      inputFormDataState.inputFormStatusId === STATUS_ID_MAPPING.PREPARED.id
    ) {
      setStatus(STATUS_ID_MAPPING.PREPARED.text);
      setIconStatus(STATUS_ID_MAPPING.PREPARED.icon);

      //for reviewer
      setSubmitConfirmReviewBtnDisabeld(false);
    } else if (
      inputFormDataState.inputFormStatusId === STATUS_ID_MAPPING.REVIEWED.id
    ) {
      setStatus(STATUS_ID_MAPPING.REVIEWED.text);
      setIconStatus(STATUS_ID_MAPPING.REVIEWED.icon);

      //for preparer and reviewer
      setShowSubmitFormBtn(true);
      setSubmitFormBtnDisabled(false);
    } else if (
      inputFormDataState.inputFormStatusId === STATUS_ID_MAPPING.SUBMITTED.id
    ) {
      setStatus(STATUS_ID_MAPPING.SUBMITTED.text);
      setIconStatus(STATUS_ID_MAPPING.SUBMITTED.icon);

      //for preparer and reviewer
      setShowSubmitFormBtn(false);
      setShowSubmittedBtn(true);
    } else if (
      inputFormDataState.inputFormStatusId === STATUS_ID_MAPPING.ARCHIVED.id
    ) {
      //for Archived inputs
      setStatus(STATUS_ID_MAPPING.ARCHIVED.text);
      setIconStatus(STATUS_ID_MAPPING.ARCHIVED.icon);
    } else if (
      inputFormDataState.inputFormStatusId ===
      STATUS_ID_MAPPING.ROLL_FORWARD_IN_PROGRESS.id
    ) {
      //for Roll forward in progress inputs
      setStatus(STATUS_ID_MAPPING.ROLL_FORWARD_IN_PROGRESS.text);
      setIconStatus(STATUS_ID_MAPPING.ROLL_FORWARD_IN_PROGRESS.icon);
    } else if (
      inputFormDataState.inputFormStatusId ===
      STATUS_ID_MAPPING.ROLL_FORWARD_HALTED.id
    ) {
      //for Roll forward halted inputs
      setStatus(STATUS_ID_MAPPING.ROLL_FORWARD_HALTED.text);
      setIconStatus(STATUS_ID_MAPPING.ROLL_FORWARD_HALTED.icon);
    }
  }, [inputFormDataState.inputFormStatusId]);

  useEffect(() => {
    if (pageRefresh && !inputFormDataState?.onClickOnInputForm) {
      if (
        inputFormDataState?.InputFormProgressData &&
        Object.keys(inputFormDataState.InputFormProgressData).length
      ) {
        setTotalPercentage(
          inputFormDataState?.InputFormProgressData?.percentCompletion
        );
        setPageRefresh(false);
      }
    }
  }, [inputFormDataState?.InputFormProgressData]);

  useEffect(() => {
    if (inputFormDataState?.onClickOnInputForm === true) {
      if (
        inputFormDataState?.InputFormProgressData &&
        Object.keys(inputFormDataState.InputFormProgressData).length
      ) {
        setTotalPercentage(
          inputFormDataState?.InputFormProgressData?.percentCompletion
        );
        dispatch(setInputformIconClick(false));
      }
    }
  }, [inputFormDataState.onClickOnInputForm]);

  useEffect(() => {
    let filledInValues = 0;
    if (inputFormDataState.configurationDataSubmit.countryName?.length > 0)
      filledInValues++;
    if (inputFormDataState.configurationDataSubmit.currency !== null)
      filledInValues++;
    if (inputFormDataState.configurationDataSubmit.hasOther !== null)
      filledInValues++;
    if (inputFormDataState.configurationDataSubmit.hasTier5 !== null)
      filledInValues++;

    setTotalFilledConfig(filledInValues);
    const currentProgress = (filledInValues / totalFieldsConfigurations) * 100;
    setConfigurationPercent(currentProgress);
  }, [inputFormDataState.configurationDataSubmit]);

  useEffect(() => {
    let inputFormProgressData = inputFormDataState?.InputFormProgressDataLocal
      ?.fieldCountDetails?.length
      ? inputFormDataState?.InputFormProgressDataLocal
      : inputFormDataState?.InputFormProgressData;

    let revenueTotalFields = 0;
    let revenueCompletedFields = 0;
    inputFormProgressData?.fieldCountDetails?.map((item: any) => {
      for (let tableId of REVENUE_AND_PL_TABLE_IDS) {
        if (parseInt(item.inputFormMasterTableId) === tableId) {
          revenueTotalFields = revenueTotalFields + item.totalFields;
          revenueCompletedFields =
            revenueCompletedFields + item.completedFields;
        }
      }
    });
    const revPnLTableFilledPercent =
      (revenueCompletedFields / revenueTotalFields) * 100;
    setRevenuePLTableProgress(revPnLTableFilledPercent);
    setTotalRevPnLFields(revenueTotalFields);
    setTotalFilledRevPnL(revenueCompletedFields);

    let yearlyUtilizationTotalFields = 0;
    let yearlyUtilizationCompletedFields = 0;
    inputFormProgressData?.fieldCountDetails?.map((item: any) => {
      if (
        parseInt(item.inputFormMasterTableId) === YEARLY_UTILIZATION_TABLE_ID
      ) {
        yearlyUtilizationTotalFields = item.totalFields;
        yearlyUtilizationCompletedFields = item.completedFields;
      }
    });
    const yearlyUtilizationFilledPercent =
      (yearlyUtilizationCompletedFields / yearlyUtilizationTotalFields) * 100;
    setYearlyUtilizationTableProgress(yearlyUtilizationFilledPercent);
    setTotalYearlyUtilizationFields(yearlyUtilizationTotalFields);
    setTotalFilledYearlyUtilization(yearlyUtilizationCompletedFields);

    let headCoutsTotalFields = 0;
    let headCountsCompletedFields = 0;
    inputFormProgressData?.fieldCountDetails?.map((item: any) => {
      for (let tableId of HEAD_COUNTS_TABLE_IDS) {
        if (parseInt(item.inputFormMasterTableId) === tableId) {
          headCoutsTotalFields = headCoutsTotalFields + item.totalFields;
          headCountsCompletedFields =
            headCountsCompletedFields + item.completedFields;
        }
      }
    });
    const headCountsTableFilledPercent =
      (headCountsCompletedFields / headCoutsTotalFields) * 100;
    setHeadCountsTableProgress(headCountsTableFilledPercent);
    setTotalFieldsHeadcounts(headCoutsTotalFields);
    setTotalFilledHeadcounts(headCountsCompletedFields);

    let TA_TotalFields = 0;
    let TA_CompletedFields = 0;
    inputFormProgressData?.fieldCountDetails?.map((item: any) => {
      for (let tableId of TRANSFERMATION_TABLE_IDS) {
        if (parseInt(item.inputFormMasterTableId) === tableId) {
          TA_TotalFields = TA_TotalFields + item.totalFields;
          TA_CompletedFields = TA_CompletedFields + item.completedFields;
        }
      }
    });
    const TA_TableFilledPercent = (TA_CompletedFields / TA_TotalFields) * 100;
    setTransformationPercent(TA_TableFilledPercent);
    setTotalFieldsTransformation(TA_TotalFields);
    setTotalFilledTransformation(TA_CompletedFields);
  }, [
    inputFormDataState?.InputFormProgressData,
    inputFormDataState?.InputFormProgressDataLocal,
  ]);

  const exportSeedModel = async () => {
    if (inputFormDataState.inputFormStatusId !== STATUS_ID_MAPPING.ARCHIVED.id)
      await handleInputFormSubmit();
    const payload = {
      exportReferenceId: planSubmissionId,
      exportFileName: `${inputFormDataState.configurationData.countryName}_Model`,
      exportType: EXPORT_TYPE_MAPPING.ENGINE_EXPORT,
      exportFormat: EXPORT_FORMAT.xls,
    };
    dispatch(sendExportItem(payload));
  };

  const handleSubmitForReviewPreparer = () => {
    if (
      userRoleId === Roles.preparer &&
      (status === STATUS_ID_MAPPING.IN_PROGRESS.text ||
        status === STATUS_ID_MAPPING.PREPARED.text)
    ) {
      handleInputFormSubmit(false);
      setShowSubmitReviewModal(true);
    }
  };

  const handleCompleteReview = () => {
    if (
      userRoleId === Roles.reviewer &&
      status === STATUS_ID_MAPPING.PREPARED.text
    ) {
      //first save the changes that might have been made on the current tab
      handleInputFormSubmit(false);

      //show the modal for the completing review
      setShowSubmitReviewModal(true);
    }
  };

  const handleConfirmSubmitForReview = () => {
    setShowSubmitReviewModal(false);
    if (
      userRoleId === Roles.preparer &&
      status === STATUS_ID_MAPPING.IN_PROGRESS.text
    ) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="input.form.submit.review.confirm.toaster.message" />
          ),
        })
      );
      dispatch(submitForReviewInputForm(planSubmissionId));
      setShowSubmitFormBtn(true);
      setSubmitFormBtnDisabled(true);
    } else if (
      userRoleId === Roles.reviewer &&
      status === STATUS_ID_MAPPING.PREPARED.text
    ) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="input.form.submit.review.confirm.reviewer.toaster.message" />
          ),
        })
      );
      dispatch(completeReviewInputForm(planSubmissionId));
    }
  };
  return (
    <div className={BLOCK}>
      <div className={`${BLOCK}__header`}>
        <span className={`${BLOCK}__header--title`}>
          <FormattedMessage
            id={`geography.inputs.main.title`}
            values={{
              countryName:
                inputFormDataState?.configurationData?.countryName ?? "___",
            }}
          />
        </span>
        <div className={`${BLOCK}__header--actions`}>
          {step !== 1 && (
            <div className={`${BLOCK}__header--status`}>
              <Toggle
                label={<FormattedMessage id={"form.toggle.message"} />}
                value={inputFormDataState.isHighlightEmpty}
                onChange={() => {
                  dispatch(
                    setHighlightEmptyInput(!inputFormDataState.isHighlightEmpty)
                  );
                }}
                disabled={
                  inputFormDataState.inputFormStatusId ===
                  STATUS_ID_MAPPING.ARCHIVED.id
                }
                color="green"
              />
            </div>
          )}
          <div className={`${BLOCK}__header--status`}>
            <span className={`${BLOCK}__header--status--icon`}>
              <Icon name={statusIcon} width={10} height={10} />
            </span>
            <span className={`${BLOCK}__header--status--text`}>{status}</span>
          </div>
          <div className={`${BLOCK}__header--save`}>
            <button
              className={classNames(`${BLOCK}__header--save--btn`, {
                [`${BLOCK}__header--save--btn--enabled`]:
                  submitConfiguration && !savingData,
                [`${BLOCK}__header--save--btn--disabled`]:
                  !submitConfiguration ||
                  savingData ||
                  inputFormDataState.inputFormStatusId ===
                    STATUS_ID_MAPPING.ARCHIVED.id,
              })}
              disabled={
                !submitConfiguration ||
                savingData ||
                inputFormDataState.inputFormStatusId ===
                  STATUS_ID_MAPPING.ARCHIVED.id
              }
              onClick={() => {
                if (submitConfiguration) {
                  handleInputFormSubmit();
                }
              }}
            >
              {step === 1 ? (
                !savingData ? (
                  <span className={`${BLOCK}__save-text`}>
                    <FormattedMessage id={`input-save-continue-btn-title`} />
                  </span>
                ) : (
                  <span className={`${BLOCK}__save-text`}>
                    <FormattedMessage id={`input-saving-btn-title`} />
                  </span>
                )
              ) : !savingData ? (
                <span className={`${BLOCK}__save-text`}>
                  <FormattedMessage id={`input-form-save-only-btn`} />
                </span>
              ) : (
                <span className={`${BLOCK}__save-text`}>
                  <FormattedMessage id={`input-saving-btn-title`} />
                </span>
              )}
              {savingData && (
                <Icon
                  name="loading"
                  width={20}
                  height={20}
                  data-test="loading"
                  className={classNames(`${BLOCK}__loading`, {
                    "loading-icon": savingData,
                  })}
                />
              )}
            </button>
          </div>
          <div className={`${BLOCK}__header--submit`}>
            {userRoleId === Roles.preparer &&
              !showSubmitFormBtn &&
              !showSubmittedBtn &&
              status === STATUS_ID_MAPPING.IN_PROGRESS.text && (
                <button
                  className={classNames(`${BLOCK}__header--submit--btn`, {
                    [`${BLOCK}__header--submit--btn--disabled`]:
                      submitForReviewBtnDisabled,
                    [`${BLOCK}__header--submit--btn--enabled`]:
                      !submitForReviewBtnDisabled,
                  })}
                  onClick={() => handleSubmitForReviewPreparer()}
                  disabled={submitForReviewBtnDisabled}
                >
                  <FormattedMessage id={`input-submit-btn-title`} />
                </button>
              )}
            {userRoleId === Roles.reviewer &&
              !showSubmitFormBtn &&
              !showSubmittedBtn &&
              (status === STATUS_ID_MAPPING.IN_PROGRESS.text ||
                status === STATUS_ID_MAPPING.PREPARED.text) && (
                <button
                  className={classNames(`${BLOCK}__header--submit--btn`, {
                    [`${BLOCK}__header--submit--btn--disabled`]:
                      submitConfirmReviewBtnDisabled,
                    [`${BLOCK}__header--submit--btn--enabled`]:
                      !submitConfirmReviewBtnDisabled,
                  })}
                  onClick={() => handleCompleteReview()}
                  disabled={submitForReviewBtnDisabled}
                >
                  <FormattedMessage id={`input.form.complete.review.btn`} />
                </button>
              )}
            {!showSubmitFormBtn && showSubmittedBtn && (
              <button
                className={classNames(`${BLOCK}__header--submit--btn`, {
                  [`${BLOCK}__header--submit--btn--disabled`]: true,
                })}
                disabled={true}
              >
                <FormattedMessage id={`input.form.submitted.title`} />
              </button>
            )}
          </div>
          {/* <div className={`${BLOCK}__header--export`}>
            <button
              className={classNames(`${BLOCK}__header--export--btn`, {
                [`${BLOCK}__header--export--btn--disabled`]: exportBtnDisabled,
                [`${BLOCK}__header--export--btn--enabled`]: !exportBtnDisabled,
              })}
              onClick={() => exportSeedModel()}
              disabled={exportBtnDisabled}
            >
              <Icon
                name={exportBtnDisabled ? "disabled-export" : "download-blue"}
                height={25}
                width={25}
              />
            </button>
          </div> */}
        </div>
      </div>
      <div className={`${BLOCK}__sidebar`}>
        <NavigationPanel
          BLOCK={BLOCK}
          stepSelected={step}
          setToggleNavigation={setToggleNavigation}
          toggleNavigation={toggleNavigation}
          revenueAndPLPercent={revenuePLTableProgress}
          yearlyUtilizationPercent={yearlyUtilizationTableProgress}
          headCountsPercent={headCountsTableProgress}
          planSubmissionId={planSubmissionId}
          enableAllInputForms={enableAllInputForms}
          totalFieldsRevPnL={totalRevPnLFields}
          totalFilledRevPnL={totalFilledRevPnL}
          totalYearlyUtilizationFields={totalYearlyUtilizationFields}
          totalFilledYearlyUtilization={totalFilledYearlyUtilization}
          totalFieldsHeadcounts={totalFieldsHeadcounts}
          totalFilledHeadcounts={totalFilledHeadcounts}
          changeTabs={changeTabs}
          setDestination={setDestination}
          totalPercentage={totalPercentage}
          setTotalPercentage={setTotalPercentage}
          locationName={locationName}
          totalFieldsConfig={totalFieldsConfigurations}
          totalFilledInConfig={totalFilledConfig}
          configurationPercent={configurationPercent}
          setStepSelectedRevenuePl={setStepSelectedRevenuePl}
          stepSelectedRevenuePl={stepSelectedRevenuePl}
          setStepSelectedHeadCount={setStepSelectedHeadCount}
          stepSelectedHeadCount={stepSelectedHeadCount}
          setStepSelectedTransformation={setStepSelectedTransformation}
          stepSelectedTransformation={stepSelectedTransformation}
          totalFieldsTransformations={totalFieldsTransformation}
          totalFilledTransformations={totalFilledTransformation}
          transformationPercent={transformationPercent}
        />
        {step === 1 && (
          <Configuration
            toggleNavigation={toggleNavigation}
            setSaved={setSaved}
            setConfigurationDirty={setConfigurationDirty}
          />
        )}
        {step === 2 && (
          <RevenueContextProvider>
            <RevenueAndPLTab
              toggleNavigation={toggleNavigation}
              setSaved={setSaved}
              savingData={savingData}
              setSavingData={setSavingData}
              revenueApiCall={revenuaApiCall}
              setRevenuaApiCall={setRevenuaApiCall}
              totalPercentage={totalPercentage ? totalPercentage : 0}
              revenuePLTableProgress={revenuePLTableProgress}
              revenueDirtyTables={revenueDirtyTables}
              setRevenueDirtyTables={setRevenueDirtyTables}
            />
          </RevenueContextProvider>
        )}

        {step === 3 && (
          <YearlyUtilizationContextProvider>
            <YearlyUtlizationTab
              toggleNavigation={toggleNavigation}
              setSaved={setSaved}
              savingData={savingData}
              setSavingData={setSavingData}
              yearlyUtilizationApiCall={yearlyUtilizationApiCall}
              setYearlyUtilizationApiCall={setYearlyUtilizationApiCall}
              totalPercentage={totalPercentage ? totalPercentage : 0}
              yearlyUtilizationTableProgress={yearlyUtilizationTableProgress}
              yearlyUtilizationDirtyTables={yearlyUtilizationDirtyTables}
              setYearlyUtilizationDirtyTables={setYearlyUtilizationDirtyTables}
            />
          </YearlyUtilizationContextProvider>
        )}

        {step === 4 && (
          <HeadCountsContextProvider>
            <UpdatedHeadcounts
              toggleNavigation={toggleNavigation}
              planSubmissionId={planSubmissionId}
              saved={saved}
              setSaved={setSaved}
              savingData={savingData}
              setSavingData={setSavingData}
              haedCountApiCall={headCountApiCall}
              setHeadCountApiCall={setHeadCountApiCall}
              headCountsTableProgress={headCountsTableProgress}
              totalPercentage={totalPercentage ? totalPercentage : 0}
              headCountsDirtyTables={headCountsDirtyTables}
              setHeadCountsDirtyTables={setHeadCountsDirtyTables}
            />
          </HeadCountsContextProvider>
        )}
        {step === 5 && (
          <TransformationAssumptionContextProvider>
            <LiberatedHoursContextProvider>
              <Transformations
                toggleNavigation={toggleNavigation}
                planSubmissionId={planSubmissionId}
                changeTabs={changeTabs}
                setDestination={setDestination}
                saved={saved}
                setSaved={setSaved}
                savingData={savingData}
                setSavingData={setSavingData}
                transformationAssumptionsApiCall={
                  transformationAssumptionsApiCall
                }
                setTransformationAssumptionsApiCall={
                  setTransformationAssumptionsApiCall
                }
                setTotalFilledTransformation={setTotalFilledTransformation}
                setTotalFieldsTransformation={setTotalFieldsTransformation}
                setTransformationPercent={setTransformationPercent}
                transformationPercent={transformationPercent}
                totalPercentage={totalPercentage ? totalPercentage : 0}
                transformationDirtyTables={transformationDirtyTables}
                setTransformationDirtyTables={setTransformationDirtyTables}
                liberatedHoursApiCall={liberatedHoursApiCall}
                setLiberatedHoursApiCall={setLiberatedHoursApiCall}
                liberatedHoursDirtyTables={liberatedHoursDirtyTables}
                setLiberatedHoursDirtyTables={setLiberatedHoursDirtyTables}
                setStepSelectedTransformation={setStepSelectedTransformation}
              />
            </LiberatedHoursContextProvider>
          </TransformationAssumptionContextProvider>
        )}
      </div>
      {showSubmitReviewModal && (
        <SubmitReviewPopUp
          showModal={setShowSubmitReviewModal}
          confirmSubmission={handleConfirmSubmitForReview}
          userRoleId={userRoleId}
          preparerEmailId={preparerEmail}
          reviewerEmailId={reviewerEmail}
          mfAdminEmailId={mfAdminEmail}
        />
      )}
      {showWarningPopUp && (
        <NotSavedPopUp
          showModal={setShowWarningPopUp}
          confirmChangeTabs={changeTabs}
          destination={destination}
        />
      )}
    </div>
  );
};

export default Form;
