import { useState, memo, useRef, createContext, useCallback } from "react";
import Table from "../shared/table/table";
import {
  productSegmentsMapping,
  overHeadTypedIdOrderList,
  overHeadTypeIdList,
  REVENUE_AND_PL_CANNOT_BE_ZERO,
} from "utils/constants";
import Icon from "../shared/icon/icon";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useAreaSelection } from "utils/area-selection";
import {
  inputValueValidation,
  handleEnterTable,
  setDirtyTables,
} from "utils/useInputForm";
import { validateInputValueLength } from "services/inputForm/inputFormSlice";

const SelectionContext = createContext<DOMRect | null>(null);

const PandL_CostTable = ({
  currency,
  setSaved,
  tableId,
  showModal,
  subHeadingBlock,
  subTitle,
  showCurrencyHeader,
  baseYear,
  showUpdateInputsPopup,
  revenueDirtyTables,
  setRevenueDirtyTables,
  tableData,
  compareTableData,
  setTableData,
  revenueTableIdList,
  revenueData,
}: any) => {
  const BLOCK = "headcountsTable";
  const [prevValue, setPrevValue] = useState<any>("");
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });

  const getTableHeaders = () => {
    let dynamicTableHeaders: any =
      tableData &&
      tableData?.length > 0 &&
      Object.keys(tableData[0] || {})
        .filter(Number)
        .map((item) => {
          return {
            text: `FY${item}`,
            isFormattedText: false,
          };
        });

    const tableHeaders = dynamicTableHeaders &&
      dynamicTableHeaders.length > 0 && [
        {
          text: "P&L Costs " + `(${currency} 'M)`,
          isFormattedText: false,
        },
        ...dynamicTableHeaders,
      ];

    return tableHeaders;
  };

  const handleOnChange = (
    inputVal: any,
    segmentId: any,
    columnId: any,
    overHeadTypeId: number,
    roundOff: boolean = false
  ) => {
    setPrevValue(inputVal);
    let newVal = inputValueValidation(inputVal, prevValue, setPrevValue);
    let value: string;
    if (roundOff) {
      value = validateInputValueLength(newVal, prevValue, roundOff);
    } else {
      value = validateInputValueLength(newVal, prevValue);
    }
    let newTableValues =
      tableData &&
      tableData?.length > 0 &&
      tableData.map((item: any) => {
        if (
          overHeadTypeId == item.overheadTypeId &&
          item.productSegmentId === segmentId
        ) {
          if (
            item.overheadTypeId == overHeadTypedIdOrderList[0] &&
            value == "0" &&
            roundOff
          ) {
            return {
              ...item,
              [columnId]: null,
              error: true,
            };
          } else if (
            item.overheadTypeId == overHeadTypedIdOrderList[0] &&
            value !== "0" &&
            roundOff
          ) {
            return {
              ...item,
              [columnId]: value,
              error: false,
            };
          } else {
            return {
              ...item,
              [columnId]: value,
            };
          }
        }
        return item;
      });
    setTableData(newTableValues);
    setDirtyTables(
      compareTableData,
      newTableValues,
      revenueDirtyTables,
      setRevenueDirtyTables,
      tableId
    );
  };

  const FocusChage = (
    inputValue: any,
    segmentId: number,
    id: any,
    overHeadTypeId: number
  ) => {
    setSaved(false);
    handleOnChange(inputValue, segmentId, id, overHeadTypeId, true);
  };

  const getRowItems = () => {
    if (tableData && tableData?.length > 0) {
      const sortedTableData: any = [];
      overHeadTypedIdOrderList.map((id: any) => {
        tableData.map((item: any) => {
          if (item.overheadTypeId === id) {
            sortedTableData.push(item);
          }
        });
      });
      return (
        sortedTableData &&
        sortedTableData?.length > 0 &&
        sortedTableData.map((item: any, index: number) => {
          let dynamicRows = Object.keys(item || {})
            .filter(Number)
            .map((id) => {
              let cellObject = {
                customIndex: item?.productSegmentId + "#" + id,
                textInput: true,
                onBlur: (e: any) => {
                  FocusChage(
                    e.target.value,
                    item.productSegmentId,
                    id,
                    item.overheadTypeId
                  );
                },
                onchangeHandler: (e: any) => {
                  let columnId = id;
                  handleOnChange(
                    e.target.value,
                    item.productSegmentId,
                    columnId,
                    item.overheadTypeId
                  );
                },
                onHandleEnter: (evt: any, keyIdentifier: any) => {
                  setPrevValue(evt?.target?.value);
                  handleEnterTable(
                    evt,
                    keyIdentifier,
                    tableData,
                    tableId,
                    revenueTableIdList,
                    baseYear,
                    revenueData
                  );
                },
                placholder: "-",
                inputValue: item[id],
                allowNegValues:
                  item.overheadTypeId === overHeadTypedIdOrderList[2]
                    ? true
                    : false,
                inputDisable:
                  Object.keys(item?.disabled || {}).length > 0 &&
                  item?.disabled[id]
                    ? true
                    : false,
              };
              return item.overheadTypeId === overHeadTypedIdOrderList[0] &&
                !item?.disabled[id]
                ? {
                    ...cellObject,
                    error: item.error,
                    errorText: REVENUE_AND_PL_CANNOT_BE_ZERO,
                  }
                : cellObject;
            });
          let tableRows = {
            id: item.overheadTypeId,
            key: `${item.overheadTypeId}-${index}`,
            className: `${BLOCK}__row`,
            columnItems: [
              {
                text: item.productSegmentId
                  ? productSegmentsMapping[item.productSegmentId]
                  : overHeadTypeIdList[item.overheadTypeId],
              },
              ...dynamicRows,
            ],
          };
          return tableRows;
        })
      );
    }
  };

  return (
    <>
      <div data-test="pAndLCostTable" style={{ marginTop: 30 }}>
        <div className={`${subHeadingBlock}__sub-header`}>
          <div className={`${subHeadingBlock}__sub-header--title`}>
            {tableId === 1 ? (
              subTitle
            ) : (
              <>
                <FormattedMessage id={subTitle} />{" "}
                {currency && showCurrencyHeader ? `(${currency} 'M)` : ""}
              </>
            )}
          </div>
        </div>
        <SelectionContext.Provider value={selection}>
          <div ref={selectContainerRef} id={tableId}>
            {tableData && tableData?.length === 0 ? (
              <div className={`${subHeadingBlock}__spinner-container`}>
                <Icon
                  name={"loading"}
                  width={50}
                  height={50}
                  className={`${subHeadingBlock}--loading`}
                />
              </div>
            ) : (
              <Table
                className={`${BLOCK}--innerWrapper`}
                headerItems={getTableHeaders()}
                rowItems={getRowItems()}
                innerClassName={BLOCK}
                selection={selection}
                tableId={tableId}
                subTitle={subTitle}
              />
            )}
          </div>
        </SelectionContext.Provider>
      </div>
    </>
  );
};

export default memo(PandL_CostTable);
