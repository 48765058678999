import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "./visual.dashboard.api";
import {
  VISUAL_DASHBOARD_OPTIONS,
  DashboardTables,
  DeliveryCenter,
  OverHeadTypeId,
} from "./visual.dashboard.models";

const initialState = {
  loading: [],
  currencyList: [],
  currency: [],
  consolidationGeographies: [],
  consolidationGeographiesSelected: {
    parentCheckBoxSelected: [],
    subParentCheckBoxSelected: [],
    childCheckBoxSelected: [],
    childSubConsolidationGeographyCheckBoxSelected: [],
  },
  applyMetrics: false,
  consolidateNetRevnueGraphData: null,
  gmBridgeGraphData: null,
  netRevenueBridgesGraphData: null,
  netFeePerHrGraphData: null,
  netCostPerHrGraphData: null,
  netRevenueData: null,
  pricingImprovementData: null,
  totalTechnicalStaffCostData: null,
  technicalStaffCostOnshoreData: null,
  technicalStaffCostUSIData: null,
  technicalStaffCostRADCData: null,
  technicalStaffCostLocalDCData: null,
  technicalStaffCostLocalCOEData: null,
  netProfitData: null,
  netMarginData: null,
  grossProfitData: null,
  costPerHourData: null,
  totalHoursData: null,
  netFeePerHourData: null,
  totalHeadcountCohortData: null,
  promotedHeadcountsData: null,
  totalAttritionLossData: null,
  consolidationTransformationAssumptionsData: null,
  netRevenueAfterPricingData: null,
  accumExitsData: null,
  grossMargin: null,
  onshoreCohort: null,
  usiCohort: null,
  radcCohort: null,
  localDcCohort: null,
  localCOECohort: null,
  totalHeadCountGrade: null,
  onshoreGrade: null,
  usiGrade: null,
  radcGrade: null,
  localDCGrade: null,
  localCOEGrade: null,
  overHeadsTotalData: null,
  overHeadsFTEData: null,
  overHeadsRevenueData: null,
  overHeadOtherData: null,
  overHeadsAllocationsData: null,
  gmBridgeCohort: [],
  gmBridgeStartYear: [],
  gmBridgeEndYear: [],
  gmBridgeToggle: false,
  refreshVisualDashboard: false,
};

const visualDashBoardSlice = createSlice({
  name: "visualDashboard",
  initialState,
  reducers: {
    setVisualDashboardCurrency(state: any, action) {
      state.currency = action.payload;
    },
    setConsolidationGeographies(state: any, action) {
      state.consolidationGeographiesSelected = action.payload;
    },
    applyMetrics(state: any) {
      state.applyMetrics = true;
    },
    MakeGraphDataEmpty(state: any) {
      state.consolidateNetRevnueGraphData = null;
      state.netRevenueBridgesGraphData = null;
      state.gmBridgeGraphData = null;
      state.netFeePerHrGraphData = null;
      state.netCostPerHrGraphData = null;
    },
    setGMBridgeCohort(state: any, action) {
      state.gmBridgeCohort = action.payload;
    },
    setGMBridgeToggle(state: any, action) {
      state.gmBridgeToggle = action.payload;
    },
    setGMBridgeStartYear(state: any, action) {
      state.gmBridgeStartYear = action.payload;
    },
    setGMBridgeEndYear(state: any, action) {
      state.gmBridgeEndYear = action.payload;
    },
    setRefreshVisualDashboard(state: any, action) {
      state.refreshVisualDashboard = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadNetRevenue.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.NET_REVENUE_LOADING);
      })
      .addCase(loadNetRevenue.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.NET_REVENUE_LOADING
        );
        state.netRevenueData = action.payload;
      })
      .addCase(loadPricingImprovements.pending, (state: any, action: any) => {
        state.loading.push(
          VISUAL_DASHBOARD_OPTIONS.PRICING_IMPROVEMENTS_LOADING
        );
      })
      .addCase(loadPricingImprovements.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.PRICING_IMPROVEMENTS_LOADING
        );
        state.pricingImprovementData = action.payload;
      })
      .addCase(
        loadTotalTechnicalStaffCost.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.TOTAL_TECHNICAL_STAFF_COST_LOADING
          );
        }
      )
      .addCase(
        loadTotalTechnicalStaffCost.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.TOTAL_TECHNICAL_STAFF_COST_LOADING
          );
          state.totalTechnicalStaffCostData = action.payload;
        }
      )
      .addCase(
        getTechnicalStaffCostOnshoreData.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_ONSHORE_LOADING
          );
        }
      )
      .addCase(
        getTechnicalStaffCostOnshoreData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_ONSHORE_LOADING
          );
          state.technicalStaffCostOnshoreData = action.payload;
        }
      )
      .addCase(
        getTechnicalStaffCostUSIData.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_USI_LOADING
          );
        }
      )
      .addCase(
        getTechnicalStaffCostUSIData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_USI_LOADING
          );
          state.technicalStaffCostUSIData = action.payload;
        }
      )
      .addCase(
        getTechnicalStaffCostRADCData.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_RADC_LOADING
          );
        }
      )
      .addCase(
        getTechnicalStaffCostRADCData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_RADC_LOADING
          );
          state.technicalStaffCostRADCData = action.payload;
        }
      )
      .addCase(
        getTechnicalStaffCostLocalDCData.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_LOCAL_DC_LOADING
          );
        }
      )
      .addCase(
        getTechnicalStaffCostLocalDCData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_LOCAL_DC_LOADING
          );
          state.technicalStaffCostLocalDCData = action.payload;
        }
      )
      .addCase(
        getTechnicalStaffCostLocalCOEData.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_LOCAL_COE_LOADING
          );
        }
      )
      .addCase(
        getTechnicalStaffCostLocalCOEData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.TECHNICAL_STAFF_COST_LOCAL_COE_LOADING
          );
          state.technicalStaffCostLocalCOEData = action.payload;
        }
      )
      .addCase(loadNetProfit.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.NET_PROFIT_LOADING);
      })
      .addCase(loadNetProfit.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.NET_PROFIT_LOADING
        );
        state.netProfitData = action.payload;
      })
      .addCase(loadNetMargin.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.NET_MARGIN_LOADING);
      })
      .addCase(loadNetMargin.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.NET_MARGIN_LOADING
        );
        state.netMarginData = action.payload;
      })

      .addCase(loadGrossProfit.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.GROSS_PROFIT_LOADING);
      })
      .addCase(loadGrossProfit.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.GROSS_PROFIT_LOADING
        );
        state.grossProfitData = action.payload;
      })
      .addCase(loadCostPerHour.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.COST_PER_HOUR_LOADING);
      })
      .addCase(loadCostPerHour.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.COST_PER_HOUR_LOADING
        );
        state.costPerHourData = action.payload;
      })
      .addCase(loadTotalHours.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.TOTAL_HOURS_LOADING);
      })
      .addCase(loadTotalHours.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.TOTAL_HOURS_LOADING
        );
        state.totalHoursData = action.payload;
      })

      .addCase(loadNetFeePerHour.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.NET_FEE_PER_HOUR_LOADING);
      })
      .addCase(loadNetFeePerHour.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.NET_FEE_PER_HOUR_LOADING
        );
        state.netFeePerHourData = action.payload;
      })
      .addCase(loadTotalHeadcountCohorts.pending, (state: any, action: any) => {
        state.loading.push(
          VISUAL_DASHBOARD_OPTIONS.TOTAL_HEADCOUNTS_COHORTS_LOADING
        );
      })
      .addCase(
        loadTotalHeadcountCohorts.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.TOTAL_HEADCOUNTS_COHORTS_LOADING
          );
          state.totalHeadcountCohortData = action.payload;
        }
      )
      .addCase(loadPromotedHeadCounts.pending, (state: any, action: any) => {
        state.loading.push(
          VISUAL_DASHBOARD_OPTIONS.PROMOTED_HEADCOUNTS_LOADING
        );
      })
      .addCase(loadPromotedHeadCounts.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.PROMOTED_HEADCOUNTS_LOADING
        );
        state.promotedHeadcountsData = action.payload;
      })
      .addCase(loadTotalAttritionLoss.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.ATTRITION_LOSS_LOADING);
      })
      .addCase(loadTotalAttritionLoss.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.ATTRITION_LOSS_LOADING
        );
        state.totalAttritionLossData = action.payload;
      })
      .addCase(
        loadConsolidationTrasnformationAssumptions.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.CONSOLIDATION_TRANSFORMATION_ASSUMPTION_LOADING
          );
        }
      )
      .addCase(
        loadConsolidationTrasnformationAssumptions.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.CONSOLIDATION_TRANSFORMATION_ASSUMPTION_LOADING
          );
          state.consolidationTransformationAssumptionsData = action.payload;
        }
      )
      .addCase(loadCurrency.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.CURRENCY_LOADING);
      })
      .addCase(loadCurrency.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.CURRENCY_LOADING
        );
        state.currencyList = action.payload;
      })
      .addCase(
        loadConsolidationGeographies.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.CONSOLIDATION_GEOGRAPHIES_LOADING
          );
        }
      )
      .addCase(
        loadConsolidationGeographies.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.CONSOLIDATION_GEOGRAPHIES_LOADING
          );
          state.consolidationGeographies = action.payload;
        }
      )
      .addCase(
        getConsolidatedNetRevenueGraphsData.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.NETREVENUE_BRIDGES_LOADING
          );
        }
      )
      .addCase(
        getConsolidatedNetRevenueGraphsData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.NETREVENUE_BRIDGES_LOADING
          );
          state.consolidateNetRevnueGraphData = action.payload;
        }
      )
      .addCase(getGMBridgeGraphsData.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.GM_BRIDGES_LOADING);
      })
      .addCase(getGMBridgeGraphsData.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.GM_BRIDGES_LOADING
        );
        state.gmBridgeGraphData = action.payload;
      })
      .addCase(
        getNetrevenueBridgeGraphsData.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.NETREVENUE_BRIDGES_LOADING
          );
        }
      )
      .addCase(
        getNetrevenueBridgeGraphsData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.NETREVENUE_BRIDGES_LOADING
          );
          state.netRevenueBridgesGraphData = action.payload;
        }
      )
      .addCase(
        getNetFeePerHourGraphsData.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.NETREVENUE_BRIDGES_LOADING
          );
        }
      )
      .addCase(
        getNetFeePerHourGraphsData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.NETREVENUE_BRIDGES_LOADING
          );
          state.netFeePerHrGraphData = action.payload;
        }
      )
      .addCase(
        getNetCostPerHourGraphsData.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.NETREVENUE_BRIDGES_LOADING
          );
        }
      )
      .addCase(
        getNetCostPerHourGraphsData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.NETREVENUE_BRIDGES_LOADING
          );
          state.netCostPerHrGraphData = action.payload;
        }
      )
      .addCase(
        loadNetRevenueAfterPricing.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.NET_REVENUE_AFTER_PRICING_LOADING
          );
        }
      )
      .addCase(
        loadNetRevenueAfterPricing.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.NET_REVENUE_AFTER_PRICING_LOADING
          );
          state.netRevenueAfterPricingData = action.payload;
        }
      )
      .addCase(loadAccumExits.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.ACCUM_EXITS_LOADING);
      })
      .addCase(loadAccumExits.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.ACCUM_EXITS_LOADING
        );
        state.accumExitsData = action.payload;
      })
      .addCase(loadGrossMargin.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.GROSS_MARGIN_LOADING);
      })
      .addCase(loadGrossMargin.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.GROSS_MARGIN_LOADING
        );
        state.grossMargin = action.payload;
      })
      .addCase(
        getHeadCountByCohortsOnshoreData.pending,
        (state: any, action: any) => {
          state.loading.push(VISUAL_DASHBOARD_OPTIONS.ONSHORE_COHORT_LOADING);
        }
      )
      .addCase(
        getHeadCountByCohortsOnshoreData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.ONSHORE_COHORT_LOADING
          );
          state.onshoreCohort = action.payload;
        }
      )
      .addCase(
        getHeadCountByCohortsUsiData.pending,
        (state: any, action: any) => {
          state.loading.push(VISUAL_DASHBOARD_OPTIONS.USI_COHORT_LOADING);
        }
      )
      .addCase(
        getHeadCountByCohortsUsiData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.USI_COHORT_LOADING
          );
          state.usiCohort = action.payload;
        }
      )
      .addCase(
        getHeadCountByCohortsRadcData.pending,
        (state: any, action: any) => {
          state.loading.push(VISUAL_DASHBOARD_OPTIONS.RADC_COHORT_LOADING);
        }
      )
      .addCase(
        getHeadCountByCohortsRadcData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.RADC_COHORT_LOADING
          );
          state.radcCohort = action.payload;
        }
      )
      .addCase(
        getHeadCountByCohortsLocalDCData.pending,
        (state: any, action: any) => {
          state.loading.push(VISUAL_DASHBOARD_OPTIONS.LOCAL_DC_COHORT_LOADING);
        }
      )
      .addCase(
        getHeadCountByCohortsLocalDCData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.LOCAL_DC_COHORT_LOADING
          );
          state.localDcCohort = action.payload;
        }
      )
      .addCase(
        getHeadCountByCohortsLocalCOEData.pending,
        (state: any, action: any) => {
          state.loading.push(VISUAL_DASHBOARD_OPTIONS.LOCAL_COE_COHORT_LOADING);
        }
      )
      .addCase(
        getHeadCountByCohortsLocalCOEData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.LOCAL_COE_COHORT_LOADING
          );
          state.localCOECohort = action.payload;
        }
      )
      .addCase(
        getHeadCountByGradesOnshoreData.pending,
        (state: any, action: any) => {
          state.loading.push(VISUAL_DASHBOARD_OPTIONS.ONSHORE_GRADE_LOADING);
        }
      )
      .addCase(
        getHeadCountByGradesOnshoreData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.ONSHORE_GRADE_LOADING
          );
          state.onshoreGrade = action.payload;
        }
      )
      .addCase(
        getHeadCountByGradesUsiData.pending,
        (state: any, action: any) => {
          state.loading.push(VISUAL_DASHBOARD_OPTIONS.USI_GRADE_LOADING);
        }
      )
      .addCase(
        getHeadCountByGradesUsiData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.USI_GRADE_LOADING
          );
          state.usiGrade = action.payload;
        }
      )
      .addCase(
        getHeadCountByGradesRadcData.pending,
        (state: any, action: any) => {
          state.loading.push(VISUAL_DASHBOARD_OPTIONS.RADC_GRADE_LOADING);
        }
      )
      .addCase(
        getHeadCountByGradesRadcData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.RADC_GRADE_LOADING
          );
          state.radcGrade = action.payload;
        }
      )
      .addCase(
        getHeadCountByGradesLocalDCData.pending,
        (state: any, action: any) => {
          state.loading.push(VISUAL_DASHBOARD_OPTIONS.LOCAL_DC_GRADE_LOADING);
        }
      )
      .addCase(
        getHeadCountByGradesLocalDCData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.LOCAL_DC_GRADE_LOADING
          );
          state.localDCGrade = action.payload;
        }
      )
      .addCase(
        getHeadCountByGradesLocalCOEData.pending,
        (state: any, action: any) => {
          state.loading.push(VISUAL_DASHBOARD_OPTIONS.LOCAL_COE_GRADE_LOADING);
        }
      )
      .addCase(
        getHeadCountByGradesLocalCOEData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.LOCAL_COE_GRADE_LOADING
          );
          state.localCOEGrade = action.payload;
        }
      )
      .addCase(
        getOverHeadsDetailsTotalData.pending,
        (state: any, action: any) => {
          state.loading.push(VISUAL_DASHBOARD_OPTIONS.OVERHEADS_TOTAL_LOADING);
        }
      )
      .addCase(
        getOverHeadsDetailsTotalData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.OVERHEADS_TOTAL_LOADING
          );
          state.overHeadsTotalData = action.payload;
        }
      )
      .addCase(getOverHeadFTEDrivenData.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.OVERHEADS_FTE_LOADING);
      })
      .addCase(
        getOverHeadFTEDrivenData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.OVERHEADS_FTE_LOADING
          );
          state.overHeadsFTEData = action.payload;
        }
      )
      .addCase(getOverHeadRevenueData.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.OVERHEADS_REVENUE_LOADING);
      })
      .addCase(getOverHeadRevenueData.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.OVERHEADS_REVENUE_LOADING
        );
        state.overHeadsRevenueData = action.payload;
      })
      .addCase(getOverHeadOtherData.pending, (state: any, action: any) => {
        state.loading.push(VISUAL_DASHBOARD_OPTIONS.OVERHEADS_OTHER_LOADING);
      })
      .addCase(getOverHeadOtherData.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(
          state,
          VISUAL_DASHBOARD_OPTIONS.OVERHEADS_OTHER_LOADING
        );
        state.overHeadOtherData = action.payload;
      })
      .addCase(
        getOverHeadAllocationsData.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.OVERHEADS_ALLOCATIONS_LOADING
          );
        }
      )
      .addCase(
        getOverHeadAllocationsData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.OVERHEADS_ALLOCATIONS_LOADING
          );
          state.overHeadsAllocationsData = action.payload;
        }
      )
      .addCase(
        getTotalHeadCountByGradesData.pending,
        (state: any, action: any) => {
          state.loading.push(
            VISUAL_DASHBOARD_OPTIONS.TOTAL_HEADCOUNTS_GRADE_LOADING
          );
        }
      )
      .addCase(
        getTotalHeadCountByGradesData.fulfilled,
        (state: any, action: any) => {
          state.loading = removeLoaders(
            state,
            VISUAL_DASHBOARD_OPTIONS.TOTAL_HEADCOUNTS_GRADE_LOADING
          );
          state.totalHeadCountGrade = action.payload;
        }
      );
  },
});

const removeLoaders = (state: any, loader: any) => {
  return state.loading.filter((filter: any) => {
    return filter !== loader;
  });
};

export const loadNetRevenue = createAsyncThunk(
  "visualDashboard/fetchNetRevenueData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.NET_REVENUE,
      DashboardTables.PROFIT_AND_LOSS_SECTION
    );
  }
);

export const loadNetRevenueAfterPricing = createAsyncThunk(
  "visualDashboard/fetchNetRevenueAfterPricingData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.NET_REVENUE_AFTER_PRICNG,
      DashboardTables.PROFIT_AND_LOSS_SECTION
    );
  }
);

export const loadAccumExits = createAsyncThunk(
  "visualDashboard/fetchAccumExitsData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.ACCUM_EXITS,
      DashboardTables.PROFIT_AND_LOSS_SECTION
    );
  }
);

export const loadPricingImprovements = createAsyncThunk(
  "visualDashboard/fetchPriceImprovementsData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.PRICING_IMPROVEMENTS,
      DashboardTables.PROFIT_AND_LOSS_SECTION
    );
  }
);

export const loadTotalTechnicalStaffCost = createAsyncThunk(
  "visualDashboard/fetchTotalTechnicalStaffCostData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.TOTAL_TECHNICAL_STAFF_COST,
      DashboardTables.PROFIT_AND_LOSS_SECTION
    );
  }
);
export const getTechnicalStaffCostOnshoreData = createAsyncThunk(
  "visualDashboard/fetchTechnicalStaffCostOnshoreData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.TECHNICAL_STAFF_COST_DELIVERY_CENTERS,
      DashboardTables.PROFIT_AND_LOSS_SECTION,
      DeliveryCenter.ONSHORE
    );
  }
);
export const getTechnicalStaffCostUSIData = createAsyncThunk(
  "visualDashboard/fetchTechnicalStaffCostUSIData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.TECHNICAL_STAFF_COST_DELIVERY_CENTERS,
      DashboardTables.PROFIT_AND_LOSS_SECTION,
      DeliveryCenter.USI
    );
  }
);
export const getTechnicalStaffCostRADCData = createAsyncThunk(
  "visualDashboard/fetchTechnicalStaffCostRADCData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.TECHNICAL_STAFF_COST_DELIVERY_CENTERS,
      DashboardTables.PROFIT_AND_LOSS_SECTION,
      DeliveryCenter.RADC
    );
  }
);
export const getTechnicalStaffCostLocalDCData = createAsyncThunk(
  "visualDashboard/fetchTechnicalStaffCostLocalDCData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.TECHNICAL_STAFF_COST_DELIVERY_CENTERS,
      DashboardTables.PROFIT_AND_LOSS_SECTION,
      DeliveryCenter.LOCAL_DC
    );
  }
);
export const getTechnicalStaffCostLocalCOEData = createAsyncThunk(
  "visualDashboard/fetchTechnicalStaffCostLocalCOEData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.TECHNICAL_STAFF_COST_DELIVERY_CENTERS,
      DashboardTables.PROFIT_AND_LOSS_SECTION,
      DeliveryCenter.LOCAL_COE
    );
  }
);

export const loadNetProfit = createAsyncThunk(
  "visualDashboard/fetchNetProfitData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.NET_PROFIT,
      DashboardTables.PROFIT_AND_LOSS_SECTION
    );
  }
);
export const loadNetMargin = createAsyncThunk(
  "visualDashboard/fetchNetMarginData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.NET_MARGIN,
      DashboardTables.PROFIT_AND_LOSS_SECTION
    );
  }
);

export const loadGrossProfit = createAsyncThunk(
  "visualDashboard/fetchGrossProfitData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.GROSS_PROFIT,
      DashboardTables.PROFIT_AND_LOSS_SECTION
    );
  }
);
export const loadCostPerHour = createAsyncThunk(
  "visualDashboard/fetchCostPerHourData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.COST_PER_HOUR,
      DashboardTables.GROSS_MARGIN_SECTION
    );
  }
);
export const loadTotalHours = createAsyncThunk(
  "visualDashboard/fetchTotalHoursData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.TOTAL_HOURS,
      DashboardTables.GROSS_MARGIN_SECTION
    );
  }
);

export const loadNetFeePerHour = createAsyncThunk(
  "visualDashboard/fetchNetFeePerHourData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.NET_FEE_PER_HOUR,
      DashboardTables.GROSS_MARGIN_SECTION
    );
  }
);

export const loadGrossMargin = createAsyncThunk(
  "visualDashboard/fetchGrossMarginData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.GROSS_MARGIN,
      DashboardTables.PROFIT_AND_LOSS_SECTION
    );
  }
);
export const loadTotalHeadcountCohorts = createAsyncThunk(
  "visualDashboard/fetchTotalHeadcountCohortsData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.TOTAL_HEADCOUNTS_COHORTS,
      DashboardTables.HEADCOUNT_SECTION
    );
  }
);
export const loadPromotedHeadCounts = createAsyncThunk(
  "visualDashboard/fetchPromotedHeadcountData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.PROMOTED_HEADCOUNTS,
      DashboardTables.HEADCOUNT_SECTION
    );
  }
);
export const loadTotalAttritionLoss = createAsyncThunk(
  "visualDashboard/fetchTotalAttritionLossData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.ATTRITION_LOSS,
      DashboardTables.HEADCOUNT_SECTION
    );
  }
);
export const loadConsolidationTrasnformationAssumptions = createAsyncThunk(
  "visualDashboard/fetchConsolidationTrasnformationAssumptionsData",
  async (data: any) => {
    try {
      const { countryCodes, currencyCode, baseYear } = data;
      const res = await api.getConsolidationTransformationAssumptionsData(
        countryCodes,
        currencyCode,
        baseYear
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const loadCurrency = createAsyncThunk(
  "visualDashboard/fetchCurrencyData",
  async () => {
    try {
      const res = await api.getCurrency();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const loadConsolidationGeographies = createAsyncThunk(
  "visualDashboard/fetchConsolidationGeographiesData",
  async () => {
    try {
      const res = await api.getConsolidationGeographies();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getConsolidatedNetRevenueGraphsData = createAsyncThunk(
  "visualDashboard/fetchConsolidatedNetRevenueGraphData",
  async (data: any) => {
    const { countryCodes, currencyCode, baseYear } = data;

    try {
      const res = await api.getConsolidatedNetRevenueData(
        countryCodes,
        currencyCode,
        baseYear
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getNetrevenueBridgeGraphsData = createAsyncThunk(
  "visualDashboard/fetchNetrevenueBridgeGraphsData",
  async (data: any) => {
    const { countryCodes, currencyCode, baseYear } = data;

    try {
      const res = await api.getNetRevenueBridgesData(
        countryCodes,
        currencyCode,
        baseYear
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getNetFeePerHourGraphsData = createAsyncThunk(
  "visualDashboard/fetchNetFeePerHourGraphsData",
  async (data: any) => {
    const { countryCodes, currencyCode, baseYear } = data;

    try {
      const res = await api.getNetFeePerHourData(
        countryCodes,
        currencyCode,
        baseYear
      );
      const resObj =
        res?.data &&
        Object.keys(res?.data).length &&
        res.data.subTotalGrossMargin;
      return resObj;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getNetCostPerHourGraphsData = createAsyncThunk(
  "visualDashboard/fetchNetCostPerHourGraphsData",
  async (data: any) => {
    const { countryCodes, currencyCode, baseYear } = data;

    try {
      const res = await api.getCostPerHourData(
        countryCodes,
        currencyCode,
        baseYear
      );
      const resObj =
        res?.data &&
        Object.keys(res?.data).length &&
        res.data.subTotalGrossMargin;
      return resObj;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getGMBridgeGraphsData = createAsyncThunk(
  "visualDashboard/fetchGMBridgeGraphsData",
  async (data: any) => {
    const { countryCodes, currencyCode, baseYear, startYear, endYear, cohort } =
      data;

    try {
      const res = await api.getGMBridgeData(
        countryCodes,
        currencyCode,
        baseYear,
        startYear,
        endYear,
        cohort
      );
      const resObj = res?.data && Object.keys(res?.data).length && res.data;
      return resObj;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getHeadCountByCohortsOnshoreData = createAsyncThunk(
  "visualDashboard/fetchHeadCountByCohortsOnshoreData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.HEAD_COUNT_BY_COHORTS,
      DashboardTables.HEADCOUNT_SECTION,
      DeliveryCenter.ONSHORE
    );
  }
);

export const getHeadCountByCohortsUsiData = createAsyncThunk(
  "visualDashboard/fetchHeadCountByCohortsUsiData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.HEAD_COUNT_BY_COHORTS,
      DashboardTables.HEADCOUNT_SECTION,
      DeliveryCenter.USI
    );
  }
);
export const getHeadCountByCohortsRadcData = createAsyncThunk(
  "visualDashboard/fetchHeadCountByCohortsRadcData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.HEAD_COUNT_BY_COHORTS,
      DashboardTables.HEADCOUNT_SECTION,
      DeliveryCenter.RADC
    );
  }
);
export const getHeadCountByCohortsLocalDCData = createAsyncThunk(
  "visualDashboard/fetchHeadCountByCohortsLocalDCData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.HEAD_COUNT_BY_COHORTS,
      DashboardTables.HEADCOUNT_SECTION,
      DeliveryCenter.LOCAL_DC
    );
  }
);
export const getHeadCountByCohortsLocalCOEData = createAsyncThunk(
  "visualDashboard/fetchgetHeadCountByCohortsLocalCOEData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.HEAD_COUNT_BY_COHORTS,
      DashboardTables.HEADCOUNT_SECTION,
      DeliveryCenter.LOCAL_COE
    );
  }
);

export const getTotalHeadCountByGradesData = createAsyncThunk(
  "visualDashboard/fetchTotalHeadCountByGradesData",
  async (data: any) => {
    return commonGetTableData(
      data,
      DashboardTables.TOTAL_HEADCOUNTS_GRADES,
      DashboardTables.HEADCOUNT_SECTION
    );
  }
);

export const getHeadCountByGradesOnshoreData = createAsyncThunk(
  "visualDashboard/fetchHeadCountByGradesOnshoreData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.HEAD_COUNT_BY_GRADES,
      DashboardTables.HEADCOUNT_SECTION,
      DeliveryCenter.ONSHORE
    );
  }
);
export const getHeadCountByGradesUsiData = createAsyncThunk(
  "visualDashboard/fetchHeadCountByGradesUsiData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.HEAD_COUNT_BY_GRADES,
      DashboardTables.HEADCOUNT_SECTION,
      DeliveryCenter.USI
    );
  }
);
export const getHeadCountByGradesRadcData = createAsyncThunk(
  "visualDashboard/fetchHeadCountByGradesRadcData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.HEAD_COUNT_BY_GRADES,
      DashboardTables.HEADCOUNT_SECTION,
      DeliveryCenter.RADC
    );
  }
);
export const getHeadCountByGradesLocalDCData = createAsyncThunk(
  "visualDashboard/fetchHeadCountByGradesLocalDCData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.HEAD_COUNT_BY_GRADES,
      DashboardTables.HEADCOUNT_SECTION,
      DeliveryCenter.LOCAL_DC
    );
  }
);
export const getHeadCountByGradesLocalCOEData = createAsyncThunk(
  "visualDashboard/fetchHeadCountByGradesLocalCOEData",
  async (data: any) => {
    return commonSectionsTableDataByDeliveryCenterId(
      data,
      DashboardTables.HEAD_COUNT_BY_GRADES,
      DashboardTables.HEADCOUNT_SECTION,
      DeliveryCenter.LOCAL_COE
    );
  }
);

export const getOverHeadsDetailsTotalData = createAsyncThunk(
  "visualDashboard/fetchOverHeadsDetailsTotalData",
  async (data: any) => {
    return commonOverHeadsData(
      data,
      DashboardTables.OVER_HEADS_DETAIL,
      DashboardTables.PROFIT_AND_LOSS_SECTION,
      OverHeadTypeId.Total
    );
  }
);

export const getOverHeadFTEDrivenData = createAsyncThunk(
  "visualDashboard/fetchOverHeadFTEDrivenData",
  async (data: any) => {
    return commonOverHeadsData(
      data,
      DashboardTables.OVER_HEADS_DETAIL,
      DashboardTables.PROFIT_AND_LOSS_SECTION,
      OverHeadTypeId.Direct_Overheads_Headcount_Driven
    );
  }
);

export const getOverHeadRevenueData = createAsyncThunk(
  "visualDashboard/fetchOverHeadRevenueData",
  async (data: any) => {
    return commonOverHeadsData(
      data,
      DashboardTables.OVER_HEADS_DETAIL,
      DashboardTables.PROFIT_AND_LOSS_SECTION,
      OverHeadTypeId.Direct_Overheads_Revenue_Driven
    );
  }
);

export const getOverHeadAllocationsData = createAsyncThunk(
  "visualDashboard/fetchOverHeadAllocationsData",
  async (data: any) => {
    return commonOverHeadsData(
      data,
      DashboardTables.OVER_HEADS_DETAIL,
      DashboardTables.PROFIT_AND_LOSS_SECTION,
      OverHeadTypeId.Overheads_Allocations_and_Non_Operating_Expenses
    );
  }
);

export const getOverHeadOtherData = createAsyncThunk(
  "visualDashboard/fetchOverHeadOtherData",
  async (data: any) => {
    return commonOverHeadsData(
      data,
      DashboardTables.OVER_HEADS_DETAIL,
      DashboardTables.PROFIT_AND_LOSS_SECTION,
      OverHeadTypeId.Overheads_Other
    );
  }
);

const commonOverHeadsData = async (
  data: any,
  tableName: string,
  section: string,
  overheadTypeId: number
) => {
  const { countryCodes, currencyCode, baseYear } = data;

  try {
    const res = await api.getOverHeadsData(
      countryCodes,
      currencyCode,
      baseYear,
      tableName,
      section,
      overheadTypeId
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const commonSectionsTableDataByDeliveryCenterId = async (
  data: any,
  tableName: string,
  section: string,
  delieveryCenterID: number
) => {
  const { countryCodes, currencyCode, baseYear } = data;

  try {
    const res = await api.getSectionsTableDataByDeliveryCenterID(
      countryCodes,
      currencyCode,
      baseYear,
      tableName,
      section,
      delieveryCenterID
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const commonGetTableData = async (
  data: any,
  tableName: string,
  section: string
) => {
  const { countryCodes, currencyCode, baseYear } = data;
  try {
    const res = await api.getTableData(
      countryCodes,
      currencyCode,
      baseYear,
      tableName,
      section
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const {
  setVisualDashboardCurrency,
  applyMetrics,
  setConsolidationGeographies,
  MakeGraphDataEmpty,
  setGMBridgeCohort,
  setGMBridgeStartYear,
  setGMBridgeEndYear,
  setGMBridgeToggle,
  setRefreshVisualDashboard,
} = visualDashBoardSlice.actions;

export default visualDashBoardSlice.reducer;
