import Modal from "../shared/modal/modal";
import React, { useState, useRef, useEffect } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { addToastMessage } from "services/common/commonsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Roles, STATUS_ID_MAPPING } from "utils/constants";
import { RootStore } from "services/store.service";

type SubmitReviewProps = {
  showModal: any;
  confirmSubmission: any;
  userRoleId?: number;
  reviewerEmailId?: string;
  mfAdminEmailId?: string;
  preparerEmailId?: string;
};

const SubmitReviewPopUp = ({
  showModal,
  confirmSubmission,
  userRoleId,
  reviewerEmailId,
  preparerEmailId,
  mfAdminEmailId,
}: SubmitReviewProps) => {
  const BLOCK = "submitReviewModal";
  const dispatch = useDispatch();
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);

  const handleClose = () => {
    showModal(false);
  };
  const determineHeader = () => {
    if (
      inputFormDataState?.inputFormStatusId === STATUS_ID_MAPPING.REVIEWED.id
    ) {
      return "input.form.final.submit.header";
    } else if (userRoleId === Roles.preparer) {
      return "input.form.submit.review.header";
    } else return "input.form.submit.reviewer.header";
  };

  return (
    <Modal
      show={true}
      header={determineHeader()}
      handleClose={handleClose}
      config={false}
      data-test="component-SubmitForReviewModel"
    >
      <div className={BLOCK}>
        <div className={`${BLOCK}__content`}>
          <div className={`${BLOCK}__content--text`}>
            {userRoleId === Roles.preparer &&
              inputFormDataState?.inputFormStatusId !==
                STATUS_ID_MAPPING.REVIEWED.id && (
                <FormattedMessage id={`input.form.submit.review.text`} />
              )}
            {userRoleId === Roles.reviewer &&
              inputFormDataState?.inputFormStatusId !==
                STATUS_ID_MAPPING.REVIEWED.id && (
                <FormattedMessage
                  id={`input.form.submit.review.reviewer.text`}
                />
              )}
            {inputFormDataState?.inputFormStatusId ===
              STATUS_ID_MAPPING.REVIEWED.id && (
              <FormattedMessage id={`input.form.final.submit.text`} />
            )}
          </div>
          <div className={`${BLOCK}__content--footer`}>
            <div className={`${BLOCK}__content--footer--actions`}>
              <button
                data-test="cloaseButton"
                className={`${BLOCK}__content--footer--actions--cancel`}
                onClick={() => handleClose()}
              >
                <FormattedMessage id={`input.form.submit.review.cancel`} />
              </button>
              <button
                data-test="confirmButton"
                className={`${BLOCK}__content--footer--actions--confirm`}
                onClick={() => confirmSubmission()}
              >
                <FormattedMessage id={`input.form.submit.review.confirm`} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubmitReviewPopUp;
