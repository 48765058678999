import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "./dashboard.api";

const initialState = {
  loading: false,
  geographyList: [],
  fiscalYearList: [],
  sortInfo: null,
  rollForwardData: false,
  selectedYear: [],
  configureFYLoading: false,
  configuredYear: null,
  configurationProjectedYearSelected: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setSortBy(state: any, action) {
      state.sortInfo = action.payload;
    },
    setSelectedYear(state: any, action) {
      state.selectedYear = action.payload;
    },
    dashboardLoading(state: any, action) {
      state.loading = action.payload;
    },
    makeDashboardListEmpty(state: any) {
      state.geographyList = [];
    },
    setConfigurationProjectedYearSelected(state: any, action) {
      state.configurationProjectedYearSelected = action.payload;
    },
    resetConfiguredYear(state: any, action) {
      state.configuredYear = action.payload;
    },
  },
  extraReducers(builder: any) {
    builder
      .addCase(getRollForwardData.fulfilled, (state: any, action: any) => {
        state.rollForwardData = action.payload;
      })
      .addCase(
        getConfiguredProjectedYear.pending,
        (state: any, action: any) => {
          state.configureFYLoading = true;
        }
      )
      .addCase(
        getConfiguredProjectedYear.fulfilled,
        (state: any, action: any) => {
          state.configuredYear = action.payload;
          state.configureFYLoading = false;
        }
      )
      .addCase(
        getConfiguredProjectedYear.rejected,
        (state: any, action: any) => {
          state.configureFYLoading = false;
        }
      )
      .addCase(getFilterYears.fulfilled, (state: any, action: any) => {
        state.fiscalYearList = action.payload ? action.payload : [];
      })
      .addCase(getDashboardTableList.pending, (state: any, action: any) => {
        state.loading = true;
      })
      .addCase(getDashboardTableList.fulfilled, (state: any, action: any) => {
        state.loading = false;
        const updatedList = addOrder(action.payload);
        state.geographyList = updatedList ? updatedList : [];
      })
      .addCase(getDashboardTableList.rejected, (state: any, action: any) => {
        state.loading = false;
      });
  },
});

export const getRollForwardData = createAsyncThunk(
  "dashboard/fetchRollForwardData",
  async () => {
    try {
      const res = await api.getRollForward();
      return res.data;
    } catch (e) {
      console.log("Error getting dashboard data");
      console.log(e);
    }
  }
);
export const getConfiguredProjectedYear = createAsyncThunk(
  "dashboard/fetchConfiguredProjectedYear",
  async (fiscalYear: number | null) => {
    try {
      const res = await api.getConfiguredYear(fiscalYear);
      return res.data;
    } catch (e) {
      console.log("Error getting dashboard data");
      console.log(e);
    }
  }
);

export const getFilterYears = createAsyncThunk(
  "dashboard/fetchFilterYearsData",
  async () => {
    try {
      const res = await api.getFiscalYearList();
      return res.data;
    } catch (e) {
      console.log("Error getting dashboard data");
      console.log(e);
    }
  }
);

export const getDashboardTableList = createAsyncThunk(
  "dashboard/fetchDashboardTableListData",
  async (fiscalYear: number | null) => {
    try {
      const res = await api.getDashboardTableList(fiscalYear);
      return res.data;
    } catch (e) {
      console.log("Error getting dashboard data");
      console.log(e);
    }
  }
);

const addOrder = (geoList: any) => {
  return geoList.map((geo: any) => {
    return {
      ...geo,
      order: geo.status,
    };
  });
};

export const uploadFile = (
  planSubmissionId: number | null,
  payload: any,
  config: any
) => {
  return new Promise((resolve, reject) => {
    api
      .uploadFile(planSubmissionId, payload, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const setRollForwardProcess = (year: number, config: any) => {
  return new Promise((resolve, reject) => {
    api
      .setRollForwardProcess(year, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        console.log("Error getting on rollforward process");
        reject(err);
      });
  });
};

export const setConfiguredProjectedYear = (
  baseYear: number,
  configuredYear: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setConfiguredYear({ NumberFY: configuredYear, BaseYear: baseYear })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        console.log("Error getting on rollforward process");
        reject(err);
      });
  });
};

export const {
  setSortBy,
  setSelectedYear,
  dashboardLoading,
  makeDashboardListEmpty,
  setConfigurationProjectedYearSelected,
  resetConfiguredYear,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
