import { lazy, Suspense, useEffect } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useSelector } from "react-redux";
import { Roles } from "utils/constants";
import DashboardHeaderButtons from "./DashbordHeader";
import Icon from "components/shared/icon/icon";
import { useDispatch } from "react-redux";
import { makeDashboardListEmpty } from "services/dashboard/dashBoardSlice";

const Dashboard = () => {
  const BLOCK = "dashboard";
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  useEffect(() => {
    dispatch(makeDashboardListEmpty());
  }, []);
  const DashboardTable = lazy(() => import("./dashboard-table")); //lazy import
  const renderLoader = () => (
    <div className="dashboardTable__spinner-container">
      <Icon
        name={"loading"}
        width={50}
        height={50}
        className={`${BLOCK}--loading`}
      />
    </div>
  );

  let currUserRole =
    currentUser && currentUser.userRoles && currentUser.userRoles.length > 0
      ? currentUser.userRoles[0].roleId
      : 0;

  return (
    <div className={BLOCK}>
      <div className={`${BLOCK}__header`}>
        <span className={`${BLOCK}__header--title`}>
          <FormattedMessage id={`home.main.title`} />
        </span>
      </div>
      <div className={`${BLOCK}__tableContainer`}>
        <div className={`${BLOCK}__tableContainer--header`}>
          <div className={`${BLOCK}__tableContainer--header--title`}>
            <FormattedMessage id={"dashbaord.table.header.title"} />
          </div>
          <div className={`${BLOCK}__filters`}>
            <DashboardHeaderButtons BLOCK={BLOCK} />
          </div>
        </div>
      </div>
      <div className={`${BLOCK}__tableContainer--table`} id="homeTable">
        {Object.entries(currentUser).length > 0 && (
          <Suspense fallback={renderLoader()}>
            <DashboardTable
              isGlobalAdmin={currUserRole === Roles.globalAdmin}
              isMfAdmin={currUserRole === Roles.memberFirmAdmin}
            />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
