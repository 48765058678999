import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { Link, useLocation } from "react-router-dom";
import { MENU_ITEMS } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import history from "utils/history";
import { RootStore } from "services/store.service";

type props = {
  BLOCK: string;
  icon: string;
  path: any;
  isMenuOpen: boolean;
  setPathname?: any;
  setShowModal?: any;
  setShowSave?: any;
  sharedSearchCount?: number;
  label: string;
  configuration?: any;
  tootlTipLabel?: any;
  setShowWarningPopUp: any;
  setDestination: any;
};

const MenuItem = ({
  BLOCK,
  icon,
  path,
  isMenuOpen,
  setPathname,
  setShowModal,
  sharedSearchCount,
  label,
  configuration,
  tootlTipLabel,
  setShowWarningPopUp,
  setDestination,
}: props) => {
  const location = useLocation();
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const sideMenulabel = (
    <FormattedMessage id={`side-menu.menu-item.${label}`} />
  );
  const finalToolTip = (
    <FormattedMessage id={`side-menu.menu-item.${tootlTipLabel}`} />
  );
  const pathname = typeof path === "string" ? path : "home";

  const handleSearchClick = (e: any) => {
    e.preventDefault();
    if (path) {
      if (!inputFormDataState.isInputFormSaved) {
        setShowWarningPopUp(true);
        setDestination({
          pathName: pathname,
          index: -1,
        });
      } else {
        history.push(pathname);
      }
    }
  };

  const isMenuActive = (path: string) => {
    return new RegExp(`^${path}`).test(location.pathname);
  };

  return (
    <Link
      className={classNames(`${BLOCK}__menu-item`, {
        [`${BLOCK}__menu-item--active`]: isMenuActive(
          typeof path === "string" ? path : "home"
        ),
      })}
      data-test="menu-item"
      data-testid="menu-item"
      to={path ?? ""}
      onClick={handleSearchClick}
    >
      {sharedSearchCount ? (
        <span className={`${BLOCK}__menu-item__icon`}>
          <span
            className={`${BLOCK}__menu-item__icon-content`}
            data-test="shared-search-count"
          >
            {isNaN(sharedSearchCount) ? 0 : sharedSearchCount.toString()}
          </span>
        </span>
      ) : (
        ""
      )}
      <Icon name={icon} height={24} />

      <div
        className={classNames(`${BLOCK}__menu-label`, {
          [`${BLOCK}__menu-label--show`]: isMenuOpen,
        })}
      >
        {sideMenulabel}
      </div>
      {((!isMenuOpen && tootlTipLabel) ||
        (isMenuOpen &&
          configuration &&
          !configuration.planSubmissionId &&
          tootlTipLabel)) && (
        <div className={`${BLOCK}__menu-item__tooltip`}>
          <Tooltip position={TooltipPosition.right}>{finalToolTip}</Tooltip>
        </div>
      )}
    </Link>
  );
};

export default MenuItem;
