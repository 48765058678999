import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Line, Circle } from "rc-progress";
import history from "../../utils/history";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  configurationsdPath,
  revenuePLdPath,
  headCountInputsPath,
  yearlyUtilizationPath,
  transformationsPath,
  planSubmission,
  configurationRequirements,
} from "utils/constants";
import TransformationAssumtionSubItems from "./transformationAssumptionSubItems";
import RevenueAndPlSubItems from "./revenueAndPlSubItems";
import HeadCountSubItems from "./headCountSubItems";
import Icon from "components/shared/icon/icon";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { setToggleNavigationPanel } from "services/common/commonsSlice";
import { useDispatch } from "react-redux";
import doubleArrowRight from "images/double-arrow-right.svg";
import doubleArrowLeft from "images/double-arrow-left.svg";

interface navigationPanel {
  BLOCK: string;
  stepSelected: number;
  setToggleNavigation: any;
  toggleNavigation: boolean;
  revenueAndPLPercent: number;
  yearlyUtilizationPercent: number;
  headCountsPercent: number;
  planSubmissionId: number;
  enableAllInputForms: boolean;
  totalFieldsRevPnL: number;
  totalFilledRevPnL: number;
  totalYearlyUtilizationFields: number;
  totalFilledYearlyUtilization: number;
  totalFieldsHeadcounts: number;
  totalFilledHeadcounts: number;
  changeTabs: any;
  setDestination: any;
  totalPercentage: any;
  setTotalPercentage: any;
  locationName: string;
  totalFieldsConfig: number;
  totalFilledInConfig: number;
  configurationPercent: number;
  setStepSelectedRevenuePl: any;
  stepSelectedRevenuePl: any;
  setStepSelectedHeadCount: any;
  stepSelectedHeadCount: any;
  setStepSelectedTransformation: any;
  stepSelectedTransformation: any;
  totalFieldsTransformations: number;
  totalFilledTransformations: number;
  transformationPercent: number;
}

const NavigationPanel = ({
  BLOCK,
  stepSelected,
  toggleNavigation,
  setToggleNavigation,
  revenueAndPLPercent,
  yearlyUtilizationPercent,
  headCountsPercent,
  planSubmissionId,
  enableAllInputForms,
  totalFieldsRevPnL,
  totalFilledRevPnL,
  totalYearlyUtilizationFields,
  totalFilledYearlyUtilization,
  totalFieldsHeadcounts,
  totalFilledHeadcounts,
  changeTabs,
  setDestination,
  totalPercentage,
  setTotalPercentage,
  locationName,
  totalFieldsConfig,
  totalFilledInConfig,
  configurationPercent,
  setStepSelectedRevenuePl,
  stepSelectedRevenuePl,
  setStepSelectedHeadCount,
  stepSelectedHeadCount,
  setStepSelectedTransformation,
  stepSelectedTransformation,
  totalFieldsTransformations,
  totalFilledTransformations,
  transformationPercent,
}: navigationPanel) => {
  const dispatch = useDispatch();
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const [revenueAndPlToggle, setRevenueAndPlToggle] = useState<boolean>(false);
  const [headCountToggle, setHeadCountToggle] = useState<boolean>(false);
  const [transformationToggle, setTransformationToggle] =
    useState<boolean>(false);
  const [stepSelectedNavigation, setStepSelectedNavigation] = useState<
    number | null
  >(stepSelected);

  const randomList = new Uint8Array(1);

  const [randomID, setRandomID] = useState(
    String(crypto.getRandomValues(randomList))
  );

  useEffect(() => {
    setStepSelectedNavigation(stepSelected);
  }, [stepSelected]);

  useEffect(() => {
    if (!locationName.includes(transformationsPath)) {
      setStepSelectedTransformation(null);
    } else {
      setTransformationToggle(true);
    }
  }, [locationName]);
  useEffect(() => {
    if (!locationName.includes(revenuePLdPath)) {
      setStepSelectedRevenuePl(null);
    } else {
      setRevenueAndPlToggle(true);
    }
  }, [locationName]);

  useEffect(() => {
    if (!locationName.includes(headCountInputsPath)) {
      setStepSelectedHeadCount(null);
    } else {
      setHeadCountToggle(true);
    }
  }, [locationName]);

  let strokeWidth = 15;
  let linestrokeWidth = 2;
  useEffect(() => {
    if (
      totalFieldsConfig !== 0 &&
      totalFieldsHeadcounts !== 0 &&
      totalYearlyUtilizationFields !== 0 &&
      totalFieldsRevPnL !== 0 &&
      totalFieldsTransformations !== 0
    ) {
      const totalFieldsAllTables =
        totalFieldsConfig +
        totalFieldsHeadcounts +
        totalYearlyUtilizationFields +
        totalFieldsRevPnL +
        totalFieldsTransformations;
      const totalFilledAllTables =
        totalFilledHeadcounts +
        totalFilledYearlyUtilization +
        totalFilledInConfig +
        totalFilledRevPnL +
        totalFilledTransformations;

      let currTotalPercentage =
        (totalFilledAllTables / totalFieldsAllTables) * 100;
      currTotalPercentage = Math.floor(currTotalPercentage);

      setTotalPercentage(currTotalPercentage);
    } else if (inputFormDataState?.metaData?.percentCompletion) {
      setTotalPercentage(inputFormDataState?.metaData?.percentCompletion);
    }
  }, [
    totalFieldsConfig,
    totalFieldsHeadcounts,
    totalYearlyUtilizationFields,
    totalFieldsRevPnL,
    totalFilledHeadcounts,
    totalFilledYearlyUtilization,
    totalFilledRevPnL,
    totalFilledInConfig,
    totalFieldsTransformations,
    totalFilledTransformations,
    inputFormDataState?.metaData,
  ]);

  const callChangeTabs = (
    pathName: string,
    index: number = -1,
    byPass: boolean = false
  ) => {
    changeTabs(pathName, byPass, index);
    setDestination({
      pathName: pathName,
      index: index,
    });
  };

  useEffect(() => {
    let headerElements = document.querySelectorAll("th");
    let greyInputFields: any = document.getElementsByClassName(
      "Table__grade_out_input-container"
    );
    let inputFields: any = document.getElementsByClassName(
      "Table__input-container"
    );
    let erroInputFields: any = document.getElementsByClassName(
      "Table__error_input-container"
    );

    if (!toggleNavigation) {
      dispatch(setToggleNavigationPanel(false));
      for (let i = 0; i < headerElements.length; i++) {
        if (i === 0) {
          headerElements[i].style.maxWidth = "150px";
        } else {
          headerElements[i].style.maxWidth = "150px";
        }
      }
      for (let i = 0; i < greyInputFields.length; i++) {
        greyInputFields[i].style.width = "149px";
      }
      for (let i = 0; i < inputFields.length; i++) {
        inputFields[i].style.width = "149px";
      }
      for (let i = 0; i < erroInputFields.length; i++) {
        erroInputFields[i].style.width = "149px";
      }
    } else {
      dispatch(setToggleNavigationPanel(true));
      for (let i = 0; i < headerElements.length; i++) {
        if (i === 0) {
          headerElements[i].style.maxWidth = "150px";
        } else {
          headerElements[i].style.maxWidth = "130px";
        }
      }
      for (let i = 0; i < greyInputFields.length; i++) {
        greyInputFields[i].style.width = "96px";
      }
      for (let i = 0; i < inputFields.length; i++) {
        inputFields[i].style.width = "96px";
      }
      for (let i = 0; i < erroInputFields.length; i++) {
        erroInputFields[i].style.width = "96px";
      }
    }
  }, [toggleNavigation]);

  return (
    <>
      {!toggleNavigation && (
        <div
          className={`${BLOCK}__navigation-panel`}
          style={{
            cursor: "pointer",
            backgroundColor: "#e9e9e9",
            width: "48px",
          }}
          onClick={() =>
            !toggleNavigation && setToggleNavigation(!toggleNavigation)
          }
        >
          <div className={`${BLOCK}__side-bar-container`}>
            <div style={{ marginTop: 25, marginRight: 12 }}>
              <img
                className={`${BLOCK}--double-arrow`}
                src={!toggleNavigation ? doubleArrowRight : doubleArrowLeft}
                onClick={() => setToggleNavigation(!toggleNavigation)}
              />
            </div>
            <div className={`${BLOCK}__side-bar-onclose`}>
              <FormattedMessage id="tool.yp.name" />
            </div>
          </div>
        </div>
      )}
      <div
        className={`${BLOCK}__navigation-panel`}
        style={
          toggleNavigation
            ? { width: "25%", display: "block" }
            : {
                cursor: "pointer",
                backgroundColor: "#e9e9e9",
                width: "48px",
                display: "none",
              }
        }
        onClick={() =>
          !toggleNavigation && setToggleNavigation(!toggleNavigation)
        }
      >
        <ReactTooltip id={randomID} effect="solid" place="right" />
        <div className={`${BLOCK}__navigation-panel--item`}>
          <FormattedMessage id="tool.yp.name" />
          <div style={{ float: "right" }}>
            <img
              className={`${BLOCK}--double-arrow`}
              src={!toggleNavigation ? doubleArrowRight : doubleArrowLeft}
              onClick={() => setToggleNavigation(!toggleNavigation)}
            />
          </div>
        </div>
        <div className={`${BLOCK}__navigation-panel-scroll`}>
          <div className={`${BLOCK}__navigation-panel--progress`}>
            <span>Progress</span>
            <span style={{ float: "right" }}>{totalPercentage}%</span>
            <Line
              percent={totalPercentage}
              trailWidth={linestrokeWidth}
              strokeWidth={linestrokeWidth}
              strokeColor={totalPercentage === 100 ? "#43B02A" : "#00A3E0"}
            />
          </div>
          <div
            className={`${BLOCK}__navigation-panel--item`}
            onClick={() => callChangeTabs(configurationsdPath)}
          >
            <span
              style={{
                color: stepSelectedNavigation === 1 ? "#00A3E0" : "black",
                cursor: "pointer",
              }}
            >
              Configuration
            </span>
            <div>
              <div
                data-tip={`${totalFilledInConfig}/${totalFieldsConfig}`}
                data-for={randomID}
              >
                <Circle
                  style={{ width: "20px" }}
                  trailWidth={strokeWidth}
                  percent={Math.round(configurationPercent)}
                  strokeWidth={strokeWidth}
                  strokeLinecap="round"
                  strokeColor={
                    Math.round(configurationPercent) === 100
                      ? "#43B02A"
                      : "#00A3E0"
                  }
                />
              </div>
            </div>
          </div>
          {/* Revenue And Pl Section */}
          <div
            className={`${BLOCK}__navigation-panel--item ${BLOCK}__tooltip`}
            data-tip={!enableAllInputForms ? configurationRequirements : ""}
            data-for={randomID}
          >
            <div className={`${BLOCK}__icon-text`}>
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  enableAllInputForms &&
                  setRevenueAndPlToggle(!revenueAndPlToggle)
                }
              >
                <Icon
                  name={revenueAndPlToggle ? "caretup" : "caretright"}
                  width={16}
                  height={16}
                />
              </span>

              <div
                style={{
                  cursor: enableAllInputForms ? "pointer" : "context-menu",
                }}
                onClick={(e) => {
                  if (enableAllInputForms) {
                    callChangeTabs(revenuePLdPath);
                    setRevenueAndPlToggle(true);
                  }
                }}
              >
                <div
                  style={{
                    color: !enableAllInputForms
                      ? "rgb(217, 217, 217)"
                      : stepSelectedNavigation === 2
                      ? "#00A3E0"
                      : "black",
                    marginLeft: 20,
                  }}
                >
                  <FormattedMessage id={`navigation.revenue.title`} />
                </div>
              </div>
            </div>
            <div>
              <div
                data-tip={
                  enableAllInputForms
                    ? `${totalFilledRevPnL}/${totalFieldsRevPnL}`
                    : configurationRequirements
                }
                data-for={randomID}
              >
                <Circle
                  style={{ width: "20px" }}
                  trailWidth={strokeWidth}
                  percent={revenueAndPLPercent}
                  strokeWidth={strokeWidth}
                  strokeLinecap="round"
                  strokeColor={
                    revenueAndPLPercent === 100 ? "#43B02A" : "#00A3E0"
                  }
                />
              </div>
            </div>
          </div>
          {revenueAndPlToggle && (
            <RevenueAndPlSubItems BLOCK={BLOCK} changeTabs={callChangeTabs} />
          )}

          {/* Yearly Utilization Rates section */}
          <div
            className={`${BLOCK}__navigation-panel--item ${BLOCK}__tooltip`}
            data-tip={!enableAllInputForms ? configurationRequirements : ""}
            data-for={randomID}
          >
            <div className={`${BLOCK}__icon-text`}>
              <div
                style={{
                  cursor: enableAllInputForms ? "pointer" : "context-menu",
                }}
                onClick={(e) => {
                  if (enableAllInputForms) {
                    callChangeTabs(yearlyUtilizationPath);
                  }
                }}
              >
                <div
                  style={{
                    color: !enableAllInputForms
                      ? "rgb(217, 217, 217)"
                      : stepSelectedNavigation === 3
                      ? "#00A3E0"
                      : "black",
                  }}
                >
                  <FormattedMessage id={`navigation.yearlyUtilization.title`} />
                </div>
              </div>
            </div>
            <div>
              <div
                data-tip={
                  enableAllInputForms
                    ? `${totalFilledYearlyUtilization}/${totalYearlyUtilizationFields}`
                    : configurationRequirements
                }
                data-for={randomID}
              >
                <Circle
                  style={{ width: "20px" }}
                  trailWidth={strokeWidth}
                  percent={yearlyUtilizationPercent}
                  strokeWidth={strokeWidth}
                  strokeLinecap="round"
                  strokeColor={
                    yearlyUtilizationPercent === 100 ? "#43B02A" : "#00A3E0"
                  }
                />
              </div>
            </div>
          </div>

          {/* Headcounts section */}
          <div
            className={`${BLOCK}__navigation-panel--item ${BLOCK}__tooltip`}
            data-tip={!enableAllInputForms ? configurationRequirements : ""}
            data-for={randomID}
          >
            <div className={`${BLOCK}__icon-text`}>
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  enableAllInputForms && setHeadCountToggle(!headCountToggle)
                }
              >
                <Icon
                  name={headCountToggle ? "caretup" : "caretright"}
                  width={16}
                  height={16}
                />
              </span>

              <div
                style={{
                  cursor: enableAllInputForms ? "pointer" : "context-menu",
                }}
                onClick={(e) => {
                  if (enableAllInputForms) {
                    callChangeTabs(headCountInputsPath);
                    setHeadCountToggle(true);
                  }
                }}
              >
                <div
                  style={{
                    color: !enableAllInputForms
                      ? "rgb(217, 217, 217)"
                      : stepSelectedNavigation === 4
                      ? "#00A3E0"
                      : "black",
                    marginLeft: 20,
                  }}
                >
                  <FormattedMessage id={`navigation.headcounts.title`} />
                </div>
              </div>
            </div>
            <div>
              <div
                data-tip={
                  enableAllInputForms
                    ? `${totalFilledHeadcounts}/${totalFieldsHeadcounts}`
                    : configurationRequirements
                }
                data-for={randomID}
              >
                <Circle
                  style={{ width: "20px" }}
                  trailWidth={strokeWidth}
                  percent={headCountsPercent}
                  strokeWidth={strokeWidth}
                  strokeLinecap="round"
                  strokeColor={
                    headCountsPercent === 100 ? "#43B02A" : "#00A3E0"
                  }
                />
              </div>
            </div>
          </div>
          {headCountToggle && (
            <HeadCountSubItems BLOCK={BLOCK} changeTabs={callChangeTabs} />
          )}

          {/* Transformation Section */}
          <div
            className={`${BLOCK}__navigation-panel--item ${BLOCK}__tooltip`}
            data-tip={!enableAllInputForms ? configurationRequirements : ""}
            data-for={randomID}
          >
            <div
              style={{
                cursor: enableAllInputForms ? "pointer" : "context-menu",
              }}
              className={`${BLOCK}__icon-text`}
              onClick={() =>
                enableAllInputForms &&
                setTransformationToggle(!transformationToggle)
              }
            >
              <Icon
                name={transformationToggle ? "caretup" : "caretright"}
                width={16}
                height={16}
              />
              <div
                style={{
                  color: !enableAllInputForms
                    ? "rgb(217, 217, 217)"
                    : stepSelectedNavigation === 5
                    ? "#00A3E0"
                    : "black",
                  marginLeft: 20,
                }}
              >
                <FormattedMessage id={`navigation.transfomration.title`} />
              </div>
            </div>
            <div>
              <div
                data-tip={
                  enableAllInputForms
                    ? `${totalFilledTransformations}/${totalFieldsTransformations}`
                    : configurationRequirements
                }
                data-for={randomID}
              >
                <Circle
                  style={{ width: "20px" }}
                  trailWidth={strokeWidth}
                  percent={transformationPercent}
                  strokeWidth={strokeWidth}
                  strokeLinecap="round"
                  strokeColor={
                    transformationPercent === 100 ? "#43B02A" : "#00A3E0"
                  }
                />
              </div>
            </div>
          </div>
          {transformationToggle && (
            <TransformationAssumtionSubItems
              BLOCK={BLOCK}
              changeTabs={callChangeTabs}
              toggleNavigation={toggleNavigation}
              planSubmissionId={planSubmissionId}
              stepSelectedTransformation={stepSelectedTransformation}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default NavigationPanel;
