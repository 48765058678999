import Unauthorized from "./unauthorized.png";
import FormattedMessage from "../shared/formatted-message/formatted-message";

const UnauthorizedPage = () => {
  const BLOCK = "unauthorized";

  const supportLink = (
    <a
      key="unauthorized-supportLink"
      className={`${BLOCK}__link`}
      href="https://deloitteus.service-now.com/sp?id=kb_article&sys_id=4b0891c81ba711503078b99e0a4bcb92"
      target="_blank"
      rel="noreferrer"
    >
      <FormattedMessage id="unauthorized.support-link" />
    </a>
  );

  return (
    <div className={`${BLOCK}`}>
      <div className={`${BLOCK}__container`}>
        <img
          className={`${BLOCK}__image`}
          src={Unauthorized}
          alt="Unathorized"
        />
        <span className={`${BLOCK}__title`}>
          <FormattedMessage id="unauthorized.title" />
        </span>
        <span className={`${BLOCK}__message`}>
          <p>
            <FormattedMessage
              id="unauthorized.message"
              values={{ supportLink }}
            />
          </p>
        </span>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
