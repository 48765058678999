import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { dropdownHeight } from "utils/constants";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { memo } from "react";
import ReactHtmlParser from "react-html-parser";

type ObjectKeys = {
  name: string;
  id: string;
  externalId?: string;
  ticker?: string;
  valueText?: string;
  children?: string;
  childName?: string;
  childId?: string;
  childExternalId?: string;
};

type Props = {
  className?: string;
  label?: string | object;
  showLabel?: boolean;
  placeholder?: string | object;
  loading: boolean;
  flag?: boolean;
  options: any[];
  values?: any[];
  handleChange?: any;
  editText?: any;
  handleSelect?: any;
  handleUnselect?: any;
  handleGroupUnselect?: any;
  handleChangeValue?: any;
  objectKeys: ObjectKeys;
  contentStyle?: boolean;
  textValue?: any;
  displayFunction?: any;
  handleOnEnter?: any;
  mapFiles?: boolean;
  hideValues?: boolean;
  showAllValues?: any;
  fieldMissing?: boolean;
  disabled?: boolean;
  customPlaceHolder?: string;
  preparerFilter?: boolean;
};

const DropDown = ({
  className,
  label,
  showLabel = true,
  placeholder,
  loading,
  options,
  values = [],
  handleChange,
  handleSelect,
  handleUnselect,
  handleGroupUnselect,
  handleChangeValue,
  objectKeys,
  contentStyle,
  displayFunction,
  textValue,
  handleOnEnter,
  mapFiles,
  hideValues,
  showAllValues,
  fieldMissing,
  customPlaceHolder,
  disabled = false,
  editText,
  flag,
  preparerFilter,
}: Props) => {
  const [showOptions, toggleOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputFilter, setInputFilter] = useState("");
  const [input, setInput] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const inputSizeRef = useRef<HTMLDivElement>(null);
  const BLOCK = "drop-down";

  const handleLocalSelect = (option: any) => {
    let newValue;

    if (option?.email) {
      newValue = option.email;
    } else if (option?.currencyCode && option?.name) {
      newValue = option[objectKeys.id] + " - " + option[objectKeys.name];
    } else if (option?.countryName || option?.name) {
      newValue = option[objectKeys.name];
    } else {
      newValue = option;
    }

    !preparerFilter ? setInputFilter(newValue) : setInputFilter("");
    handleSelect(newValue);
    option?.currencyCode && option?.name && handleFilterChange("");
  };

  useEffect(() => {
    setFilteredOptions(options ? options : []);
  }, [options]);

  useEffect(() => {
    if (!preparerFilter && inputFilter !== "" && values?.length === 0) {
      setInputFilter("");
    }
    if (!preparerFilter && values?.length !== 0) {
      setInputFilter(values[0]);
    }
  }, [values]);

  useEffect(() => {
    if (showOptions && optionsRef.current) {
      optionsRef.current.focus();
      const dropdownElement = optionsRef.current?.parentElement;
      const scrollView = optionsRef.current?.parentElement?.parentElement;
      if (dropdownElement && scrollView) {
        const dropdownBottomOffset = dropdownElement.offsetTop + dropdownHeight;
        const offsetToScroll =
          dropdownBottomOffset > scrollView.clientHeight
            ? dropdownBottomOffset - scrollView.clientHeight
            : 0;
        setTimeout(() => {
          scrollView.scrollTo({ top: offsetToScroll, behavior: "smooth" });
        }, 200); // wait for the transition to finish
      }
    }
  }, [showOptions, values]);

  useEffect(() => {
    if (!loading && filteredOptions?.length === 0 && inputFilter !== "") {
      setInput(true);
    } else {
      setInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFilter, loading, filteredOptions?.length]);

  useEffect(() => {
    const handleClick = (event: any) => {
      return showOptions || event.target.nodeName !== "svg"
        ? toggleOptions(
            wrapperRef.current !== null &&
              wrapperRef.current.contains(event.target)
          )
        : null;
    };

    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);

    textareaRef.current?.setAttribute(
      "style",
      `width: ${inputSizeRef.current?.clientWidth}px;
      height: ${inputSizeRef.current?.clientHeight}px`
    );
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, options, input, inputFilter, showOptions]);

  const highlightOption = (option: string) => {
    if (inputFilter !== "" && option && !contentStyle) {
      let replace: any;
      // Replacing Open Braces and Close braces if found individually
      // Would break the regex condition
      if (inputFilter?.includes("(") && inputFilter?.includes(")")) {
        replace = new RegExp(`${inputFilter}`, "i");
      } else if (inputFilter?.includes("(")) {
        replace = new RegExp(`${inputFilter})`, "i");
      } else if (inputFilter?.includes(")")) {
        replace = new RegExp(`(${inputFilter}`, "i");
      } else replace = new RegExp(`(${inputFilter})`, "i");

      return option
        .split(replace)
        .map((subtext) =>
          replace.test(subtext) ? `<b>${subtext}</b>` : subtext
        )
        .join("");
    }
    return option;
  };

  const getOption = (option: any, optionKeys: ObjectKeys, index: number) => {
    let newValue;
    if (option?.email) {
      newValue = option.email;
    } else if (option?.currencyCode && option?.name) {
      newValue = option[objectKeys.id] + " - " + option[objectKeys.name];
    } else {
      newValue = option[objectKeys.name];
    }

    const isSelected =
      values.length > 0 && values[0] === newValue ? true : false;

    return (
      <button
        key={option[objectKeys.name] + `-` + index}
        className={classNames(`${BLOCK}__option`, {
          [`${BLOCK}__option--selected`]: isSelected,
        })}
        onClick={() => {
          handleLocalSelect(option);
          toggleOptions(false);
        }}
        data-test="select-option-button"
        data-testid="select-option-button"
      >
        <table>
          <tbody>
            <tr>
              {optionKeys.ticker && (
                <td className={`${BLOCK}__ticker`}>
                  {ReactHtmlParser(highlightOption(option[optionKeys.ticker]))}
                </td>
              )}
              <td className={`${BLOCK}__option-value`} data-test="option-value">
                {ReactHtmlParser(
                  highlightOption(
                    displayFunction
                      ? displayFunction(option)
                      : option[optionKeys.name]
                  )
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {isSelected && <Icon name="tick" height={20} />}
      </button>
    );
  };

  const handleFilterChange = (e: any) => {
    const value = e?.target?.value;
    setInputFilter(value);
    if (handleChangeValue) handleChangeValue(value);

    if (value) {
      if (handleChange) {
        handleChange(e);
        return;
      }
      if (objectKeys.children) {
        setFilteredOptions(
          options.reduce((filtered, option) => {
            filtered.push({
              ...option,
              [objectKeys.children || ""]: option[
                objectKeys.children || ""
              ].filter((child: any) =>
                child[objectKeys.childName || ""]
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ),
            });
            let newFilter = filtered.filter((child: any) => {
              if (child.formTypes) {
                return child.formTypes.length !== 0;
              } else if (child.sectors) {
                return child.sectors.length !== 0;
              }
              return true;
            });
            return newFilter;
          }, [])
        );
      } else {
        setFilteredOptions(
          options.filter((option) => {
            if (option.currencyCode && option.name) {
              let searchOptionValue =
                option[objectKeys.id] + " - " + option[objectKeys.name];
              return searchOptionValue
                ?.toLowerCase()
                ?.includes(value.toLowerCase());
            } else if (option?.countryName || option?.name) {
              return option[objectKeys.name]
                ?.toLowerCase()
                ?.includes(value.toLowerCase());
            } else {
              return option?.toLowerCase()?.includes(value.toLowerCase());
            }
          })
        );
      }
    } else {
      setFilteredOptions(handleChange ? [] : options);
    }
  };

  useEffect(() => {
    if (!showOptions && values && values.length) {
      setInputFilter(values[0]);
    }
  }, [showOptions]);

  return (
    <div
      ref={wrapperRef}
      id={`${BLOCK}-${objectKeys.id}`}
      className={`${BLOCK} ${className}`}
      data-test="drop-down"
    >
      <div className={`${BLOCK}__label`} data-test="drop-down">
        {showLabel && label}
      </div>
      <div
        className={classNames(`${BLOCK}__outline`, {
          [`${BLOCK}__outline--not-empty`]: values && values.length,
          [`${BLOCK}__outline--missing`]: fieldMissing,
        })}
        style={
          disabled
            ? { backgroundColor: "#dfdfdf" }
            : { backgroundColor: "white" }
        }
      >
        <div className={`${BLOCK}__values`} data-test="values">
          {hideValues && !disabled && (
            <span
              className={`${BLOCK}__hiddenChips`}
              onClick={() => showAllValues(!hideValues)}
            >
              {`+${values.length - 2}`}
            </span>
          )}
          {values.length > 2 && mapFiles && !hideValues && !disabled && (
            <span
              className={`${BLOCK}__hiddenChips--showLess`}
              onClick={() => showAllValues(true)}
            >
              <FormattedMessage id="map-files-show-less" />
            </span>
          )}

          <textarea
            ref={textareaRef}
            value={textValue ? textValue : inputFilter}
            className={classNames(`${BLOCK}__input`, {
              [`${BLOCK}__input--not-empty`]: values && values.length,
            })}
            data-test="input-text-area"
            onChange={(e) => {
              editText && editText(e);
              handleFilterChange(e);
            }}
            onClick={(e) => {
              flag && handleFilterChange(e);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleOnEnter({});
              }
            }}
            placeholder={customPlaceHolder && customPlaceHolder}
            disabled={disabled}
          />
          <div ref={inputSizeRef} className={`${BLOCK}__input-size`}>
            {inputFilter}
          </div>
        </div>
        {!textValue && !values?.length && inputFilter === "" && (
          <div className={`${BLOCK}__placeholder`}>{placeholder}</div>
        )}
        {!disabled && filteredOptions?.length > 0 && (
          <button
            className={classNames(`${BLOCK}__chevron-button`, {
              [`${BLOCK}__chevron-button--not-empty`]: values && values.length,
            })}
            data-test="chevron-button"
            onClick={() => !disabled && toggleOptions(!showOptions)}
          >
            <Icon
              className={classNames(`${BLOCK}__chevron-icon`, {
                [`${BLOCK}__chevron-icon--open`]: showOptions,
                [`${BLOCK}__chevron-icon--close`]: !showOptions,
              })}
              name="chevron-down"
              height={24}
            />
          </button>
        )}
      </div>

      {!disabled && (
        <div
          ref={optionsRef}
          className={classNames(
            `${BLOCK}__options`,
            {
              [`${BLOCK}__options--show`]: showOptions,
              [`${BLOCK}__options--hide`]: !showOptions,
            },
            {
              [`${BLOCK}__options--map`]: mapFiles,
            }
          )}
          data-test="drop-down-options"
        >
          {loading || filteredOptions?.length === 0 ? (
            <button className={`${BLOCK}__option`}>
              {loading && (
                <Icon
                  name="loading"
                  width={20}
                  height={20}
                  data-test="loading"
                  className={classNames(`${BLOCK}__loading`, {
                    "loading-icon": loading,
                  })}
                />
              )}
              {!loading &&
                inputFilter !== "" &&
                filteredOptions?.length === 0 && (
                  <div>
                    <FormattedMessage id="filter.no.matches" />
                  </div>
                )}
            </button>
          ) : (
            filteredOptions?.map((option: any, index: number) =>
              getOption(option, objectKeys, index)
            )
          )}
        </div>
      )}
    </div>
  );
};

export default memo(DropDown);
