import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAPI } from "./yearlyUtilizationContextProvider";
import {
  calculateOpeningAndClosingPositionAPI,
  changeYearlyUtilizationData,
  getProgressPercentage,
  getStatusId,
  setInputFormProgressDataLocal,
  submitYearlyUtilisationRate,
  setInputFormSaved,
} from "services/inputForm/inputFormSlice";
import ProductSegmentTab from "./productSegmentTab";
import classNames from "classnames";
import Toggle from "components/shared/toggle/toggle";
import {
  productSegmentsMapping,
  productSegmentOtherId,
  productSegmentTier5Id,
  productSegmentsMappingWithYearlyUtilizationTableId,
  YEARLY_UTILIZATION_TABLE_ID,
  YEARLY_UTLIZATION_TOGGLE_TOOLTIP,
  STATUS_ID_MAPPING,
} from "utils/constants";
import { useEffect, useState } from "react";
import { addToastMessage } from "services/common/commonsSlice";
import UpdateInputsModal from "components/inputform/updateMultipleInputsModal";

const YearlyUtlizationTab = ({
  toggleNavigation,
  setSaved,
  setSavingData,
  savingData,
  yearlyUtilizationApiCall,
  setYearlyUtilizationApiCall,
  yearlyUtilizationTableProgress,
  totalPercentage,
  yearlyUtilizationDirtyTables,
  setYearlyUtilizationDirtyTables,
}: any) => {
  const BLOCK = "headcounts";
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  const [showUpdateInputsPopup, setShowUpdateInputsPopup] =
    useState<boolean>(false);
  const [isDisable, setDisable] = useState<boolean>(true);
  const {
    compareTablesData,
    configToggle,
    yearlyUtilizationTablesData,
    setCompareTablesData,
    setConfigToggle,
    setYearlyUtilizationTablesData,
    yearlyUtilizationTableIdList,
  }: any = useAPI();
  const params: any = useParams();
  const dispatch = useDispatch();

  let planSubmissionId = inputFormDataState?.metaData?.planSubmissionId
    ? inputFormDataState?.metaData?.planSubmissionId
    : Number(params?.id);

  useEffect(() => {
    let inputFormProgressData =
      inputFormDataState?.InputFormProgressData?.fieldCountDetails;

    let yearlyUtilizationCompletedFields = 0;

    yearlyUtilizationTablesData?.map((item: any) => {
      Object.keys(item || {})
        ?.filter(Number)
        ?.map((row: any) => {
          if (item[row] !== null) yearlyUtilizationCompletedFields++;
        });
    });
    let updatedData: any = [];

    if (inputFormProgressData?.length) {
      updatedData = inputFormProgressData?.map((item: any) => {
        switch (item.inputFormMasterTableId) {
          case YEARLY_UTILIZATION_TABLE_ID:
            return yearlyUtilizationTablesData &&
              Object.keys(yearlyUtilizationTablesData || {})?.length > 0
              ? {
                  ...item,
                  completedFields: yearlyUtilizationCompletedFields,
                }
              : item;

          default:
            return item;
        }
      });
    }

    let dataObj = {
      fieldCountDetails: updatedData,
      percentCompletion:
        inputFormDataState?.InputFormProgressData?.percentCompletion,
      planSubmissionId:
        inputFormDataState?.InputFormProgressData?.planSubmissionId,
    };
    dispatch(setInputFormProgressDataLocal(dataObj));
  }, [inputFormDataState?.InputFormProgressData, yearlyUtilizationTablesData]);

  useEffect(() => {
    let baseYear: any;
    yearlyUtilizationTablesData?.map((item: any) => {
      return Object.keys(item || {})
        ?.filter(Number)
        ?.map((row: any, index: number) => {
          if (index == 0) {
            return (baseYear = row);
          }
        });
    });
    const found = yearlyUtilizationTablesData.find(
      (item: any) => item[baseYear] !== null
    );
    if (found || configToggle == true) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if(inputFormDataState.inputFormStatusId === STATUS_ID_MAPPING.ARCHIVED.id){
      setDisable(true)
    }
  }, [yearlyUtilizationTablesData]);

  useEffect(() => {
    submitData(
      yearlyUtilizationTablesData,
      savingData,
      yearlyUtilizationApiCall,
      false
    );
  }, [savingData, yearlyUtilizationApiCall]);

  const submitData = (
    Data: any,
    isSave: boolean,
    isAPICall: boolean,
    toggleValue: boolean,
    isToggleCall: boolean = false
  ) => {
    if (isSave && isAPICall) {
      isToggleCall &&
        changeYearlyUtilizationData(planSubmissionId, toggleValue)
          .then(() => {
            dispatch(setInputFormSaved(true));
            setConfigToggle(toggleValue);
          })
          .catch(() => {
            setConfigToggle(!toggleValue);
          });

      if (yearlyUtilizationDirtyTables?.length > 0) {
        Promise.all([submitYearlyUtilisationRate(Data, planSubmissionId)])
          .then(() => {
            totalPercentage &&
              totalPercentage === 100 &&
              dispatch(getStatusId(planSubmissionId));
            dispatch(getProgressPercentage(planSubmissionId));

            dispatch(
              calculateOpeningAndClosingPositionAPI(planSubmissionId, false)
            );
            setCompareTablesData(removeDisableObj(Data));
            setYearlyUtilizationDirtyTables([]);
            dispatch(
              addToastMessage({
                description: (
                  <FormattedMessage id="form.save.toaster.message" />
                ),
              })
            );
          })
          .catch(() => {
            dispatch(
              addToastMessage({
                description: (
                  <FormattedMessage id="form.error.toaster.message" />
                ),
              })
            );
          })
          .finally(() => {
            setSavingData(false);
            setYearlyUtilizationApiCall(false);
          });
      } else {
        setSavingData(false);
        setYearlyUtilizationApiCall(false);
        dispatch(
          addToastMessage({
            description: <FormattedMessage id="form.save.toaster.message" />,
          })
        );
      }
    }
  };
  const removeDisableObj = (Data: any) => {
    let updatedTableData: any = [];
    Data?.map((item: any) => {
      const {
        error,
        errorList,
        errorToastNotRequired,
        disabled,
        ...newObject
      } = item;
      updatedTableData.push(newObject);
    });
    return updatedTableData;
  };
  const changeYearlyUtilizationFlag = () => {
    setConfigToggle(!configToggle);
    submitData(yearlyUtilizationTablesData, true, true, !configToggle, true);
  };

  return (
    <>
      <div
        className={`${BLOCK}`}
        style={{ width: !toggleNavigation ? "100%" : "75%" }}
      >
        <div className="scroll-add-remove">
          <div
            className={classNames(`${BLOCK}__divider-header`, {
              [`${BLOCK}__contentSpace`]: true,
            })}
          >
            <div className={`${BLOCK}__divider-header--title`}>
              <FormattedMessage id={"yearly.utilization.main.title"} />
            </div>
            <div className={`inputform__header--status`}>
              <Toggle
                label={
                  <FormattedMessage id={"yearly.utilization.toggle.message"} />
                }
                tooltip={YEARLY_UTLIZATION_TOGGLE_TOOLTIP}
                disabled={isDisable}
                value={configToggle}
                onChange={changeYearlyUtilizationFlag}
                isLabelFront={true}
              />
            </div>
          </div>
          {Object.keys(productSegmentsMapping)
            .filter((item: any) => {
              if (
                !inputFormDataState?.configurationData?.hasOther &&
                !inputFormDataState?.configurationData?.hasTier5
              ) {
                return (
                  item !== productSegmentOtherId &&
                  item !== productSegmentTier5Id
                );
              } else if (!inputFormDataState?.configurationData?.hasOther) {
                return item !== productSegmentOtherId;
              } else if (!inputFormDataState?.configurationData?.hasTier5) {
                return item !== productSegmentTier5Id;
              } else {
                return item;
              }
            })
            .map((item: any) => {
              return (
                <ProductSegmentTab
                  BLOCK={BLOCK}
                  key={item}
                  productSegmentId={item}
                  tableId={
                    productSegmentsMappingWithYearlyUtilizationTableId[item]
                  }
                  baseYear={
                    inputFormDataState?.metaData?.baseYear
                      ? inputFormDataState?.metaData?.baseYear
                      : ""
                  }
                  showModal={setShowUpdateInputsPopup}
                  showUpdateInputsPopup={showUpdateInputsPopup}
                  tablesData={yearlyUtilizationTablesData}
                  setTablesData={setYearlyUtilizationTablesData}
                  compareTablesData={compareTablesData}
                  yearlyUtilizationDirtyTables={yearlyUtilizationDirtyTables}
                  setYearlyUtilizationDirtyTables={
                    setYearlyUtilizationDirtyTables
                  }
                  setSaved={setSaved}
                  yearlyUtilizationTableIdList={yearlyUtilizationTableIdList}
                />
              );
            })}
        </div>
      </div>

      {showUpdateInputsPopup && inputFormDataState.selectedCells.length > 0 && (
        <UpdateInputsModal
          setSaved={setSaved}
          showModal={setShowUpdateInputsPopup}
          selectedCells={inputFormDataState.selectedCells}
          dirtyTables={yearlyUtilizationDirtyTables}
          setDirtyTables={setYearlyUtilizationDirtyTables}
        />
      )}
    </>
  );
};

export default YearlyUtlizationTab;
