import { useState, useRef, createContext, memo } from "react";
import Table from "../shared/table/table";
import Icon from "../shared/icon/icon";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useAreaSelection } from "utils/area-selection";
import { inputValueValidation, handleEnterTable } from "utils/useInputForm";
import { updateAnnualInflationTables } from "services/inputForm/inputFormSlice";
import { PERCENTAGE_ABOVE_100_ERROR } from "utils/constants";

const SelectionContext = createContext<DOMRect | null>(null);

const AnnualInflationTable = ({
  setSaved,
  tableId,
  showModal,
  subHeadingBlock,
  baseYear,
  subTitle,
  showUpdateInputsPopup,
  revenueDirtyTables,
  setRevenueDirtyTables,
  tableData,
  compareTableData,
  setTableData,
  revenueTableIdList,
  revenueData,
}: any) => {
  const BLOCK = "headcountsTable";
  const InflationTable = true;
  const [prevValue, setPrevValue] = useState<any>("");
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const selection = useAreaSelection({
    container: selectContainerRef,
    showModal,
    showUpdateInputsPopup,
  });

  const getTableHeaders = () => {
    let dynamicTableHeaders: any =
      tableData &&
      tableData?.length > 0 &&
      Object.keys(tableData[0] || {})
        .filter(Number)
        .map((item) => {
          return {
            text: `FY${item}`,
            isFormattedText: false,
          };
        });

    const tableHeaders = dynamicTableHeaders &&
      dynamicTableHeaders.length > 0 && [
        {
          text: "revenue-pl.annual.inflation.main.subheader",
          isFormattedText: true,
        },
        ...dynamicTableHeaders,
      ];

    return tableHeaders;
  };

  const getAnnualInflationRowItems = (
    tableData: any,
    FocusChage: any,
    handleOnChange: any,
    baseYear: any,
    tableId: any,
    revenueTableIdList: any,
    revenueData: any,
    BLOCK: any,
    inputIcon: boolean = false
  ) => {
    return (
      tableData &&
      tableData.length > 0 &&
      tableData.map((item: any, index: number) => {
        let dynamicRows = Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            return {
              customIndex: id,
              textInput: true,
              error: item?.errorList?.includes(id) ? true : false,
              errorText: PERCENTAGE_ABOVE_100_ERROR,
              onBlur: (e: any) => {
                FocusChage(e.target.value, id);
              },
              onchangeHandler: (e: any) => {
                let columnId = id;
                handleOnChange(e.target.value, columnId);
              },
              onHandleEnter: (evt: any, keyIdentifier: any) => {
                setPrevValue(evt?.target?.value);
                handleEnterTable(
                  evt,
                  keyIdentifier,
                  tableData,
                  tableId,
                  revenueTableIdList,
                  baseYear,
                  revenueData
                );
              },
              placholder: "-",
              inputValue: item[id],
              inputIcon: inputIcon,
              allowNegValues: true,
            };
          });
        let tableRows = {
          id: index + 1,
          key: index,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: "revenue-pl.annual.inflation.row.name",
              isFormattedText: true,
            },
            ...dynamicRows,
          ],
        };

        return tableRows;
      })
    );
  };

  const handleOnChange = (
    inputVal: any,
    columnId: any,
    roundOff: boolean = false
  ) => {
    setPrevValue(inputVal);
    let newVal = inputValueValidation(
      inputVal,
      prevValue,
      setPrevValue,
      true,
      InflationTable
    );
    updateAnnualInflationTables(
      newVal,
      columnId,
      roundOff,
      tableData,
      setTableData,
      compareTableData,
      revenueDirtyTables,
      setRevenueDirtyTables,
      tableId,
      prevValue
    );
  };

  const FocusChage = (e: any, id: number) => {
    setSaved(false);
    handleOnChange(e, id, true);
  };

  return (
    <>
      <div data-test="annualInflation" style={{ marginTop: 30 }}>
        <div className={`${subHeadingBlock}__sub-header`}>
          <div className={`${subHeadingBlock}__sub-header--title`}>
            <FormattedMessage id={subTitle} />
          </div>
        </div>
        <SelectionContext.Provider value={selection}>
          <div
            ref={selectContainerRef}
            id={tableId}
            data-test="component-annualInflation"
          >
            {tableData && tableData.length === 0 ? (
              <div className={`${subHeadingBlock}__spinner-container`}>
                <Icon
                  name={"loading"}
                  width={50}
                  height={50}
                  className={`${subHeadingBlock}--loading`}
                />
              </div>
            ) : (
              <Table
                headerItems={getTableHeaders()}
                rowItems={getAnnualInflationRowItems(
                  tableData,
                  FocusChage,
                  handleOnChange,
                  baseYear,
                  tableId,
                  revenueTableIdList,
                  revenueData,
                  BLOCK,
                  true
                )}
                className={`${BLOCK}--innerWrapper`}
                innerClassName={BLOCK}
                selection={selection}
                tableId={tableId}
              />
            )}
          </div>
        </SelectionContext.Provider>
      </div>
    </>
  );
};

export default memo(AnnualInflationTable);
