import { useState, useEffect } from "react";
import { isEqual } from "lodash";
import {
  validateInputValueLength,
  validateInputValueLengthPercentage,
} from "services/inputForm/inputFormSlice";
import {
  isDecimalsZero,
  convertCommaSeperator,
  valueConvertor,
} from "../components/shared/commons/globalFunctions";
import {
  ClosingPositions,
  productSegmentsMapping,
  deployementTableReference,
  PERCENTAGE_ABOVE_100_ERROR,
  TABLE_MAPPINGS,
  MAX_PERCENTAGE_LENGTH,
  MAX_NON_PERCENTAGE_LENGTH,
  overHeadTypedIdOrderList,
  INTERM_RESULT_MAPPING,
  yearlyUtilizationTableIdList,
  revenueTableIdList,
  productSegmentsMappingWithYearlyUtilizationTableId,
} from "utils/constants";

export const useInputForm = (inputFormDataState: any) => {
  const [submitConfiguration, setSubmitConfiguration] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      Object.keys(inputFormDataState.configurationDataSubmit).length !== 0 &&
      inputFormDataState.configurationDataSubmit.currency !== null &&
      inputFormDataState.configurationDataSubmit.currency?.currencyCode !==
        null &&
      inputFormDataState.configurationDataSubmit.annualUtilisationHours !==
        null &&
      inputFormDataState.configurationDataSubmit.currency?.name !== null &&
      inputFormDataState.configurationDataSubmit.hasOther !== null &&
      inputFormDataState.configurationDataSubmit.hasTier5 !== null
    ) {
      setSubmitConfiguration(true);
    } else {
      setSubmitConfiguration(false);
    }
  }, [
    inputFormDataState.configurationData,
    inputFormDataState?.metaData?.planSubmissionId,
    inputFormDataState.configurationDataSubmit,
  ]);

  return submitConfiguration;
};

export const useEnableInputForm = (inputFormDataState: any) => {
  const [enableInputFormSteps, setEnableInputFormSteps] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      Object.keys(inputFormDataState.configurationData).length !== 0 &&
      inputFormDataState.configurationData.currency !== null &&
      inputFormDataState.configurationData.currency?.currencyCode !== null &&
      inputFormDataState.configurationData.currency?.name !== null &&
      inputFormDataState.configurationData.hasOther !== null &&
      inputFormDataState.configurationData.hasTier5 !== null
    ) {
      setEnableInputFormSteps(true);
    } else {
      setEnableInputFormSteps(false);
    }
  }, [
    inputFormDataState.configurationData,
    inputFormDataState?.metaData?.planSubmissionId,
    inputFormDataState.configurationDataSubmit,
  ]);

  return enableInputFormSteps;
};

export const useCheckTableBottomVisible = (
  selectContainerRef: any,
  tableScrollIds: any,
  setTableScrolledIds: any
) => {
  const [tableId, setTableId] = useState<any>();
  const handleScroll = () => {
    const element = selectContainerRef.current;
    if (!element) return;
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const isBottomInView = rect.bottom <= windowHeight;
    if (isBottomInView) {
      setTableId(element?.id);
      removeScrollListener();
    }
  };
  const addScrollListener = () => {
    window.addEventListener("scroll", handleScroll, true);
  };
  const removeScrollListener = () => {
    window.removeEventListener("scroll", handleScroll, true);
  };
  useEffect(() => {
    tableId && setTableScrolledIds(Number(tableId));
  }, [tableScrollIds, tableId]);

  useEffect(() => {
    addScrollListener();
    return () => {
      removeScrollListener();
    };
  }, []);
};

export const inputValueValidation = (
  inputVal: any,
  prevValue: any,
  setState: any,
  percentageField: boolean = false,
  tableWithTripleDigitNegativeNumber = false
) => {
  let subStringMaxLength =
    !tableWithTripleDigitNegativeNumber && percentageField
      ? MAX_PERCENTAGE_LENGTH
      : tableWithTripleDigitNegativeNumber &&
        percentageField &&
        inputVal.includes("-")
      ? MAX_PERCENTAGE_LENGTH + 1
      : tableWithTripleDigitNegativeNumber && percentageField
      ? MAX_PERCENTAGE_LENGTH
      : inputVal.includes("-")
      ? MAX_NON_PERCENTAGE_LENGTH + 1
      : MAX_NON_PERCENTAGE_LENGTH;

  let newVal: string = inputVal;

  if (inputVal === "-") {
    newVal = "-";
  } else if (inputVal === ".") {
    newVal = ".";
  } else if (inputVal === "-.") {
    newVal = "-.";
  } else if (isNaN(inputVal.replace(/,/g, ""))) {
    setState(prevValue.replace(/,/g, ""));
    newVal = prevValue.replace(/,/g, "");
  } else if (inputVal && inputVal.replace(/,/g, "").split(".").length > 1) {
    let numberBeforeDecimal = inputVal.replace(/,/g, "")?.split(".")?.[0];

    if (numberBeforeDecimal?.length > subStringMaxLength) {
      setState(prevValue.replace(/,/g, ""));
      newVal = prevValue.replace(/,/g, "");
    }
  } else if (
    inputVal &&
    inputVal?.replace(/,/g, "")?.length > subStringMaxLength
  ) {
    setState(prevValue.replace(/,/g, ""));
    newVal = prevValue.replace(/,/g, "");
  }

  return newVal;
};

export const checkConfigurationDirty = (
  compareData: any,
  originalData: any,
  setDirty: any
) => {
  if (!isEqual(compareData, originalData)) {
    setDirty(true);
  } else {
    setDirty(false);
  }
};

export const setDirtyTables = (
  compareTableData: any,
  newTableValues: any,
  dirtyTableList: any,
  setDirtyTableList: any,
  tableId: any,
  isDisabled: boolean = false
) => {
  let tableVals: any = [];

  newTableValues.map((item: any) => {
    if (isDisabled) {
      const {
        error,
        errorList,
        errorToastNotRequired,
        disabled,
        ...newObject
      } = item;
      tableVals.push(newObject);
    } else {
      const { error, errorList, errorToastNotRequired, ...newObject } = item;
      tableVals.push(newObject);
    }
  });

  let newTableData: any = [];
  tableVals.map((item: any) => {
    const newObject: any = {};
    for (const key in item) {
      if (!key.includes("error")) {
        newObject[key] = item[key];
      }
    }
    newTableData.push(newObject);
  });
  if (!isEqual(compareTableData, newTableData)) {
    if (!dirtyTableList.includes(tableId)) {
      setDirtyTableList((prevVal: Number[]) => {
        return [...prevVal, tableId];
      });
    }
  } else {
    if (dirtyTableList.includes(tableId)) {
      setDirtyTableList((prevVal: Number[]) => {
        return prevVal.filter((ids: any) => ids !== tableId);
      });
    }
  }
};

export const updateCostAdoptionTableData = (
  leverId: any,
  inputVal: any,
  roundOff: any,
  tableData: any,
  setTableData: any,
  compareTableData: any,
  liberatedHoursDirtyTables: any,
  setLiberatedHoursDirtyTables: any,
  tableId: any,
  setPrevValue: any,
  prevValue: any = "",
  validatePercentage: boolean = false,
  dropDownSelected: any = 1
) => {
  let newVal = inputValueValidation(
    inputVal,
    prevValue,
    setPrevValue,
    validatePercentage
  );
  let value: string;

  if (roundOff) {
    value = validatePercentage
      ? validateInputValueLengthPercentage(newVal, prevValue, roundOff)
      : validateInputValueLength(newVal, prevValue, roundOff);
  } else {
    value = validatePercentage
      ? validateInputValueLengthPercentage(newVal, prevValue)
      : validateInputValueLength(newVal, prevValue);
  }

  let newTableValues =
    tableData &&
    tableData?.length > 0 &&
    tableData?.map((item: any) => {
      if (item.leverId === leverId) {
        delete item.errorToastNotRequired;
        if (roundOff && Number(value) > 100) {
          return {
            ...item,
            value: null,
            error: true,
          };
        } else {
          return {
            ...item,
            value: value,
            error: false,
          };
        }
      }
      return item;
    });

  setTableData((prevVal: any) => {
    return {
      ...prevVal,
      [productSegmentsMapping[dropDownSelected]]: newTableValues,
    };
  });
  setDirtyTables(
    compareTableData,
    newTableValues,
    liberatedHoursDirtyTables,
    setLiberatedHoursDirtyTables,
    tableId
  );
};
export const updateDeployementTableData = (
  leverId: any,
  year: number,
  inputVal: any,
  roundOff: any,
  tableData: any,
  setTableData: any,
  compareTableData: any,
  liberatedHoursDirtyTables: any,
  setLiberatedHoursDirtyTables: any,
  tableId: any,
  setPrevValue: any,
  prevValue: any = "",
  validatePercentage: boolean = false,
  dropDownSelected: any = 1
) => {
  let newVal = inputValueValidation(
    inputVal,
    prevValue,
    setPrevValue,
    validatePercentage
  );

  let value: string;

  if (roundOff) {
    value = validatePercentage
      ? validateInputValueLengthPercentage(newVal, prevValue, roundOff)
      : validateInputValueLength(newVal, prevValue, roundOff);
  } else {
    value = validatePercentage
      ? validateInputValueLengthPercentage(newVal, prevValue)
      : validateInputValueLength(newVal, prevValue);
  }
  let newTableValues =
    tableData &&
    tableData?.length > 0 &&
    tableData?.map((item: any) => {
      if (item.leverId === leverId && item.year === year) {
        delete item.errorToastNotRequired;
        if (roundOff && Number(value) >= 0 && Number(value) > 100) {
          return {
            ...item,
            value: null,
            error: true,
          };
        } else {
          return {
            ...item,
            value: value,
            error: false,
          };
        }
      }
      return item;
    });
  setTableData((prevVal: any) => {
    return {
      ...prevVal,
      [productSegmentsMapping[dropDownSelected]]: newTableValues,
    };
  });
  const filterByLeverId = (data: any) => {
    return data.filter(
      (item: any) =>
        item.leverId == deployementTableReference[0].leverId ||
        item.leverId == deployementTableReference[1].leverId
    );
  };

  setDirtyTables(
    filterByLeverId(compareTableData),
    filterByLeverId(newTableValues),
    liberatedHoursDirtyTables,
    setLiberatedHoursDirtyTables,
    tableId
  );
};

export const updateTables = (
  gradeLevelId: any,
  inputVal: any,
  columnId: any,
  roundOff: any,
  tableData: any,
  setTableData: any,
  compareTableData: any,
  dirtyTable: any,
  setDirtyTable: any,
  tableId: any,
  setPrevValue: any,
  prevValue: any = "",
  validatePercentage: boolean = false,
  fiscalYear: any = null
) => {
  let newVal = inputValueValidation(
    inputVal,
    prevValue,
    setPrevValue,
    validatePercentage
  );
  let value: string;
  if (roundOff) {
    value = validatePercentage
      ? validateInputValueLengthPercentage(newVal, prevValue, roundOff)
      : validateInputValueLength(newVal, prevValue, roundOff);
  } else {
    value = validatePercentage
      ? validateInputValueLengthPercentage(newVal, prevValue)
      : validateInputValueLength(newVal, prevValue);
  }
  let newTableValues =
    tableData &&
    tableData?.length > 0 &&
    tableData.map((item: any) => {
      if (item.gradeLevelId === gradeLevelId) {
        if (fiscalYear) {
          if (item.year === fiscalYear) {
            return {
              ...item,
              [columnId]: value,
            };
          }
        } else {
          return {
            ...item,
            [columnId]: value,
          };
        }
      }
      return item;
    });
  setTableData(newTableValues);
  setDirtyTables(
    compareTableData,
    newTableValues,
    dirtyTable,
    setDirtyTable,
    tableId
  );
};

export function filterTablesByProductSegmentId(
  tablesData: any,
  productSegmentId: any
) {
  return tablesData.filter(
    (data: any) => data.productSegmentId == productSegmentId
  );
}

export const updateyearlyUtilizationTables = (
  gradeLevelId: any,
  inputVal: any,
  columnId: any,
  roundOff: any,
  tablesData: any,
  setTablesData: any,
  compareTablesData: any,
  dirtyTable: any,
  setDirtyTable: any,
  productSegmentId: any,
  tableId: any,
  setPrevValue: any,
  prevValue: any = "",
  validatePercentage: boolean = false,
  toggle: boolean = true
) => {
  let value: string;
  if (roundOff) {
    value = validatePercentage
      ? validateInputValueLengthPercentage(inputVal, prevValue, roundOff)
      : validateInputValueLength(inputVal, prevValue, roundOff);
  } else {
    value = validatePercentage
      ? validateInputValueLengthPercentage(inputVal, prevValue)
      : validateInputValueLength(inputVal, prevValue);
  }
  let newTablesValues =
    tablesData &&
    tablesData?.length > 0 &&
    tablesData.map((item: any) => {
      if (
        item.productSegmentId == productSegmentId &&
        item.gradeLevelId === gradeLevelId
      ) {
        if (roundOff && !toggle) {
          let yearsList: any = Object.keys(item || {})
            .filter(Number)
            .slice(1);
          for (let year of yearsList) {
            if (item[year] !== null) {
              return {
                ...item,
                [columnId]: value,
              };
            }
          }
          let updatedItem = { ...item };
          for (let year of yearsList) {
            updatedItem[year] = value;
          }
          updatedItem[columnId] = value;
          return updatedItem;
        } else {
          return {
            ...item,
            [columnId]: value,
          };
        }
      }
      return item;
    });
  setTablesData(newTablesValues);
  setDirtyTables(
    filterTablesByProductSegmentId(compareTablesData, productSegmentId),
    filterTablesByProductSegmentId(newTablesValues, productSegmentId),
    dirtyTable,
    setDirtyTable,
    tableId,
    true
  );
};

export const revenueUpdateTables = (
  segmentId: any,
  inputVal: any,
  columnId: any,
  roundOff: any,
  tableData: any,
  setTableData: any,
  compareTableData: any,
  dirtyTable: any,
  setDirtyTable: any,
  tableId: any,
  prevValue: any = "",
  validatePercentage: boolean = false,
  overHeadTypeId: any = null
) => {
  let value: string;
  if (roundOff) {
    value = validatePercentage
      ? validateInputValueLengthPercentage(inputVal, prevValue, roundOff)
      : validateInputValueLength(inputVal, prevValue, roundOff);
  } else {
    value = validatePercentage
      ? validateInputValueLengthPercentage(inputVal, prevValue)
      : validateInputValueLength(inputVal, prevValue);
  }
  let newTableValues =
    tableData &&
    tableData?.length > 0 &&
    tableData.map((item: any) => {
      if (
        overHeadTypeId
          ? item.overheadTypeId === overHeadTypeId
            ? true
            : false
          : true
      ) {
        if (item.productSegmentId === segmentId) {
          return {
            ...item,
            [columnId]: value,
          };
        }
      }
      return item;
    });
  setTableData(newTableValues);
  setDirtyTables(
    compareTableData,
    newTableValues,
    dirtyTable,
    setDirtyTable,
    tableId
  );
};

export const getRevenueRowItems = (
  tableData: any,
  FocusChage: any,
  handleOnChange: any,
  baseYear: any,
  tableId: any,
  revenueTableIdList: any,
  revenueData: any,
  BLOCK: any,
  setPrevValue: any,
  inputIcon: boolean = false
) => {
  return (
    tableData &&
    tableData.length > 0 &&
    tableData.map((item: any, index: number) => {
      let dynamicRows = Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          return {
            customIndex: id,
            textInput: true,
            onBlur: (e: any) => {
              FocusChage(e.target.value, item.productSegmentId, id);
            },
            onchangeHandler: (e: any) => {
              let columnId = id;
              handleOnChange(e.target.value, item.productSegmentId, columnId);
            },
            onHandleEnter: (evt: any, keyIdentifier: any) => {
              setPrevValue(evt?.target?.value);
              handleEnterTable(
                evt,
                keyIdentifier,
                tableData,
                tableId,
                revenueTableIdList,
                baseYear,
                revenueData
              );
            },
            placholder: "-",
            inputValue: item[id],
            inputDisable: id === `${baseYear}` ? true : false,
            inputIcon: inputIcon,
          };
        });
      let tableRows = {
        id: item.productSegmentId,
        key: `${item.productSegmentId}-${index}`,
        className: `${BLOCK}__row`,
        columnItems: [
          {
            text: item.productSegment,
          },
          ...dynamicRows,
        ],
      };

      return tableRows;
    })
  );
};
const findExpandItemId = (
  expandItems: any,
  currentProductSegmentId: number
) => {
  let sortedExpandItems = expandItems?.map((x: number) => x).sort();
  let nextExpendedItem: number = 0;
  for (let i: number = 0; i <= 6; i++) {
    if (sortedExpandItems[i] >= currentProductSegmentId) {
      nextExpendedItem = sortedExpandItems[i];
      break;
    }
  }
  if (nextExpendedItem == 0) {
    nextExpendedItem = sortedExpandItems[0];
  }
  return nextExpendedItem;
};

const findNextTableFirstIndex = (
  tableId: any,
  tableIndex: any,
  sectionTableData: any,
  tableList: any,
  baseYear: any,
  expandItems: any
) => {
  let index: any;
  let nextTableData: any;
  switch (tableId) {
    case 62:
      let nextExpendedItem_omnia = findExpandItemId(expandItems, 3);
      nextTableData = sectionTableData?.filter(
        (data: any) => data.productSegmentId == nextExpendedItem_omnia
      );
      index = `${
        productSegmentsMappingWithYearlyUtilizationTableId[
          nextExpendedItem_omnia
        ]
      }#${nextTableData?.[0]?.gradeLevelId}#${
        Object.keys(nextTableData?.[0]).filter(Number)[0]
      }`;
      break;
    case 63:
      let nextExpendedItem_levvia = findExpandItemId(expandItems, 4);
      nextTableData = sectionTableData?.filter(
        (data: any) => data.productSegmentId == nextExpendedItem_levvia
      );
      index = `${
        productSegmentsMappingWithYearlyUtilizationTableId[
          nextExpendedItem_levvia
        ]
      }#${nextTableData?.[0]?.gradeLevelId}#${
        Object.keys(nextTableData?.[0]).filter(Number)[0]
      }`;
      break;
    case 64:
      let nextExpendedItem_tier5 = findExpandItemId(expandItems, 5);
      nextTableData = sectionTableData?.filter(
        (data: any) => data.productSegmentId == nextExpendedItem_tier5
      );
      index = `${
        productSegmentsMappingWithYearlyUtilizationTableId[
          nextExpendedItem_tier5
        ]
      }#${nextTableData?.[0]?.gradeLevelId}#${
        Object.keys(nextTableData?.[0]).filter(Number)[0]
      }`;
      break;

    case 65:
      let nextExpendedItem_assurance = findExpandItemId(expandItems, 6);
      nextTableData = sectionTableData?.filter(
        (data: any) => data.productSegmentId == nextExpendedItem_assurance
      );
      index = `${
        productSegmentsMappingWithYearlyUtilizationTableId[
          nextExpendedItem_assurance
        ]
      }#${nextTableData?.[0]?.gradeLevelId}#${
        Object.keys(nextTableData?.[0]).filter(Number)[0]
      }`;
      break;
    case 66:
      let nextExpendedItem_other = findExpandItemId(expandItems, 2);
      nextTableData = sectionTableData?.filter(
        (data: any) => data.productSegmentId == nextExpendedItem_other
      );
      index = `${
        productSegmentsMappingWithYearlyUtilizationTableId[
          nextExpendedItem_other
        ]
      }#${nextTableData?.[0]?.gradeLevelId}#${
        Object.keys(nextTableData?.[0]).filter(Number)[0]
      }`;
      break;

    case 16:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.salaryPriceIncrease?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.salaryPriceIncrease?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 8:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.fullTimeEquavalents?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.fullTimeEquavalents?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 10:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.attritionRatePerGrade?.[0]?.gradeLevelId
      }#${baseYear + 1}`;
      break;
    case 12:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.promotionRatePerGrade?.[0]?.gradeLevelId
      }#${baseYear + 1}`;
      break;
    case 26:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.targetManagedHours?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.targetManagedHours?.[0]).filter(Number)[0]
      }#${baseYear}`;
      break;
    case 20:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.usiRateCard?.[0]?.gradeLevelId
      }#${Object.keys(sectionTableData?.usiRateCard?.[0]).filter(Number)[0]}`;
      break;
    case 181:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.radcRateCard?.[0]?.gradeLevelId
      }#${Object.keys(sectionTableData?.radcRateCard?.[0]).filter(Number)[0]}`;
      break;
    case 182:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.localDCRateCard?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.localDCRateCard?.[0]).filter(Number)[0]
      }`;
      break;
    case 183:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.localCOERateCard?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.localCOERateCard?.[0]).filter(Number)[0]
      }`;
      break;
    case 184:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.usiPriceIncreaseRates?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.usiPriceIncreaseRates?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 241:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.radcPriceIncreaseRates?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.radcPriceIncreaseRates?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 242:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.localDCPriceIncreaseRates?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.localDCPriceIncreaseRates?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 243:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.localCOEPriceIncreaseRates?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.localCOEPriceIncreaseRates?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 244:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.usiAnnualTotalHours?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.usiAnnualTotalHours?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 281:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.radcAnnualTotalHours?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.radcAnnualTotalHours?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 282:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.localDCAnnualTotalHours?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.localDCAnnualTotalHours?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 283:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.localCOEAnnualTotalHours?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.localCOEAnnualTotalHours?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 284:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.usiUtilisationRates?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.usiUtilisationRates?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 191:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.radcUtilisationRates?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.radcUtilisationRates?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 192:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.localDCUtilisationRates?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.localDCUtilisationRates?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 193:
      index = `${tableList[tableIndex + 1]}#${
        sectionTableData?.localCOEUtilisationRates?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.localCOEUtilisationRates?.[0]).filter(
          Number
        )[0]
      }`;
      break;
    case 194:
      index = `${tableList[0]}#${
        sectionTableData?.avgAnnualFTESalary?.[0]?.gradeLevelId
      }#${
        Object.keys(sectionTableData?.avgAnnualFTESalary?.[0]).filter(Number)[0]
      }`;
      break;
    case TABLE_MAPPINGS.fy23Rev:
      index = `${TABLE_MAPPINGS.annualinflation}#1#${baseYear}`;
      break;
    case TABLE_MAPPINGS.annualinflation:
      let pandLKeyIdentifierList: any = [];
      getLocalKeyIdentifier(
        sectionTableData?.profitsAndLossesTableData,
        TABLE_MAPPINGS.plCosts,
        pandLKeyIdentifierList,
        baseYear
      );
      index = pandLKeyIdentifierList[0];
      break;
    case TABLE_MAPPINGS.plCosts:
      index = `${TABLE_MAPPINGS.winsNetRev}#${
        sectionTableData?.winsNetRevenueData?.[0]?.productSegmentId
      }#${baseYear + 1}`;
      break;
    case TABLE_MAPPINGS.winsNetRev:
      index = `${TABLE_MAPPINGS.lossesNetRev}#${
        sectionTableData?.lossesNetRevenueData?.[0]?.productSegmentId
      }#${baseYear + 1}`;
      break;
    case TABLE_MAPPINGS.lossesNetRev:
      index = `${TABLE_MAPPINGS.exitProgramme}#${1}#${1}`;
      break;
    case TABLE_MAPPINGS.exitProgramme:
      index = `${TABLE_MAPPINGS.feePerExit}#${
        sectionTableData?.feePerExitData?.[0].productSegmentId
      }#${1}`;
      break;
    case TABLE_MAPPINGS.feePerExit:
      index = `${TABLE_MAPPINGS.exitPhasing}#${
        sectionTableData?.exitPhasingData?.[0].productSegmentId
      }#${baseYear + 1}`;
      break;
    case TABLE_MAPPINGS.exitPhasing:
      index = `${TABLE_MAPPINGS.revPriceIncrease}#${
        sectionTableData?.revenuePriceIncrease?.[0].productSegmentId
      }#${baseYear + 1}`;
      break;
    case TABLE_MAPPINGS.revPriceIncrease:
      index = `${TABLE_MAPPINGS.increaseHoursDeliver}#${
        sectionTableData?.increaseInHoursData?.[0].productSegmentId
      }#${1}`;
      break;
    case TABLE_MAPPINGS.increaseHoursDeliver:
      index = `${TABLE_MAPPINGS.fy23Rev}#${
        sectionTableData?.revenuePnlData?.[0].productSegmentId
      }#${1}`;
      break;

    default:
      break;
  }

  return index;
};

const getLocalKeyIdentifier = (
  tableData: any,
  tableId: any,
  localKeyIdentifier: any,
  baseYear: any
) => {
  switch (tableId) {
    case TABLE_MAPPINGS.fy23Rev:
      tableData?.map((item: any) => {
        localKeyIdentifier.push(`${tableId}#${item.productSegmentId}#${1}`);
      });
      tableData?.map((item: any) => {
        localKeyIdentifier.push(`${tableId}#${item.productSegmentId}#${2}`);
      });
      break;
    case TABLE_MAPPINGS.annualinflation:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            localKeyIdentifier.push(`${tableId}#1#${id}`);
          });
      });
      break;
    case TABLE_MAPPINGS.plCosts:
      overHeadTypedIdOrderList.map((overheadTypeId: any) => {
        tableData?.map((item: any) => {
          Object.keys(item || {})
            .filter(Number)
            .map((id: any) => {
              if (
                item.overheadTypeId === overheadTypeId &&
                !item?.disabled[id]
              ) {
                localKeyIdentifier.push(
                  `${tableId}#${item.overheadTypeId}#${item.productSegmentId}#${id}`
                );
              }
            });
        });
      });
      break;
    case TABLE_MAPPINGS.winsNetRev:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            id !== baseYear &&
              localKeyIdentifier.push(
                `${tableId}#${item.productSegmentId}#${id}`
              );
          });
      });
      break;
    case TABLE_MAPPINGS.attrition:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            id !== baseYear &&
              localKeyIdentifier.push(`${tableId}#${item.gradeLevelId}#${id}`);
          });
      });
      break;
    case TABLE_MAPPINGS.promotion:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            id !== baseYear &&
              localKeyIdentifier.push(`${tableId}#${item.gradeLevelId}#${id}`);
          });
      });
      break;
    case TABLE_MAPPINGS.lossesNetRev:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            id !== baseYear &&
              localKeyIdentifier.push(
                `${tableId}#${item.productSegmentId}#${id}`
              );
          });
      });
      break;
    case TABLE_MAPPINGS.exitProgramme:
      tableData?.map((item: any) => {
        localKeyIdentifier.push(`${tableId}#${item.overheadTypeId}#${1}`);
      });
      break;
    case TABLE_MAPPINGS.feePerExit:
      tableData?.map((item: any) => {
        localKeyIdentifier.push(`${tableId}#${item.productSegmentId}#${1}`);
      });
      break;
    case TABLE_MAPPINGS.exitPhasing:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            id !== baseYear &&
              localKeyIdentifier.push(
                `${tableId}#${item.productSegmentId}#${id}`
              );
          });
      });
      break;
    case TABLE_MAPPINGS.revPriceIncrease:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            id !== baseYear &&
              localKeyIdentifier.push(
                `${tableId}#${item.productSegmentId}#${id}`
              );
          });
      });
      break;
    case TABLE_MAPPINGS.increaseHoursDeliver:
      tableData?.map((item: any) => {
        localKeyIdentifier.push(`${tableId}#${item.productSegmentId}#${1}`);
      });
      break;
    case TABLE_MAPPINGS.omniaYearlyUtilization:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            id !== baseYear &&
              (!item?.disabled || !item?.disabled[id]) &&
              localKeyIdentifier.push(`${tableId}#${item.gradeLevelId}#${id}`);
          });
      });
      break;
    case TABLE_MAPPINGS.LevviaYearlyUtilization:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            id !== baseYear &&
              (!item?.disabled || !item?.disabled[id]) &&
              localKeyIdentifier.push(`${tableId}#${item.gradeLevelId}#${id}`);
          });
      });
      break;
    case TABLE_MAPPINGS.Tier5YearlyUtilization:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            id !== baseYear &&
              (!item?.disabled || !item?.disabled[id]) &&
              localKeyIdentifier.push(`${tableId}#${item.gradeLevelId}#${id}`);
          });
      });
      break;
    case TABLE_MAPPINGS.AssuranceYearlyUtilization:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            id !== baseYear &&
              (!item?.disabled || !item?.disabled[id]) &&
              localKeyIdentifier.push(`${tableId}#${item.gradeLevelId}#${id}`);
          });
      });
      break;
    case TABLE_MAPPINGS.OtherYearlyUtilization:
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            id !== baseYear &&
              (!item?.disabled || !item?.disabled[id]) &&
              localKeyIdentifier.push(`${tableId}#${item.gradeLevelId}#${id}`);
          });
      });
      break;

    default:
      break;
  }
};

export const handleEnterTable = (
  evt: any,
  keyIdentifier: any,
  tableData: any,
  tableId: any,
  tableList: any,
  baseYear: any,
  sectionTableData: any,
  expandItems: any = []
) => {
  if (evt.key === "Enter") {
    let localKeyIdentifier: any = [];
    let ratePerGradeIds: any = [12, 26];
    let tableIndex = tableList.findIndex((item: any) => item === tableId);

    if (revenueTableIdList.includes(tableId)) {
      getLocalKeyIdentifier(tableData, tableId, localKeyIdentifier, baseYear);
    } else if (yearlyUtilizationTableIdList.includes(tableId)) {
      getLocalKeyIdentifier(tableData, tableId, localKeyIdentifier, baseYear);
    } else {
      tableData?.map((item: any) => {
        Object.keys(item || {})
          .filter(Number)
          .map((id: any) => {
            localKeyIdentifier.push(
              `${tableId}#${item.gradeLevelId}#${id}${
                tableId === 20 ? `#${item.year}` : ""
              }`
            );
          });
      });
    }
    localKeyIdentifier.sort((a: any, b: any) => {
      return (
        a.split("#")[tableId === TABLE_MAPPINGS.plCosts ? 3 : 2] -
        b.split("#")[tableId === TABLE_MAPPINGS.plCosts ? 3 : 2]
      );
    });

    let index = localKeyIdentifier.findIndex(
      (item: any) => item === keyIdentifier
    );
    let element;
    if (index > -1) {
      element = document.getElementById(localKeyIdentifier[index + 1]);
    }

    if (!element) {
      const nextTableFirstIndex = findNextTableFirstIndex(
        tableId,
        tableIndex,
        sectionTableData,
        tableList,
        baseYear,
        expandItems
      );
      document.getElementById(nextTableFirstIndex)?.focus();
    } else {
      element?.focus();
    }
  }
};

export const getHeadCountsRowItems = (
  tableData: any,
  FocusChage: any,
  handleOnChange: any,
  BLOCK: any,
  headCountsTableGradeLevels: any,
  baseYear: any,
  tableId: any,
  headCountTableIdList: any,
  headCountsData: any = {},
  setPrevValue: any,
  { showPercentageInsideInputIcon, allowNegValues }: any = {
    showPercentageInsideInputIcon: false,
    allowNegValues: false,
  },
  addFiscalYear: boolean = false
) => {
  return (
    tableData &&
    tableData?.length > 0 &&
    tableData.map((item: any, index: number) => {
      let dynamicRows = Object.keys(item || {})
        .filter(Number)
        .map((id) => {
          return {
            customIndex: item?.year ? id + "#" + item?.year : id,
            textInput: true,
            onBlur: (e: any) => {
              FocusChage(e.target.value, item.gradeLevelId, id, item.year);
            },
            onchangeHandler: (e: any) => {
              let columnId = id;
              handleOnChange(
                item.gradeLevelId,
                e.target.value,
                columnId,
                item.year
              );
            },
            onHandleEnter: (evt: any, keyIdentifier: any) => {
              setPrevValue(evt?.target?.value);
              handleEnterTable(
                evt,
                keyIdentifier,
                tableData,
                tableId,
                headCountTableIdList,
                baseYear,
                headCountsData
              );
            },
            placholder: "-",
            inputValue: item[id],
            inputDisable: false,
            inputIcon: showPercentageInsideInputIcon,
            allowNegValues: allowNegValues,
          };
        });
      let fiscalYearObj = {
        customIndex: item?.year,
        textInput: true,
        placholder: "-",
        inputValue: item["year"] ? "FY" + item["year"] : "FY__",
        inputDisable: true,
        inputIcon: showPercentageInsideInputIcon,
      };
      let tableRows = {
        id: item.gradeLevelId,
        key: `${item.gradeLevelId}-${index}`,
        className: `${BLOCK}__row`,
        columnItems: [
          {
            text: headCountsTableGradeLevels[item.gradeLevelId],
          },
          ...(addFiscalYear ? [fiscalYearObj] : []),
          ...dynamicRows,
        ],
      };
      return tableRows;
    })
  );
};

export const getYearlyUtilizationRowItems = (
  tablesData: any,
  FocusChange: any,
  handleOnChange: any,
  BLOCK: any,
  headCountsTableGradeLevels: any,
  baseYear: any,
  productSegmentId: any,
  tableId: any,
  yearlyUtilizationTableIdList: any,
  setPrevValue: any,
  { showPercentageInsideInputIcon, allowNegValues }: any = {
    showPercentageInsideInputIcon: false,
    allowNegValues: false,
  },
  addFiscalYear: boolean = false,
  expandItems: any = []
) => {
  const tableData = filterTablesByProductSegmentId(
    tablesData,
    productSegmentId
  );
  return (
    tableData &&
    tableData?.length > 0 &&
    tableData.map((item: any, index: number) => {
      let dynamicRows = Object.keys(item || {})
        .filter(Number)
        .map((id) => {
          return {
            customIndex: item?.year ? id + "#" + item?.year : id,
            textInput: true,
            onBlur: (e: any) => {
              FocusChange(e.target.value, item.gradeLevelId, id, item.year);
            },
            onchangeHandler: (e: any) => {
              let columnId = id;
              handleOnChange(
                item.gradeLevelId,
                e.target.value,
                columnId,
                item.year
              );
            },
            onHandleEnter: (evt: any, keyIdentifier: any) => {
              setPrevValue(evt?.target?.value);
              handleEnterTable(
                evt,
                keyIdentifier,
                tableData,
                tableId,
                yearlyUtilizationTableIdList,
                baseYear,
                tablesData,
                expandItems
              );
            },
            placholder: "-",
            inputValue: item[id],
            inputIcon: showPercentageInsideInputIcon,
            allowNegValues: allowNegValues,
            inputDisable:
              Object.keys(item?.disabled || {}).length > 0 && item?.disabled[id]
                ? true
                : false,
          };
        });
      let fiscalYearObj = {
        customIndex: item?.year,
        textInput: true,
        placholder: "-",
        inputValue: item["year"] ? "FY" + item["year"] : "FY__",
        inputDisable: true,
        inputIcon: showPercentageInsideInputIcon,
      };
      let tableRows = {
        id: item.gradeLevelId,
        key: `${item.gradeLevelId}-${index}`,
        className: `${BLOCK}__row`,
        columnItems: [
          {
            text: headCountsTableGradeLevels[item.gradeLevelId],
          },
          ...(addFiscalYear ? [fiscalYearObj] : []),
          ...dynamicRows,
        ],
      };
      return tableRows;
    })
  );
};

export const ratePerGradeRowItems = (
  deleteErrors: any,
  tableData: any,
  FocusChage: any,
  handleOnChange: any,
  BLOCK: any,
  headCountsTableGradeLevels: any,
  tableIdMapping: any,
  baseYear: any,
  headCountTableIdList: any,
  headCountsData: any,
  tableId: any,
  setPrevValue: any
) => {
  return (
    deleteErrors &&
    tableData?.length > 0 &&
    tableData?.map((item: any, index: number) => {
      let dynamicRows = Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          if (id) {
            return {
              customIndex: id,
              textInput: true,
              error: item["error" + id],
              errorText: PERCENTAGE_ABOVE_100_ERROR,
              errorToastNotRequired: item?.errorToastNotRequired ? true : false,
              onBlur: (e: any) => {
                FocusChage(e.target.value, item.gradeLevelId, id);
              },
              onchangeHandler: (e: any) => {
                let columnId = id;
                handleOnChange(e.target.value, item.gradeLevelId, columnId);
              },
              onHandleEnter: (evt: any, keyIdentifier: any) => {
                setPrevValue(evt?.target?.value);
                handleEnterTable(
                  evt,
                  keyIdentifier,
                  tableData,
                  tableId,
                  headCountTableIdList,
                  baseYear,
                  headCountsData
                );
              },
              placholder: "-",
              inputIcon: true,
              inputValue: item[id],
            };
          }
        });
      let tableRows = {
        id: item.gradeLevelId,
        key: `${item.gradeLevelId}-${index}`,
        className: `${BLOCK}__row`,
        columnItems: [
          {
            text: headCountsTableGradeLevels[item.gradeLevelId],
          },
          ...dynamicRows,
        ],
      };
      return tableRows;
    })
  );
};

export const ratePerGradeTableUpdate = (
  gradeLevelId: number,
  inputVal: any,
  roundOff: boolean = false,
  columnId: any,
  tableData: any,
  setTableData: any,
  compareTableData: any,
  dirtyTable: any,
  setDirtyTable: any,
  tableId: any,
  validatePercentage: boolean = false,
  // ratePerGrade: string,
  setPrevValue: any,
  prevValue: any
) => {
  let newVal = inputValueValidation(
    inputVal,
    prevValue,
    setPrevValue,
    validatePercentage
  );
  let value: string;
  if (roundOff) {
    value = validatePercentage
      ? validateInputValueLengthPercentage(newVal, prevValue, roundOff)
      : validateInputValueLength(newVal, prevValue, roundOff);
  } else {
    value = validatePercentage
      ? validateInputValueLengthPercentage(newVal, prevValue)
      : validateInputValueLength(newVal, prevValue);
  }
  let newTableValues =
    tableData?.length > 0 &&
    tableData?.map((item: any) => {
      if (item.gradeLevelId === gradeLevelId) {
        if (roundOff && Number(value) > 100) {
          delete item.errorToastNotRequired;
          return {
            ...item,
            [columnId]: null,
            ["error" + columnId]: true,
          };
        } else {
          return {
            ...item,
            [columnId]: value,
            ["error" + columnId]: false,
          };
        }
      }
      return item;
    });
  setTableData(newTableValues);
  setDirtyTables(
    compareTableData,
    newTableValues,
    dirtyTable,
    setDirtyTable,
    tableId
  );
};

export const updateTransformationTables = (
  dataIndex: any,
  inputVal: any,
  columnId: any,
  allowNegValues: boolean,
  roundOff: boolean,
  tableData: any,
  setTransformationAssumptionsTableData: any,
  transformationAssumptionsTableDataCompare: any,
  transformationDirtyTables: any,
  setTransformationDirtyTables: any,
  selectedTab: any,
  year: any,
  setPrevValue: any,
  prevValue: any
) => {
  let validatePercentage: boolean;
  if (
    columnId == ClosingPositions.Free_Efficiency_Improvement ||
    columnId == ClosingPositions.Levvia_Efficiency_Improvement ||
    columnId == ClosingPositions.Omnia_Efficiency_Improvement
  ) {
    validatePercentage = true;
  } else {
    validatePercentage = false;
  }

  let newVal = inputValueValidation(
    inputVal,
    prevValue,
    setPrevValue,
    validatePercentage
  );
  let value: string;
  if (roundOff) {
    value =
      columnId == ClosingPositions.Free_Efficiency_Improvement ||
      columnId == ClosingPositions.Levvia_Efficiency_Improvement ||
      columnId == ClosingPositions.Omnia_Efficiency_Improvement
        ? validateInputValueLengthPercentage(newVal, prevValue, roundOff)
        : validateInputValueLength(newVal, prevValue, roundOff);
  } else {
    value =
      columnId == ClosingPositions.Free_Efficiency_Improvement ||
      columnId == ClosingPositions.Levvia_Efficiency_Improvement ||
      columnId == ClosingPositions.Omnia_Efficiency_Improvement
        ? validateInputValueLengthPercentage(newVal, prevValue, false)
        : validateInputValueLength(newVal, prevValue, false);
  }
  let newTableValues =
    tableData.length > 0 &&
    tableData.map((item: any, index: number) => {
      if (index === dataIndex) {
        if (
          roundOff &&
          (columnId == ClosingPositions.Free_Efficiency_Improvement ||
            columnId == ClosingPositions.Omnia_Efficiency_Improvement) &&
          Number(value) > 100
        ) {
          let errorList = [...(item?.errorList || []), columnId];
          item.errorList = [...new Set(errorList)];
          delete item.errorToastNotRequired;
          return { ...item, [columnId]: null };
        } else {
          let indexOf = item?.errorList?.indexOf(columnId);
          if (indexOf > -1) {
            item?.errorList?.splice(indexOf, 1);
          }
          return {
            ...item,
            [columnId]: value,
          };
        }
      }
      return item;
    });
  setTransformationAssumptionsTableData((prevVal: any) => {
    return { ...prevVal, [`${year}-${selectedTab}`]: newTableValues };
  });
  setDirtyTables(
    transformationAssumptionsTableDataCompare[`${year}-${selectedTab}`],
    newTableValues,
    transformationDirtyTables,
    setTransformationDirtyTables,
    selectedTab
  );
};

export const useCalculateTotalValueRows = (tableData: any) => {
  const [totalValueRow, setTotalValueRow] = useState<any>([]);

  useEffect(() => {
    let openingPositionArr: any = [];
    let omniaEfficiencyImprovementArr: any = [];
    let freeEffeciencyImprovementArr: any = [];
    let levviaEfficiencyImprovementArr: any = [];

    let openingPositionTotal: any = 0;
    let totalOmniaHoursReduction: any = 0;
    let totalLevviaHoursReduction: any = 0;
    let totalFreeHoursReduction: any = 0;
    let closingPositionTotal: any = 0;
    let strategicResourcingCoreAuditTotal: any = 0;
    let intermResultsTotalList: any = calculateintermResultsTotal(tableData);

    tableData &&
      tableData.length > 0 &&
      tableData.map((item: any) => {
        Object.keys(item)
          .filter(Number)
          .map((id: any) => {
            switch (id) {
              case "1":
                if (
                  (!item.deliveryCenterId && item.gradeLevelId) ||
                  (item.deliveryCenterId && !item.gradeLevelId)
                ) {
                  openingPositionArr.push(item[id]);
                }
                break;
              case "2":
                if (
                  (!item.deliveryCenterId && item.gradeLevelId) ||
                  (item.deliveryCenterId && !item.gradeLevelId)
                ) {
                  omniaEfficiencyImprovementArr.push(item[id]);
                }
                break;
              case "3":
                if (
                  (!item.deliveryCenterId && item.gradeLevelId) ||
                  (item.deliveryCenterId && !item.gradeLevelId)
                ) {
                  levviaEfficiencyImprovementArr.push(item[id]);
                }
                break;
              case "4":
                if (
                  (!item.deliveryCenterId && item.gradeLevelId) ||
                  (item.deliveryCenterId && !item.gradeLevelId)
                ) {
                  freeEffeciencyImprovementArr.push(item[id]);
                }
                break;
              case "5":
                if (!item.deliveryCenterId && item.gradeLevelId) {
                  strategicResourcingCoreAuditTotal += valueConvertor(item[id]);
                }
                break;
              case "10":
                if (
                  (!item.deliveryCenterId && item.gradeLevelId) ||
                  (item.deliveryCenterId && !item.gradeLevelId)
                ) {
                  closingPositionTotal += valueConvertor(item[id]);
                }
                break;

              default:
            }
          });
      });

    openingPositionArr.length > 0 &&
      openingPositionArr.map((item: any, index: any) => {
        totalOmniaHoursReduction += -(
          valueConvertor(item) *
          (valueConvertor(omniaEfficiencyImprovementArr[index]) / 100)
        );

        totalLevviaHoursReduction += -(
          valueConvertor(item) *
          (valueConvertor(levviaEfficiencyImprovementArr[index]) / 100)
        );

        let efficienyImprovement = !isNaN(
          valueConvertor(omniaEfficiencyImprovementArr[index])
        )
          ? valueConvertor(omniaEfficiencyImprovementArr[index])
          : !isNaN(valueConvertor(levviaEfficiencyImprovementArr[index]))
          ? valueConvertor(levviaEfficiencyImprovementArr[index])
          : 0;

        totalFreeHoursReduction += -(
          valueConvertor(item) *
          (1 - valueConvertor(efficienyImprovement) / 100) *
          valueConvertor(freeEffeciencyImprovementArr[index])
        );

        openingPositionTotal += valueConvertor(item);
      });

    let omniaEfficiencyImprovementTotal: any =
      -(
        valueConvertor(totalOmniaHoursReduction) /
        valueConvertor(openingPositionTotal)
      ) * 100;

    let hoursReduction = !isNaN(valueConvertor(totalOmniaHoursReduction))
      ? valueConvertor(totalOmniaHoursReduction)
      : !isNaN(valueConvertor(totalLevviaHoursReduction))
      ? valueConvertor(totalLevviaHoursReduction)
      : 0;

    let freeEfficiencyImprovementTotal = -(
      valueConvertor(totalFreeHoursReduction) /
      (valueConvertor(openingPositionTotal) + valueConvertor(hoursReduction))
    );

    let levviaEfficiencyImprovementTotal = -(
      (valueConvertor(totalLevviaHoursReduction) /
        valueConvertor(openingPositionTotal)) *
      100
    );

    let transformationAssuptionsTotals: any = [];
    let commonProperty: any = {
      textInput: true,
      inputDisable: true,
      roundNumbers: 2,
    };

    Object.keys(tableData?.[0] || {})
      ?.filter(Number)
      .map((id: any) => {
        switch (id) {
          case "1":
            transformationAssuptionsTotals.push({
              ...commonProperty,
              inputValue: Number(openingPositionTotal) || 0,
            });
            break;
          case "2":
            transformationAssuptionsTotals.push({
              ...commonProperty,
              inputValue: Number(omniaEfficiencyImprovementTotal) || 0,
            });
            break;
          case "3":
            transformationAssuptionsTotals.push({
              ...commonProperty,
              inputValue: Number(levviaEfficiencyImprovementTotal) || 0,
            });
            break;
          case "4":
            transformationAssuptionsTotals.push({
              ...commonProperty,
              inputValue: Number(freeEfficiencyImprovementTotal) || 0,
            });
            break;
          case "5":
            transformationAssuptionsTotals.push({
              ...commonProperty,
              inputValue: Number(strategicResourcingCoreAuditTotal) || 0,
            });
            break;
          case "6":
            transformationAssuptionsTotals.push({
              ...commonProperty,
              inputValue:
                Number(
                  intermResultsTotalList[
                    INTERM_RESULT_MAPPING.INTERM_RESULT_3
                  ] -
                    intermResultsTotalList[
                      INTERM_RESULT_MAPPING.INTERM_RESULT_2
                    ]
                ) || 0, //Interim Result 3 – Interim Result 2,
            });
            break;
          case "7":
            transformationAssuptionsTotals.push({
              ...commonProperty,
              inputValue:
                Number(
                  intermResultsTotalList[
                    INTERM_RESULT_MAPPING.INTERM_RESULT_4
                  ] -
                    intermResultsTotalList[
                      INTERM_RESULT_MAPPING.INTERM_RESULT_3
                    ]
                ) || 0, //Interim Result 4 – Interim Result 3,
            });
            break;
          case "8":
            transformationAssuptionsTotals.push({
              ...commonProperty,
              inputValue:
                Number(
                  intermResultsTotalList[
                    INTERM_RESULT_MAPPING.INTERM_RESULT_5
                  ] -
                    intermResultsTotalList[
                      INTERM_RESULT_MAPPING.INTERM_RESULT_4
                    ]
                ) || 0, //Interim Result 5 – Interim Result 4,
            });
            break;
          case "9":
            transformationAssuptionsTotals.push({
              ...commonProperty,
              inputValue:
                Number(
                  intermResultsTotalList[
                    INTERM_RESULT_MAPPING.INTERM_RESULT_6
                  ] -
                    intermResultsTotalList[
                      INTERM_RESULT_MAPPING.INTERM_RESULT_5
                    ]
                ) || 0, //Interim Result 6 – Interim Result 5,
            });
            break;
          case "10":
            transformationAssuptionsTotals.push({
              ...commonProperty,
              inputValue: Number(closingPositionTotal) || 0,
            });
            break;
        }
      });

    setTotalValueRow([...transformationAssuptionsTotals]);
  }, [tableData]);

  return totalValueRow;
};
const calculateintermResultsTotal = (tableData: any) => {
  let intermIdsTotalList: any = [];
  for (let intermId = 1; intermId <= 6; intermId++) {
    let totalIntermResultValue: number = 0;
    tableData?.map((item: any) => {
      if (item.gradeLevelId !== 0) {
        totalIntermResultValue += CalculateIntermResultRowbyID(
          item[ClosingPositions.Opening_Position],
          item[ClosingPositions.Omnia_Efficiency_Improvement],
          item[ClosingPositions.Free_Efficiency_Improvement],
          item[ClosingPositions.Levvia_Efficiency_Improvement],
          item[ClosingPositions.Strategic_Resourcing_Core_Audit_Team_Shift],
          item[ClosingPositions.USI_SHIFT],
          item[ClosingPositions.RADC_SHIFT],
          item[ClosingPositions.Local_DC_SHIFT],
          item[ClosingPositions.Local_COE_SHIFT],
          intermId
        );
      }
    });
    intermIdsTotalList.push(totalIntermResultValue);
  }
  return intermIdsTotalList;
};
const CalculateIntermResultRowbyID = (
  openingPosition: number,
  omniaEfficiencyImprovement: number,
  freeEffeciencyImprovement: number,
  levviaEfficiencyImprovement: number,
  strategicResourceCoreAuditTeamShift: number,
  usiShift: number,
  radcShift: number,
  localDCShift: number,
  localCOEShift: number,
  id: number
) => {
  let percentageCalculation =
    (valueConvertor(omniaEfficiencyImprovement)
      ? 1 - valueConvertor(omniaEfficiencyImprovement) / 100
      : 1) *
    valueConvertor(
      valueConvertor(levviaEfficiencyImprovement)
        ? 1 - valueConvertor(levviaEfficiencyImprovement) / 100
        : 1
    ) *
    (valueConvertor(freeEffeciencyImprovement)
      ? 1 - valueConvertor(freeEffeciencyImprovement) / 100
      : 1);

  let calculatedIntermResult1: any =
    valueConvertor(openingPosition) * percentageCalculation;
  let calculatedIntermResult2: any =
    calculatedIntermResult1 + strategicResourceCoreAuditTeamShift;
  let calculatedIntermResult3: any = calculatedIntermResult2 + usiShift;
  let calculatedIntermResult4: any = calculatedIntermResult3 + radcShift;
  let calculatedIntermResult5: any = calculatedIntermResult4 + localDCShift;
  let calculatedIntermResult6: any = calculatedIntermResult5 + localCOEShift;

  switch (id) {
    case 1:
      return calculatedIntermResult1;
    case 2:
      return calculatedIntermResult2;
    case 3:
      return calculatedIntermResult3;
    case 4:
      return calculatedIntermResult4;
    case 5:
      return calculatedIntermResult5;
    case 6:
      return calculatedIntermResult6;
    default:
      break;
  }
};

export const useCalculateLiberatedHoursTable = (
  tableData: any,
  deployementCalData: any,
  costAdoptionAssumptionList: any
) => {
  const [newTableData, setNewTableData] = useState<any>();
  useEffect(() => {
    if (
      deployementCalData &&
      deployementCalData.length > 0 &&
      tableData &&
      Object.keys(tableData).length &&
      costAdoptionAssumptionList &&
      costAdoptionAssumptionList.length > 0
    ) {
      let newData = [...tableData];

      const baseYearList: any = [];
      newData?.map((item: any) => {
        if (
          !baseYearList.includes(item.year) &&
          item.leverId !== deployementTableReference[0]?.leverId &&
          item.leverId !== deployementTableReference[1]?.leverId
        ) {
          baseYearList.push(item.year);
        }
      });

      var openingAuditHoursValue: any;
      newData.map((item: any) => {
        if (item.leverId === 9 && item.year === baseYearList[0]) {
          openingAuditHoursValue = item.value;
        }
      });

      var cumulativeAdoptionPrior: any;
      newData.map((item: any) => {
        if (item.leverId === 7 && item.year === baseYearList[0] - 2) {
          cumulativeAdoptionPrior = item.value;
        }
      });

      //Net cost/efficency in transition year
      newData.map((item: any) => {
        if (item.leverId === 10) {
          let deployementObj = deployementCalData?.find((value: any) => {
            return value.year === item.year;
          });
          if (deployementObj && Object.keys(deployementObj).length) {
            item.value =
              openingAuditHoursValue != null &&
              deployementObj.inputValue != null &&
              costAdoptionAssumptionList[0] != null
                ? Number(
                    (openingAuditHoursValue ? openingAuditHoursValue : 0) *
                      (deployementObj.inputValue
                        ? deployementObj.inputValue / 100
                        : 0) *
                      (costAdoptionAssumptionList[0]
                        ? costAdoptionAssumptionList[0] / 100
                        : 0)
                  ) || 0
                : null;
          }
        }
      });

      // liberated hours
      let yearRows = 1;
      var deployementTableSource: any = [];

      deployementCalData?.map((item: any, index: number) => {
        if (index === 0) {
          deployementTableSource[0] = cumulativeAdoptionPrior;
        } else {
          deployementTableSource[index] = item.inputValue;
        }
      });

      newData.map((item: any) => {
        if (item.leverId === 11) {
          let deployementTableYearsWise: any = [];
          for (let i = yearRows + 1; i > 0; i--) {
            deployementTableYearsWise.push(deployementTableSource[i - 1]);
          }

          let rowValue: any = null;
          for (let i = 0; i < deployementTableYearsWise.length; i++) {
            let cumulativeVal =
              openingAuditHoursValue != null &&
              (i + 1 < costAdoptionAssumptionList.length
                ? costAdoptionAssumptionList[i + 1] != null
                : costAdoptionAssumptionList[
                    costAdoptionAssumptionList.length - 1
                  ] != null) &&
              deployementTableYearsWise[i] != null
                ? (openingAuditHoursValue ? openingAuditHoursValue : 0) *
                  (i + 1 < costAdoptionAssumptionList.length
                    ? costAdoptionAssumptionList[i + 1]
                      ? costAdoptionAssumptionList[i + 1] / 100
                      : 0
                    : costAdoptionAssumptionList[
                        costAdoptionAssumptionList.length - 1
                      ]
                    ? costAdoptionAssumptionList[
                        costAdoptionAssumptionList.length - 1
                      ] / 100
                    : 0) *
                  (deployementTableYearsWise[i]
                    ? deployementTableYearsWise[i] / 100
                    : 0)
                : null;
            rowValue =
              cumulativeVal != null ? rowValue + cumulativeVal : rowValue;
            item.value = Number(rowValue) || 0;
          }

          yearRows = yearRows + 1;
        }
      });

      //Net In Year Liberated Hours
      newData.map((item: any) => {
        if (item.leverId === 12) {
          let liberatedHoursObj = newData.find((value: any) => {
            return value.year === item.year && value.leverId === 11;
          });
          let netCostEfficiencyObj = newData.find((value: any) => {
            return value.year === item.year && value.leverId === 10;
          });
          if (
            liberatedHoursObj &&
            netCostEfficiencyObj &&
            Object.keys(liberatedHoursObj).length &&
            Object.keys(netCostEfficiencyObj).length
          ) {
            let calculatedVal =
              (liberatedHoursObj.value ? liberatedHoursObj.value : 0) +
              (netCostEfficiencyObj.value ? netCostEfficiencyObj.value : 0);

            item.value = calculatedVal ? -calculatedVal : calculatedVal;
          }
        }
      });

      //closing audit hours
      newData.map((item: any) => {
        if (item.leverId === 13) {
          let netInYearliberatedObj = newData.find((value: any) => {
            return value.year === item.year && value.leverId === 12;
          });
          let openingAuditHoursObj = newData.find((value: any) => {
            return value.year === item.year && value.leverId === 9;
          });
          if (
            netInYearliberatedObj &&
            openingAuditHoursObj &&
            Object.keys(netInYearliberatedObj).length &&
            Object.keys(openingAuditHoursObj).length
          ) {
            item.value =
              Number(
                (openingAuditHoursObj.value ? openingAuditHoursObj.value : 0) -
                  (netInYearliberatedObj.value
                    ? netInYearliberatedObj.value
                    : 0)
              ) || 0;

            reflactOpeningHour(newData, baseYearList);
          }
        }
      });

      //Net in year liberated hours (%)
      newData.map((item: any) => {
        if (item.leverId === 14) {
          let netInYearliberatedObj = newData.find((value: any) => {
            return value.year === item.year && value.leverId === 12;
          });
          if (
            netInYearliberatedObj &&
            Object.keys(netInYearliberatedObj).length
          ) {
            item.value =
              netInYearliberatedObj?.value != null &&
              openingAuditHoursValue != null
                ? openingAuditHoursValue
                  ? Number(
                      (netInYearliberatedObj.value / openingAuditHoursValue) *
                        100
                    ) || 0
                  : 0
                : null;
          }
        }
      });
      setNewTableData(newData);
    }
  }, [deployementCalData, costAdoptionAssumptionList]);
  return newTableData;
};

const reflactOpeningHour = (data: any, baseYearList: any) => {
  //Opening Audit Hours
  data.map((item: any) => {
    if (item.leverId === 9 && item.year !== baseYearList[0]) {
      let closingAuditHoursObj = data.find((value: any) => {
        return value.leverId === 13 && value.year === item.year - 1;
      });
      if (closingAuditHoursObj) {
        item.value = closingAuditHoursObj.value;
      }
    }
  });
};
