import api from "services/api.service";

export const fetchGeo = () => api.get("geographies").then(({ data }) => data);

export const addGeo = (payload: any) =>
  api.post("plansubmissions", payload).then(({ data }) => data);

export const fetchMFAPreparerReviewer = (countryCode: any, payload: any) =>
  api
    .post(`users/searchuser?countryCode=${countryCode}`, payload)
    .then(({ data }) => data);

export const setExistingMemberFirmAdmin = (memberFirmCode: string) =>
  api
    .get(`geographies/${memberFirmCode}/memberfirmadmin`)
    .then(({ data }) => data);

export const updateGeo = (GeoInfo: any) =>
  api.put(`/plansubmissions`, GeoInfo).then(({ data }) => data);
