import { useEffect } from "react";
import { RootStore } from "services/store.service";
import { useSelector, useDispatch } from "react-redux";
import CostAdoptionAssumptions from "./costAdoptionAssumption";
import DeployementOfFile from "./deployement";
import LiberatedHoursTable from "./liberatedHoursTable";
import {
  useAPI,
  liberateHoursCostAssumptionSectionWiseTableData,
} from "./liberatedHoursContextProvider";
import {
  loadCostAdoptionCenter,
  loadDeploymentAndLiberatedHours,
  loadLiberatedHoursDropDowndaContext,
} from "services/inputForm/inputFormSlice";
import { useParams } from "react-router-dom";

const LiberatedHours = ({
  showModal,
  showUpdateInputsPopup,
  liberatedHoursDirtyTables,
  setLiberatedHoursDirtyTables,
}: any) => {
  const BLOCK = "liberatedHours";
  const inputFormDataState: any = useSelector(
    (state: RootStore) => state.inputForm
  );
  const params: any = useParams();
  const dispatch = useDispatch();
  let planSubmissionId = inputFormDataState?.metaData?.planSubmissionId
    ? inputFormDataState?.metaData?.planSubmissionId
    : Number(params?.id);

  const {
    setCostAssumptionLoader,
    setCostAssumptionCenterData,
    setCompareTableData,
    setDeploymentAndLiberatedHoursLoader,
    setDeployementAndLiberatedHoursData,
  }: any = useAPI();

  useEffect(() => {
    if (planSubmissionId) {
      setCostAssumptionCenterData([]);
      setDeployementAndLiberatedHoursData([]);
      dispatch(loadLiberatedHoursDropDowndaContext(planSubmissionId));
      loadCostAdoptionCenter(planSubmissionId)
        .then(({ data }: any) => {
          setCostAssumptionLoader(false);
          setCostAssumptionCenterData(
            liberateHoursCostAssumptionSectionWiseTableData(data)
          );
          setCompareTableData((prevVal: any) => ({
            ...prevVal,
            costAssumptionCenterData:
              liberateHoursCostAssumptionSectionWiseTableData(data),
          }));
        })
        .catch((err) => {
          console.log("error", err);
        });
      loadDeploymentAndLiberatedHours(planSubmissionId)
        .then(({ data }: any) => {
          setDeploymentAndLiberatedHoursLoader(false);
          setDeployementAndLiberatedHoursData(
            liberateHoursCostAssumptionSectionWiseTableData(data)
          );
          setCompareTableData((prevVal: any) => ({
            ...prevVal,
            deployementAndLiberatedHoursData:
              liberateHoursCostAssumptionSectionWiseTableData(data),
          }));
        })
        .catch((err) => {
          console.log("error", err);
        });
      return () => {
        setDeploymentAndLiberatedHoursLoader(true);
        setCostAssumptionLoader(true);
        setDeployementAndLiberatedHoursData([]);
        setCostAssumptionCenterData([]);
      };
    }
  }, [planSubmissionId]);

  return (
    <div className={`${BLOCK}__container`} data-test="liberatedHours-component">
      <CostAdoptionAssumptions
        showModal={showModal}
        showUpdateInputsPopup={showUpdateInputsPopup}
        dropDownSelected={inputFormDataState?.selectedProductSegment?.id}
        liberatedHoursDirtyTables={liberatedHoursDirtyTables}
        setLiberatedHoursDirtyTables={setLiberatedHoursDirtyTables}
      />
      <DeployementOfFile
        showModal={showModal}
        showUpdateInputsPopup={showUpdateInputsPopup}
        dropDownSelected={inputFormDataState?.selectedProductSegment?.id}
        liberatedHoursDirtyTables={liberatedHoursDirtyTables}
        setLiberatedHoursDirtyTables={setLiberatedHoursDirtyTables}
      />
      <LiberatedHoursTable
        dropDownSelected={inputFormDataState?.selectedProductSegment?.id}
      />
    </div>
  );
};

export default LiberatedHours;
