import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { revenuePLdPath, revenueAndPlTablesList } from "utils/constants";
import { tableNaviagtionEventListener } from "../shared/commons/globalFunctions";
import { setCurrentRevPLNavigation } from "services/inputForm/inputFormSlice";

type revenueAndPlSubItems = {
  BLOCK: string;
  changeTabs: any;
};

const RevenueAndPlSubItems = ({ BLOCK, changeTabs }: revenueAndPlSubItems) => {
  const inputFormDataState = useSelector((state: RootStore) => state.inputForm);
  let sectionSelector = "#revenuepl";
  let locationName = useLocation().pathname;
  const [subsectionName, setSubSectionName] = useState<any>(null);
  const [initialClick, setInitialClick] = useState<any>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!subsectionName) {
      setSubSectionName(
        locationName.includes(revenuePLdPath)
          ? revenueAndPlTablesList[0].refId
          : null
      );
    }
    if (!locationName.includes(revenuePLdPath)) {
      setInitialClick(null);
      setSubSectionName(null);
    }
  }, [locationName]);

  const handleCLick = (tableId: any, index: number) => {
    const element = document.getElementById(tableId);
    setInitialClick(tableId);
    if (element) {
      setSubSectionName(tableId);
      element.scrollIntoView();
    } else if (!locationName.includes(revenuePLdPath)) {
      changeTabs(`${revenuePLdPath}`, index);
    }
  };
  useEffect(() => {
    const main: any = document?.querySelector(sectionSelector);
    let sections = main?.querySelectorAll(`.headcounts__scroll-margin`);
    if (sections && initialClick) {
      setSubSectionName(initialClick);
      const element = document.getElementById(initialClick);
      element?.scrollIntoView();
    }
  });
  useEffect(() => {
    dispatch(setCurrentRevPLNavigation(subsectionName));
  }, [subsectionName]);

  const getActiveId = () => {
    const main: any = document?.querySelector(sectionSelector);
    let sections = main?.querySelectorAll(`.headcounts__scroll-margin`);
    let activeId;
    sections?.forEach((section: any) => {
      const rect = section?.getBoundingClientRect();

      if (
        rect.top + (window.innerHeight - window.innerHeight / 1.5) <
          window.innerHeight &&
        rect.bottom >= 0
      ) {
        setInitialClick(null);
        activeId = Number(section.id);
      }
    });
    setSubSectionName(activeId);
  };

  useEffect(() => {
    const element = document?.querySelector(sectionSelector);
    tableNaviagtionEventListener(element, getActiveId);
    return () => {
      tableNaviagtionEventListener(element, getActiveId, false);
    };
  }, [locationName]);

  const createStringFromTemplate = (template: any, variables: any) => {
    return template?.replace(
      new RegExp("{([^{]+)}", "g"),
      function (_unused: any, varName: any) {
        return variables[varName];
      }
    );
  };

  return (
    <div
      className={`${BLOCK}__container`}
      data-test="revenueAndPl-subItem-test"
    >
      {revenueAndPlTablesList.map((item: any, index: number) => {
        return (
          <div
            key={`navigation-item-${index}`}
            className={`${BLOCK}__navigation-panel--item`}
          >
            <span
              data-test="revenueAndPl-subItem-test-onClick"
              className={`${BLOCK}__sub-items`}
              onClick={() => {
                handleCLick(item.refId, index);
              }}
              style={{
                color: subsectionName === item.refId ? "#00A3E0" : "black",
              }}
            >
              {createStringFromTemplate(item.tableName, {
                year: inputFormDataState?.metaData?.baseYear
                  ? inputFormDataState?.metaData?.baseYear
                  : "__",
              })}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default RevenueAndPlSubItems;
