import { useEffect, useMemo } from "react";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import { configurationProjectedYearsList } from "utils/constants";
import DropDown from "../search/filters/drop-down";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { addToastMessage } from "services/common/commonsSlice";
import {
  setConfigurationProjectedYearSelected,
  getConfiguredProjectedYear,
  setConfiguredProjectedYear,
  getDashboardTableList,
  resetConfiguredYear,
} from "services/dashboard/dashBoardSlice";

const ConfigurationProjectedYearsModal = ({
  confirmationCount,
  setConfirmationCount,
  setConfigurProjectedYearsModal,
  buttonNamme,
  content,
}: any) => {
  const BLOCK = "RollForward";
  const dispatch = useDispatch();
  const handleClose = () => {
    setConfigurProjectedYearsModal(false);
  };

  const dashboardTableState = useSelector(
    (state: RootStore) => state.dashboard
  );

  const selectProjectedYear = (value: any) => {
    dispatch(setConfigurationProjectedYearSelected([value]));
  };
  useEffect(() => {
    if (
      dashboardTableState.fiscalYearList &&
      Object.keys(dashboardTableState.fiscalYearList).length &&
      dashboardTableState.fiscalYearList[0]?.year
    ) {
      dispatch(
        getConfiguredProjectedYear(dashboardTableState.fiscalYearList[0]?.year)
      );
    }
  }, []);
  useEffect(() => {
    if (dashboardTableState && dashboardTableState?.configuredYear) {
      dispatch(
        setConfigurationProjectedYearSelected([
          String(dashboardTableState?.configuredYear),
        ])
      );
    }
  }, [dashboardTableState?.configuredYear]);

  const confirmConfiguredYear = () => {
    if (
      dashboardTableState?.configurationProjectedYearSelected &&
      dashboardTableState.fiscalYearList &&
      Object.keys(dashboardTableState.fiscalYearList).length &&
      dashboardTableState.fiscalYearList[0]?.year
    ) {
    }
    handleClose();
    setConfiguredProjectedYear(
      dashboardTableState.fiscalYearList[0]?.year,
      Number(dashboardTableState?.configurationProjectedYearSelected)
    )
      .then((response: any) => {
        dispatch(
          getDashboardTableList(
            dashboardTableState.selectedYear?.year
              ? dashboardTableState.selectedYear?.year
              : null
          )
        );
        dispatch(
          resetConfiguredYear(
            Number(dashboardTableState?.configurationProjectedYearSelected)
          )
        );
        dispatch(
          addToastMessage({
            description: <FormattedMessage id="configure.fy.success" />,
          })
        );
      })
      .catch(() => {
        dispatch(
          addToastMessage({
            description: <FormattedMessage id="configure.fy.failure" />,
          })
        );
      });
  };

  return (
    <Modal
      show={true}
      header={
        confirmationCount === 1
          ? `config.proj.years`
          : `config.proj.years.confirm`
      }
      handleClose={handleClose}
      config={false}
    >
      <div
        className={BLOCK}
        data-test="component-Configuration Projected Years Modal"
      >
        <div className={`${BLOCK}__contentText`}>{content}</div>
        {confirmationCount === 1 && (
          <div className={`${BLOCK}__drop-down-container`}>
            <DropDown
              className={`metricsModel__drop-down`}
              placeholder={<FormattedMessage id="con.proj.years" />}
              loading={dashboardTableState.configureFYLoading}
              objectKeys={{
                name: "",
                id: "",
                externalId: "",
              }}
              values={dashboardTableState.configurationProjectedYearSelected}
              options={configurationProjectedYearsList}
              handleSelect={selectProjectedYear}
              displayFunction={(option: any) => {
                return option;
              }}
            />
          </div>
        )}
        <div className={`${BLOCK}__buttonsLayout`}>
          <div className={`${BLOCK}__buttonsContainer`}>
            <button
              className={`primary-outline`}
              data-test="cancelButton"
              onClick={() =>
                confirmationCount === 2
                  ? setConfirmationCount(1)
                  : handleClose()
              }
            >
              <FormattedMessage id="roll.forward.button.cancel" />
            </button>
            <button
              data-test="submitButton"
              onClick={(e) => {
                confirmationCount === 1
                  ? setConfirmationCount(confirmationCount + 1)
                  : confirmConfiguredYear();
              }}
              className={
                confirmationCount === 1 &&
                (Number(
                  dashboardTableState?.configurationProjectedYearSelected
                ) == dashboardTableState.configuredYear ||
                  dashboardTableState?.configurationProjectedYearSelected
                    ?.length == 0)
                  ? `${BLOCK}__rollForwardButtonDisable`
                  : `${BLOCK}__rollForwardButton`
              }
              disabled={
                confirmationCount === 1 &&
                (Number(
                  dashboardTableState?.configurationProjectedYearSelected
                ) == dashboardTableState.configuredYear ||
                  dashboardTableState?.configurationProjectedYearSelected
                    ?.length == 0)
                  ? true
                  : false
              }
            >
              <FormattedMessage id={buttonNamme} />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigurationProjectedYearsModal;
