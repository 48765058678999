import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "./input-form.api";
import {
  STATUS_ID_MAPPING,
  EngineObject,
  productSegments,
  productSegmentsMapping,
  ClosingPositions,
  TABLE_MAPPINGS,
  TABLE_MAPPINGS_HOURS,
  TABLE_MAPPINGS_PRICE_INCREASE,
  TABLE_MAPPINGS_UTIL_RATE,
  overHeadTypedIdOrderList,
  YEARLY_UTLIZATION_DEFAULT_PRODUCTSEGMENT_ID,
} from "utils/constants";
import { Dispatch } from "redux";
import { valueConvertor } from "../../components/shared/commons/globalFunctions";
import {
  filterTablesByProductSegmentId,
  setDirtyTables,
} from "utils/useInputForm";
import Decimal from "decimal.js";

const initialState = {
  loading: [],
  configurationData: [],
  metaData: [],
  onClickOnInputForm: false,
  inputFormStatusId: -1,
  InputFormProgressData: [],
  configurationDataSubmit: {},
  isInputFormSaved: true,
  isHighlightEmpty: false,
  conversionRatiosGetData: [],
  yearlyUtilizationGetData: [],
  conversionRatiosGetDataCompareData: [],
  liberatedHoursDropDownData: [],
  selectedProductSegment: [],
  conversionRatiosLoading: false,
  currencyList: [],
  currency: [],
  selectedCells: [],
  tableNumber: 2,
  changeTableNumber: 2,
  configurationCompareData: [],
  InputFormProgressDataLocal: [],
  currentRevPlNavigation: null,
  currentHeadCountTab: null,
  expandedItems: [YEARLY_UTLIZATION_DEFAULT_PRODUCTSEGMENT_ID],
};

const inputFormSlice = createSlice({
  name: "inputForm",
  initialState,
  reducers: {
    setMetaData(state: any, action) {
      state.metaData = action.payload;
    },
    setConfigurationDataSubmit(state: any, action) {
      state.configurationDataSubmit = action.payload ? action.payload : [];
    },
    setInputformIconClick(state: any, action) {
      state.onClickOnInputForm = action.payload;
    },
    setInputFormSaved(state: any, action) {
      state.isInputFormSaved = action.payload;
    },
    setHighlightEmptyInput(state: any, action) {
      state.isHighlightEmpty = action.payload;
    },
    setCurrency(state: any, action) {
      state.currency = action.payload;
    },
    emptyAllSelectedCells(state: any, action) {
      state.selectedCells = [];
    },
    setCellAsSelectedFromDrag(state: any, action) {
      state.selectedCells = [...state.selectedCells, action.payload];
    },
    removeCellsFromSelection(state: any, action) {
      state.selectedCells = state.selectedCells.filter(
        (cell: any) => cell !== action.payload
      );
    },
    setTableNumberWhenPopup(state: any, action) {
      state.tableNumber = action.payload;
    },
    ChangeTable(state: any, action) {
      state.changeTableNumber = action.payload;
    },
    setConversionRatiosGetTableData(state: any, action) {
      state.conversionRatiosGetData = action.payload;
    },
    setYearlyUtilizationGetTableData(state: any, action) {
      state.yearlyUtilizationGetData = action.payload;
    },
    setLiberatedHourProductSegment(state: any, action) {
      state.selectedProductSegment = action.payload;
    },
    TruncateAllInputformRecords(state: any, action) {
      state.conversionRatiosGetData = [];
      state.configurationData = [];
      state.InputFormProgressData = [];
      state.InputFormProgressDataLocal = [];
      state.expandedItems = [YEARLY_UTLIZATION_DEFAULT_PRODUCTSEGMENT_ID];
    },
    setConversionRatiosGetDataCompareData(state: any, action) {
      state.conversionRatiosGetDataCompareData = action.payload;
    },
    setInputFormProgressDataLocal(state: any, action) {
      state.InputFormProgressDataLocal = action.payload;
    },
    setCurrentRevPLNavigation(state: any, action) {
      state.currentRevPlNavigation = action.payload;
    },
    setCurrentHeadCountTab(state: any, action) {
      state.currentHeadCountTab = action.payload;
    },
    setExpandedItems(state: any, action) {
      state.expandedItems = action.payload;
    },
  },
  extraReducers(builder: any) {
    builder
      .addCase(loadConfiguration.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(state, "configurationLoading");
        state.configurationData = action.payload ? action.payload : [];
        state.configurationCompareData = action.payload ? action.payload : [];
      })
      .addCase(loadConfiguration.pending, (state: any, action: any) => {
        state.loading.push("configurationLoading");
      })
      .addCase(loadConfiguration.rejected, (state: any, action: any) => {
        state.loading = removeLoaders(state, "configurationLoading");
        state.configurationData = action.payload;
        state.configurationCompareData = action.payload;
      })
      .addCase(getStatusId.fulfilled, (state: any, action: any) => {
        state.inputFormStatusId = action.payload
          ? action.payload
          : STATUS_ID_MAPPING.ERROR.id;
      })
      .addCase(getStatusId.rejected, (state: any, action: any) => {
        state.inputFormStatusId = STATUS_ID_MAPPING.ERROR.id;
      })
      .addCase(
        UpdateTheStatusAfterCalculation.fulfilled,
        (state: any, action: any) => {
          state.inputFormStatusId = action.payload
            ? action.payload
            : STATUS_ID_MAPPING.ERROR.id;
        }
      )
      .addCase(
        UpdateTheStatusAfterCalculation.rejected,
        (state: any, action: any) => {
          state.inputFormStatusId = STATUS_ID_MAPPING.ERROR.id;
        }
      )
      .addCase(getProgressPercentage.fulfilled, (state: any, action: any) => {
        state.InputFormProgressData = action.payload ? action.payload : [];
      })
      .addCase(
        submitForReviewInputForm.fulfilled,
        (state: any, action: any) => {
          state.inputFormStatusId =
            action.payload !== 0
              ? action.payload
              : STATUS_ID_MAPPING.PREPARED.id;
        }
      )
      .addCase(completeReviewInputForm.fulfilled, (state: any, action: any) => {
        state.inputFormStatusId =
          action.payload !== 0 ? action.payload : STATUS_ID_MAPPING.PREPARED.id;
      })
      .addCase(
        getConversionRatiosTableData.fulfilled,
        (state: any, action: any) => {
          state.conversionRatiosLoading = false;
          state.conversionRatiosGetData = action.payload ? action.payload : [];
          state.conversionRatiosGetDataCompareData = action.payload
            ? action.payload
            : [];
        }
      )
      .addCase(
        getConversionRatiosTableData.pending,
        (state: any, action: any) => {
          state.conversionRatiosLoading = true;
        }
      )
      .addCase(
        getConversionRatiosTableData.rejected,
        (state: any, action: any) => {
          state.conversionRatiosLoading = false;
          state.conversionRatiosGetData = action.payload;
          state.conversionRatiosGetDataCompareData = action.payload;
        }
      )
      .addCase(
        loadLiberatedHoursDropDowndaContext.fulfilled,
        (state: any, action: any) => {
          state.liberatedHoursDropDownData = action.payload
            ? action.payload
            : [];
        }
      )
      .addCase(
        loadLiberatedHoursDropDowndaContext.rejected,
        (state: any, action: any) => {
          state.liberatedHoursDropDownData = [];
        }
      )
      .addCase(loadCurrency.fulfilled, (state: any, action: any) => {
        state.loading = removeLoaders(state, "currencyLoading");
        state.currencyList = action.payload ? action.payload : [];
      })
      .addCase(loadCurrency.pending, (state: any, action: any) => {
        state.loading.push("currencyLoading");
      })
      .addCase(loadCurrency.rejected, (state: any, action: any) => {
        state.loading = removeLoaders(state, "currencyLoading");
        state.currencyList = action.payload;
      });
  },
});

const removeLoaders = (state: any, loader: any) => {
  return state.loading.filter((filter: any) => {
    return filter !== loader;
  });
};

export const loadConfiguration = createAsyncThunk(
  "dashboard/fetchConfigurationData",
  async (planSubmissionId: number) => {
    try {
      const res = await api.getConfiguration(planSubmissionId);
      return res.data;
    } catch (e) {
      console.log("Error getting dashboard data");
      console.log(e);
    }
  }
);

export const getStatusId = createAsyncThunk(
  "dashboard/fetchStatusId",
  async (planSubmissionId: number = 1) => {
    try {
      const res = await api.getInputFormStatus(planSubmissionId);
      return res.data;
    } catch (e) {
      console.log("Error getting dashboard data");
      console.log(e);
    }
  }
);
export const getProgressPercentage = createAsyncThunk(
  "dashboard/fetchProgressPercentage",
  async (planSubmissionId: number = 1) => {
    try {
      const res = await api.getInputFormProgress(planSubmissionId);
      return res.data;
    } catch (e) {
      console.log(`error on getting the progress data for ${planSubmissionId}`);
      console.log(e);
    }
  }
);
export const getConversionRatiosTableData = createAsyncThunk(
  "dashboard/fetchConvertionRatioData",
  async (planSubmissionId: number = 1) => {
    try {
      const res = await api.getConversionRatiosData(planSubmissionId);
      return res;
    } catch (e) {
      console.log("error while getting the data");
      console.log(e);
    }
  }
);
export const loadCurrency = createAsyncThunk(
  "dashboard/fetchCurrencyList",
  async () => {
    try {
      const res = await api.getCurrency();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const loadYearlyUtilizationConfigContext = (
  planSubmissionId: number = 1
) => {
  return new Promise((resolve, reject) => {
    api
      .getYearlyUtilizationConfig(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRevenuePnlTableDataContext = (planSubmissionId: number) => {
  return new Promise((resolve, reject) => {
    api
      .getRevenueAndPLTableData(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getRevenuePriceIncreaseDataContext = (
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getRevenuePriceIncreaseTableData(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getProfitsAndLossesTableDataContext = (
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getPandLCostsTableData(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getWinsNetRevenueTableDataContext = (planSubmissionId: number) => {
  return new Promise((resolve, reject) => {
    api
      .getWinsNetRevenueTableData(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getLossesNetRevenueTableDataContext = (
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getLossNetRevenueTableData(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getExitProgrammePhasesTableDataContext = (
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getExitProgrammePhasesTableData(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getFeePerExitHoursTableDataContext = (
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getFeePerExitHoursTableData(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getIncreaseInHoursTableDataContext = (
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getIncreaseInHoursTableData(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getExitPhasingTableDataContext = (planSubmissionId: number) => {
  return new Promise((resolve, reject) => {
    api
      .getExitPhasingTableData(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const RearrangingTheArrWithFourYears = (Arr: any, tableId: string) => {
  let dataArr = Arr.map((item: any) => {
    if (item.productSegmentId in productSegments) {
      return {
        productSegmentId: item.productSegmentId,
        [item.year]: item.value,
        planSubmissionId: item.planSubmissionId,
        productSegment: item.productSegment,
        [tableId]: item[tableId],
      };
    }
  });
  let dataObject: any = {};
  for (let i = 0; i < dataArr.length; i++) {
    if (dataArr[i].productSegmentId in dataObject) {
      dataObject[dataArr[i].productSegmentId] = {
        ...dataObject[dataArr[i].productSegmentId],
        ...dataArr[i],
      };
    } else {
      dataObject[dataArr[i].productSegmentId] = dataArr[i];
    }
  }
  let dataObjectKeys = Object.keys(dataObject).map((key: any) => {
    return dataObject[key];
  });
  return dataObjectKeys;
};

export const RearrangingTheArrWithFourYearsWithoutPS = (
  Arr: any,
  tableId: string
) => {
  let dataArr = Arr.map((item: any) => {
    return {
      [item.year]: item.value,
      planSubmissionId: item.planSubmissionId,
      [tableId]: item[tableId],
    };
  });
  let dataObject: any = {};
  for (let i = 0; i < dataArr.length; i++) {
    if (dataArr[i].planSubmissionId in dataObject) {
      dataObject[dataArr[i].planSubmissionId] = {
        ...dataObject[dataArr[i].planSubmissionId],
        ...dataArr[i],
      };
    } else {
      dataObject[dataArr[i].planSubmissionId] = dataArr[i];
    }
  }
  let dataObjectKeys = Object.keys(dataObject).map((key: any) => {
    return dataObject[key];
  });
  return dataObjectKeys;
};

export const loadAvgAnnualFTESalaryContext = (planSubmissionId: number) => {
  return new Promise((resolve, reject) => {
    api
      .getAvgAnnualFTESalary(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const loadSalaryPriceIncreaseContext = (planSubmissionId: number) => {
  return new Promise((resolve, reject) => {
    api
      .getSalaryPriceIncreases(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const loadYearlyUtilisationRateContext = (planSubmissionId: number) => {
  return new Promise((resolve, reject) => {
    api
      .getYearlyUtilisationRates(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const loadFullTimeEquavalentsContext = (planSubmissionId: number) => {
  return new Promise((resolve, reject) => {
    api
      .getFY22BasedHeadCounts(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const loadAttritionRatePerGradeContext = (
  planSubmissionId: number,
  rate: string
) => {
  return new Promise((resolve, reject) => {
    api
      .getRates(planSubmissionId, rate)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const loadPromotionRatePerGradeContext = (
  planSubmissionId: number,
  rate: string
) => {
  return new Promise((resolve, reject) => {
    api
      .getRates(planSubmissionId, rate)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loadTargetManagedHoursContext = (planSubmissionId: number) => {
  return new Promise((resolve, reject) => {
    api
      .getTargetManagedHours(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loadRateCardsContext = (
  planSubmissionId: number,
  deliveryCenterId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getRateCards(planSubmissionId, deliveryCenterId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loadPriceIncreaseRatesContext = (
  planSubmissionId: number,
  deliveryCenterId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getPriceIncreaseRates(planSubmissionId, deliveryCenterId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loadAnnualTotalHoursContext = (
  planSubmissionId: number,
  deliveryCenterId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getAnnualTotalHours(planSubmissionId, deliveryCenterId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loadUtilisationRatesContext = (
  planSubmissionId: number,
  deliveryCenterId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getUtilisationrates(planSubmissionId, deliveryCenterId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loadLiberatedHoursDropDowndaContext = createAsyncThunk(
  "dashboard/fetchLiberatedHoursDropdowwndata",
  async (planSubmissionId: number = 1) => {
    return new Promise((resolve, reject) => {
      api
        .getLiberatedHoursDropDown(planSubmissionId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
);
export const loadTransformationTablesContext = (
  planSubmissionId: number,
  productSegmentId: number,
  year: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getTransformationsData(planSubmissionId, productSegmentId, year)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const calculateOpeningAndClosingPositionAPI =
  (
    planSubmissionId: number,
    updateTheStatusId: boolean = false,
    engineObject: EngineObject = {}
  ) =>
  async (dispatch: Dispatch<any>) => {
    try {
      return new Promise((resolve, reject) => {
        api
          .calculateOpeningAndClosingPosition(planSubmissionId)
          .then(({ data }) => {
            resolve(data);
            if (updateTheStatusId && data) {
              dispatch(UpdateTheStatusAfterCalculation(planSubmissionId)); // it will call when the status is rollforward halted
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch (e) {
      console.log(e);
    }
  };

export const calculateEngineApi = (
  planSubmissionId: any,
  inputChangeType: any
) => {
  return new Promise((resolve, reject) => {
    api
      .calculateEngineApi(planSubmissionId, inputChangeType)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const UpdateTheStatusAfterCalculation = createAsyncThunk(
  "dashboard/updateTheStatusAfterCalculation",
  async (planSubmissionId: number = 1) => {
    try {
      return new Promise((resolve, reject) => {
        api
          .updateInputFormStatus(planSubmissionId, 1) // updating the status to inprogress after calculation api success.
          .then(({ data }) => {
            resolve(data);
            return data; // seting the updated status.
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch (e) {
      console.log(e);
    }
  }
);

// submit configuration
export const submitConfigurationData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .addConfiguration(payload, planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// save apis for revenue and pl
export const submitRevenueAndPlTableData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setRevenueAndPLTableData(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const submitProfitsAndLossesData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setPandLCostsTableData(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const submitWinsNetRevenueData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setWinsNetRevenueTableData(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const submitLossesNetRevenueData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setLossNetRevenueTableData(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const submitRevenuePriceIncreasesData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setRevenuePriceIncreaseTableData(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const submitExitProgrammePhasingData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setExitProgrammePhasesTableData(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const submitFeePerExitHours = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setFeePerExitHoursTableData(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const submitExitPhasingData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setExitPhasingTableData(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitIncreaseInHoursgData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setIncreaseInHoursTableData(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitPriceIncrease = (
  payload: any,
  deliveryCenterId: number,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .submitPriceIncrease(planSubmissionId, deliveryCenterId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//submit Yearly utilization
export const changeYearlyUtilizationData = (
  planSubmissionId: number,
  flag: boolean
) => {
  return new Promise((resolve, reject) => {
    api
      .submitYearlyUtilizationConfig(planSubmissionId, flag)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//submit headcounts
export const submitTargetManagedHoursData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setTargetManagedHours(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitAvgAnnualFTESalary = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setAvgAnualFTESalary(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitSalaryPrice = (payload: any, planSubmissionId: number) => {
  return new Promise((resolve, reject) => {
    api
      .setSalaryPriceIncreases(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitYearlyUtilisationRate = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setYearlyUtilisationRate(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitFy22BasedHeadCounts = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setFy22BasedHeadCounts(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitAnnualTotalHours = (
  payload: any,
  delieveryCenterID: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setAnnualTotalHours(planSubmissionId, delieveryCenterID, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitUtilisationRates = (
  payload: any,
  delieveryCenterID: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setUtilisationRates(planSubmissionId, delieveryCenterID, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitRate = (
  payload: any,
  planSubmissionId: number,
  rate: string
) => {
  let clonedArray = payload?.map((a: any) => {
    return { ...a };
  });
  clonedArray?.map((item: any) => {
    delete item.error;
  });
  return new Promise((resolve, reject) => {
    api
      .submitRate(planSubmissionId, clonedArray, rate)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitRateCard = (
  payload: any,
  delieveryCenterID: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setRadeCard(planSubmissionId, delieveryCenterID, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// submit transformation assumptions
export const submitTransformationAssumptions = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .submitTransformationData(planSubmissionId, payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitLiberatedHoursTableData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .submitLiberatedHoursData(payload, planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const submitDeployementAndLiberatedHoursTableData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .submitLiberatedHoursDeployementData(payload, planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitConvertionRatiosTableData = (
  payload: any,
  planSubmissionId: number
) => {
  let tableData: any = [];

  payload?.map((item: any) => {
    const { error1, error2, error3, error4, ...newObject } = item;
    tableData.push(newObject);
  });

  return new Promise((resolve, reject) => {
    api
      .setConversionRatiosData(planSubmissionId, tableData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// generic validation of Inputs
const isFloat = (value: any) => {
  var er = /^-?[0-9]+$/;
  return !er.test(value);
};

const validate = (s: any) => {
  var rgx =
    /^(-{1}?(?:([0-9]{0,10}))|([0-9]{1})?(?:([0-9]{0,9})))?(?:\.([0-9]{0,10}))?$/;
  return s.match(rgx);
};

const beforeAndAfterDecimalConvertor = (value: any) => {
  let subStringMaxLength = value.includes("-") ? 11 : 10;
  let maxLimit = new Decimal(9999999999.99999);
  let minLimit = new Decimal(-9999999999.99999);

  if (value && value.split(".").length > 1) {
    let numberBeforeDecimal = value
      ?.split(".")?.[0]
      ?.substring(0, subStringMaxLength);
    let valueDecimal = new Decimal(value);

    let numberAfterDecimal = value?.split(".")?.[1];

    if (valueDecimal.gt(maxLimit) || valueDecimal.lt(minLimit)) {
      return numberBeforeDecimal;
    }
    return `${numberBeforeDecimal}.${numberAfterDecimal}`;
  } else if (value === null || value === undefined || value === "") {
    return null;
  } else if (isNaN(value)) {
    return value;
  } else return Number(value.substring(0, subStringMaxLength));
};

export const validateInputValueLength = (
  inputVal: any,
  prevValue: any = "",
  roundOff: boolean = false
) => {
  let validateInput = validate(inputVal.replace(/,/g, ""));

  if (!roundOff && validateInput) {
    return beforeAndAfterDecimalConvertor(inputVal);
  } else if (!roundOff && !validateInput) {
    return beforeAndAfterDecimalConvertor(prevValue);
  } else if (roundOff) {
    if (inputVal === null || inputVal === undefined || inputVal === "") {
      return null;
    } else if (1 / inputVal === -Infinity) {
      return 0;
    }
    return Number(inputVal.replace(/,/g, ""));
  }
};

const beforeAndAfterDecimalPercentageConvertor = (
  value: any,
  tableId: any = null
) => {
  let subStringMaxLength = tableId && value.includes("-") ? 4 : 3;
  let maxLimit = tableId ? 999.99999 : 999;
  let minLimit = tableId ? -999.99999 : -99;
  if (value && value.split(".").length > 1) {
    let numberBeforeDecimal = value
      ?.split(".")?.[0]
      ?.substring(0, subStringMaxLength);

    let numberAfterDecimal = value?.split(".")?.[1];

    if (value > maxLimit || value < minLimit) {
      return numberBeforeDecimal;
    }

    return `${numberBeforeDecimal}.${numberAfterDecimal}`;
  } else if (value === null || value === undefined || value === "") {
    return null;
  } else if (isNaN(value)) {
    return value;
  } else return Number(value.substring(0, subStringMaxLength));
};

export const validateInputValueLengthPercentage = (
  inputVal: any,
  prevValue: any = "",
  roundOff: boolean = false,
  tableId: any = null
) => {
  let validateInput = validate(inputVal.replace(/,/g, ""));

  if (!roundOff && validateInput) {
    return beforeAndAfterDecimalPercentageConvertor(inputVal, tableId);
  } else if (!roundOff && !validateInput) {
    return beforeAndAfterDecimalPercentageConvertor(prevValue, tableId);
  } else if (roundOff) {
    if (inputVal === null || inputVal === undefined || inputVal === "") {
      return null;
    } else if (1 / inputVal === -Infinity) {
      return 0;
    }
    return Number(inputVal);
  }
};
export const validateConvertionRatiosTableInputs =
  (data: any, index: number) => async (dispatch: Dispatch<any>) => {
    if (index !== null && Object.keys(data).length) {
      let newTableValue = data.map((item: any) => {
        if (parseFloat(item[index]) === 0) {
          return {
            ...item,
            ["error" + index]: true,
            [index]: null,
          };
        } else {
          return {
            ...item,
            ["error" + index]: false,
          };
        }
      });
      dispatch(setConversionRatiosGetTableData(newTableValue));
    }
  };
// multi select functions
export const commonTransformationAssumptionsMultipleUpdate = (
  year: any,
  selectedTab: any,
  newValue: any,
  tableData: any,
  selectedCells: any,
  transformationDirtyTables: any,
  setTransformationAssumptionsTableData: any,
  setTransformationDirtyTables: any,
  tableId: any,
  setMultipleErrors: any
) => {
  let newTableValues =
    tableData[`${year}-${selectedTab}`].length > 0 &&
    tableData[`${year}-${selectedTab}`].map((item: any, index: number) => {
      let objInit = { ...item };
      let myerrorList: any = [];
      Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          let rowId = `${tableId}#${id}#${index}`;
          if (selectedCells.includes(rowId)) {
            if (
              (id == ClosingPositions.Free_Efficiency_Improvement ||
                id == ClosingPositions.Omnia_Efficiency_Improvement) &&
              (valueConvertor(newValue) > 100 || valueConvertor(newValue) < -99)
            ) {
              let errorList = [...(objInit?.errorList || []), id];
              myerrorList.push(id);
              objInit = {
                ...objInit,
                errorToastNotRequired: true,
                [id]: null,
                errorList: [...new Set(errorList)],
              };
              setMultipleErrors(true);
            } else {
              let updatedErrorList = objInit?.errorList?.filter(
                (error: any) => {
                  return error !== id;
                }
              );

              objInit = {
                ...objInit,
                errorList: updatedErrorList,
                [id]: newValue,
              };
            }
          }
        });
      return objInit;
    });

  setTransformationAssumptionsTableData({
    ...tableData,
    [`${year}-${selectedTab}`]: newTableValues,
  });
  setDirtyTables(
    tableData[`${year}-${selectedTab}`],
    newTableValues,
    transformationDirtyTables,
    setTransformationDirtyTables,
    selectedTab
  );
};
export const RevenueMultipleUpdate =
  (
    tableData: any,
    setTableData: any,
    newValue: any,
    selectedCells: any,
    compareTableData: any,
    dirtyTable: any,
    setDirtyTable: any,
    tableId: any,
    setMultipleErrorsRev: any
  ) =>
  async () => {
    let newTableValues = tableData.map((item: any) => {
      let objInit = { ...item };

      if (
        selectedCells.includes(`${tableId}#${item.productSegmentId}#1`) &&
        selectedCells.includes(`${tableId}#${item.productSegmentId}#2`)
      ) {
        objInit = {
          ...objInit,
          fee: newValue,
          hours: newValue,
          error: false,
        };
      } else if (
        selectedCells.includes(`${tableId}#${item.productSegmentId}#1`)
      ) {
        if (newValue == 0 && item.hours == 0) {
          objInit = {
            ...objInit,
            fee: newValue,
            error: false,
          };
        } else if (newValue == 0 && item.hours > 0) {
          objInit = {
            ...objInit,
            fee: null,
            hours: null,
            error: true,
            errorToastNotRequired: true,
          };
          setMultipleErrorsRev(true);
        } else if (newValue > 0 && item.hours == 0) {
          objInit = {
            ...objInit,
            fee: null,
            hours: null,
            error: true,
            errorToastNotRequired: true,
          };
          setMultipleErrorsRev(true);
        } else {
          objInit = {
            ...objInit,
            fee: newValue,
            error: false,
          };
        }
      } else if (
        selectedCells.includes(`${tableId}#${item.productSegmentId}#2`)
      ) {
        if (item.fee == 0 && newValue == 0) {
          objInit = {
            ...objInit,
            hours: newValue,
            error: false,
          };
        } else if (item.fee == 0 && newValue > 0) {
          objInit = {
            ...objInit,
            fee: null,
            hours: null,
            error: true,
            errorToastNotRequired: true,
          };
          setMultipleErrorsRev(true);
        } else if (item.fee > 0 && newValue == 0) {
          objInit = {
            ...objInit,
            fee: null,
            hours: null,
            error: true,
            errorToastNotRequired: true,
          };
          setMultipleErrorsRev(true);
        } else {
          objInit = {
            ...objInit,
            hours: newValue,
            error: false,
          };
        }
      }
      return objInit;
    });

    setTableData(newTableValues);
    setDirtyTables(
      compareTableData.revenuePnlData,
      newTableValues,
      dirtyTable,
      setDirtyTable,
      tableId
    );
  };

export const PandLcostMultipleUpdate =
  (
    tableData: any,
    setTableData: any,
    newValue: any,
    selectedCells: any,
    compareTableData: any,
    dirtyTable: any,
    setDirtyTable: any,
    tableId: any,
    setMultipleErrorsRev: any
  ) =>
  async () => {
    let newTableValues = tableData.map((item: any) => {
      let objInit = { ...item };
      Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          let columnId = id;
          let rowId = `${tableId}#${item.overheadTypeId}#${item.productSegmentId}#${id}`;

          if (selectedCells.includes(rowId)) {
            if (
              item.overheadTypeId == overHeadTypedIdOrderList[0] &&
              newValue == "0"
            ) {
              objInit = {
                ...objInit,
                [columnId]: null,
                error: true,
              };
            } else if (
              item.overheadTypeId == overHeadTypedIdOrderList[0] &&
              newValue !== "0"
            ) {
              objInit = {
                ...objInit,
                [columnId]: newValue,
                error: false,
              };
            } else {
              objInit = {
                ...objInit,
                [columnId]: newValue,
              };
            }
          }
        });
      return objInit;
    });
    setTableData(newTableValues);
    setDirtyTables(
      compareTableData.profitsAndLosses,
      newTableValues,
      dirtyTable,
      setDirtyTable,
      tableId
    );
  };
export const commonRevenueTableUpdate =
  (
    tableData: any,
    setTableData: any,
    newValue: any,
    selectedCells: any,
    compareTableData: any,
    dirtyTable: any,
    setDirtyTable: any,
    tableId: any
  ) =>
  async () => {
    let newTableValues = tableData.map((item: any) => {
      let objInit = { ...item };
      Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          let columnId = id;
          let rowId = `${tableId}#${item.productSegmentId}#${id}`;

          if (selectedCells.includes(rowId)) {
            objInit = {
              ...objInit,
              [columnId]: newValue,
            };
          }
        });
      return objInit;
    });
    setTableData(newTableValues);
    let comparisonTableDataSelectionBasedOnTableID =
      tableId == 4
        ? compareTableData.winsNetRevenueData
        : tableId == 5
        ? compareTableData.lossesNetRevenueData
        : tableId == 13
        ? compareTableData.exitPhasingData
        : compareTableData.revenuePriceIncrease;
    setDirtyTables(
      comparisonTableDataSelectionBasedOnTableID,
      newTableValues,
      dirtyTable,
      setDirtyTable,
      tableId
    );
  };

export const FeePerExitHoursMultipleUpdate =
  (
    tableData: any,
    setTableData: any,
    newValue: any,
    selectedCells: any,
    compareTableData: any,
    dirtyTable: any,
    setDirtyTable: any,
    tableId: any
  ) =>
  async () => {
    let newTableValues = tableData.map((item: any) => {
      let rowId = `${tableId}#${item.productSegmentId}#1`;
      if (selectedCells.includes(rowId)) {
        return {
          ...item,
          value: newValue,
        };
      }
      return item;
    });
    setTableData(newTableValues);
    setDirtyTables(
      compareTableData.feePerExitTableData,
      newTableValues,
      dirtyTable,
      setDirtyTable,
      tableId
    );
  };

export const IncreaseInHoursMultipleUpdate =
  (
    tableData: any,
    setTableData: any,
    newValue: any,
    selectedCells: any,
    compareTableData: any,
    dirtyTable: any,
    setDirtyTable: any,
    tableId: any
  ) =>
  async () => {
    let newTableValues = tableData.map((item: any) => {
      let rowId = `${tableId}#${item.productSegmentId}#1`;
      if (selectedCells.includes(rowId)) {
        return {
          ...item,
          amount: newValue,
        };
      }
      return item;
    });
    setTableData(newTableValues);
    setDirtyTables(
      compareTableData.increaseInHoursData,
      newTableValues,
      dirtyTable,
      setDirtyTable,
      tableId
    );
  };

export const SalaryPriceIncreaseMultipleUpdate =
  (
    tableData: any,
    setTableData: any,
    newValue: any,
    selectedCells: any,
    compareTableData: any,
    dirtyTable: any,
    setDirtyTable: any,
    tableId: any
  ) =>
  async () => {
    let newTableValues = tableData.map((item: any) => {
      let objInit = { ...item };
      Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          let columnId = id;
          let rowId = `${tableId}#${item.gradeLevelId}#${id}`;

          if (selectedCells.includes(rowId)) {
            objInit = {
              ...objInit,
              [columnId]: newValue,
            };
          }
        });
      return objInit;
    });
    setTableData(newTableValues);
    setDirtyTables(
      compareTableData.salaryPriceIncrease,
      newTableValues,
      dirtyTable,
      setDirtyTable,
      tableId
    );
  };

export const costAdoptionAssumptionsMultipleUpdate = (
  tableData: any,
  setTableData: any,
  newValue: any,
  selectedCells: any,
  tableId: any,
  dropDownSelected: any,
  compareTableData: any,
  dirtyTable: any,
  setDirtyTable: any,
  mainTableId: any,
  setMultipleErrors: any
) => {
  let newTableValues = tableData.map((item: any) => {
    let rowId = `${tableId}#${item.leverId}#1`;
    if (selectedCells.includes(rowId)) {
      if (valueConvertor(newValue) > 100 || valueConvertor(newValue) < -99) {
        setMultipleErrors(true);
        return {
          ...item,
          value: null,
          error: true,
          errorToastNotRequired: true,
        };
      } else {
        return {
          ...item,
          value: newValue,
          error: false,
        };
      }
    }
    return item;
  });

  let totalVal = 0;
  newTableValues &&
    newTableValues?.map((item: any) => {
      if (!isNaN(item.value) && item.leverId !== 6) {
        totalVal += item.value;
      }
    });

  let totalEfficiencyUpdatedTableData =
    newTableValues &&
    newTableValues?.length > 0 &&
    newTableValues?.map((item: any) => {
      if (item.leverId === 6) {
        return { ...item, value: totalVal };
      } else {
        return item;
      }
    });

  setTableData((prevVal: any) => {
    return {
      ...prevVal,
      [productSegmentsMapping[dropDownSelected]]:
        totalEfficiencyUpdatedTableData,
    };
  });
  setDirtyTables(
    compareTableData,
    newTableValues,
    dirtyTable,
    setDirtyTable,
    mainTableId
  );
};
export const deployementOfFilesMultipleUpdate = (
  tableData: any,
  setTableData: any,
  newValue: any,
  selectedCells: any,
  tableId: any,
  dropDownSelected: any,
  compareTableData: any,
  dirtyTable: any,
  setDirtyTable: any,
  mainTableId: any,
  setMultipleErrors: any
) => {
  let newTableValues = tableData.map((item: any) => {
    let rowId = `${tableId}#${item.leverId}#${item.year}`;
    if (selectedCells.includes(rowId)) {
      if (valueConvertor(newValue) > 100 && valueConvertor(newValue) >= 0) {
        setMultipleErrors(true);
        return {
          ...item,
          value: null,
          error: true,
          errorToastNotRequired: true,
        };
      } else {
        return {
          ...item,
          value: newValue,
          error: false,
        };
      }
    }
    return item;
  });
  setTableData((prevVal: any) => {
    return {
      ...prevVal,
      [productSegmentsMapping[dropDownSelected]]: newTableValues,
    };
  });
  setDirtyTables(
    compareTableData,
    newTableValues,
    dirtyTable,
    setDirtyTable,
    mainTableId
  );
};

export const HeadCountsMultipleUpdate =
  (
    tableData: any,
    setTableData: any,
    newValue: any,
    selectedCells: any,
    compareTableData: any,
    dirtyTable: any,
    setDirtyTable: any,
    tableId: any
  ) =>
  async () => {
    let newTableValues = tableData.map((item: any) => {
      let objInit = { ...item };

      Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          let columnId = id;
          let rowId = objInit?.year
            ? `${tableId}#${item.gradeLevelId}#${id}#${objInit?.year}`
            : `${tableId}#${item.gradeLevelId}#${id}`;
          if (selectedCells.includes(rowId)) {
            objInit = {
              ...objInit,
              [columnId]: newValue,
            };
          }
        });
      return objInit;
    });
    switch (tableId) {
      case TABLE_MAPPINGS.annualAvgFTE:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.avgAnnualFTESalary,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.yearlyUtilRate:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.yearlyUtilisationRate,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.fullTimeEq:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.fullTimeEquavalents,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.targetManagedHours:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.targetManagedHours,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.rateCardUSI:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.usiRateCard,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.rateCardRADC:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.radcRateCard,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.rateCardLocalDC:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.localDCRateCard,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.rateCardLocalCOE:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.localCOERateCard,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;

      case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseUSI:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.usiPriceIncreaseRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseRADC:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.radcPriceIncreaseRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseLocalDC:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.localDCPriceIncreaseRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;

      case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaeLocalCOE:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.localCOEPriceIncreaseRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;

      case TABLE_MAPPINGS_HOURS.hoursUSI:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.usiAnnualTotalHours,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS_HOURS.hoursRADC:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.radcAnnualTotalHours,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS_HOURS.hoursLocalDC:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.localDCAnnualTotalHours,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;

      case TABLE_MAPPINGS_HOURS.hoursLocalCOE:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.localCOEAnnualTotalHours,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;

      case TABLE_MAPPINGS_UTIL_RATE.utilRateUSI:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.usiUtilisationRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS_UTIL_RATE.utilRateRADC:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.radcUtilisationRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS_UTIL_RATE.utilLocalDC:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.localDCUtilisationRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS_UTIL_RATE.utilLocalCOE:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.localCOEUtilisationRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      default:
        break;
    }
  };

export const RatePerGradeMultipleUpdate =
  (
    tableData: any,
    setTableData: any,
    newValue: any,
    selectedCells: any,
    compareTableData: any,
    dirtyTable: any,
    setDirtyTable: any,
    tableId: any,
    setMultipleErrorsHC: any
  ) =>
  async () => {
    let newTableValues = tableData.map((item: any) => {
      let objInit = { ...item };
      Object.keys(item || {})
        .filter(Number)
        .map((id: any, index: any) => {
          let rowId = `${tableId}#${item.gradeLevelId}#${id}`;
          if (selectedCells.includes(rowId)) {
            objInit = {
              ...objInit,
              [id]: newValue,
            };
            if (Number(newValue) > 100) {
              setMultipleErrorsHC(true);
              objInit = {
                ...objInit,
                ["error" + id]: true,
                errorToastNotRequired: true,
                [id]: null,
              };
            } else {
              objInit = {
                ...objInit,
                ["error" + id]: false,
              };
            }
          }
        });
      return objInit;
    });
    switch (tableId) {
      case TABLE_MAPPINGS.attrition:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.attritionRatePerGrade,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.promotion:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.promotionRatePerGrade,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      default:
        break;
    }
  };
export const PriceIncreaseMultipleUpdate =
  (
    tableData: any,
    setTableData: any,
    newValue: any,
    selectedCells: any,
    compareTableData: any,
    dirtyTable: any,
    setDirtyTable: any,
    tableId: any
  ) =>
  async () => {
    let newTableValues = tableData.map((item: any) => {
      let objInit = { ...item };
      Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          let columnId = id;
          let rowId = `${tableId}#${item.gradeLevelId}#${id}`;

          if (selectedCells.includes(rowId)) {
            objInit = {
              ...objInit,
              [columnId]: newValue,
            };
          }
        });
      return objInit;
    });
    switch (tableId) {
      case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseUSI:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.usiPriceIncreaseRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseRADC:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.radcPriceIncreaseRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaseLocalDC:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.localDCPriceIncreaseRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS_PRICE_INCREASE.priceIncreaeLocalCOE:
        setTableData(newTableValues);
        setDirtyTables(
          compareTableData.localCOEPriceIncreaseRates,
          newTableValues,
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      default:
        break;
    }
  };

export const yearlyUtilizationMultipleUpdate =
  (
    tablesData: any,
    setTablesData: any,
    newValue: any,
    selectedCells: any,
    compareTablesData: any,
    dirtyTable: any,
    setDirtyTable: any,
    tableId: any,
    productSegmentId: any,
    toggle: boolean
  ) =>
  async () => {
    let newTableValues = toggle
      ? tablesData.map((item: any) => {
          let objInit = { ...item };
          if (productSegmentId == item.productSegmentId) {
            Object.keys(item || {})
              .filter(Number)
              .map((id: any) => {
                let columnId = id;
                let rowId = `${tableId}#${item.gradeLevelId}#${id}`;

                if (selectedCells.includes(rowId)) {
                  objInit = {
                    ...objInit,
                    [columnId]: newValue,
                  };
                }
              });
          }
          return objInit;
        })
      : tablesData?.map((item: any) => {
          for (let cells of selectedCells) {
            if (
              item.productSegmentId == productSegmentId &&
              item.gradeLevelId == cells?.split("#")[1]
            ) {
              let yearsList: any = [];
              Object.keys(item || {})
                .filter(Number)
                .forEach((id: any) => {
                  yearsList.push(id);
                });

              for (let year of yearsList) {
                if (item[year] !== null) {
                  return {
                    ...item,
                    [yearsList[0]]: newValue,
                  };
                }
              }
              let updatedItem = { ...item };
              for (let year of yearsList) {
                updatedItem[year] = newValue;
              }
              return updatedItem;
            }
          }
          return item;
        });
    switch (tableId) {
      case TABLE_MAPPINGS.omniaYearlyUtilization:
        setTablesData(newTableValues);
        setDirtyTables(
          filterTablesByProductSegmentId(compareTablesData, productSegmentId),
          filterTablesByProductSegmentId(newTableValues, productSegmentId),
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.LevviaYearlyUtilization:
        setTablesData(newTableValues);
        setDirtyTables(
          filterTablesByProductSegmentId(compareTablesData, productSegmentId),
          filterTablesByProductSegmentId(newTableValues, productSegmentId),
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.Tier5YearlyUtilization:
        setTablesData(newTableValues);
        setDirtyTables(
          filterTablesByProductSegmentId(compareTablesData, productSegmentId),
          filterTablesByProductSegmentId(newTableValues, productSegmentId),
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.AssuranceYearlyUtilization:
        setTablesData(newTableValues);
        setDirtyTables(
          filterTablesByProductSegmentId(compareTablesData, productSegmentId),
          filterTablesByProductSegmentId(newTableValues, productSegmentId),
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      case TABLE_MAPPINGS.OtherYearlyUtilization:
        setTablesData(newTableValues);
        setDirtyTables(
          filterTablesByProductSegmentId(compareTablesData, productSegmentId),
          filterTablesByProductSegmentId(newTableValues, productSegmentId),
          dirtyTable,
          setDirtyTable,
          tableId
        );
        break;
      default:
        break;
    }
  };

export const updateConvertionRatiosTableData =
  (
    tableData: any,
    selectedCells: any,
    newValue: any,
    tableId: any,
    setTableData: any,
    setMultipleErrors: any
  ) =>
  async (dispatch: Dispatch) => {
    let newTableData = tableData.map((item: any) => {
      let objInit = { ...item };
      Object.keys(item || {})
        .filter(Number)
        .map((id: any, index: any) => {
          let rowId = `${tableId}#${item.deliveryCenterId}#${id}`;
          if (selectedCells.includes(rowId)) {
            objInit = {
              ...objInit,
              [id]: newValue,
            };
            if (newValue == 0) {
              setMultipleErrors(true);
              objInit = {
                ...objInit,
                ["error" + id]: true,
                errorToastNotRequired: true,
                [id]: null,
              };
            } else if (item[id] == 0) {
              setMultipleErrors(true);
              objInit = {
                ...objInit,
                ["error" + id]: true,
                errorToastNotRequired: true,
                [id]: null,
              };
            } else {
              objInit = {
                ...objInit,
                ["error" + id]: false,
              };
            }
          }
        });
      return objInit;
    });
    dispatch(setTableData(newTableData));
  };

export const submitForReviewInputForm = createAsyncThunk(
  "dashboard/submitForReviewInputForm",
  async (plansubmissionId: number) => {
    try {
      const res = await api.submitForReview(plansubmissionId);
      return res.data;
    } catch (e) {
      console.log("error in submit for review api call");
      console.log(e);
    }
  }
);
export const completeReviewInputForm = createAsyncThunk(
  "dashboard/completeReviewInputForm",
  async (plansubmissionId: number) => {
    try {
      const res = await api.completeReview(plansubmissionId);
      return res.data;
    } catch (e) {
      console.log("error in complete for review api call");
      console.log(e);
    }
  }
);

export const loadCostAdoptionCenter = (planSubmissionId: number) => {
  return new Promise((resolve, reject) => {
    api
      .costAdoptionCenter(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loadDeploymentAndLiberatedHours = (planSubmissionId: number) => {
  return new Promise((resolve, reject) => {
    api
      .deploymentAndLiberatedHours(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inflationTableUpdate =
  (
    tableData: any,
    setTableData: any,
    newValue: any,
    selectedCells: any,
    compareTableData: any,
    dirtyTable: any,
    setDirtyTable: any,
    tableId: any
  ) =>
  async () => {
    let newTableValues = tableData.map((item: any, index: number) => {
      let objInit = { ...item };
      Object.keys(item || {})
        .filter(Number)
        .map((id: any) => {
          let columnId = id;
          let rowId = `${tableId}#${index + 1}#${id}`;
          if (selectedCells.includes(rowId)) {
            objInit = {
              ...objInit,
              [columnId]: newValue,
            };
          }
        });
      return objInit;
    });
    setTableData(newTableValues);
    setDirtyTables(
      compareTableData.annualInflationData,
      newTableValues,
      dirtyTable,
      setDirtyTable,
      tableId
    );
  };

export const getAnnualInflationTableDataContext = (
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .getAnnualInflationTableData(planSubmissionId)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitAnnualInflationData = (
  payload: any,
  planSubmissionId: number
) => {
  return new Promise((resolve, reject) => {
    api
      .setAnnualInflationTableData(planSubmissionId, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateAnnualInflationTables = (
  inputVal: any,
  columnId: any,
  roundOff: any,
  tableData: any,
  setTableData: any,
  compareTableData: any,
  dirtyTable: any,
  setDirtyTable: any,
  tableId: any,
  prevValue: any = ""
) => {
  let value: string;
  value = validateInputValueLengthPercentage(
    inputVal,
    prevValue,
    roundOff,
    tableId
  );

  let newTableValues =
    tableData &&
    tableData?.length > 0 &&
    tableData.map((item: any) => {
      return {
        ...item,
        [columnId]: value,
      };
    });

  setTableData(newTableValues);
  setDirtyTables(
    compareTableData,
    newTableValues,
    dirtyTable,
    setDirtyTable,
    tableId
  );
};

export const {
  setMetaData,
  setConfigurationDataSubmit,
  setInputformIconClick,
  setInputFormSaved,
  setHighlightEmptyInput,
  setCurrency,
  emptyAllSelectedCells,
  setCellAsSelectedFromDrag,
  removeCellsFromSelection,
  setTableNumberWhenPopup,
  ChangeTable,
  setConversionRatiosGetTableData,
  setYearlyUtilizationGetTableData,
  setLiberatedHourProductSegment,
  TruncateAllInputformRecords,
  setConversionRatiosGetDataCompareData,
  setInputFormProgressDataLocal,
  setCurrentRevPLNavigation,
  setCurrentHeadCountTab,
  setExpandedItems,
} = inputFormSlice.actions;

export default inputFormSlice.reducer;
